import { Trans } from '@lingui/macro'
import { Button, Card, Select, MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Field, useFormikContext } from 'formik'
import moment from 'moment'
import { opportunitieStages } from '../../../../services/sfAuth/sfData/sfOpportunity'
import { parseDisplayedText } from '../Common'

// export const SUBMIT_GRANT_APPLICATION = 'grantApplication'
// export const SUBMIT_TECHNICAL_ADVISORY_ASSIGMENT = 'technicalAdvisoryAssigment'
// export const formSubmitTypes = [
//   {
//     id: SUBMIT_GRANT_APPLICATION,
//     label: <Trans>Grant application</Trans>,
//     object: 'Opportunity'
//   },
//   {
//     id: SUBMIT_TECHNICAL_ADVISORY_ASSIGMENT,
//     label: <Trans>Technical Advisory Assigment</Trans>,
//     object: 'TechnicalAdvisoryAssignment__c'
//   }
// ]

export const FormErrorsList = ({
  errors = [],
  langFR,
  connectedMap,
  injectablesMap,
  helperText,
  describeMap,
  objectsFieldsMap
}) => {
  if (errors.length === 0) {
    return null
  }
  const errorsToSections = {}
  errors.forEach(error => {
    if (errorsToSections[error.sectionName]) {
      errorsToSections[error.sectionName].push(error)
    } else {
      errorsToSections[error.sectionName] = [error]
    }
  })

  return (
    <Card className='p-24 mb-44 bg-light-error box-shadow-none'>
      <h5 className='m-0 mb-8 font-weight-500 text-error'>
        <Trans>Errors found!</Trans>
      </h5>
      {helperText && <p className='m-0 text-muted'>{helperText}</p>}
      {Object.keys(errorsToSections).map((key, index) => {
        const sectionErrors = errorsToSections[key]
        return (
          <div key={index} style={{ marginTop: 10 }}>
            <h6 className='m-0 mb-8 font-weight-500 text-error'>
              {parseDisplayedText({
                text: key,
                french: langFR,
                describeMap,
                objectsFieldsMap,
                injectablesMap,
                renderProps: {
                  connectedMap
                }
              })}
            </h6>
            <ul>
              {sectionErrors.map((errorObj, index) => {
                const { error, title } = errorObj
                if (error && error.toMap) {
                  return (
                    <li key={index}>
                      <b>{title}</b>
                      <ul>
                        {error.toMap.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </li>
                  )
                } else if (error && typeof error === 'object' && !error.props) {
                  return (
                    <div key={index}>
                      <b>{title}</b>
                      <ul>
                        {Object.keys(error).map((key, index) => (
                          <li key={index}>
                            <u>{error[key].label}</u>: {error[key].error}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                } else {
                  return (
                    <li key={index}>
                      {title ? (
                        <>
                          <b>{title}:</b> {error}
                        </>
                      ) : (
                        error
                      )}
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        )
      })}
    </Card>
  )
}

export const FormSubmitButton = ({
  id,
  value,
  configuration,
  handleSubmit,
  saving,
  disabled,
  saveDisabled,
  typeProps = {},
  errors,
  objectsFieldsMap,
  injectablesMap,
  connectedMap,
  ...props
}) => {
  const { values } = useFormikContext()
  const { submitType } = typeProps
  // if (!submitType) {
  //   console.warn('There was no submit function selected for submit button!')
  // }
  const errorsPresent = Object.keys(errors).length > 0
  let disableButton = errorsPresent || saving || disabled || saveDisabled
  if (configuration && configuration.endTime) {
    console.log('submit button', value, props)
    if (
      moment.utc().isAfter(moment(configuration.endTime).utc()) &&
      !configuration.inTesting
    ) {
      disableButton = true
      for (const connected in connectedMap) {
        const connObj = connectedMap[connected].sfObject
        if (connObj.StageName === opportunitieStages.MORE_INFO_REQUIERED) {
          disableButton = false
        }
      }
    }
  }

  return (
    <div>
      {/* TODO remove disabled when the field is configured to sav */}
      {errorsPresent && !disabled && (
        <FormErrorsList
          helperText={
            <>
              <Trans>You need to fix these error before You can submit</Trans>:
            </>
          }
          errors={errors}
          objectsFieldsMap={objectsFieldsMap}
          injectablesMap={injectablesMap}
          connectedMap={connectedMap}
        />
      )}
      <Button
        onClick={() => {
          handleSubmit({ values })
        }}
        disabled={disableButton}
        color='primary'
        variant='contained'
        fullWidth
      >
        <Trans>Submit</Trans>
      </Button>
    </div>
  )
}

export const FormEditorSubmitButton = ({
  typeProps = {},
  depth,
  editMode,
  ...props
}) => {
  // const dispatch = useDispatch()
  // const { submitType } = typeProps
  if (!editMode) {
    return (
      <Button color='primary' variant='contained' fullWidth>
        <Trans>Submit</Trans>
      </Button>
    )
  }

  return <div />

  // return (
  //   <Field>
  //     {({ form }) => {
  //       const objects = form.values.objectsConnected || []
  //       const types = objects.map(obj => obj.type)
  //       const optionsAvaliable = formSubmitTypes.filter(obj =>
  //         types.includes(obj.object)
  //       )
  //       return (
  //         <TextField
  //           label={<Trans>Submit type</Trans>}
  //           select
  //           disabled={optionsAvaliable.length === 0 || !objects}
  //           value={submitType}
  //           fullWidth
  //           variant='outlined'
  //           defaultValue=''
  //           onChange={e => {
  //             const toSet = { ...typeProps }
  //             toSet.submitType = e.target.value
  //             dispatch({
  //               type: 'FIELD',
  //               depth: depth.split('.'),
  //               fieldName: 'typeProps',
  //               fieldValue: { ...toSet }
  //             })
  //           }}
  //         >
  //           {optionsAvaliable.map((item, index) => {
  //             return (
  //               <MenuItem key={index} value={item.id}>
  //                 {item.label}
  //               </MenuItem>
  //             )
  //           })}
  //         </TextField>
  //       )
  //     }}
  //   </Field>
  // )
}
