/**
 * Created by Michał Stawski on 18.08.2020.
 */

import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import React from 'react'

// TODO zmienic niebieski
// dodac mozliwosc klikania
// save dla applicant i organization details
// contact
// wywalic fund type z project detaila
// terms and conditions checkboxy
// w elgigibility quiz linki do szczegolow
// nazwa w project details (wymagane)
// dodac informacje
// tutul
// language on register
//
// edit view z disableb polamie
// organisation signer do organizacji

// message na phone number
// wykrzyknik w kolku dla pol wymaganych dla submita
//
// alert-circle-outline
// alert-circle-check-outline
//
// Tooltip dla ikony "required for applications submission"
//
// sprawdzanie poprawnosci (tylko name required required)
//
// zadannie mateusza dla zarnowskiego
//
// dodac community.centre.support na live
// dev.centre.support na sandbox
//
// usunac pola z formularza do ktorych nie ma pol
//
// schowac salutation

// filtrowanie po isMydeleted, ustawianie go na delete
//

export const FormTitle = ({ title, children }) => (
  <div
    style={{
      margin: '5px 0px',
      borderRadius: 4,
      padding: 15
    }}
  >
    <Typography
      variant='h5'
      style={{
        marginLeft: 35,
        marginTop: 0,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <DoubleArrowIcon style={{ paddingRight: 10 }} />
      <Grid container>
        <Grid item xs>
          {title}
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Typography>
  </div>
)
