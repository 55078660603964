import { Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core'
import { Text } from '@react-pdf/renderer'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  parseDisplayedText,
  NumberFormatDefault,
  FormColorAutocomplete,
  pdfDefaultFontSize
} from './Common'

export function FormTextPdf ({
  langFR,
  injectableId,
  connectedMap,
  objectsFieldsMap,
  injectablesMap,
  describeMap,
  titleEN,
  titleFR,
  typeProps = {},
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    alignCenter,
    justify,
    fontSize,
    bold,
    underline,
    italics
  } = typeProps

  const styles = {
    fontSize: fontSize ? Number(fontSize) : pdfDefaultFontSize,
    fontFamily: 'Roboto',
    fontWeight: bold ? 'bold' : 400,
    fontStyle: italics ? 'italic' : 'normal',
    textDecoration: underline && 'underline',
    backgroundColor: backgroundColor && backgroundColor.id,
    color: textColor && textColor.id,
    padding: 0,
    margin: 0
  }
  if (alignCenter) {
    styles.textAlign = 'center'
  } else if (justify) {
    styles.textAlign = 'justify'
  }

  return (
    <Text style={styles}>
      {parseDisplayedText({
        text: langFR ? titleFR : titleEN,
        french: langFR,
        pdf: true,
        describeMap,
        injectablesMap,
        objectsFieldsMap,
        renderProps: {
          connectedMap,
          injectableId
        }
      })}
    </Text>
  )
}

export function FormText ({
  langFR,
  editor,
  injectablesMap,
  connectedMap,
  describeMap,
  objectsFieldsMap,
  titleEN,
  titleFR,
  typeProps = {},
  injectableId,
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    alignCenter,
    justify,
    italics,
    bold,
    underline,
    fontSize
  } = typeProps

  const ContainerType = alignCenter ? 'div' : 'span'
  const text = langFR ? titleFR : titleEN
  return (
    <ContainerType
      style={{
        backgroundColor: backgroundColor && backgroundColor.id,
        color: textColor && textColor.id,
        borderRadius: 5,
        fontSize: fontSize && Number(fontSize),
        textAlign: alignCenter ? 'center' : justify && 'justify',
        fontWeight: bold ? 'bold' : 400,
        fontStyle: italics ? 'italic' : 'normal',
        textDecoration: underline && 'underline',
        whiteSpace: 'pre-wrap',
        display: editor && 'block',
        width: '100%'
        // padding: Number(padding)
      }}
      className='break-in-print'
    >
      {!editor
        ? parseDisplayedText({
            text,
            french: langFR,
            describeMap,
            injectablesMap,
            objectsFieldsMap,
            renderProps: {
              connectedMap,
              injectableId
            }
          })
        : text}
    </ContainerType>
  )
}

export function FormEditorText ({
  editMode,
  injectable,
  depth,
  typeProps = {},
  showPrintProps,
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    italics,
    bold,
    underline,
    fontSize,
    // padding,
    justify,
    alignCenter,
    printPageBreakBefore
  } = typeProps
  const dispatch = useDispatch()
  if (!editMode) {
    return <FormText {...props} editor typeProps={typeProps} />
  }
  return (
    <div>
      <FormColorAutocomplete
        typeProps={typeProps}
        value={textColor}
        name='textColor'
        depth={depth}
        label={<Trans>Text color</Trans>}
        injectable={injectable}
      />

      <TextField
        label={<Trans>Font size</Trans>}
        value={fontSize || ''}
        fullWidth
        variant='outlined'
        multiline
        InputProps={{ inputComponent: NumberFormatDefault }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.fontSize = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(bold)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.bold = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Bold</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(italics)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.italics = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Italics</Trans>}
        />
      </div>
      <div style={{ marginLeft: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(underline)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.underline = e.target.checked
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Underline</Trans>}
        />
      </div>
      <FormColorAutocomplete
        typeProps={typeProps}
        value={backgroundColor}
        name='backgroundColor'
        depth={depth}
        label={<Trans>Background color</Trans>}
        injectable={injectable}
      />

      {/* <TextField
        value={padding || ''}
        style={{ marginTop: 5, marginBottom: 5 }}
        fullWidth
        label={<Trans>Padding</Trans>}
        InputProps={{ inputComponent: NumberFormatDefault }}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.padding = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      /> */}

      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(alignCenter)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.alignCenter = e.target.checked
                delete toSet.justify
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Align text to center</Trans>}
        />
      )}
      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(justify)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.justify = e.target.checked
                delete toSet.alignCenter
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Justify text</Trans>}
        />
      )}
    </div>
  )
}
