import { t, Trans } from '@lingui/macro'
import { parseDisplayedText } from './components/Common'
import { formElementTypes } from './GroupElement'
import * as Yup from 'yup'
import { unified } from 'unified'
import remarkParse from 'remark-parse/lib'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import { myI18n } from 'translation/I18nConnectedProvider'
import {
  emailTrans,
  questionRequiredTrans,
  requiredSite
} from './formTranslations'
import { isConditionMet } from './FormHelpersConditions'

Yup.addMethod(Yup.string, 'advancedTextEditorValidity', function (max) {
  return this.test('advancedTextEditorValidity', null, function (value) {
    const { path, createError } = this
    let stringValue = value || ''
    stringValue = stringValue
      .replaceAll('&nbsp;', ' ')
      .replace(/<[^>]+>/g, '')
      .replace(/\s\s+/g, ' ')
      .replaceAll('\n', '')
    if (stringValue.length > max) {
      return createError({
        path,
        message: myI18n._(
          t`This fields length cannot exceed ${max} characters!`
        )
      })
    }
    return true
  })
})

export const errorsToRender = ({
  errors,
  disabledIds,
  elementsMap,
  injectablesMap = {},
  describeMap = {},
  objectsFieldsMap = {},
  french = false
}) =>
  Object.keys(errors)
    .filter(errorId => !disabledIds.includes(errorId))
    .map(errorId => {
      const error = errors[errorId]
      const errItem = elementsMap[errorId]
      const elementProps = formElementTypes[errItem.elementType]
      let parsedError
      let title = parseDisplayedText({
        text: french ? errItem.titleFR : errItem.titleEN,
        french,
        objectsFieldsMap,
        returnString: true,
        injectablesMap,
        describeMap
      })
      if (errItem.labelAsMarkdown) {
        title = unified()
          .use(remarkParse)
          .use(remarkRehype, { allowDangerousHtml: true })
          .use(rehypeStringify, { allowDangerousHtml: true })
          .processSync(title)
          .toString()
          .replace(/<[^>]+>/g, '')
          .replaceAll('\n', '')
      }
      if (elementProps.extractError) {
        parsedError = elementProps.extractError(error)
      } else {
        if (Array.isArray(error)) {
          return {
            ...errItem,
            title,
            error: {
              toMap: error.map(
                (item, index) =>
                  String(index + 1) + '. ' + myI18n._(item.props.id)
              )
            }
          }
        } else {
          if (error.props) {
            parsedError = myI18n._(error.props.id)
          } else {
            parsedError = myI18n._(error)
          }
        }
      }
      return {
        ...errItem,
        title,
        error: parsedError
      }
    })

export const constructValidationSchema = ({
  data,
  validationInfoFromConditions = {},
  requiredFromConditions = [],
  nonRequiredFromConditions = [],
  english = true,
  returnRaw = false
}) => {
  const toRet = {}
  if (!data || !data.sections) {
    return returnRaw ? {} : Yup.object().shape({})
  }
  data.sections.forEach(section => {
    section.elements.forEach(element => {
      getValidationRule({
        item: element,
        obj: toRet,
        english,
        requiredFromConditions,
        nonRequiredFromConditions,
        validationInfoFromConditions
      })
    })
  })
  if (returnRaw) {
    return toRet
  }
  return Yup.object().shape(toRet)
}

export const addRule = (obj, id, rule) => {
  if (obj[id]) {
    obj[id] = obj[id].concat(rule)
  } else {
    obj[id] = rule
  }
}

export const getValidationRule = ({
  item,
  obj,
  english,
  requiredFromConditions,
  nonRequiredFromConditions,
  validationInfoFromConditions
}) => {
  if (item.elements) {
    item.elements.forEach(item => {
      getValidationRule({
        item,
        obj,
        english,
        requiredFromConditions,
        nonRequiredFromConditions,
        validationInfoFromConditions
      })
    })
  } else {
    const type = item.elementType
    const {
      maxOptions,
      required,
      max,
      min,
      maxChar,
      options,
      regexValidation,
      regexValidationMessageEN,
      regexValidationMessageFR,
      isUrl,
      isPhone,
      isEmail,
      picklistType,
      requiredAll,
      minFiles,
      isAdvancedTextEditor
    } = item.typeProps
    if (type === 'textInput') {
      if (regexValidation) {
        addRule(
          obj,
          item.id,
          Yup.string()
            .ensure()
            .matches(new RegExp(regexValidation), {
              excludeEmptyString: true,
              message: english
                ? regexValidationMessageEN
                : regexValidationMessageFR
            })
        )
      }
      if (maxChar) {
        if (isAdvancedTextEditor) {
          addRule(
            obj,
            item.id,
            Yup.string().advancedTextEditorValidity(maxChar)
          )
        } else {
          addRule(
            obj,
            item.id,
            Yup.string()
              .ensure()
              .max(maxChar, ({ max }) =>
                myI18n._(t`This fields length cannot exceed ${max} characters!`)
              )
          )
        }
      }
      if (isUrl) {
        addRule(
          obj,
          item.id,
          Yup.string()
            .default('')
            .ensure()
            .url(requiredSite)
        )
      } else if (isEmail) {
        addRule(
          obj,
          item.id,
          Yup.string()
            .ensure()
            .email(() => emailTrans)
        )
      }
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id)
      ) {
        addRule(obj, item.id, Yup.string().required(questionRequiredTrans))
      }
    } else if (type === 'textInputNumeric') {
      if (isPhone) {
        let rule = Yup.string().test(
          'isValidPhone',
          () => <Trans>Phone Number must have at least 10 digits</Trans>,
          value => {
            if (value) {
              const filtered = value.replace(/[^0-9]/g, '')
              return filtered.length >= 10
            } else {
              return true
            }
          }
        )
        if (
          (required && !nonRequiredFromConditions.includes(item.id)) ||
          requiredFromConditions.includes(item.id)
        ) {
          rule = rule.required(questionRequiredTrans)
        }
        addRule(obj, item.id, rule)
      } else {
        if (min) {
          addRule(
            obj,
            item.id,
            Yup.number().min(min, ({ min }) =>
              myI18n._(t`Provided value cannot be lesser than ${min}!`)
            )
          )
        }
        if (max) {
          addRule(
            obj,
            item.id,
            Yup.number().max(max, ({ max }) =>
              myI18n._(t`Provided value cannot be greater than ${max}!`)
            )
          )
        }
        if (
          (required && !nonRequiredFromConditions.includes(item.id)) ||
          requiredFromConditions.includes(item.id)
        ) {
          addRule(obj, item.id, Yup.number().required(questionRequiredTrans))
        }
      }
    } else if (type === 'picklist' && picklistType === 'multiselect') {
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id)
      ) {
        addRule(
          obj,
          item.id,
          Yup.array()
            .nullable()
            .compact()
            .min(1, questionRequiredTrans)
            .required(questionRequiredTrans)
        )
      } else if (
        (requiredAll && !nonRequiredFromConditions.includes(item.id)) ||
        (requiredAll && requiredFromConditions.includes(item.id))
      ) {
        const min = maxOptions ? Number(maxOptions) : options.length
        addRule(
          obj,
          item.id,
          Yup.array()
            .compact()
            .min(
              min,
              maxOptions ? (
                <Trans>You must select {min} checkboxes</Trans>
              ) : (
                <Trans>You must select all checkboxes!</Trans>
              )
            )
        )
      }
    } else if (type === 'uploadFiles') {
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id)
      ) {
        let minNumOfFiles = minFiles ? Number(minFiles) : 1
        if (validationInfoFromConditions[item.id]?.minFiles) {
          minNumOfFiles = validationInfoFromConditions[item.id].minFiles
        }
        addRule(
          obj,
          item.id,
          Yup.array()
            .nullable()
            .compact()
            .meta({
              current: minNumOfFiles
            })
            .min(minNumOfFiles, ({ min, value }) => {
              const desired = min
              const current = value.length
              return desired - current === 1
                ? myI18n._(t`You need to upload at least one file!`)
                : myI18n._(
                    t`You need to upload at least ${minNumOfFiles} file!`
                  )
            })
        )
      }
    } else if (type === 'bool') {
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id)
      ) {
        addRule(
          obj,
          item.id,
          Yup.boolean().equals([true], questionRequiredTrans)
        )
      }
    } else {
      const elementProps = formElementTypes[type]
      if (elementProps) {
        if (elementProps.validation) {
          addRule(obj, item.id, elementProps.validation(item))
        } else {
          if (
            (required && !nonRequiredFromConditions.includes(item.id)) ||
            requiredFromConditions.includes(item.id)
          ) {
            addRule(obj, item.id, Yup.mixed().required(questionRequiredTrans))
          }
        }
      }
    }
  }
}
