import React from 'react'
import { Grid } from '@material-ui/core'
import { useSpring, animated } from '@react-spring/web'

export default function CursorIcon ({
  width = 25,
  height = 25,
  coordinates,
  color,
  dimensions,
  name
}) {
  const { y, xPercent, yPercent } = coordinates
  const x = xPercent * dimensions.width
  const animStyles = useSpring({
    position: 'relative',
    left: x,
    top: y
  })

  return (
    <animated.div style={animStyles}>
      <Grid container>
        {/* <SVG fill='red' width={50} height={50} color='red' /> */}
        <svg
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          width={width}
          height={height}
          color={color}
          fill={color}
          x='0px'
          y='0px'
          viewBox='1064.7701 445.5539 419.8101 717.0565'
          enable-background='new 1064.7701 445.5539 419.8101 717.0565'
        >
          <polygon
            points='1283.1857,1127.3097 1406.1421,1077.6322 1314.2406,850.1678 1463.913,852.7823 1093.4828,480.8547
	1085.4374,1005.6964 1191.2842,899.8454 '
          />
        </svg>
        <span style={{ fontSize: 12, userSelect: 'none', color: color }}>
          {name}
        </span>
      </Grid>
    </animated.div>
  )
}
