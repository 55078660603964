import { Trans } from '@lingui/macro'
import { checkContact } from 'app/services/sfAuth/sfData/sfContact'
import { getDocumentsByEntity } from 'app/services/sfAuth/sfData/sfFiles'
import { describe } from 'app/services/sfAuth/sfData/sfSurvey'
import { getUserByFlow } from 'app/services/sfAuth/sfData/sfUser'
import { getMainConnected, mapFormElements } from './Form'
import { formObjectsToConnect } from './FormWizard'
import { formElementTypes } from './GroupElement'
import SFAuthService from 'app/services/sfAuth/SFAuthService'

const baseSFObjectFields = ['Id', 'LastModifiedDate']
export const connectedObjectQuery = (data, props = {}) => {
  const {
    returnOnlyDescribe = false,
    setupDescribeMap,
    id,
    langFR = false,
    handleObjectMissing,
    enqueueSnackbar
  } = props
  const conn = SFAuthService.getConnection()
  const toInclude = []
  const map = mapFormElements(data, langFR)
  const idsMap = {}
  if (!id) {
    return Promise.resolve().then(r => ({}))
  }
  const addressArray = id.split(';')
  addressArray.forEach(sub => {
    const arr = sub.split('=')
    idsMap[arr[0]] = arr[1]
  })
  const promises = []
  const toDescribe = []
  const promisesMap = {}
  const identIdToType = {}
  const idToType = {}
  const identIdToName = {}
  const fieldsToSelect = {}
  const idToName = {}
  const nameToType = {}

  data.objectsConnected.forEach(object => {
    identIdToName[object.identId] = object.name
    const objId = idsMap[object.identId]
    if (promisesMap[object.type]) {
      promisesMap[object.type].push(objId)
    } else {
      promisesMap[object.type] = [objId]
    }
    idToName[objId] = object.name
    idToType[objId] = object.type
    identIdToType[object.identId] = object.type
    nameToType[object.name] = object.type
  })
  const { referencedFields, referencedCollections } = getFieldsReferences({
    data,
    identIdToName
  })

  Object.keys(promisesMap).forEach(objName => {
    const sfObjectData = formObjectsToConnect[objName]
    if (!toDescribe.includes(objName)) {
      toDescribe.push(objName)
    }
    if (sfObjectData.additionalObjects) {
      sfObjectData.additionalObjects.forEach(obj => {
        const { sfObject, additionalObjects, collection, key } = obj
        let valid = false
        if (collection) {
          Object.keys(referencedCollections).some(name => {
            const type = nameToType[name]
            const bool = type === objName
            const referenced = referencedCollections[name] || []
            if (bool && referenced.includes(key)) {
              valid = true
            }
            return bool
          })
        } else {
          valid = true
        }
        if (valid) {
          if (!obj.disabled) {
            if (!toDescribe.includes(sfObject)) {
              toDescribe.push(sfObject)
            }
            if (additionalObjects) {
              additionalObjects.forEach(obj => {
                if (!obj.disabled && !toDescribe.includes(obj.sfObject)) {
                  toDescribe.push(obj.sfObject)
                }
              })
            }
          }
        }
      })
    }
  })

  // TODO improve - fetch only files that are referenced in upload files element to avoid getting broken query with too many files
  return Promise.all(toDescribe.map(name => describe(name))).then(
    describesResult => {
      const describeMap = {}
      describesResult.forEach(obj => {
        describeMap[obj.name] = obj
      })

      if (returnOnlyDescribe) {
        Object.values(describeMap).forEach(obj => {
          const fieldsMap = {}
          obj.fields.forEach(field => (fieldsMap[field.name] = field))
          obj.fieldsMap = fieldsMap
        })
        return describeMap
      }

      Object.keys(promisesMap).forEach(objName => {
        const toFetch = promisesMap[objName]
        let objPromise = conn.sobject(objName).find({
          Id: { $in: toFetch }
        })
        const sfObjectData = formObjectsToConnect[objName]
        if (!sfObjectData) {
          return
        }

        // Get referenced fields to select in query from text references and base select fields
        data.objectsConnected.forEach(object => {
          const validFields = describeMap[object.type].fields.map(
            field => field.name
          )
          const textReferenceFields = referencedFields[object.name] || []
          // Get base select fields for an object
          if (!fieldsToSelect[object.type]) {
            fieldsToSelect[object.type] = formObjectsToConnect[object.type]
              .select
              ? [...formObjectsToConnect[object.type].select]
              : []
          }
          const fieldsToLoop = [...textReferenceFields]
          baseSFObjectFields.forEach(field => {
            if (!fieldsToLoop.includes(field)) {
              fieldsToLoop.push(field)
            }
          })
          // Check if field exists on SF object
          fieldsToLoop
            .filter(field => field)
            .forEach(field => {
              if (
                !fieldsToSelect[object.type].includes(field) &&
                validFields.includes(field)
              ) {
                fieldsToSelect[object.type].push(field)
              } else if (!validFields.includes(field) && !field.includes('.')) {
                console.error(
                  'There is invalid reference in form: ',
                  object.type,
                  field
                )
              }
            })
        })

        // Get referenced fields to select in query from fields connected to form elments
        Object.keys(map).forEach(key => {
          const item = map[key]
          const { connectedTo, options } = item.typeProps

          if (connectedTo) {
            connectedTo.forEach(connObj => {
              const extraFields = formElementTypes[item.elementType].extraFields
              const fieldName = connObj.connectedField?.name
              const objType = identIdToType[connObj.connectedObject]

              let validFields = []
              const addFieldToSelect = fieldName => {
                if (
                  !fieldsToSelect[objType].includes(fieldName) &&
                  validFields.includes(fieldName)
                ) {
                  fieldsToSelect[objType].push(fieldName)
                } else if (
                  !validFields.includes(fieldName) &&
                  !fieldName.includes('.')
                ) {
                  console.error(
                    'There is invalid reference in form: ',
                    objType,
                    fieldName
                  )
                }
              }

              if (objType) {
                validFields = describeMap[objType].fields.map(
                  field => field.name
                )
                if (fieldName) {
                  addFieldToSelect(fieldName, objType)
                }
              }

              if (options) {
                options.forEach(option => {
                  const { connectedField } = option
                  if (connectedField) {
                    addFieldToSelect(connectedField.name)
                  }
                })
              }

              // Add fields that are needed for specific form element
              if (extraFields && objType) {
                extraFields.forEach(field => {
                  if (!fieldsToSelect[objType].includes(field)) {
                    fieldsToSelect[objType].push(field)
                  }
                })
              }

              if (item.elementType === 'googleMapsPicker') {
                const extraConnects = [
                  'city',
                  'street',
                  'zipCode',
                  'latitude',
                  'longitude'
                ]
                extraConnects.forEach(key => {
                  if (connObj[key] && connObj[key].name) {
                    const fieldName = connObj[key].name
                    addFieldToSelect(fieldName, objType)
                  }
                })
              }
            })
          }
        })

        if (fieldsToSelect[objName]) {
          objPromise = objPromise.select(fieldsToSelect[objName].join(','))
        }
        if (sfObjectData.include) {
          sfObjectData.include.forEach(include => {
            objPromise = objPromise.include(include).end()
          })
        }
        if (sfObjectData.additionalObjects) {
          sfObjectData.additionalObjects
            .filter(obj => {
              let refCollectionsArray = []
              Object.keys(referencedCollections).some(key => {
                if (nameToType[key] === objName) {
                  refCollectionsArray = referencedCollections[key]
                }
                return nameToType[key] === objName
              })
              return (
                obj.collection &&
                refCollectionsArray.includes(obj.key) &&
                obj.include
              )
            })
            .forEach(obj => {
              objPromise = objPromise.include(obj.include).end()
            })
        }

        const emptyPromise = () => Promise.resolve({})
        const addPromise = sfObjectData.additionalInfo || emptyPromise
        Object.keys(map).forEach(key => {
          const item = map[key]
          const objData = formElementTypes[item.elementType]
          const { connectedObject } = getMainConnected(item)
          if (
            objData &&
            objData.include &&
            !toInclude.includes(objData.include) &&
            connectedObject
          ) {
            if (objName === identIdToType[connectedObject]) {
              toInclude.push(objData.include)
              objPromise = objPromise.include(objData.include)
              if (objData.includeSelect) {
                objPromise = objPromise.select(objData.includeSelect)
              }
              objPromise = objPromise.end()
            }
          }
        })

        promises.push(
          Promise.all([
            objPromise,
            Promise.all([
              ...toFetch.map(id => {
                return addPromise(id, referencedCollections[idToName[id]])
              })
            ]),
            Promise.all([
              ...toFetch.map(id =>
                idToType[id] === 'User'
                  ? Promise.resolve([])
                  : getDocumentsByEntity(id)
              )
            ])
          ])
        )
      })

      return Promise.all(promises).then(resultArray => {
        const idsToIdentMap = {}
        const addressArray = id.split(';')
        addressArray.forEach(sub => {
          const arr = sub.split('=')
          idsToIdentMap[arr[1]] = arr[0]
        })
        const connectedMap = {}
        resultArray.forEach(arr => {
          const objects = arr[0]
          const additionalInfos = arr[1]
          const files = arr[2]
          objects.forEach((obj, index) => {
            const fieldsMap = {}
            const objectType = obj.attributes.type
            let additionalInfo = additionalInfos[index] || {}
            if (formObjectsToConnect[objectType]?.additionalInfoAfterFetch) {
              additionalInfo = {
                ...additionalInfo,
                ...formObjectsToConnect[objectType].additionalInfoAfterFetch(
                  obj
                )
              }
            }
            describeMap[objectType].fields.forEach(
              field => (fieldsMap[field.name] = field)
            )
            connectedMap[idsToIdentMap[obj.Id]] = {
              objectType: obj.attributes.type,
              identName: identIdToName[idsToIdentMap[obj.Id]],
              sfObject: obj,
              files: files[index].map((item, index) => ({
                name: item.ContentDocument?.Title,
                uploadId: index,
                tags: item.ContentVersion?.TagCsv,
                id: item.ContentDocumentId
              })),
              additionalInfo,
              fieldsMap
            }
          })
        })
        let objectMissing
        Object.keys(idsToIdentMap).forEach(sfId => {
          const identId = idsToIdentMap[sfId]
          if (!connectedMap[identId]) {
            objectMissing = true
            console.warn('No access to object: ', sfId)
          }
        })
        if (setupDescribeMap) {
          setupDescribeMap(describeMap)
        }
        if (objectMissing && handleObjectMissing) {
          handleObjectMissing()
        }
        const referencesToFetch = getReferencedObjects({ data, connectedMap })
        if (referencesToFetch.length > 0) {
          const conn = SFAuthService.getConnection()
          const toFetch = {}
          referencesToFetch.forEach(obj => {
            if (!toFetch[obj.id]) {
              toFetch[obj.id] = obj
            }
          })
          return Promise.allSettled(
            Object.values(toFetch).map(obj => {
              let promise
              if (obj.type === 'Contact') {
                promise = checkContact({
                  fetchId: obj.id
                }).then(result => result[0].outputValues?.contactsFound[0])
              } else if (obj.type === 'User') {
                promise = getUserByFlow({ id: obj.id })
              } else {
                promise = conn
                  .sobject(obj.type)
                  .find({
                    Id: obj.id
                  })
                  .then(r => r[0])
              }
              return promise
            })
          ).then(
            result => {
              const objects = result
                .filter(obj => obj.status === 'fulfilled')
                .map(obj => obj.value)
                .filter(obj => obj)
              const isError = result.some(obj => obj.status === 'rejected')
              if (isError) {
                if (enqueueSnackbar) {
                  enqueueSnackbar(
                    <Trans>
                      Error ocurred while loading referenced objects!
                    </Trans>,
                    {
                      variant: 'error'
                    }
                  )
                }
              }
              console.log('found reference objects', result, toFetch)
              const objectsFound = {}
              objects.forEach(obj => {
                objectsFound[obj.Id] = obj
              })
              referencesToFetch.forEach(obj => {
                connectedMap[obj.parent].sfObject[obj.reference] =
                  objectsFound[obj.id]
              })
              return connectedMap
            },
            reject => {
              console.log('error fetchin referenced objects', reject, toFetch)
              if (enqueueSnackbar) {
                enqueueSnackbar(
                  <Trans>
                    Error ocurred while loading referenced objects!
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
              return connectedMap
            }
          )
        } else {
          return connectedMap
        }
      })
    }
  )
}

export const getReferencedObjects = ({ data, connectedMap }) => {
  const referencedObjects = []
  const referencedCollections = []
  const connectedMapByName = {}
  Object.keys(connectedMap).forEach(key => {
    const obj = connectedMap[key]
    connectedMapByName[obj.identName] = { ...obj, key }
  })

  const checkStringForReferences = string => {
    if (string && typeof string === 'string') {
      const occurrences = string.match(/!{([^}]*)}/g)
      if (occurrences) {
        occurrences.forEach(occurrence => {
          if (occurrence.includes('__r')) {
            let object = occurrence.substring(
              occurrence.indexOf('{') + 1,
              occurrence.indexOf('.')
            )
            if (object.includes('T/')) {
              object = object.replaceAll('T/', '')
            }
            const field = occurrence.substring(
              occurrence.indexOf('.') + 1,
              occurrence.indexOf('__r')
            )
            const sfObjectData = connectedMapByName[object]
            if (sfObjectData) {
              const { fieldsMap, sfObject } = sfObjectData
              const fieldData =
                fieldsMap[field + '__c'] ||
                fieldsMap[field] ||
                fieldsMap[field + 'Id']
              if (fieldData && fieldData.type === 'reference') {
                const objectReference = fieldData.referenceTo[0]
                let fieldValue =
                  sfObject[field + '__c'] ||
                  sfObject[field] ||
                  sfObject[field + 'Id']
                if (fieldValue) {
                  if (fieldValue.Id) {
                    fieldValue = fieldValue.Id
                  }
                  referencedObjects.push({
                    reference: field + '__r',
                    id: fieldValue,
                    type: objectReference,
                    parent: sfObjectData.key
                  })
                }
              } else {
                // TODO handle collection references?
              }
            }
          }
        })
      }
    }
  }

  const checkElementForReferences = item => {
    const textKeys = ['titleEN', 'titleFR']
    const typePropsKeys = ['html', 'htmlFrench', 'imgLink']
    textKeys.forEach(key => {
      if (item[key]) {
        checkStringForReferences(item[key])
      }
    })
    typePropsKeys.forEach(key => {
      if (item.typeProps[key]) {
        checkStringForReferences(item.typeProps[key])
      }
    })
    if (item.elementType === 'textWithReferences') {
      const { content = [] } = item.typeProps
      content.forEach(item => {
        if (item.type === 'text') {
          checkStringForReferences(item.textEN)
          checkStringForReferences(item.textFR)
        }
      })
    }
  }

  checkStringForReferences(data.titleEN)
  checkStringForReferences(data.titleFR)

  if (data.pdfProps) {
    checkStringForReferences(data.pdfProps?.header.textEN)
    checkStringForReferences(data.pdfProps?.header.textFR)
    checkStringForReferences(data.pdfProps?.footer.textEN)
    checkStringForReferences(data.pdfProps?.footer.textFR)
  }

  data.sections.forEach(section => {
    checkStringForReferences(section.titleEN)
    checkStringForReferences(section.titleFR)
    section.elements.forEach(element => {
      const loopElement = element => {
        if (element.elements) {
          element.elements.forEach(child => {
            loopElement(child)
          })
        } else {
          checkElementForReferences(element)
        }
      }
      loopElement(element)
    })
  })
  console.log(
    'Found additional reference objects to fetch: ',
    referencedObjects
  )
  return referencedObjects
}

export const getFieldsReferences = ({ data, identIdToName }) => {
  const referencedFields = {}
  const referencedCollections = {}

  const addReference = (objectName, field, collection = false) => {
    if (field && objectName) {
      const toAdd = collection ? referencedCollections : referencedFields
      if (toAdd[objectName] && !toAdd[objectName].includes(field)) {
        toAdd[objectName].push(field)
      } else if (!toAdd[objectName]) {
        toAdd[objectName] = [field]
      }
    }
  }

  const checkStringForReferences = string => {
    if (string && typeof string === 'string') {
      const occurrences = string.match(/!{([^}]*)}/g)
      if (occurrences) {
        occurrences.forEach(occurrence => {
          let object = occurrence.substring(
            occurrence.indexOf('{') + 1,
            occurrence.indexOf('.')
          )
          if (object.includes('T/')) {
            object = object.replaceAll('T/', '')
          }
          if (object === 'SPECIAL') {
            return
          }
          let field = occurrence.substring(occurrence.indexOf('.') + 1)
          let valid = false
          if (field.slice(-1) === '}' && !occurrence.includes('__r')) {
            field = field.slice(0, field.length - 1)
            valid = true
          } else if (occurrence.includes('__r')) {
            field = occurrence.substring(
              occurrence.indexOf('.') + 1,
              occurrence.indexOf('__r')
            )
            field = field + '__c'
            valid = true
          }
          if (valid) {
            addReference(object, field)
          }
        })
      }
    }
  }

  const checkConditionForReferences = condition => {
    const { sfField, sfObject } = condition
    if (sfField && sfObject) {
      const objectName = identIdToName[sfObject]
      addReference(objectName, sfField)
    }
  }

  const checkElementForReferences = item => {
    const textKeys = ['titleEN', 'titleFR']
    const typePropsKeys = ['html', 'htmlFrench', 'imgLink']

    if (item.typeProps.connectedTo) {
      item.typeProps.connectedTo.forEach((obj, index) => {
        const objectName = identIdToName[obj.connectedObject]
        if (obj.connectedObject) {
          const itemData = formElementTypes[item.elementType]
          if (itemData && itemData.relatedCollections) {
            itemData.relatedCollections.forEach(collectionKey => {
              addReference(objectName, collectionKey, true)
            })
          }
        }
        if (obj.connectedCollection) {
          addReference(objectName, obj.connectedCollection, true)
        }
      })
    }

    if (item.conditions) {
      item.conditions.forEach(condition => {
        if (condition.conditions) {
          condition.conditions.forEach(subcondition => {
            checkConditionForReferences(subcondition)
          })
        } else {
          checkConditionForReferences(condition)
        }
      })
    }

    textKeys.forEach(key => {
      if (item[key]) {
        checkStringForReferences(item[key])
      }
    })
    typePropsKeys.forEach(key => {
      if (item.typeProps[key]) {
        checkStringForReferences(item.typeProps[key])
      }
    })
    if (item.elementType === 'textWithReferences') {
      const { content = [] } = item.typeProps
      content.forEach(item => {
        const {
          type,
          referenceField,
          referenceObject,
          textEN,
          textFR,
          referenceCollection
        } = item
        if (type === 'text') {
          checkStringForReferences(textEN)
          checkStringForReferences(textFR)
        } else if (
          type === 'fieldReference' &&
          referenceField &&
          referenceObject
        ) {
          const objectName = identIdToName[referenceObject]
          addReference(objectName, referenceField)
        } else if (
          type === 'collectionReference' ||
          type === 'wholeCollectionReference'
        ) {
          const objectName = identIdToName[referenceObject]
          addReference(objectName, referenceCollection, true)
        }
      })
    }
  }

  checkStringForReferences(data.titleEN)
  checkStringForReferences(data.titleFR)

  if (data.pdfProps) {
    checkStringForReferences(data.pdfProps?.header.textEN)
    checkStringForReferences(data.pdfProps?.header.textFR)
    checkStringForReferences(data.pdfProps?.footer.textEN)
    checkStringForReferences(data.pdfProps?.footer.textFR)
  }

  data.sections.forEach(section => {
    checkStringForReferences(section.titleEN)
    checkStringForReferences(section.titleFR)
    section.elements.forEach(element => {
      const loopElement = element => {
        if (element.elements) {
          element.elements.forEach(child => {
            loopElement(child)
          })
        } else {
          checkElementForReferences(element)
        }
      }
      loopElement(element)
      if (element.conditions) {
        element.conditions.forEach(condition => {
          if (condition.conditions) {
            condition.conditions.forEach(subcondition => {
              checkConditionForReferences(subcondition)
            })
          } else {
            checkConditionForReferences(condition)
          }
        })
      }
    })
  })
  console.log(
    'Extracted referenced fields and collections to fetch: ',
    referencedFields,
    referencedCollections
  )
  return { referencedFields, referencedCollections }
}
