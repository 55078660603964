import React, { createRef, useCallback, useEffect, useState } from 'react'
import { FastField, Form, Formik } from 'formik'
import ReactDOM from 'react-dom'
import * as Yup from 'yup'
import {
  FormikCheckboxGroupField,
  FormikSelectField,
  FormikTextField
} from 'formik-material-fields'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { FormTitle } from './FormTitle'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from 'formik-material-ui'
import { I18n } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
  Icon,
  FormLabel
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { DisplayFormikErrors } from '../../utils/DisplayFormikErrors'
import { TooltipLabelIcon } from '../page-layouts/TooltipLabelIcon'
import {
  createCase,
  HELP_CASE_TYPES
} from '../../services/sfAuth/sfData/sfCase'
import lodash from 'lodash'
import {
  requiredInt,
  requiredPositive,
  requiredSite,
  requiredTrans
} from '../forms/formTranslations'
import {
  getAccountParsed,
  NO_FOCUS_DEMOGRAPHIC,
  ERROR_DUPLICATE_NAME,
  saveAccountParsed,
  checkIfOrganizationsAreStale,
  getAccountByName,
  associateWithOrganisation
} from '../../services/sfAuth/sfData/sfAccount'
import {
  cleanUpForYup,
  getObjectPermissions
} from '../../services/sfAuth/sfData/jsConnectionUtils'
import { NO_USER } from '../../services/sfAuth/SFAuthService'
import Loading from '../../../egret/components/EgretLoadable/Loading'
import { myI18n } from '../../../translation/I18nConnectedProvider'
import 'yup-phone'
import RedirectWarning from '../page-layouts/RedirectWarning'
import { setOrganization } from 'app/redux/actions/OrganizationActions'
import FormikRadioGroupField from 'formik-material-fields/lib/FormikRadioGroupField/FormikRadioGroupField'
import { setUserData } from 'app/redux/actions/UserActions'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import {
  HEADER,
  GRID,
  DATA_LIST,
  PAGE_BREAK,
  convertConfiguration,
  DATA_ON_TOP,
  PAPER,
  SUBHEADER,
  DATA_ON_BOTTOM,
  DATA_ON_BOTTOM_NO_CARD
} from '../opportunity/opportunityViewComponents'
import { fieldsFromList } from '../opportunity/OpportunityView'
import { accountRoles } from './UserOrganizations'
import DuplicateOrganizationDialog from './DuplicateOrganizationDialog'
import { useHistory } from 'react-router'
import ReactToPrint from 'react-to-print'
import { printStyles } from './ApplicationList'
import { defaultDocTitle } from 'app/appSettings'
import { fetchFormPage } from 'app/services/sfAuth/sfData/sfForms'
import { countHelperText } from '../page-layouts/FormElement'
import { CurrencyFormated } from '../common/Formats'
import { constructFormAddressString } from '../forms/components/Common'

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  button: {
    margin: theme.spacing(1)
  },
  FormGroup_grid: {
    flexDirection: 'row',
    marginLeft: 25,
    justifyContent: 'space-between'
  },
  FormControlLabel_grid: {
    width: '45%'
  },
  HelperText_root: {
    marginLeft: 10,
    marginTop: 18,
    fontSize: 14,
    lineHeight: 1
  },
  MuiCheckbox_root: {
    padding: 3
  }
})

const toPrintStyles = theme => printStyles(theme)
const usePrintStyles = makeStyles(toPrintStyles)
const useStyles = makeStyles(styles)

const validationSchema = Yup.object().shape({
  organisationsName: Yup.string()
    .ensure()
    .required(requiredTrans)
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .label(<Trans>Organization's Legal Name</Trans>),
  otherName: Yup.string()
    .default('')
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .label(<Trans>Other used name</Trans>),
  facebook: Yup.string()
    .default('')
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .url(requiredSite)
    .label(<Trans>Facebook</Trans>),
  website: Yup.string()
    .default('')
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .url(requiredSite)
    .label(<Trans>Website</Trans>),
  City: Yup.string()
    .ensure()
    .required(requiredTrans)
    .label(<Trans>City</Trans>),
  province: Yup.string()
    .ensure()
    .required(requiredTrans)
    .label(<Trans>Province / Territory</Trans>),
  street: Yup.string()
    .ensure()
    .required(requiredTrans)
    .label(<Trans>Number + street</Trans>),
  // X1X 1X1
  postalCode: Yup.string()
    .ensure()
    .required(requiredTrans)
    .uppercase()
    .matches(/[A-Z]\d[A-Z] \d[A-Z]\d/, {
      message: <Trans>Correct format is X1X 1X1</Trans>
    })
    .label(<Trans>Postal Code</Trans>),

  organisationHistory: Yup.string()
    .ensure()
    .max(13000, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .required(requiredTrans)
    .label(<Trans>Organisation History</Trans>),
  missionStatement: Yup.string()
    .default('')
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .label(<Trans>Mission Statement</Trans>),
  geographicArea: Yup.string()
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .required(requiredTrans)
    .label(<Trans>Geographic area of operation</Trans>),
  services: Yup.string()
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .required(requiredTrans)
    .label(<Trans>What kind of services do you provide</Trans>),
  memberOfAsocNetw: Yup.string()
    .required(requiredTrans)
    .label(
      <Trans>Is your organization a member of an association/ network?</Trans>
    ),
  AsocNetwName: Yup.string()
    .default('')
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .label(<Trans>Association / Network Name</Trans>),
  buildings: Yup.number()
    .default(0)
    .min(0, requiredPositive)
    .integer(requiredInt)
    .label(<Trans>Number of buildings</Trans>),
  acronym: Yup.string()
    .default('')
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .label(<Trans>Acronym</Trans>),
  phone: Yup.string()
    .matches(/^[0-9 ()\-+]*$/, () =>
      myI18n._(t`Must contain only digits "()-+" and space`)
    )
    .required(requiredTrans)
    .test(
      'isphone',
      () => myI18n._(t`Phone Number must have at least 10 digits`),
      value => {
        if (!value) {
          return false
        }
        const filtered = value.replace(/[^0-9]/g, '')
        return filtered.length >= 10
      }
    )
    .label(<Trans>Phone Number</Trans>),
  email: Yup.string()
    .ensure()
    .email(() => myI18n._(t`Must be a valid email`))
    .required(requiredTrans)
    .label(<Trans>Email</Trans>),
  linkedIn: Yup.string()
    .default('')
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .label(<Trans>LinkedIn</Trans>),
  employees: Yup.number()
    // .default(0)
    .required(requiredTrans)
    .min(0, requiredPositive)
    .integer(requiredInt)
    .label(<Trans>Number of Employees</Trans>),
  yearlyBudget: Yup.number()
    // .default(0)
    .required(requiredTrans)
    .min(0, requiredPositive)
    .integer(requiredInt)
    .label(<Trans>Organisation's total yearly budget</Trans>),
  numberOfMembers: Yup.number()
    .default(0)
    .min(0, requiredPositive)
    .integer(requiredInt)
    .label(<Trans>Number of members</Trans>),
  mainFundingSource: Yup.string()
    .ensure()
    .max(255, ({ max }) => <Trans>Must be at most {max} chars</Trans>)
    .required(requiredTrans)
    .label(<Trans>Main funding sources</Trans>),
  numberOfTenants: Yup.number()
    .default(0)
    .min(0, requiredPositive)
    .integer(requiredInt)
    .label(<Trans>Number of tenants living in your building (s)</Trans>),
  units: Yup.number()
    // .default(0)
    // .required(requiredTrans)
    .min(0, requiredPositive)
    .integer(requiredInt)
    .label(<Trans>Housing units managed by Organisation</Trans>),
  typeOfPopulation: Yup.array(),
  organisationType: Yup.array()
    .required(requiredTrans)
    .label(<Trans>What type of organisation are you?</Trans>),
  focusDemographic: Yup.array()
    .ensure()
    .max(3, ({ max }) =>
      myI18n._(t`Focus demographic ust have less then or equal ${max} items`)
    )
    .label([
      <Trans>
        For which groups/ demographic is your housing exclusively reserved ?
      </Trans>,
      ' / ',
      <Trans>
        Which group/ demographic does your organization primarily focuses on?
      </Trans>
    ])

  /* centreObjectives: Yup.array().required("This field is required. Please choose at least one"),
  demographic: Yup.array().required("This field is required. Please choose at least one"),
  amountRequested: Yup.number('This field accepts only numbers').required(requiredTrans).positive().integer(),
  demographicSpecify: Yup.string().required(requiredTrans), */
})
export const OrganisationDetailsValidationSchema = validationSchema

const initialData = {
  organisationsName: '',
  otherName: '',
  facebook: '',
  linkedIn: '',
  website: '',
  City: '',
  province: '',
  street: '',
  postalCode: '',
  organisationHistory: '',
  missionStatement: '',
  geographicArea: '',
  services: '',
  memberOfAsocNetw: false,
  AsocNetwName: '',
  indigenousAffiliation: [],
  organisationType: [],
  buildings: 0,
  acronym: '',
  email: '',
  phone: '',
  employees: 0,
  yearlyBudget: 0,
  numberOfMembers: 0,
  mainFundingSource: '',
  numberOfTenants: 0,
  units: 0,
  typeOfPopulation: [],
  hasFocusDemographic: false,
  focusDemographic: [],
  targetDemographics: false
}

const provincesToPostal = () => [
  {
    label: myI18n._(t`Newfoundland and Labrador`),
    value: 'NL'
  },
  {
    label: myI18n._(t`Prince Edward Island`),
    value: 'PE'
  },
  {
    label: myI18n._(t`Nova Scotia`),
    value: 'NS'
  },
  {
    label: myI18n._(t`New Brunswick`),
    value: 'NB'
  },
  {
    label: myI18n._(t`Québec`),
    value: 'QC'
  },
  {
    label: myI18n._(t`Ontario`),
    value: 'ON'
  },
  {
    label: myI18n._(t`Manitoba`),
    value: 'MB'
  },
  {
    label: myI18n._(t`Saskatchewan`),
    value: 'SK'
  },
  {
    label: myI18n._(t`Alberta`),
    value: 'AB'
  },
  {
    label: myI18n._(t`British Columbia`),
    value: 'BC'
  },
  {
    label: myI18n._(t`Yukon`),
    value: 'YT'
  },
  {
    label: myI18n._(t`Northwest Territories`),
    value: 'NT'
  },
  {
    label: myI18n._(t`Nunavut`),
    value: 'NU'
  }
]

const provinceToCode = Object.fromEntries(
  provincesToPostal().map(({ label, value }) => [label, value])
)

const publicOrCoopSet = new Set([
  'Non-Profit Housing',
  'Co-op Housing',
  'Public Housing',
  'Supportive housing',
  'Transitional housing',
  'Shelter and other short-term/emergency housing services',
  'Urban, rural or northern Indigenous housing',
  'On-reserve Indigenous housing',
  'Other housing provider',
  'Community Land Trust'
])

const checkPublicOrCoop = values => {
  const intersect = [...values.organisationType].filter(i =>
    publicOrCoopSet.has(i)
  )
  return intersect.length !== 0
}

export const OrganisationToPrintPageBreakBefore = ({ classes }) => {
  const toRet = [
    {
      type: PAGE_BREAK
    }
  ]
  toRet.push(...OrganisationToPrint({ classes }))
  toRet.splice(toRet.length - 1, 1)
  return toRet
}

export const OrganisationToPrint = ({ classes }) => [
  {
    type: HEADER,
    style: { fontSize: 24, fonWeight: 'bold' },
    text: (
      <span className={classes.mainTitle}>
        <Trans>Organization details</Trans>
      </span>
    )
  },
  {
    type: GRID,
    justify: 'center',
    style: {
      breakInside: 'auto'
    },
    gridClass: classes.gridContainerStretch,
    paperClass: classes.dataContainer,
    fields: [
      ...fieldsFromList(
        [
          {
            field: 'info.accountName',
            subtitle: <Trans>Organizations Legal Name</Trans>
          },
          {
            field: 'account.geographicAreaOfOperation',
            subtitle: <Trans>Geographic area of operation</Trans>
          },
          {
            field: 'account.totalYearBudget',
            formatter: value => <CurrencyFormated value={value} />,
            subtitle: <Trans>Organization's total yearly budget</Trans>
          }
        ],
        classes.dataContainer,
        { padding: 10 }
      ),
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Organization type</Trans>,
        data: opp =>
          opp.account.accountType ? opp.account.accountType.split(';') : []
      },
      {
        type: DATA_ON_BOTTOM,
        // style: { margin: 20 },
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        data: opp => opp.account.missionStatement,
        subtitle: (
          <span className={classes.subtitle}>
            <Trans>Mission Statement</Trans>
          </span>
        )
      },
      {
        type: DATA_ON_BOTTOM,
        gridClass: classes.gridContainerStretch,
        // style: { margin: 20 },
        paperClass: classes.dataContainer,
        data: opp => opp.account.organizationHistory,
        subtitle: (
          <span className={classes.subtitle}>
            <Trans>Organization History</Trans>
          </span>
        )
      }
    ]
  },
  {
    type: GRID,
    gridClass: classes.gridContainer,
    justify: 'center',
    fields: [
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Indigenous Affiliation</Trans>,
        data: opp =>
          opp.account.indigenousAffiliation
            ? opp.account.indigenousAffiliation.split(';')
            : []
      },
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Primary demographic target (if applicable)</Trans>,
        data: opp =>
          opp.account.focusDemographic
            ? opp.account.focusDemographic.split(';')
            : []
      }
    ]
  },
  {
    type: GRID,
    justify: 'center',
    gridClass: classes.gridContainerStretch,
    paperClass: classes.dataContainer,
    fields: [
      ...fieldsFromList(
        [
          {
            field: 'account.servicesProvided',
            subtitle: <Trans>Services offered</Trans>
          },
          {
            field: 'account.numberOfTenants',
            subtitle: <Trans>Number of tenants</Trans>
          },
          {
            field: 'account.housingUnits',
            subtitle: <Trans>Housing units</Trans>
          },
          {
            field: 'account.unitsSpecialNeeds',
            subtitle: <Trans>Units for people needing support services</Trans>
          },
          {
            field: 'account.numberOfEmployees',
            subtitle: <Trans>Number of employees</Trans>
          }
        ],
        classes.gridChild,
        { paddingLeft: 10 }
      )
    ]
  },
  {
    type: PAGE_BREAK
  }
]

const OrganisationToPrintFull = ({ classes }) => {
  return {
    fields: [
      {
        type: HEADER,
        style: { fontSize: 24, fonWeight: 'bold' },
        text: app => (
          <span className={classes.mainTitle}>{app.organisationsName}</span>
        )
      },
      {
        type: PAPER,
        justify: 'center',
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        fields: [
          {
            type: SUBHEADER,
            text: <Trans>Organization Information</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.otherName,
            // disabled: app => !app.otherName,
            subtitle: <Trans>Other used name</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.acronym,
            // disabled: app => !app.acronym,
            subtitle: <Trans>Acronym</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.phone,
            // disabled: app => !app.phone,
            subtitle: <Trans>Phone Number</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.email,
            // disabled: app => !app.email,
            subtitle: <Trans>Email</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.website,
            // disabled: app => !app.website,
            subtitle: <Trans>Website</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.facebook,
            // disabled: app => !app.facebook,
            subtitle: <Trans>Facebook</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.linkedIn,
            // disabled: app => !app.linkedIn,
            subtitle: <Trans>LinkedIn</Trans>
          }
        ]
      },
      {
        type: PAPER,
        justify: 'center',
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        fields: [
          {
            type: SUBHEADER,
            text: <Trans>Mailing details</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.street,
            // disabled: app => !app.street,
            subtitle: <Trans>Street address</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.City,
            // disabled: app => !app.City,
            subtitle: <Trans>City</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => {
              let toRet = null
              provincesToPostal().forEach(ob => {
                if (ob.value === app.province) {
                  toRet = ob.label
                }
              })
              return toRet
            },
            // disabled: app => !app.province,
            subtitle: <Trans>Province / Territory</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.postalCode,
            // disabled: app => !app.postalCode,
            subtitle: <Trans>Postal Code</Trans>
          },
          {
            type: DATA_ON_TOP,
            data: app => app.geographicArea,
            // disabled: app => !app.geographicArea,
            subtitle: <Trans>Geographic area of operation</Trans>
          }
        ]
      },
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Organization type</Trans>,
        // disabled: acc => acc.organisationType && acc.organisationType.length > 0,
        data: acc => (acc.organisationType ? acc.organisationType : [])
      },
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        subtitle: <Trans>Indigenous Affiliation</Trans>,
        // disabled: acc => acc.indigenousAffiliation && acc.indigenousAffiliation.length > 0,
        data: acc =>
          acc.indigenousAffiliation ? acc.indigenousAffiliation : []
      },
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        prevData: acc => {
          return {
            main: acc.isHousingProvider ? (
              <Trans>
                Is your housing exclusively reserved for a specific group,
                demographic, or household type?
              </Trans>
            ) : (
              <Trans>
                Does your organization primarily focus on a particular
                group/demographic?
              </Trans>
            ),
            sub: <Trans id={acc.hasFocusDemographic} />
          }
        },
        // disabled: acc => acc.focusDemographic && acc.focusDemographic.length > 0,
        subtitle: <Trans>Focus Demographic</Trans>,
        data: acc => (acc.focusDemographic ? acc.focusDemographic : [])
      },
      {
        type: DATA_LIST,
        paperClass: classes.dataList,
        bulletIcon: 'accessibility_new',
        prevData: acc => {
          return {
            main: (
              <Trans>
                Does your organization have tailored processes, policies, or
                practices for certain groups/ demographics?
              </Trans>
            ),
            sub: <Trans id={acc.targetDemographics} />
          }
        },
        subtitle: acc => {
          return acc.targetDemographics === 'Yes' ? (
            <Trans>
              Which groups/ demographics does your organization target through
              tailored processes, policies, or practices?
            </Trans>
          ) : acc.isHousingProvider ? (
            <Trans>
              Which characteristics tend to describe the people who live in your
              housing?
            </Trans>
          ) : (
            <Trans>
              Does your organization have particular experience working with any
              groups/ demographics?
            </Trans>
          )
        },
        data: acc => (acc.typeOfPopulation ? acc.typeOfPopulation : [])
      },
      {
        type: PAPER,
        justify: 'center',
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        fields: [
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.services,
            subtitle: <Trans>What kind of services do you provide</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.employees,
            subtitle: <Trans>Number of employees</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.numberOfMembers,
            subtitle: <Trans>Number of members (individuals)</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.buildings,
            subtitle: <Trans>Number of buildings</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.numberOfTenants,
            subtitle: (
              <Trans>Number of tenants living in your building (s)</Trans>
            )
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.units,
            subtitle: <Trans>Housing units managed</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.unitsSpecialNeeds,
            subtitle: (
              <Trans>Number of units for people needing support services</Trans>
            )
          }
        ]
      },
      {
        type: PAPER,
        justify: 'center',
        gridClass: classes.gridContainerStretch,
        paperClass: classes.dataContainer,
        fields: [
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.missionStatement,
            subtitle: <Trans>Mission Statement</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.organisationHistory,
            subtitle: <Trans>Organization History</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.yearlyBudget,
            subtitle: <Trans>Organization's total yearly budget</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.mainFundingSource,
            subtitle: <Trans>Main funding sources</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => app.numberOfOrganizations,
            subtitle: <Trans>Number of member organizations</Trans>
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            data: app => <Trans id={app.memberOfAsocNetw} />,
            subtitle: (
              <Trans>
                Is your organization a member of an association/ network?
              </Trans>
            )
          },
          {
            type: DATA_ON_BOTTOM_NO_CARD,
            disabled: app => app.memberOfAsocNetw !== 'Yes',
            data: app => app.AsocNetwName,
            subtitle: <Trans>Association/ network name</Trans>
          }
        ]
      }
    ]
  }
}

export const userRolesWithEditAccess = [
  accountRoles.MANAGER.apiName,
  accountRoles.CONTRIBUTOR.apiName
]

const OrganisationDetails = ({ match }) => {
  const [formId, setFormId] = React.useState()
  const [address, setAddress] = React.useState()
  const history = useHistory()
  const organization = useSelector(state => state.organization)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.configuration)
  const { enqueueSnackbar } = useSnackbar()
  const id = match.params.id || organization.id

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    if (config.FORM_ORGANIZATION_DETAILS) {
      fetchFormPage(config.FORM_ORGANIZATION_DETAILS).then(form => {
        setAddress(
          constructFormAddressString({
            objectsConnected: form.objectsConnected,
            organizationId: id,
            user
          })
        )
      })
      setFormId(config.FORM_ORGANIZATION_DETAILS)
    } else {
      enqueueSnackbar(
        <Trans>There was no form configured for this tab!</Trans>,
        {
          variant: 'error'
        }
      )
    }
  }

  if (!formId || !address) {
    return <Loading />
  }
  history.push('/elasticform/' + formId + '/' + address)
  return <div />
}

const OrganisationDetails2 = ({
  values,
  touched,
  errors,
  dirty,
  setSubmitting,
  isSubmitting,
  setFieldValue,
  isValid,
  isOwner
}) => {
  const classes = useStyles(styles)
  const printClasses = usePrintStyles()
  const [duplicateOrg, setDuplicateOrg] = React.useState(null)
  const [dialogOpened, setDialogState] = React.useState(!isOwner)
  const user = useSelector(state => state.user)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  let userRole = 'No role'

  if (values.members) {
    for (const member of values.members) {
      if (member.UserId === user.userId) {
        userRole = member.TeamMemberRole
      }
    }
  }
  const disabled = !isOwner || userRolesWithEditAccess.indexOf(userRole) === -1
  const dispatch = useDispatch()
  const isHousingProvider = checkPublicOrCoop(values)
  const ref = createRef()
  const printRef = createRef()
  const history = useHistory()

  const submitData = useCallback(() => {
    enqueueSnackbar(<Trans>Submitting Request</Trans>, {
      variant: 'info'
    })
    createCase({
      description: 'Ownership request for the organization',
      type: HELP_CASE_TYPES.HELP,
      language: user.language,
      organization: values.id
      // contact: user.userObject.contactId,
    })
      .then(() => {
        enqueueSnackbar(<Trans>Submitted Request</Trans>, {
          variant: 'success'
        })
      })
      .catch(error => {
        console.error('Error submitting case', error)
        enqueueSnackbar(<Trans>Error Submitting Request</Trans>, {
          variant: 'error'
        })
      })
  }, [user, values.id])

  console.log('values', values)

  const handleSave = () => {
    setSubmitting(true)
    // setLastHandled(submitCount)
    if (!values.hasFocusDemographic) {
      values.focusDemographic = [NO_FOCUS_DEMOGRAPHIC]
    }
    if (errors.website) {
      delete values.website
    }
    if (errors.email) {
      delete values.email
    }
    if (errors.facebook) {
      delete values.facebook
    }
    console.log('handleSubmit', values)
    const saving = enqueueSnackbar(null, {
      variant: 'info',
      persist: true,
      content: key => ProgressSnackbar(<Trans>Saving Data</Trans>)
    })
    getAccountByName(values.organisationsName).then(results => {
      results = results.filter(acc => acc !== values.id)
      if (results.length !== 0) {
        closeSnackbar(saving)
        enqueueSnackbar(
          <Trans>Organization with this name already exists!</Trans>,
          {
            variant: 'error'
          }
        )
        setDuplicateOrg(results[0])
      } else {
        Promise.all([
          saveAccountParsed(values),
          checkIfOrganizationsAreStale(user.userId, dirty ? values.id : null)
        ])
          .then(([result, staleOrganizations]) => {
            console.log('saved', result)
            closeSnackbar(saving)
            enqueueSnackbar(<Trans>Saved Data</Trans>, {
              variant: 'success'
            })
            setSubmitting(false)
            var newUser = { ...user }
            newUser.staleOrganizations = staleOrganizations
            dispatch(setUserData(newUser))
            dispatch(setOrganization(values))
            return result
          })
          .catch(err => {
            if (err !== NO_USER) {
              enqueueSnackbar(<Trans>Error Saving Data</Trans>, {
                variant: 'error'
              })
            }
            closeSnackbar(saving)
            setSubmitting(false)
            console.log('not saved', err)
          })
      }
    })
  }

  return (
    <Form autoComplete='off'>
      <DuplicateOrganizationDialog
        handleDecline={() => {
          setSubmitting(false)
          setDuplicateOrg(null)
        }}
        text={
          <Trans>
            Organisation with this name already exists! Would you like to try to
            connect with existing organization instead (Unsaved data will be
            lost)?
          </Trans>
        }
        handleAccept={() => {
          const progressKey = enqueueSnackbar(null, {
            persist: true,
            content: key => ProgressSnackbar(<Trans>Updating info</Trans>)
          })
          closeSnackbar(progressKey)
          history.push('/grants/JoinOrganization')
          // associateWithOrganisation({
          //   email: user.email,
          //   userId: user.userId,
          //   orgId: duplicateOrg
          // }).then(
          //   flowResult => {
          //     const connectData =
          //       connectToOrganizationFlowResults[
          //         flowResult[0].outputValues.result
          //       ]
          //     if (connectData) {
          //       enqueueSnackbar(connectData.messege, {
          //         variant: connectData.type
          //       })
          //     } else {
          //       enqueueSnackbar(<Trans>Info updated!</Trans>, {
          //         variant: 'success'
          //       })
          //     }

          // ReactDOM.findDOMNode(ref.current).scrollIntoView()
          // Promise.all([
          //   getAccountParsed(duplicateOrg, {
          //     permissions: {}
          //   }),
          //   saveUser({
          //     Id: user.userId,
          //     Associated_Organizations__c: duplicateOrg
          //   })
          // ]).then(([org, saveUser]) => {
          //   if (connectData.edit) {
          //     dispatch(setOrganization({ ...org }))
          //     history.push('/grants/JoinOrganization')
          //   }
          // })
          // },
          // error => {
          //   console.log(error)
          //   setDuplicateOrg(null)
          // }
          // )
        }}
        open={Boolean(duplicateOrg)}
        orgId={duplicateOrg}
      />
      <Dialog open={dialogOpened}>
        <DialogTitle>
          <Trans>
            You don't have permission to edit data of this organization!
          </Trans>
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Trans>
              You don't have permission to edit data of this organization, if
              this is incorrect contact the administrator
            </Trans>
          </Typography>
          <Grid container justify='center' style={{ marginTop: 15 }}>
            <Button
              variant='contained'
              color='primary'
              style={{ margin: 10 }}
              onClick={() => {
                setDialogState(false)
              }}
            >
              <Trans>Ok</Trans>
            </Button>
            <Button
              variant='contained'
              color='primary'
              style={{ margin: 10 }}
              onClick={() => {
                setDialogState(false)
                submitData()
              }}
            >
              <Trans>Request Access</Trans>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <RedirectWarning open={dirty && !duplicateOrg} handleSave={handleSave} />
      <I18n>
        {({ i18n }) => (
          <Paper className={classes.root}>
            <div style={{ padding: 10 }}>
              <ReactToPrint
                onAfterPrint={() => (document.title = defaultDocTitle)}
                onBeforePrint={() =>
                  (document.title = values.organisationsName)
                }
                trigger={() => (
                  <IconButton aria-label={<Trans>Print</Trans>} className=''>
                    <Icon>print</Icon>
                  </IconButton>
                )}
                content={() => printRef.current}
              />
            </div>

            <div className='hideInPrint' ref={printRef}>
              {convertConfiguration(
                OrganisationToPrintFull({ classes: printClasses }),
                values,
                {}
              )}
            </div>

            <div ref={ref}>
              <FormTitle title={<Trans>Organization Information</Trans>} />{' '}
            </div>

            <Grid
              container
              spacing={1}
              style={{ marginBottom: 5 }}
              justify='space-between'
            >
              <Grid
                container
                item
                lg={6}
                xs={12}
                spacing={1}
                style={{ marginBottom: 5 }}
                justify='space-between'
              >
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ marginBottom: -15 }}>
                      <b>
                        <Trans>Organisation's Name</Trans>
                      </b>
                      <Tooltip
                        title={i18n._(
                          t`Enter your organization main address. This information will help us identify your organization and see if a similar record is matching with existing information in our system. `
                        )}
                      >
                        <IconButton
                          aria-label='delete'
                          className={classes.button}
                          size='small'
                        >
                          <HelpOutlineIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FastField
                    disabled={disabled}
                    component={TextField}
                    name='organisationsName'
                    label={i18n._(t`Organization's Legal Name`)}
                    margin='normal'
                    variant='outlined'
                    helperText={countHelperText(
                      values.organisationsName,
                      255,
                      true
                    )}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ marginBottom: -15 }}>
                      <Trans>Other used name</Trans>
                      <Tooltip
                        title={i18n._(
                          t`Is your organization known under another name among your community? Maybe you are using a shorter version than the full organization legal name. If it’s an acronym, please enter it in the next field below. `
                        )}
                      >
                        <IconButton
                          aria-label='delete'
                          className={classes.button}
                          size='small'
                        >
                          <HelpOutlineIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormikTextField
                    // variant="outlined"
                    disabled={disabled}
                    name='otherName'
                    label=''
                    margin='normal'
                    variant='outlined'
                    fullWidth
                    helperText={countHelperText(values.otherName, 255)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ marginBottom: -15 }}>
                      <Trans>Acronym</Trans>
                      <Tooltip
                        title={i18n._(
                          t`Is there an acronym that you use to identify your organization? `
                        )}
                      >
                        <IconButton
                          aria-label='delete'
                          className={classes.button}
                          size='small'
                        >
                          <HelpOutlineIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormikTextField
                    disabled={disabled}
                    // variant="outlined"
                    name='acronym'
                    label={i18n._(t`Acronym`)}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                    helperText={countHelperText(values.acronym, 255)}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                lg={6}
                xs={12}
                spacing={1}
                style={{ marginBottom: 5 }}
                justify='space-between'
              >
                <Grid
                  item
                  xs={12}
                  lg={4}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginTop: 5, marginRight: 15 }}>
                    <b>
                      <Trans>Phone Number</Trans>
                    </b>
                  </div>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FormikTextField
                    disabled={disabled}
                    name='phone'
                    label={<Trans>Phone Number</Trans>}
                    margin='normal'
                    variant='outlined'
                    helperText={i18n._(t`Required`)}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginTop: 5, marginRight: 15 }}>
                    <b>
                      <Trans>Email</Trans>
                    </b>
                  </div>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FormikTextField
                    disabled={disabled}
                    name='email'
                    label='Email'
                    margin='normal'
                    variant='outlined'
                    helperText={i18n._(t`Required`)}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ marginBottom: -15 }}>
                      <Trans>Website</Trans>
                      <Tooltip
                        title={i18n._(
                          t`Enter the full internet address related to your organization website. If you don’t have one of your own, but you know another website that provide basic information about your organization, you can enter that. This is useful to understand what your organization is about.`
                        )}
                      >
                        <IconButton
                          aria-label='delete'
                          className={classes.button}
                          size='small'
                        >
                          <HelpOutlineIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FormikTextField
                    disabled={disabled}
                    name='website'
                    label={i18n._(t`Website`)}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                    helperText={countHelperText(values.website, 255)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ marginBottom: -15 }}>
                      <Trans>Facebook</Trans>
                      <Tooltip
                        title={i18n._(
                          t`Do you have a designated Facebook page for your organization? Please provide us the link for it.`
                        )}
                      >
                        <IconButton
                          aria-label='delete'
                          className={classes.button}
                          size='small'
                        >
                          <HelpOutlineIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FormikTextField
                    // variant="outlined"
                    disabled={disabled}
                    name='facebook'
                    label={i18n._(t`Facebook`)}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                    helperText={countHelperText(values.facebook, 255)}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={4}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <div style={{ marginBottom: 15 }}>
                    <div style={{ marginBottom: -15 }}>
                      <Trans>LinkedIn</Trans>
                      <Tooltip
                        title={i18n._(
                          t`Do you have a designated LinkedIn page for your organization? Please provide us the link for it.`
                        )}
                      >
                        <IconButton
                          aria-label='delete'
                          className={classes.button}
                          size='small'
                        >
                          <HelpOutlineIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <FormikTextField
                    disabled={disabled}
                    name='linkedIn'
                    label={i18n._(t`LinkedIn`)}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                    helperText={countHelperText(values.linkedIn, 255)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{ marginBottom: 5 }}
              justify='space-between'
            >
              <Grid
                container
                item
                lg={6}
                xs={12}
                spacing={1}
                style={{ marginBottom: 5, marginLeft: 15 }}
                justify='space-between'
              >
                <Grid item xs={12} lg={12}>
                  <b>
                    <Trans>Organisation Address</Trans>
                  </b>
                  <Tooltip
                    title={i18n._(
                      t`Enter the main address of your organization (headquarters, main office, etc.)`
                    )}
                  >
                    <IconButton
                      aria-label='delete'
                      className={classes.button}
                      size='small'
                    >
                      <HelpOutlineIcon fontSize='inherit' />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <FormikTextField
                    disabled={disabled}
                    name='street'
                    label={i18n._(t`Number + street`)}
                    margin='normal'
                    variant='outlined'
                    helperText={i18n._(t`Required`)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <FormikTextField
                    disabled={disabled}
                    name='City'
                    label={i18n._(t`City`)}
                    margin='normal'
                    variant='outlined'
                    helperText={i18n._(t`Required`)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  {/* <FormikTextField */}
                  {/*  disabled={disabled} */}
                  {/*  name='province' */}
                  {/*  label={i18n._(t`Province / Territory`)} */}
                  {/*  margin='normal' */}
                  {/*  variant='outlined' */}
                  {/*  helperText={i18n._(t`Required`)} */}
                  {/*  fullWidth */}
                  {/* /> */}
                  <FormikSelectField
                    disabled={disabled}
                    name='province'
                    label={i18n._(t`Province / Territory`)}
                    margin='normal'
                    variant='outlined'
                    helperText={i18n._(t`Required`)}
                    options={provincesToPostal()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <FormikTextField
                    disabled={disabled}
                    name='postalCode'
                    label={i18n._(t`Postal Code`)}
                    margin='normal'
                    variant='outlined'
                    helperText={i18n._(t`Required`)}
                    fullWidth
                  />
                </Grid>
                {/* </Grid> */}
                {/* <Grid */}
                {/*  container */}
                {/*  item */}
                {/*  lg={6} */}
                {/*  xs={12} */}
                {/*  spacing={1} */}
                {/*  style={{ marginBottom: 5 }} */}
                {/*  justify='space-between' */}
                {/* > */}
                <Grid
                  item
                  xs={12}
                  lg={12}
                  style={{ marginTop: 25, textAlign: 'left' }}
                >
                  <b>
                    <Trans>Geographic area of operation</Trans>
                  </b>
                  <Tooltip
                    title={i18n._(
                      t`Research and pick the areas on which you are conducting your activities. You can pick many. If you cannot find that specific area please the closest option to it. (Canada, provinces, municipalities, region, etc)`
                    )}
                  >
                    <IconButton
                      aria-label='delete'
                      className={classes.button}
                      size='small'
                    >
                      <HelpOutlineIcon fontSize='inherit' />
                    </IconButton>
                  </Tooltip>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      disabled={disabled}
                      name='geographicArea'
                      label=''
                      margin='normal'
                      variant='outlined'
                      helperText={countHelperText(
                        values.geographicArea,
                        255,
                        true
                      )}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{ marginBottom: 5 }}
              justify='space-between'
            >
              <Grid
                xs={12}
                item
                style={{
                  marginTop: 25,
                  marginLeft: 15,
                  textAlign: 'left',
                  minWidth: 300
                }}
              >
                <div style={{ marginBottom: 15 }}>
                  <div style={{ marginBottom: -15 }}>
                    <b>
                      <Trans>What type of organisation are you?</Trans>
                    </b>
                    <Tooltip title={i18n._(t`Select all that apply`)}>
                      <IconButton
                        aria-label='delete'
                        className={classes.button}
                        size='small'
                      >
                        <HelpOutlineIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
              <Grid container style={{ width: '900px' }}>
                <FormikCheckboxGroupField
                  name='organisationType'
                  label=''
                  margin='normal'
                  disabled={disabled}
                  FormGroupProps={{
                    classes: {
                      root: classes.FormGroup_grid
                    }
                  }}
                  FormControlLabelProps={{
                    classes: {
                      root: classes.FormControlLabel_grid
                    }
                  }}
                  CheckboxProps={{
                    classes: {
                      root: classes.MuiCheckbox_root
                    }
                  }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.HelperText_root
                    }
                  }}
                  // TODO Account_Type__c
                  options={[
                    {
                      label: i18n._(t`Non-profit housing`),
                      translatedValue: t`Non-Profit Housing`,
                      value: 'Non-Profit Housing'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(
                            t`Community economic development organization`
                          )}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`Supports local job creation, training programs, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'Community economic development organization'
                    },
                    {
                      label: i18n._(t`Cooperative housing`),
                      translatedValue: t`Co-op Housing`,
                      value: 'Co-op Housing'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Government structure`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`Band council, self-governing nation, federal, provincial, municipal, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'Government structure'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Public housing`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`Municipal, local housing corporation etc.`
                            )}
                          />
                        </div>
                      ),
                      translatedValue: t`Public Housing`,
                      value: 'Public Housing'
                    },
                    {
                      label: i18n._(t`Funding agency`),
                      value: 'Funding agency'
                    },
                    {
                      label: i18n._(t`Supportive housing`),
                      value: 'Supportive housing'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Professional organization`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`Union, professional order/ associations, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'Professional organization'
                    },
                    {
                      label: i18n._(t`Transitional housing`),
                      value: 'Transitional housing'
                    },
                    {
                      label: i18n._(t`Consulting firm`),
                      translatedValue: t`Consulting Firm`,
                      value: 'Consulting Firm'
                    },
                    {
                      label: i18n._(
                        t`Shelter and other short-term/emergency housing services`
                      ),
                      value:
                        'Shelter and other short-term/emergency housing services'
                    },
                    {
                      label: i18n._(t`For-profit enterprise`),
                      translatedValue: t`For-profit Enterprise`,
                      value: 'For-profit Enterprise'
                    },
                    {
                      label: i18n._(
                        t`Urban, rural or northern Indigenous housing`
                      ),
                      translatedValue: t`Urban, rural or northern Indigenous housing`,
                      value: 'Urban, rural or northern Indigenous housing'
                    },
                    {
                      label: i18n._(t`Foundation`),
                      value: 'Foundation'
                    },
                    {
                      label: i18n._(t`Community Land Trust`),
                      value: 'Community Land Trust'
                    },
                    {
                      label: i18n._(t`On-reserve Indigenous housing`),
                      value: 'On-reserve Indigenous housing'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Other housing provider`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(t`Student housing, etc.`)}
                          />
                        </div>
                      ),
                      value: 'Other housing provider'
                    },
                    {
                      label: i18n._(t`Non-profit organization`),
                      translatedValue: t`Non-profit Organization`,
                      value: 'Non-profit Organization'
                    },
                    {
                      label: i18n._(t`Registered charity`),
                      value: 'Registered charity'
                    },
                    {
                      label: i18n._(t`Tenant Association`),
                      value: 'Tenant Association'
                    },
                    {
                      label: i18n._(t`Academic or research institution`),
                      value: 'Academic or research institution'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Community housing sector organization`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`Association, federation, network, technical resource group, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'Community housing sector organization'
                    },
                    {
                      label: i18n._(t`Media`),
                      value: 'Media'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Grassroots organization`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`Community organization, Friendship Centre, anti-poverty, women’s centre, senior centre, soup kitchen, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'Grassroots organization'
                    },
                    {
                      label: i18n._(t`Other`),
                      value: 'Other'
                    }
                  ]}
                  multiple
                />
                {!(errors.organisationType && touched.organisationType) && (
                  <FormLabel
                    component='legend'
                    style={{ marginTop: 10, marginLeft: 10, marginBottom: 8 }}
                  >
                    Required
                  </FormLabel>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                  marginLeft: 15,
                  textAlign: 'left',
                  minWidth: 300
                }}
              >
                <b>
                  <Trans>Which describe your organization</Trans>
                </b>
                <Tooltip title={i18n._(t`Select all that apply`)}>
                  <IconButton
                    aria-label='delete'
                    className={classes.button}
                    size='small'
                  >
                    <HelpOutlineIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid container style={{ paddingLeft: 25 }}>
                <FormikCheckboxGroupField
                  name='indigenousAffiliation'
                  disabled={disabled}
                  label=''
                  margin='normal'
                  CheckboxProps={{
                    classes: {
                      root: classes.MuiCheckbox_root
                    }
                  }}
                  options={[
                    {
                      label: (
                        <div>
                          {i18n._(t`Indigenous owned`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`For non-profits or coops, this means your members or voters are mostly Indigenous`
                            )}
                          />
                        </div>
                      ),
                      translatedValue: (
                        <Trans>
                          Indigenous-owned (report back to an Indigenous
                          community, organization, or population)
                        </Trans>
                      ),
                      value:
                        'Indigenous-owned (report back to an Indigenous community, organization, or population)'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Indigenous led`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`The management and board are primarily Indigenous`
                            )}
                          />
                        </div>
                      ),
                      translatedValue: t`Indigenous-led (management and board)`,
                      value: 'Indigenous-led (management and board)'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Indigenous focus`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`Primarily serving or focused on Indigenous people, but may or may not be led or
                               owned by Indigenous people, e.g. A Friendship Centre or housing organization that has
                                units reserved for Indigenous tenants`
                            )}
                          />
                        </div>
                      ),
                      translatedValue: t`Indigenous focus (primary demographic served)`,
                      value: 'Indigenous focus (primary demographic served)'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`Indigenous aware`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`An organization that has services, policies, or procedures adapted to Indigenous cultural and social circumstances, e.g. a shelter that provides Elder visits and sweat lodge ceremonies`
                            )}
                          />
                        </div>
                      ),
                      translatedValue: t`Indigenous aware (Indigenous services, policies, procedures)`,
                      value:
                        'Indigenous aware (Indigenous services, policies, procedures)'
                    },
                    {
                      label: <div>{i18n._(t`None of the above`)}</div>,
                      value: 'None of the above'
                    }
                  ]}
                  multiple
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{ marginBottom: 5 }}
              justify='space-between'
            >
              <Grid container direction='row' xs={12}>
                <Grid>
                  <FormikRadioGroupField
                    disabled={disabled}
                    style={{ margin: 15 }}
                    label={
                      <div style={{ color: 'black' }}>
                        <b>
                          {isHousingProvider ? (
                            <Trans>
                              Is your housing exclusively reserved for a
                              specific group, demographic, or household type?
                            </Trans>
                          ) : (
                            <Trans>
                              Does your organization primarily focus on a
                              particular group/demographic?
                            </Trans>
                          )}
                        </b>
                        <TooltipLabelIcon
                          tooltip={i18n._(
                            isHousingProvider
                              ? t`e.g. seniors’ housing, housing for people at risk of homelessness, etc.`
                              : t`e.g. a senior centre, women’s council, Indigenous Friendship Centre, etc.`
                          )}
                        />
                      </div>
                    }
                    name='hasFocusDemographic'
                    margin='normal'
                    row='all'
                    onChange={e => {
                      if (e === 'No') {
                        setFieldValue('focusDemographic', [])
                      }
                    }}
                    options={[
                      { label: <Trans>Yes</Trans>, value: 'Yes' },
                      {
                        label: <Trans>No</Trans>,
                        value: 'No'
                      }
                    ]}
                  />
                </Grid>
              </Grid>

              {values.hasFocusDemographic === 'Yes' && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'left',
                      marginTop: 15,
                      marginLeft: 15,
                      minWidth: 300
                    }}
                  >
                    <b>
                      {isHousingProvider ? (
                        <Trans>
                          For which groups/ demographic is your housing
                          exclusively reserved ?
                        </Trans>
                      ) : (
                        <Trans>
                          Which group/ demographic does your organization
                          primarily focuses on?
                        </Trans>
                      )}
                    </b>
                    <Tooltip
                      title={
                        isHousingProvider
                          ? i18n._(
                              t`Only select more than one option if your organization exclusively houses these groups. For example, select both ‘Seniors’ and ‘People with physical disabilities’ only if your housing is exclusively reserved for seniors with physical disabilities.`
                            )
                          : i18n._(
                              t`Only select more than one option if your organization almost exclusively focuses on these groups. For example, select both ‘Seniors’ and ‘People with physical disabilities’ only if your organization is primarily oriented to serving seniors with physical disabilities.`
                            )
                      }
                    >
                      <IconButton
                        aria-label='delete'
                        className={classes.button}
                        size='small'
                      >
                        <HelpOutlineIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid container style={{ width: '900px' }}>
                    <FormikCheckboxGroupField
                      name='focusDemographic'
                      disabled={disabled}
                      label=''
                      multiple
                      margin='normal'
                      FormGroupProps={{
                        classes: {
                          root: classes.FormGroup_grid
                        }
                      }}
                      FormControlLabelProps={{
                        classes: {
                          root: classes.FormControlLabel_grid
                        }
                      }}
                      CheckboxProps={{
                        classes: {
                          root: classes.MuiCheckbox_root
                        }
                      }}
                      options={[
                        {
                          label: i18n._(t`Families`),
                          value: 'Families'
                        },
                        {
                          label: i18n._(t`Racialized persons/communities`),
                          value: 'Racialized person/communities'
                        },
                        {
                          label: i18n._(t`Single-parent families`),
                          value: 'Single-parent families'
                        },
                        {
                          label: i18n._(t`Newcomers and refugees`),
                          value: 'Newcomers and refugees'
                        },
                        {
                          label: i18n._(t`Persons living alone`),
                          value: 'Persons living alone'
                        },
                        {
                          label: i18n._(t`People experiencing Homelessness`),
                          value: 'People experiencing Homelessness'
                        },
                        { label: i18n._(t`Seniors`), value: 'Seniors' },
                        {
                          label: i18n._(t`Formerly incarcerated people`),
                          value: 'Formerly incarcerated people'
                        },
                        {
                          label: i18n._(t`Low-income households`),
                          value: 'Low-income households'
                        },
                        {
                          label: i18n._(t`People fleeing domestic violence`),
                          value: 'People fleeing domestic violence'
                        },
                        {
                          label: i18n._(t`Women`),
                          value: 'Women'
                        },
                        {
                          label: (
                            <div>
                              {i18n._(t`People living with mental illness`)}
                              <TooltipLabelIcon
                                tooltip={i18n._(
                                  t`e.g. Bipolar disorder, Schizophrenia, etc.`
                                )}
                              />
                            </div>
                          ),
                          value: 'People living with mental illness'
                        },
                        {
                          label: i18n._(t`Youth/ young adults`),
                          value: 'Youth/ young adults'
                        },
                        {
                          label: i18n._(t`People living with addictions`),
                          value: 'People living with addictions'
                        },
                        {
                          label: i18n._(t`At-risk youth`),
                          value: 'At-risk youth'
                        },
                        {
                          label: (
                            <div>
                              {i18n._(t`People with physical disabilities`)}
                              <TooltipLabelIcon
                                tooltip={i18n._(
                                  t`e.g. reduced mobility, visually impaired, etc.`
                                )}
                              />
                            </div>
                          ),
                          value: 'People with Physical disabilities'
                        },
                        {
                          label: i18n._(t`LGBTQIA2S+`),
                          value: 'LGBTQIA2S+'
                        },
                        {
                          label: (
                            <div>
                              {i18n._(
                                t`People with developmental disabilities`
                              )}
                              <TooltipLabelIcon
                                tooltip={i18n._(
                                  t`e.g. Fetal Alcohol Spectrum Disorder, Autism, etc.`
                                )}
                              />
                            </div>
                          ),
                          value: 'People with developmental disabilities'
                        },
                        {
                          label: i18n._(t`First Nations`),
                          value: 'First Nations'
                        },
                        {
                          label: i18n._(
                            t`People experiencing social isolation`
                          ),
                          value: 'People experiencing social isolation'
                        },
                        {
                          label: i18n._(t`Inuit`),
                          value: 'Inuit'
                        },
                        { label: i18n._(t`Veterans`), value: 'Veterans' },
                        {
                          label: i18n._(t`Métis`),
                          value: 'Métis'
                        },
                        {
                          label: i18n._(t`Other`),
                          value: 'Other'
                        },
                        {
                          label: i18n._(t`All Indigenous Peoples`),
                          value: 'All Indigenous Peoples'
                        }
                      ]}
                    />
                  </Grid>
                </>
              )}

              <Grid container direction='row' xs={12}>
                <FormikRadioGroupField
                  disabled={disabled}
                  style={{ margin: 15 }}
                  label={
                    <div style={{ color: 'black' }}>
                      <b>
                        {isHousingProvider ? (
                          <Trans>
                            Does your organization have tailored processes,
                            policies, or practices for certain groups/
                            demographics?
                          </Trans>
                        ) : (
                          <Trans>
                            Does your organization have tailored processes,
                            policies, or practices for certain groups/
                            demographics?
                          </Trans>
                        )}
                      </b>
                      <TooltipLabelIcon
                        tooltip={i18n._(
                          isHousingProvider
                            ? t`e.g. a housing provider that offers a daycare service to accommodate families with children, activities for at-risk youth, translation services for immigrants, etc.`
                            : t`This would include an organization that prioritizes certain demographics in its mission or offers service/support for specific demographics (activities for at-risk youth, translation services for immigrants, etc.), even if such groups are not the sole focus of the organization`
                        )}
                      />
                    </div>
                  }
                  name='targetDemographics'
                  margin='normal'
                  row='all'
                  options={[
                    { label: <Trans>Yes</Trans>, value: 'Yes' },
                    {
                      label: <Trans>No</Trans>,
                      value: 'No'
                    }
                  ]}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: 15, marginLeft: 15, textAlign: 'left' }}
              >
                <b>
                  {values.targetDemographics === 'Yes' ? (
                    <Trans>
                      Which groups/ demographics does your organization target
                      through tailored processes, policies, or practices?
                    </Trans>
                  ) : isHousingProvider ? (
                    <Trans>
                      Which characteristics tend to describe the people who live
                      in your housing?
                    </Trans>
                  ) : (
                    <Trans>
                      Does your organization have particular experience working
                      with any groups/ demographics?
                    </Trans>
                  )}
                </b>
                <Tooltip title={i18n._(t`Select all that apply`)}>
                  <IconButton
                    aria-label='delete'
                    className={classes.button}
                    size='small'
                  >
                    <HelpOutlineIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid container style={{ width: '900px' }}>
                <FormikCheckboxGroupField
                  name='typeOfPopulation'
                  disabled={disabled}
                  label=''
                  margin='normal'
                  FormGroupProps={{
                    classes: {
                      root: classes.FormGroup_grid
                    }
                  }}
                  FormControlLabelProps={{
                    classes: {
                      root: classes.FormControlLabel_grid
                    }
                  }}
                  CheckboxProps={{
                    classes: {
                      root: classes.MuiCheckbox_root
                    }
                  }}
                  options={[
                    {
                      label: i18n._(t`Families`),
                      value: 'Families'
                    },
                    {
                      label: i18n._(t`Racialized persons/communities`),
                      value: 'Racialized person/communities'
                    },
                    {
                      label: i18n._(t`Single-parent families`),
                      value: 'Single-parent families'
                    },
                    {
                      label: i18n._(t`Newcomers and refugees`),
                      value: 'Newcomers and refugees'
                    },
                    {
                      label: i18n._(t`Persons living alone`),
                      value: 'Persons living alone'
                    },
                    {
                      label: i18n._(t`People experiencing Homelessness`),
                      value: 'People experiencing Homelessness'
                    },
                    { label: i18n._(t`Seniors`), value: 'Seniors' },
                    {
                      label: i18n._(t`Formerly incarcerated people`),
                      value: 'Formerly incarcerated people'
                    },
                    {
                      label: i18n._(t`Low-income households`),
                      value: 'Low-income households'
                    },
                    {
                      label: i18n._(t`People fleeing domestic violence`),
                      value: 'People fleeing domestic violence'
                    },
                    {
                      label: i18n._(t`Women`),
                      value: 'Women'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`People living with mental illness`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`e.g. Bipolar disorder, Schizophrenia, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'People living with mental illness'
                    },
                    {
                      label: i18n._(t`Youth/ young adults`),
                      value: 'Youth/ young adults'
                    },
                    {
                      label: i18n._(t`People living with addictions`),
                      value: 'People living with addictions'
                    },
                    {
                      label: i18n._(t`At-risk youth`),
                      value: 'At-risk youth'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`People with physical disabilities`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`e.g. reduced mobility, visually impaired, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'People with Physical disabilities'
                    },
                    {
                      label: i18n._(t`LGBTQIA2S+`),
                      value: 'LGBTQIA2S+'
                    },
                    {
                      label: (
                        <div>
                          {i18n._(t`People with developmental disabilities`)}
                          <TooltipLabelIcon
                            tooltip={i18n._(
                              t`e.g. Fetal Alcohol Spectrum Disorder, Autism, etc.`
                            )}
                          />
                        </div>
                      ),
                      value: 'People with developmental disabilities'
                    },
                    {
                      label: i18n._(t`First Nations`),
                      value: 'First Nations'
                    },
                    {
                      label: i18n._(t`People experiencing social isolation`),
                      value: 'People experiencing social isolation'
                    },
                    {
                      label: i18n._(t`Inuit`),
                      value: 'Inuit'
                    },
                    { label: i18n._(t`Veterans`), value: 'Veterans' },
                    {
                      label: i18n._(t`Métis`),
                      value: 'Métis'
                    },
                    {
                      label: i18n._(t`Other`),
                      value: 'Other'
                    },
                    {
                      label: i18n._(t`People of Indigenous descent`),
                      value: 'People of Indigenous descent'
                    }
                  ]}
                  multiple
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={2} style={{ marginTop: 25, textAlign: 'right' }}>
                <b>
                  <Trans>What kind of services do you provide</Trans>
                </b>
                <Tooltip
                  title={i18n._(
                    t`Can you name the types of services your organization is offering?`
                  )}
                >
                  <IconButton
                    aria-label='delete'
                    className={classes.button}
                    size='small'
                  >
                    <HelpOutlineIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <FormikTextField
                  disabled={disabled}
                  name='services'
                  label=''
                  margin='normal'
                  variant='outlined'
                  helperText={countHelperText(values.services, 255, true)}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={6}
                lg={2}
                style={{ marginTop: 25, textAlign: 'right' }}
              >
                <div style={{ marginBottom: 15 }}>
                  <div style={{ marginBottom: -15 }}>
                    <b>
                      <Trans>Number of Employees</Trans>
                    </b>
                    <Tooltip
                      title={i18n._(
                        t`How many employees are currently employed by your organization?`
                      )}
                    >
                      <IconButton
                        aria-label='delete'
                        className={classes.button}
                        size='small'
                      >
                        <HelpOutlineIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormikTextField
                  disabled={disabled}
                  name='employees'
                  label=''
                  type='number'
                  margin='normal'
                  variant='outlined'
                  fullWidth
                  helperText={i18n._(t`Required`)}
                />
              </Grid>
              <Grid
                item
                xs={6}
                lg={2}
                style={{ marginTop: 25, textAlign: 'right' }}
              >
                <div style={{ marginBottom: 15 }}>
                  <div style={{ marginBottom: -15 }}>
                    <b>
                      <Trans>Number of members</Trans>
                    </b>
                    <Tooltip
                      title={i18n._(
                        t`Fill this only if your organization has members, such if you are a cooperative or an association of some sort.  `
                      )}
                    >
                      <IconButton
                        aria-label='delete'
                        className={classes.button}
                        size='small'
                      >
                        <HelpOutlineIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} lg={2}>
                <FormikTextField
                  disabled={disabled}
                  // variant="outlined"
                  name='numberOfMembers'
                  type='number'
                  label=''
                  margin='normal'
                  variant='outlined'
                  fullWidth
                />
              </Grid>

              {isHousingProvider && (
                <>
                  <Grid
                    item
                    xs={6}
                    lg={2}
                    style={{ marginTop: 25, textAlign: 'right' }}
                  >
                    <div style={{ marginBottom: 15 }}>
                      <div style={{ marginBottom: -15 }}>
                        <b>
                          <Trans>Number of buildings</Trans>
                        </b>
                        <Tooltip
                          title={i18n._(
                            t`How many building does your organization own, rent or manage in total?`
                          )}
                        >
                          <IconButton
                            aria-label='delete'
                            className={classes.button}
                            size='small'
                          >
                            <HelpOutlineIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={2}>
                    <FormikTextField
                      disabled={disabled}
                      name='buildings'
                      label=''
                      type='number'
                      margin='normal'
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={2}
                    style={{ marginTop: 25, textAlign: 'right' }}
                  >
                    <div style={{ marginBottom: 15 }}>
                      <div style={{ marginBottom: -15 }}>
                        <b>
                          <Trans>
                            Number of tenants living in your building (s)
                          </Trans>
                        </b>
                        <Tooltip
                          title={i18n._(
                            t`Indicate the total number of people living in your buildings. They can be members or tenants.`
                          )}
                        >
                          <IconButton
                            aria-label='delete'
                            className={classes.button}
                            size='small'
                          >
                            <HelpOutlineIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={2}>
                    <FormikTextField
                      disabled={disabled}
                      name='numberOfTenants'
                      label=''
                      type='number'
                      margin='normal'
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={2}
                    style={{ marginTop: 25, textAlign: 'right' }}
                  >
                    <div style={{ marginBottom: 15 }}>
                      <div style={{ marginBottom: -15 }}>
                        <b>
                          <Trans>Housing units managed by Organisation</Trans>
                        </b>
                        <Tooltip
                          title={i18n._(
                            t`Indicate the total number of units that your organization is managing or owning.`
                          )}
                        >
                          <IconButton
                            aria-label='delete'
                            className={classes.button}
                            size='small'
                          >
                            <HelpOutlineIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={2}>
                    <FormikTextField
                      disabled={disabled}
                      name='units'
                      label=''
                      type='number'
                      margin='normal'
                      variant='outlined'
                      // helperText={i18n._(t`Required`)}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={2}
                    style={{ marginTop: 25, textAlign: 'right' }}
                  >
                    <div style={{ marginBottom: 15 }}>
                      <div style={{ marginBottom: -15 }}>
                        <b>
                          <Trans>
                            Number of units for people needing support services
                          </Trans>
                        </b>
                        <Tooltip
                          title={i18n._(
                            t`Total number of units in your building stock for people needing support services, e.g. individuals who live with physical and/or developmental challenges and/or people living with mental illness or addictions. `
                          )}
                        >
                          <IconButton
                            aria-label='delete'
                            className={classes.button}
                            size='small'
                          >
                            <HelpOutlineIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} lg={2}>
                    <FormikTextField
                      disabled={disabled}
                      name='unitsSpecialNeeds'
                      label=''
                      type='number'
                      margin='normal'
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} />
              <Grid
                item
                xs={12}
                lg={2}
                style={{ marginTop: 25, textAlign: 'right' }}
              >
                <Trans>Mission Statement</Trans>
                <Tooltip
                  title={i18n._(
                    t`Explain briefly what your organization’s mission is.`
                  )}
                >
                  <IconButton
                    aria-label='delete'
                    className={classes.button}
                    size='small'
                  >
                    <HelpOutlineIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} lg={10}>
                <FormikTextField
                  disabled={disabled}
                  helperText={countHelperText(values.missionStatement, 255)}
                  name='missionStatement'
                  label=''
                  margin='normal'
                  variant='outlined'
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={2}
                style={{ marginTop: 25, textAlign: 'right' }}
              >
                <b>
                  <Trans>Organisation History</Trans>
                </b>
                <Tooltip
                  title={i18n._(
                    t`Let us know about the history of your organization. How did it start, how it has evolved?`
                  )}
                >
                  <IconButton
                    aria-label='delete'
                    className={classes.button}
                    size='small'
                  >
                    <HelpOutlineIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} lg={10}>
                <FormikTextField
                  disabled={disabled}
                  name='organisationHistory'
                  label=''
                  multiline
                  rows={12}
                  margin='normal'
                  variant='outlined'
                  helperText={countHelperText(
                    values.organisationHistory,
                    13000,
                    true
                  )}
                  fullWidth
                />
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  style={{ marginTop: 25, textAlign: 'right' }}
                >
                  <b>
                    <Trans>Organisation's total yearly budget</Trans>
                  </b>
                  <Tooltip
                    title={i18n._(
                      t`Enter the total revenues your organization had the last time you produced your annual financial statement.`
                    )}
                  >
                    <IconButton
                      aria-label='delete'
                      className={classes.button}
                      size='small'
                    >
                      <HelpOutlineIcon fontSize='inherit' />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} lg={10}>
                  <FormikTextField
                    disabled={disabled}
                    name='yearlyBudget'
                    label=''
                    type='number'
                    margin='normal'
                    variant='outlined'
                    helperText={i18n._(t`Required`)}
                    fullWidth
                  />
                </Grid>

                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    lg={2}
                    style={{ marginTop: 25, textAlign: 'right' }}
                  >
                    <b>
                      <Trans>Main funding sources</Trans>
                    </b>
                    <Tooltip
                      title={i18n._(
                        t`In your own words, please explain what are your organization’s main sources of funding or revenues.`
                      )}
                    >
                      <IconButton
                        aria-label='delete'
                        className={classes.button}
                        size='small'
                      >
                        <HelpOutlineIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} lg={10}>
                    <FormikTextField
                      disabled={disabled}
                      name='mainFundingSource'
                      label=''
                      margin='normal'
                      variant='outlined'
                      helperText={countHelperText(
                        values.mainFundingSource,
                        255,
                        true
                      )}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  style={{ marginBottom: 20 }}
                >
                  <Grid item>
                    <b>
                      <Trans>Number of member organizations</Trans>
                    </b>
                    <Tooltip
                      title={i18n._(
                        t`Fill this out only if your organization has other organizations as members, such as in an association.`
                      )}
                    >
                      <IconButton
                        aria-label='delete'
                        className={classes.button}
                        size='small'
                      >
                        <HelpOutlineIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <FormikTextField
                      disabled={disabled}
                      name='numberOfOrganizations'
                      label=''
                      type='number'
                      margin='normal'
                      variant='outlined'
                      // helperText={i18n._(t`Required`)}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    container
                    direction='row'
                    wrap='nowrap'
                    alignItems='center'
                  >
                    {/* <b>
                      <Trans>Is a member of Association / Network</Trans>
                    </b>
                    <Tooltip
                      title={i18n._(
                        t`Is the organization member of other association, federation or network?`
                      )}
                    >
                      <IconButton
                        aria-label='delete'
                        className={classes.button}
                        size='small'
                      >
                        <HelpOutlineIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                    <Field
                      component={Switch}
                      disabled={disabled}
                      type='checkbox'
                      name='memberOfAsocNetw'
                      label=''
                    /> */}

                    <div style={{ margin: 15 }}>
                      <FormikRadioGroupField
                        disabled={disabled}
                        label={
                          <div style={{ color: 'black' }}>
                            <b>
                              <Trans>
                                Is a member of Association / Network
                              </Trans>
                            </b>
                            <TooltipLabelIcon
                              tooltip={i18n._(
                                t`Is the organization member of other association, federation or network?`
                              )}
                            />
                          </div>
                        }
                        name='memberOfAsocNetw'
                        margin='normal'
                        row='all'
                        options={[
                          { label: <Trans>Yes</Trans>, value: 'Yes' },
                          {
                            label: <Trans>No</Trans>,
                            value: 'No'
                          }
                        ]}
                      />
                      <FormLabel component='legend'>Required</FormLabel>
                    </div>
                  </Grid>
                  {values.memberOfAsocNetw === 'Yes' && (
                    <Grid
                      item
                      // xs
                      // container
                      // wrap='nowrap'
                      // direction='row'
                      // alignItems='flex-start'
                    >
                      <b>
                        <Trans>Association / Network Name</Trans>
                      </b>
                      <Tooltip
                        title={i18n._(
                          t`Enter the full name of the association, federation or network you are part of.`
                        )}
                      >
                        <IconButton
                          aria-label='delete'
                          className={classes.button}
                          size='small'
                        >
                          <HelpOutlineIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {values.memberOfAsocNetw === 'Yes' && (
                    <Grid item xs>
                      <FormikTextField
                        disabled={disabled}
                        name='AsocNetwName'
                        label={i18n._(t`Association / Network Name`)}
                        variant='outlined'
                        fullWidth
                        helperText={countHelperText(values.AsocNetwName, 255)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {!isValid && (
              <DisplayFormikErrors
                errors={errors}
                validationsSchema={validationSchema}
              />
            )}
            <Button
              onClick={handleSave}
              fullWidth
              color='primary'
              variant='contained'
              // disabled={!isValid || !dirty || isSubmitting}
              disabled={isSubmitting || disabled || !isValid}
            >
              <Trans>Save</Trans>
              {/* {isValid ? <Trans>Save</Trans> : <Trans>Form data not valid check fields</Trans>} */}
            </Button>
          </Paper>
        )}
      </I18n>
    </Form>
  )
}

const OrganisationDetailsWrapped = ({ user, organization, ...props }) => {
  const [account, setAccount] = useState({})
  const [isOwner, setIsOwner] = useState()
  const { enqueueSnackbar } = useSnackbar()
  console.log('organisation details', account, user)

  const accId = props.match.params.id

  useEffect(() => {
    console.log(user.userId, organization.id)
    getObjectPermissions(user.userId, organization.id).then(
      permission => {
        const toSet = { ...organization }
        // convert full province to abbreviation
        if (provinceToCode[toSet.province]) {
          toSet.province = provinceToCode[toSet.province]
        }
        if (accId) {
          getAccountParsed(accId, { permissions: { TEAM_MEMBERS: true } }).then(
            acc => {
              setAccount(acc)
              setIsOwner(permission)
            },
            reject => {
              enqueueSnackbar(<Trans>Wrong organization id provided!</Trans>, {
                variant: 'error'
              })
            }
          )
        } else {
          setAccount(toSet)
          setIsOwner(permission)
        }
      },
      error => {
        enqueueSnackbar(
          <Trans>There is no current organization to be loaded!</Trans>,
          { variant: 'error' }
        )
      }
    )
  }, [organization, accId])

  return account && !lodash.isEmpty(account) ? (
    <Formik
      initialValues={{ ...initialData, ...cleanUpForYup(account) }}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      component={props => {
        return <OrganisationDetails isOwner={isOwner} {...props} />
      }}
    />
  ) : (
    <Loading />
  )
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
})

export default OrganisationDetails

// export default withStyles(styles)(
//   connect(mapStateToProps)(React.memo(OrganisationDetailsWrapped))
// )
