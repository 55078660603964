import { grpc } from '@improbable-eng/grpc-web'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import _ from 'lodash'
import moment from 'moment'
import {
  Form,
  FormAndUserInfo,
  FormAndField,
  FormAndFieldAndValue,
  CursorEvent,
  FormToSubmit,
  FormAndFieldAndLockIDAndContent,
  FormAndFieldAndFieldValue,
  RequestSFSaveMessage,
  RequestStatus,
  SaveToSFCompletedReport,
  FormCachePointer,
  FormIDString,
  LockOperation,
  FieldLockOperation
} from './proto/generated/Multiuser_grpc_web_pb'
import { Multiuser } from './proto/generated/Multiuser_pb_service'
import {
  ChatMessageCollectionRequest,
  ChatMessageSend,
  FieldAndValue,
  FormAndFieldAndLockID,
  FormAndFieldsAndValues,
  LockOperationMap
} from './proto/generated/Multiuser_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export const host = sfOauthConfig.config.multiuserHost

const muFormId = id => {
  const formId = new FormIDString()
  formId.setFormid(id)
  return formId
}

const decodeUserInfoArrayResponse = response => {
  const toRet = {}
  response[0].forEach((array, loginOrder) => {
    if (array.length > 0) {
      const id = array[1]
      toRet[id] = {
        id,
        logOrder: loginOrder,
        ...JSON.parse(array[2])
      }
    }
  })
  return toRet
}

export const grpcGetFormBackups = ({ formId, userId, onFail, onSuccess }) => {
  const request = muFormId(formId)
  grpc.unary(Multiuser.GetListOfCachedFormVersions, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          const caches = response.message.array[0].map(obj => ({
            id: obj[0],
            date: moment.unix(obj[1][0])
          }))
          onSuccess(caches)
        }
      }
    }
  })
}

export const grpcGetFormCache = ({ id, formId, userId, onFail, onSuccess }) => {
  const request = new FormCachePointer()
  request.setUniqueid(id)
  request.setFormid(formId)
  grpc.unary(Multiuser.GetFormCacheByID, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(JSON.parse(response.message.array[2]))
        }
      }
    }
  })
}

export const tryInitiatingForm = ({
  onFail,
  onSuccess,
  formId,
  userId,
  initialValues
}) => {
  const formRequest = muFormId(formId)
  grpc.unary(Multiuser.IsFormInited, {
    request: formRequest,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        const isInited = response.message.toObject().istrue
        console.log('Is form inited?', isInited)
        if (isInited && onSuccess) {
          // onSuccess(isInited)
          grpcFetchAllUsersInfo({
            id: formId,
            userId: userId,
            onSuccess: ({ users, sessionStartTime }) => {
              getCurrentFormState({
                formId,
                userId,
                startEditingTime: sessionStartTime,
                onSuccess: response => {
                  console.log('got current form state', response)
                  onSuccess(isInited, response)
                }
              })
            }
          })

          // getNewestCacheOfForm({
          //   id: formId,
          //   userId,
          //   onSuccess: response => {
          //     console.log('got newest cache', response)
          //     onSuccess(isInited, JSON.parse(response[2]))
          //   }
          // })
        } else if (!isInited) {
          const formToInit = new Form()
          formToInit.setFormid(formId)
          formToInit.setContent(JSON.stringify(initialValues))
          grpc.unary(Multiuser.InitForm, {
            request: formToInit,
            host,
            metadata: new grpc.Metadata({
              UserID: userId
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                if (onSuccess) {
                  onSuccess(response.message)
                }
              }
            }
          })
        }
      }
    }
  })
}

export const updateLockedFieldValue = ({
  onFail,
  onSuccess,
  formId,
  fieldId,
  userId,
  lockId,
  fieldValue
}) => {
  const request = new FormAndFieldAndLockIDAndContent()
  request.setFormid(formId)
  request.setFieldvalue(JSON.stringify(fieldValue))
  request.setFieldid(fieldId)
  request.setLockid(lockId)
  grpc.unary(Multiuser.UpdateLockedFieldValue, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const commitFormCache = ({
  values,
  formId,
  userId,
  onFail,
  onSuccess
}) => {
  const toSend = _.cloneDeep(values)
  delete toSend.muInfo
  delete toSend.muUsers
  const request = new FormToSubmit()
  request.setFormid(formId)
  request.setContent(JSON.stringify(toSend))
  console.log('commiting form cache', toSend, formId, userId)
  grpc.unary(Multiuser.SubmitFormCache, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const commitChangeToMultipleFields = ({
  array,
  formId,
  userId,
  onSuccess,
  onFail
}) => {
  const request = new FormAndFieldsAndValues()
  request.setFormid(formId)
  request.setFieldsandvaluesList(
    array.map(([id, value]) => {
      const toRet = new FieldAndValue()
      toRet.setFieldid(id)
      toRet.setFieldvalue(JSON.stringify(value))
      return toRet
    })
  )
  console.log('QQQ', array)
  grpc.unary(Multiuser.MassiveCommitFieldsImmediately, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })

  // array.forEach(([id, value]) => {
  //   const request = new FormAndFieldAndFieldValue()
  //   request.setFieldid(id)
  //   request.setFormid(formId)
  //   request.setFieldvalue(JSON.stringify(value))
  //   grpc.unary(Multiuser.CommitFieldImmediately, {
  //     request,
  //     host,
  //     metadata: new grpc.Metadata({
  //       UserID: userId
  //     }),
  //     onEnd: response => {
  //       if (response.status !== grpc.Code.OK) {
  //         if (onFail) {
  //           onFail(response)
  //         }
  //       }
  //     }
  //   })
  // })
}

export const endEditingField = ({
  onFail,
  onSuccess,
  formId,
  fieldId,
  userId,
  lockId,
  fieldValue
}) => {
  console.log('end editing field', fieldId, fieldValue)
  const request = new FormAndField()
  request.setFieldid(fieldId)
  request.setFormid(formId)
  grpc.unary(Multiuser.IsFieldLocked, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      console.log('ended editing field for lock', lockId)
      console.log('field id', fieldId)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        const isLocked = Boolean(response.message.array[0])
        console.log('was it locked?', isLocked)
        if (isLocked) {
          const request = new FormAndFieldAndLockIDAndContent()
          request.setFieldid(fieldId)
          request.setFormid(formId)
          request.setFieldvalue(JSON.stringify(fieldValue))
          request.setLockid(lockId)
          grpc.unary(Multiuser.CommitLockedField, {
            request,
            host,
            metadata: new grpc.Metadata({
              UserID: userId
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                if (onSuccess) {
                  onSuccess(response.message)
                }
              }
            }
          })
        } else {
          const request = new FormAndFieldAndFieldValue()
          request.setFieldid(fieldId)
          request.setFormid(formId)
          request.setFieldvalue(JSON.stringify(fieldValue))
          grpc.unary(Multiuser.CommitFieldImmediately, {
            request,
            host,
            metadata: new grpc.Metadata({
              UserID: userId
            }),
            onEnd: response => {
              if (response.status !== grpc.Code.OK) {
                if (onFail) {
                  onFail(response)
                }
              } else {
                if (onSuccess) {
                  onSuccess(response.message)
                }
              }
            }
          })
        }
      }
    }
  })
}

export const pingServer = ({ formId, userId, onReject, onSuccess }) => {
  const request = muFormId(formId)
  grpc.unary(Multiuser.Ping, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        onReject()
      } else {
        onSuccess()
      }
    }
  })
}

export const unlockFieldWithoutChanges = ({
  lockId,
  fieldId,
  userId,
  formId,
  onFail,
  onSuccess
}) => {
  const request = new FormAndFieldAndLockID()
  request.setFieldid(fieldId)
  request.setFormid(formId)
  request.setLockid(lockId)
  grpc.unary(Multiuser.CancelLockedField, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const startEditingField = ({
  onFail,
  onSuccess,
  formId,
  fieldId,
  userId
}) => {
  const request = new FormAndField()
  request.setFieldid(fieldId)
  request.setFormid(formId)
  grpGetLockedFieldsForForm({
    formId,
    userId,
    onFail: e => {
      if (onFail) {
        onFail()
      }
    },
    onSuccess: locks => {
      let isLocked = false
      locks
        .filter(obj => obj.lockedBy === userId)
        .forEach(lockObj => {
          if (lockObj.fieldId === fieldId) {
            isLocked = true
          } else {
            unlockFieldWithoutChanges({
              lockId: lockObj.lockId,
              fieldId: lockObj.fieldId,
              formId,
              userId
            })
          }
        })

      if (!isLocked) {
        grpc.unary(Multiuser.LockField, {
          request,
          host,
          metadata: new grpc.Metadata({
            UserID: userId
          }),
          onEnd: response => {
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              if (onSuccess) {
                onSuccess(response.message)
              }
            }
          }
        })
      } else {
        console.log('field is already locked')
      }
    }
  })
}

export const sendChatMessage = ({
  message,
  formId,
  userId,
  onFail,
  onSuccess
}) => {
  const request = new ChatMessageSend()
  request.setFormid(formId)
  request.setMessagecontent(message)
  return grpc.unary(Multiuser.SendChatMessage, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  })
}

export const grpcFetchChatMessages = ({
  formId,
  userId,
  sessionStartTime,
  onFail,
  onSuccess
}) => {
  const request = new ChatMessageCollectionRequest()
  //request.setUserid(userId)
  request.setFormid(formId)
  if (sessionStartTime) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(moment.utc(sessionStartTime).unix())
    request.setFromtime(timestamp)
  }
  return grpc.unary(Multiuser.GetChatMessages, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        const { array } = response.message
        if (onSuccess) {
          onSuccess(
            array[0].map(array => {
              return {
                userId: array[1],
                recieved: array[3] && moment.unix(array[3][0]),
                text: array[2]
              }
            })
          )
        }
      }
    }
  })
}

export const grpcListenForChatMessageSent = ({ id, onEventRecieved }) => {
  const formId = muFormId(id)
  return grpc.invoke(Multiuser.UserSentChatMessage, {
    request: formId,
    host,
    onMessage: response => {
      if (onEventRecieved) {
        onEventRecieved({
          user: response.array[1],
          message: response.array[2]
        })
      }
    }
  })
}

export const grpcListenForFieldLockEvent = ({ id, onEventRecieved }) => {
  const formId = muFormId(id)
  return grpc.invoke(Multiuser.UserChangedLockFieldStatus, {
    request: formId,
    host,
    onMessage: response => {
      if (onEventRecieved) {
        const { array } = response
        onEventRecieved({
          userId: array[1],
          operation: array[3] || 0,
          changes: array[2].map(arr => ({
            fieldId: arr[0],
            lockId: arr[1],
            fieldValue: arr[2]
          }))
        })
      }
    }
  })
}

export const grpcListenForEndEditingFormEvent = ({ id, onEventRecieved }) => {
  const formId = muFormId(id)
  return grpc.invoke(Multiuser.UserExitedEditingForm, {
    request: formId,
    host,
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        onEventRecieved(response.array[1])
      }
    }
  })
}

export const grpcFetchAllUsersInfo = ({ id, onFail, userId, onSuccess }) => {
  const formId = muFormId(id)
  grpc.unary(Multiuser.GetAllConnectedUsersWithInfo, {
    request: formId,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    host,
    onEnd: response => {
      console.log('got response for all connected users', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          const users = decodeUserInfoArrayResponse(response.message.array)
          let isAnotherUser, sessionStartTime
          Object.values(users).forEach(obj => {
            if (obj.id !== userId) {
              isAnotherUser = true
            }
            if (
              !sessionStartTime ||
              moment.utc(obj.startEditingTime).isBefore(sessionStartTime)
            ) {
              sessionStartTime = moment.utc(obj.startEditingTime)
            }
          })
          onSuccess({ users, sessionStartTime, isAnotherUser })
        }
      }
    }
  })
}

export const getCurrentFormState = ({
  formId,
  userId,
  onFail,
  onSuccess,
  startEditingTime
}) => {
  let toRet = {}
  const request = new muFormId(formId)
  grpc.unary(Multiuser.GetLocksNotSubmitedToCache, {
    request,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    host,
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        const fieldLocks = response.message.array[0]
        console.log('got current field locks', fieldLocks)
        grpc.unary(Multiuser.GetNewestFormCache, {
          request,
          metadata: new grpc.Metadata({
            UserID: userId
          }),
          host,
          onEnd: response => {
            console.log('got current form state', response)
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              const { array } = response.message
              if (array[2]) {
                const data = JSON.parse(array[2])
                const submitTime = moment.unix(array[3])
                let isValid = true
                if (startEditingTime && submitTime) {
                  if (
                    moment
                      .utc(submitTime)
                      .isBefore(moment.utc(startEditingTime))
                  ) {
                    isValid = false
                  }
                }
                if (isValid) {
                  toRet = { ...toRet, ...data }
                }
              }
              fieldLocks.forEach(lock => {
                const fieldId = lock[3]
                const fieldValue = lock[4] && JSON.parse(lock[4])
                const lockCreatedTime = moment.unix(lock[8][0])
                let isValid = true

                if (startEditingTime && lockCreatedTime) {
                  if (
                    moment
                      .utc(lockCreatedTime)
                      .isBefore(moment.utc(startEditingTime))
                  ) {
                    isValid = false
                  }
                }
                if (isValid && lock[4]) {
                  toRet[fieldId] = fieldValue
                }
              })
              if (onSuccess) {
                onSuccess(toRet)
              }
            }
          }
        })
      }
    }
  })
}

export const grpcReportSFSaveResult = ({
  formId,
  userId,
  onFail,
  onSuccess,
  result,
  type
}) => {
  const request = new SaveToSFCompletedReport()
  request.setFormid(formId)
  request.setType(type)
  request.setResult(result)
  return grpc.unary(Multiuser.ReportSaveToSFCompleted, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const grpGetLockedFieldsForForm = ({
  formId,
  userId,
  onFail,
  onSuccess
}) => {
  const request = new muFormId(formId)
  return grpc.unary(Multiuser.GetLockedFields, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        const { array } = response.message
        if (onSuccess) {
          onSuccess(
            array[0].map(lock => ({
              fieldId: lock[3],
              lockId: lock[1],
              lockedBy: lock[2]
            }))
          )
        }
      }
    }
  })
}

export const grpcUpdateUserInfo = ({
  formId,
  userInfo,
  userId,
  onFail,
  onSuccess
}) => {
  const request = new FormAndUserInfo()
  request.setFormid(formId)
  request.setUserdata(userInfo)
  request.setUsername(userInfo.name)
  return grpc.unary(Multiuser.UpdateUserInfo, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess()
        }
      }
    }
  })
}

export const grpcListenForUserInfoUpdated = ({ id, onEventRecieved }) => {
  const request = muFormId(id)
  return grpc.invoke(Multiuser.UserUpdatedInfoAboutSelf, {
    request,
    host,
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        onEventRecieved({
          userId: array[1],
          info: JSON.parse(array[2])
        })
      }
    }
  })
}

export const grpcGetFieldHistory = ({
  fieldId,
  formId,
  userId,
  onSuccess,
  onFail
}) => {
  const request = new FormAndField()
  request.setFieldid(fieldId)
  request.setFormid(formId)
  return grpc.unary(Multiuser.GetLocksForField, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          const savedValues = response.message.array[0]
          onSuccess(
            savedValues.map(array => ({
              date: moment.unix(array[8][0]),
              value: array[4] && JSON.parse(array[4])
            }))
          )
        }
      }
    }
  })
}

export const grpcRequestSFSave = ({
  formId,
  userId,
  onFail,
  onSuccess,
  type
}) => {
  const request = new RequestSFSaveMessage()
  request.setFormid(formId)
  request.setType(type)
  return grpc.unary(Multiuser.RequestSFSave, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(response)
        }
      }
    }
  })
}

export const grpcListenForSFSaveRequest = ({ id, onEventRecieved }) => {
  const formId = muFormId(id)
  return grpc.invoke(Multiuser.UserRequestedSFSave, {
    request: formId,
    host,
    onMessage: response => {
      console.log('save request message', response)
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userRequesting = response.array[1]
        const requestType = response.array[3]
        const status = response.array[2]
        let canSave = true
        if (status === RequestStatus.BLOCKED) {
          canSave = false
        }
        onEventRecieved({
          canSave,
          requestType,
          userRequesting
        })
      }
    }
  })
}

export const grpcListenForSFSaveResult = ({ id, onEventRecieved }) => {
  const formId = muFormId(id)
  return grpc.invoke(Multiuser.UserCompletedSaveToSF, {
    request: formId,
    host,
    onMessage: response => {
      console.log('save result form server', response)
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userSaving = response.array[0]
        const requestType = response.array[2]
        const status = response.array[1]
        let success = true
        if (!status || status === RequestStatus.BLOCKED) {
          success = false
        }
        onEventRecieved({
          success,
          requestType,
          userSaving
        })
      }
    }
  })
}

export const getNewestCacheOfForm = ({ id, userId, onFail, onSuccess }) => {
  const formId = muFormId(id)
  grpc.unary(Multiuser.GetNewestFormCache, {
    request: formId,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    host,
    onEnd: response => {
      console.log('got current form state', response)
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message.array)
        }
      }
    }
  })
}

export const moveMouseCursor = ({
  x,
  y,
  xPercent,
  yPercent,
  userId,
  formId,
  onFail,
  onSuccess
}) => {
  const cursorEvent = new CursorEvent()
  cursorEvent.setFormid(formId)
  cursorEvent.setEventvalue(
    JSON.stringify({
      x,
      y,
      xPercent,
      yPercent
    })
  )
  grpc.unary(Multiuser.SendCursorEvent, {
    request: cursorEvent,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    host,
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message.array)
        }
      }
    }
  })
}

export const grpcListenForMouseCursorEvent = ({ id, onEventRecieved }) => {
  const formId = muFormId(id)
  return grpc.invoke(Multiuser.UserSentCursorEvent, {
    request: formId,
    host,
    onMessage: response => {
      const { array } = response
      if (onEventRecieved && array && array.length > 0) {
        const userId = response.array[2]
        const coordinates = JSON.parse(response.array[1])
        onEventRecieved({ userId, coordinates })
      }
    }
  })
}

export const grpcListenForStarEditingFormEvent = ({ id, onEventRecieved }) => {
  const formId = muFormId(id)
  return grpc.invoke(Multiuser.UserStartedEditingForm, {
    request: formId,
    host,
    onMessage: response => {
      const userResponse = response.array[2]
      if (onEventRecieved && userResponse) {
        onEventRecieved(JSON.parse(userResponse))
      }
    }
  })
}

export const grpcEndEditingForm = ({ formId, userId, onFail, onSuccess }) => {
  const request = new FormAndUserInfo()
  request.setFormid(formId)
  request.setUserdata(userId)
  grpc.unary(Multiuser.ExitEditingForm, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const grpcStartEditingForm = ({
  formId,
  userId,
  userInfo,
  onFail,
  onSuccess
}) => {
  const request = new FormAndUserInfo()
  request.setFormid(formId)
  request.setUserdata(userInfo)
  request.setUsername(userInfo.name)
  console.log('start editing form')
  grpc.unary(Multiuser.StartEditingForm, {
    request,
    host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
          if (response.trailers.has('Exception')) {
            console.log('exception caught', response.trailers.get('Exception'))
          }
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}
