import { t, Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  parseDisplayedText,
  NumberFormatDefault,
  FormColorAutocomplete,
  pdfDefaultFontSize,
  guessFieldType
} from './Common'
import ReactHtmlParser from 'react-html-parser'
import { useFormikContext } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import moment from 'moment'
import { dateFormat } from 'app/appSettings'
import {
  bilingualDateFormat,
  currencyFormatedString
} from 'app/views/common/Formats'
import { myI18n } from 'translation/I18nConnectedProvider'
import { Text } from '@react-pdf/renderer'
import Html from 'react-pdf-html'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { formObjectsToConnect } from '../FormWizard'
import { formElementTypes } from '../GroupElement'

export function FormTextWithReferences ({
  langFR,
  editor,
  titleEN,
  titleFR,
  typeProps = {},
  injectablesMap,
  connectedMap,
  describeMap,
  pdf,
  injectableId,
  objectsFieldsMap,
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    alignCenter,
    justify,
    fontSize,
    content = []
  } = typeProps

  const ContainerType = pdf ? Text : 'span'
  return (
    <ContainerType
      style={{
        backgroundColor: backgroundColor && backgroundColor.id,
        color: textColor && textColor.id,
        borderRadius: 5,
        fontSize: fontSize ? Number(fontSize) : pdf && pdfDefaultFontSize,
        textAlign: alignCenter ? 'center' : justify && 'justify',
        display: editor && 'block'
      }}
      className='break-in-print'
    >
      {content.map((obj, index) => {
        let {
          textEN,
          textFR,
          type,
          isHtml,
          cIndex = 0,
          testField,
          testValue,
          injectableElement,
          referenceObject,
          referenceField,
          referenceCollection,
          specialReference,
          wholeCollectionValue,
          textProps = []
        } = obj
        const style = {
          fontWeight: textProps.includes('bold') ? 'bold' : 400,
          fontStyle: textProps.includes('italics') ? 'italic' : 'normal',
          textDecoration: textProps.includes('underline') && 'underline',
          fontSize: fontSize ? Number(fontSize) : pdf && pdfDefaultFontSize
        }
        // if (pdf) {
        //   style.fontSize = pdfDefaultFontSize
        // }
        const ElementType = pdf ? Text : 'span'
        const translate = textProps.includes('translate')
        if (type === 'text') {
          const text = langFR ? textFR : textEN
          if (editor) {
            if (isHtml) {
              return ReactHtmlParser(text)
            } else {
              return text
            }
          }
          const parsedString = parseDisplayedText({
            text,
            french: langFR,
            returnString: true,
            injectablesMap,
            describeMap,
            objectsFieldsMap,
            renderProps: {
              connectedMap,
              injectableId
            }
          })
          return isHtml ? (
            pdf ? (
              <Html
                key={index}
                style={{
                  fontSize: fontSize ? +fontSize : pdf && pdfDefaultFontSize
                }}
              >
                {parsedString}
              </Html>
            ) : (
              <ElementType key={index}>
                {ReactHtmlParser(parsedString)}
              </ElementType>
            )
          ) : (
            <ElementType key={index} style={style}>
              {parseDisplayedText({
                text,
                french: langFR,
                injectablesMap,
                describeMap,
                objectsFieldsMap,
                renderProps: {
                  connectedMap,
                  injectableId
                }
              })}
            </ElementType>
          )
        } else if (type === 'special') {
          if (specialReference) {
            switch (specialReference) {
              case 'CURRENT_DATE':
                return (
                  <ElementType key={index} style={style}>
                    {translate
                      ? bilingualDateFormat(moment(), langFR)
                      : moment.utc().format(dateFormat)}
                  </ElementType>
                )
              default:
                return (
                  <ElementType key={index} style={style}>
                    {'NO CONDITIONS SPECIFIED FOR: ' + specialReference}
                  </ElementType>
                )
            }
          }
        } else {
          if (editor && !specialReference) {
            if (
              type === 'collectionReferece' ||
              type === 'wholeCollectionReference'
            ) {
              return (
                <ElementType key={index} style={style}>
                  COLLECTION_REFERENCE_PLACEHOLDER
                </ElementType>
              )
            }
            const reference = referenceField || injectableElement
            return (
              <ElementType key={index} style={style}>
                {'REFERENCE_PLACEHOLDER: ' + reference}
              </ElementType>
            )
          }

          if (injectableElement) {
            if (injectablesMap[injectableElement]) {
              const renderProps = {
                connectedMap,
                injectableId
              }
              if (
                renderProps &&
                renderProps.injectableId === injectableElement
              ) {
                return (
                  <ElementType key={index} style={style}>
                    {'ERROR OCURRED WHILE RENDERING. ' +
                      injectableElement +
                      'CANNOT REFERENCE ITSELF'}
                  </ElementType>
                )
              }
              const element = injectablesMap[injectableElement]
              if (
                renderProps &&
                renderProps.injectableId === element.injectableId
              ) {
                return (
                  <ElementType key={index} style={style}>
                    {'ERROR OCURRED WHILE RENDERING. ' +
                      injectableElement +
                      'CANNOT REFERENCE ITSELF'}
                  </ElementType>
                )
              } else {
                const props = {
                  pdf,
                  objectsFieldsMap,
                  injectablesMap,
                  describeMap,
                  ...renderProps,
                  ...element
                }
                if (pdf) {
                  return formElementTypes[element.elementType].formComponentPdf(
                    props
                  )
                } else {
                  return formElementTypes[element.elementType].formComponent(
                    props
                  )
                }
              }
            } else {
              return (
                <ElementType key={index} style={style}>
                  {'NO CONDITIONS SPECIFIED FOR: ' +
                    referenceField +
                    ' IN COLLECTION: ' +
                    referenceCollection}
                </ElementType>
              )
            }
          }

          let sfData = connectedMap[referenceObject]
          if (!sfData) {
            return null
          }
          let fieldName = referenceField || ''
          let sfObject = sfData.sfObject
          let collection
          let fieldData = sfData.fieldsMap[referenceField]
          if (
            referenceCollection &&
            sfData.additionalInfo[referenceCollection]
          ) {
            collection = sfData.additionalInfo[referenceCollection]
            if (collection) {
              sfObject = null
              if (type === 'wholeCollectionReference') {
                if (wholeCollectionValue) {
                  let string = ''
                  switch (wholeCollectionValue) {
                    case 'length':
                      string = String(collection.length)
                      break
                    default:
                      string =
                        'NO CONDITIONS SPECIFIED FOR COLLECTION: ' +
                        referenceCollection
                      break
                  }
                  return (
                    <ElementType key={index} style={style}>
                      {string}
                    </ElementType>
                  )
                } else {
                  return (
                    <ElementType key={index} style={style}>
                      {'NO CONDITIONS SPECIFIED FOR COLLECTION: ' +
                        referenceCollection}
                    </ElementType>
                  )
                }
              }

              if (!testValue || !testField) {
                return (
                  <ElementType key={index} style={style}>
                    {'NO CONDITIONS SPECIFIED FOR: ' +
                      referenceField +
                      ' IN COLLECTION: ' +
                      referenceCollection}
                  </ElementType>
                )
              }
              if (testValue === 'true') {
                testValue = true
              } else if (testValue === 'false') {
                testValue = false
              }
              collection = collection.filter(obj => {
                let toCheck = obj
                if (testField.indexOf('.') !== -1) {
                  const field = testField.split('.')[0]
                  const subField = testField.split('.')[1]
                  toCheck = obj[field]
                  return toCheck[subField] === testValue
                } else {
                  return toCheck[testField] === testValue
                }
              })
              if (collection.length === 1) {
                sfObject = collection[0]
              } else if (collection.length > 1) {
                if (!isNaN(+cIndex)) {
                  sfObject = collection[cIndex]
                } else {
                  return (
                    <ElementType key={index} style={style}>
                      {'NO INDEX PROVIDED FOR SPECIFIED CONDITIONS: ' +
                        testValue +
                        ' IN FIELD ' +
                        testField +
                        ' IN COLLECTION: ' +
                        referenceCollection}
                    </ElementType>
                  )
                }
              }
              if (!sfObject) {
                return (
                  <ElementType key={index} style={style}>
                    {'NOTHING FOUND FOR SPECIFIED CONDITIONS: ' +
                      testValue +
                      ' IN FIELD ' +
                      testField +
                      ' IN COLLECTION: ' +
                      referenceCollection}
                  </ElementType>
                )
              }
              const sfObjectType = sfObject.attributes.type
              sfData = describeMap[sfObjectType]
              const fieldsMap = {}
              sfData.fields.forEach(field => {
                fieldsMap[field.name] = field
              })
              fieldData = fieldsMap[fieldName]
            }
          }
          let value = sfObject[referenceField]
          if (!fieldData && fieldName.indexOf('.') !== -1) {
            const subField = fieldName.split('.')[1]
            fieldName = fieldName.split('.')[0]
            if (fieldName && subField) {
              try {
                if (sfObject[fieldName].value) {
                  value = sfObject[fieldName].value[subField]
                } else {
                  const sfObjectType = sfObject.attributes.type
                  const formSfObjectData = formObjectsToConnect[sfObjectType]
                  if (
                    formSfObjectData.selectInfo &&
                    formSfObjectData.selectInfo[referenceField]
                  ) {
                    value = sfObject[fieldName][subField]
                    fieldData = {
                      type:
                        formSfObjectData.selectInfo[referenceField] ||
                        guessFieldType(value)
                    }
                  } else {
                    const type = sfObject[fieldName].attributes.type
                    sfData = describeMap[type]
                    const fieldsMap = {}
                    sfData.fields.forEach(field => {
                      fieldsMap[field.name] = field
                    })
                    fieldData = fieldsMap[subField]
                    value = sfObject[fieldName][subField]
                  }
                }
              } catch (er) {
                return (
                  <ElementType key={index} style={style}>
                    {'NO SUBFIELD ' + subField + ' FOR FIELD: ' + fieldName}
                  </ElementType>
                )
              }
            }
          }
          if (!fieldData) {
            if (
              type === 'wholeCollectionReference' ||
              type === 'collectionReference'
            ) {
              return (
                <ElementType key={index} style={style}>
                  {'NO DATA CONFIGURED FOR COLLECTION REFERENCE'}
                </ElementType>
              )
            }
            return (
              <ElementType key={index} style={style}>
                {'NO DATA CONFIGURED FOR :' + referenceField}
              </ElementType>
            )
          }
          if (['date', 'datetime'].includes(fieldData.type)) {
            if (moment.utc(value).isValid()) {
              if (translate) {
                value = bilingualDateFormat(value, langFR)
              } else {
                value = moment.utc(value).format(dateFormat)
              }
            }
          } else if (fieldData.type === 'currency') {
            value = currencyFormatedString(value, langFR)
          } else if (['multipicklist', 'picklist'].includes(fieldData.type)) {
            if (value) {
              value = value.replaceAll(';', ', ')
            }
          } else if (fieldData.type === 'boolean') {
            if (typeof value === 'boolean') {
              value = value ? myI18n._(t`Yes`) : myI18n._(t`No`)
            }
          } else if (
            [
              'string',
              'textarea',
              'email',
              'url',
              'phone',
              'address',
              'id'
            ].includes(fieldData.type)
          ) {
            if (/<\/?[a-z][\s\S]*>/i.test(value)) {
              return pdf ? (
                <Html key={index} style={style}>
                  {value}
                </Html>
              ) : (
                ReactHtmlParser(value)
              )
            }
          }
          if (translate) {
            value = String(myI18n._(value))
          }
          return (
            <ElementType key={index} style={style}>
              {value}
            </ElementType>
          )
        }
      })}
    </ContainerType>
  )
}

export function FormEditorTextWithReferences ({
  editMode,
  injectable,
  depth,
  typeProps = {},
  showPrintProps,
  ...props
}) {
  const {
    backgroundColor,
    textColor,
    fontSize,
    content = [],
    alignCenter,
    justify,
    printPageBreakBefore
  } = typeProps
  const dispatch = useDispatch()
  const { injectableComponents } = useSelector(state => state.formEditorTree)
  const { values } = useFormikContext()
  const { objectsConnected, objects } = values

  if (!editMode) {
    return <FormTextWithReferences {...props} editor typeProps={typeProps} />
  }
  return (
    <div>
      <FormColorAutocomplete
        typeProps={typeProps}
        value={textColor}
        name='textColor'
        depth={depth}
        label={<Trans>Text color</Trans>}
        injectable={injectable}
      />

      <TextField
        label={<Trans>Font size</Trans>}
        value={fontSize || ''}
        fullWidth
        variant='outlined'
        InputProps={{ inputComponent: NumberFormatDefault }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.fontSize = e.target.value
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <FormColorAutocomplete
        typeProps={typeProps}
        value={backgroundColor}
        name='backgroundColor'
        depth={depth}
        label={<Trans>Background color</Trans>}
        injectable={injectable}
      />

      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(alignCenter)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.alignCenter = e.target.checked
                delete toSet.justify
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Align text to center</Trans>}
        />
      )}
      {!injectable && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(justify)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.justify = e.target.checked
                delete toSet.alignCenter
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Justify text</Trans>}
        />
      )}

      <Typography style={{ fontWeight: 'bold', fontSize: 21 }}>
        <Trans>Text content</Trans>
      </Typography>
      <Grid container style={{ padding: 10 }}>
        {[
          {
            label: <Trans>Add text</Trans>,
            init: {
              type: 'text',
              textEN: '',
              textFR: ''
            }
          },
          {
            label: <Trans>Add field reference</Trans>,
            init: {
              type: 'fieldReference'
            }
          },
          {
            label: <Trans>Add collection reference (single item)</Trans>,
            init: {
              type: 'collectionReference'
            }
          },
          {
            label: <Trans>Add collection reference (whole collection)</Trans>,
            init: {
              type: 'wholeCollectionReference'
            }
          },
          {
            label: <Trans>Add element reference</Trans>,
            init: {
              type: 'element'
            }
          },
          {
            label: <Trans>Add special reference</Trans>,
            init: {
              type: 'special'
            }
          }
        ].map((item, index) => (
          <Grid
            item
            style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5 }}
            key={index}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={e => {
                const toSet = { ...typeProps }
                const newContent = [...content]
                newContent.push(item.init)
                toSet.content = newContent
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            >
              <Grid container justify='center'>
                <Icon style={{ marginRight: 5 }}>add</Icon>
                {item.label}
              </Grid>
            </Button>
          </Grid>
        ))}
      </Grid>

      {content.map((obj, index) => {
        const {
          textEN,
          textFR,
          type,
          isHtml,
          cIndex,
          testField,
          testValue,
          wholeCollectionValue,
          injectableElement,
          specialReference,
          referenceObject,
          referenceField,
          referenceCollection,
          textProps = []
        } = obj
        let element
        if (type === 'text') {
          element = (
            <div>
              <TextField
                variant='outlined'
                multiline
                // rows={2}
                style={{ marginBottom: 10, marginTop: 10 }}
                label={<Trans>Text - English</Trans>}
                value={textEN || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  let v = e.currentTarget.value
                  if (
                    e.nativeEvent.inputType &&
                    e.nativeEvent.inputType.startsWith('insertFromPaste')
                  ) {
                    v = e.currentTarget.value.replace(/\r?\n|\r/g, ' ')
                  }
                  toSet.content[index].textEN = v
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                fullWidth
              />
              <TextField
                variant='outlined'
                multiline
                // rows={2}
                style={{ marginBottom: 10 }}
                label={<Trans>Text - French</Trans>}
                value={textFR || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  let v = e.currentTarget.value
                  if (
                    e.nativeEvent.inputType &&
                    e.nativeEvent.inputType.startsWith('insertFromPaste')
                  ) {
                    v = e.currentTarget.value.replace(/\r?\n|\r/g, ' ')
                  }
                  toSet.content[index].textFR = v
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                fullWidth
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(isHtml)}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.content[index].isHtml = e.target.checked
                      toSet.content[index].textProps = []
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Is html?</Trans>}
              />
            </div>
          )
        } else if (type === 'special') {
          element = (
            <TextField
              select
              variant='outlined'
              style={{ marginBottom: 10, marginTop: 10 }}
              value={specialReference || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.content[index].specialReference = e.target.value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
              fullWidth
            >
              {[
                {
                  label: <Trans>Current date</Trans>,
                  value: 'CURRENT_DATE'
                }
              ].map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )
        } else if (type === 'element') {
          element = (
            <TextField
              select
              label={<Trans>Referenced element</Trans>}
              fullWidth
              variant='outlined'
              defaultValue=''
              value={injectableElement || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.content[index].injectableElement = e.target.value
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            >
              {injectableComponents
                .filter(item => item.injectableId)
                .map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.injectableId}>
                      {item.injectableId}
                    </MenuItem>
                  )
                })}
            </TextField>
          )
        } else {
          const avaliableObjectsMap = {}
          objectsConnected.forEach(obj => {
            avaliableObjectsMap[obj.identId] = obj
          })
          let options = []
          let relatedCollections = []
          let objData
          referenceObject &&
            objects.some(obj => {
              if (!avaliableObjectsMap[referenceObject]) {
                return false
              }
              const bool =
                obj.name === avaliableObjectsMap[referenceObject].type
              if (bool) {
                objData = formObjectsToConnect[obj.name]
                relatedCollections = obj.relatedCollections
                if (referenceCollection) {
                  relatedCollections.some(collection => {
                    if (collection.key === referenceCollection) {
                      options = collection.fields
                    }
                    return collection.key === referenceCollection
                  })
                } else if (type !== 'collectionReference') {
                  options = obj.fields
                }
              }
              return bool
            })
          options = options.filter(item =>
            [
              'string',
              'textarea',
              'email',
              'url',
              'phone',
              'address',
              'id',
              'int',
              'double',
              'multipicklist',
              'picklist',
              'currency',
              'date',
              'datetime',
              'boolean'
            ].includes(item.type)
          )
          const optionsMap = {}
          const optionsFromSelectFields = []
          if (objData && objData.select) {
            objData.select.forEach(sel => {
              if (!sel.includes('*')) {
                optionsFromSelectFields.push({
                  name: sel,
                  label: sel
                })
              }
            })
          }
          const allOptions = [...options, ...optionsFromSelectFields]
          allOptions.forEach(opt => {
            optionsMap[opt.name] = opt
          })
          const collectionsMap = {}
          relatedCollections.forEach(opt => {
            collectionsMap[opt.key] = opt.label
          })
          const testFieldData = optionsMap[testField]

          element = (
            <Grid container>
              <Grid item xs={6} style={{ padding: 5 }}>
                <TextField
                  select
                  label={<Trans>Connected object</Trans>}
                  fullWidth
                  variant='outlined'
                  defaultValue=''
                  value={referenceObject || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.content[index].referenceObject = e.target.value
                    delete toSet.content[index].referenceField
                    delete toSet.content[index].referenceCollection
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                >
                  {objectsConnected
                    .filter(item => item.name)
                    .map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.identId}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                </TextField>
              </Grid>

              {type === 'fieldReference' && (
                <Grid item xs={6} style={{ padding: 5 }}>
                  <Autocomplete
                    freeSolo={false}
                    value={referenceField || ''}
                    onChange={(e, value) => {
                      const toSet = { ...typeProps }
                      toSet.content[index].referenceField = value
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                    fullWidth
                    options={allOptions.map(item => item.name)}
                    // getOptionSelected={(option, value) => value === option.id}
                    getOptionLabel={o => optionsMap[o]?.label || ''}
                    renderInput={params => (
                      <TextField
                        variant='outlined'
                        {...params}
                        label={<Trans>Connected field</Trans>}
                      />
                    )}
                  />
                </Grid>
              )}

              {(type === 'collectionReference' ||
                type === 'wholeCollectionReference') && (
                <Grid item xs={6} style={{ padding: 5 }}>
                  <Autocomplete
                    freeSolo={false}
                    value={referenceCollection || ''}
                    onChange={(e, value) => {
                      const toSet = { ...typeProps }
                      toSet.content[index].referenceCollection = value
                      delete toSet.content[index].referenceField
                      delete toSet.content[index].testField
                      delete toSet.content[index].testValue
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                    fullWidth
                    options={relatedCollections.map(item => item.key)}
                    // getOptionSelected={(option, value) => value === option.id}
                    getOptionLabel={o => collectionsMap[o] || ''}
                    renderInput={params => (
                      <TextField
                        variant='outlined'
                        {...params}
                        label={<Trans>Referenced collection</Trans>}
                      />
                    )}
                  />
                </Grid>
              )}
              {type === 'collectionReference' && (
                <>
                  <Grid item xs={6} style={{ padding: 5 }}>
                    {testFieldData &&
                    testFieldData.picklistValues.length > 0 ? (
                      <TextField
                        label={<Trans>Condition value</Trans>}
                        select
                        fullWidth
                        variant='outlined'
                        value={testValue || ''}
                        onChange={e => {
                          const toSet = { ...typeProps }
                          toSet.content[index].testValue = e.target.value
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        {testFieldData.picklistValues
                          .filter(obj => obj.active)
                          .map((obj, index) => (
                            <MenuItem value={obj.value} key={index}>
                              {obj.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={<Trans>Condition value</Trans>}
                        fullWidth
                        variant='outlined'
                        value={testValue || ''}
                        onChange={e => {
                          const toSet = { ...typeProps }
                          toSet.content[index].testValue = e.target.value
                          dispatch({
                            type: 'FIELD',
                            injectable,
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      />
                    )}
                  </Grid>
                  <Grid xs={6} item style={{ padding: 5 }}>
                    <Autocomplete
                      freeSolo={false}
                      value={testField || ''}
                      onChange={(e, value) => {
                        const toSet = { ...typeProps }
                        toSet.content[index].testField = value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      fullWidth
                      options={options.map(item => item.name)}
                      getOptionLabel={o => optionsMap[o]?.label || ''}
                      renderInput={params => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label={<Trans>Condition field</Trans>}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs style={{ padding: 5 }}>
                    <TextField
                      label={<Trans>Index</Trans>}
                      fullWidth
                      variant='outlined'
                      value={cIndex || ''}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.content[index].cIndex = e.target.value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      inputProps={{
                        maxLength: 3
                      }}
                      InputProps={{
                        inputComponent: NumberFormatDefault
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TooltipLabelIcon
                      tooltip={
                        <Trans>
                          If more than one collection element will be found for
                          specified conditions the element at this index will be
                          selected if able
                        </Trans>
                      }
                    />
                  </Grid>
                  <Grid xs={6} item style={{ padding: 5 }}>
                    <Autocomplete
                      freeSolo={false}
                      value={referenceField || ''}
                      onChange={(e, value) => {
                        const toSet = { ...typeProps }
                        toSet.content[index].referenceField = value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      fullWidth
                      options={options.map(item => item.name)}
                      // getOptionSelected={(option, value) => value === option.id}
                      getOptionLabel={o => optionsMap[o]?.label || ''}
                      renderInput={params => (
                        <TextField
                          variant='outlined'
                          {...params}
                          label={<Trans>Connected field</Trans>}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {type === 'wholeCollectionReference' && (
                <>
                  <Grid item xs={12} style={{ padding: 5 }}>
                    <TextField
                      label={<Trans>Whole collection reference</Trans>}
                      select
                      fullWidth
                      variant='outlined'
                      value={wholeCollectionValue || 'none'}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.content[index].wholeCollectionValue =
                          e.target.value
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    >
                      <MenuItem value='none' key={1}>
                        <Trans>None</Trans>
                      </MenuItem>
                      <MenuItem value='length' key={2}>
                        <Trans>Collection length</Trans>
                      </MenuItem>
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
          )
        }

        return (
          <div key={index} style={{ padding: 5 }}>
            <Grid container justify='center' alignItems='center'>
              <Grid item xs>
                <b>{Number(index + 1) + '. '}</b>
                {type === 'text' && <Trans>Text</Trans>}
                {type === 'fieldReference' && <Trans>Field reference</Trans>}
                {type === 'collectionReference' && (
                  <Trans>Collection reference</Trans>
                )}
                {type === 'element' && <Trans>Element reference</Trans>}
                {type === 'special' && <Trans>Special reference</Trans>}
              </Grid>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.content[index]
                  const toReplace = toSet.content[index + 1]
                  toSet.content[index + 1] = toMove
                  toSet.content[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === content.length - 1}
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.content[index]
                  const toReplace = toSet.content[index - 1]
                  toSet.content[index - 1] = toMove
                  toSet.content[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === 0}
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
              <IconButton
                onClick={e => {
                  const toSet = { ...typeProps }
                  toSet.content.splice(index, 1)
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
            {element}
            {!isHtml && type !== 'element' && (
              <FormGroup row>
                {[
                  { key: 'bold', label: <Trans>Bold</Trans> },
                  { key: 'italics', label: <Trans>Italics</Trans> },
                  { key: 'underline', label: <Trans>Underline</Trans> },
                  { key: 'translate', label: <Trans>Translate</Trans> }
                ]
                  .filter(
                    item => !(type === 'text' && item.key === 'translate')
                  )
                  .map(item => (
                    <FormControlLabel
                      key={item.key}
                      control={
                        <Checkbox
                          checked={textProps.includes(item.key)}
                          value={item.key}
                          onChange={e => {
                            const v = e.target.value
                            const toSet = { ...typeProps }
                            const newTextProps = [...textProps]
                            if (newTextProps.includes(v)) {
                              newTextProps.splice(newTextProps.indexOf(v), 1)
                            } else {
                              newTextProps.push(v)
                            }
                            toSet.content[index].textProps = newTextProps
                            dispatch({
                              type: 'FIELD',
                              injectable,
                              depth: depth.split('.'),
                              fieldName: 'typeProps',
                              fieldValue: { ...toSet }
                            })
                          }}
                        />
                      }
                      label={item.label}
                    />
                  ))}
              </FormGroup>
            )}
          </div>
        )
      })}
    </div>
  )
}
