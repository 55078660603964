import { TextField } from '@material-ui/core'
import React from 'react'
import HomepageButton from '../../grants/HomepageButton'
import { Trans } from '@lingui/macro'
import { useDispatch } from 'react-redux'

export const FormHomePageButton = ({ titleEN, titleFR, french, typeProps: { url, color }, ...props }) => {
  console.log('FormHomePageButton', url, props)
  return (
    <div>
      <HomepageButton text={french ? titleFR : titleEN} link={true} url={url} color={color}/>
    </div>
  )
}

export const FormEditorHomePageButton = ({ editMode, depth, typeProps, ...props }) => {
  const dispatch = useDispatch()
  if (!editMode) {
    return <FormHomePageButton typeProps={typeProps} {...props} />
  }
  const { url, color } = typeProps
  return <div>
    <TextField
      label={<Trans>URL</Trans>}
      fullWidth
      value={url}
      variant="outlined"
      onChange={e => {
        const toSet = { ...typeProps }
        toSet.url = e.target.value
        dispatch({
          type: 'FIELD',
          depth: depth.split('.'),
          fieldName: 'typeProps',
          fieldValue: { ...toSet }
        })
      }}
    />
    <TextField
      label={<Trans>Color</Trans>}
      fullWidth
      value={color}
      variant="outlined"
      onChange={e => {
        const toSet = { ...typeProps }
        toSet.color = e.target.value
        dispatch({
          type: 'FIELD',
          depth: depth.split('.'),
          fieldName: 'typeProps',
          fieldValue: { ...toSet }
        })
      }}
    />
  </div>
}
