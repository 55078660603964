import { Trans } from '@lingui/macro'
import { Slider, Grid } from '@material-ui/core'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import React from 'react'
import NumberFormat from 'react-number-format'

export const SURVEY_SLIDER_DEFAULT_MIN = 0
export const SURVEY_SLIDER_DEFAULT_MAX = 100

export default function SurveyCreationSliderFields ({
  question,
  index,
  setFieldValue
}) {
  return (
    <Grid container direction='row'>
      <Grid xs={6} item style={{ padding: 8 }}>
        <FormikTextField
          defaultValue={SURVEY_SLIDER_DEFAULT_MIN}
          name={`created.${index}.min`}
          variant='outlined'
          //   inputProps={{ currentMax: question.max || SURVEY_SLIDER_DEFAULT_MAX }}
          InputProps={{
            inputComponent: SliderValueFormat
          }}
          label={<Trans>Minimum slider value</Trans>}
          onChange={e => {
            const curr = Number(e.target.value)
            const max = Number(question.max)
            if (curr > max || !question.max) {
              setFieldValue(`created.${index}.max`, curr + 1)
            }
          }}
          fullWidth
        />
      </Grid>
      <Grid xs={6} item style={{ padding: 8 }}>
        <FormikTextField
          defaultValue={SURVEY_SLIDER_DEFAULT_MAX}
          name={`created.${index}.max`}
          variant='outlined'
          // inputProps={{ currentMin: question.min || SURVEY_SLIDER_DEFAULT_MIN }}
          InputProps={{
            inputComponent: SliderValueFormat
          }}
          label={<Trans>Maximum slider value</Trans>}
          onChange={e => {
            const curr = Number(e.target.value)
            const min = Number(question.min)
            if (min > curr || !question.min) {
              setFieldValue(`created.${index}.min`, curr - 1)
            }
          }}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

// function SliderMaxValueFormat (props) {
//   const { inputRef, onChange, currentMin, ...other } = props
//   return (
//     <NumberFormat
//       {...other}
//       getInputRef={inputRef}
//       onValueChange={values => {
//         onChange({
//           target: {
//             name: props.name,
//             value: values.value
//           }
//         })
//       }}
//       isAllowed={({ floatValue }) => {
//         if (!floatValue) {
//           return true
//         }
//         return floatValue > currentMin
//       }}
//       allowNegative={false}
//       isNumericString={false}
//     />
//   )
// }

function SliderValueFormat (props) {
  const { inputRef, onChange, currentMax, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      allowNegative={false}
      isNumericString={false}
    />
  )
}
