import { t, Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import { useFormikContext } from 'formik'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { useDispatch } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { muiDateCompare } from 'utils'
import {
  commonPdfStyles,
  FormColorAutocomplete,
  FormConnectToObject,
  NumberFormatDefault,
  parseSfFieldValue,
  pdfDefaultFontSize
} from '../Common'

const pdfStyles = StyleSheet.create({
  headerCell: {
    // textAlign: 'center',
    // fontWeight: 'bold',
    padding: 10,
    fontSize: pdfDefaultFontSize,
    flex: 1,
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  },
  cell: {
    padding: 10,
    fontSize: pdfDefaultFontSize,
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  }
})

export const FormTablePdf = ({
  value,
  title,
  langFR,
  connectedMap,
  typeProps,
  ...props
}) => {
  const {
    connectedTo = [],
    columns = [],
    pdfTableFontSize,
    pdfTableTitleFontSize,
    pdfTableTitleTextAlign,
    pdfTableTitleProps = []
  } = typeProps
  let collection = []
  const collectionKey = connectedTo[0]?.connectedCollection
  const connectedObject = connectedTo[0]?.connectedObject
  if (collectionKey && connectedObject) {
    collection =
      connectedMap[connectedObject]?.additionalInfo[collectionKey] || []
  }

  const titleStyle = {
    fontSize: pdfDefaultFontSize,
    textAlign: pdfTableTitleTextAlign || 'center'
  }
  if (pdfTableTitleProps.includes('bold')) {
    titleStyle.fontWeigth = 'bold'
  }
  if (pdfTableTitleProps.includes('italics')) {
    titleStyle.fontStyle = 'italic'
  }
  if (pdfTableTitleProps.includes('underline')) {
    titleStyle.textDecoration = 'underline'
  }
  if (pdfTableTitleFontSize) {
    titleStyle.fontSize = +pdfTableTitleFontSize
  }
  const paddingKeys = ['Left', 'Right', 'Top', 'Bottom']
  paddingKeys.forEach(key => {
    const fullKey = 'pdfTableTitlePadding' + key
    if (typeProps[fullKey]) {
      titleStyle['padding' + key] = Number(typeProps[fullKey])
    }
  })

  return (
    <View>
      <Text style={titleStyle}>{title}</Text>
      <View style={commonPdfStyles.row}>
        {columns.map((column, index) => {
          const {
            pdfHeaderTextProps = [],
            pdfColumnFlex,
            pdfBackgroundColor,
            pdfHeaderTextAlign,
            pdfFontSize,
            labelEN,
            labelFR
          } = column
          const headerStyle = {
            ...pdfStyles.headerCell,
            flex: pdfColumnFlex ? Number(pdfColumnFlex) : 1,
            textAlign: pdfHeaderTextAlign || 'center'
          }
          if (pdfHeaderTextProps.includes('bold')) {
            headerStyle.fontWeigth = 'bold'
          }
          if (pdfHeaderTextProps.includes('italics')) {
            headerStyle.fontStyle = 'italic'
          }
          if (pdfHeaderTextProps.includes('underline')) {
            headerStyle.textDecoration = 'underline'
          }
          if (pdfBackgroundColor) {
            headerStyle.backgroundColor = pdfBackgroundColor.id
          }
          if (pdfFontSize) {
            headerStyle.fontSize = +pdfFontSize
          }
          return (
            <View key={index} style={headerStyle}>
              <Text>{langFR ? labelFR : labelEN}</Text>
            </View>
          )
        })}
      </View>
      {collection.map((item, index) => {
        return (
          <View style={commonPdfStyles.row} key={index}>
            {columns.map((column, index) => {
              const {
                field,
                pdfCellTextAlign,
                translate,
                pdfColumnFlex
              } = column
              const cellStyle = {
                ...pdfStyles.cell,
                flex: pdfColumnFlex ? Number(pdfColumnFlex) : 1,
                textAlign: pdfCellTextAlign || 'center',
                display: 'flex',
                justifyContent: 'center'
                // alignItems: 'center'
              }
              if (pdfTableFontSize) {
                cellStyle.fontSize = +pdfTableFontSize
              }
              if (!field.name) {
                return (
                  <View key={index} style={cellStyle}>
                    <Text>NO FIELD CONNECTED</Text>
                  </View>
                )
              }
              const fieldValue = item[field.name]
              return (
                <View key={index} style={cellStyle}>
                  <Text>
                    {parseSfFieldValue({
                      value: fieldValue,
                      type: field.type,
                      french: langFR,
                      translate
                    })}
                  </Text>
                </View>
              )
            })}
          </View>
        )
      })}
    </View>
  )
}

export const FormTable = ({ title, langFR, connectedMap, typeProps }) => {
  const { connectedTo = [], columns = [] } = typeProps
  let collection = []
  const collectionKey = connectedTo[0]?.connectedCollection
  const connectedObject = connectedTo[0]?.connectedObject
  if (collectionKey && connectedObject && connectedMap) {
    collection =
      connectedMap[connectedObject]?.additionalInfo[collectionKey] || []
  }

  return (
    <MUIDataTable
      title={title}
      data={collection.map((obj, index) => {
        const toRet = {}
        columns.forEach(column => {
          const { field, translate } = column
          if (field.name) {
            const fieldValue = obj[field.name]
            toRet[field.name] = parseSfFieldValue({
              value: fieldValue,
              type: field.type,
              french: langFR,
              translate
            })
          }
        })
        return toRet
      })}
      options={{
        textLabels: muiTextLabels(myI18n),
        selectableRows: 'none',
        // print: false,
        // download: false,
        viewColumns: false
      }}
      columns={columns
        .map((column, index) => {
          const { labelEN, labelFR, field } = column
          const label = langFR ? labelFR : labelEN
          return {
            name: field.name,
            label: label,
            options: {
              customHeadLabelRender: props => {
                return (
                  <div style={{ marginLeft: index === 0 && 20 }}>{label}</div>
                )
              },
              sortCompare: field.type === 'date' && muiDateCompare(),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div style={{ marginLeft: index === 0 && 20 }}>{value}</div>
                )
              }
            }
          }
        })
        .filter(column => column.name)}
    />
  )
}

export const FormEditorTable = ({
  depth,
  showPdfProps,
  typeProps,
  editMode,
  ...props
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { objects, objectsConnected } = values
  const {
    connectedTo = [],
    columns = [],
    pdfTableFontSize,
    pdfTableTitleFontSize,
    pdfTableTitleTextAlign,
    pdfTableTitleProps = []
  } = typeProps

  if (!editMode) {
    return <FormTable editMode typeProps={typeProps} {...props} />
  }

  let options = []
  if (connectedTo.length > 0) {
    const connectedObject = connectedTo[0].connectedObject
    const collectionKey = connectedTo[0]?.connectedCollection
    const avaliableObjectsMap = {}
    objectsConnected.forEach(obj => {
      avaliableObjectsMap[obj.identId] = obj
    })
    connectedObject &&
      objects.some(obj => {
        if (!avaliableObjectsMap[connectedObject]) {
          return false
        }
        const bool = obj.name === avaliableObjectsMap[connectedObject].type
        if (bool) {
          const { relatedCollections } = obj
          relatedCollections.some(collection => {
            if (collection.key === collectionKey) {
              options = collection.fields
            }
            return collection.key === collectionKey
          })
        }
        return bool
      })
  }
  const optionsMap = {}
  options.forEach(opt => {
    optionsMap[opt.name] = opt
  })

  return (
    <div>
      <Grid container style={{ padding: 5 }} alignItems='center'>
        <Typography variant='h6' style={{ marginRight: 20 }}>
          <Trans>Columns</Trans>
        </Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={e => {
            const toSet = { ...typeProps }
            toSet.columns = [...columns]
            toSet.columns.push({
              labelEN: '',
              labelFR: '',
              field: '',
              pdfHeaderTextProps: []
            })
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        >
          <Grid container alignItems='center'>
            <Trans>Add column</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
      </Grid>

      {columns.map((column, index) => {
        const {
          labelEN,
          labelFR,
          field,
          translate,
          pdfColumnFlex,
          pdfHeaderTextProps = [],
          pdfHeaderTextAlign,
          pdfCellTextAlign,
          pdfBackgroundColor,
          pdfFontSize
        } = column
        return (
          <div key={index}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs style={{ marginLeft: 20 }}>
                <b>{index + 1 + '. '}</b>
              </Grid>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.columns[index]
                  const toReplace = toSet.columns[index + 1]
                  toSet.columns[index + 1] = toMove
                  toSet.columns[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === columns.length - 1}
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                size='small'
                onClick={e => {
                  const toSet = { ...typeProps }
                  const toMove = toSet.columns[index]
                  const toReplace = toSet.columns[index - 1]
                  toSet.columns[index - 1] = toMove
                  toSet.columns[index] = toReplace
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                disabled={index === 0}
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
              <IconButton
                onClick={e => {
                  const toSet = { ...typeProps }
                  toSet.columns.splice(index, 1)
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
            <Grid container>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  label={<Trans>Header label - english</Trans>}
                  fullWidth
                  variant='outlined'
                  value={labelEN || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.columns[index].labelEN = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  label={<Trans>Header label - french</Trans>}
                  fullWidth
                  variant='outlined'
                  value={labelFR || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.columns[index].labelFR = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
            </Grid>
            <Autocomplete
              style={{ padding: 5 }}
              freeSolo={false}
              value={field?.name || ''}
              onChange={(e, value) => {
                const toSet = { ...typeProps }
                toSet.columns[index].field = {
                  name: value.name,
                  type: value.type
                }
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              fullWidth
              options={options}
              getOptionSelected={(o, v) => o.name === v}
              getOptionLabel={o => {
                if (typeof o === 'object') {
                  return o.label || ''
                } else {
                  return optionsMap[o]?.label || ''
                }
              }}
              renderInput={params => (
                <TextField
                  variant='outlined'
                  {...params}
                  label={<Trans>Column field</Trans>}
                />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ marginLeft: 10 }}
                  checked={Boolean(translate)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.columns[index].translate = e.target.checked
                    delete toSet.justify
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Translate field</Trans>}
            />
            {showPdfProps && (
              <div style={{ padding: 10 }}>
                <div style={{ padding: 5 }}>
                  <Typography style={{ marginBottom: 10, fontSize: 16 }}>
                    <Trans>Cell</Trans> - <Trans>Pdf props</Trans>
                  </Typography>
                  <FormControl>
                    <FormLabel>
                      <Trans>Text align</Trans>
                    </FormLabel>
                    <RadioGroup
                      value={pdfCellTextAlign || 'center'}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.columns[index].pdfCellTextAlign = e.target.value
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      row
                    >
                      {[
                        { label: <Trans>Left</Trans>, value: 'left' },
                        { label: <Trans>Center</Trans>, value: 'center' },
                        { label: <Trans>Right</Trans>, value: 'right' }
                      ].map((obj, index) => (
                        <FormControlLabel
                          key={index}
                          value={obj.value}
                          control={
                            <Radio checked={pdfCellTextAlign === obj.value} />
                          }
                          label={obj.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>

                <div style={{ padding: 5 }}>
                  <Typography style={{ marginBottom: 10, fontSize: 16 }}>
                    <Trans>Header</Trans> - <Trans>Pdf props</Trans>
                  </Typography>

                  <Grid container>
                    <Grid item xs style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <TextField
                        label={<Trans>Font size</Trans>}
                        fullWidth
                        variant='outlined'
                        value={pdfFontSize || ''}
                        onChange={e => {
                          const toSet = { ...typeProps }
                          toSet.columns[index].pdfFontSize = e.target.value
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                        inputProps={{
                          maxLength: 2
                        }}
                        InputProps={{
                          inputComponent: NumberFormatDefault
                        }}
                      />
                    </Grid>
                    <Grid item xs style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <TextField
                        label={<Trans>Column size</Trans>}
                        fullWidth
                        variant='outlined'
                        value={pdfColumnFlex || '1'}
                        onChange={e => {
                          const toSet = { ...typeProps }
                          toSet.columns[index].pdfColumnFlex = e.target.value
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                        inputProps={{
                          maxLength: 2
                        }}
                        InputProps={{
                          inputComponent: NumberFormatDefault
                        }}
                      />
                    </Grid>
                  </Grid>

                  <FormGroup row>
                    {[
                      { key: 'bold', label: <Trans>Bold</Trans> },
                      { key: 'italics', label: <Trans>Italics</Trans> },
                      { key: 'underline', label: <Trans>Underline</Trans> }
                    ].map(item => (
                      <FormControlLabel
                        key={item.key}
                        control={
                          <Checkbox
                            checked={pdfHeaderTextProps.includes(item.key)}
                            value={item.key}
                            onChange={e => {
                              const v = e.target.value
                              const toSet = { ...typeProps }
                              const newTextProps = [...pdfHeaderTextProps]
                              if (newTextProps.includes(v)) {
                                newTextProps.splice(newTextProps.indexOf(v), 1)
                              } else {
                                newTextProps.push(v)
                              }
                              toSet.columns[
                                index
                              ].pdfHeaderTextProps = newTextProps
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'typeProps',
                                fieldValue: { ...toSet }
                              })
                            }}
                          />
                        }
                        label={item.label}
                      />
                    ))}
                  </FormGroup>

                  <FormControl>
                    <FormLabel>
                      <Trans>Text align</Trans>
                    </FormLabel>
                    <RadioGroup
                      value={pdfHeaderTextAlign || 'center'}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.columns[index].pdfHeaderTextAlign = e.target.value
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                      row
                    >
                      {[
                        { label: <Trans>Left</Trans>, value: 'left' },
                        { label: <Trans>Center</Trans>, value: 'center' },
                        { label: <Trans>Right</Trans>, value: 'right' }
                      ].map((obj, index) => (
                        <FormControlLabel
                          key={index}
                          value={obj.value}
                          control={
                            <Radio checked={pdfHeaderTextAlign === obj.value} />
                          }
                          label={obj.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormColorAutocomplete
                    depth={depth}
                    fieldArray='columns'
                    fieldArrayIndex={index}
                    label={<Trans>Cell background color</Trans>}
                    name='pdfBackgroundColor'
                    typeProps={typeProps}
                    value={pdfBackgroundColor}
                  />
                </div>
              </div>
            )}
          </div>
        )
      })}
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        connectToCollection
        disableMultiple
        clearOnChange={['columns']}
        clearOnDisconnect={['columns']}
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Table</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant='outlined'
              value={pdfTableFontSize || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.pdfTableFontSize = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              inputProps={{
                maxLength: 2
              }}
              InputProps={{
                inputComponent: NumberFormatDefault
              }}
            />

            <Typography
              style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}
            >
              <Trans>Table title</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant='outlined'
              value={pdfTableTitleFontSize || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.pdfTableTitleFontSize = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              inputProps={{
                maxLength: 2
              }}
              InputProps={{
                inputComponent: NumberFormatDefault
              }}
            />
            <FormGroup row>
              {[
                { key: 'bold', label: <Trans>Bold</Trans> },
                { key: 'italics', label: <Trans>Italics</Trans> },
                { key: 'underline', label: <Trans>Underline</Trans> }
              ].map(item => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfTableTitleProps.includes(item.key)}
                      value={item.key}
                      onChange={e => {
                        const v = e.target.value
                        const toSet = { ...typeProps }
                        const newTextProps = [...pdfTableTitleProps]
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1)
                        } else {
                          newTextProps.push(v)
                        }
                        toSet.pdfTableTitleProps = newTextProps
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormControl>
              <FormLabel>
                <Trans>Text align</Trans>
              </FormLabel>
              <RadioGroup
                value={pdfTableTitleTextAlign || 'left'}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.pdfTableTitleTextAlign = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                row
              >
                {[
                  { label: <Trans>Left</Trans>, value: 'left' },
                  { label: <Trans>Center</Trans>, value: 'center' },
                  { label: <Trans>Right</Trans>, value: 'right' }
                ].map((obj, index) => (
                  <FormControlLabel
                    key={index}
                    value={obj.value}
                    control={
                      <Radio
                        checked={
                          (!pdfTableTitleTextAlign && obj.value === 'left') ||
                          pdfTableTitleTextAlign === obj.value
                        }
                      />
                    }
                    label={obj.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Grid container>
              {[
                {
                  key: 'pdfTableTitlePaddingLeft',
                  label: <Trans>Padding left</Trans>
                },
                {
                  key: 'pdfTableTitlePaddingRight',
                  label: <Trans>Padding right</Trans>
                },
                {
                  key: 'pdfTableTitlePaddingTop',
                  label: <Trans>Padding top</Trans>
                },
                {
                  key: 'pdfTableTitlePaddingBottom',
                  label: <Trans>Padding bottom</Trans>
                }
              ].map((obj, index) => {
                return (
                  <Grid item xs key={index} style={{ padding: 5 }}>
                    <TextField
                      style={{ marginBottom: 10 }}
                      label={obj.label}
                      value={typeProps[obj.key] || '0'}
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatDefault
                      }}
                      variant='outlined'
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet[obj.key] = e.target.value
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </div>
      )}
    </div>
  )
}
