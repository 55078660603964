/**
 * Created by Michał Stawski on 20.08.2020.
 */
import { mapSFToForm, reverseMap } from '../sfDataService'
import SFAuthService from '..//SFAuthService'
import { NO_USER } from '../SFAuthService'
import { getAccount } from './sfAccount'
import sfOauthConfig from '../sfAuthConfig'
import { fetchConfiguration, fetchFormPage } from './sfForms'
import { checkFormValidity } from 'app/views/forms/Form'
import { constructFormAddressString } from 'app/views/forms/components/Common'

/*
let i = {
  'attributes': { 'type': 'User', 'url': '/services/data/v42.0/sobjects/User/0054m000000KIPHAA4' },
  'Id': '0054m000000KIPHAA4',
  'Username': 'externaluser1595852970992@company.com',
  'LastName': 'ExternalUser1595852970992',
  'FirstName': null,
  'Name': 'ExternalUser1595852970992',
  'CompanyName': null,
  'Division': null,
  'Department': null,
  'Title': null,
  'Street': null,
  'City': null,
  'State': null,
  'PostalCode': null,
  'Country': null,
  'Latitude': null,
  'Longitude': null,
  'GeocodeAccuracy': null,
  'Address': null,
  'Email': 'apicommunity+2@magnaro.com',
  'EmailPreferencesAutoBcc': true,
  'EmailPreferencesAutoBccStayInTouch': false,
  'EmailPreferencesStayInTouchReminder': true,
  'SenderEmail': null,
  'SenderName': null,
  'Signature': null,
  'StayInTouchSubject': null,
  'StayInTouchSignature': null,
  'StayInTouchNote': null,
  'Phone': null,
  'Fax': null,
  'MobilePhone': null,
  'Alias': '52970992',
  'CommunityNickname': 'ExternalUser1595852970992',
  'BadgeText': 'Partner',
  'IsActive': true,
  'TimeZoneSidKey': 'GMT',
  'LocaleSidKey': 'en_CA',
  'ReceivesInfoEmails': false,
  'ReceivesAdminInfoEmails': false,
  'EmailEncodingKey': 'ISO-8859-1',
  'UserType': 'PowerPartner',
  'LanguageLocaleKey': 'en_US',
  'EmployeeNumber': null,
  'DelegatedApproverId': null,
  'ManagerId': null,
  'LastLoginDate': '2020-08-20T03:48:11.000+0000',
  'CreatedDate': '2020-07-27T12:29:31.000+0000',
  'CreatedById': '0053j00000A5LriAAF',
  'LastModifiedDate': '2020-07-27T12:29:31.000+0000',
  'LastModifiedById': '0053j00000A5LriAAF',
  'SystemModstamp': '2020-08-19T14:54:05.000+0000',
  'OfflineTrialExpirationDate': null,
  'OfflinePdaTrialExpirationDate': null,
  'UserPermissionsMarketingUser': false,
  'UserPermissionsOfflineUser': false,
  'UserPermissionsAvantgoUser': false,
  'UserPermissionsCallCenterAutoLogin': false,
  'UserPermissionsMobileUser': false,
  'UserPermissionsSFContentUser': false,
  'UserPermissionsInteractionUser': false,
  'UserPermissionsSupportUser': false,
  'ForecastEnabled': false,
  'UserPreferencesActivityRemindersPopup': false,
  'UserPreferencesEventRemindersCheckboxDefault': false,
  'UserPreferencesTaskRemindersCheckboxDefault': false,
  'UserPreferencesReminderSoundOff': false,
  'UserPreferencesDisableAllFeedsEmail': false,
  'UserPreferencesDisableFollowersEmail': false,
  'UserPreferencesDisableProfilePostEmail': false,
  'UserPreferencesDisableChangeCommentEmail': false,
  'UserPreferencesDisableLaterCommentEmail': false,
  'UserPreferencesDisProfPostCommentEmail': false,
  'UserPreferencesHideCSNGetChatterMobileTask': false,
  'UserPreferencesDisableMentionsPostEmail': false,
  'UserPreferencesDisMentionsCommentEmail': false,
  'UserPreferencesHideCSNDesktopTask': false,
  'UserPreferencesHideChatterOnboardingSplash': false,
  'UserPreferencesHideSecondChatterOnboardingSplash': false,
  'UserPreferencesDisCommentAfterLikeEmail': false,
  'UserPreferencesDisableLikeEmail': true,
  'UserPreferencesSortFeedByComment': true,
  'UserPreferencesDisableMessageEmail': false,
  'UserPreferencesDisableBookmarkEmail': false,
  'UserPreferencesDisableSharePostEmail': false,
  'UserPreferencesEnableAutoSubForFeeds': false,
  'UserPreferencesDisableFileShareNotificationsForApi': false,
  'UserPreferencesShowTitleToExternalUsers': true,
  'UserPreferencesShowManagerToExternalUsers': false,
  'UserPreferencesShowEmailToExternalUsers': false,
  'UserPreferencesShowWorkPhoneToExternalUsers': false,
  'UserPreferencesShowMobilePhoneToExternalUsers': false,
  'UserPreferencesShowFaxToExternalUsers': false,
  'UserPreferencesShowStreetAddressToExternalUsers': false,
  'UserPreferencesShowCityToExternalUsers': false,
  'UserPreferencesShowStateToExternalUsers': false,
  'UserPreferencesShowPostalCodeToExternalUsers': false,
  'UserPreferencesShowCountryToExternalUsers': false,
  'UserPreferencesShowProfilePicToGuestUsers': false,
  'UserPreferencesShowTitleToGuestUsers': false,
  'UserPreferencesShowCityToGuestUsers': false,
  'UserPreferencesShowStateToGuestUsers': false,
  'UserPreferencesShowPostalCodeToGuestUsers': false,
  'UserPreferencesShowCountryToGuestUsers': false,
  'UserPreferencesHideS1BrowserUI': false,
  'UserPreferencesDisableEndorsementEmail': false,
  'UserPreferencesPathAssistantCollapsed': false,
  'UserPreferencesCacheDiagnostics': false,
  'UserPreferencesShowEmailToGuestUsers': false,
  'UserPreferencesShowManagerToGuestUsers': false,
  'UserPreferencesShowWorkPhoneToGuestUsers': false,
  'UserPreferencesShowMobilePhoneToGuestUsers': false,
  'UserPreferencesShowFaxToGuestUsers': false,
  'UserPreferencesShowStreetAddressToGuestUsers': false,
  'UserPreferencesLightningExperiencePreferred': true,
  'UserPreferencesHideEndUserOnboardingAssistantModal': false,
  'UserPreferencesHideLightningMigrationModal': false,
  'UserPreferencesHideSfxWelcomeMat': true,
  'UserPreferencesHideBiggerPhotoCallout': false,
  'UserPreferencesGlobalNavBarWTShown': false,
  'UserPreferencesGlobalNavGridMenuWTShown': false,
  'UserPreferencesCreateLEXAppsWTShown': false,
  'UserPreferencesFavoritesWTShown': false,
  'UserPreferencesRecordHomeSectionCollapseWTShown': false,
  'UserPreferencesRecordHomeReservedWTShown': false,
  'UserPreferencesFavoritesShowTopFavorites': false,
  'UserPreferencesExcludeMailAppAttachments': false,
  'UserPreferencesSuppressTaskSFXReminders': false,
  'UserPreferencesSuppressEventSFXReminders': false,
  'UserPreferencesPreviewCustomTheme': false,
  'UserPreferencesHasCelebrationBadge': false,
  'ContactId': '0034m000001sfYYAAY',
  'AccountId': '0014m000002ngJIAAY',
  'CallCenterId': null,
  'Extension': null,
  'PortalRole': null,
  'IsPortalEnabled': true,
  'FederationIdentifier': null,
  'AboutMe': null,
  'FullPhotoUrl': 'https://chtc-ctlc--script--c.documentforce.com/profilephoto/005/F',
  'SmallPhotoUrl': 'https://chtc-ctlc--script--c.documentforce.com/profilephoto/005/T',
  'IsExtIndicatorVisible': false,
  'OutOfOfficeMessage': '',
  'MediumPhotoUrl': 'https://chtc-ctlc--script--c.documentforce.com/profilephoto/005/M',
  'DigestFrequency': 'N',
  'DefaultGroupNotificationFrequency': 'N',
  'IsPrmSuperUser': false,
  'LastViewedDate': '2020-08-19T15:07:49.000+0000',
  'LastReferencedDate': '2020-08-19T15:07:49.000+0000',
  'BannerPhotoUrl': '/profilephoto/005/B',
  'SmallBannerPhotoUrl': '/profilephoto/005/D',
  'MediumBannerPhotoUrl': '/profilephoto/005/E',
  'IsProfilePhotoActive': false,
  'FGM_Portal__Portal_Account_Admin__c': false,
  'FGM_Portal__ProfileType__c': 'Organization',
  'FGM_Portal__Return_URL__c': null,
  'FGM_Portal__UserEmail__c': 'apicommunity+2@magnaro.com',
  'FGM_Portal__UserProfile__c': 'Grantee',
  'Language__c': null
}
*/

const mapFields = {
  // Address: {
  //   name: 'address',
  //   type: 'Address'
  // },
  Prefer_Emails_and_Phone_Calls__c: 'preferPhone',
  Prefer_Only_Emails__c: 'preferEmail',
  direction: 'in',
  Id: 'id',
  FirstName: 'firstName',
  LastName: 'lastName',
  Phone: 'contactPhone',
  UserName: 'username',
  LanguageLocaleKey: 'language',
  Email: 'email',
  Title: 'jobTitle',
  Associated_Organizations__c: 'associatedOrganizations',
  ContactId: 'contactId',
  AccountId: 'accountId',
  Redirected_To_Profile__c: 'redirectedToProfile',
  Redirected_To_Join_Organization__c: 'redirectedToJoinOrganization',
  FGM_Portal__UserProfile__c: {
    key: 'role',
    in: user =>
      user.FGM_Portal__UserProfile__c &&
      user.FGM_Portal__UserProfile__c.split(';')
  }
  // user permissions checked by salesforce are based on membership in public group
  // Allocation Committee -
}
const reverseMapFields = reverseMap(mapFields)

export const getUser = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('User').retrieve(id)
}
export const getUserParsed = (id, getOrganization = false) => {
  return getUser(id).then(user => {
    console.log('getUser', user)
    if (getOrganization) {
      return getAccount(user.Associated_Organizations__c).then(org => {
        return {
          ...mapSFToForm(mapFields, user),
          associatedOrganizationName: org.Name
        }
      })
    }
    return mapSFToForm(mapFields, user)
  })
}

export const getUsers = ({ ids, permissions = {} }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  if (ids.length === 0) {
    return Promise.resolve().then(r => [])
  }
  let retPromise = conn.sobject('User').find({
    Id: { $in: ids }
  })

  if (permissions.MANAGE_ORGANIZATION) {
    retPromise = retPromise.select(
      'Id, Name, Email, FGM_Portal__UserEmail__c, ContactId'
    )
  }

  return retPromise
}

export const getUsersParsed = idsList => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return (
    conn
      .sobject('User')
      .find({
        Id: { $in: idsList }
      })
      // .include('AccountTeams')
      // .select('Id')
      // .end()
      // .include('UserRecordAccess.HasEditAccess')
      .then(result => {
        console.log(idsList, result)
        return result.map(acc => mapSFToForm(mapFields, acc))
      })
  )
}

export const getUsersByFlow = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Find_User', {
      inputs: ids.map(id => ({ id }))
    })
    .then(result => result.map(obj => obj.outputValues.user))
}

export const getUserByFlow = getUserByFlow => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Find_User', {
      inputs: [getUserByFlow]
    })
    .then(result => result[0].outputValues.user)
}

export const USER_SEARCH_ERROR_TOO_MANY =
  'Too many records found! Try to be more specific in your search!'
export const USER_SEARCH_ERROR_NOT_FOUND = 'No users found!'
export const USER_VALIDITY_NO_FORM = 'USER_VALIDITY_NO_FORM'

export const checkUserProfileValidity = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return fetchConfiguration({
    Name: 'FORM_USER_PROFILE'
  }).then(config => {
    if (config.Value__c) {
      return fetchFormPage(config.Value__c).then(form => {
        if (!form) {
          return USER_VALIDITY_NO_FORM
        }
        return checkFormValidity({
          formId: form.id,
          id: constructFormAddressString({
            userId: id,
            objectsConnected: form.objectsConnected
          })
        })
      })
    } else {
      return USER_VALIDITY_NO_FORM
    }
  })
}

export const getMembershipsForUsers = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Get_Group_Memberships_For_User', {
      inputs: ids.map(id => ({ id }))
    })
    .then(membershipResult => {
      const toRet = {}
      membershipResult.forEach((result, index) => {
        const { memberships } = result.outputValues
        if (memberships.length > 0) {
          toRet[ids[index]] = memberships
        }
      })
      return toRet
    })
}

export const getPublicGroups = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Group').find({
    Type: 'Regular'
  })
}

export const getAllUsersWithInfo = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Get_All_Users_With_Info', {
      inputs: [{}]
    })
    .then(result => result[0].outputValues)
}

export const getUserGroupMembership = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('GroupMember')
    .find({
      UserOrGroupId: id
    })
    .then(result => {
      const toFetch = result.map(obj => obj.GroupId)
      if (toFetch.length === 0) {
        return []
      }
      return conn
        .sobject('Group')
        .find({
          Id: { $in: toFetch }
        })
        .then(result => {
          return result.map(group => group.DeveloperName)
        })
    })
}

export const searchForUser = searchObj => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  console.log(searchObj)
  // if (searchObj.email === '') {
  //   return Promise.resolve().then(result => {
  //     return 'Cannot search by empty record!'
  //   })
  // }
  return conn
    .requestPost('/actions/custom/flow/App_Find_Users', {
      inputs: [
        {
          name: searchObj.name !== '' ? searchObj.name : null,
          email: searchObj.email !== '' ? searchObj.email : null
        }
      ]
    })
    .then(
      result => {
        let records = []
        if (result[0].outputValues.resultCount > 0) {
          records = result[0].outputValues.usersList
        }
        console.log(records, result)
        if (records.length === 0) {
          return { error: USER_SEARCH_ERROR_NOT_FOUND, records: [] }
        } else if (records.length > 0 && records.length <= 3) {
          return { records: records }
        } else {
          return {
            error: USER_SEARCH_ERROR_TOO_MANY,
            records: []
          }
        }
      },
      reject => {
        console.log(reject)
      }
    )
}

export const getNetworkUser = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  // return conn.sobject('User').retrieve(id)
  return (
    conn
      .sobject('NetworkMember')
      .find({
        MemberId: id
      })
      // .select(Object.keys(mapFields).join(', '))
      .select('NetworkId, MemberId, Network.Name')
      .autoFetch(true)
      .then(result => {
        console.log('getNetworkUser', result)
        if (result && result.length === 1) {
          return result[0]
        } else {
          return Promise.reject()
        }
      })
  )
}

export const getNetwork = name => {
  const conn = SFAuthService.getConnection()
  if (!name) {
    if (sfOauthConfig.isIcce) {
      name = 'ICCE-CAEC Community'
    } else {
      name = 'APIConnectionPortal'
    }
  }
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Network')
    .find({
      Name: name
    })
    .select('Id, Name')
    .autoFetch(true)
    .then(result => {
      console.log('getNetworkUser', result)
      if (result && result.length === 1) {
        return result[0]
      } else {
        return Promise.reject()
      }
    })
}

export const parseUserValues = values => {
  return mapSFToForm(mapFields, values)
}

export const saveUserByFlow = toUpdate => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Update_User', {
      inputs: [
        {
          toUpdate
        }
      ]
    })
    .then(result => result[0].outputValues.user)
}

export const saveUser = value => {
  console.log('save User', value)
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  // delete field that can't be edited
  delete value.AccountId
  return conn.sobject('User').update(value)
}

export const describeObjects = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return Promise.all([
    conn.sobject('Contact').describe(),
    conn.sobject('Account').describe()
  ]).then(([contact, account]) => {
    console.log('describe Account', account)
    console.log('describe Contact', contact)
  })
}

export const saveUserParsed = value => {
  return saveUser(mapSFToForm(reverseMapFields, value))
}

export const updateOldUsersAccountTeam = userId => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Update_Old_Users_Account_Team',
      {
        inputs: [
          {
            userId
          }
        ]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

export const appSetDefaultAccountTeam = userId => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Set_Default_Account_Team',
      {
        inputs: [
          {
            userId
          }
        ]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}
export const applyByEmail = ({ accountId, email, jobTitle, teamRole }) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost('/actions/custom/flow/App_Team_Invite_by_email', {
      inputs: [
        {
          accountId,
          email,
          jobTitle,
          teamRole
        }
      ]
    })
    // output in result field:
    // RESULT_BY_EMAIL_NO_USER_INVITE_SENT
    // ERROR_BY_EMAIL_USER_EXISTS_FOR_EMAIL
  } else {
    return Promise.reject(NO_USER)
  }
}
