import { Trans } from '@lingui/macro'
import {
  Card,
  FormControlLabel,
  Grid,
  Checkbox,
  Typography,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { getAccountParsed } from 'app/services/sfAuth/sfData/sfAccount'
import FindAccountButton from 'app/views/grants/FindAccountButton'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Field, useFormikContext } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMainConnected, insertValueToSFObject } from '../../Form'
import {
  endEditingField,
  startEditingField
} from '../../multiuser/grpcMultiuserEdit'
import { FormConnectToObject, getMuState, MuEditedByLabel } from '../Common'

export const formAccountPickerExtractSaveKey = ({
  saveMap,
  sfObject,
  value,
  connectedProps,
  subObjectsMap,
  connectedObjectId
}) => {
  const { connectedField } = connectedProps
  insertValueToSFObject({
    saveMap,
    sfObject,
    value: value ? value.id : null,
    fieldProps: connectedField,
    subObjectsMap,
    connectedObjectId
  })
}

export const formAccountPickerParseValueToCompare = (value, props) => {
  if (props.saveFailed && value) {
    return value.name
  }
  if (value && value.id) {
    return value.id
  } else {
    return value
  }
}

export const formAccountPickerDefaultValue = (obj, additionalInfo, item) => {
  const { accountsMap = {} } = additionalInfo
  if (!obj) {
    return null
  } else {
    const { connectedField } = getMainConnected(item)
    const field = connectedField?.name
    if (obj[field] && accountsMap[obj[field]]) {
      const account = accountsMap[obj[field]]
      return {
        id: account.Id,
        name: account.Name
      }
    } else {
      return obj[field]
    }
  }
}

export const formAccountPickerValueToText = (
  value,
  question,
  { accountsMap }
) => {
  if (typeof value === 'string') {
    const account = accountsMap[value] || {}
    return {
      en: account.Name || '',
      fr: account.Name || ''
    }
  } else if (!value) {
    return {
      en: '',
      fr: ''
    }
  }
  return {
    en: value.name || '',
    fr: value.name || ''
  }
}

export const FormAccountPickerPrint = ({ value, title, typeProps }) => {
  const { printLabelPlacement } = typeProps
  const top = !printLabelPlacement || printLabelPlacement === 'top'
  return (
    <div>
      {top && <Typography className='form-print-subtitle'>{title}</Typography>}
      <Typography style={{ whiteSpace: 'pre-line' }}>
        {value && value.name}
      </Typography>
      {!top && <Typography className='form-print-subtitle'>{title}</Typography>}
    </div>
  )
}

export const FormAccountPicker = ({
  id,
  useMultiuser,
  formId,
  disabled,
  connectedFieldDetails,
  editMode
}) => {
  const user = useSelector(state => state.user)
  const { values, setFieldValue } = useFormikContext()
  const { isEdited, userColor, userName } = getMuState({ values, id })

  return (
    <Field name={id}>
      {({ field: { value }, form: { setFieldValue } }) => {
        const onAccountFound = ({ Id, Name, ...account }) => {
          const newValue = {
            id: Id,
            name: Name
          }
          setFieldValue(id, newValue)
          if (useMultiuser) {
            endEditingField({
              userId: user.userId,
              formId,
              fieldId: id,
              fieldValue: newValue,
              lockId: values.muInfo.lockId
            })
          }
        }

        return (
          <>
            <Card
              elevation={5}
              style={{
                padding: 20,
                border: '1px solid #C4C4C4'
              }}
            >
              <Grid container direction='column' justify='center'>
                <div style={{ marginBottom: 10 }}>
                  <FindAccountButton
                    disabled={
                      disabled ||
                      !connectedFieldDetails ||
                      Boolean(isEdited && useMultiuser)
                    }
                    onSelect={onAccountFound}
                    onDialogClose={e => {
                      if (useMultiuser) {
                        endEditingField({
                          userId: user.userId,
                          formId,
                          fieldId: id,
                          fieldValue: value,
                          lockId: values.muInfo.lockId
                        })
                      }
                    }}
                    onDialogOpen={e => {
                      if (useMultiuser) {
                        startEditingField({
                          userId: user.userId,
                          formId,
                          fieldId: id
                        })
                      }
                    }}
                  />
                  {Boolean(!connectedFieldDetails && !editMode) && (
                    <div style={{ padding: 10, color: 'red' }}>
                      <Trans>There is no object connected in editor!</Trans>
                    </div>
                  )}
                </div>
                {Boolean(value && typeof value === 'object') && (
                  <b>
                    <span>{value.name}</span>
                  </b>
                )}
              </Grid>
            </Card>
            {isEdited && (
              <div style={{ marginTop: 5 }}>
                <MuEditedByLabel color={userColor} userName={userName} />
              </div>
            )}
          </>
        )
      }}
    </Field>
  )
}

export const FormEditorAccountPicker = ({
  editMode,
  showPrintProps,
  depth,
  typeProps,
  ...props
}) => {
  const dispatch = useDispatch()
  const { required, printLabelPlacement, printPageBreakBefore } = typeProps
  if (!editMode) {
    return <FormAccountPicker editMode typeProps={typeProps} {...props} />
  }
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(required)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.required = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Required</Trans>}
        />
      </div>
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        filter={item =>
          item.type === 'reference' && item.referenceTo.includes('Account')
        }
      />
    </div>
  )
}
