import React from 'react'
import {
  makeStyles,
  TextField,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Button,
  Icon,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Divider,
  FormGroup
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import { Field, useFormikContext } from 'formik'
import Grid from '@material-ui/core/Grid'
import { FormikTextField } from 'formik-material-fields'
import { t, Trans } from '@lingui/macro'
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import SFAuthService, { NO_USER } from 'app/services/sfAuth/SFAuthService'
import {
  commonPdfStyles,
  FormColorAutocomplete,
  FormConnectToObject,
  mapEditorFormElements,
  NumberFormatDefault
} from '../Common'
import CustomDatePicker from 'app/views/common/CustomDatePicker'
import { FormErrorText } from '../FormErrorLabel'
import { useDispatch, useSelector } from 'react-redux'
import {
  MILESTONE_ORDER,
  MILESTONE_TRANSLATIONS
} from 'app/services/sfAuth/sfData/sfOpportunity'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getCurrentWords } from '../FormTextField'
import { countHelperText } from 'app/views/page-layouts/FormElement'
import { dateFormat } from 'app/appSettings'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import MUTextField from '../multiuser-input/MUTextField'
import MUDatePicker from '../multiuser-input/MUDatePicker'
import {
  commitChangeToMultipleFields,
  endEditingField
} from '../../multiuser/grpcMultiuserEdit'

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  dataPicker: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    width: '80%',
    marginBottom: 16
  },
  tcSpan4: {
    paddingLeft: 50,
    paddingTop: 25,
    paddingBottom: 25,
    paddingRight: 35
  },
  tr: {
    borderBottom: 'solid 1px #c1c1c1',
    borderTop: 'solid 0px #ffffff'
  },
  tHeaderText: {
    textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  divCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export const formMilestonesValueToText = (v, question) => {
  const { phases } = question.typeProps
  const toRet = (
    <div>
      {phases.map((phase, index) => {
        const milestone = v[index]
        return (
          <div key={index} style={{ width: '100%', padding: 5 }}>
            <Typography
              style={{
                fontSize: 14,
                fonWeight: 'bold',
                textAlign: 'center',
                margin: 10
              }}
            >
              <Trans id={myI18n._(t`${phase.apiValue}`)} />
            </Typography>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Start Date</Trans>
                </Typography>
                <Typography style={{ fontSize: 17 }}>
                  {milestone.startDate &&
                    moment.utc(milestone.startDate).format(dateFormat)}
                </Typography>
              </Grid>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Typography className='form-print-subtitle'>
                  <Trans>End Date</Trans>
                </Typography>
                <Typography style={{ fontSize: 17 }}>
                  {milestone.endDate &&
                    moment.utc(milestone.endDate).format(dateFormat)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <div style={{ padding: 5 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Primary Activities</Trans>
              </Typography>
              <Typography>{milestone.primaryActivities}</Typography>
            </div>
            <Divider />
            <div style={{ padding: 5 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Comments</Trans>
              </Typography>
              <Typography>{milestone.comments}</Typography>
            </div>
          </div>
        )
      })}
      <div style={{ color: '#e0a912' }}>
        <Trans>
          Overriding will ony save changes to already added milestones. It will
          not delete any newly added milestones. Manual cleanup may be required.
        </Trans>
      </div>
    </div>
  )
  return {
    en: toRet,
    fr: toRet
  }
}

export const FormMilestonesDefaultValue = (obj, addInfo, item) => {
  const { phases = [] } = item.typeProps
  if (!obj || !obj.FGM_Base__Benchmarks__r) {
    return phases.map(phase => ({
      startDate: moment.utc().add(1, 'day'),
      endDate: moment.utc().add(1, 'day')
    }))
  }
  const toRet = obj.FGM_Base__Benchmarks__r.records
    .map(milestone => {
      return {
        startDate:
          milestone.FGM_Base__Due_Date__c || moment.utc().add(1, 'day'),
        endDate:
          milestone.FGM_Base__Completion_Date__c || moment.utc().add(1, 'day'),
        primaryActivities: milestone.Primary_activities__c || '',
        comments: milestone.FGM_Base__Description__c || '',
        stage: milestone.FGM_Base__Status__c,
        id: milestone.Id
      }
    })
    .sort(
      (a, b) =>
        MILESTONE_ORDER.indexOf(a.stage) - MILESTONE_ORDER.indexOf(b.stage)
    )
  if (toRet.length > phases.length) {
    toRet.length = phases.length
  }
  return toRet
}

Yup.addMethod(Yup.array, 'checkChronology', function (message) {
  return this.test('checkChronology', message, function (milestones) {
    const { path, createError } = this
    if (milestones.length === 0) {
      return true
    }
    const startDates = milestones.map(item => item.startDate)
    const endDates = milestones.map(item => item.endDate)
    let bool = false
    const wrongInputs = { start: [], end: [] }
    for (let index = 1; index < startDates.length; index++) {
      if (startDates[index] && startDates[index] <= endDates[index - 1]) {
        bool = true
        wrongInputs.start.push(startDates[index])
      }
    }
    for (let index = 0; index < endDates.length; index++) {
      if (endDates[index] <= startDates[index]) {
        bool = true
        wrongInputs.end.push(endDates[index])
      }
    }
    console.log('checkChronology', milestones, bool, wrongInputs)
    if (bool) {
      console.log('create error')
      return createError({ path, message })
    }
    return !bool
  })
})

const requiredTrans = <Trans>This field is required</Trans>
const notAllowedTrans = <Trans>Past or current date is not allowed!</Trans>
const chronologicalTrans = (
  <Trans>Dates have to be in chronological order!</Trans>
)

export const FormMilestonesValidation = item => {
  const { commentsRequired, activitiesRequired, datesRequired } = item.typeProps
  const baseObject = {}
  if (commentsRequired) {
    baseObject.comments = Yup.string()
      .ensure()
      .required(requiredTrans)
      .label(<Trans>Comments</Trans>)
  }
  if (activitiesRequired) {
    baseObject.primaryActivities = Yup.string()
      .ensure()
      .required(requiredTrans)
      .label(<Trans>Primary Activities</Trans>)
  }
  if (datesRequired) {
    baseObject.startDate = Yup.date()
      .typeError(<Trans>Must be valid date</Trans>)
      .min(moment.utc(), notAllowedTrans)
      .required(requiredTrans)
      .label(<Trans>Start Date</Trans>)
    baseObject.endDate = Yup.date()
      .typeError(<Trans>Must be valid date</Trans>)
      .min(moment.utc(), notAllowedTrans)
      .required(requiredTrans)
      .label(<Trans>End Date</Trans>)
  }
  return Yup.array()
    .ensure()
    .of(Yup.object(baseObject))
    .checkChronology(chronologicalTrans)
    .label(<Trans>Milestones</Trans>)
}

export const FormMilestonesExtractKey = ({
  saveMap,
  value,
  item,
  connectedObjectId
}) => {
  const { phases = [] } = item.typeProps
  if (value.length > phases.length) {
    value.length = phases.length
  }
  const inner = value.map((item, index) => {
    return {
      FGM_Base__Due_Date__c: item.startDate,
      FGM_Base__Completion_Date__c: item.endDate,
      Primary_activities__c: item.primaryActivities,
      FGM_Base__Description__c: item.comments,
      FGM_Base__Status__c: phases[index].apiValue,
      Id: item.id
    }
  })
  saveMap[connectedObjectId].FGM_Base__Benchmarks__r = inner
}

export const FormMilestonesSavePromise = ({ value }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toUpdate = value.map(item => ({
    FGM_Base__Due_Date__c: item.startDate,
    FGM_Base__Completion_Date__c: item.endDate,
    Primary_activities__c: item.primaryActivities,
    FGM_Base__Description__c: item.comments,
    Id: item.id
  }))
  return conn.sobject('FGM_Base__Benchmark__c').update(toUpdate)
}

const indexToStage = {
  0: <Trans>Planning / initiation stage</Trans>,
  1: <Trans>Execution stage</Trans>,
  2: <Trans>Closure stage</Trans>
}
const keyToLabel = {
  comments: <Trans>Comments</Trans>,
  primaryActivities: <Trans>Primary Activities</Trans>
}
export const FormMilestonesError = error => {
  if (Array.isArray(error)) {
    return {
      toMap: error.map((item, index) => {
        if (!item) {
          console.log('errors milestones undefined error ')
          return null
        }
        return (
          <span key={index}>
            <u>
              {index + 1}
              {'. '}
              {indexToStage[index]}
            </u>
            <ul key={index}>
              {Object.entries(item).map(([key, value]) => {
                return (
                  <li
                    key={key}
                    className='m-0 text-muted'
                    style={{ maxWidth: '771px' }}
                  >
                    {keyToLabel[key]}
                    {': '}
                    {value}
                  </li>
                )
              })}
            </ul>
          </span>
        )
      })
    }
  } else {
    return error
  }
}

const useStyles = makeStyles(styles)
const pdfStyles = StyleSheet.create({
  headerCell: {
    textAlign: 'center',
    // fontWeight: 'bold',
    // backgroundColor: '#daeef3',
    padding: 10,
    fontSize: 10,
    flex: 1,
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  },
  cell: {
    padding: 10,
    fontSize: 10,
    flex: 1,
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  }
})

export const FormMilestonesPdf = ({ value = [], title, typeProps = {} }) => {
  const {
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
    phases = []
  } = typeProps
  const headerStyle = { ...pdfStyles.headerCell }
  if (pdfHeaderTextProps.includes('bold')) {
    headerStyle.fontWeigth = 'bold'
  }
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }
  return (
    <View>
      <View>
        <Text style={commonPdfStyles.title}>{title}</Text>
      </View>
      <View style={commonPdfStyles.row}>
        <View style={headerStyle}>
          <Text>{myI18n._(t`Stage`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n._(t`Start Date`)}</Text>
        </View>
        <View style={headerStyle}>
          <Text>{myI18n._(t`End Date`)}</Text>
        </View>
        <View style={{ ...headerStyle, flex: 2 }}>
          <Text>{myI18n._(t`Primary Activities`)}</Text>
        </View>
        <View style={{ ...headerStyle, flex: 2 }}>
          <Text>{myI18n._(t`Comments`)}</Text>
        </View>
      </View>
      {phases.map((phase, index) => {
        const milestone = value[index]
        return (
          <View style={commonPdfStyles.row} key={index}>
            <View style={pdfStyles.cell}>
              <Text>
                <Trans id={myI18n._(t`${phase.apiValue}`)} />
              </Text>
            </View>
            <View style={pdfStyles.cell}>
              <Text>
                {milestone.startDate &&
                  moment.utc(milestone.startDate).format(dateFormat)}
              </Text>
            </View>
            <View style={pdfStyles.cell}>
              <Text>
                {milestone.endDate &&
                  moment.utc(milestone.endDate).format(dateFormat)}
              </Text>
            </View>
            <View style={{ ...pdfStyles.cell, flex: 2 }}>
              <Text>{milestone.primaryActivities}</Text>
            </View>
            <View style={{ ...pdfStyles.cell, flex: 2 }}>
              <Text>{milestone.comments}</Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}

export const FormMilestonesPrint = ({ value, title, typeProps = {} }) => {
  const { phases = [] } = typeProps

  // return <div className='form-print-title-small'>{title}</div>

  return (
    <>
      <div className='form-print-title-small'>{title}</div>
      {phases.map((phase, index) => {
        const milestone = value[index]
        return (
          <Paper
            elevation={6}
            key={index}
            style={{ width: '100%', padding: 10 }}
            className='break-in-print'
          >
            <Typography
              style={{
                fontSize: 18,
                fonWeight: 'bold',
                textAlign: 'center',
                margin: 10
              }}
            >
              <Trans id={myI18n._(t`${phase.apiValue}`)} />
            </Typography>
            <Grid container style={{ marginBottom: 15 }}>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Typography className='form-print-subtitle'>
                  <Trans>Start Date</Trans>
                </Typography>
                <Typography style={{ fontSize: 21 }}>
                  {milestone.startDate &&
                    moment.utc(milestone.startDate).format(dateFormat)}
                </Typography>
              </Grid>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Typography className='form-print-subtitle'>
                  <Trans>End Date</Trans>
                </Typography>
                <Typography style={{ fontSize: 21 }}>
                  {milestone.endDate &&
                    moment.utc(milestone.endDate).format(dateFormat)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <div style={{ padding: 8 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Primary Activities</Trans>
              </Typography>
              <Typography>{milestone.primaryActivities}</Typography>
            </div>
            <Divider />
            <div style={{ padding: 8 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Comments</Trans>
              </Typography>
              <Typography>{milestone.comments}</Typography>
            </div>
          </Paper>
        )
      })}
    </>
  )
}

export const FormMilestones = ({
  id,
  value = [],
  useMultiuser,
  formId,
  title,
  disabled,
  connectedObject,
  editMode,
  langFR,
  typeProps = {}
}) => {
  const {
    phases = [],
    textFR,
    textEN,
    commentsMaxChar,
    commentsMaxWords,
    activitiesMaxChar,
    activitiesMaxWords,
    commentsRequired,
    activitiesRequired,
    commentsExpandable,
    commentsRows,
    activitiesRows,
    activitiesExpandable
  } = typeProps
  const user = useSelector(state => state.user)
  const { values, setFieldValue, setValues } = useFormikContext()
  const columns = [t`Name`, t`Start Date`, t`End Date`]
  const classes = useStyles()
  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  return (
    <Paper className={classes.root}>
      <h3>{title}</h3>
      <Typography>{langFR ? textFR : textEN}</Typography>
      <div className='w-100 overflow-auto' style={{ padding: 20 }}>
        <TableContainer component={Paper}>
          <Table style={{ whiteSpace: 'pre' }}>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                {columns.map((item, i) => (
                  <TableCell key={i}>
                    <p className={classes.tHeaderText}>
                      <Trans id={item} />
                    </p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {phases.map((item, index) => {
                const {
                  startDateTooltipEN,
                  startDateTooltipFR,
                  endDateTooltipEN,
                  endDateTooltipFR,
                  endDateMaxDate,
                  endDateMinDate,
                  startDateMaxDate,
                  startDateMinDate
                } = item
                const startDate = value[index]?.startDate
                const endDate = value[index]?.endDate
                const comments = value[index]?.comments || ''
                const activities = value[index]?.primaryActivities || ''
                const error = moment(startDate).isAfter(moment(endDate)) && (
                  <Trans>Start date cannot be set after end date</Trans>
                )
                const startTooltip = langFR
                  ? startDateTooltipFR
                  : startDateTooltipEN
                const endTooltip = langFR ? endDateTooltipFR : endDateTooltipEN

                let endDateMin = endDateMinDate
                let endDateMax = endDateMaxDate
                let startDateMin = startDateMinDate
                let startDateMax = startDateMaxDate
                const array = ['startDate', 'endDate']
                array.forEach(key => {
                  const maxElementId = typeProps[key + 'MaxDateElement']
                  const momentMaxDate = moment.utc(values[maxElementId])
                  const minElementId = typeProps[key + 'MinDateElement']
                  const momentMinDate = moment.utc(values[minElementId])
                  const value = moment.utc(
                    key === 'endDate' ? endDate : startDate
                  )
                  const subId =
                    key === 'endDate'
                      ? id + `[${index}].endDate`
                      : id + `[${index}].startDate`

                  if (minElementId && values[minElementId]) {
                    if (key === 'endDate') {
                      endDateMin = momentMinDate
                    } else {
                      startDateMin = momentMinDate
                    }
                    if (value.isValid() && momentMinDate.isAfter(value)) {
                      if (
                        !(
                          momentMinDate.isValid() &&
                          momentMaxDate.isBefore(momentMinDate)
                        )
                      ) {
                        setFieldValue(subId, momentMinDate)
                      }
                    }
                  }
                  if (maxElementId && values[maxElementId]) {
                    if (key === 'endDate') {
                      endDateMax = momentMaxDate
                    } else {
                      startDateMax = momentMaxDate
                    }
                    if (value.isValid() && momentMaxDate.isBefore(value)) {
                      if (
                        !(
                          momentMinDate.isValid() &&
                          momentMinDate.isAfter(momentMaxDate)
                        )
                      ) {
                        setFieldValue(subId, momentMaxDate)
                      }
                    }
                  }
                })

                const commentsHelperArray = []
                if (commentsMaxWords) {
                  commentsHelperArray.push(
                    <span>
                      {getCurrentWords(comments) + '/' + commentsMaxWords}{' '}
                      <Trans>words</Trans>
                      {'. '}
                    </span>
                  )
                }
                if (Number(commentsMaxChar)) {
                  commentsHelperArray.push(
                    <span>
                      {countHelperText(comments, Number(commentsMaxChar))}
                      {'. '}
                    </span>
                  )
                }
                if (commentsRequired) {
                  commentsHelperArray.push(
                    <span>
                      <Trans>Required</Trans>
                      {'. '}
                    </span>
                  )
                }

                const activitiesHelperArray = []
                if (activitiesMaxWords) {
                  activitiesHelperArray.push(
                    <span>
                      {getCurrentWords(activities) + '/' + activitiesMaxWords}{' '}
                      <Trans>words</Trans>
                      {'. '}
                    </span>
                  )
                }
                if (Number(activitiesMaxChar)) {
                  activitiesHelperArray.push(
                    <span>
                      {countHelperText(activities, Number(activitiesMaxChar))}
                      {'. '}
                    </span>
                  )
                }
                if (activitiesRequired) {
                  activitiesHelperArray.push(
                    <span>
                      <Trans>Required</Trans>
                      {'. '}
                    </span>
                  )
                }

                const activitiesId = id + `[${index}].primaryActivities`
                const commentsId = id + `[${index}].comments`

                return [
                  <TableRow
                    key={index}
                    style={{ borderBottom: 'solid 0px #ffffff' }}
                  >
                    <TableCell style={{ paddingLeft: 20 }}>
                      <div style={{ paddingLeft: 15 }}>
                        <b>{langFR ? item.labelFR : item.labelEN}</b>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Grid container wrap='nowrap'>
                        <MUDatePicker
                          id={id + `[${index}].startDate`}
                          formId={formId}
                          useMultiuser={useMultiuser}
                          autoOk
                          error={error}
                          minDate={startDateMin}
                          maxDate={startDateMax}
                          style={{
                            margin: 0,
                            paddingRight: 0,
                            paddingTop: 15,
                            paddingLeft: 15
                          }}
                          disabled={disabled}
                          format={dateFormat}
                          onChange={event => {
                            const toSet = [...value]
                            if (index !== 0 && event) {
                              const newDate = moment(event).subtract(1, 'days')
                              toSet[index - 1].endDate = newDate
                            }
                            const item = { ...toSet[index] }
                            item.startDate = event
                            if (!item.endDate) {
                              const newDate = moment(event).add(1, 'days')
                              item.endDate = newDate
                            }
                            toSet[index] = item
                            setFieldValue(id, toSet)
                            if (useMultiuser) {
                              endEditingField({
                                userId: user.userId,
                                formId,
                                fieldId: id,
                                fieldValue: toSet
                              })
                            }
                          }}
                        />
                        {startTooltip && (
                          <TooltipLabelIcon tooltip={startTooltip} />
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container wrap='nowrap'>
                        <MUDatePicker
                          id={id + `[${index}].endDate`}
                          formId={formId}
                          useMultiuser={useMultiuser}
                          autoOk
                          error={error}
                          minDate={endDateMin}
                          maxDate={endDateMax}
                          style={{
                            margin: 0,
                            paddingRight: 0,
                            paddingTop: 15,
                            paddingLeft: 15
                          }}
                          disabled={disabled}
                          format={dateFormat}
                          onChange={event => {
                            const toSet = [...value]
                            if (index < value.length - 1 && event) {
                              const newDate = moment(event).add(1, 'days')
                              toSet[index + 1].startDate = newDate
                            }
                            const item = { ...toSet[index] }
                            item.endDate = event
                            if (!item.startDate) {
                              const newDate = moment(event).subtract(1, 'days')
                              item.startDate = newDate
                            }
                            toSet[index] = item
                            setFieldValue(id, toSet)
                            if (useMultiuser) {
                              endEditingField({
                                userId: user.userId,
                                formId,
                                fieldId: id,
                                fieldValue: toSet
                              })
                            }
                          }}
                        />
                        {endTooltip && (
                          <TooltipLabelIcon tooltip={endTooltip} />
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>,
                  <TableRow key={index + phases.length} className={classes.tr}>
                    <TableCell colSpan={3} className={classes.tcSpan4}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={2}>
                          <Trans>Primary Activities</Trans>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                          <MUTextField
                            id={activitiesId}
                            formId={formId}
                            useMultiuser={useMultiuser}
                            multiline
                            minRows={activitiesRows || 5}
                            maxRows={
                              activitiesExpandable ? null : activitiesRows || 5
                            }
                            margin='normal'
                            disabled={disabled}
                            onChange={e => {
                              const toSet = e.target.value
                              if (activitiesMaxWords) {
                                if (
                                  getCurrentWords(toSet) <= activitiesMaxWords
                                ) {
                                  setFieldValue(activitiesId, e.target.value)
                                }
                              } else {
                                setFieldValue(activitiesId, e.target.value)
                              }
                            }}
                            inputProps={{
                              maxLength:
                                activitiesMaxChar && Number(activitiesMaxChar)
                            }}
                            helperText={activitiesHelperArray}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>,
                  <TableRow
                    key={index + phases.length * 2}
                    className={classes.tr}
                  >
                    <TableCell colSpan={3} className={classes.tcSpan4}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={2}>
                          <Trans>Comments</Trans>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                          <MUTextField
                            id={commentsId}
                            formId={formId}
                            useMultiuser={useMultiuser}
                            multiline
                            minRows={commentsRows || 5}
                            maxRows={
                              commentsExpandable ? null : commentsRows || 5
                            }
                            margin='normal'
                            disabled={disabled}
                            onChange={e => {
                              const toSet = e.target.value
                              if (commentsMaxWords) {
                                if (
                                  getCurrentWords(toSet) <= commentsMaxWords
                                ) {
                                  setFieldValue(commentsId, e.target.value)
                                }
                              } else {
                                setFieldValue(commentsId, e.target.value)
                              }
                            }}
                            inputProps={{
                              maxLength:
                                commentsMaxChar && Number(commentsMaxChar)
                            }}
                            helperText={commentsHelperArray}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ]
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  )
}

export const FormEditorMilestones = ({
  editMode,
  showPdfProps,
  showPrintProps,
  depth,
  id,
  french,
  typeProps,
  ...props
}) => {
  const {
    phases = [],
    printPageBreakBefore,
    textFR,
    textEN,
    commentsRequired,
    activitiesRequired,
    datesRequired,
    commentsMaxChar,
    commentsMaxWords,
    commentsExpandable,
    commentsRows,
    activitiesRows,
    activitiesExpandable,
    activitiesMaxChar,
    activitiesMaxWords,
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize
  } = typeProps
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const avaliableElements = mapEditorFormElements({
    data: tree,
    french,
    id,
    elementTypes: ['datePicker']
  })
  if (!editMode) {
    return <FormMilestones editMode id={id} typeProps={typeProps} {...props} />
  }
  return (
    <div>
      <TextField
        label={<Trans>Help text - english</Trans>}
        value={textEN}
        fullWidth
        variant='outlined'
        multiline
        rows={2}
        style={{ marginBottom: 10 }}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.textEN = e.currentTarget.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <TextField
        label={<Trans>Help text - french</Trans>}
        value={textFR}
        fullWidth
        variant='outlined'
        multiline
        rows={2}
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.textFR = e.currentTarget.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <div style={{ marginTop: 10 }}>
        <Typography variant='h6' style={{ marginBottom: 5 }}>
          <Trans>Start/end date fields</Trans>
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(datesRequired)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.datesRequired = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Are required?</Trans>}
        />
      </div>
      <div>
        <Typography variant='h6' style={{ marignTop: 5, marginBottom: 5 }}>
          <Trans>Comments field</Trans>
        </Typography>
        <Grid container wrap='nowrap' alignItems='center'>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(commentsRequired)}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.commentsRequired = e.target.checked
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            }
            label={<Trans>Is required?</Trans>}
          />
          <TextField
            style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
            label={<Trans>Characters limit</Trans>}
            value={commentsMaxChar}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.commentsMaxChar = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
          <TextField
            style={{ marginTop: 5, marginBottom: 5 }}
            label={<Trans>Words limit</Trans>}
            value={commentsMaxWords}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.commentsMaxWords = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
          <Grid item>
            <TextField
              style={{ marginTop: 5, marginBottom: 5, marginLeft: 10 }}
              label={<Trans>Rows</Trans>}
              value={commentsRows || ''}
              InputProps={{ inputComponent: NumberFormatDefault }}
              variant='outlined'
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.commentsRows = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(commentsExpandable)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.commentsExpandable = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Allow expanding rows?</Trans>}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Typography variant='h6' style={{ marignTop: 5, marginBottom: 5 }}>
          <Trans>Primary activities field</Trans>
        </Typography>
        <Grid container wrap='nowrap' alignItems='center'>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(activitiesRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.activitiesRequired = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              }
              label={<Trans>Is required?</Trans>}
            />
          </div>
          <TextField
            style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
            label={<Trans>Characters limit</Trans>}
            value={activitiesMaxChar}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.activitiesMaxChar = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
          <TextField
            style={{ marginTop: 5, marginBottom: 5 }}
            label={<Trans>Words limit</Trans>}
            value={activitiesMaxWords}
            InputProps={{ inputComponent: NumberFormatDefault }}
            variant='outlined'
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.activitiesMaxWords = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
          <Grid item>
            <TextField
              style={{ marginTop: 5, marginBottom: 5, marginLeft: 10 }}
              label={<Trans>Rows</Trans>}
              value={activitiesRows || ''}
              InputProps={{ inputComponent: NumberFormatDefault }}
              variant='outlined'
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.activitiesRows = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(activitiesExpandable)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.activitiesExpandable = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Allow expanding rows?</Trans>}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: 10 }}>
        <Button
          variant='contained'
          color='primary'
          style={{ marginLeft: 5, marginBottom: 10 }}
          onClick={e => {
            const toSet = { ...typeProps }
            toSet.phases = [...phases]
            toSet.phases.push({
              labelEN: '',
              labelFR: ''
            })
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        >
          <Grid container alignItems='center'>
            <Trans>Add phase</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
        {phases.map((item, index) => (
          <div key={index}>
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              wrap='nowrap'
            >
              <Grid item>
                <b style={{ marginLeft: 10, marginRight: 5 }}>
                  {+index + 1}
                  {'.'}
                </b>
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  variant='outlined'
                  label={<Trans>Label - english</Trans>}
                  fullWidth
                  value={item.labelEN || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.phases[index].labelEN = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  variant='outlined'
                  label={<Trans>Label - french</Trans>}
                  fullWidth
                  value={item.labelFR || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.phases[index].labelFR = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  variant='outlined'
                  label={<Trans>API value</Trans>}
                  fullWidth
                  select
                  value={item.apiValue || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.phases[index].apiValue = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                >
                  {Object.keys(MILESTONE_TRANSLATIONS).map((key, index) => (
                    <MenuItem value={key} key={index}>
                      {myI18n._(MILESTONE_TRANSLATIONS[key])}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={e => {
                    const toSet = { ...typeProps }
                    toSet.phases.splice(index, 1)
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <div>
              <h6 style={{ marginLeft: 5, marginTop: 5 }}>
                <Trans>Start date</Trans>
              </h6>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.startDateMinDate || null}
                    label={<Trans>Minimum input date</Trans>}
                    inputVariant='outlined'
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].startDateMinDateElement
                      toSet.phases[index].startDateMinDate = e
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  />
                </Grid>
                <Grid item xs style={{ padding: 5 }}>
                  <TextField
                    select
                    fullWidth
                    variant='outlined'
                    label={<Trans>Minimum date based on element</Trans>}
                    value={item.startDateMinDateElement || ''}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].startDateMinDate
                      toSet.phases[index].startDateMinDateElement =
                        e.target.value
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  >
                    {[
                      <MenuItem key='none' value=''>
                        <Trans>Clear</Trans>
                      </MenuItem>,
                      ...avaliableElements.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))
                    ]}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.startDateMaxDate || null}
                    label={<Trans>Maximum input date</Trans>}
                    inputVariant='outlined'
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].startDateMaxDateElement
                      toSet.phases[index].startDateMaxDate = e
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  />
                </Grid>
                <Grid item xs style={{ padding: 5 }}>
                  <TextField
                    select
                    fullWidth
                    variant='outlined'
                    label={<Trans>Maximum date based on element</Trans>}
                    value={item.startDateMaxDateElement || ''}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].startDateMaxDate
                      toSet.phases[index].startDateMaxDateElement =
                        e.target.value
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  >
                    {[
                      <MenuItem key='none' value=''>
                        <Trans>Clear</Trans>
                      </MenuItem>,
                      ...avaliableElements.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))
                    ]}
                  </TextField>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              wrap='nowrap'
            >
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  variant='outlined'
                  label={<Trans>Start date - english tooltip</Trans>}
                  fullWidth
                  value={item.startDateTooltipEN || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.phases[index].startDateTooltipEN = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  variant='outlined'
                  label={<Trans>Start date - french tooltip</Trans>}
                  fullWidth
                  value={item.startDateTooltipFR || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.phases[index].startDateTooltipFR = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
            </Grid>
            <div>
              <h6 style={{ marginLeft: 5, marginTop: 5 }}>
                <Trans>End date</Trans>
              </h6>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.endDateMinDate || null}
                    label={<Trans>Minimum input date</Trans>}
                    inputVariant='outlined'
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].endDateMinDateElement
                      toSet.phases[index].endDateMinDate = e
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  />
                </Grid>
                <Grid item xs style={{ padding: 5 }}>
                  <TextField
                    select
                    fullWidth
                    variant='outlined'
                    label={<Trans>Minimum date based on element</Trans>}
                    value={item.endDateMinDateElement || ''}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].endDateMinDate
                      toSet.phases[index].endDateMinDateElement = e.target.value
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  >
                    {[
                      <MenuItem key='none' value=''>
                        <Trans>Clear</Trans>
                      </MenuItem>,
                      ...avaliableElements.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))
                    ]}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs style={{ padding: 5 }}>
                  <CustomDatePicker
                    fullWidth
                    clearable
                    format={dateFormat}
                    value={item.endDateMaxDate || null}
                    label={<Trans>Maximum input date</Trans>}
                    inputVariant='outlined'
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].endDateMaxDateElement
                      toSet.phases[index].endDateMaxDate = e
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  />
                </Grid>
                <Grid item xs style={{ padding: 5 }}>
                  <TextField
                    select
                    fullWidth
                    variant='outlined'
                    label={<Trans>Maximum date based on element</Trans>}
                    value={item.endDateMaxDateElement || ''}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      delete toSet.phases[index].endDateMaxDate
                      toSet.phases[index].endDateMaxDateElement = e.target.value
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  >
                    {[
                      <MenuItem key='none' value=''>
                        <Trans>Clear</Trans>
                      </MenuItem>,
                      ...avaliableElements.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))
                    ]}
                  </TextField>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              wrap='nowrap'
            >
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  variant='outlined'
                  label={<Trans>End date - english tooltip</Trans>}
                  fullWidth
                  value={item.endDateTooltipEN || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.phases[index].endDateTooltipEN = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  variant='outlined'
                  label={<Trans>End date - french tooltip</Trans>}
                  fullWidth
                  value={item.endDateTooltipFR || ''}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.phases[index].endDateTooltipFR = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                />
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Header</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant='outlined'
              value={pdfFontSize || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.pdfFontSize = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              inputProps={{
                maxLength: 2
              }}
              InputProps={{
                inputComponent: NumberFormatDefault
              }}
            />
            <FormGroup row>
              {[
                { key: 'bold', label: <Trans>Bold</Trans> },
                { key: 'italics', label: <Trans>Italics</Trans> },
                { key: 'underline', label: <Trans>Underline</Trans> }
              ].map(item => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfHeaderTextProps.includes(item.key)}
                      value={item.key}
                      onChange={e => {
                        const v = e.target.value
                        const toSet = { ...typeProps }
                        const newTextProps = [...pdfHeaderTextProps]
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1)
                        } else {
                          newTextProps.push(v)
                        }
                        toSet.pdfHeaderTextProps = newTextProps
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormColorAutocomplete
              depth={depth}
              label={<Trans>Cell background color</Trans>}
              name='pdfBackgroundColor'
              typeProps={typeProps}
              value={pdfBackgroundColor}
            />
          </div>
        </div>
      )}
    </div>
  )
}
