import { Card, Typography } from '@material-ui/core'
import React from 'react'

/**
 * Created by Michał Stawski on 21.01.2022.
 */


export const StyledCard = ({ children }) => {
  return <Card className="p-24 mb-24 bg-light-error box-shadow-none mt-24">
    <Typography
      style={{
        color: 'var(--text-muted) !important',
        textAlign: 'center',
        marginLeft: 10
      }}
    >
      {children}
    </Typography>
  </Card>
}
