import { I18n } from '@lingui/react'
import { Trans, t } from '@lingui/macro'
import SFAuthService from 'app/services/sfAuth/SFAuthService'
import { fetchFormPage } from 'app/services/sfAuth/sfData/sfForms'
import Loading from 'egret/components/EgretLoadable/Loading'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import {
  IconButton,
  Grid,
  Icon,
  MuiThemeProvider,
  Typography
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { muiTextLabels } from '../utilities/muiDataTablesTranslations'

export const PortalForm = ({
  formId,
  dataList,
  objLabel,
  tableLabel,
  fetchProps = {}
}) => {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  useEffect(() => {
    fetchFormPage(formId).then(result => {
      if (result.objectConnected) {
        if (dataList) {
          console.log(
            dataList.map(item => {
              console.log(item)
              return {
                name: item.Name,
                actions: {
                  objId: item.Id
                }
              }
            })
          )
          setData(
            dataList.map(item => ({
              name: item.Name,
              actions: {
                objId: item.Id
              }
            }))
          )
          setLoading(false)
          return
        }
        const conn = SFAuthService.getConnection()
        conn
          .sobject(result.objectConnected)
          .find({ ...fetchProps })
          .then(result => {
            setData(
              result.map(item => ({
                name: item.Name,
                actions: {
                  objId: item.Id
                }
              }))
            )
            setLoading(false)
          })
      } else {
        enqueueSnackbar(
          <Trans>There is no object connected to this form!</Trans>,
          {
            type: 'error'
          }
        )
      }
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <I18n>
      {({ i18n }) => (
        <div style={{ padding: 15 }}>
            <MUIDataTable
              title={
                <Grid container>
                  <Typography variant='h5'>
                    <b>{tableLabel}</b>
                  </Typography>
                </Grid>
              }
              data={data}
              options={{
                textLabels: muiTextLabels(i18n),
                filter: false,
                selectableRows: 'none',
                print: false,
                download: false,
                viewColumns: false
              }}
              columns={[
                {
                  name: 'name',
                  label: objLabel,
                  options: {
                    customHeadLabelRender: props => {
                      return <div style={{ marginLeft: 20 }}>{objLabel}</div>
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <div style={{ flex: 1, marginLeft: 20 }}>{value}</div>
                      )
                    }
                  }
                },
                {
                  name: 'actions',
                  label: i18n._(t`Actions`),
                  options: {
                    sort: false,
                    customHeadLabelRender: props => {
                      return null
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <Grid container justify='flex-end'>
                          <IconButton
                            style={{ marginRight: 20 }}
                            onClick={() => {
                              history.push(
                                '/elasticform' + `/${formId}/${value.objId}`
                              )
                            }}
                          >
                            <Icon>edit</Icon>
                          </IconButton>
                        </Grid>
                      )
                    }
                  }
                }
              ]}
            />
        </div>
      )}
    </I18n>
  )
}
