import { t, Trans } from '@lingui/macro'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  bilingualNumberFormat,
  CurrencyFormated,
  currencyFormatedString,
  PhoneCountryCodeFormat,
  PhoneFormat,
  PhoneFormatWithExtension
} from 'app/views/common/Formats'
import { useFormikContext } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import _ from 'lodash'
import React, { useCallback } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import { getMainConnected, getSFObjectFieldValue } from '../Form'
import { isConditionMet } from '../FormHelpersConditions'
import { validFieldTypesForTextFieldNumeric } from '../FormWizard'
import { FormConnectToObject } from './Common'
import MUTextField from './multiuser-input/MUTextField'

export const formTextFieldNumericDefaultValue = (obj, additional, item) => {
  const { initObj } = additional
  const { isPhone } = item.typeProps
  const { connectedField, connectedObject } = getMainConnected(item)
  if (connectedObject && connectedField && obj) {
    let sfValue = getSFObjectFieldValue(obj, connectedField)
    const stringV = String(sfValue)
    if (sfValue && stringV.indexOf('+(') !== -1) {
      const countryCode = stringV.substring(2, stringV.indexOf(')'))
      _.set(initObj, 'other.' + item.id, countryCode)
      sfValue = stringV.substring(stringV.indexOf(')'))
    }
    const parsed = String(sfValue).replace(/[^0-9]/g, '')
    if (!parsed) {
      return ''
    }
    return isPhone ? parsed : Number(parsed)
  } else {
    return ''
  }
}

export const formTextFieldNumericValueToText = value =>
  value ? { en: value, fr: value } : { en: '', fr: '' }

export const formTextFieldNumericConditionsStates = {
  numMin: {
    label: <Trans>Set min input to</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Min input</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  },
  maxMin: {
    label: <Trans>Set max input to</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Max input</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}

export function FormTextFieldNumeric ({
  id,
  useMultiuser,
  formId,
  value,
  title,
  preview,
  disabled,
  typeProps,
  conditions = [],
  connectedMap,
  elementsMap,
  langFR,
  ...props
}) {
  const {
    required,
    currency,
    readOnly,
    isPhone,
    showFieldLabel,
    max,
    min,
    allowPhoneExtension
  } = typeProps
  const { values } = useFormikContext()
  let maxInput = max
  let minInput = min

  conditions
    .filter(condition => ['maxMin', 'numMin'].includes(condition.state))
    .forEach(condition => {
      const { conditionMet, state } = isConditionMet({
        condition,
        connectedMap,
        values,
        elementsMap
      })
      if (conditionMet) {
        if (state === 'numMin' && condition.numMin) {
          minInput = Number(condition.numMin)
        } else if (state === 'maxMin' && condition.maxMin) {
          maxInput = Number(condition.maxMin)
        }
      }
    })

  let helper = []

  if (isPhone) {
    if (required) {
      helper.push(<Trans>Required</Trans>)
    }
  } else {
    if (required) {
      helper = [
        <Trans key={1}>Required</Trans>,
        '. ',
        <Trans key={2}>Only numerical values are permitted in this field</Trans>
      ]
    } else {
      helper = [
        <Trans key={1}>Only numerical values are permitted in this field</Trans>
      ]
    }
  }

  if (minInput) {
    helper.push(
      '. ',
      <Trans>Minimum value is: {bilingualNumberFormat(minInput, langFR)}</Trans>
    )
  }
  if (maxInput) {
    helper.push(
      '. ',
      <Trans>Maximum value is: {bilingualNumberFormat(maxInput, langFR)}</Trans>
    )
  }

  const NumberFormatWithMinAndMax = useCallback(
    props => {
      const { inputRef, onChange, id, value = '', ...other } = props
      let formattedValue = ''
      // noinspection JSIncompatibleTypesComparison
      if (value || value === 0) {
        const num = String(value).replaceAll(',', '')
        formattedValue = parseFloat(Number(num).toFixed(2))
      }
      let separator = ','
      if (langFR && currency) {
        separator = ' '
      }
      return (
        <NumberFormat
          {...other}
          isAllowed={({ floatValue }) => {
            if (!floatValue && floatValue !== 0) {
              return true
            }
            if (maxInput && !minInput) {
              return floatValue <= Number(maxInput)
            } else if (minInput && !maxInput) {
              return floatValue >= Number(minInput)
            } else if (maxInput && minInput) {
              return (
                floatValue <= Number(maxInput) && floatValue >= Number(minInput)
              )
            }
            return true
          }}
          value={typeof props.value === 'object' ? '' : formattedValue}
          defaultValue=''
          thousandSeparator={separator}
          isNumericString={false}
          getInputRef={inputRef}
          allowNegative={false}
          suffix={langFR && currency ? ' $' : ''}
          prefix={!langFR && currency ? '$' : ''}
          allowLeadingZeros={false}
          onValueChange={values => {
            onChange({
              target: {
                name: props.name,
                value: values.value
              }
            })
          }}
        />
      )
    },
    [maxInput, minInput, currency, langFR]
  )

  const baseProps = {
    inputComponent: NumberFormatWithMinAndMax
  }
  // if (currency) {
  //   baseProps.startAdornment = (
  //     <InputAdornment position='start'>CAD</InputAdornment>
  //   )
  // }
  if (isPhone) {
    baseProps.inputComponent = allowPhoneExtension
      ? PhoneFormatWithExtension
      : PhoneFormat
    // baseProps.startAdornment = (
    //   <InputAdornment position='start'>+1</InputAdornment>
    // )
  }

  if (readOnly) {
    return (
      <Typography>
        {currency ? <CurrencyFormated value={value} /> : value}
      </Typography>
    )
  }

  if (isPhone) {
    return (
      <Grid container wrap='nowrap'>
        <Grid item>
          <MUTextField
            defaultValue='1'
            useMultiuser={useMultiuser}
            formId={formId}
            disabled={disabled}
            style={{ marginTop: 5, width: 65 }}
            id={'other.' + id}
            variant='outlined'
            inputProps={{
              maxLength: 5
            }}
            InputProps={{
              inputComponent: PhoneCountryCodeFormat
            }}
          />
        </Grid>
        <Grid item xs>
          <MUTextField
            useMultiuser={useMultiuser}
            formId={formId}
            disabled={disabled}
            style={{ marginTop: 5 }}
            label={showFieldLabel && title}
            id={id}
            fullWidth
            variant='outlined'
            InputProps={baseProps}
            helperText={helper}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <MUTextField
      id={id}
      useMultiuser={useMultiuser}
      formId={formId}
      disabled={disabled}
      style={{ marginTop: 5 }}
      label={showFieldLabel && title}
      fullWidth
      variant='outlined'
      InputProps={baseProps}
      helperText={helper}
      maxInput={maxInput}
      minInput={minInput}
      type='number'
    />
  )
}

export function FormEditorTextFieldNumeric ({
  typeProps = {},
  french,
  showPrintProps,
  showPdfProps,
  depth,
  editMode,
  title,
  ...props
}) {
  const dispatch = useDispatch()
  const {
    required,
    allowPhoneExtension,
    printPageBreakBefore,
    isPhone,
    forceRequired,
    currency,
    showFieldLabel,
    printLabelPlacement,
    max,
    min
  } = typeProps

  if (!editMode) {
    return (
      <FormTextFieldNumeric typeProps={typeProps} title={title} {...props} />
    )
  }

  const { connectedField } = getMainConnected({ typeProps, ...props })
  const forceReadOnly = connectedField && connectedField.readOnly
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      {!showPdfProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField) || showPrintProps}
              disabled={!connectedField || showPrintProps || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.required
                delete toSet.min
                delete toSet.max
                delete toSet.allowPhoneExtension
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {forceReadOnly && !showPdfProps ? (
        <Alert severity='info' variant='outlined'>
          <Trans>
            You can't change this element to an input. Either connected field or
            whole form is marked as read only
          </Trans>
        </Alert>
      ) : (
        !readOnly &&
        !showPdfProps && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(showFieldLabel)}
                  disabled={readOnly}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.showFieldLabel = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show label in field?</Trans>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly || forceRequired}
                  checked={Boolean(required || forceRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />
          </>
        )
      )}

      {!forceReadOnly && !showPdfProps && (
        <h6 style={{ marginBottom: 5, marginTop: 10 }}>Input restrictions</h6>
      )}
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(isPhone)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.isPhone = e.target.checked
                delete toSet.allowPhoneExtension
                if (e.target.checked) {
                  delete toSet.currency
                  delete toSet.min
                  delete toSet.max
                }
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Is phone number?</Trans>}
        />

        {isPhone && !readOnly && !showPdfProps && (
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(allowPhoneExtension)}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.allowPhoneExtension = e.target.checked
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            }
            label={<Trans>Allow phone number extension?</Trans>}
          />
        )}
      </div>

      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(currency)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.currency = e.target.checked
              if (e.target.checked) {
                delete toSet.isPhone
                delete toSet.allowPhoneExtension
              }
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Is currency?</Trans>}
      />

      {!readOnly && !showPdfProps && (
        <Grid container>
          <Grid item xs style={{ padding: 5 }}>
            <TextField
              label={<Trans>Max input</Trans>}
              disabled={Boolean(isPhone)}
              value={max || ''}
              InputProps={{ inputComponent: NumberFormatCustom }}
              variant='outlined'
              fullWidth
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.max = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          </Grid>
          <Grid item xs style={{ padding: 5 }}>
            <TextField
              label={<Trans>Min input</Trans>}
              disabled={Boolean(isPhone)}
              value={min || ''}
              InputProps={{ inputComponent: NumberFormatCustom }}
              variant='outlined'
              fullWidth
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.min = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          </Grid>
        </Grid>
      )}

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextFieldNumeric}
        allowReadOnlyFields
        clearOnReadOnlyField={['required', 'min', 'max', 'allowPhoneExtension']}
      />
    </Grid>
  )
}

// function PhoneFormatWithExtension (props) {
//   const { inputRef, onChange, ...other } = props
//   const inputValue = other.value || ''
//   let format = `###-###-####, ${myI18n._(t`Ext.`)}`
//   // if (inputValue.length === 10) {
//   //   format = '###-###-#####'
//   // } else
//   if (inputValue.length >= 10) {
//     format = `###-###-####, ${myI18n._(t`Ext.`)} ####`
//   }

//   return (
//     <NumberFormat
//       {...other}
//       format={format}
//       mask={inputValue.length >= 10 ? ' ' : '_'}
//       isNumericString
//       type='tel'
//       getInputRef={inputRef}
//       allowNegative={false}
//       onValueChange={values => {
//         onChange({
//           target: {
//             name: props.name,
//             value: values.value
//           }
//         })
//       }}
//     />
//   )
// }

function NumberFormatCustom (props) {
  const { inputRef, onChange, id, value = '', ...other } = props
  let formattedValue = ''
  // noinspection JSIncompatibleTypesComparison
  if (value || value === 0) {
    const num = String(value).replaceAll(',', '')
    formattedValue = parseFloat(Number(num).toFixed(2))
  }
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : formattedValue}
      defaultValue=''
      thousandSeparator=','
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}
