import { EgretLoadable } from 'egret'
import { authRoles } from '../../auth/authRoles'
import { TestFormTabs } from '../grants/TestFormsTab'

const Forms = EgretLoadable({
  loader: () => import('../internal/Forms')
})

const Form = EgretLoadable({
  loader: () => import('./Form')
})

const FormEditor = EgretLoadable({
  loader: () => import('../forms/FormWizard')
})

const CommuniyFoundingApplications = EgretLoadable({
  loader: () => import('../forms/CommunityFoundingApplications')
})

const OpportunityViewWithForm = EgretLoadable({
  loader: () => import('../forms/OpportunityViewWithForm')
})

const TestFormsTab = TestFormTabs

const formsRoutes = [
  {
    path: '/internal/Forms',
    component: Forms,
    auth: authRoles.pm
  },
  {
    path: '/internal/FormEditor/:id?',
    component: FormEditor,
    auth: authRoles.pm
  },
  {
    path: '/elasticform/:formId/:id?/:preview?',
    component: Form
  },
  {
    path: '/opportunityFormView/:formId/:opportunityId/:objectId/:printConfig?',
    component: OpportunityViewWithForm
  },
  // {
  //   path: '/form/CommunityFundingApplications',
  //   component: CommuniyFoundingApplications
  // },
  {
    path: '/forms/TestFormsTab',
    component: TestFormsTab
  }
]

export default formsRoutes
