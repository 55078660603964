/**
 * @fileoverview gRPC-Web generated client stub for Mo_multiuser
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.Mo_multiuser = require('./Multiuser_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Mo_multiuser.MultiuserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Mo_multiuser.MultiuserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UserInfo>}
 */
const methodDescriptor_Multiuser_UserStartedEditingForm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserStartedEditingForm',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UserInfo,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserInfo.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userStartedEditingForm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserStartedEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserStartedEditingForm);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userStartedEditingForm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserStartedEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserStartedEditingForm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UserInfo>}
 */
const methodDescriptor_Multiuser_UserExitedEditingForm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserExitedEditingForm',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UserInfo,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserInfo.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userExitedEditingForm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserExitedEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserExitedEditingForm);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserInfo>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userExitedEditingForm =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserExitedEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserExitedEditingForm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.FormAndUserInfoReceive>}
 */
const methodDescriptor_Multiuser_UserUpdatedInfoAboutSelf = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserUpdatedInfoAboutSelf',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.FormAndUserInfoReceive,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FormAndUserInfoReceive.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormAndUserInfoReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userUpdatedInfoAboutSelf =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserUpdatedInfoAboutSelf',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserUpdatedInfoAboutSelf);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormAndUserInfoReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userUpdatedInfoAboutSelf =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserUpdatedInfoAboutSelf',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserUpdatedInfoAboutSelf);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UsersCount>}
 */
const methodDescriptor_Multiuser_UsersCountInFormChanged = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UsersCountInFormChanged',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UsersCount,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UsersCount.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UsersCount>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.usersCountInFormChanged =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UsersCountInFormChanged',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UsersCountInFormChanged);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UsersCount>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.usersCountInFormChanged =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UsersCountInFormChanged',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UsersCountInFormChanged);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.FieldLockOperation>}
 */
const methodDescriptor_Multiuser_UserChangedLockFieldStatus = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserChangedLockFieldStatus',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.FieldLockOperation,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldLockOperation.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldLockOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userChangedLockFieldStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangedLockFieldStatus',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangedLockFieldStatus);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldLockOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userChangedLockFieldStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangedLockFieldStatus',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangedLockFieldStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_UserCommitedFieldAsynchronously = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserCommitedFieldAsynchronously',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userCommitedFieldAsynchronously =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserCommitedFieldAsynchronously',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserCommitedFieldAsynchronously);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userCommitedFieldAsynchronously =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserCommitedFieldAsynchronously',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserCommitedFieldAsynchronously);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.FormCacheReceive>}
 */
const methodDescriptor_Multiuser_UserSubmitedFormCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserSubmitedFormCache',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.FormCacheReceive,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FormCacheReceive.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormCacheReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userSubmitedFormCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSubmitedFormCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSubmitedFormCache);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormCacheReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userSubmitedFormCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSubmitedFormCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSubmitedFormCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.RequestSFSaveReceive>}
 */
const methodDescriptor_Multiuser_UserRequestedSFSave = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserRequestedSFSave',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.RequestSFSaveReceive,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RequestSFSaveReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userRequestedSFSave =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserRequestedSFSave',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserRequestedSFSave);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RequestSFSaveReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userRequestedSFSave =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserRequestedSFSave',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserRequestedSFSave);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.SaveToSFCompleted>}
 */
const methodDescriptor_Multiuser_UserCompletedSaveToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserCompletedSaveToSF',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.SaveToSFCompleted,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.SaveToSFCompleted.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.SaveToSFCompleted>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userCompletedSaveToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserCompletedSaveToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserCompletedSaveToSF);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.SaveToSFCompleted>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userCompletedSaveToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserCompletedSaveToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserCompletedSaveToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_Multiuser_ServerRequestSubmitCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ServerRequestSubmitCache',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UserID,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.serverRequestSubmitCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/ServerRequestSubmitCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ServerRequestSubmitCache);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.serverRequestSubmitCache =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/ServerRequestSubmitCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ServerRequestSubmitCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_Multiuser_ServerRequestSubmitToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ServerRequestSubmitToSF',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UserID,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.serverRequestSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/ServerRequestSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ServerRequestSubmitToSF);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.serverRequestSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/ServerRequestSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ServerRequestSubmitToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UserOperation>}
 */
const methodDescriptor_Multiuser_UserEndedToSubmitToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserEndedToSubmitToSF',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UserOperation,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserOperation.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userEndedToSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserEndedToSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserEndedToSubmitToSF);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userEndedToSubmitToSF =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserEndedToSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserEndedToSubmitToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.CursorEventReceive>}
 */
const methodDescriptor_Multiuser_UserSentCursorEvent = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserSentCursorEvent',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.CursorEventReceive,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.CursorEventReceive.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.CursorEventReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userSentCursorEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentCursorEvent);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.CursorEventReceive>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userSentCursorEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentCursorEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.FieldCommentOperation>}
 */
const methodDescriptor_Multiuser_UserChangeFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserChangeFieldComment',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.FieldCommentOperation,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldCommentOperation.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldCommentOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userChangeFieldComment =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangeFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangeFieldComment);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldCommentOperation>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userChangeFieldComment =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserChangeFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserChangeFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.ChatMessage>}
 */
const methodDescriptor_Multiuser_UserSentChatMessage = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UserSentChatMessage',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.ChatMessage,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.ChatMessage.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.ChatMessage>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.userSentChatMessage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentChatMessage);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.ChatMessage>}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.userSentChatMessage =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/Mo_multiuser.Multiuser/UserSentChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UserSentChatMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndUser,
 *   !proto.Mo_multiuser.UserSessionInfo>}
 */
const methodDescriptor_Multiuser_GetUserInfo = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetUserInfo',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndUser,
  proto.Mo_multiuser.UserSessionInfo,
  /**
   * @param {!proto.Mo_multiuser.FormAndUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserSessionInfo.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserSessionInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserSessionInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserInfo,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndUser} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserSessionInfo>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UsersCount>}
 */
const methodDescriptor_Multiuser_GetUsersCount = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetUsersCount',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UsersCount,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UsersCount.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UsersCount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UsersCount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getUsersCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUsersCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUsersCount,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UsersCount>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getUsersCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUsersCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUsersCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UsersSessionsList>}
 */
const methodDescriptor_Multiuser_GetAllConnectedUsers = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetAllConnectedUsers',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UsersSessionsList,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UsersSessionsList.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UsersSessionsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UsersSessionsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getAllConnectedUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsers',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsers,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UsersSessionsList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getAllConnectedUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsers',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.UserSessionsInfoList>}
 */
const methodDescriptor_Multiuser_GetAllConnectedUsersWithInfo = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetAllConnectedUsersWithInfo',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.UserSessionsInfoList,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserSessionsInfoList.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserSessionsInfoList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserSessionsInfoList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getAllConnectedUsersWithInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsersWithInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsersWithInfo,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserSessionsInfoList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getAllConnectedUsersWithInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetAllConnectedUsersWithInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetAllConnectedUsersWithInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.IsTrue>}
 */
const methodDescriptor_Multiuser_IsFormInited = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/IsFormInited',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.IsTrue,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.IsTrue.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.IsTrue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.IsTrue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.isFormInited =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsFormInited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsFormInited,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.IsTrue>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.isFormInited =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsFormInited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsFormInited);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.Form,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_InitForm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/InitForm',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.Form,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.Form} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.Form} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.initForm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/InitForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_InitForm,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.Form} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.initForm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/InitForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_InitForm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndUserInfo,
 *   !proto.Mo_multiuser.FormState>}
 */
const methodDescriptor_Multiuser_StartEditingForm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/StartEditingForm',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndUserInfo,
  proto.Mo_multiuser.FormState,
  /**
   * @param {!proto.Mo_multiuser.FormAndUserInfo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FormState.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndUserInfo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FormState)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormState>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.startEditingForm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/StartEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_StartEditingForm,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndUserInfo} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FormState>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.startEditingForm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/StartEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_StartEditingForm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_ExitEditingForm = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ExitEditingForm',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.exitEditingForm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ExitEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ExitEditingForm,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.exitEditingForm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ExitEditingForm',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ExitEditingForm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndUserInfo,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_UpdateUserInfo = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UpdateUserInfo',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndUserInfo,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FormAndUserInfo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndUserInfo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.updateUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateUserInfo,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndUserInfo} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.updateUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateUserInfo',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.Time>}
 */
const methodDescriptor_Multiuser_Ping = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/Ping',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.Time,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.Time.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.Time)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.Time>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.ping =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/Ping',
      request,
      metadata || {},
      methodDescriptor_Multiuser_Ping,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.Time>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.ping =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/Ping',
      request,
      metadata || {},
      methodDescriptor_Multiuser_Ping);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndField,
 *   !proto.Mo_multiuser.IsTrue>}
 */
const methodDescriptor_Multiuser_IsFieldLocked = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/IsFieldLocked',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndField,
  proto.Mo_multiuser.IsTrue,
  /**
   * @param {!proto.Mo_multiuser.FormAndField} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.IsTrue.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.IsTrue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.IsTrue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.isFieldLocked =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsFieldLocked',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsFieldLocked,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.IsTrue>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.isFieldLocked =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsFieldLocked',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsFieldLocked);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.LockPointer,
 *   !proto.Mo_multiuser.LockStateType>}
 */
const methodDescriptor_Multiuser_IsLockCommited = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/IsLockCommited',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.LockPointer,
  proto.Mo_multiuser.LockStateType,
  /**
   * @param {!proto.Mo_multiuser.LockPointer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockStateType.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.LockPointer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockStateType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockStateType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.isLockCommited =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsLockCommited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsLockCommited,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.LockPointer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockStateType>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.isLockCommited =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/IsLockCommited',
      request,
      metadata || {},
      methodDescriptor_Multiuser_IsLockCommited);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndField,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_Multiuser_GetUserLockingField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetUserLockingField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndField,
  proto.Mo_multiuser.UserID,
  /**
   * @param {!proto.Mo_multiuser.FormAndField} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getUserLockingField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserLockingField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserLockingField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getUserLockingField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetUserLockingField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetUserLockingField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.LocksListLite>}
 */
const methodDescriptor_Multiuser_GetIDsOfLocksNotSubmitedToCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetIDsOfLocksNotSubmitedToCache',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.LocksListLite,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksListLite.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getIDsOfLocksNotSubmitedToCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksNotSubmitedToCache,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getIDsOfLocksNotSubmitedToCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksNotSubmitedToCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.LocksList>}
 */
const methodDescriptor_Multiuser_GetLocksNotSubmitedToCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetLocksNotSubmitedToCache',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.LocksList,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksList.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getLocksNotSubmitedToCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksNotSubmitedToCache,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getLocksNotSubmitedToCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksNotSubmitedToCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksNotSubmitedToCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndField,
 *   !proto.Mo_multiuser.LocksListLite>}
 */
const methodDescriptor_Multiuser_GetIDsOfLocksForField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetIDsOfLocksForField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndField,
  proto.Mo_multiuser.LocksListLite,
  /**
   * @param {!proto.Mo_multiuser.FormAndField} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksListLite.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getIDsOfLocksForField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksForField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getIDsOfLocksForField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLocksForField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndField,
 *   !proto.Mo_multiuser.LocksList>}
 */
const methodDescriptor_Multiuser_GetLocksForField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetLocksForField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndField,
  proto.Mo_multiuser.LocksList,
  /**
   * @param {!proto.Mo_multiuser.FormAndField} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksList.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getLocksForField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksForField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getLocksForField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLocksForField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLocksForField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.LocksListLite>}
 */
const methodDescriptor_Multiuser_GetIDsOfLockedFields = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetIDsOfLockedFields',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.LocksListLite,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksListLite.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getIDsOfLockedFields =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLockedFields,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getIDsOfLockedFields =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetIDsOfLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetIDsOfLockedFields);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.LocksList>}
 */
const methodDescriptor_Multiuser_GetLockedFields = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetLockedFields',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.LocksList,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LocksList.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LocksList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LocksList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getLockedFields =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLockedFields,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LocksList>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getLockedFields =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetLockedFields',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetLockedFields);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndField,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_LockField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/LockField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndField,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.Mo_multiuser.FormAndField} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.lockField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndField} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.lockField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndFieldAndLockIDAndContent,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_UpdateLockedFieldValue = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UpdateLockedFieldValue',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndFieldAndLockIDAndContent,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.updateLockedFieldValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateLockedFieldValue',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateLockedFieldValue,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.updateLockedFieldValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateLockedFieldValue',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateLockedFieldValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndFieldAndLockIDAndContent,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_CommitLockedField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CommitLockedField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndFieldAndLockIDAndContent,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.commitLockedField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitLockedField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.commitLockedField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitLockedField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndFieldAndLockID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_CancelLockedField = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CancelLockedField',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndFieldAndLockID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FormAndFieldAndLockID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.cancelLockedField =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CancelLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CancelLockedField,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.cancelLockedField =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CancelLockedField',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CancelLockedField);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndFieldAndFieldValue,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_CommitFieldImmediately = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CommitFieldImmediately',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndFieldAndFieldValue,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.Mo_multiuser.FormAndFieldAndFieldValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndFieldValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.commitFieldImmediately =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldImmediately,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndFieldAndFieldValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.commitFieldImmediately =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldImmediately);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.AsyncLock,
 *   !proto.Mo_multiuser.LockID>}
 */
const methodDescriptor_Multiuser_CreateAsynchronousLock = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CreateAsynchronousLock',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.AsyncLock,
  proto.Mo_multiuser.LockID,
  /**
   * @param {!proto.Mo_multiuser.AsyncLock} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.LockID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.AsyncLock} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.LockID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.LockID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.createAsynchronousLock =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CreateAsynchronousLock',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CreateAsynchronousLock,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.AsyncLock} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.LockID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.createAsynchronousLock =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CreateAsynchronousLock',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CreateAsynchronousLock);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormAndFieldsAndValues,
 *   !proto.Mo_multiuser.FieldsAndLocks>}
 */
const methodDescriptor_Multiuser_MassiveCommitFieldsImmediately = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/MassiveCommitFieldsImmediately',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormAndFieldsAndValues,
  proto.Mo_multiuser.FieldsAndLocks,
  /**
   * @param {!proto.Mo_multiuser.FormAndFieldsAndValues} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldsAndLocks.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormAndFieldsAndValues} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FieldsAndLocks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldsAndLocks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.massiveCommitFieldsImmediately =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/MassiveCommitFieldsImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_MassiveCommitFieldsImmediately,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormAndFieldsAndValues} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FieldsAndLocks>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.massiveCommitFieldsImmediately =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/MassiveCommitFieldsImmediately',
      request,
      metadata || {},
      methodDescriptor_Multiuser_MassiveCommitFieldsImmediately);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.FormCacheListLite>}
 */
const methodDescriptor_Multiuser_GetListOfCachedFormVersions = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetListOfCachedFormVersions',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.FormCacheListLite,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FormCacheListLite.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FormCacheListLite)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormCacheListLite>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getListOfCachedFormVersions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetListOfCachedFormVersions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetListOfCachedFormVersions,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FormCacheListLite>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getListOfCachedFormVersions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetListOfCachedFormVersions',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetListOfCachedFormVersions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormCachePointer,
 *   !proto.Mo_multiuser.FormCache>}
 */
const methodDescriptor_Multiuser_GetFormCacheByID = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetFormCacheByID',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormCachePointer,
  proto.Mo_multiuser.FormCache,
  /**
   * @param {!proto.Mo_multiuser.FormCachePointer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FormCache.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormCachePointer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FormCache)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormCache>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getFormCacheByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetFormCacheByID',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetFormCacheByID,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormCachePointer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FormCache>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getFormCacheByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetFormCacheByID',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetFormCacheByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormIDString,
 *   !proto.Mo_multiuser.FormCache>}
 */
const methodDescriptor_Multiuser_GetNewestFormCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetNewestFormCache',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormIDString,
  proto.Mo_multiuser.FormCache,
  /**
   * @param {!proto.Mo_multiuser.FormIDString} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FormCache.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FormCache)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FormCache>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getNewestFormCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetNewestFormCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetNewestFormCache,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormIDString} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FormCache>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getNewestFormCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetNewestFormCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetNewestFormCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormToSubmit,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_SubmitFormCache = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/SubmitFormCache',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormToSubmit,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FormToSubmit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormToSubmit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.submitFormCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SubmitFormCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SubmitFormCache,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormToSubmit} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.submitFormCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SubmitFormCache',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SubmitFormCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FormOperation,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_ReportSubmitToSF = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ReportSubmitToSF',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FormOperation,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FormOperation} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FormOperation} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.reportSubmitToSF =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReportSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReportSubmitToSF,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FormOperation} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.reportSubmitToSF =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReportSubmitToSF',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReportSubmitToSF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.SaveToSFCompletedReport,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_ReportSaveToSFCompleted = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/ReportSaveToSFCompleted',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.SaveToSFCompletedReport,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.reportSaveToSFCompleted =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReportSaveToSFCompleted',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReportSaveToSFCompleted,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.reportSaveToSFCompleted =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/ReportSaveToSFCompleted',
      request,
      metadata || {},
      methodDescriptor_Multiuser_ReportSaveToSFCompleted);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.RequestSFSaveMessage,
 *   !proto.Mo_multiuser.RequestSFSaveResponde>}
 */
const methodDescriptor_Multiuser_RequestSFSave = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/RequestSFSave',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.RequestSFSaveMessage,
  proto.Mo_multiuser.RequestSFSaveResponde,
  /**
   * @param {!proto.Mo_multiuser.RequestSFSaveMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.RequestSFSaveResponde)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.RequestSFSaveResponde>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.requestSFSave =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/RequestSFSave',
      request,
      metadata || {},
      methodDescriptor_Multiuser_RequestSFSave,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.RequestSFSaveResponde>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.requestSFSave =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/RequestSFSave',
      request,
      metadata || {},
      methodDescriptor_Multiuser_RequestSFSave);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.CursorEvent,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_SendCursorEvent = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/SendCursorEvent',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.CursorEvent,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.CursorEvent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.CursorEvent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.sendCursorEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendCursorEvent,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.CursorEvent} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.sendCursorEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendCursorEvent',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendCursorEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentID,
 *   !proto.Mo_multiuser.FieldComment>}
 */
const methodDescriptor_Multiuser_GetFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentID,
  proto.Mo_multiuser.FieldComment,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.FieldComment.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.FieldComment)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.FieldComment>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.FieldComment>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentID,
 *   !proto.Mo_multiuser.UserID>}
 */
const methodDescriptor_Multiuser_WhoIsLockingFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/WhoIsLockingFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentID,
  proto.Mo_multiuser.UserID,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.UserID.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.UserID)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.UserID>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.whoIsLockingFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/WhoIsLockingFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_WhoIsLockingFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.UserID>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.whoIsLockingFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/WhoIsLockingFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_WhoIsLockingFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentToSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_LockFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/LockFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentToSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentToSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.lockFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.lockFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/LockFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_LockFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentToSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_UpdateFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/UpdateFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentToSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentToSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.updateFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.updateFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/UpdateFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_UpdateFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.FieldCommentToSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_CommitFieldComment = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/CommitFieldComment',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.FieldCommentToSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.FieldCommentToSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.commitFieldComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldComment,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.FieldCommentToSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.commitFieldComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/CommitFieldComment',
      request,
      metadata || {},
      methodDescriptor_Multiuser_CommitFieldComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.ChatMessageCollectionRequest,
 *   !proto.Mo_multiuser.ChatMessageCollection>}
 */
const methodDescriptor_Multiuser_GetChatMessages = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetChatMessages',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.ChatMessageCollectionRequest,
  proto.Mo_multiuser.ChatMessageCollection,
  /**
   * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.ChatMessageCollection.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.ChatMessageCollection)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.ChatMessageCollection>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getChatMessages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessages',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessages,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.ChatMessageCollection>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getChatMessages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessages',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.ChatMessageCollectionRequest,
 *   !proto.Mo_multiuser.MessageCount>}
 */
const methodDescriptor_Multiuser_GetChatMessagesCount = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/GetChatMessagesCount',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.ChatMessageCollectionRequest,
  proto.Mo_multiuser.MessageCount,
  /**
   * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Mo_multiuser.MessageCount.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Mo_multiuser.MessageCount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Mo_multiuser.MessageCount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.getChatMessagesCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessagesCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessagesCount,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Mo_multiuser.MessageCount>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.getChatMessagesCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/GetChatMessagesCount',
      request,
      metadata || {},
      methodDescriptor_Multiuser_GetChatMessagesCount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Mo_multiuser.ChatMessageSend,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Multiuser_SendChatMessage = new grpc.web.MethodDescriptor(
  '/Mo_multiuser.Multiuser/SendChatMessage',
  grpc.web.MethodType.UNARY,
  proto.Mo_multiuser.ChatMessageSend,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.Mo_multiuser.ChatMessageSend} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.Mo_multiuser.ChatMessageSend} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Mo_multiuser.MultiuserClient.prototype.sendChatMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendChatMessage,
      callback);
};


/**
 * @param {!proto.Mo_multiuser.ChatMessageSend} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.Mo_multiuser.MultiuserPromiseClient.prototype.sendChatMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Mo_multiuser.Multiuser/SendChatMessage',
      request,
      metadata || {},
      methodDescriptor_Multiuser_SendChatMessage);
};


module.exports = proto.Mo_multiuser;

