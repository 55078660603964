import SFAuthService, { NO_USER } from '../SFAuthService'
import { t } from '@lingui/macro'
import sfOauthConfig from '../sfAuthConfig'

const trans = [
  t`Request Payment`,
  t`Scheduled`,
  t`Check request`,
  t`Paid`,
  t`Cancelled`,
  t`Hold`,
  t`Payment Problem`
]

export const getPaymentsForOpportunity = opportunityId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const selectFields = [
    'FGM_Base__Scheduled_Payment_Date__c',
    'FGM_Base__Grantee_Report__c',
    'FGM_Base__Status__c',
    'FGM_Base__Amount__c',
    'Name',
    'Id',
    'Percentage_New__c'
  ]
  if (!sfOauthConfig.isIcce) {
    selectFields.push('FGM_Base__Awarded_Amount__c')
  }
  return conn
    .sobject('FGM_Base__Payment__c')
    .find({
      FGM_Base__Request__c: opportunityId
    })
    .select(selectFields)
}

export const savePayment = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Payment__c').update({
    Id: values.id,
    FGM_Base__Scheduled_Payment_Date__c: values.dueDate,
    FGM_Base__Amount__c: values.base
  })
}

export const createPayments = array => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Payment__c').create(
    array.map(ob => {
      return {
        FGM_Base__Payment_Type__c: 'Wire Transfer',
        FGM_Base__Request__c: ob.opportunityId,
        FGM_Base__Grantee_Report__c: ob.reportId,
        FGM_Base__Amount__c: ob.base,
        FGM_Base__Scheduled_Payment_Date__c: ob.dueDate
      }
    })
  )
}

export const createPayment = ({ opportunityId, reportId, dueDate, base }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Payment__c').create({
    FGM_Base__Request__c: opportunityId,
    FGM_Base__Grantee_Report__c: reportId,
    FGM_Base__Amount__c: base,
    FGM_Base__Scheduled_Payment_Date__c: dueDate
  })
}

export const getPayments = reportsIds => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Payment__c')
    .find({
      FGM_Base__Grantee_Report__c: { $in: [...reportsIds] }
    })
    .select(
      'FGM_Base__Scheduled_Payment_Date__c, FGM_Base__Grantee_Report__c, FGM_Base__Status__c, FGM_Base__Amount__c, FGM_Base__Awarded_Amount__c, Name, Id, Percentage_New__c'
    )
}
