// source: Multiuser.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
var jspb = require('google-protobuf')
var goog = jspb
var global = Function('return this')()

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')
goog.object.extend(proto, google_protobuf_duration_pb)
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
goog.object.extend(proto, google_protobuf_timestamp_pb)
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
goog.object.extend(proto, google_protobuf_empty_pb)
goog.exportSymbol('proto.Mo_multiuser.AsyncLock', null, global)
goog.exportSymbol('proto.Mo_multiuser.ChatMessage', null, global)
goog.exportSymbol('proto.Mo_multiuser.ChatMessageCollection', null, global)
goog.exportSymbol(
  'proto.Mo_multiuser.ChatMessageCollectionRequest',
  null,
  global
)
goog.exportSymbol('proto.Mo_multiuser.ChatMessageSend', null, global)
goog.exportSymbol('proto.Mo_multiuser.CursorEvent', null, global)
goog.exportSymbol('proto.Mo_multiuser.CursorEventReceive', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldAndValue', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldComment', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldCommentID', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldCommentOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldCommentToSend', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldIDAndLockID', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldIDLockIDFieldValue', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldLock', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldLockOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.FieldsAndLocks', null, global)
goog.exportSymbol('proto.Mo_multiuser.Form', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormAndField', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormAndFieldAndFieldValue', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormAndFieldAndLockID', null, global)
goog.exportSymbol(
  'proto.Mo_multiuser.FormAndFieldAndLockIDAndContent',
  null,
  global
)
goog.exportSymbol('proto.Mo_multiuser.FormAndFieldsAndValues', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormAndUser', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormAndUserInfo', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormAndUserInfoReceive', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormCache', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormCacheListLite', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormCacheLite', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormCachePointer', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormCacheReceive', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormCacheType', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormIDString', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormPointer', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormState', null, global)
goog.exportSymbol('proto.Mo_multiuser.FormToSubmit', null, global)
goog.exportSymbol('proto.Mo_multiuser.IsTrue', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockID', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockPointer', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockState', null, global)
goog.exportSymbol('proto.Mo_multiuser.LockStateType', null, global)
goog.exportSymbol('proto.Mo_multiuser.LocksList', null, global)
goog.exportSymbol('proto.Mo_multiuser.LocksListLite', null, global)
goog.exportSymbol('proto.Mo_multiuser.MessageCount', null, global)
goog.exportSymbol('proto.Mo_multiuser.OperationResult', null, global)
goog.exportSymbol('proto.Mo_multiuser.RequestSFSaveMessage', null, global)
goog.exportSymbol('proto.Mo_multiuser.RequestSFSaveReceive', null, global)
goog.exportSymbol('proto.Mo_multiuser.RequestSFSaveResponde', null, global)
goog.exportSymbol('proto.Mo_multiuser.RequestStatus', null, global)
goog.exportSymbol('proto.Mo_multiuser.SaveToSFCompleted', null, global)
goog.exportSymbol('proto.Mo_multiuser.SaveToSFCompletedReport', null, global)
goog.exportSymbol('proto.Mo_multiuser.Time', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserAndFormAndField', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserEditedField', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserID', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserInfo', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserOperation', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserSessionInfo', null, global)
goog.exportSymbol('proto.Mo_multiuser.UserSessionsInfoList', null, global)
goog.exportSymbol('proto.Mo_multiuser.UsersCount', null, global)
goog.exportSymbol('proto.Mo_multiuser.UsersSessionsList', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.IsTrue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.IsTrue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.IsTrue.displayName = 'proto.Mo_multiuser.IsTrue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.Time = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.Time, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.Time.displayName = 'proto.Mo_multiuser.Time'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndUserInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormAndUserInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndUserInfo.displayName =
    'proto.Mo_multiuser.FormAndUserInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndUserInfoReceive = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormAndUserInfoReceive, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndUserInfoReceive.displayName =
    'proto.Mo_multiuser.FormAndUserInfoReceive'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndUser = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormAndUser, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndUser.displayName = 'proto.Mo_multiuser.FormAndUser'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserAndFormAndField = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserAndFormAndField, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserAndFormAndField.displayName =
    'proto.Mo_multiuser.UserAndFormAndField'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserSessionInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserSessionInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserSessionInfo.displayName =
    'proto.Mo_multiuser.UserSessionInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserInfo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserInfo, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserInfo.displayName = 'proto.Mo_multiuser.UserInfo'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserID.displayName = 'proto.Mo_multiuser.UserID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UsersSessionsList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.UsersSessionsList.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.UsersSessionsList, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UsersSessionsList.displayName =
    'proto.Mo_multiuser.UsersSessionsList'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserSessionsInfoList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.UserSessionsInfoList.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.UserSessionsInfoList, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserSessionsInfoList.displayName =
    'proto.Mo_multiuser.UserSessionsInfoList'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UsersCount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UsersCount, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UsersCount.displayName = 'proto.Mo_multiuser.UsersCount'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserOperation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserOperation.displayName =
    'proto.Mo_multiuser.UserOperation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.UserEditedField = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.UserEditedField, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.UserEditedField.displayName =
    'proto.Mo_multiuser.UserEditedField'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LockID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.LockID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LockID.displayName = 'proto.Mo_multiuser.LockID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.AsyncLock = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.AsyncLock, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.AsyncLock.displayName = 'proto.Mo_multiuser.AsyncLock'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LockPointer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.LockPointer, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LockPointer.displayName = 'proto.Mo_multiuser.LockPointer'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormAndFieldAndFieldValue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndFieldAndFieldValue.displayName =
    'proto.Mo_multiuser.FormAndFieldAndFieldValue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndFieldsAndValues = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FormAndFieldsAndValues.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FormAndFieldsAndValues, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndFieldsAndValues.displayName =
    'proto.Mo_multiuser.FormAndFieldsAndValues'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldsAndLocks = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FieldsAndLocks.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FieldsAndLocks, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldsAndLocks.displayName =
    'proto.Mo_multiuser.FieldsAndLocks'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldIDAndLockID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldIDAndLockID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldIDAndLockID.displayName =
    'proto.Mo_multiuser.FieldIDAndLockID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldLockOperation = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FieldLockOperation.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FieldLockOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldLockOperation.displayName =
    'proto.Mo_multiuser.FieldLockOperation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldAndValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldAndValue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldAndValue.displayName =
    'proto.Mo_multiuser.FieldAndValue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldLock = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldLock, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldLock.displayName = 'proto.Mo_multiuser.FieldLock'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldIDLockIDFieldValue, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldIDLockIDFieldValue.displayName =
    'proto.Mo_multiuser.FieldIDLockIDFieldValue'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndFieldAndLockID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormAndFieldAndLockID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndFieldAndLockID.displayName =
    'proto.Mo_multiuser.FormAndFieldAndLockID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormAndFieldAndLockIDAndContent, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.displayName =
    'proto.Mo_multiuser.FormAndFieldAndLockIDAndContent'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormAndField = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormAndField, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormAndField.displayName =
    'proto.Mo_multiuser.FormAndField'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LocksListLite = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.LocksListLite.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.LocksListLite, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LocksListLite.displayName =
    'proto.Mo_multiuser.LocksListLite'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LocksList = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.LocksList.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.LocksList, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LocksList.displayName = 'proto.Mo_multiuser.LocksList'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.LockStateType = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.LockStateType, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.LockStateType.displayName =
    'proto.Mo_multiuser.LockStateType'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormState = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormState, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormState.displayName = 'proto.Mo_multiuser.FormState'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormIDString = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormIDString, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormIDString.displayName =
    'proto.Mo_multiuser.FormIDString'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormCachePointer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormCachePointer, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormCachePointer.displayName =
    'proto.Mo_multiuser.FormCachePointer'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormPointer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormPointer, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormPointer.displayName = 'proto.Mo_multiuser.FormPointer'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.Form = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.Form, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.Form.displayName = 'proto.Mo_multiuser.Form'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormCache = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormCache, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormCache.displayName = 'proto.Mo_multiuser.FormCache'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormCacheListLite = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FormCacheListLite.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FormCacheListLite, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormCacheListLite.displayName =
    'proto.Mo_multiuser.FormCacheListLite'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormCacheLite = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormCacheLite, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormCacheLite.displayName =
    'proto.Mo_multiuser.FormCacheLite'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormCacheReceive = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FormCacheReceive.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FormCacheReceive, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormCacheReceive.displayName =
    'proto.Mo_multiuser.FormCacheReceive'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormToSubmit = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.FormToSubmit.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.FormToSubmit, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormToSubmit.displayName =
    'proto.Mo_multiuser.FormToSubmit'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FormOperation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FormOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FormOperation.displayName =
    'proto.Mo_multiuser.FormOperation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.SaveToSFCompletedReport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.SaveToSFCompletedReport, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.SaveToSFCompletedReport.displayName =
    'proto.Mo_multiuser.SaveToSFCompletedReport'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RequestSFSaveResponde = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RequestSFSaveResponde, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RequestSFSaveResponde.displayName =
    'proto.Mo_multiuser.RequestSFSaveResponde'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RequestSFSaveMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RequestSFSaveMessage, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RequestSFSaveMessage.displayName =
    'proto.Mo_multiuser.RequestSFSaveMessage'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.RequestSFSaveReceive = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.RequestSFSaveReceive, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.RequestSFSaveReceive.displayName =
    'proto.Mo_multiuser.RequestSFSaveReceive'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.SaveToSFCompleted = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.SaveToSFCompleted, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.SaveToSFCompleted.displayName =
    'proto.Mo_multiuser.SaveToSFCompleted'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldCommentID = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldCommentID, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldCommentID.displayName =
    'proto.Mo_multiuser.FieldCommentID'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldCommentToSend = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldCommentToSend, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldCommentToSend.displayName =
    'proto.Mo_multiuser.FieldCommentToSend'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldComment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldComment, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldComment.displayName =
    'proto.Mo_multiuser.FieldComment'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.FieldCommentOperation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.FieldCommentOperation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.FieldCommentOperation.displayName =
    'proto.Mo_multiuser.FieldCommentOperation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.CursorEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.CursorEvent, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.CursorEvent.displayName = 'proto.Mo_multiuser.CursorEvent'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.CursorEventReceive = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.CursorEventReceive, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.CursorEventReceive.displayName =
    'proto.Mo_multiuser.CursorEventReceive'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessageSend = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.ChatMessageSend, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessageSend.displayName =
    'proto.Mo_multiuser.ChatMessageSend'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessageCollection = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Mo_multiuser.ChatMessageCollection.repeatedFields_,
    null
  )
}
goog.inherits(proto.Mo_multiuser.ChatMessageCollection, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessageCollection.displayName =
    'proto.Mo_multiuser.ChatMessageCollection'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessageCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.ChatMessageCollectionRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessageCollectionRequest.displayName =
    'proto.Mo_multiuser.ChatMessageCollectionRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.MessageCount = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.MessageCount, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.MessageCount.displayName =
    'proto.Mo_multiuser.MessageCount'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Mo_multiuser.ChatMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.Mo_multiuser.ChatMessage, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Mo_multiuser.ChatMessage.displayName = 'proto.Mo_multiuser.ChatMessage'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.IsTrue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.IsTrue.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.IsTrue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.IsTrue.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        istrue: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.IsTrue}
 */
proto.Mo_multiuser.IsTrue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.IsTrue()
  return proto.Mo_multiuser.IsTrue.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.IsTrue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.IsTrue}
 */
proto.Mo_multiuser.IsTrue.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIstrue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.IsTrue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.IsTrue.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.IsTrue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.IsTrue.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getIstrue()
  if (f) {
    writer.writeBool(1, f)
  }
}

/**
 * optional bool Istrue = 1;
 * @return {boolean}
 */
proto.Mo_multiuser.IsTrue.prototype.getIstrue = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    1,
    false
  ))
}

/**
 * @param {boolean} value
 * @return {!proto.Mo_multiuser.IsTrue} returns this
 */
proto.Mo_multiuser.IsTrue.prototype.setIstrue = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.Time.prototype.toObject = function (opt_includeInstance) {
    return proto.Mo_multiuser.Time.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.Time} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.Time.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        remainingtime: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          1,
          0.0
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.Time}
 */
proto.Mo_multiuser.Time.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.Time()
  return proto.Mo_multiuser.Time.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.Time} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.Time}
 */
proto.Mo_multiuser.Time.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setRemainingtime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.Time.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.Time.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.Time} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.Time.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getRemainingtime()
  if (f !== 0.0) {
    writer.writeDouble(1, f)
  }
}

/**
 * optional double RemainingTime = 1;
 * @return {number}
 */
proto.Mo_multiuser.Time.prototype.getRemainingtime = function () {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    1,
    0.0
  ))
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.Time} returns this
 */
proto.Mo_multiuser.Time.prototype.setRemainingtime = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndUserInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndUserInfo.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndUserInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndUserInfo.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userdata: jspb.Message.getFieldWithDefault(msg, 2, ''),
        username: jspb.Message.getFieldWithDefault(msg, 3, ''),
        sessionid: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndUserInfo}
 */
proto.Mo_multiuser.FormAndUserInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndUserInfo()
  return proto.Mo_multiuser.FormAndUserInfo.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndUserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndUserInfo}
 */
proto.Mo_multiuser.FormAndUserInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserdata(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setSessionid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndUserInfo.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndUserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndUserInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserdata()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getSessionid()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfo} returns this
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserData = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.getUserdata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfo} returns this
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.setUserdata = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserName = 3;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfo} returns this
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string SessionID = 4;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.getSessionid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfo} returns this
 */
proto.Mo_multiuser.FormAndUserInfo.prototype.setSessionid = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndUserInfoReceive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndUserInfoReceive.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndUserInfoReceive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndUserInfoReceive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userdata: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        username: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndUserInfoReceive}
 */
proto.Mo_multiuser.FormAndUserInfoReceive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndUserInfoReceive()
  return proto.Mo_multiuser.FormAndUserInfoReceive.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndUserInfoReceive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndUserInfoReceive}
 */
proto.Mo_multiuser.FormAndUserInfoReceive.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserdata(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndUserInfoReceive.serializeBinaryToWriter(
    this,
    writer
  )
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndUserInfoReceive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndUserInfoReceive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserdata()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfoReceive} returns this
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserData = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.getUserdata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfoReceive} returns this
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.setUserdata = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfoReceive} returns this
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.setUserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string UserName = 4;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUserInfoReceive} returns this
 */
proto.Mo_multiuser.FormAndUserInfoReceive.prototype.setUsername = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndUser.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndUser.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndUser} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndUser.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndUser}
 */
proto.Mo_multiuser.FormAndUser.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndUser()
  return proto.Mo_multiuser.FormAndUser.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndUser}
 */
proto.Mo_multiuser.FormAndUser.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndUser.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndUser.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndUser.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUser.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUser} returns this
 */
proto.Mo_multiuser.FormAndUser.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormAndUser.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndUser} returns this
 */
proto.Mo_multiuser.FormAndUser.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserAndFormAndField.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserAndFormAndField.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserAndFormAndField} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserAndFormAndField.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserAndFormAndField}
 */
proto.Mo_multiuser.UserAndFormAndField.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserAndFormAndField()
  return proto.Mo_multiuser.UserAndFormAndField.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserAndFormAndField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserAndFormAndField}
 */
proto.Mo_multiuser.UserAndFormAndField.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserAndFormAndField.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserAndFormAndField.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserAndFormAndField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserAndFormAndField.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserAndFormAndField.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserAndFormAndField} returns this
 */
proto.Mo_multiuser.UserAndFormAndField.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.UserAndFormAndField.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserAndFormAndField} returns this
 */
proto.Mo_multiuser.UserAndFormAndField.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldID = 4;
 * @return {string}
 */
proto.Mo_multiuser.UserAndFormAndField.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserAndFormAndField} returns this
 */
proto.Mo_multiuser.UserAndFormAndField.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserSessionInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserSessionInfo.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserSessionInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserSessionInfo.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userdata: jspb.Message.getFieldWithDefault(msg, 3, ''),
        logtime:
          (f = msg.getLogtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        username: jspb.Message.getFieldWithDefault(msg, 5, ''),
        sessionid: jspb.Message.getFieldWithDefault(msg, 6, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserSessionInfo}
 */
proto.Mo_multiuser.UserSessionInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserSessionInfo()
  return proto.Mo_multiuser.UserSessionInfo.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserSessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserSessionInfo}
 */
proto.Mo_multiuser.UserSessionInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserdata(value)
        break
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLogtime(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setSessionid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserSessionInfo.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserSessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserSessionInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserdata()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getLogtime()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getSessionid()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionInfo} returns this
 */
proto.Mo_multiuser.UserSessionInfo.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionInfo} returns this
 */
proto.Mo_multiuser.UserSessionInfo.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserData = 3;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.getUserdata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionInfo} returns this
 */
proto.Mo_multiuser.UserSessionInfo.prototype.setUserdata = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional google.protobuf.Timestamp LogTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.getLogtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    4
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.UserSessionInfo} returns this
 */
proto.Mo_multiuser.UserSessionInfo.prototype.setLogtime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.UserSessionInfo} returns this
 */
proto.Mo_multiuser.UserSessionInfo.prototype.clearLogtime = function () {
  return this.setLogtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.hasLogtime = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * optional string UserName = 5;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionInfo} returns this
 */
proto.Mo_multiuser.UserSessionInfo.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string SessionID = 6;
 * @return {string}
 */
proto.Mo_multiuser.UserSessionInfo.prototype.getSessionid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserSessionInfo} returns this
 */
proto.Mo_multiuser.UserSessionInfo.prototype.setSessionid = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserInfo.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserInfo.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserInfo.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userdata: jspb.Message.getFieldWithDefault(msg, 3, ''),
        logtime:
          (f = msg.getLogtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        username: jspb.Message.getFieldWithDefault(msg, 5, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserInfo}
 */
proto.Mo_multiuser.UserInfo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserInfo()
  return proto.Mo_multiuser.UserInfo.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserInfo}
 */
proto.Mo_multiuser.UserInfo.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserdata(value)
        break
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLogtime(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setUsername(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserInfo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserInfo.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserInfo.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserdata()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getLogtime()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getUsername()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserData = 3;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getUserdata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setUserdata = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional google.protobuf.Timestamp LogTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.UserInfo.prototype.getLogtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    4
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setLogtime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.clearLogtime = function () {
  return this.setLogtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.UserInfo.prototype.hasLogtime = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * optional string UserName = 5;
 * @return {string}
 */
proto.Mo_multiuser.UserInfo.prototype.getUsername = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserInfo} returns this
 */
proto.Mo_multiuser.UserInfo.prototype.setUsername = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserID.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserID.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        user: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserID}
 */
proto.Mo_multiuser.UserID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserID()
  return proto.Mo_multiuser.UserID.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserID}
 */
proto.Mo_multiuser.UserID.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUser(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserID.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getUser()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string User = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserID.prototype.getUser = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserID} returns this
 */
proto.Mo_multiuser.UserID.prototype.setUser = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.UsersSessionsList.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UsersSessionsList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UsersSessionsList.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UsersSessionsList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UsersSessionsList.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        useridList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UsersSessionsList}
 */
proto.Mo_multiuser.UsersSessionsList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UsersSessionsList()
  return proto.Mo_multiuser.UsersSessionsList.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UsersSessionsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UsersSessionsList}
 */
proto.Mo_multiuser.UsersSessionsList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.addUserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UsersSessionsList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UsersSessionsList.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UsersSessionsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UsersSessionsList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUseridList()
  if (f.length > 0) {
    writer.writeRepeatedString(1, f)
  }
}

/**
 * repeated string UserID = 1;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.UsersSessionsList.prototype.getUseridList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.UsersSessionsList} returns this
 */
proto.Mo_multiuser.UsersSessionsList.prototype.setUseridList = function (
  value
) {
  return jspb.Message.setField(this, 1, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.UsersSessionsList} returns this
 */
proto.Mo_multiuser.UsersSessionsList.prototype.addUserid = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.UsersSessionsList} returns this
 */
proto.Mo_multiuser.UsersSessionsList.prototype.clearUseridList = function () {
  return this.setUseridList([])
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.UserSessionsInfoList.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserSessionsInfoList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserSessionsInfoList.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserSessionsInfoList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserSessionsInfoList.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userinfoList: jspb.Message.toObjectList(
          msg.getUserinfoList(),
          proto.Mo_multiuser.UserSessionInfo.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserSessionsInfoList}
 */
proto.Mo_multiuser.UserSessionsInfoList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserSessionsInfoList()
  return proto.Mo_multiuser.UserSessionsInfoList.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserSessionsInfoList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserSessionsInfoList}
 */
proto.Mo_multiuser.UserSessionsInfoList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.UserSessionInfo()
        reader.readMessage(
          value,
          proto.Mo_multiuser.UserSessionInfo.deserializeBinaryFromReader
        )
        msg.addUserinfo(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserSessionsInfoList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserSessionsInfoList.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserSessionsInfoList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserSessionsInfoList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserinfoList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.UserSessionInfo.serializeBinaryToWriter
    )
  }
}

/**
 * repeated UserSessionInfo UserInfo = 1;
 * @return {!Array<!proto.Mo_multiuser.UserSessionInfo>}
 */
proto.Mo_multiuser.UserSessionsInfoList.prototype.getUserinfoList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.UserSessionInfo>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Mo_multiuser.UserSessionInfo,
    1
  ))
}

/**
 * @param {!Array<!proto.Mo_multiuser.UserSessionInfo>} value
 * @return {!proto.Mo_multiuser.UserSessionsInfoList} returns this
 */
proto.Mo_multiuser.UserSessionsInfoList.prototype.setUserinfoList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.UserSessionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.UserSessionInfo}
 */
proto.Mo_multiuser.UserSessionsInfoList.prototype.addUserinfo = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.UserSessionInfo,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.UserSessionsInfoList} returns this
 */
proto.Mo_multiuser.UserSessionsInfoList.prototype.clearUserinfoList = function () {
  return this.setUserinfoList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UsersCount.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UsersCount.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UsersCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UsersCount.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        count: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UsersCount}
 */
proto.Mo_multiuser.UsersCount.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UsersCount()
  return proto.Mo_multiuser.UsersCount.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UsersCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UsersCount}
 */
proto.Mo_multiuser.UsersCount.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32())
        msg.setCount(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UsersCount.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UsersCount.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UsersCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UsersCount.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getCount()
  if (f !== 0) {
    writer.writeInt32(1, f)
  }
}

/**
 * optional int32 Count = 1;
 * @return {number}
 */
proto.Mo_multiuser.UsersCount.prototype.getCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.UsersCount} returns this
 */
proto.Mo_multiuser.UsersCount.prototype.setCount = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserOperation.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserOperation.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserOperation}
 */
proto.Mo_multiuser.UserOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserOperation()
  return proto.Mo_multiuser.UserOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserOperation}
 */
proto.Mo_multiuser.UserOperation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.OperationResult} */ (reader.readEnum())
        msg.setResult(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserOperation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserOperation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserOperation.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserOperation} returns this
 */
proto.Mo_multiuser.UserOperation.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional OperationResult Result = 2;
 * @return {!proto.Mo_multiuser.OperationResult}
 */
proto.Mo_multiuser.UserOperation.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.OperationResult} */ (jspb.Message.getFieldWithDefault(
    this,
    2,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.OperationResult} value
 * @return {!proto.Mo_multiuser.UserOperation} returns this
 */
proto.Mo_multiuser.UserOperation.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.UserEditedField.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.UserEditedField.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.UserEditedField} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.UserEditedField.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.UserEditedField}
 */
proto.Mo_multiuser.UserEditedField.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.UserEditedField()
  return proto.Mo_multiuser.UserEditedField.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.UserEditedField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.UserEditedField}
 */
proto.Mo_multiuser.UserEditedField.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.UserEditedField.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.UserEditedField.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.UserEditedField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.UserEditedField.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.UserEditedField.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserEditedField} returns this
 */
proto.Mo_multiuser.UserEditedField.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.UserEditedField.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserEditedField} returns this
 */
proto.Mo_multiuser.UserEditedField.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldID = 3;
 * @return {string}
 */
proto.Mo_multiuser.UserEditedField.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserEditedField} returns this
 */
proto.Mo_multiuser.UserEditedField.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string FieldValue = 4;
 * @return {string}
 */
proto.Mo_multiuser.UserEditedField.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.UserEditedField} returns this
 */
proto.Mo_multiuser.UserEditedField.prototype.setFieldvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LockID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LockID.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LockID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LockID.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LockID}
 */
proto.Mo_multiuser.LockID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LockID()
  return proto.Mo_multiuser.LockID.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LockID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LockID}
 */
proto.Mo_multiuser.LockID.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LockID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LockID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LockID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LockID.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getId()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string ID = 1;
 * @return {string}
 */
proto.Mo_multiuser.LockID.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.LockID} returns this
 */
proto.Mo_multiuser.LockID.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.AsyncLock.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.AsyncLock.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.AsyncLock} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.AsyncLock.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        parentid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.AsyncLock}
 */
proto.Mo_multiuser.AsyncLock.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.AsyncLock()
  return proto.Mo_multiuser.AsyncLock.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.AsyncLock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.AsyncLock}
 */
proto.Mo_multiuser.AsyncLock.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setParentid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.AsyncLock.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.AsyncLock.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.AsyncLock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.AsyncLock.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getParentid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.AsyncLock.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.AsyncLock} returns this
 */
proto.Mo_multiuser.AsyncLock.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string ParentID = 2;
 * @return {string}
 */
proto.Mo_multiuser.AsyncLock.prototype.getParentid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.AsyncLock} returns this
 */
proto.Mo_multiuser.AsyncLock.prototype.setParentid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldValue = 3;
 * @return {string}
 */
proto.Mo_multiuser.AsyncLock.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.AsyncLock} returns this
 */
proto.Mo_multiuser.AsyncLock.prototype.setFieldvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LockPointer.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LockPointer.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LockPointer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LockPointer.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LockPointer}
 */
proto.Mo_multiuser.LockPointer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LockPointer()
  return proto.Mo_multiuser.LockPointer.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LockPointer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LockPointer}
 */
proto.Mo_multiuser.LockPointer.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LockPointer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LockPointer.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LockPointer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LockPointer.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.LockPointer.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.LockPointer} returns this
 */
proto.Mo_multiuser.LockPointer.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.LockPointer.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.LockPointer} returns this
 */
proto.Mo_multiuser.LockPointer.prototype.setLockid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndFieldAndFieldValue.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndFieldAndFieldValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndFieldAndFieldValue.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndFieldAndFieldValue}
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndFieldAndFieldValue()
  return proto.Mo_multiuser.FormAndFieldAndFieldValue.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndFieldAndFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndFieldAndFieldValue}
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndFieldAndFieldValue.serializeBinaryToWriter(
    this,
    writer
  )
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndFieldAndFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndFieldValue} returns this
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndFieldValue} returns this
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldValue = 3;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndFieldValue} returns this
 */
proto.Mo_multiuser.FormAndFieldAndFieldValue.prototype.setFieldvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FormAndFieldsAndValues.repeatedFields_ = [2]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndFieldsAndValues.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndFieldsAndValues.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndFieldsAndValues} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndFieldsAndValues.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldsandvaluesList: jspb.Message.toObjectList(
          msg.getFieldsandvaluesList(),
          proto.Mo_multiuser.FieldAndValue.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndFieldsAndValues}
 */
proto.Mo_multiuser.FormAndFieldsAndValues.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndFieldsAndValues()
  return proto.Mo_multiuser.FormAndFieldsAndValues.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndFieldsAndValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndFieldsAndValues}
 */
proto.Mo_multiuser.FormAndFieldsAndValues.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = new proto.Mo_multiuser.FieldAndValue()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldAndValue.deserializeBinaryFromReader
        )
        msg.addFieldsandvalues(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndFieldsAndValues.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndFieldsAndValues.serializeBinaryToWriter(
    this,
    writer
  )
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndFieldsAndValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndFieldsAndValues.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldsandvaluesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Mo_multiuser.FieldAndValue.serializeBinaryToWriter
    )
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldsAndValues.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldsAndValues} returns this
 */
proto.Mo_multiuser.FormAndFieldsAndValues.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * repeated FieldAndValue FieldsAndValues = 2;
 * @return {!Array<!proto.Mo_multiuser.FieldAndValue>}
 */
proto.Mo_multiuser.FormAndFieldsAndValues.prototype.getFieldsandvaluesList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.FieldAndValue>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Mo_multiuser.FieldAndValue,
    2
  ))
}

/**
 * @param {!Array<!proto.Mo_multiuser.FieldAndValue>} value
 * @return {!proto.Mo_multiuser.FormAndFieldsAndValues} returns this
 */
proto.Mo_multiuser.FormAndFieldsAndValues.prototype.setFieldsandvaluesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value)
}

/**
 * @param {!proto.Mo_multiuser.FieldAndValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldAndValue}
 */
proto.Mo_multiuser.FormAndFieldsAndValues.prototype.addFieldsandvalues = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.Mo_multiuser.FieldAndValue,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FormAndFieldsAndValues} returns this
 */
proto.Mo_multiuser.FormAndFieldsAndValues.prototype.clearFieldsandvaluesList = function () {
  return this.setFieldsandvaluesList([])
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FieldsAndLocks.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldsAndLocks.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldsAndLocks.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldsAndLocks} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldsAndLocks.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldsandvaluesList: jspb.Message.toObjectList(
          msg.getFieldsandvaluesList(),
          proto.Mo_multiuser.FieldIDAndLockID.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldsAndLocks}
 */
proto.Mo_multiuser.FieldsAndLocks.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldsAndLocks()
  return proto.Mo_multiuser.FieldsAndLocks.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldsAndLocks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldsAndLocks}
 */
proto.Mo_multiuser.FieldsAndLocks.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.FieldIDAndLockID()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldIDAndLockID.deserializeBinaryFromReader
        )
        msg.addFieldsandvalues(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldsAndLocks.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldsAndLocks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldsAndLocks.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldsandvaluesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.FieldIDAndLockID.serializeBinaryToWriter
    )
  }
}

/**
 * repeated FieldIDAndLockID FieldsAndValues = 1;
 * @return {!Array<!proto.Mo_multiuser.FieldIDAndLockID>}
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.getFieldsandvaluesList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.FieldIDAndLockID>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Mo_multiuser.FieldIDAndLockID,
    1
  ))
}

/**
 * @param {!Array<!proto.Mo_multiuser.FieldIDAndLockID>} value
 * @return {!proto.Mo_multiuser.FieldsAndLocks} returns this
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.setFieldsandvaluesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.FieldIDAndLockID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldIDAndLockID}
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.addFieldsandvalues = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.FieldIDAndLockID,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FieldsAndLocks} returns this
 */
proto.Mo_multiuser.FieldsAndLocks.prototype.clearFieldsandvaluesList = function () {
  return this.setFieldsandvaluesList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldIDAndLockID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldIDAndLockID.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldIDAndLockID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldIDAndLockID.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldIDAndLockID}
 */
proto.Mo_multiuser.FieldIDAndLockID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldIDAndLockID()
  return proto.Mo_multiuser.FieldIDAndLockID.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldIDAndLockID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldIDAndLockID}
 */
proto.Mo_multiuser.FieldIDAndLockID.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldIDAndLockID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldIDAndLockID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldIDAndLockID.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDAndLockID} returns this
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDAndLockID} returns this
 */
proto.Mo_multiuser.FieldIDAndLockID.prototype.setLockid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FieldLockOperation.repeatedFields_ = [3]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldLockOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldLockOperation.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldLockOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldLockOperation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldsList: jspb.Message.toObjectList(
          msg.getFieldsList(),
          proto.Mo_multiuser.FieldIDLockIDFieldValue.toObject,
          includeInstance
        ),
        operation: jspb.Message.getFieldWithDefault(msg, 4, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldLockOperation}
 */
proto.Mo_multiuser.FieldLockOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldLockOperation()
  return proto.Mo_multiuser.FieldLockOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldLockOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldLockOperation}
 */
proto.Mo_multiuser.FieldLockOperation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 3:
        var value = new proto.Mo_multiuser.FieldIDLockIDFieldValue()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinaryFromReader
        )
        msg.addFields(value)
        break
      case 4:
        var value = /** @type {!proto.Mo_multiuser.LockOperation} */ (reader.readEnum())
        msg.setOperation(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldLockOperation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldLockOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldLockOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Mo_multiuser.FieldIDLockIDFieldValue.serializeBinaryToWriter
    )
  }
  f = message.getOperation()
  if (f !== 0.0) {
    writer.writeEnum(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * repeated FieldIDLockIDFieldValue Fields = 3;
 * @return {!Array<!proto.Mo_multiuser.FieldIDLockIDFieldValue>}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.getFieldsList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.FieldIDLockIDFieldValue>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Mo_multiuser.FieldIDLockIDFieldValue,
    3
  ))
}

/**
 * @param {!Array<!proto.Mo_multiuser.FieldIDLockIDFieldValue>} value
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.setFieldsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value)
}

/**
 * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.addFields = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.Mo_multiuser.FieldIDLockIDFieldValue,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.clearFieldsList = function () {
  return this.setFieldsList([])
}

/**
 * optional LockOperation Operation = 4;
 * @return {!proto.Mo_multiuser.LockOperation}
 */
proto.Mo_multiuser.FieldLockOperation.prototype.getOperation = function () {
  return /** @type {!proto.Mo_multiuser.LockOperation} */ (jspb.Message.getFieldWithDefault(
    this,
    4,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.LockOperation} value
 * @return {!proto.Mo_multiuser.FieldLockOperation} returns this
 */
proto.Mo_multiuser.FieldLockOperation.prototype.setOperation = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldAndValue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldAndValue.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldAndValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldAndValue.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldAndValue}
 */
proto.Mo_multiuser.FieldAndValue.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldAndValue()
  return proto.Mo_multiuser.FieldAndValue.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldAndValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldAndValue}
 */
proto.Mo_multiuser.FieldAndValue.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldAndValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldAndValue.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldAndValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldAndValue.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndValue.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndValue} returns this
 */
proto.Mo_multiuser.FieldAndValue.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldValue = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldAndValue.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldAndValue} returns this
 */
proto.Mo_multiuser.FieldAndValue.prototype.setFieldvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldLock.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldLock.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldLock} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldLock.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 4, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 5, ''),
        fieldmetadata: jspb.Message.getFieldWithDefault(msg, 6, ''),
        state: jspb.Message.getFieldWithDefault(msg, 7, 0),
        parentlockid: jspb.Message.getFieldWithDefault(msg, 8, ''),
        lockstarttime:
          (f = msg.getLockstarttime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        lockcommittime:
          (f = msg.getLockcommittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        locksubmitedtime:
          (f = msg.getLocksubmitedtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldLock}
 */
proto.Mo_multiuser.FieldLock.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldLock()
  return proto.Mo_multiuser.FieldLock.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldLock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldLock}
 */
proto.Mo_multiuser.FieldLock.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldmetadata(value)
        break
      case 7:
        var value = /** @type {!proto.Mo_multiuser.LockState} */ (reader.readEnum())
        msg.setState(value)
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.setParentlockid(value)
        break
      case 9:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLockstarttime(value)
        break
      case 10:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLockcommittime(value)
        break
      case 11:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLocksubmitedtime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldLock.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldLock.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldLock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldLock.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getFieldmetadata()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getState()
  if (f !== 0.0) {
    writer.writeEnum(7, f)
  }
  f = message.getParentlockid()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
  f = message.getLockstarttime()
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getLockcommittime()
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getLocksubmitedtime()
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setLockid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string FieldID = 4;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string FieldValue = 5;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setFieldvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string FieldMetadata = 6;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getFieldmetadata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setFieldmetadata = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional LockState State = 7;
 * @return {!proto.Mo_multiuser.LockState}
 */
proto.Mo_multiuser.FieldLock.prototype.getState = function () {
  return /** @type {!proto.Mo_multiuser.LockState} */ (jspb.Message.getFieldWithDefault(
    this,
    7,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.LockState} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setState = function (value) {
  return jspb.Message.setProto3EnumField(this, 7, value)
}

/**
 * optional string ParentLockID = 8;
 * @return {string}
 */
proto.Mo_multiuser.FieldLock.prototype.getParentlockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setParentlockid = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * optional google.protobuf.Timestamp LockStartTime = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldLock.prototype.getLockstarttime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    9
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setLockstarttime = function (value) {
  return jspb.Message.setWrapperField(this, 9, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.clearLockstarttime = function () {
  return this.setLockstarttime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldLock.prototype.hasLockstarttime = function () {
  return jspb.Message.getField(this, 9) != null
}

/**
 * optional google.protobuf.Timestamp LockCommitTime = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldLock.prototype.getLockcommittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    10
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setLockcommittime = function (value) {
  return jspb.Message.setWrapperField(this, 10, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.clearLockcommittime = function () {
  return this.setLockcommittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldLock.prototype.hasLockcommittime = function () {
  return jspb.Message.getField(this, 10) != null
}

/**
 * optional google.protobuf.Timestamp LockSubmitedTime = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldLock.prototype.getLocksubmitedtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    11
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.setLocksubmitedtime = function (value) {
  return jspb.Message.setWrapperField(this, 11, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldLock} returns this
 */
proto.Mo_multiuser.FieldLock.prototype.clearLocksubmitedtime = function () {
  return this.setLocksubmitedtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldLock.prototype.hasLocksubmitedtime = function () {
  return jspb.Message.getField(this, 11) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldIDLockIDFieldValue.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldIDLockIDFieldValue.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldIDLockIDFieldValue()
  return proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldIDLockIDFieldValue.serializeBinaryToWriter(
    this,
    writer
  )
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldIDLockIDFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FieldID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue} returns this
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string LockID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue} returns this
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.setLockid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string FieldValue = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldIDLockIDFieldValue} returns this
 */
proto.Mo_multiuser.FieldIDLockIDFieldValue.prototype.setFieldvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndFieldAndLockID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndFieldAndLockID.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndFieldAndLockID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndFieldAndLockID.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockID}
 */
proto.Mo_multiuser.FormAndFieldAndLockID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndFieldAndLockID()
  return proto.Mo_multiuser.FormAndFieldAndLockID.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockID}
 */
proto.Mo_multiuser.FormAndFieldAndLockID.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndFieldAndLockID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndFieldAndLockID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndFieldAndLockID.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndLockID.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockID} returns this
 */
proto.Mo_multiuser.FormAndFieldAndLockID.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndLockID.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockID} returns this
 */
proto.Mo_multiuser.FormAndFieldAndLockID.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string LockID = 3;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndLockID.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockID} returns this
 */
proto.Mo_multiuser.FormAndFieldAndLockID.prototype.setLockid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        lockid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        fieldvalue: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent}
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndFieldAndLockIDAndContent()
  return proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent}
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setLockid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.serializeBinaryToWriter(
    this,
    writer
  )
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLockid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getFieldvalue()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} returns this
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} returns this
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string LockID = 3;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.getLockid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} returns this
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.setLockid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string FieldValue = 4;
 * @return {string}
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.getFieldvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndFieldAndLockIDAndContent} returns this
 */
proto.Mo_multiuser.FormAndFieldAndLockIDAndContent.prototype.setFieldvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormAndField.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormAndField.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormAndField} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormAndField.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormAndField}
 */
proto.Mo_multiuser.FormAndField.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormAndField()
  return proto.Mo_multiuser.FormAndField.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormAndField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormAndField}
 */
proto.Mo_multiuser.FormAndField.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormAndField.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormAndField.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormAndField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormAndField.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormAndField.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndField} returns this
 */
proto.Mo_multiuser.FormAndField.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormAndField.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormAndField} returns this
 */
proto.Mo_multiuser.FormAndField.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.LocksListLite.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LocksListLite.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LocksListLite.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LocksListLite} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LocksListLite.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        locksidsList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LocksListLite}
 */
proto.Mo_multiuser.LocksListLite.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LocksListLite()
  return proto.Mo_multiuser.LocksListLite.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LocksListLite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LocksListLite}
 */
proto.Mo_multiuser.LocksListLite.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.addLocksids(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LocksListLite.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LocksListLite.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LocksListLite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LocksListLite.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getLocksidsList()
  if (f.length > 0) {
    writer.writeRepeatedString(1, f)
  }
}

/**
 * repeated string LocksIDs = 1;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.LocksListLite.prototype.getLocksidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.LocksListLite} returns this
 */
proto.Mo_multiuser.LocksListLite.prototype.setLocksidsList = function (value) {
  return jspb.Message.setField(this, 1, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.LocksListLite} returns this
 */
proto.Mo_multiuser.LocksListLite.prototype.addLocksids = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.LocksListLite} returns this
 */
proto.Mo_multiuser.LocksListLite.prototype.clearLocksidsList = function () {
  return this.setLocksidsList([])
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.LocksList.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LocksList.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LocksList.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LocksList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LocksList.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        locksList: jspb.Message.toObjectList(
          msg.getLocksList(),
          proto.Mo_multiuser.FieldLock.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LocksList}
 */
proto.Mo_multiuser.LocksList.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LocksList()
  return proto.Mo_multiuser.LocksList.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LocksList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LocksList}
 */
proto.Mo_multiuser.LocksList.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.FieldLock()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FieldLock.deserializeBinaryFromReader
        )
        msg.addLocks(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LocksList.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LocksList.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LocksList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LocksList.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getLocksList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.FieldLock.serializeBinaryToWriter
    )
  }
}

/**
 * repeated FieldLock Locks = 1;
 * @return {!Array<!proto.Mo_multiuser.FieldLock>}
 */
proto.Mo_multiuser.LocksList.prototype.getLocksList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.FieldLock>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Mo_multiuser.FieldLock,
    1
  ))
}

/**
 * @param {!Array<!proto.Mo_multiuser.FieldLock>} value
 * @return {!proto.Mo_multiuser.LocksList} returns this
 */
proto.Mo_multiuser.LocksList.prototype.setLocksList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.FieldLock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FieldLock}
 */
proto.Mo_multiuser.LocksList.prototype.addLocks = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.FieldLock,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.LocksList} returns this
 */
proto.Mo_multiuser.LocksList.prototype.clearLocksList = function () {
  return this.setLocksList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.LockStateType.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.LockStateType.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.LockStateType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.LockStateType.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        lockstate: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.LockStateType}
 */
proto.Mo_multiuser.LockStateType.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.LockStateType()
  return proto.Mo_multiuser.LockStateType.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.LockStateType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.LockStateType}
 */
proto.Mo_multiuser.LockStateType.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {!proto.Mo_multiuser.LockState} */ (reader.readEnum())
        msg.setLockstate(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.LockStateType.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.LockStateType.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.LockStateType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.LockStateType.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getLockstate()
  if (f !== 0.0) {
    writer.writeEnum(1, f)
  }
}

/**
 * optional LockState LockState = 1;
 * @return {!proto.Mo_multiuser.LockState}
 */
proto.Mo_multiuser.LockStateType.prototype.getLockstate = function () {
  return /** @type {!proto.Mo_multiuser.LockState} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.LockState} value
 * @return {!proto.Mo_multiuser.LockStateType} returns this
 */
proto.Mo_multiuser.LockStateType.prototype.setLockstate = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormState.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormState.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormState} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormState.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 2, ''),
        locks:
          (f = msg.getLocks()) &&
          proto.Mo_multiuser.LocksList.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormState}
 */
proto.Mo_multiuser.FormState.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormState()
  return proto.Mo_multiuser.FormState.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormState}
 */
proto.Mo_multiuser.FormState.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 3:
        var value = new proto.Mo_multiuser.LocksList()
        reader.readMessage(
          value,
          proto.Mo_multiuser.LocksList.deserializeBinaryFromReader
        )
        msg.setLocks(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormState.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormState.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormState.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLocks()
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Mo_multiuser.LocksList.serializeBinaryToWriter
    )
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormState.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormState} returns this
 */
proto.Mo_multiuser.FormState.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormState.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormState} returns this
 */
proto.Mo_multiuser.FormState.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional LocksList Locks = 3;
 * @return {?proto.Mo_multiuser.LocksList}
 */
proto.Mo_multiuser.FormState.prototype.getLocks = function () {
  return /** @type{?proto.Mo_multiuser.LocksList} */ (jspb.Message.getWrapperField(
    this,
    proto.Mo_multiuser.LocksList,
    3
  ))
}

/**
 * @param {?proto.Mo_multiuser.LocksList|undefined} value
 * @return {!proto.Mo_multiuser.FormState} returns this
 */
proto.Mo_multiuser.FormState.prototype.setLocks = function (value) {
  return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FormState} returns this
 */
proto.Mo_multiuser.FormState.prototype.clearLocks = function () {
  return this.setLocks(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FormState.prototype.hasLocks = function () {
  return jspb.Message.getField(this, 3) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormIDString.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormIDString.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormIDString} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormIDString.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormIDString}
 */
proto.Mo_multiuser.FormIDString.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormIDString()
  return proto.Mo_multiuser.FormIDString.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormIDString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormIDString}
 */
proto.Mo_multiuser.FormIDString.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormIDString.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormIDString.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormIDString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormIDString.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormIDString.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormIDString} returns this
 */
proto.Mo_multiuser.FormIDString.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormCachePointer.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormCachePointer.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormCachePointer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormCachePointer.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        uniqueid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormCachePointer}
 */
proto.Mo_multiuser.FormCachePointer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormCachePointer()
  return proto.Mo_multiuser.FormCachePointer.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormCachePointer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormCachePointer}
 */
proto.Mo_multiuser.FormCachePointer.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormCachePointer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormCachePointer.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormCachePointer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormCachePointer.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormCachePointer.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCachePointer} returns this
 */
proto.Mo_multiuser.FormCachePointer.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UniqueID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormCachePointer.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCachePointer} returns this
 */
proto.Mo_multiuser.FormCachePointer.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormPointer.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormPointer.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormPointer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormPointer.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        uniqueid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormPointer}
 */
proto.Mo_multiuser.FormPointer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormPointer()
  return proto.Mo_multiuser.FormPointer.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormPointer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormPointer}
 */
proto.Mo_multiuser.FormPointer.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormPointer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormPointer.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormPointer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormPointer.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormPointer.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormPointer} returns this
 */
proto.Mo_multiuser.FormPointer.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UniqueID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormPointer.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormPointer} returns this
 */
proto.Mo_multiuser.FormPointer.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.Form.prototype.toObject = function (opt_includeInstance) {
    return proto.Mo_multiuser.Form.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.Form} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.Form.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.Form}
 */
proto.Mo_multiuser.Form.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.Form()
  return proto.Mo_multiuser.Form.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.Form} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.Form}
 */
proto.Mo_multiuser.Form.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.Form.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.Form.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.Form} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.Form.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.Form.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.Form} returns this
 */
proto.Mo_multiuser.Form.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 2;
 * @return {string}
 */
proto.Mo_multiuser.Form.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.Form} returns this
 */
proto.Mo_multiuser.Form.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormCache.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormCache.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormCache} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormCache.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        uniqueid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        content: jspb.Message.getFieldWithDefault(msg, 3, ''),
        submittime:
          (f = msg.getSubmittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        cachetype: jspb.Message.getFieldWithDefault(msg, 5, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormCache}
 */
proto.Mo_multiuser.FormCache.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormCache()
  return proto.Mo_multiuser.FormCache.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormCache} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormCache}
 */
proto.Mo_multiuser.FormCache.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSubmittime(value)
        break
      case 5:
        var value = /** @type {!proto.Mo_multiuser.FormCacheType} */ (reader.readEnum())
        msg.setCachetype(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormCache.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormCache.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormCache} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormCache.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getSubmittime()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getCachetype()
  if (f !== 0.0) {
    writer.writeEnum(5, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormCache.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCache} returns this
 */
proto.Mo_multiuser.FormCache.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UniqueID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormCache.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCache} returns this
 */
proto.Mo_multiuser.FormCache.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Content = 3;
 * @return {string}
 */
proto.Mo_multiuser.FormCache.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCache} returns this
 */
proto.Mo_multiuser.FormCache.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional google.protobuf.Timestamp SubmitTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FormCache.prototype.getSubmittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    4
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FormCache} returns this
 */
proto.Mo_multiuser.FormCache.prototype.setSubmittime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FormCache} returns this
 */
proto.Mo_multiuser.FormCache.prototype.clearSubmittime = function () {
  return this.setSubmittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FormCache.prototype.hasSubmittime = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * optional FormCacheType CacheType = 5;
 * @return {!proto.Mo_multiuser.FormCacheType}
 */
proto.Mo_multiuser.FormCache.prototype.getCachetype = function () {
  return /** @type {!proto.Mo_multiuser.FormCacheType} */ (jspb.Message.getFieldWithDefault(
    this,
    5,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.FormCacheType} value
 * @return {!proto.Mo_multiuser.FormCache} returns this
 */
proto.Mo_multiuser.FormCache.prototype.setCachetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FormCacheListLite.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormCacheListLite.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormCacheListLite.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormCacheListLite} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormCacheListLite.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formcacheliteList: jspb.Message.toObjectList(
          msg.getFormcacheliteList(),
          proto.Mo_multiuser.FormCacheLite.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormCacheListLite}
 */
proto.Mo_multiuser.FormCacheListLite.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormCacheListLite()
  return proto.Mo_multiuser.FormCacheListLite.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormCacheListLite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormCacheListLite}
 */
proto.Mo_multiuser.FormCacheListLite.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.FormCacheLite()
        reader.readMessage(
          value,
          proto.Mo_multiuser.FormCacheLite.deserializeBinaryFromReader
        )
        msg.addFormcachelite(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormCacheListLite.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormCacheListLite.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormCacheListLite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormCacheListLite.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormcacheliteList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.FormCacheLite.serializeBinaryToWriter
    )
  }
}

/**
 * repeated FormCacheLite FormCacheLite = 1;
 * @return {!Array<!proto.Mo_multiuser.FormCacheLite>}
 */
proto.Mo_multiuser.FormCacheListLite.prototype.getFormcacheliteList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.FormCacheLite>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Mo_multiuser.FormCacheLite,
    1
  ))
}

/**
 * @param {!Array<!proto.Mo_multiuser.FormCacheLite>} value
 * @return {!proto.Mo_multiuser.FormCacheListLite} returns this
 */
proto.Mo_multiuser.FormCacheListLite.prototype.setFormcacheliteList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.FormCacheLite=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FormCacheLite}
 */
proto.Mo_multiuser.FormCacheListLite.prototype.addFormcachelite = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.FormCacheLite,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FormCacheListLite} returns this
 */
proto.Mo_multiuser.FormCacheListLite.prototype.clearFormcacheliteList = function () {
  return this.setFormcacheliteList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormCacheLite.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormCacheLite.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormCacheLite} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormCacheLite.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        uniqueid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        submittime:
          (f = msg.getSubmittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormCacheLite}
 */
proto.Mo_multiuser.FormCacheLite.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormCacheLite()
  return proto.Mo_multiuser.FormCacheLite.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormCacheLite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormCacheLite}
 */
proto.Mo_multiuser.FormCacheLite.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSubmittime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormCacheLite.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormCacheLite.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormCacheLite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormCacheLite.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getSubmittime()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
}

/**
 * optional string UniqueID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormCacheLite.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCacheLite} returns this
 */
proto.Mo_multiuser.FormCacheLite.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional google.protobuf.Timestamp SubmitTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FormCacheLite.prototype.getSubmittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    2
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FormCacheLite} returns this
 */
proto.Mo_multiuser.FormCacheLite.prototype.setSubmittime = function (value) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FormCacheLite} returns this
 */
proto.Mo_multiuser.FormCacheLite.prototype.clearSubmittime = function () {
  return this.setSubmittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FormCacheLite.prototype.hasSubmittime = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FormCacheReceive.repeatedFields_ = [6]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormCacheReceive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormCacheReceive.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormCacheReceive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormCacheReceive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        uniqueid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        content: jspb.Message.getFieldWithDefault(msg, 3, ''),
        submittime:
          (f = msg.getSubmittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        userid: jspb.Message.getFieldWithDefault(msg, 5, ''),
        locksidsList:
          (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
        cachetype: jspb.Message.getFieldWithDefault(msg, 7, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormCacheReceive}
 */
proto.Mo_multiuser.FormCacheReceive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormCacheReceive()
  return proto.Mo_multiuser.FormCacheReceive.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormCacheReceive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormCacheReceive}
 */
proto.Mo_multiuser.FormCacheReceive.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUniqueid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSubmittime(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.addLocksids(value)
        break
      case 7:
        var value = /** @type {!proto.Mo_multiuser.FormCacheType} */ (reader.readEnum())
        msg.setCachetype(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormCacheReceive.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormCacheReceive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormCacheReceive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUniqueid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getSubmittime()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getLocksidsList()
  if (f.length > 0) {
    writer.writeRepeatedString(6, f)
  }
  f = message.getCachetype()
  if (f !== 0.0) {
    writer.writeEnum(7, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UniqueID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.getUniqueid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.setUniqueid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Content = 3;
 * @return {string}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional google.protobuf.Timestamp SubmitTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.getSubmittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    4
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.setSubmittime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.clearSubmittime = function () {
  return this.setSubmittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.hasSubmittime = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * optional string UserID = 5;
 * @return {string}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * repeated string LocksIDs = 6;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.getLocksidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.setLocksidsList = function (
  value
) {
  return jspb.Message.setField(this, 6, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.addLocksids = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.clearLocksidsList = function () {
  return this.setLocksidsList([])
}

/**
 * optional FormCacheType CacheType = 7;
 * @return {!proto.Mo_multiuser.FormCacheType}
 */
proto.Mo_multiuser.FormCacheReceive.prototype.getCachetype = function () {
  return /** @type {!proto.Mo_multiuser.FormCacheType} */ (jspb.Message.getFieldWithDefault(
    this,
    7,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.FormCacheType} value
 * @return {!proto.Mo_multiuser.FormCacheReceive} returns this
 */
proto.Mo_multiuser.FormCacheReceive.prototype.setCachetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 7, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.FormToSubmit.repeatedFields_ = [3]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormToSubmit.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormToSubmit.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormToSubmit} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormToSubmit.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        content: jspb.Message.getFieldWithDefault(msg, 2, ''),
        locksidsList:
          (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
        cachetype: jspb.Message.getFieldWithDefault(msg, 4, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormToSubmit}
 */
proto.Mo_multiuser.FormToSubmit.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormToSubmit()
  return proto.Mo_multiuser.FormToSubmit.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormToSubmit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormToSubmit}
 */
proto.Mo_multiuser.FormToSubmit.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.addLocksids(value)
        break
      case 4:
        var value = /** @type {!proto.Mo_multiuser.FormCacheType} */ (reader.readEnum())
        msg.setCachetype(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormToSubmit.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormToSubmit.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormToSubmit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormToSubmit.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getLocksidsList()
  if (f.length > 0) {
    writer.writeRepeatedString(3, f)
  }
  f = message.getCachetype()
  if (f !== 0.0) {
    writer.writeEnum(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormToSubmit.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormToSubmit} returns this
 */
proto.Mo_multiuser.FormToSubmit.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Content = 2;
 * @return {string}
 */
proto.Mo_multiuser.FormToSubmit.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormToSubmit} returns this
 */
proto.Mo_multiuser.FormToSubmit.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * repeated string LocksIDs = 3;
 * @return {!Array<string>}
 */
proto.Mo_multiuser.FormToSubmit.prototype.getLocksidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.Mo_multiuser.FormToSubmit} returns this
 */
proto.Mo_multiuser.FormToSubmit.prototype.setLocksidsList = function (value) {
  return jspb.Message.setField(this, 3, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.FormToSubmit} returns this
 */
proto.Mo_multiuser.FormToSubmit.prototype.addLocksids = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.FormToSubmit} returns this
 */
proto.Mo_multiuser.FormToSubmit.prototype.clearLocksidsList = function () {
  return this.setLocksidsList([])
}

/**
 * optional FormCacheType CacheType = 4;
 * @return {!proto.Mo_multiuser.FormCacheType}
 */
proto.Mo_multiuser.FormToSubmit.prototype.getCachetype = function () {
  return /** @type {!proto.Mo_multiuser.FormCacheType} */ (jspb.Message.getFieldWithDefault(
    this,
    4,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.FormCacheType} value
 * @return {!proto.Mo_multiuser.FormToSubmit} returns this
 */
proto.Mo_multiuser.FormToSubmit.prototype.setCachetype = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FormOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FormOperation.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FormOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FormOperation.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FormOperation}
 */
proto.Mo_multiuser.FormOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FormOperation()
  return proto.Mo_multiuser.FormOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FormOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FormOperation}
 */
proto.Mo_multiuser.FormOperation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.OperationResult} */ (reader.readEnum())
        msg.setResult(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FormOperation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FormOperation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FormOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FormOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FormOperation.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FormOperation} returns this
 */
proto.Mo_multiuser.FormOperation.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional OperationResult Result = 2;
 * @return {!proto.Mo_multiuser.OperationResult}
 */
proto.Mo_multiuser.FormOperation.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.OperationResult} */ (jspb.Message.getFieldWithDefault(
    this,
    2,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.OperationResult} value
 * @return {!proto.Mo_multiuser.FormOperation} returns this
 */
proto.Mo_multiuser.FormOperation.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.SaveToSFCompletedReport.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.SaveToSFCompletedReport.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.SaveToSFCompletedReport.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0),
        type: jspb.Message.getFieldWithDefault(msg, 3, ''),
        error: jspb.Message.getFieldWithDefault(msg, 4, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.SaveToSFCompletedReport()
  return proto.Mo_multiuser.SaveToSFCompletedReport.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (reader.readEnum())
        msg.setResult(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setError(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.SaveToSFCompletedReport.serializeBinaryToWriter(
    this,
    writer
  )
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.SaveToSFCompletedReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.SaveToSFCompletedReport.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getError()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport} returns this
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional RequestStatus Result = 2;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (jspb.Message.getFieldWithDefault(
    this,
    2,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport} returns this
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.setResult = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

/**
 * optional string Type = 3;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport} returns this
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.setType = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string Error = 4;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.getError = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompletedReport} returns this
 */
proto.Mo_multiuser.SaveToSFCompletedReport.prototype.setError = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RequestSFSaveResponde.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RequestSFSaveResponde.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RequestSFSaveResponde} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RequestSFSaveResponde.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        status: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RequestSFSaveResponde}
 */
proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RequestSFSaveResponde()
  return proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RequestSFSaveResponde} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RequestSFSaveResponde}
 */
proto.Mo_multiuser.RequestSFSaveResponde.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (reader.readEnum())
        msg.setStatus(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RequestSFSaveResponde.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RequestSFSaveResponde.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RequestSFSaveResponde} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RequestSFSaveResponde.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getStatus()
  if (f !== 0.0) {
    writer.writeEnum(1, f)
  }
}

/**
 * optional RequestStatus Status = 1;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.RequestSFSaveResponde.prototype.getStatus = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.RequestSFSaveResponde} returns this
 */
proto.Mo_multiuser.RequestSFSaveResponde.prototype.setStatus = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RequestSFSaveMessage.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RequestSFSaveMessage.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RequestSFSaveMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RequestSFSaveMessage.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        type: jspb.Message.getFieldWithDefault(msg, 2, ''),
        error: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage}
 */
proto.Mo_multiuser.RequestSFSaveMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RequestSFSaveMessage()
  return proto.Mo_multiuser.RequestSFSaveMessage.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage}
 */
proto.Mo_multiuser.RequestSFSaveMessage.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setError(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RequestSFSaveMessage.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RequestSFSaveMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RequestSFSaveMessage.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getError()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage} returns this
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string Type = 2;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage} returns this
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Error = 3;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.getError = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveMessage} returns this
 */
proto.Mo_multiuser.RequestSFSaveMessage.prototype.setError = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.RequestSFSaveReceive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.RequestSFSaveReceive.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.RequestSFSaveReceive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.RequestSFSaveReceive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        result: jspb.Message.getFieldWithDefault(msg, 3, 0),
        type: jspb.Message.getFieldWithDefault(msg, 4, ''),
        error: jspb.Message.getFieldWithDefault(msg, 5, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive}
 */
proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.RequestSFSaveReceive()
  return proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.RequestSFSaveReceive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive}
 */
proto.Mo_multiuser.RequestSFSaveReceive.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 3:
        var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (reader.readEnum())
        msg.setResult(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setError(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.RequestSFSaveReceive.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.RequestSFSaveReceive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.RequestSFSaveReceive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(3, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getError()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional RequestStatus Result = 3;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (jspb.Message.getFieldWithDefault(
    this,
    3,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value)
}

/**
 * optional string Type = 4;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string Error = 5;
 * @return {string}
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.getError = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.RequestSFSaveReceive} returns this
 */
proto.Mo_multiuser.RequestSFSaveReceive.prototype.setError = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.SaveToSFCompleted.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.SaveToSFCompleted.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.SaveToSFCompleted} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.SaveToSFCompleted.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        result: jspb.Message.getFieldWithDefault(msg, 2, 0),
        type: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.SaveToSFCompleted}
 */
proto.Mo_multiuser.SaveToSFCompleted.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.SaveToSFCompleted()
  return proto.Mo_multiuser.SaveToSFCompleted.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.SaveToSFCompleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.SaveToSFCompleted}
 */
proto.Mo_multiuser.SaveToSFCompleted.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 2:
        var value = /** @type {!proto.Mo_multiuser.RequestStatus} */ (reader.readEnum())
        msg.setResult(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setType(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.SaveToSFCompleted.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.SaveToSFCompleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.SaveToSFCompleted.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getResult()
  if (f !== 0.0) {
    writer.writeEnum(2, f)
  }
  f = message.getType()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string UserID = 1;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompleted} returns this
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional RequestStatus Result = 2;
 * @return {!proto.Mo_multiuser.RequestStatus}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.getResult = function () {
  return /** @type {!proto.Mo_multiuser.RequestStatus} */ (jspb.Message.getFieldWithDefault(
    this,
    2,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.RequestStatus} value
 * @return {!proto.Mo_multiuser.SaveToSFCompleted} returns this
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.setResult = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value)
}

/**
 * optional string Type = 3;
 * @return {string}
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.SaveToSFCompleted} returns this
 */
proto.Mo_multiuser.SaveToSFCompleted.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldCommentID.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldCommentID.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldCommentID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldCommentID.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldCommentID}
 */
proto.Mo_multiuser.FieldCommentID.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldCommentID()
  return proto.Mo_multiuser.FieldCommentID.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldCommentID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldCommentID}
 */
proto.Mo_multiuser.FieldCommentID.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldCommentID.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldCommentID.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldCommentID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldCommentID.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentID.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentID} returns this
 */
proto.Mo_multiuser.FieldCommentID.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentID.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentID} returns this
 */
proto.Mo_multiuser.FieldCommentID.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldCommentToSend.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldCommentToSend.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldCommentToSend} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldCommentToSend.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        content: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldCommentToSend}
 */
proto.Mo_multiuser.FieldCommentToSend.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldCommentToSend()
  return proto.Mo_multiuser.FieldCommentToSend.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldCommentToSend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldCommentToSend}
 */
proto.Mo_multiuser.FieldCommentToSend.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldCommentToSend.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldCommentToSend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldCommentToSend.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentToSend} returns this
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentToSend} returns this
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Content = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentToSend} returns this
 */
proto.Mo_multiuser.FieldCommentToSend.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldComment.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldComment.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldComment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldComment.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        content: jspb.Message.getFieldWithDefault(msg, 3, ''),
        lastsubmiteruserid: jspb.Message.getFieldWithDefault(msg, 4, ''),
        lastsubmittime:
          (f = msg.getLastsubmittime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldComment}
 */
proto.Mo_multiuser.FieldComment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldComment()
  return proto.Mo_multiuser.FieldComment.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldComment}
 */
proto.Mo_multiuser.FieldComment.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setLastsubmiteruserid(value)
        break
      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setLastsubmittime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldComment.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldComment.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldComment.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getLastsubmiteruserid()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getLastsubmittime()
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setFieldid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Content = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setContent = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional string LastSubmiterUserID = 4;
 * @return {string}
 */
proto.Mo_multiuser.FieldComment.prototype.getLastsubmiteruserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setLastsubmiteruserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional google.protobuf.Timestamp LastSubmitTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.FieldComment.prototype.getLastsubmittime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    5
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.setLastsubmittime = function (value) {
  return jspb.Message.setWrapperField(this, 5, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.FieldComment} returns this
 */
proto.Mo_multiuser.FieldComment.prototype.clearLastsubmittime = function () {
  return this.setLastsubmittime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.FieldComment.prototype.hasLastsubmittime = function () {
  return jspb.Message.getField(this, 5) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.FieldCommentOperation.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.FieldCommentOperation.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.FieldCommentOperation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.FieldCommentOperation.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fieldid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        content: jspb.Message.getFieldWithDefault(msg, 3, ''),
        operation: jspb.Message.getFieldWithDefault(msg, 4, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.FieldCommentOperation}
 */
proto.Mo_multiuser.FieldCommentOperation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.FieldCommentOperation()
  return proto.Mo_multiuser.FieldCommentOperation.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.FieldCommentOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.FieldCommentOperation}
 */
proto.Mo_multiuser.FieldCommentOperation.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setFieldid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setContent(value)
        break
      case 4:
        var value = /** @type {!proto.Mo_multiuser.LockOperation} */ (reader.readEnum())
        msg.setOperation(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.FieldCommentOperation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.FieldCommentOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.FieldCommentOperation.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFieldid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getContent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getOperation()
  if (f !== 0.0) {
    writer.writeEnum(4, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string FieldID = 2;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getFieldid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setFieldid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string Content = 3;
 * @return {string}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getContent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setContent = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional LockOperation Operation = 4;
 * @return {!proto.Mo_multiuser.LockOperation}
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.getOperation = function () {
  return /** @type {!proto.Mo_multiuser.LockOperation} */ (jspb.Message.getFieldWithDefault(
    this,
    4,
    0
  ))
}

/**
 * @param {!proto.Mo_multiuser.LockOperation} value
 * @return {!proto.Mo_multiuser.FieldCommentOperation} returns this
 */
proto.Mo_multiuser.FieldCommentOperation.prototype.setOperation = function (
  value
) {
  return jspb.Message.setProto3EnumField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.CursorEvent.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.CursorEvent.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.CursorEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.CursorEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        eventvalue: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.CursorEvent}
 */
proto.Mo_multiuser.CursorEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.CursorEvent()
  return proto.Mo_multiuser.CursorEvent.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.CursorEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.CursorEvent}
 */
proto.Mo_multiuser.CursorEvent.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setEventvalue(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.CursorEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.CursorEvent.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.CursorEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.CursorEvent.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getEventvalue()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.CursorEvent.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEvent} returns this
 */
proto.Mo_multiuser.CursorEvent.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string EventValue = 2;
 * @return {string}
 */
proto.Mo_multiuser.CursorEvent.prototype.getEventvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEvent} returns this
 */
proto.Mo_multiuser.CursorEvent.prototype.setEventvalue = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.CursorEventReceive.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.CursorEventReceive.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.CursorEventReceive} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.CursorEventReceive.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        eventvalue: jspb.Message.getFieldWithDefault(msg, 2, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 3, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.CursorEventReceive}
 */
proto.Mo_multiuser.CursorEventReceive.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.CursorEventReceive()
  return proto.Mo_multiuser.CursorEventReceive.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.CursorEventReceive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.CursorEventReceive}
 */
proto.Mo_multiuser.CursorEventReceive.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setEventvalue(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.CursorEventReceive.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.CursorEventReceive.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.CursorEventReceive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.CursorEventReceive.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getEventvalue()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.CursorEventReceive.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEventReceive} returns this
 */
proto.Mo_multiuser.CursorEventReceive.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string EventValue = 2;
 * @return {string}
 */
proto.Mo_multiuser.CursorEventReceive.prototype.getEventvalue = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEventReceive} returns this
 */
proto.Mo_multiuser.CursorEventReceive.prototype.setEventvalue = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.Mo_multiuser.CursorEventReceive.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.CursorEventReceive} returns this
 */
proto.Mo_multiuser.CursorEventReceive.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessageSend.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.ChatMessageSend.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessageSend} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessageSend.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        messagecontent: jspb.Message.getFieldWithDefault(msg, 2, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessageSend}
 */
proto.Mo_multiuser.ChatMessageSend.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessageSend()
  return proto.Mo_multiuser.ChatMessageSend.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessageSend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessageSend}
 */
proto.Mo_multiuser.ChatMessageSend.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setMessagecontent(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessageSend.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.ChatMessageSend.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessageSend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessageSend.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getMessagecontent()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessageSend.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessageSend} returns this
 */
proto.Mo_multiuser.ChatMessageSend.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string MessageContent = 2;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessageSend.prototype.getMessagecontent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessageSend} returns this
 */
proto.Mo_multiuser.ChatMessageSend.prototype.setMessagecontent = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Mo_multiuser.ChatMessageCollection.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessageCollection.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.ChatMessageCollection.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessageCollection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessageCollection.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        messagesList: jspb.Message.toObjectList(
          msg.getMessagesList(),
          proto.Mo_multiuser.ChatMessage.toObject,
          includeInstance
        )
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessageCollection}
 */
proto.Mo_multiuser.ChatMessageCollection.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessageCollection()
  return proto.Mo_multiuser.ChatMessageCollection.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessageCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessageCollection}
 */
proto.Mo_multiuser.ChatMessageCollection.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.Mo_multiuser.ChatMessage()
        reader.readMessage(
          value,
          proto.Mo_multiuser.ChatMessage.deserializeBinaryFromReader
        )
        msg.addMessages(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.ChatMessageCollection.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessageCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessageCollection.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMessagesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Mo_multiuser.ChatMessage.serializeBinaryToWriter
    )
  }
}

/**
 * repeated ChatMessage Messages = 1;
 * @return {!Array<!proto.Mo_multiuser.ChatMessage>}
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.getMessagesList = function () {
  return /** @type{!Array<!proto.Mo_multiuser.ChatMessage>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.Mo_multiuser.ChatMessage,
    1
  ))
}

/**
 * @param {!Array<!proto.Mo_multiuser.ChatMessage>} value
 * @return {!proto.Mo_multiuser.ChatMessageCollection} returns this
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.setMessagesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.Mo_multiuser.ChatMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mo_multiuser.ChatMessage}
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.addMessages = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.Mo_multiuser.ChatMessage,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Mo_multiuser.ChatMessageCollection} returns this
 */
proto.Mo_multiuser.ChatMessageCollection.prototype.clearMessagesList = function () {
  return this.setMessagesList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.ChatMessageCollectionRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessageCollectionRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fromtime:
          (f = msg.getFromtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        totime:
          (f = msg.getTotime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
        count: jspb.Message.getFieldWithDefault(msg, 4, 0),
        userid: jspb.Message.getFieldWithDefault(msg, 5, '')
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessageCollectionRequest()
  return proto.Mo_multiuser.ChatMessageCollectionRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setFromtime(value)
        break
      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setTotime(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readInt32())
        msg.setCount(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.ChatMessageCollectionRequest.serializeBinaryToWriter(
    this,
    writer
  )
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessageCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFromtime()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getTotime()
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
  f = message.getCount()
  if (f !== 0) {
    writer.writeInt32(4, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setFormid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional google.protobuf.Timestamp FromTime = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getFromtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    2
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setFromtime = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.clearFromtime = function () {
  return this.setFromtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.hasFromtime = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * optional google.protobuf.Timestamp ToTime = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getTotime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    3
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setTotime = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.clearTotime = function () {
  return this.setTotime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.hasTotime = function () {
  return jspb.Message.getField(this, 3) != null
}

/**
 * optional int32 Count = 4;
 * @return {number}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getCount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setCount = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 4, value)
}

/**
 * optional string UserID = 5;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessageCollectionRequest} returns this
 */
proto.Mo_multiuser.ChatMessageCollectionRequest.prototype.setUserid = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.MessageCount.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.MessageCount.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.MessageCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.MessageCount.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        messages: jspb.Message.getFieldWithDefault(msg, 1, 0)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.MessageCount}
 */
proto.Mo_multiuser.MessageCount.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.MessageCount()
  return proto.Mo_multiuser.MessageCount.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.MessageCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.MessageCount}
 */
proto.Mo_multiuser.MessageCount.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32())
        msg.setMessages(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.MessageCount.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.MessageCount.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.MessageCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.MessageCount.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getMessages()
  if (f !== 0) {
    writer.writeInt32(1, f)
  }
}

/**
 * optional int32 Messages = 1;
 * @return {number}
 */
proto.Mo_multiuser.MessageCount.prototype.getMessages = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.Mo_multiuser.MessageCount} returns this
 */
proto.Mo_multiuser.MessageCount.prototype.setMessages = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Mo_multiuser.ChatMessage.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.Mo_multiuser.ChatMessage.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Mo_multiuser.ChatMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Mo_multiuser.ChatMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        formid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        messagecontent: jspb.Message.getFieldWithDefault(msg, 3, ''),
        sendtime:
          (f = msg.getSendtime()) &&
          google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Mo_multiuser.ChatMessage}
 */
proto.Mo_multiuser.ChatMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.Mo_multiuser.ChatMessage()
  return proto.Mo_multiuser.ChatMessage.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Mo_multiuser.ChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Mo_multiuser.ChatMessage}
 */
proto.Mo_multiuser.ChatMessage.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setFormid(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserid(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setMessagecontent(value)
        break
      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp()
        reader.readMessage(
          value,
          google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader
        )
        msg.setSendtime(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Mo_multiuser.ChatMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.Mo_multiuser.ChatMessage.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Mo_multiuser.ChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Mo_multiuser.ChatMessage.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFormid()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getMessagecontent()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getSendtime()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    )
  }
}

/**
 * optional string FormID = 1;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessage.prototype.getFormid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setFormid = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string UserID = 2;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessage.prototype.getUserid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setUserid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string MessageContent = 3;
 * @return {string}
 */
proto.Mo_multiuser.ChatMessage.prototype.getMessagecontent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setMessagecontent = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional google.protobuf.Timestamp SendTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Mo_multiuser.ChatMessage.prototype.getSendtime = function () {
  return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(
    this,
    google_protobuf_timestamp_pb.Timestamp,
    4
  ))
}

/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.setSendtime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.Mo_multiuser.ChatMessage} returns this
 */
proto.Mo_multiuser.ChatMessage.prototype.clearSendtime = function () {
  return this.setSendtime(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Mo_multiuser.ChatMessage.prototype.hasSendtime = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.LockState = {
  UNCOMMITED: 0,
  COMMITTED_NORMALLY: 5,
  COMMITTED_NORMALLY_AND_SUBMITED: 6,
  COMMITTED_ASYNCHRONOUS: 10,
  COMMITTED_ASYNCHRONOUS_AND_SUBMITED: 11
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.LockOperation = {
  LOCK: 0,
  UPDATE: 5,
  COMMIT: 10,
  CANCEL: 15
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.OperationResult = {
  FAILED: 0,
  FAILED_TIMEOUT: 5,
  SUCCESS: 10
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.FormCacheType = {
  MANUAL_SAVE: 0,
  AUTO_SAVE: 5,
  CHANGES_NOT_SAVED: 10
}

/**
 * @enum {number}
 */
proto.Mo_multiuser.RequestStatus = {
  BLOCKED: 0,
  ALLOWED: 5
}

goog.object.extend(exports, proto.Mo_multiuser)
