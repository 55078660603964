/**
 * Created by Michał Stawski on 20.05.2020.
 */
export const PROD = 'prod'
export const SCRIPT = 'script'
export const TESTING = 'testing'
export const HOTFIX = 'hotfix'
export const REVIEW = 'review'
export const INTERNAL = 'internal'
export const INTERNAL_TESTING = 'internal_testing'
export const ICCE = 'icce'
export const ICCE_TESTING = 'iccetesting'
export const ICCE_INTERNAL_TESTING = 'icceinternaltesting'
export const ICCE_INTERNAL = 'icceinternal'
export const ICCE_REVIEW = 'iccereview'

export const devEnvs = [TESTING, SCRIPT, ICCE_TESTING]
export const internalEnvs = [
  INTERNAL,
  INTERNAL_TESTING,
  ICCE_INTERNAL,
  ICCE_INTERNAL_TESTING
]
export const prodEnvs = [HOTFIX, PROD, REVIEW, ICCE_REVIEW, ICCE]
export const nonInternalEnvs = [
  HOTFIX,
  PROD,
  REVIEW,
  ICCE_REVIEW,
  ICCE,
  ICCE_TESTING,
  TESTING
]
export const chtcEnvs = [
  HOTFIX,
  PROD,
  REVIEW,
  TESTING,
  INTERNAL_TESTING,
  INTERNAL
]
export const icceEnvs = [ICCE, ICCE_TESTING, ICCE_INTERNAL, ICCE_REVIEW]

const configs = {
  [PROD]: {
    instanceUrl: 'https://chtc-ctlc.my.salesforce.com',
    loginUrl: 'https://chtc.force.com/APIConnectionPortal',
    clientId:
      '3MVG9nkapUnZB56EDSefar0nDpAV8iiLs5xo8zilpKveJAWRPx3Q0t4xShdTGu.B8hf85N.SIM7qHytNhoKP2',
    redirectUri: 'https://community.centre.support',
    gaConfig: 'G-HPQ0V1375K',
    multiuserHost: 'https://main.multiuser.magnaro.com'
  },
  // same connection as prod just with new code
  [HOTFIX]: {
    instanceUrl: 'https://chtc-ctlc.my.salesforce.com',
    loginUrl: 'https://chtc.force.com/APIConnectionPortal',
    clientId:
      '3MVG9nkapUnZB56EDSefar0nDpAV8iiLs5xo8zilpKveJAWRPx3Q0t4xShdTGu.B8hf85N.SIM7qHytNhoKP2',
    redirectUri: 'https://hotfix.centre.support',
    gaConfig: 'G-HPQ0V1375K',
    multiuserHost: 'https://main.multiuser.magnaro.com'
  },
  [REVIEW]: {
    instanceUrl: 'https://chtc-ctlc.my.salesforce.com',
    loginUrl: 'https://chtc.force.com/APIConnectionPortal',
    clientId:
      '3MVG9nkapUnZB56EDSefar0nDpAV8iiLs5xo8zilpKveJAWRPx3Q0t4xShdTGu.B8hf85N.SIM7qHytNhoKP2',
    redirectUri: 'https://review.centre.support',
    gaConfig: 'G-HPQ0V1375K',
    multiuserHost: 'https://dev1.multiuser.magnaro.com'
  },
  [INTERNAL]: {
    instanceUrl: 'https://chtc-ctlc.my.salesforce.com',
    loginUrl: 'https://login.salesforce.com',
    clientId:
      '3MVG9nkapUnZB56EDSefar0nDpAV8iiLs5xo8zilpKveJAWRPx3Q0t4xShdTGu.B8hf85N.SIM7qHytNhoKP2',
    redirectUri: 'https://internal.centre.support',
    gaConfig: 'G-HPQ0V1375K',
    multiuserHost: 'https://main.multiuser.magnaro.com'
  },
  [SCRIPT]: {
    instanceUrl: 'https://chtc-ctlc--script.my.salesforce.com',
    loginUrl:
      'https://script-chtc.can20s.sfdc-58ktaz.force.com/APIConnectionPortal',
    clientId:
      '3MVG9SUK8FGR3O.tJC0xL_RuyKrAj1GPEpQx.beFIT.5PLDTerGvqdjF_FNQIKH1.nCMRn9M91eLCdn6L8Ry3',
    redirectUri: 'https://dev.centre.support',
    gaConfig: 'G-XMNP6TZ223',
    sandbox: true,
    multiuserHost: 'https://main.multiuser.magnaro.com'
  },
  [TESTING]: {
    instanceUrl: 'https://chtc-ctlc--testing.sandbox.my.salesforce.com',
    loginUrl:
      'https://chtc-ctlc--testing.sandbox.my.site.com/APIConnectionPortal',
    clientId:
      '3MVG9rIycKjurncZx12hVuPLVUlLX260T6VHDh9HhDKuOhTQgvZQPtFML6tQxazTc2B0LBYz6DRTU5NFxscUh',
    redirectUri: 'https://testing.centre.support',
    gaConfig: 'G-XMNP6TZ223',
    sandbox: true,
    multiuserHost: 'https://dev0.multiuser.magnaro.com'
  },
  [INTERNAL_TESTING]: {
    instanceUrl: 'https://chtc-ctlc--testing.my.salesforce.com',
    loginUrl: 'https://test.salesforce.com',
    clientId:
      '3MVG9rIycKjurncZx12hVuPLVUlLX260T6VHDh9HhDKuOhTQgvZQPtFML6tQxazTc2B0LBYz6DRTU5NFxscUh',
    redirectUri: 'https://internaltesting.centre.support',
    gaConfig: 'G-XMNP6TZ223',
    sandbox: true,
    multiuserHost: 'https://dev0.multiuser.magnaro.com'
  },
  [ICCE]: {
    instanceUrl: 'https://icce.my.salesforce.com',
    loginUrl: 'https://icce-caec.force.com/iccecommunity',
    clientId:
      '3MVG9cHH2bfKACZYPEeYStEFE64wvYSyN1NzARNR6kIcuMXl8bnnzMlR2QfrRDJuZ.iet8Z373Ej3hM1eSYER',
    redirectUri: 'https://community.icce-caec.ca',
    gaConfig: 'G-1TEYW5MG6Q',
    multiuserHost: 'https://main.multiuser.magnaro.com'
    // sandbox: true,
  },
  [ICCE_REVIEW]: {
    instanceUrl: 'https://icce.my.salesforce.com',
    loginUrl: 'https://icce-caec.force.com/iccecommunity',
    clientId:
      '3MVG9cHH2bfKACZYPEeYStEFE64wvYSyN1NzARNR6kIcuMXl8bnnzMlR2QfrRDJuZ.iet8Z373Ej3hM1eSYER',
    redirectUri: 'https://review.icce-caec.ca',
    gaConfig: 'G-YVCTL5M8M1',
    multiuserHost: 'https://main.multiuser.magnaro.com'
  },
  [ICCE_INTERNAL]: {
    instanceUrl: 'https://icce.my.salesforce.com',
    loginUrl: 'https://login.salesforce.com',
    clientId:
      '3MVG9cHH2bfKACZYPEeYStEFE64wvYSyN1NzARNR6kIcuMXl8bnnzMlR2QfrRDJuZ.iet8Z373Ej3hM1eSYER',
    redirectUri: 'https://internal.icce-caec.ca',
    gaConfig: 'G-CXS1CBYSV7',
    multiuserHost: 'https://main.multiuser.magnaro.com'
  },
  [ICCE_TESTING]: {
    instanceUrl: 'https://icce--pc.sandbox.my.salesforce.com',
    loginUrl: 'https://icce--pc.sandbox.my.site.com/iccecommunity',
    clientId: '3MVG9P7Pp4QrREPm4EkHavd5XzhiCD3alk6gDdu_InkluP_X_U4ieaJpxSq3babhsprojUxZxrCRtyMIwFEVL',
    redirectUri: 'https://testing.icce-caec.ca',
    sandbox: true,
    multiuserHost: 'https://dev0.multiuser.magnaro.com'
  },
  [ICCE_INTERNAL_TESTING]: {
    instanceUrl: 'https://icce--pc.sandbox.my.salesforce.com',
    loginUrl: 'https://test.salesforce.com',
    clientId:
      '3MVG9P7Pp4QrREPm4EkHavd5XzhiCD3alk6gDdu_InkluP_X_U4ieaJpxSq3babhsprojUxZxrCRtyMIwFEVL',
    redirectUri: 'https://internaltesting.icce-caec.ca',
    sandbox: true,
    multiuserHost: 'https://dev0.multiuser.magnaro.com'
  }
}

let current_config

current_config = configs[process.env.REACT_APP_ENVIROMENT]

if (!current_config) {
  current_config = configs[PROD]
}

export const isSandbox = () => {
  return current_config.sandbox === true
  //  return process.env.REACT_APP_ENVIROMENT !== PROD && process.env.REACT_APP_ENVIROMENT !== HOTFIX
}

console.log(
  'current site',
  `${window.location.protocol}//${window.location.host}`,
  process.env.REACT_APP_ENVIROMENT,
  current_config.instanceUrl
)

const sfOauthConfig = {
  scope: 'web api id',
  response_type: 'token',
  instanceUrl: current_config.instanceUrl,
  // you can change loginUrl to connect to sandbox or prerelease env.
  oauth2: {
    loginUrl: current_config.loginUrl,
    clientId: current_config.clientId,
    clientSecret: current_config.clientSecret,
    redirectUri:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : // : current_config.redirectUri,
          `${window.location.protocol}//${window.location.host}`
  },
  config: current_config,
  isLive:
    process.env.REACT_APP_ENVIROMENT &&
    (process.env.REACT_APP_ENVIROMENT.indexOf('live') !== -1 ||
      process.env.REACT_APP_ENVIROMENT.indexOf('prod') !== -1),
  isInternal:
    process.env.REACT_APP_ENVIROMENT &&
    process.env.REACT_APP_ENVIROMENT.indexOf('internal') !== -1,
  isIcce:
    process.env.REACT_APP_ENVIROMENT &&
    process.env.REACT_APP_ENVIROMENT.indexOf('icce') !== -1,
  currentEnv: process.env.REACT_APP_ENVIROMENT
}

export default sfOauthConfig
