import { t, Trans } from '@lingui/macro'
import {
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  IconButton,
  Icon,
  RadioGroup,
  Radio
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { snackbarClasses } from 'app/App'
import { dateFormat } from 'app/appSettings'
import {
  bilingualDateFormat,
  CurrencyFormated,
  currencyFormatedString,
  numberFormat,
  percentFormatted,
  percentFormattedString
} from 'app/views/common/Formats'
import { useFormikContext } from 'formik'
import moment from 'moment'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import ReactHtmlParser from 'react-html-parser'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import Html from 'react-pdf-html'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import {
  FORMS_PREVIEW_ID_OPPORTUNITY,
  FORMS_PREVIEW_ID_RESOURCE
} from 'app/views/internal/Forms'
import { formElementTypes } from '../GroupElement'
import { formObjectsToConnect } from '../FormWizard'

export const pdfDefaultFontSize = 10
export const commonPdfStyles = StyleSheet.create({
  title: {
    padding: 10,
    fontSize: 16,
    fontWeight: 400
  },
  row: {
    flexWrap: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: '1px solid black',
    borderCollapse: 'collapse'
  }
})

export const getMuState = ({ values, id }) => {
  const { muInfo, muUsers = {} } = values
  const muState = muInfo[id]
  let userColor, userName, isEdited
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      isEdited = true
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }
  return {
    isEdited,
    userColor,
    userName
  }
}

export const MuEditedByLabel = ({ color, userName }) => {
  if (!userName) {
    return null
  }
  return (
    <span style={{ fontSize: 11, color, marginBottom: 8 }}>
      <Trans>Is edited by</Trans>
      {' ' + userName}
    </span>
  )
}

export const guessFieldType = value => {
  let type = 'string'
  if (!value) {
    return type
  }
  if (!isNaN(+value)) {
    type = 'int'
  } else if (moment(value).isValid()) {
    type = 'date'
  } else if (value === 'true' || value === 'false') {
    type = 'boolean'
  } else if (value && value.includes(';')) {
    type = 'multipicklist'
  }
  return type
}

export const parseSfFieldValue = ({ value, type, french, translate }) => {
  if (['date', 'datetime'].includes(type)) {
    if (moment.utc(value).isValid()) {
      if (translate) {
        value = bilingualDateFormat(value, french)
      } else {
        value = moment.utc(value).format(dateFormat)
      }
    }
  } else if (['multipicklist', 'picklist'].includes(type)) {
    if (value) {
      value = value.replaceAll(';', ', ')
    }
  } else if (type === 'currency') {
    value = currencyFormatedString(value, french)
  } else if (type === 'boolean') {
    if (typeof value === 'boolean') {
      value = value ? myI18n._(t`Yes`) : myI18n._(t`No`)
    }
  } else if (type === 'percent') {
    if (typeof value === 'number') {
      value = percentFormattedString(value, french)
    }
  }
  if (translate) {
    value = String(myI18n._(value))
  }
  return value
}

export const parseDisplayedText = ({
  text,
  french,
  renderProps = {},
  textStyle = {},
  injectablesMap = {},
  describeMap = {},
  objectsFieldsMap = {},
  pdf,
  returnString = false
}) => {
  let toRet = text || ''
  const elementsArray = []
  const occurrences = toRet.match(/!{([^}]*)}/g)
  if (!occurrences) {
    return toRet
    // if (pdf) {
    //   return <Text style={textStyle}>{toRet}</Text>
    // } else {
    //   return toRet
    // }
  }

  occurrences.forEach(occurrence => {
    let error, replace, html, translate
    let objName = occurrence.substring(
      occurrence.indexOf('{') + 1,
      occurrence.indexOf('.')
    )
    let field = occurrence.substring(
      occurrence.indexOf('.') + 1,
      occurrence.indexOf('}')
    )
    if (objName.includes('T/')) {
      translate = true
      objName = objName.replaceAll('T/', '')
    }
    if (objName === 'SPECIAL') {
      switch (field) {
        case 'CURRENT_DATE':
          replace = translate
            ? bilingualDateFormat(moment(), french)
            : moment.utc().format(dateFormat)
          break
        default:
          replace = 'INCORRECT VALUE FOR SPECIAL FIELD: ' + field
          break
      }
    } else if (objectsFieldsMap[objName]) {
      let obj = objectsFieldsMap[objName][field]
      if (!obj && field.indexOf('.') !== -1) {
        const subField = field.split('.')[1]
        field = field.split('.')[0]
        if (field && subField) {
          let referenceField = objectsFieldsMap[objName][field]
          if (!referenceField || typeof referenceField !== 'object') {
            field = field.split('__r')[0] + 'Id' + '__r'
            referenceField = objectsFieldsMap[objName][field]
          }
          try {
            // if lookup field has no id we don't want to show error
            if (typeof referenceField === 'object' && referenceField.value) {
              const fieldValue = referenceField.value[subField]
              if (typeof fieldValue !== 'object') {
                let fieldType
                const parentObject = referenceField.value
                const parentType = parentObject.attributes.type
                if (describeMap[parentType]) {
                  describeMap[parentType].fields.some(field => {
                    const bool = field.name === subField
                    if (bool) {
                      fieldType = field.type
                    }
                    return bool
                  })
                }
                obj = {
                  value: fieldValue,
                  type: fieldType || guessFieldType(fieldValue)
                }
              } else {
                obj = fieldValue
              }
            } else {
              obj = {
                value: '',
                type: 'string'
              }
            }
          } catch (er) {
            error = 'NO SUBFIELD ' + subField + ' FOR FIELD: ' + field
          }
        }
      }
      if (obj) {
        const value = obj.value
        if (['date', 'datetime'].includes(obj.type)) {
          if (moment.utc(value).isValid()) {
            if (translate) {
              replace = bilingualDateFormat(value, french)
            } else {
              replace = moment.utc(value).format(dateFormat)
            }
          }
        } else if (obj.type === 'currency') {
          replace = currencyFormatedString(value, french)
        } else if (['multipicklist', 'picklist'].includes(obj.type)) {
          if (value) {
            replace = value.replaceAll(';', ', ')
          }
        } else if (['int', 'double'].includes(obj.type)) {
          replace = returnString || pdf ? value : numberFormat(value)
        } else if (obj.type === 'boolean') {
          if (typeof value === 'boolean') {
            replace = value ? myI18n._(t`Yes`) : myI18n._(t`No`)
          }
        } else if (
          [
            'string',
            'textarea',
            'email',
            'url',
            'phone',
            'address',
            'id',
            'reference'
          ].includes(obj.type)
        ) {
          if (/<\/?[a-z][\s\S]*>/i.test(value) && !returnString) {
            replace = pdf ? (
              <View>
                <Html style={textStyle}>{value}</Html>
              </View>
            ) : (
              ReactHtmlParser(value)
            )
            html = true
          } else {
            replace = value
          }
        } else {
          console.warn('No value parse configured for field type: ', obj)
          if (obj.type === 'object') {
            error =
              'REFERENCE: ' +
              occurrence +
              ' REFERENCES OBJECT, NOT SINGLE FIELD'
          } else {
            error = 'THIS TYPE OF FIELD IS NOT SUPPORTED: ' + obj.type
          }
        }
      } else if (!error) {
        error = 'NO FIELD: ' + field
      }
    } else {
      const referenceString = occurrence.substring(
        occurrence.indexOf('{') + 1,
        occurrence.indexOf('}')
      )
      if (injectablesMap[referenceString]) {
        if (renderProps && renderProps.injectableId === referenceString) {
          error =
            occurrence +
            ' COULD NOT BE REPLACED. THE FORM ELEMENT CANNOT REFERENCE ITSELF'
        } else if (!returnString) {
          const element = injectablesMap[referenceString]
          const { elementType, injectableId } = element
          if (renderProps && renderProps.injectableId === injectableId) {
            error =
              occurrence +
              ' COULD NOT BE REPLACED. THE FORM ELEMENT CANNOT REFERENCE ITSELF'
          } else {
            const props = {
              pdf,
              objectsFieldsMap,
              injectablesMap,
              describeMap,
              ...renderProps,
              ...element
            }
            if (pdf) {
              replace = formElementTypes[elementType].formComponentPdf(props)
            } else {
              replace = formElementTypes[elementType].formComponent(props)
            }
          }
        } else {
          error =
            occurrence +
            ' COULD NOT BE REPLACED WITH DESIRED ELEMENT. THIS FORM ELEMENT CAN ONLY RENDER PLAIN TEXT, NOT ANOTHER ELEMENTS'
        }
      } else {
        error = 'NO OBJECT OR ELEMENT WAS CONFIGURED FOR: ' + occurrence
      }
    }
    if (!replace) {
      replace = ''
    }
    if (translate) {
      replace = String(myI18n._(replace))
    }
    if (returnString) {
      toRet = toRet.replace(occurrence, error || replace)
    } else {
      let element
      const sub1 = toRet.split(occurrence)[0]
      if (html) {
        element = replace
        if (sub1) {
          elementsArray.push(sub1)
        }
        elementsArray.push(element)
      } else {
        element = error || replace
        elementsArray.push(sub1, element)
      }
      toRet = toRet.split(occurrence)[1] || ''
    }
  })
  if (elementsArray.length === 0) {
    return toRet
    // if (pdf) {
    //   return <Text style={textStyle}>{toRet}</Text>
    // } else {
    //   return toRet
    // }
  }
  if (toRet) {
    // elementsArray.push(pdf ? <Text style={textStyle}>{toRet}</Text> : toRet)
    elementsArray.push(toRet)
  }

  if (pdf) {
    let returnArray = []
    let tempArray = []
    elementsArray.forEach(item => {
      if (item.type) {
        // returnArray.push(<Text style={textStyle}>{tempArray}</Text>)
        returnArray = returnArray.concat(tempArray)
        returnArray.push(item)
        tempArray = []
      } else {
        tempArray.push(item)
      }
    })
    if (tempArray.length > 0) {
      // returnArray.push(<Text style={textStyle}>{tempArray}</Text>)
      returnArray = returnArray.concat(tempArray)
    }
    return returnArray
  } else {
    return returnString ? toRet : elementsArray
  }
}

export const constructFormAddressString = ({
  previewConfig,
  user,
  userId,
  technicalAdvisoryAssignemntId,
  externalReviewId,
  reportId,
  organizationId,
  organization,
  configuration = {},
  opportunity,
  contact,
  resource,
  ids = {},
  objectsConnected = []
}) => {
  let defaultAddressString = []
  const objectsCount = {}
  objectsConnected.forEach(obj => {
    let id = ids[obj.type] || 'NO_OBJECT_ID'
    if (id === 'NO_OBJECT_ID') {
      if (obj.type === 'Account') {
        id = organizationId || organization.id
      } else if (obj.type === 'User') {
        id = userId || user?.userId
      } else if (obj.type === 'Opportunity') {
        id = opportunity || configuration[FORMS_PREVIEW_ID_OPPORTUNITY]
      } else if (obj.type === 'Resource__c') {
        id = configuration[FORMS_PREVIEW_ID_RESOURCE]
      } else if (obj.type === 'Contact') {
        id = contact
      } else if (obj.type === 'FGM_Base__Grantee_Report__c') {
        id = reportId
      } else if (obj.type === 'FGM_Base__Review__c') {
        id = externalReviewId
      } else if (obj.type === 'TechnicalAdvisoryAssignment__c') {
        id = technicalAdvisoryAssignemntId
      }
    }
    if (Array.isArray(id)) {
      if (!objectsCount[obj.type]) {
        objectsCount[obj.type] = 1
        id = id[0]
      } else {
        id = id[objectsCount[obj.type]]
        objectsCount[obj.type] += 1
      }
    }
    if (previewConfig && previewConfig[obj.type]) {
      const idsArray = previewConfig[obj.type] || []
      if (!objectsCount[obj.type]) {
        objectsCount[obj.type] = 1
        id = idsArray[0]
      } else {
        id = idsArray[objectsCount[obj.type]]
        objectsCount[obj.type] += 1
      }
    }
    defaultAddressString.push(obj.identId + '=' + id)
  })
  defaultAddressString = defaultAddressString.join(';')
  return defaultAddressString
}

export const handleSelectActions = ({
  toSet,
  checked,
  selectActions,
  lastOptionActions = []
}) => {
  const modifiedIds = []
  Array.isArray(selectActions) &&
    selectActions.forEach(actionObj => {
      const { activation, target, action, targetProps } = actionObj
      const activate =
        ((!activation || activation === 'select') && checked) ||
        (activation === 'deselect' && !checked)
      if (target && activate) {
        if (action === 'reset') {
          if (targetProps?.picklistType === 'multiselect') {
            toSet[target] = []
            modifiedIds.push(target)
          } else {
            toSet[target] = ''
            modifiedIds.push(target)
          }
        }
      }
    })

  lastOptionActions.forEach(actionObj => {
    const { activation, target, action, targetProps } = actionObj
    if (target && activation === 'deselect') {
      if (action === 'reset') {
        if (targetProps?.picklistType === 'multiselect') {
          toSet[target] = []
          modifiedIds.push(target)
        } else {
          toSet[target] = ''
          modifiedIds.push(target)
        }
      }
    }
  })
  return modifiedIds
}

export const ConfigureSelectActions = ({
  depth,
  id,
  french,
  index,
  typeProps,
  selectActions
}) => {
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const avaliableElements = mapEditorFormElements({
    data: tree,
    french,
    id,
    elementTypes: [
      'textInputNumeric',
      'numericSlider',
      'textInput',
      'bool',
      'picklist'
    ],
    asMap: true
  })

  const isSub = index || index === 0
  return (
    <>
      <Grid container direction='row' alignItems='center'>
        <Typography>
          <Trans>Actions</Trans>
        </Typography>
        <IconButton
          onClick={() => {
            const toSet = { ...typeProps }
            if (isSub) {
              toSet.options[index].selectActions = [...selectActions]
              toSet.options[index].selectActions.push({})
            } else {
              toSet.selectActions = [...selectActions]
              toSet.selectActions.push({})
            }
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <Icon>add</Icon>
        </IconButton>
      </Grid>
      {selectActions.map((item, actionIndex) => {
        return (
          <Grid
            item
            container
            direction='row'
            key={actionIndex}
            justifyContent='space-between'
            alignItems='center'
            wrap='nowrap'
          >
            <Grid item style={{ flex: 1, padding: 10 }}>
              <Grid container direction='row'>
                <Grid xs style={{ padding: 5 }}>
                  <TextField
                    select
                    variant='outlined'
                    label={<Trans>Activate on</Trans>}
                    fullWidth
                    value={item.activation || 'select'}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      if (isSub) {
                        toSet.options[index].selectActions[
                          actionIndex
                        ].activation = e.target.value
                      } else {
                        toSet.selectActions[actionIndex].activation =
                          e.target.value
                      }
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'selectActions',
                        fieldValue: toSet
                      })
                    }}
                  >
                    <MenuItem value='select'>
                      <Trans>On select</Trans>
                    </MenuItem>
                    <MenuItem value='deselect'>
                      <Trans>On deselect</Trans>
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid xs style={{ padding: 5 }}>
                  <TextField
                    select
                    variant='outlined'
                    label={<Trans>Action</Trans>}
                    fullWidth
                    value={item.action || ''}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      if (isSub) {
                        toSet.options[index].selectActions[actionIndex].action =
                          e.target.value
                      } else {
                        toSet.selectActions[actionIndex].action = e.target.value
                      }
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'selectActions',
                        fieldValue: toSet
                      })
                    }}
                  >
                    <MenuItem value='reset'>
                      <Trans>Reset element</Trans>
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid xs style={{ padding: 5 }}>
                  <Autocomplete
                    freeSolo={false}
                    value={item.target || ''}
                    onChange={(e, value) => {
                      const targetItem = avaliableElements[value]
                      const toSet = { ...typeProps }
                      const baseObj = {
                        target: targetItem?.id,
                        type: targetItem?.elementType,
                        targetProps: {
                          picklistType: targetItem?.typeProps?.picklistType
                        }
                      }
                      if (isSub) {
                        toSet.options[index].selectActions[actionIndex] = {
                          ...toSet.options[index].selectActions[actionIndex],
                          ...baseObj
                        }
                      } else {
                        toSet.selectActions[actionIndex] = {
                          ...toSet.selectActions[actionIndex],
                          ...baseObj
                        }
                      }
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'selectActions',
                        fieldValue: toSet
                      })
                    }}
                    fullWidth
                    getOptionLabel={id => avaliableElements[id]?.label || ''}
                    options={Object.values(avaliableElements).map(
                      (item, index) => item.id
                    )}
                    renderInput={params => (
                      <TextField
                        variant='outlined'
                        {...params}
                        label={<Trans>Target</Trans>}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div style={{ width: 50 }}>
              <IconButton
                onClick={() => {
                  const toSet = { ...typeProps }
                  if (isSub) {
                    toSet.options[index].selectActions.splice(actionIndex, 1)
                  } else {
                    toSet.selectActions.splice(actionIndex, 1)
                  }
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              >
                <Icon color='primary'>delete</Icon>
              </IconButton>
            </div>
          </Grid>
        )
      })}
    </>
  )
}

export const mapEditorFormElements = ({
  data,
  french,
  id,
  elementTypes = [],
  asMap = false
}) => {
  const returnArray = []

  const mapItem = ({ item, returnArray, section }) => {
    if (item.elements) {
      item.elements.forEach(element =>
        mapItem({ item: element, returnArray, section })
      )
    } else {
      if (id !== item.id && elementTypes.includes(item.elementType)) {
        const title = french ? item.titleFR : item.titleEN
        const sectionName = french ? section.titleFR : section.titleEN
        returnArray.push({
          id: item.id,
          label: '[' + sectionName + '] ' + title,
          elementType: item.elementType,
          typeProps: item.typeProps
        })
      }
    }
  }

  data.sections.forEach(section =>
    section.elements.forEach(item => mapItem({ item, returnArray, section }))
  )

  if (asMap) {
    const retObject = {}
    returnArray.forEach(item => {
      retObject[item.id] = item
    })
    return retObject
  }
  return returnArray
}

export const FormColorAutocomplete = ({
  name,
  injectable,
  fieldArray,
  fieldArrayIndex,
  value,
  typeProps,
  depth,
  label
}) => {
  const dispatch = useDispatch()
  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      alignContent='center'
      wrap='nowrap'
    >
      <Autocomplete
        freeSolo
        value={value || ''}
        onChange={(e, value) => {
          const toSet = { ...typeProps }
          if (fieldArray) {
            toSet[fieldArray][fieldArrayIndex][name] = value || {}
          } else {
            toSet[name] = value || {}
          }
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
        onInput={(e, v) => {
          const toSet = { ...typeProps }
          if (fieldArray) {
            toSet[fieldArray][fieldArrayIndex][name] = { id: e.target.value }
          } else {
            toSet[name] = { id: e.target.value }
          }
          dispatch({
            type: 'FIELD',
            injectable,
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: toSet
          })
        }}
        fullWidth
        getOptionLabel={o => {
          if (!o) {
            return ''
          }
          if (!o.label) {
            return o.id || ''
          }
          return o.label + ' (' + o.id + ')'
        }}
        options={[
          ...Object.keys(snackbarClasses).map((key, index) => {
            const color = snackbarClasses[key].backgroundColor
            return { label: key, id: color }
          }),
          { label: 'White', id: '#ffffff' },
          { label: 'Blue', id: '#0000ff' },
          { label: 'Brown', id: '#a52a2a' },
          { label: 'Green', id: '#008000' },
          { label: 'Orange', id: '#ffa500' },
          { label: 'Pink', id: '#ffc0cb' },
          { label: 'Purple', id: '#800080' },
          { label: 'Red', id: '#ff0000' },
          { label: 'Grey', id: '#808080' }
        ]}
        renderInput={params => (
          <TextField variant='outlined' {...params} label={label} />
        )}
      />
      <div
        style={{
          margin: 10,
          borderRadius: 5,
          height: 50,
          width: 100,
          backgroundColor: value && value.id
        }}
      />
    </Grid>
  )
}

export const FormElementPrintProps = ({
  elementType,
  injectable,
  depth,
  typeProps,
  ...props
}) => {
  const {
    printPageBreakBefore,
    printAvoidBreak,
    printLabelPlacement,
    printIcon
  } = typeProps
  const dispatch = useDispatch()
  return (
    <div style={{ marginTop: 10 }}>
      <Typography variant='h6'>
        <Trans>Print props</Trans>
      </Typography>

      {[
        'datePicker',
        'textInput',
        'textInputNumeric',
        'connectAccount',
        'connectContact'
      ].includes(elementType) && (
        <RadioGroup
          value={printLabelPlacement || 'top'}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.printLabelPlacement = e.currentTarget.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <FormControlLabel
            value='top'
            control={<Radio />}
            label={<Trans>Render label at top</Trans>}
          />

          <FormControlLabel
            value='bottom'
            control={<Radio />}
            label={<Trans>Render label at bottom</Trans>}
          />
        </RadioGroup>
      )}

      {['picklist'].includes(elementType) && (
        <TextField
          select
          variant='outlined'
          label={<Trans>Print list icon</Trans>}
          fullWidth
          value={printIcon || 'none'}
          style={{ marginTop: 10 }}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.printIcon = e.target.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <MenuItem value='none' key='none'>
            <Trans>None</Trans>
          </MenuItem>
          {[
            'supervised_user_circle',
            'accessibility_new',
            'public',
            'fiber_manual_record',
            'payments',
            'location_city',
            'paid',
            'work'
          ].map(item => (
            <MenuItem value={item} key={item}>
              <Icon>{item}</Icon>
            </MenuItem>
          ))}
        </TextField>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(printPageBreakBefore)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.printPageBreakBefore = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Page break before?</Trans>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(printAvoidBreak)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.printAvoidBreak = e.target.checked
              dispatch({
                type: 'FIELD',
                injectable,
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        }
        label={<Trans>Avoid breaking element into separte pages?</Trans>}
      />
    </div>
  )
}

export const FormConnectToObject = ({
  typeProps,
  disableMultiple,
  depth,
  filter,
  validTypes,
  clearOnChange = [],
  clearOnReadOnlyField = [],
  clearOnDisconnect = [],
  handleDisconnect,
  fieldRender,
  allowReadOnlyFields = false,
  connectToCollection,
  noField = false
}) => {
  const dispatch = useDispatch()
  const { isConnected, connectedTo = [] } = typeProps
  const { values } = useFormikContext()
  const { objectsConnected, objects } = values
  const avaliableObjectsMap = {}
  objectsConnected.forEach(obj => {
    avaliableObjectsMap[obj.identId] = obj
  })
  const alreadyConnected = {}
  connectedTo.forEach(obj => {
    if (obj.connectedObject && obj.connectedField) {
      const array = alreadyConnected[obj.connectedObject] || []
      alreadyConnected[obj.connectedObject] = [
        ...array,
        obj.connectedField.name
      ]
    }
  })
  return (
    <div>
      <FormControlLabel
        style={{ marginBottom: 5 }}
        control={
          <Checkbox
            checked={Boolean(isConnected)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.isConnected = e.target.checked
              toSet.connectedTo = []
              if (!toSet.isConnected) {
                clearOnDisconnect.forEach(key => delete toSet[key])
                if (handleDisconnect) {
                  handleDisconnect(toSet)
                }
                delete toSet.readOnly
                delete toSet.forceRequired
              }
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>Is connected to object?</Trans>}
      />
      {isConnected && (
        <div>
          <Grid container alignItems='center'>
            <Trans>Add connection</Trans>
            <IconButton
              size='small'
              disabled={connectedTo.length === 1 && disableMultiple}
              onClick={e => {
                const toSet = { ...typeProps }
                toSet.connectedTo = [...connectedTo]
                toSet.connectedTo.push({
                  connectedObject: ''
                })
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            >
              <Icon>add</Icon>
            </IconButton>
          </Grid>
        </div>
      )}
      {connectedTo.map((obj, index) => {
        const { connectedObject, connectedField, connectedCollection } = obj
        let options = []
        let relatedCollections = []
        connectedObject &&
          objects.some(obj => {
            if (!avaliableObjectsMap[connectedObject]) {
              return false
            }
            const bool = obj.name === avaliableObjectsMap[connectedObject].type
            if (bool) {
              relatedCollections = obj.relatedCollections
              if (connectedCollection) {
                relatedCollections.some(collection => {
                  if (collection.key === connectedCollection) {
                    options = collection.fields
                  }
                  return collection.key === connectedCollection
                })
              }
              options = obj.fields.filter(field => {
                const connectedArray = alreadyConnected[connectedObject] || []
                return (
                  !connectedArray.includes(field.name) ||
                  field.name === connectedField?.name
                )
              })
            }
            return bool
          })
        if (validTypes) {
          options = options.filter(item => validTypes.includes(item.type))
        }
        if (filter) {
          options = options.filter(item => filter(item))
        }
        if (!allowReadOnlyFields) {
          options = options.filter(item => !item.readOnly)
        }
        // if (typeProps.picklistType === 'multiselect') {
        //   options = options.filter(item => item.type === 'multipicklist')
        // }
        const collectionsMap = {}
        const optionsMap = {}
        options.forEach(opt => {
          optionsMap[opt.name] = opt
        })
        relatedCollections.forEach(opt => {
          collectionsMap[opt.key] = opt.label
        })

        return (
          <div key={index} style={{ paddingTop: 5 }}>
            <Grid container justify='space-between' alignItems='center'>
              <span style={{ margin: 4 }}>
                {String(index + 1)}
                {index === 0 && (
                  <span style={{ marginLeft: 8 }}>
                    <b>
                      <Trans>[Main object]</Trans>
                    </b>
                    <TooltipLabelIcon
                      tooltip={
                        <Trans>
                          This field will be used to determine initial value of
                          an element, it's avaliable picklist options etc.
                        </Trans>
                      }
                    />
                  </span>
                )}
              </span>
              <Grid item>
                <IconButton
                  size='small'
                  onClick={e => {
                    const toSet = { ...typeProps }
                    const toMove = toSet.connectedTo[index]
                    const toReplace = toSet.connectedTo[index - 1]
                    toSet.connectedTo[index - 1] = toMove
                    toSet.connectedTo[index] = toReplace
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                  disabled={index === 0}
                >
                  <Icon>arrow_downward</Icon>
                </IconButton>
                <IconButton
                  size='small'
                  onClick={e => {
                    const toSet = { ...typeProps }
                    const toMove = toSet.connectedTo[index]
                    const toReplace = toSet.connectedTo[index + 1]
                    toSet.connectedTo[index + 1] = toMove
                    toSet.connectedTo[index] = toReplace
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                  disabled={index === connectedTo.length - 1}
                >
                  <Icon>arrow_upward</Icon>
                </IconButton>
                <IconButton
                  size='small'
                  onClick={e => {
                    const toSet = { ...typeProps }
                    toSet.connectedTo.splice(index, 1)
                    if (
                      toSet.forceRequired &&
                      !toSet.connectedTo.some(
                        obj => obj.connectedField && obj.connectedField.required
                      )
                    ) {
                      clearOnDisconnect.forEach(key => delete toSet[key])
                      delete toSet.forceRequired
                      delete toSet.required
                    }
                    if (toSet.connectedTo.length === 0) {
                      delete toSet.readOnly
                    }
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: toSet
                    })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <TextField
              select
              label={<Trans>Connected object</Trans>}
              fullWidth
              variant='outlined'
              defaultValue=''
              style={{ marginTop: 10 }}
              value={connectedObject || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                delete toSet.readOnly
                toSet.connectedTo[index].connectedObject = e.target.value
                delete toSet.connectedTo[index].connectedField
                delete toSet.connectedTo[index].connectedCollection
                clearOnChange.forEach(key => delete toSet[key])
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            >
              {objectsConnected.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.identId}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </TextField>
            {Boolean(
              connectedObject &&
                !noField &&
                !fieldRender &&
                !connectToCollection
            ) && (
              <Autocomplete
                freeSolo={false}
                value={connectedField?.name || ''}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  let field
                  options.some(item => {
                    const bool = item.name === value
                    if (bool) {
                      field = item
                    }
                    return bool
                  })
                  toSet.connectedTo[index].connectedField = field
                  clearOnChange.forEach(key => delete toSet[key])
                  if (field) {
                    if (field.type === 'multipicklist') {
                      toSet.picklistType = 'multiselect'
                    } else if (toSet.picklistType) {
                      toSet.picklistType = 'singleselect'
                    }
                    if (field.required) {
                      toSet.required = true
                      toSet.forceRequired = true
                    } else if (
                      toSet.forceRequired &&
                      !toSet.connectedTo.some(
                        obj => obj.connectedField && obj.connectedField.required
                      )
                    ) {
                      clearOnDisconnect.forEach(key => delete toSet[key])
                      delete toSet.forceRequired
                      delete toSet.required
                    }
                    if (field.readOnly) {
                      toSet.readOnly = true
                      clearOnReadOnlyField.forEach(key => delete toSet[key])
                    }
                  }
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                style={{ marginTop: 15 }}
                fullWidth
                getOptionLabel={option => optionsMap[option]?.label || ''}
                options={options.map(item => item.name)}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Connected field</Trans>}
                  />
                )}
              />
            )}
            {connectToCollection && (
              <Autocomplete
                freeSolo={false}
                style={{ marginTop: 10 }}
                value={connectedCollection || ''}
                onChange={(e, value) => {
                  const toSet = { ...typeProps }
                  toSet.connectedTo[index].connectedCollection = value
                  clearOnChange.forEach(key => delete toSet[key])
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                fullWidth
                options={relatedCollections.map(item => item.key)}
                getOptionLabel={o => collectionsMap[o] || ''}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={<Trans>Connected collection</Trans>}
                  />
                )}
              />
            )}
            {Boolean(fieldRender && connectedObject) &&
              fieldRender({
                objects,
                connectedObject,
                index,
                avaliableObjectsMap
              })}
          </div>
        )
      })}
    </div>
  )
}

export function NumberFormatDefault (props) {
  const { inputRef, onChange, id, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      defaultValue=''
      thousandSeparator=','
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}
