import { Trans } from '@lingui/macro'
import {
  Grid,
  TextField,
  Typography,
  Icon,
  IconButton,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from '@material-ui/core'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { parseDisplayedText } from './Common'
import { Image, Svg, View, Text } from '@react-pdf/renderer'
import Dropzone from 'react-dropzone'
import { formUploadComponentStyles } from './FormUploadFiles'
import { useSnackbar } from 'notistack'
import {
  deleteDocument,
  parseDocument,
  uploadFile
} from 'app/services/sfAuth/sfData/sfFiles'
import { useFormikContext } from 'formik'
import { isConditionMet } from '../FormHelpersConditions'
import { useEffect } from 'react'
import reactImageSize from 'react-image-size'
import { DefaultNumericFormat } from 'app/views/common/Formats'
import { useState } from 'react'

export const formImageConditionsStates = {
  altLink: {
    label: <Trans>Show alternative image</Trans>,
    component: ({ onChange, value }) => (
      <Grid xs item>
        <TextField
          variant='outlined'
          fullWidth
          label={<Trans>Link to image</Trans>}
          value={value || ''}
          onChange={e => {
            onChange(e.target.value)
          }}
        />
      </Grid>
    )
  }
}

export const FormImagePrint = ({
  id,
  langFR,
  titleFR,
  titleEN,
  typeProps,
  padding,
  describeMap,
  injectablesMap,
  objectsFieldsMap,
  ...props
}) => {
  const { imgLink, file, width, height, percent } = typeProps
  const [calcHeight, setCalcHeight] = useState()
  const [calcWidth, setCalcWidth] = useState()
  const ref = useRef()
  const url = imgLink
  // if (file && file.url) {
  //   url = file.url
  // } else {
  //   return <View />
  // }

  const source = parseDisplayedText({
    text: url,
    french: langFR,
    describeMap,
    injectablesMap,
    objectsFieldsMap,
    returnString: true
  })

  useEffect(() => {
    if (percent) {
      reactImageSize(source).then(({ width, height }) => {
        setCalcHeight((height * percent) / 100)
        setCalcWidth((width * percent) / 100)
      })
    }
  }, [percent, source])

  const imgStyle = {}
  if (width) {
    imgStyle.maxWidth = +width
  }
  if (height) {
    imgStyle.maxHeight = +height
  }
  if (calcHeight) {
    imgStyle.maxHeight = calcHeight
  }
  if (calcWidth) {
    imgStyle.maxWidth = calcWidth
  }

  return (
    <View ref={ref} style={{ display: 'flex' }}>
      <Image src={source} style={{ ...imgStyle }} />
      <Text
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: 10
        }}
      >
        {langFR ? titleFR : titleEN}
      </Text>
    </View>
  )
}

export const FormImage = ({
  id,
  langFR,
  titleFR,
  titleEN,
  typeProps,
  preview,
  injectablesMap,
  describeMap,
  objectsFieldsMap,
  connectedMap,
  elementsMap,
  conditions = [],
  ...props
}) => {
  const [errorOcurred, setError] = React.useState(false)
  const { values } = useFormikContext()
  const { imgLink, width, height, percent } = typeProps
  const [calcHeight, setCalcHeight] = useState()
  const [calcWidth, setCalcWidth] = useState()
  let url = imgLink
  conditions
    .filter(condition => condition.state === 'altLink')
    .some(condition => {
      const { conditionMet } = isConditionMet({
        condition,
        connectedMap,
        values,
        elementsMap
      })
      if (conditionMet && condition.altLink) {
        url = condition.altLink
        return true
      }
      return false
    })
  // let url
  // if (file && file.url) {
  //   url = file.url
  // } else {
  //   url = imgLink
  // }
  const source = preview
    ? url
    : parseDisplayedText({
        text: url,
        french: langFR,
        describeMap,
        injectablesMap,
        objectsFieldsMap,
        returnString: true
      })

  useEffect(() => {
    if (percent) {
      reactImageSize(source).then(
        ({ width, height }) => {
          setCalcHeight((height * percent) / 100)
          setCalcWidth((width * percent) / 100)
        },
        reject => {
          console.error(
            'Could not read image width and height for url: ' + source
          )
          setError(true)
        }
      )
    }
  }, [percent, source])

  if (errorOcurred) {
    return (
      <Typography
        style={{
          fontWeight: 'bold',
          color: 'red',
          marginLeft: 10
        }}
      >
        <Trans>Invalid image link!</Trans>
      </Typography>
    )
  }

  const imgStyle = {}
  if (width) {
    imgStyle.width = +width
  }
  if (height) {
    imgStyle.height = +height
  }
  if (calcHeight) {
    imgStyle.height = calcHeight
  }
  if (calcWidth) {
    imgStyle.width = calcWidth
  }
  return (
    <Grid direction='column' alignItems='center' container justify='center'>
      <img
        src={source}
        style={imgStyle}
        onError={e => {
          console.log('error', e)
          setError(true)
        }}
      />
      <Typography
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: 10
        }}
      >
        {langFR ? titleFR : titleEN}
      </Typography>
    </Grid>
  )
}

export const FormEditorImage = ({
  typeProps = {},
  id,
  showPrintProps,
  connectedObject,
  french,
  depth,
  formId,
  networkId,
  editMode,
  ...props
}) => {
  const { imgLink, printPageBreakBefore, width, height, percent } = typeProps
  const dispatch = useDispatch()
  const classes = formUploadComponentStyles()
  const { enqueueSnackbar } = useSnackbar()

  const handleOnDrop = ({ files }) => {
    console.log('handleOnDrop', files)
    const file = files[0]
    const toSet = { ...typeProps }
    toSet.file = {
      uploading: true,
      name: file.name
    }
    dispatch({
      type: 'FIELD',
      depth: depth.split('.'),
      fieldName: 'typeProps',
      fieldValue: { ...toSet }
    })
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const binaryStr = reader.result
      console.log('binary', binaryStr)
      enqueueSnackbar(<Trans>Uploading File</Trans>, {
        variant: 'info'
      })
      uploadFile(
        {
          name: file.name,
          tags: id,
          uploadId: formId
        },
        binaryStr,
        networkId
      )
        .then(res => {
          console.log(res)
          enqueueSnackbar(<Trans>Uploaded File</Trans>, {
            variant: 'success'
          })
          const toSet = { ...typeProps }
          toSet.file = parseDocument(res[0])
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        })
        .catch(error => {
          console.warn('cant upload file', error)
          enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
            variant: 'error'
          })
          const toSet = { ...typeProps }
          delete toSet.file
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        })
    }
    reader.readAsArrayBuffer(file)
  }

  const onRemoveItem = ({ id }) => {
    enqueueSnackbar(<Trans>Deleting File</Trans>, {
      variant: 'info'
    })
    deleteDocument(id).then(result => {
      enqueueSnackbar(<Trans>Deleted File</Trans>, {
        variant: 'success'
      })
      const toSet = { ...typeProps }
      delete toSet.file
      dispatch({
        type: 'FIELD',
        depth: depth.split('.'),
        fieldName: 'typeProps',
        fieldValue: { ...toSet }
      })
    })
  }

  if (!editMode) {
    return <FormImage preview {...props} typeProps={typeProps} />
  }

  return (
    <div>
      <TextField
        label={<Trans>Link to image</Trans>}
        fullWidth
        value={imgLink || ''}
        variant='outlined'
        onChange={e => {
          const toSet = { ...typeProps }
          toSet.imgLink = e.target.value
          dispatch({
            type: 'FIELD',
            depth: depth.split('.'),
            fieldName: 'typeProps',
            fieldValue: { ...toSet }
          })
        }}
      />
      <Grid container style={{ paddingTop: 10 }}>
        <Grid item xs style={{ paddingRight: 4 }}>
          <TextField
            label={<Trans>Width</Trans>}
            fullWidth
            value={width || ''}
            variant='outlined'
            InputProps={{
              inputComponent: DefaultNumericFormat,
              endAdornment: <InputAdornment position='end'>px</InputAdornment>
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              delete toSet.percent
              if (e.target.value) {
                toSet.width = e.target.value
              }
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ paddingLeft: 4 }}>
          <TextField
            label={<Trans>Height</Trans>}
            fullWidth
            value={height || ''}
            variant='outlined'
            InputProps={{
              inputComponent: DefaultNumericFormat,
              endAdornment: <InputAdornment position='end'>px</InputAdornment>
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.height = e.target.value
              if (e.target.value) {
                delete toSet.percent
              }
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
        <Grid item xs style={{ paddingLeft: 4 }}>
          <TextField
            label={<Trans>Percent of original size</Trans>}
            fullWidth
            value={percent || ''}
            variant='outlined'
            InputProps={{
              inputComponent: DefaultNumericFormat,
              endAdornment: <InputAdornment position='end'>%</InputAdornment>
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.percent = e.target.value
              if (e.target.value) {
                delete toSet.width
                delete toSet.height
              }
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: { ...toSet }
              })
            }}
          />
        </Grid>
      </Grid>
      {/*
      TODO faraon - uploading file to use in pdf
      <Dropzone
        accept='image/jpeg,image/png,image/svg'
        multiple={false}
        maxFiles={1}
        disabled={file}
        onDrop={files => {
          handleOnDrop({
            files
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={file ? classes.disabled : classes.dropbox}
          >
            <section>
              <div>
                <input {...getInputProps()} />
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <Icon style={{ marginRight: 10 }}>upload</Icon>
                    <Trans>
                      Drag 'n' drop file here, or click to select file
                    </Trans>
                  </Grid>
                </div>
              </div>
              <div />
            </section>
          </div>
        )}
      </Dropzone>
      {file && (
        <>
          <Grid
            container
            direction='row'
            wrap='nowrap'
            justify='space-between'
            alignItems='center'
            style={{ marginBottom: 5 }}
          >
            <Grid item>{file.name}</Grid>
            <Grid item>
              <IconButton
                disabled={file.uploading}
                onClick={e => {
                  onRemoveItem({ id: file.id })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
          </Grid>
          {file.uploading && <LinearProgress />}
        </>
      )} */}
    </div>
  )
}
