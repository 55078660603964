import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import React, { useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useHistory } from 'react-router-dom'
import { t, Trans } from '@lingui/macro'
import SFAuthService from '../../services/sfAuth/SFAuthService'
import { I18n } from '@lingui/react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  CBTI,
  CBTI_RECORD_TYPE,
  createOpportunity,
  FCHI2,
  FCHI2_RECORD_TYPE,
  STF_LP,
  STF_LP_RECORD_TYPE,
  STF_SIP,
  STF_SIP_RECORD_TYPE
} from '../../services/sfAuth/sfData/sfOpportunity'
import { useSnackbar } from 'notistack'
import { getUserParsed } from '../../services/sfAuth/sfData/sfUser'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import CardActions from '@material-ui/core/CardActions'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Grid,
  Icon,
  IconButton,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { CurrencyFormated } from '../common/Formats'

/**
 * Created by Michał Stawski on 18.08.2020.
 */

const cardData = {
  [STF_LP]: {
    mainTitle: t`Local Projects`,
    subtitle: t`Sector Transformation Fund`,
    description: t`Enhance the capacity of your local organisation to provide affordable housing in a better way.`,
    image: '/assets/images/funds-icons-stf-lp.png',
    link: t`https://centre.support/our-grants/local-projects`,
    questions: [
      t`Are you a Canadian local or regional community housing providers (non-profit or cooperative) and/or a non-profit community housing service provider?`,
      t`The purpose, objectives or related programming falls under the funding objectives, priority areas and eligible activities mentioned in the Sector Transformation Fund – Local Project description section`,
      {
        component: (
          <div>
            <Trans>The total value of your project does not exceed</Trans>{' '}
            <CurrencyFormated value={150000} />
          </div>
        )
      },
      t`We authorize the Centre to collect, store and share all the elements in this application with the agencies, collaborators and partners of the Centre necessary for the processing of this application and for the proper functioning of the Centre's activities, as defined by the Centre, including its publications, public activities and research.`
    ],
    recordType: STF_LP_RECORD_TYPE
  },
  [STF_SIP]: {
    mainTitle: t`Sectoral Impact Projects`,
    subtitle: t`Sector Transformation Fund`,
    description: t`Develop new services, models or tools to help the sector build and manage affordable housing.`,
    image: '/assets/images/funds-icons-stf-sip.png',
    link: t`https://centre.support/our-grants/sectoral-impact-projects`,
    disabledMessage: t`Please note that no applications can be accepted for the sectoral impact stream at this point. If you have any questions, please leave us a message by clicking on the bug-icon on the top right.`,
    questions: [
      t`Are you a Canadian local or regional community housing providers (non-profit or cooperative) and/or a non-profit community housing service provider?`,
      t`The purpose, objectives or related programming falls under the funding objectives and eligible activities mentioned in the Sector Transformation Fund - Sectoral Impact Project description.`,
      {
        component: (
          <div>
            <Trans>The total value of your project is higher than</Trans>{' '}
            <CurrencyFormated value={5000} />
          </div>
        )
      },
      t`We authorize the Centre to collect, store and share all the elements in this application with the agencies, collaborators and partners of the Centre necessary for the processing of this application and for the proper functioning of the Centre's activities, as defined by the Centre, including its publications, public activities and research.`
    ],
    recordType: STF_SIP_RECORD_TYPE
  },
  [CBTI]: {
    mainTitle: t`Community-Based Tenant Initiative Fund`,
    description: t`Tenant engagement projects that aim to involve tenants in housing decisions that impact them and in defining services.`,
    image: '/assets/images/funds-icons-cbti.png',
    link: t`https://centre.support/our-grants/community-based-tenant-initiative`,
    questions: [
      t`Are you a Canadian community housing provider (non-profit, social or cooperative), tenant association or community organization ?`,
      t`The purpose, objectives or related programming falls under the funding objectives and eligible activities mentioned in the Community Based Tenant Initiative Fund description.`,
      {
        component: (
          <div>
            <Trans>The total value of your project does not exceed</Trans>{' '}
            <CurrencyFormated value={150000} />
          </div>
        )
      },
      t`We authorize the Centre to collect, store and share all the elements in this application with the agencies, collaborators and partners of the Centre necessary for the processing of this application and for the proper functioning of the Centre's activities, as defined by the Centre, including its publications, public activities and research.`
    ],
    recordType: CBTI_RECORD_TYPE
  },
  [FCHI2]: {
    mainTitle: t`Temporary Rental Assistance`,
    description: t`For organizations whose agreements with CMHC expired before 2016, enrol for temporary rental assistance to subsidize households that are currently paying more than 30% of their gross income towards accommodation. `,
    image: '/assets/images/funds-icons-fchi.png',
    link: t`https://www.placetocallhome.ca`,
    // disabled: true,
    hidden: true,
    disabledMessage: t`This application will reopen on February 8, 2021.`,
    questions: [
      t`You are a local or regional non-profit housing provider operating in Québec or Prince Edward Island.`,
      {
        label: t`You have held an Operation Agreement with CMHC under one of the following social housing programs:`,
        sublist: (
          <ul>
            <li>
              <Trans>Section 95 non-profit, co-operative, urban native</Trans>
            </li>
            <li>
              <Trans>Section 56.1 non-profit, co-operative, urban native</Trans>
            </li>
            <li>
              <Trans>
                Section 95 (Post-85) Index-Linked Mortgage (ILM) co-operatives
              </Trans>
            </li>
            <li>
              <Trans>
                Section 15.1, 27 and 61 non-profit and co-operatives
              </Trans>
            </li>
            <li>
              <Trans>Section 26 non-profit</Trans>
            </li>
          </ul>
        )
      },
      t`One of more of your initial Operation Agreement(s) with CMHC expired before April 1, 2016.`,
      t`You are providing affordable units to low-income household(s).`,
      t`You authorize the Centre to collect, store and share all the elements in this application with the agencies, collaborators and partners of the Centre necessary for the processing of this application and for the proper functioning of the Centre's activities, as defined by the Centre, including its publications, public activities and research.`
    ],
    recordType: FCHI2_RECORD_TYPE
  }
}

/*
// cbti
  Lp

si

  What is the name of your project ?
  Consent

*/

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 345,
    // minWidth: 345,
    padding: 10,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: props =>
      props.disabled
        ? theme.palette.action.disabledBackground
        : props.highlight
        ? theme.palette.secondary.light
        : 'inherit',
    filter: props => (props.disabled ? 'grayscale(1)' : '')
  },
  selectedCard: {
    maxWidth: 450,
    padding: 10,
    height: '100%',
    display: 'flex'
  },
  actions: {
    flexDirection: 'column'
  },
  button: {
    margin: 8
  },
  media: {
    backgroundSize: 'contain',
    height: 140
  }
}))

const ProgramCard = ({ value, checked, onChange, disableWithMessage }) => {
  const disabled = cardData[value].disabled || disableWithMessage
  const classes = useStyles({ highlight: checked, disabled })
  return (
    <Card className={classes.root}>
      <I18n>
        {({ i18n }) => (
          <>
            <CardActionArea
              onClick={() => {
                if (!disabled) {
                  onChange(value)
                }
              }}
            >
              <CardMedia
                className={classes.media}
                image={cardData[value].image}
                title='logo'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  <Trans id={cardData[value].mainTitle} />
                </Typography>
                {disabled && (
                  <Typography variant='subtitle2'>
                    <Trans id={cardData[value].disabledMessage} />
                  </Typography>
                )}
                {cardData[value].subtitle && (
                  <Typography gutterBottom variant='h6' component='h6'>
                    <Trans id={cardData[value].subtitle} />
                  </Typography>
                )}
                <Typography variant='body2' color='textSecondary' component='p'>
                  <>
                    <Trans id={cardData[value].description} />
                    {checked}
                  </>
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.actions}>
              <Button
                color={disabled ? 'disabled' : 'primary'}
                fullWidth
                variant='contained'
                className={classes.button}
                onClick={() => {
                  if (!disabled) {
                    onChange(value)
                  }
                }}
              >
                <Trans>Select</Trans>
              </Button>
              <Button
                color={disabled ? 'disabled' : 'secondary'}
                variant='outlined'
                className={classes.button}
                fullWidth
              >
                <a
                  href={i18n._(cardData[value].link)}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <Trans>Learn More</Trans>
                </a>
              </Button>
            </CardActions>
          </>
        )}
      </I18n>
    </Card>
  )
}

const useStylesDialog = makeStyles({
  root: {
    width: 900
  },
  media: {
    height: 140
  },
  formGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  formGroupItem: {
    // height: '100%',
    width: '32%'
  }
})

const checkEligibility = (eligible, program, name) => {
  if (
    program &&
    Object.keys(eligible).length === cardData[program].questions.length
  ) {
    return (
      !name ||
      !Object.keys(eligible)
        .map(item => eligible[item])
        .reduce((previousValue, currentValue) => previousValue && currentValue)
    )
  }
  return true
}

export const EligibilityDialog = ({
  open = false,
  handleClose,
  recordTypes
}) => {
  const [eligible, setState] = useState({})
  const currentOrganization = useSelector(state => state.organization)
  const user = useSelector(state => state.user)
  const [initialValues, setInitialValues] = useState({})
  const [program, setProgram] = useState('')
  const [name, setName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const steps = [
    <Trans key={1}>Select a founding stream</Trans>,
    <Trans key={2}>Check eligibility</Trans>
  ]

  const classes = useStylesDialog()

  useEffect(() => {
    return () => {
      console.log('effect')
      setProgram('')
      setActiveStep(0)
    }
  }, [open])

  const createApplication = ({ program, ...state }) => {
    setSubmitting(true)
    const conn = SFAuthService.getConnection()
    const recordTypeId = recordTypes[cardData[program].recordType].recordTypeId
    const recordTypeName = cardData[program].recordType
    const creatingKey = enqueueSnackbar(null, {
      variant: 'info',
      persist: true,
      content: key =>
        ProgressSnackbar(
          <Trans>Creating application. This may take a few moments</Trans>
        )
    })
    createOpportunity(conn, {
      ...state,
      recordTypeId,
      recordTypeName,
      accountId: currentOrganization.id
    }).then(
      result => {
        closeSnackbar(creatingKey)
        if (result.hasErrors) {
          enqueueSnackbar(<Trans>Application create error</Trans>, {
            variant: 'error'
          })
          setSubmitting(false)
          handleClose()
        } else {
          const opps = result.results.filter(
            i => i.referenceId === 'opportunityReference'
          )
          if (opps.length === 1) {
            enqueueSnackbar(<Trans>Application Created</Trans>, {
              variant: 'info'
            })
            const id = opps[0].id
            history.push(`/grants/Application/${id}`, {
              ...state,
              id,
              recordTypeId
            })
          } else {
            console.warn('wrong result from create', result)
            enqueueSnackbar(<Trans>Application create error</Trans>, {
              variant: 'error'
            })
          }
        }
      },
      reject => {
        console.warn(reject)
        enqueueSnackbar(<Trans>Application create error</Trans>, {
          variant: 'info'
        })
        setSubmitting(false)
        handleClose()
      }
    )
  }

  const currentSelected = cardData[program]
  console.log(currentSelected)
  return (
    <I18n>
      {({ i18n }) => (
        <Dialog
          open={open}
          maxWidth='md'
          fullWidth
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            <Grid
              container
              direction='row'
              alignItems='center'
              justify='space-between'
            >
              <Trans>Create New Project</Trans>
              <IconButton
                onClick={handleClose}
                color='default'
                disabled={submitting}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 && (
              <>
                <Typography variant='h6'>
                  <Trans>Fund:</Trans>
                </Typography>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justify='center'
                  style={{ alignItems: 'stretch' }}
                >
                  {Object.entries(cardData)
                    .filter(([key, data]) => !data.hidden)
                    .map(([item, data]) => (
                      <Grid item xs={6} key={item}>
                        <FormControlLabel
                          value={item}
                          style={{ margin: 0, height: '100%' }}
                          // className={classes.formGroupItem}
                          label=''
                          control={
                            <ProgramCard
                              disableWithMessage={
                                currentOrganization.province === 'QC' &&
                                item.includes('stf-sip')
                              }
                              value={item}
                              checked={program === item}
                              onChange={value => {
                                let newName = name
                                if (value === FCHI2) {
                                  const organizationName =
                                    currentOrganization.organisationsName
                                  newName = i18n._(
                                    t`${organizationName} TRA Application`
                                  )
                                  setName(newName)
                                }
                                handleNext()
                                setProgram(value)
                                const newState = {}
                                const newInitial = {
                                  name: newName
                                }
                                let index = 0
                                for (const item of cardData[value].questions) {
                                  newInitial[`eligible_${index}`] = false
                                  newState[index] = false
                                  index++
                                }
                                setState(newState)
                                setInitialValues(newInitial)
                              }}
                            />
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justify='center'
                >
                  <CardMedia
                    title='logo'
                    image={currentSelected.image}
                    style={{
                      width: '100px',
                      height: '100px',
                      margin: 10
                    }}
                  />
                  <Typography gutterBottom variant='h5' component='h2'>
                    <Trans id={currentSelected.mainTitle} />
                  </Typography>
                </Grid>

                <Typography variant='h6' style={{ margin: 15 }}>
                  <Trans>Project Name:</Trans>
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validateOnMount
                  enableReinitialize
                  initialTouched={{ name: true }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .ensure()
                      .max(120, ({ max }) => (
                        <Trans>Must be at most {max} chars</Trans>
                      ))
                      .required(<Trans>This field is required</Trans>)
                  })}
                >
                  <FormikTextField
                    name='name'
                    value={name}
                    fullWidth
                    variant='outlined'
                    required
                    error={!name}
                    InputLabelProps={{ shrink: true }}
                    onChange={event => setName(event.target.value)}
                    label={<Trans>Project Name:</Trans>}
                  />
                </Formik>

                {program && (
                  <div>
                    <Typography variant='h6' style={{ marginTop: 15 }}>
                      <Trans>Are you Eligible for the Project</Trans>
                    </Typography>
                    {cardData[program].questions.map((item, index) => (
                      <FormControlLabel
                        style={{ margin: 5, alignItems: 'flex-start' }}
                        key={index}
                        control={
                          <Checkbox
                            checked={eligible[index]}
                            onChange={event =>
                              setState({
                                ...eligible,
                                [index]: event.target.checked
                              })
                            }
                            name={`eligible_${index}`}
                          />
                        }
                        label={
                          <div style={{ marginTop: 8 }}>
                            {item.label ? (
                              <div>
                                {i18n._(item.label)}
                                {item.sublist}
                              </div>
                            ) : item.component ? (
                              item.component
                            ) : (
                              i18n._(item)
                            )}
                          </div>
                        }
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            {activeStep > 0 && (
              <Button
                onClick={() => {
                  setProgram('')
                  handleBack()
                }}
                color='primary'
                variant='contained'
                disabled={submitting}
              >
                <Trans>Back</Trans>
              </Button>
            )}
            <Button
              onClick={() => createApplication({ eligible, name, program })}
              color='primary'
              variant='contained'
              disabled={submitting || checkEligibility(eligible, program, name)}
            >
              <Trans>Create Application</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </I18n>
  )
}
