import { Trans } from '@lingui/macro'
import { DialogTitle , Button , Grid , DialogContent , Dialog } from '@material-ui/core'




import React, { useEffect } from 'react'

export default function DuplicateOrganizationDialog ({
  open,
  text,
  orgId,
  handleAccept,
  handleDecline
}) {
  const [accepted, setAccepted] = React.useState(false)

  useEffect(() => {
    setAccepted(false)
  }, [open])

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans>Organization with the same name detected!</Trans>
      </DialogTitle>
      <DialogContent>
        {text}
        <Grid
          style={{ marginTop: 15 }}
          container
          direction='row'
          justify='space-evenly'
          justifyContent='space-evenly'
          alignItems='center'
        >
          <Button
            disabled={accepted}
            color='primary'
            variant='contained'
            onClick={() => {
              setAccepted(true)
              handleAccept()
            }}
          >
            <Trans>Yes</Trans>
          </Button>
          <Button
            disabled={accepted}
            color='primary'
            variant='contained'
            onClick={() => {
              handleDecline()
            }}
          >
            <Trans>No</Trans>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
