import { t } from '@lingui/macro'
import { I18n } from '@lingui/react'
import {
  getOpportunities,
  oppToStepper
} from 'app/services/sfAuth/sfData/sfOpportunity'
import Loading from 'egret/components/EgretLoadable/Loading'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PortalForm } from '../forms/PortalForm'

export const TestFormTabs = () => {
  const [data, setData] = React.useState(null)
  const organization = useSelector(state => state.organization)

  useEffect(() => {
    getOpportunities(
      {
        permissions: {
          TEAM_MEMBERS: true
        },
        searchParams: {
          AccountId: organization.id
        }
      },
      true
    ).then(result => {
      const data = result.filter(function (opp) {
        return opp.UserRecordAccess.HasEditAccess
      })
      // data = data.map(item => oppToStepper(item))
      setData(data)
    })
  }, [])

  if (!data) {
    return <Loading />
  }

  return (
    <div>
      <I18n>
        {({ i18n }) => {
          return (
            <PortalForm
              formId='a3K4g0000000nzcEAA'
              dataList={data}
              tableLabel={i18n._(t`Grant applications`)}
              objLabel={i18n._(t`Application`)}
            />
          )
        }}
      </I18n>
    </div>
  )
}
