import {
  formConditions,
  sfFieldToConditionType,
  specialFormConditions
} from './ConditionalElementEditor'

const isDisabled = ({ item, elementsMap, values, connectedMap, errors }) => {
  if (!item.conditions || item.conditions.length === 0) {
    return false
  }
  return item.conditions.some(condition => {
    const { conditionMet, state } = isConditionMet({
      condition,
      elementsMap,
      values,
      connectedMap,
      errors
    })
    return (
      (conditionMet && state === 'hide') || (!conditionMet && state === 'show')
    )
  })
}

export const checkAltLabel = ({
  item,
  elementsMap,
  values,
  connectedMap,
  errors,
  langFR
}) => {
  let label
  item.conditions.some(condition => {
    const { conditionMet, state, altLabel } = isConditionMet({
      condition,
      elementsMap,
      values,
      langFR,
      connectedMap,
      errors
    })
    if (conditionMet && state === 'altLabel') {
      label = altLabel
    } else {
      return false
    }
  })
  return label
}

export const getDisabledIds = ({ sections, errors, ...props }) => {
  const getIdsToDisable = errors => {
    const toRet = []
    sections.forEach(section => {
      const checkConditionsInItem = item => {
        if (item.elements) {
          if (isDisabled({ item, ...props, errors })) {
            toRet.push(item.id)
          }
          item.elements.forEach(item => {
            checkConditionsInItem(item)
          })
        } else if (isDisabled({ item, ...props, errors })) {
          toRet.push(item.id)
        }
      }
      section.elements.forEach(item => {
        checkConditionsInItem(item)
      })
    })
    return toRet
  }

  const preDisabledIds = getIdsToDisable(errors)
  const validErrors = { ...errors }
  preDisabledIds.forEach(id => {
    delete validErrors[id]
  })
  return getIdsToDisable(validErrors)
}

export const isConditionMet = ({
  condition,
  elementsMap,
  values,
  langFR,
  connectedMap = {},
  errors = {}
}) => {
  let bool = false
  let testRule, testValue
  if (condition.conditions) {
    const logic = condition.logic || 'all'
    if (logic === 'any') {
      bool = condition.conditions.some(condition => {
        const { conditionMet } = isConditionMet({
          condition,
          elementsMap,
          values,
          langFR,
          connectedMap
        })
        return conditionMet
      })
    } else {
      bool = !condition.conditions.some(condition => {
        const { conditionMet } = isConditionMet({
          condition,
          elementsMap,
          values,
          langFR,
          connectedMap
        })
        return !conditionMet
      })
    }
  } else {
    const toRet = {
      conditionMet: false,
      state: condition.state
    }
    if (
      condition.conditionTarget &&
      specialFormConditions[condition.conditionTarget]
    ) {
      if (condition.conditionTarget === 'noErrors') {
        toRet.conditionMet = Object.keys(errors).length === 0
      } else if (condition.conditionTarget === 'errorsPresent') {
        toRet.conditionMet = Object.keys(errors).length > 0
      }
      return toRet
    } else if (!condition.condition) {
      return toRet
    }
    if (
      !['isTrue', 'isFalse', 'isEmpty', 'isNotEmpty'].includes(
        condition.condition
      ) &&
      !condition.parameter
    ) {
      return toRet
    }
    if (condition.sfField) {
      const objData = connectedMap[condition.sfObject]
      if (objData) {
        testValue = objData.sfObject[condition.sfField]
        let ruleType =
          sfFieldToConditionType[objData.fieldsMap[condition.sfField]?.type]
        if (condition.sfField === 'RecordTypeId') {
          ruleType = 'picklist'
        }
        if (!ruleType) {
          return toRet
        }
        testRule = formConditions[ruleType].conditions[condition.condition].rule
      }
    } else {
      const targetElement = elementsMap[condition.conditionTarget]
      if (!targetElement) {
        console.warn(
          'Question targeted by this condition does not exist!',
          condition
        )
        return toRet
      } else if (
        !Object.keys(formConditions).includes(targetElement.elementType)
      ) {
        console.warn(
          'Question targeted by this condition has invalid type!',
          condition
        )
        return toRet
      }
      testValue = values[condition.conditionTarget]
      testRule =
        formConditions[targetElement.elementType].conditions[
          condition.condition
        ]?.rule
    }
    if (testRule) {
      bool = testRule(condition.parameter, testValue)
    }
  }

  return {
    conditionMet: bool,
    state: condition.state,
    altLabel: langFR ? condition.altLabelFR : condition.altLabelEN
  }
  // if (condition.state === 'altLabel') {
  //   if (!bool || !checkLabel) {
  //     return false
  //   }
  //   return langFR ? condition.altLabelFR : condition.altLabelEN
  // }
  // return condition.state === 'show' ? !bool : bool
}
