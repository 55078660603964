import { SATsIdsList } from '../../services/sfAuth/sfData/sfSurvey'
import { Trans } from '@lingui/macro'
import React from 'react'
import { ConfigurableSurveys } from './AvaliableSurveys'
import { Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useStyleSatTopCard } from './sat/SurveyTabSatTopCard'
import { useSelector } from 'react-redux'

const styles = theme => ({
  headerCard: {
    background: 'rgba(0, 0, 0, 0.08)',
    maxWidth: 900,
    margin: '20px auto',
    padding: 20,
    borderRadius: 8
  },
  headerPart: {
    marginTop: 20
  },
  headerP: {
    marginTop: 10
  }
})

const useStylesHeaders = makeStyles(styles)

const ESATSurveyCard = () => {
  const classes = useStylesHeaders()
  return (
    <Card className={classes.headerCard}>
      <Typography variant='h5'>
        <Trans>Environmental Self-Assessment Tool Card Title</Trans>
      </Typography>

      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>
            Environmental Self-Assessment Tool Card Title Survey Intro Text
          </Trans>
        </Typography>
        <ul>
          <li>
            <Trans>Environmental initiatives</Trans>
          </li>
          <li>
            <Trans>Utility bill analysis</Trans>
          </li>
          <li>
            <Trans>Tenant or member education and engagement</Trans>
          </li>
          <li>
            <Trans>Maintenance</Trans>
          </li>
          <li>
            <Trans>Eco-responsibility</Trans>
          </li>
        </ul>
        <Typography className={classes.headerP}>
          <Trans>
            Based on your answers, this questionnaire will generate an
            assessment of the state of your organization and establish a basis
            for the conversation with one of our program managersREC or Program
            Managers to suggest possible courses of action to take regarding the
            challenges identified.
          </Trans>
        </Typography>
        <Typography className={classes.headerP}>
          <Trans>
            You will also be able to access your completed questionnaire to use
            it, for example, within your board of directors, with funders or
            others. The Centre is committed to protecting the strict
            confidentiality of the information entered in this questionnaire.
          </Trans>
        </Typography>
      </div>
      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <b>
            <Trans>To complete the assessment:</Trans>
          </b>
        </Typography>
        <Typography className={classes.headerP}>
          <Trans>
            This questionnaire should be completed by a member of the board of
            directors or a senior staff person the manager offrom your housing
            organization or a member of the board of directors, or someone you
            deem appropriate. It should take about 30 minutes to complete.
          </Trans>
        </Typography>
        <Typography className={classes.headerP}>
          <Trans>
            To complete a previously started assessment, click on the file icon
            on the left of the assessment you wish to complete. If you want to
            give someone else access to an assessment locked by you, click on
            the lock icon. If you would like to access an assessment that was
            locked by someone else, please ask them to go to Portico and unlock
            the assessment from their account.
          </Trans>
        </Typography>
      </div>
    </Card>
  )
}

export const SurveyTabESatTopCard = () => {
  const classes = useStyleSatTopCard()
  return (
    <Card className={classes.headerCard}>
      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>
            <b>Instructions:</b> ESAT INSTRUCTIONS This is a self-assessment
            tool. This tool is yours and you alone will decide what to do with
            the results.
          </Trans>
        </Typography>
      </div>

      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>
            <b>
              ESAT Please consider the situation of your housing organization
              over the past five years—before the crisis caused by the COVID-19
              pandemic that started in 2020—when answering the questions.
            </b>
          </Trans>
        </Typography>
      </div>

      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>
            ESAT Should you need to complete the assessment at a later moment,
            don’t forget to click “save” at the bottom of the questionnaire
            before leaving the page. To return to the questionnaire later, click
            on the pencil icon in the self-assessment tool menu. When you’re
            done, hit “submit” at the end of the questionnaire to view your
            results and action plan.
          </Trans>
        </Typography>
      </div>
      <div className={classes.headerPart}>
        <Typography className={classes.headerP}>
          <Trans>Thank you for your participation!</Trans>
        </Typography>
      </div>
    </Card>
  )
}

export default props => {
  const configurations = useSelector(state => state.configuration)
  return (
    <ConfigurableSurveys
      satIds={[
        configurations.ESAT_SURVEY || 'a2ZAm000000038yMAA' // ESAT
      ]}
      texts={{
        creationSnackbarSuccess: <Trans>Created Environmental S.A.T.</Trans>,
        creationSnackbarError: (
          <Trans>Error creating Environmental S.A.T.</Trans>
        ),
        tableTitle: <Trans>Environmental S.A.T. Completed</Trans>,
        surveyIntro: <ESATSurveyCard />
      }}
      surveyCardData={[
        {
          title: <Trans>Environmental S.A.T.</Trans>,
          value: 'Environmental S.A.T.',
          image: '/assets/images/esat-icon.png',
          disabled: ({ isCoOp, isNonProfit }) => false,
          disabledText: <Trans />
        }
      ]}
      {...props}
    />
  )
}
