import { Trans } from '@lingui/macro'
import { IconButton, Icon, Button, Grid, Typography } from '@material-ui/core'

import {
  deleteCatalogs,
  fetchWorkgroups,
  saveWorkgroupCatalogs,
  updateWorkgroup
} from 'app/services/sfAuth/sfData/sfWorkgroups'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik } from 'formik'
import { FormikCheckboxField, FormikTextField } from 'formik-material-fields'
import { useSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { countHelperText } from '../page-layouts/FormElement'

const EditCommittee = ({ match }) => {
  const { params } = match
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [sfWorkgroup, setSfWorkgroup] = useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()
  const user = useSelector(state => state.user)

  const fetchData = () => {
    return fetchWorkgroups([params.id]).then(result => {
      const output = result[0].outputValues
      setSfWorkgroup({
        id: params.id,
        nameEN: output.workgroup.Name,
        nameFR: output.workgroup.Workgroup_French_Name__c,
        catalogs: output.catalogs
          ? output.catalogs.map(obj => ({
              id: obj.Id,
              textFR: obj.Label_French__c,
              textEN: obj.Label_English__c,
              allowUpload: obj.Allow_Upload__c
            }))
          : []
      })
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Formik enableReinitialize initialValues={sfWorkgroup}>
      {({ values, setFieldValue, initialValues }) => {
        const { catalogs, nameEN, nameFR } = values
        const name = user.language === 'en_US' ? nameEN : nameFR
        let catalogNum = 0

        const handleSave = () => {
          setSaving(true)
          const workgroupSave = {
            Id: sfWorkgroup.id,
            Workgroup_French_Name__c: nameFR,
            Name: nameEN
          }
          const snackbar = enqueueSnackbar(<Trans>Saving {name}</Trans>, {
            variant: 'info',
            persist: true
          })
          const promises = []
          const toSave = catalogs
            .filter(obj => !obj.deleted)
            .map(catalog => ({
              Id: catalog.id,
              Label_English__c: catalog.textEN,
              Label_French__c: catalog.textFR,
              Allow_Upload__c: catalog.allowUpload,
              Workgroup__c: sfWorkgroup.id
            }))
          if (toSave.length > 0) {
            promises.push(saveWorkgroupCatalogs(toSave))
          }
          const toDelete = catalogs
            .filter(obj => obj.deleted && obj.id)
            .map(catalog => ({
              Id: catalog.id
            }))
          if (toDelete.length > 0) {
            promises.push(deleteCatalogs(toDelete))
          }
          Promise.all([updateWorkgroup(workgroupSave), ...promises]).then(
            result => {
              fetchData().then(result => {
                setSaving(false)
                closeSnackbar(snackbar)
                enqueueSnackbar(<Trans>Saved {name}</Trans>, {
                  variant: 'success'
                })
              })
            },
            reject => {
              setSaving(false)
              closeSnackbar(snackbar)
              enqueueSnackbar(
                <Trans>Error ocurred while saving {name}!</Trans>,
                {
                  variant: 'error'
                }
              )
            }
          )
        }

        return (
          <div style={{ padding: 20 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={e => {
                history.push(`/grants/Committees/${sfWorkgroup.id}`)
              }}
            >
              <Icon style={{ marginRight: 5 }}>arrow_back</Icon>
              <Trans>Go back to committee list</Trans>
            </Button>
            <Typography variant='h4' style={{ textAlign: 'center' }}>
              <Trans>Edit</Trans>{' '}
              {user.language === 'en_US'
                ? sfWorkgroup.nameEN
                : sfWorkgroup.nameFR}
            </Typography>
            <div style={{ padding: 10 }}>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                style={{ marginTop: 15, marginBottom: 15 }}
                disabled={saving}
                onClick={e => {
                  handleSave()
                }}
              >
                <Icon style={{ marginRight: 5 }}>save</Icon>
                <Trans>Save</Trans>
              </Button>
              <Grid container>
                <Grid item xs>
                  <FormikTextField
                    label={<Trans>Name - english</Trans>}
                    name='nameEN'
                    fullWidth
                    variant='outlined'
                    inputProps={{
                      maxLength: 80
                    }}
                    helperText={countHelperText(nameEN, 80)}
                  />
                </Grid>
                <Grid item xs>
                  <FormikTextField
                    label={<Trans>Name - french</Trans>}
                    name='nameFR'
                    fullWidth
                    variant='outlined'
                    inputProps={{
                      maxLength: 80
                    }}
                    helperText={countHelperText(nameFR, 80)}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems='center'>
                <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                  <Trans>Catalogs</Trans>
                </Typography>
                <Button
                  color='primary'
                  variant='contained'
                  style={{ marginLeft: 10 }}
                  onClick={e => {
                    const toSet = [...catalogs]
                    toSet.push({
                      textFR: '',
                      textEN: '',
                      allowUpload: true
                    })
                    setFieldValue('catalogs', toSet)
                  }}
                >
                  <Icon>add</Icon>
                </Button>
              </Grid>
              {catalogs.map((catalog, index) => {
                if (catalog.deleted) {
                  return null
                } else {
                  catalogNum++
                }
                return (
                  <div key={index}>
                    <Grid container justify='space-between' alignItems='center'>
                      <Typography>{catalogNum}</Typography>
                      <IconButton
                        onClick={e => {
                          const toSet = [...catalogs]
                          toSet[index].deleted = true
                          setFieldValue('catalogs', toSet)
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                    <Grid container>
                      <Grid item xs>
                        <FormikTextField
                          label={<Trans>Label - french</Trans>}
                          name={`catalogs.${index}.textFR`}
                          fullWidth
                          variant='outlined'
                          inputProps={{
                            maxLength: 255
                          }}
                          helperText={countHelperText(catalog.textFR, 255)}
                        />
                      </Grid>
                      <Grid item xs>
                        <FormikTextField
                          label={<Trans>Label - english</Trans>}
                          name={`catalogs.${index}.textEN`}
                          fullWidth
                          variant='outlined'
                          inputProps={{
                            maxLength: 255
                          }}
                          helperText={countHelperText(catalog.textEN, 255)}
                        />
                      </Grid>
                    </Grid>
                    <FormikCheckboxField
                      name={`catalogs.${index}.allowUpload`}
                      style={{ marginLeft: 10 }}
                      FormControlLabelProps={{ labelPlacement: 'end' }}
                      controlLabel={<Trans>Allow upload</Trans>}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditCommittee
