import { grpc } from '@improbable-eng/grpc-web'
import _ from 'lodash'
import { Multiuser } from './proto/generated/Multiuser_pb_service'
import {
  ChatMessageSend,
  FieldCommentID,
  CommitFieldComment,
  FieldCommentToSend,
  FormAndFieldAndLockID,
  LockOperationMap,
  FormIDString
} from './proto/generated/Multiuser_pb'
import { host } from './grpcMultiuserEdit'

export const grpcGetFieldComment = ({
  formId,
  userId,
  fieldId,
  onFail,
  onSuccess
}) => {
  const request = new FieldCommentID()
  request.setFormid(formId)
  request.setFieldid(fieldId)
  grpc.unary(Multiuser.GetFieldComment, {
    request,
    host: host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const grpcUnlockFieldComment = ({
  comment,
  formId,
  userId,
  fieldId,
  onFail,
  onSuccess
}) => {
  const request = new FieldCommentToSend()
  request.setFormid(formId)
  request.setFieldid(fieldId)
  request.setContent(comment)
  grpc.unary(Multiuser.CommitFieldComment, {
    request,
    host: host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail(response)
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const grpcLockFieldComment = ({
  comment,
  formId,
  userId,
  fieldId,
  onFail,
  onSuccess
}) => {
  const request = new FieldCommentID()
  request.setFieldid(fieldId)
  request.setFormid(formId)
  grpc.unary(Multiuser.WhoIsLockingFieldComment, {
    request,
    host: host,
    metadata: new grpc.Metadata({
      UserID: userId
    }),
    onEnd: response => {
      console.log('is field comment locked response', response, request)
      const isLocked = Boolean(response.message.array[0])
      if (isLocked) {
        console.log('field comment is already locked')
      } else {
        const request = new FieldCommentToSend()
        request.setFormid(formId)
        request.setFieldid(fieldId)
        request.setContent(comment)
        grpc.unary(Multiuser.LockFieldComment, {
          request,
          host: host,
          metadata: new grpc.Metadata({
            UserID: userId
          }),
          onEnd: response => {
            if (response.status !== grpc.Code.OK) {
              if (onFail) {
                onFail(response)
              }
            } else {
              if (onSuccess) {
                onSuccess(response.message)
              }
            }
          }
        })
      }
    }
  })
}

export const grpcListenForFieldCommentChangedEvent = ({
  id,
  onEventRecieved
}) => {
  const request = new FormIDString()
  request.setFormid(id)
  return grpc.invoke(Multiuser.UserChangeFieldComment, {
    request,
    host,
    onMessage: response => {
      if (onEventRecieved) {
        onEventRecieved({
          fieldId: response.array[1],
          content: response.array[2],
          operation: response.array[3] || 0
        })
      }
    }
  })
}
