/**
 * Created by Michał Stawski on 01.11.2020.
 */

import { useField, useFormikContext } from 'formik'
import CKEditor from 'ckeditor4-react'
import React, { useEffect, useRef, useState } from 'react'
import { getCurrentWords } from '../forms/components/FormTextField'
import { MuEditedByLabel } from '../forms/components/Common'
import {
  endEditingField,
  startEditingField,
  unlockFieldWithoutChanges
} from '../forms/multiuser/grpcMultiuserEdit'
import { useSelector } from 'react-redux'
const h2p = require('html2plaintext')

export const FormikCKEditor = ({
  disabled,
  name,
  label,
  config,
  onBlur,
  wordLimit,
  characterLimit,
  useMultiuser,
  formId,
  ...restOfProps
}) => {
  const user = useSelector(state => state.user)
  const [startEditingValue, setStartEditingValue] = useState(null)
  const { values } = useFormikContext()
  const [field, meta, helpers] = useField(name)
  const [isEditedByOther, setIsEditedByOther] = useState(false)
  const { value = '' } = meta
  const { setValue } = helpers
  const ref = useRef()

  const { muInfo = {}, muUsers = {} } = values
  const muState = muInfo[name]
  let userColor, userName, isEdited
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      isEdited = true
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }
  const editedByOther = Boolean(
    muState && muState.locked && muState.user !== user.userId
  )

  useEffect(() => {
    if (editedByOther) {
      setIsEditedByOther(true)
    }
  }, [editedByOther])

  useEffect(() => {
    const { editor } = ref.current
    if (editor && value !== editor.getData() && isEditedByOther) {
      editor.setData(value)
      setIsEditedByOther(false)
    }
  }, [value])

  return (
    <div>
      <CKEditor
        ref={ref}
        config={config}
        readOnly={disabled || editedByOther}
        oninstanceReady={evt => {
          if (value && !evt.editor.getData()) {
            evt.editor.setData(value)
          }
        }}
        onChange={evt => {
          const newValue = evt.editor.getData()
          const stringValue = newValue
            .replaceAll('&nbsp;', ' ')
            .replace(/<[^>]+>/g, '')
            .replace(/\s\s+/g, ' ')
            .replaceAll('\n', '')
          const wordCount = getCurrentWords(stringValue)
          const charCount = stringValue.length
          let error
          if (characterLimit && characterLimit < charCount) {
            error = true
          }
          if (wordLimit && wordCount > wordLimit) {
            error = true
          }
          // This check is necessary not to set initial value second time making form dirty on load
          const parsedValue = value
            .replaceAll('&nbsp;', ' ')
            .replace(/<[^>]+>/g, '')
            .replaceAll('\n', '') //h2p(value)
          const parsedNew = newValue
            .replaceAll('&nbsp;', ' ')
            .replace(/<[^>]+>/g, '')
            .replaceAll('\n', '') //h2p(newValue)
          if (parsedValue !== parsedNew) {
            setValue(newValue, false)
          }
          // if (error) {
          //   console.log('ERROR', error, value)
          //   evt.editor.setData('value')
          // }
        }}
        onBlur={e => {
          if (onBlur) {
            onBlur(e)
          }
          if (useMultiuser) {
            const currentValue = e.editor.getData()
            if (startEditingValue === currentValue) {
              unlockFieldWithoutChanges({
                lockId: values.muInfo.lockId,
                fieldId: name,
                formId,
                userId: user.userId
              })
            } else {
              endEditingField({
                userId: user.userId,
                formId,
                fieldId: name,
                fieldValue: currentValue,
                lockId: values.muInfo.lockId
              })
            }
          }
          setStartEditingValue(null)
        }}
        onFocus={e => {
          console.log('ckedit on focus.', e)
          setStartEditingValue(e.editor.getData())
          if (useMultiuser) {
            startEditingField({
              userId: user.userId,
              formId,
              fieldId: name
            })
          }
        }}
      />
      {useMultiuser && (
        <MuEditedByLabel color={userColor} userName={userName} />
      )}
    </div>
  )
}
