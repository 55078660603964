import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Field, Formik, setNestedObjectValues, useFormikContext } from 'formik'
import {
  Step,
  StepLabel,
  Stepper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Icon,
  Paper,
  Grid,
  DialogContent,
  IconButton,
  Typography,
  ButtonGroup,
  MenuItem,
  Collapse,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragPlaceholder from './DragPlaceholder'
import GroupCard from './GroupCard'
import { Trans } from '@lingui/macro'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { GroupElement } from './GroupElement'
import {
  compressFormData,
  fetchFormPage,
  FORM_VERSION_ERROR,
  saveFormPage
} from 'app/services/sfAuth/sfData/sfForms'
import Loading from 'egret/components/EgretLoadable/Loading'
import { useSnackbar } from 'notistack'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialValues } from './Form'
import { describe } from 'app/services/sfAuth/sfData/sfSurvey'
import {
  getRecordTypes,
  saveOpportunity
} from 'app/services/sfAuth/sfData/sfOpportunity'
import { getNetwork, saveUser } from 'app/services/sfAuth/sfData/sfUser'
import SFAuthService from '../../services/sfAuth/SFAuthService'
import { setUserData } from 'app/redux/actions/UserActions'
import sfOauthConfig from '../../services/sfAuth/sfAuthConfig'
import RedirectWarning from '../page-layouts/RedirectWarning'
import { fetchAccountJoinOpportunityList } from 'app/services/sfAuth/sfData/sfAccountAndOpportunityJoin'
import {
  checkIfOrganizationsAreStale,
  fetchAccountAffiliations,
  getAccountByName,
  getAccountTeamMembers,
  mapAccountToRedux,
  saveAccount
} from 'app/services/sfAuth/sfData/sfAccount'
import { saveContact } from 'app/services/sfAuth/sfData/sfContact'
import {
  setAvaliableOrganizations,
  setOrganization
} from 'app/redux/actions/OrganizationActions'
import crypto from 'crypto'
import FormikCheckboxField from 'formik-material-fields/lib/FormikCheckboxField'
import {
  getDocumentsByEntity,
  parseDocument
} from 'app/services/sfAuth/sfData/sfFiles'
import { cloneDeep, isEqual } from 'lodash'
import { fetchOpportunityAffiliations } from 'app/services/sfAuth/sfData/sfOpportunityAffiliation'
import { accountRoles } from '../grants/UserOrganizations'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getPaymentsForOpportunity } from 'app/services/sfAuth/sfData/sfPayments'
import { getReportsForOpportunity } from 'app/services/sfAuth/sfData/sfReports'
import {
  FormikCheckboxGroupField,
  FormikRadioGroupField
} from 'formik-material-fields'
import { NumberFormatDefault } from './components/Common'
import InjectablesElementsPanel from './InjectablesElementsPanel'
import {
  getFormBackup,
  getFormBackups,
  uploadFormBackup
} from './multiuser/grpcForms'
import moment from 'moment/moment'

export const draggableItemTypes = {
  GROUP_CARD: 'GROUP_CARD',
  GROUP_ELEMENT: 'GROUP_ELEMENT',
  ITEM_ELEMENT: 'ITEM_ELEMENT'
}

const formObjectsToConnectBase = {
  Opportunity: {
    recordTypes: true,
    saveFunction: (values, reduxBag) => {
      values.Last_Modified_By_User_Date__c = moment.utc()
      values.Last_Modified_By__c = reduxBag.user.userId
      return saveOpportunity(values)
    },
    include: ['ProcessInstances'],
    selectInfo: {
      StageName: 'string',
      'Campaign.Name': 'string',
      'Campaign.StartDate': 'date',
      'Campaign.EndDate': 'date',
      'Account.Name': 'string',
      'RecordType.Name': 'string',
      'RecordType.Id': 'string',
      'UserRecordAccess.HasEditAccess': 'boolean',
      'Account.Type': 'string'
    },
    select: [
      'StageName',
      'Campaign.Name',
      'Campaign.StartDate',
      'Campaign.EndDate',
      'Account.Name',
      'RecordType.Name',
      'RecordType.Id',
      'Account.Type',
      'UserRecordAccess.HasEditAccess'
    ],
    additionalObjects: [
      {
        label: 'Opportunity Affiliations',
        key: 'opportunityAffiliatedContacts',
        sfObject: 'Opportunity_Affiliation__c',
        query: id => fetchOpportunityAffiliations({ opportunity: id }),
        collection: true,
        additionalObjects: [{ field: 'Contact__c', sfObject: 'Contact' }]
      },
      {
        label: 'Milestones',
        key: 'milestones',
        include: 'FGM_Base__Benchmarks__r',
        sfObject: 'FGM_Base__Benchmark__c',
        disabled: sfOauthConfig.isIcce,
        collection: true
      },
      {
        label: 'Objectives',
        key: 'objectives',
        include: 'Objectives__r',
        sfObject: 'Objective__c',
        disabled: sfOauthConfig.isIcce,
        collection: true
      },
      {
        label: 'Payments',
        key: 'payments',
        query: id => getPaymentsForOpportunity(id),
        sfObject: 'FGM_Base__Payment__c',
        collection: true
      },
      {
        label: 'Grantee Reports',
        key: 'reports',
        query: id => getReportsForOpportunity(id),
        sfObject: 'FGM_Base__Grantee_Report__c',
        collection: true
      },
      {
        label: 'External Reviews',
        key: 'externalReviews',
        disabled: sfOauthConfig.isIcce,
        include: 'FGM_Base__Reviews__r',
        sfObject: 'FGM_Base__Review__c',
        collection: true
      },
      {
        label: 'Budget Lines',
        key: 'budgetLines',
        include: 'Budget_Lines__r',
        sfObject: 'Budget_Line__c',
        collection: true
      }
    ],
    additionalInfoAfterFetch: obj => {
      const toRet = {}
      if (obj.FGM_Base__Reviews__r) {
        toRet.externalReviews = obj.FGM_Base__Reviews__r.records
      }
      if (obj.FGM_Base__Benchmarks__r) {
        toRet.milestones = obj.FGM_Base__Benchmarks__r.records
      }
      if (obj.Objectives__r) {
        toRet.objectives = obj.Objectives__r.records
      }
      return toRet
    },
    additionalInfo: (id, collectionKeys = []) => {
      if (sfOauthConfig.isIcce) {
        return Promise.all([
          getRecordTypes('Budget_Line__c'),
          fetchAccountJoinOpportunityList({ opportunity: id }),
          getPaymentsForOpportunity(id),
          getReportsForOpportunity(id)
        ]).then(([budgetLines, joinsResult, payments, reports]) => {
          return {
            budgetLinesRecordTypes: budgetLines,
            accountJoinOpportunityList:
              joinsResult[0].outputValues.result || [],
            payments,
            reports
            // opportunityAffiliatedContacts
          }
        })
      } else {
        const promises = []
        formObjectsToConnectBase.Opportunity.additionalObjects.forEach(obj => {
          if (obj.collection && obj.query && collectionKeys.includes(obj.key)) {
            promises.push(
              obj.query(id).then(result => ({
                [obj.key]: result
              }))
            )
          }
        })
        return Promise.all([
          getRecordTypes('Objective__c', true),
          ...promises
        ]).then(([recordTypes, ...rest]) => {
          let toRet = {
            recordTypes
          }
          rest.forEach(obj => {
            toRet = { ...toRet, ...obj }
          })
          return toRet
        })
      }
    }
  },
  User: {
    select: ['Contact.*'],
    additionalObjects: [{ field: 'Contact', sfObject: 'Contact' }],
    saveFunction: (values, { dispatch, user }) => {
      return saveUser(values).then(result => {
        return SFAuthService.getUserInfo(user, {}).then(({ user, state }) => {
          dispatch(setUserData(user))
          return result
        })
      })
    }
  },
  Account: {
    select: ['UserRecordAccess.HasEditAccess'],
    selectInfo: {
      'UserRecordAccess.HasEditAccess': 'boolean'
    },
    additionalObjects: [
      {
        label: 'Account Affiliations',
        key: 'accountAffiliations',
        sfObject: 'npe5__Affiliation__c',
        query: id => fetchAccountAffiliations({ accountId: id }),
        disabled: sfOauthConfig.isIcce,
        collection: true
      }
    ],
    additionalInfo: (id, collectionKeys = []) => {
      const promises = []
      formObjectsToConnectBase.Account.additionalObjects.forEach(obj => {
        if (obj.collection && obj.query && collectionKeys.includes(obj.key)) {
          promises.push(
            obj.query(id).then(result => ({
              [obj.key]: result
            }))
          )
        }
      })
      return Promise.all([getAccountTeamMembers(id), ...promises]).then(
        ([members, ...rest]) => {
          let toRet = {
            accountMembers: members || []
          }
          rest.forEach(obj => {
            toRet = { ...toRet, ...obj }
          })
          return toRet
        }
      )
    },
    saveFunction: (
      values,
      { dispatch, user, organization, avaliableOrganizations },
      { enqueueSnackbar }
    ) => {
      const mappedFields = {
        ...mapAccountToRedux(values, true)
      }
      const checkPromise = mappedFields.organisationsName
        ? getAccountByName(mappedFields.organisationsName)
        : Promise.resolve([])
      return checkPromise.then(results => {
        results = results.filter(acc => acc !== values.Id)
        if (results.length !== 0) {
          return Promise.reject(
            new Error('Organization with this name already exists!')
          )
        } else {
          return Promise.all([
            saveAccount(values),
            checkIfOrganizationsAreStale(user.userId, values.Id) // dirty ? values.id : null)
          ])
            .then(([result, staleOrganizations]) => {
              console.log('saved', result)
              var newUser = { ...user }
              newUser.staleOrganizations = staleOrganizations
              if (!isEqual(newUser, user)) {
                dispatch(setUserData(newUser))
              }
              if (
                organization.id === values.Id &&
                !isEqual(mappedFields, organization)
              ) {
                dispatch(setOrganization(mappedFields))
              }
              const newOrganizations = [...avaliableOrganizations]
              newOrganizations.forEach(org => {
                if (org.id === values.Id && values.Name) {
                  org.name = values.Name
                }
              })
              if (!isEqual(avaliableOrganizations, newOrganizations)) {
                dispatch(setAvaliableOrganizations(newOrganizations))
              }
              return result
            })
            .catch(err => {
              return Promise.reject(new Error(err))
            })
        }
      })
    }
  },
  Opportunity_Affiliation__c: {
    nonSelectable: true
  },
  TechnicalAdvisoryAssignment__c: {
    disabled: () => {
      console.log('formObjects disbaled', sfOauthConfig.isIcce)
      return !sfOauthConfig.isIcce
    }
  },
  Contact: {
    saveFunction: values => {
      return saveContact(values)
    }
  },
  Resource__c: {
    disabled: () => sfOauthConfig.isIcce
  },
  FGM_Base__Review__c: {
    disabled: () => sfOauthConfig.isIcce
  },
  External_Reviewer__c: {
    disabled: () => sfOauthConfig.isIcce
  }
}

export const formObjectsToConnect = Object.fromEntries(
  Object.entries(formObjectsToConnectBase).filter(
    ([key, item]) => !(item.disabled && item.disabled())
  )
)

console.log('formObjects formObjectsToConnect', formObjectsToConnect)

const parseFields = ({
  fields,
  prefix = '',
  subObject,
  includeReadonly,
  languageUsed
}) =>
  fields
    .filter(field => {
      if (includeReadonly) {
        return validFieldTypes.includes(field.type)
      } else {
        return field.updateable && validFieldTypes.includes(field.type)
      }
    })
    .map(field => {
      const readOnly = !field.updateable
      let label = prefix + field.label + ' (' + field.name + ')'
      if (readOnly) {
        label += ' [' + myI18n._('Read only') + ']'
      }
      return {
        label,
        required: !field.nillable,
        readOnly,
        picklistValues: field.picklistValues
          ? field.picklistValues.map(option => ({
              ...option,
              labelLang: languageUsed
            }))
          : [],
        name: prefix + field.name,
        referenceTo: field.referenceTo || [],
        type: field.type,
        subObject
      }
    })

const validFieldTypes = [
  'string',
  'textarea',
  'picklist',
  'multipicklist',
  'currency',
  'percent',
  'double',
  'date',
  'datetime',
  'address',
  'email',
  'url',
  'int',
  'reference',
  'phone',
  'location',
  'boolean'
]
export const validFieldTypesForTextFieldNumeric = [
  'double',
  'currency',
  'int',
  'phone'
]
export const validFieldTypesForTextField = [
  'string',
  'address',
  'textarea',
  'email',
  'url'
]
export const validFieldTypesForPicklist = [
  'picklist',
  'multipicklist',
  'string'
]

export const getDeepFieldName = (fieldName, depth) => {
  let name = `sections.${depth[0]}`
  depth.forEach((d, dIndex) => {
    if (dIndex > 0) {
      name += `.elements.${d}`
    }
  })
  return name + '.' + fieldName
}

const checkForMissingIds = data => {
  data.sections.forEach(section => {
    section.elements.forEach(element => {
      assignId(element)
    })
  })
}

const assignId = item => {
  if (item.elements) {
    item.elements.forEach(element => {
      assignId(element)
    })
  } else if (!item.id) {
    const crypto = require('crypto')
    const id = crypto.randomBytes(16).toString('hex')
    item.id = id
  }
}

const pdfBaseProps = {
  textEN: '',
  textFR: '',
  fontSize: '10',
  textProps: [],
  placement: 'center'
}

const FormWizard = ({ ...props }) => {
  const [data, setData] = React.useState(null)
  const [backups, setBackups] = React.useState(null)
  const [network, setNetwork] = React.useState(null)
  const [connectedObjectMap, setConnectedObjectsMap] = React.useState([])
  const { enqueueSnackbar } = useSnackbar()
  const languageUsed = useSelector(state => state.user.language)
  const id = props.match.params.id
  const tree = useSelector(state => state.formEditorTree)
  const dispatch = useDispatch()
  const formikRef = useRef()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    const toDescribe = []
    const recordTypesDecribe = []
    Object.keys(formObjectsToConnect).forEach(key => {
      if (!toDescribe.includes(key)) {
        toDescribe.push(key)
      }
      if (formObjectsToConnect[key].recordTypes) {
        recordTypesDecribe.push(key)
      }
      if (formObjectsToConnect[key].additionalObjects) {
        formObjectsToConnect[key].additionalObjects.forEach(obj => {
          if (!obj.disabled) {
            if (!toDescribe.includes(obj.sfObject)) {
              toDescribe.push(obj.sfObject)
            }
          }
        })
      }
    })
    Promise.all([
      fetchFormPage(id),
      getNetwork(),
      getDocumentsByEntity(id),
      Promise.all(
        recordTypesDecribe.map(key =>
          getRecordTypes(key).then(recordTypes => ({
            recordTypes,
            object: key
          }))
        )
      ),
      ...toDescribe.map(key => describe(key))
    ]).then(([result, network, documents, recordTypes, ...describeResults]) => {
      const files = documents.map(doc => parseDocument(doc))
      const filesMap = {}
      files.forEach(file => {
        filesMap[file.tags] = file
      })

      const objectToRecordTypes = {}
      recordTypes.forEach(obj => {
        objectToRecordTypes[obj.object] = obj.recordTypes
      })
      setNetwork(network)
      console.log('describe', describeResults, objectToRecordTypes)
      if (!result.sections) {
        result.sections = []
      }
      if (!result.version) {
        result.version = 1
      }
      checkForMissingIds(result)

      let convertId
      if (result.objectConnected && !result.objectsConnected) {
        convertId = crypto.randomBytes(12).toString('hex')
      }
      const resetItem = item => {
        if (item.padding || item.padding === 0) {
          const useBase = typeof item.padding !== 'object'
          if (useBase) {
            item.padding = {
              paddingLeft: String(item.padding),
              paddingRight: String(item.padding),
              paddingTop: String(item.padding),
              paddingBottom: String(item.padding)
            }
          }
        }
        if (item.editMode) {
          delete item.editMode
        }
        if (item.selected) {
          delete item.selected
        }
        if (item.typeProps) {
          if (filesMap[item.id]) {
            item.typeProps.file = filesMap[item.id]
          }
          // Convert old config to new functionality
          delete item.typeProps.longitude
          delete item.typeProps.latitude
          delete item.typeProps.street
          delete item.typeProps.city
          delete item.typeProps.zipCode
          if (convertId) {
            item.typeProps.isConnected = true
            item.typeProps.connectedTo = [
              {
                connectedObject: convertId
              }
            ]
          } else if (item.typeProps.connectedObject) {
            item.typeProps.isConnected = true
            item.typeProps.connectedTo = [
              {
                connectedObject: item.typeProps.connectedObject,
                connectedField: item.typeProps.connectedField
              }
            ]
            delete item.typeProps.connectedObject
            delete item.typeProps.connectedField
          }
        }
        if (item.elements) {
          item.elements.forEach(element => resetItem(element))
        }
      }

      if (result.injectableComponents) {
        result.injectableComponents.forEach(component => {
          resetItem(component)
        })
      }

      result.sections.forEach(section => {
        delete section.padding
        resetItem(section)
      })
      if (result.objectConnected && !result.objectsConnected) {
        result.objectsConnected = [
          {
            type: result.objectConnected,
            name: result.objectConnected,
            identId: convertId
          }
        ]
        delete result.objectConnected
      }

      if (!result.pdfProps) {
        result.pdfProps = {
          footer: { ...pdfBaseProps },
          header: { ...pdfBaseProps }
        }
      }

      dispatch({
        type: 'SET_SECTIONS',
        sections: result.sections || [],
        injectableComponents: result.injectableComponents || [],
        selectedElements: [],
        elementsInStack: []
      })

      const describeMap = {}
      describeResults.forEach(obj => {
        describeMap[obj.name] = obj
      })

      setConnectedObjectsMap(
        describeResults
          // .filter(obj => formObjectsToConnect[obj.name])
          .map(obj => {
            const fields = parseFields({
              fields: obj.fields,
              languageUsed,
              includeReadonly: true
            })
            const relatedCollections = []
            const isSelectable = Boolean(formObjectsToConnect[obj.name])
            const subObjects =
              isSelectable && formObjectsToConnect[obj.name]?.additionalObjects
            if (subObjects) {
              subObjects.forEach(subObj => {
                const {
                  sfObject,
                  field,
                  collection,
                  label,
                  additionalObjects,
                  key
                } = subObj

                if (describeMap[sfObject]) {
                  if (collection) {
                    const fields = parseFields({
                      fields: describeMap[sfObject].fields,
                      languageUsed,
                      includeReadonly: true
                    })
                    if (additionalObjects) {
                      additionalObjects.forEach(obj => {
                        fields.push(
                          ...parseFields({
                            fields: describeMap[obj.sfObject].fields,
                            languageUsed,
                            includeReadonly: true,
                            prefix: obj.field + '.',
                            subObject: sfObject
                          })
                        )
                      })
                    }
                    relatedCollections.push({
                      label,
                      fields,
                      key,
                      sfObject
                    })
                  } else {
                    fields.push(
                      ...parseFields({
                        fields: describeMap[sfObject].fields,
                        languageUsed,
                        prefix: field + '.',
                        subObject: sfObject,
                        includeReadonly: true
                      })
                    )
                  }
                }
              })
            }
            return {
              name: obj.name,
              label: obj.label,
              relatedCollections,
              recordTypes: objectToRecordTypes[obj.name],
              isSelectable,
              fields: fields.sort((a, b) => a.label.localeCompare(b.label))
            }
          })
      )
      if (Array.isArray(result.restrictAccessForRoles)) {
        result.restrictAccessForRoles = {}
      }
      setData({ id, ...result })
    })
  }

  if (!data) {
    return <Loading />
  }

  console.log('QQQ', connectedObjectMap)

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          objectsConnected: [],
          restrictAccessForRoles: {},
          ...data,
          ...getInitialValues({ data: tree }),
          objects: connectedObjectMap,
          selectedElements: [],
          elementsInStack: []
        }}
        validationSchema={Yup.object().shape({
          version: Yup.string().required(<Trans>This field is required</Trans>)
        })}
      >
        {formikState => (
          <Editor
            data={data}
            {...formikState}
            network={network}
            formId={id}
            backups={backups}
            formikRef={formikRef}
          />
        )}
      </Formik>
    </div>
  )
}

const Editor = ({
  values,
  isValid,
  network,
  formId,
  dirty,
  setValues,
  setFieldValue,
  data,
  backups,
  formikRef,
  resetForm
}) => {
  const [currentStep, setStep] = React.useState(0)
  const [saving, setSaving] = React.useState(false)
  const [copiedSnackbar, setCopiedSnackbar] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const {
    selectedElements,
    // showPrintProps,
    // showPdfProps,
    frenchVersion,
    objects = [],
    objectsConnected,
    readOnly,
    showPdfDownload
  } = values
  const tree = useSelector(state => state.formEditorTree)
  const { sections, elementsInStack, copied, injectableComponents } = tree
  const dispatch = useDispatch()

  const handleAddNewSection = ({ nameEN, nameFR }) => {
    const newSections = [...values.sections]
    newSections.push({
      titleEN: nameEN,
      titleFR: nameFR,
      elements: []
    })
    setFieldValue('sections', newSections)
  }

  useEffect(() => {
    dispatch({
      type: 'RESET_DIRTY'
    })
    const handle = setInterval(() => {
      const formik = formikRef.current
      if (formik) {
        const toSave = compressFormData({
          ...formik.values,
          ...tree,
          origin: data.origin,
          id: data.id
        })
        // uploadFormBackup({
        //   id: data.id,
        //   content: toSave,
        //   onSuccess: result => {
        //     loadBackups()
        //   },
        //   onFail: () => {
        //     enqueueSnackbar(<Trans>Failed to create form backup!</Trans>, {
        //       variant: 'error'
        //     })
        //     loadBackups()
        //   }
        // })
      }
    }, 60000 * 15)
    return () => {
      clearInterval(handle)
      closeSnackbar(copiedSnackbar)
      dispatch({
        type: 'RESET_DIRTY'
      })
    }
  }, [])

  useEffect(() => {
    if (copied) {
      closeSnackbar(copiedSnackbar)
      const key = enqueueSnackbar(<Trans>Data copied</Trans>, {
        variant: 'info',
        persist: true
      })
      setCopiedSnackbar(key)
    } else {
      closeSnackbar(copiedSnackbar)
      setCopiedSnackbar(null)
    }
  }, [copied])

  const handleSave = () => {
    const savingSnackbar = enqueueSnackbar(null, {
      persist: true,
      content: key => ProgressSnackbar(<Trans>Saving form page</Trans>)
    })
    setSaving(true)
    const toSave = {
      ...values,
      ...tree,
      origin: data.origin,
      id: data.id
    }
    // uploadFormBackup({
    //   id: data.id,
    //   content: compressFormData(toSave),
    //   onSuccess: result => {
    //     loadBackups()
    //   },
    //   onFail: () => {
    //     enqueueSnackbar(<Trans>Failed to create form backup!</Trans>, {
    //       variant: 'error'
    //     })
    //     loadBackups()
    //   }
    // })
    saveFormPage(toSave).then(
      result => {
        dispatch({
          type: 'RESET_DIRTY'
        })
        resetForm({ values })
        setSaving(false)
        closeSnackbar(savingSnackbar)
        enqueueSnackbar(<Trans>Form page saved!</Trans>, {
          variant: 'success'
        })
      },
      reject => {
        setSaving(false)
        closeSnackbar(savingSnackbar)
        if (reject === FORM_VERSION_ERROR) {
          enqueueSnackbar(<Trans>This version of form already exists!</Trans>, {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(<Trans>Error ocurred while saving form page</Trans>, {
            variant: 'error'
          })
        }
      }
    )
  }

  const currentSection = sections && sections[currentStep]

  console.log('render', tree, values)
  return (
    <>
      <Paper style={{ padding: 15 }}>
        <RedirectWarning
          open={dirty || Boolean(tree.dirty)}
          handleSave={handleSave}
        />
        <div style={{ padding: 10 }}>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
            wrap='nowrap'
          >
            <Grid item xs>
              <h3>
                <Trans>Form editor</Trans>
              </h3>
            </Grid>
            <BackupsPanel backups={backups} saving={saving} id={formId} />
            <Button
              disabled={saving || !isValid}
              style={{ marginLeft: 10 }}
              variant='contained'
              color='primary'
              onClick={handleSave}
            >
              <Trans>Save</Trans>
              <Icon style={{ marginLeft: 5 }}>save</Icon>
            </Button>
          </Grid>
        </div>
        <Grid container direction='row'>
          <Grid item xs={6} style={{ padding: 10 }}>
            <FormikTextField
              label={<Trans>Form title - English</Trans>}
              name='titleEN'
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <FormikTextField
              label={<Trans>Form title - French</Trans>}
              name='titleFR'
              fullWidth
              variant='outlined'
            />
          </Grid>
        </Grid>
        <div style={{ padding: 10 }}>
          <FormikTextField
            label={<Trans>Version</Trans>}
            name='version'
            fullWidth
            variant='outlined'
            InputProps={{ inputComponent: NumberFormatCustom }}
          />
        </div>
        <div style={{ padding: 10 }}>
          <FormikTextField
            label={<Trans>Comments</Trans>}
            name='comments'
            fullWidth
            variant='outlined'
            multiline
          />
        </div>
        <div style={{ padding: 10 }}>
          <FormikTextField
            label={<Trans>Autosave (minutes)</Trans>}
            name='autosave'
            fullWidth
            variant='outlined'
            InputProps={{ inputComponent: NumberFormatCustom }}
          />
        </div>
        <div style={{ padding: 10 }}>
          <FormikTextField
            label={<Trans>Style</Trans>}
            name='style'
            fullWidth
            variant='outlined'
          />
        </div>
        <div style={{ padding: 10 }}>
          <div>
            <FormikCheckboxField
              name='enableMultiuser'
              style={{ marginLeft: 10 }}
              FormControlLabelProps={{ labelPlacement: 'end' }}
              controlLabel={<Trans>Allow multiuser edit (for testers)?</Trans>}
              onChange={e => {
                if (e) {
                  const toSet = { ...values }
                  toSet.enableMultiuser = true
                  toSet.showPdfDownload = false
                  toSet.readOnly = false
                  toSet.pdfProps = {
                    footer: { ...pdfBaseProps },
                    header: { ...pdfBaseProps }
                  }
                  setValues(toSet)
                }
              }}
            />
          </div>

          <FormikCheckboxField
            name='readOnly'
            style={{ marginLeft: 10 }}
            FormControlLabelProps={{ labelPlacement: 'end' }}
            controlLabel={<Trans>Is form read only?</Trans>}
            onChange={e => {
              if (e) {
                const toSet = { ...values }
                toSet.enableMultiuser = false
                toSet.showPdfDownload = false
                toSet.readOnly = true
                toSet.pdfProps = {
                  footer: { ...pdfBaseProps },
                  header: { ...pdfBaseProps }
                }
                setValues(toSet)
              }
            }}
          />
          <div>
            <FormikCheckboxField
              name='showPdfDownload'
              style={{ marginLeft: 10 }}
              FormControlLabelProps={{ labelPlacement: 'end' }}
              controlLabel={<Trans>Is form a pdf?</Trans>}
              onChange={e => {
                if (e) {
                  setFieldValue('readOnly', false)
                  setFieldValue('enableMultiuser', false)
                } else {
                  setFieldValue('pdfProps', {
                    footer: { ...pdfBaseProps },
                    header: { ...pdfBaseProps }
                  })
                }
              }}
            />
          </div>
          {showPdfDownload && <PdfPropsEdit />}
          <div>
            <FormikCheckboxField
              name='displaySaveFailedDialog'
              style={{ marginLeft: 10 }}
              FormControlLabelProps={{ labelPlacement: 'end' }}
              controlLabel={
                <Trans>Should display warning dialog if saving fails?</Trans>
              }
            />
          </div>
          <div>
            <FormikCheckboxField
              name='displayUnsavedWarning'
              style={{ marginLeft: 10 }}
              FormControlLabelProps={{ labelPlacement: 'end' }}
              controlLabel={
                <Trans>
                  Should display warning if user tries to leave without saving
                  changes?
                </Trans>
              }
            />
          </div>
          <div>
            <FormikCheckboxField
              name='displayesSavedInMeantimeWarning'
              style={{ marginLeft: 10 }}
              FormControlLabelProps={{ labelPlacement: 'end' }}
              controlLabel={
                <Trans>
                  Should display warning if form was saved in meantime?
                </Trans>
              }
            />
          </div>
        </div>

        <div style={{ padding: 10 }}>
          <EditRolesRestrictionButton />
        </div>
        <div style={{ padding: 10 }}>
          <Button
            color='primary'
            variant='contained'
            style={{ marginBottom: 15 }}
            onClick={e => {
              const newValue = [...objectsConnected]
              newValue.push({
                type: '',
                identId: crypto.randomBytes(12).toString('hex')
              })
              setFieldValue('objectsConnected', newValue)
            }}
          >
            <Trans>Add new object to connect</Trans>
          </Button>
          <Grid container direction='column'>
            {objectsConnected.map((obj, index) => {
              return (
                <Grid key={index} container direction='row' wrap=''>
                  <Field name={`objectsConnected.${index}.type`}>
                    {({ field }) => {
                      return (
                        <Grid container direction='row' wrap='nowrap'>
                          <Grid item style={{ padding: 5, width: '100%' }}>
                            <TextField
                              {...field}
                              fullWidth
                              label={<Trans>Object connected</Trans>}
                              select
                              variant='outlined'
                              disabled={objects.length === 0}
                              defaultValue=''
                              onChange={e => {
                                setFieldValue(
                                  `objectsConnected.${index}.type`,
                                  e.target.value
                                )
                                dispatch({
                                  type: 'RESET_OBJECT_CONNECTED',
                                  object: objectsConnected[index].identId
                                })
                              }}
                            >
                              {objects
                                .filter(
                                  item =>
                                    item.isSelectable &&
                                    !formObjectsToConnectBase[item.name]
                                      .nonSelectable
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                          <Grid item style={{ padding: 5, width: '100%' }}>
                            <FormikTextField
                              label={<Trans>Name</Trans>}
                              name={`objectsConnected.${index}.name`}
                              fullWidth
                              variant='outlined'
                            />
                          </Grid>
                          <IconButton
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              const newValue = [...objectsConnected]
                              dispatch({
                                type: 'RESET_OBJECT_CONNECTED',
                                object: objectsConnected[index].identId
                              })
                              newValue.splice(index, 1)
                              setFieldValue('objectsConnected', newValue)
                            }}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Grid>
                      )
                    }}
                  </Field>
                </Grid>
              )
            })}
          </Grid>
        </div>
        <div style={{ padding: 5 }}>
          <Grid container>
            <Grid item xs style={{ padding: 5 }}>
              <AddSectionButton addNewSection={handleAddNewSection} />
            </Grid>
            {copied && copied.section && (
              <Grid item xs style={{ padding: 5 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={e => {
                    dispatch({
                      type: 'ADD_COPIED_SECTION'
                    })
                  }}
                >
                  <Trans>Add copied section</Trans>
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
        <Stepper
          nonLinear
          activeStep={currentStep}
          orientation='horizontal'
          alternativeLabel
        >
          {sections.map((section, index) => {
            return (
              <Step
                itemType='step'
                id='step'
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={e => setStep(index)}
              >
                <StepLabel id='label'>
                  <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'
                  >
                    <img
                      src='/assets/images/english_flag.svg'
                      style={{ width: '20px', margin: 5 }}
                    />
                    {section.titleEN}
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                  >
                    <img
                      src='/assets/images/french_flag.svg'
                      style={{ width: '20px', margin: 5 }}
                    />
                    {section.titleFR}
                  </Grid>
                </StepLabel>
                <Grid
                  container
                  direction='row'
                  justify='center'
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <IconButton
                    disabled={index === 0}
                    onClick={() => {
                      dispatch({
                        sectionIndex: index,
                        sectionMoveDirection: 'up',
                        type: 'MOVE_SECTION'
                      })
                    }}
                  >
                    <Icon>arrow_back</Icon>
                  </IconButton>
                  <EditSectionTitleButton tree={tree} sectionIndex={index} />
                  <IconButton
                    onClick={() => {
                      dispatch({
                        object: { ...cloneDeep(section), section: true },
                        type: 'COPY'
                      })
                    }}
                  >
                    <Icon>content_copy</Icon>
                  </IconButton>
                  <DeleteSectionButton index={index} />
                  <IconButton
                    disabled={index === sections.length - 1}
                    onClick={() => {
                      dispatch({
                        sectionIndex: index,
                        sectionMoveDirection: 'down',
                        type: 'MOVE_SECTION'
                      })
                    }}
                  >
                    <Icon>arrow_forward</Icon>
                  </IconButton>
                </Grid>
              </Step>
            )
          })}
        </Stepper>
        {currentSection && (
          <div style={{ padding: 15 }}>
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='center'
            >
              <Typography>
                <b>
                  <Trans>Displayed language</Trans>
                </b>
              </Typography>
              <ButtonGroup variant='text'>
                <Button
                  onClick={() => {
                    setFieldValue('frenchVersion', true)
                  }}
                >
                  <img
                    src='/assets/images/french_flag.svg'
                    className={values.frenchVersion ? null : 'grayscale'}
                    style={{ width: '50px', margin: 5 }}
                  />
                </Button>
                <Button
                  onClick={() => {
                    setFieldValue('frenchVersion', false)
                  }}
                >
                  <img
                    src='/assets/images/english_flag.svg'
                    className={values.frenchVersion ? 'grayscale' : null}
                    style={{ width: '50px', margin: 5 }}
                  />
                </Button>
              </ButtonGroup>
            </Grid>
            <Typography style={{ marginLeft: 20 }} variant='h3'>
              {frenchVersion ? currentSection.titleFR : currentSection.titleEN}
            </Typography>
          </div>
        )}
        {currentSection && (
          <DndProvider backend={HTML5Backend}>
            <DragPlaceholder
              frenchVersion={frenchVersion}
              stack={elementsInStack}
              selectedElements={selectedElements}
            />
            {currentSection.elements.map((element, index) => {
              return element.elements ? (
                <div key={index}>
                  <GroupCard
                    conditions={element.conditions}
                    french={frenchVersion}
                    networkId={network.Id}
                    formId={formId}
                    dummy={element.dummy}
                    movingInStack={element.movingInStack}
                    item={element}
                    sectionLength={currentSection.elements.length}
                    showPrintProps={Boolean(readOnly)}
                    showPdfProps={Boolean(showPdfDownload)}
                    depth={[currentStep, index]}
                  />
                </div>
              ) : (
                <GroupElement
                  {...element}
                  networkId={network.Id}
                  formId={formId}
                  tree={tree}
                  french={frenchVersion}
                  showPrintProps={Boolean(readOnly)}
                  showPdfProps={Boolean(showPdfDownload)}
                  depth={[currentStep, index]}
                  sectionLength={currentSection.elements.length}
                  index={index}
                />
              )
            })}
          </DndProvider>
        )}
        {currentSection && (
          <>
            <Grid container style={{ marginTop: 15 }}>
              <Grid item xs style={{ padding: 5 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch({
                      type: 'ADD_GROUP',
                      currentStep
                    })
                  }}
                >
                  <Grid container justify='center' alignItems='center'>
                    <Trans>Add new group</Trans>
                    <Icon style={{ marginLeft: 5 }}>add</Icon>
                  </Grid>
                </Button>
              </Grid>
              <Grid item xs style={{ padding: 5 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatch({
                      type: 'ADD_ELEMENT',
                      currentStep
                    })
                  }}
                >
                  <Grid container justify='center' alignItems='center'>
                    <Trans>Add new element</Trans>
                    <Icon style={{ marginLeft: 5 }}>add</Icon>
                  </Grid>
                </Button>
              </Grid>
              {copied && !copied.section && (
                <Grid item xs style={{ padding: 5 }}>
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      dispatch({
                        type: 'ADD_COPIED',
                        object: copied,
                        currentStep
                      })
                    }}
                  >
                    <Grid container justify='center' alignItems='center'>
                      <Trans>Add copied element</Trans>
                      <Icon style={{ marginLeft: 5 }}>add</Icon>
                    </Grid>
                  </Button>
                </Grid>
              )}
            </Grid>
            <div style={{ padding: 10 }}>
              <InjectablesElementsPanel />
            </div>
            <div style={{ marginLeft: 5, marginRight: 5 }}>
              <Button
                disabled={saving || !isValid}
                variant='contained'
                color='primary'
                onClick={handleSave}
                fullWidth
              >
                <Trans>Save</Trans>
                <Icon style={{ marginLeft: 5 }}>save</Icon>
              </Button>
            </div>
          </>
        )}
      </Paper>
      {tree.history.length > 0 && (
        <Button
          variant='contained'
          color='secondary'
          style={{ position: 'fixed', bottom: 20, right: 30 }}
          onClick={e => {
            dispatch({ type: 'REVERT' })
          }}
        >
          <Icon style={{ marginRight: 5 }}>undo</Icon>
          <Trans>Revert</Trans>
        </Button>
      )}
    </>
  )
}

const BackupsPanel = ({ saving, backups, id }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [restoring, setRestoring] = useState(false)
  const dispatch = useDispatch()
  const { values, setValues } = useFormikContext()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      {Array.isArray(backups) && (
        <Dialog open={dialogOpen} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Grid container justifyContent='space-between'>
              <Trans>Form backups</Trans>
              <IconButton
                disabled={restoring || !backups || saving}
                onClick={e => {
                  setDialogOpen(false)
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Grid>
            <div style={{ fontSize: 13, fontWeight: 400 }}>
              <Trans>
                Be careful! Restoring the backup will overrite your current
                changes
              </Trans>
            </div>
          </DialogTitle>
          <DialogContent style={{ minHeight: 600 }}>
            {backups.length === 0 ? (
              <div>
                <Trans>There are no backups yet for this form!</Trans>
              </div>
            ) : (
              backups
                .sort((a, b) => a.revision - b.revision)
                .map((backup, index) => (
                  <Grid
                    container
                    key={index}
                    alignItems='center'
                    style={{ marginTop: 10 }}
                  >
                    <Grid item xs>
                      <div style={{ fontWeight: 400, fontSize: 11 }}>
                        <Trans>Revision</Trans>
                      </div>
                      <div>{Number(backup.revision) + 1}</div>
                    </Grid>
                    <Grid item xs>
                      <div style={{ fontWeight: 400, fontSize: 11 }}>
                        <Trans>Date</Trans>
                      </div>
                      <div>{backup.date}</div>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={restoring}
                        onClick={e => {
                          setRestoring(true)
                          getFormBackup({
                            revision: backup.revision,
                            id,
                            onFail: () => {
                              setRestoring(false)
                              enqueueSnackbar(
                                <Trans>Could not restore this version!</Trans>,
                                { variant: 'error' }
                              )
                            },
                            onSuccess: result => {
                              const toSet = { ...values, ...result }
                              setValues(toSet)
                              setRestoring(false)
                              dispatch({
                                type: 'SET_SECTIONS',
                                sections: result.sections,
                                injectableComponents:
                                  result.injectableComponents,
                                selectedElements: [],
                                elementsInStack: []
                              })
                              setDialogOpen(false)
                              enqueueSnackbar(<Trans>Backup restored!</Trans>, {
                                variant: 'success'
                              })
                            }
                          })
                        }}
                      >
                        <Icon>settings_backup_restore</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                ))
            )}
          </DialogContent>
        </Dialog>
      )}
      {/* <Button
        disabled={!backups}
        variant='contained'
        color='primary'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Trans>Form backups</Trans>
        <Icon style={{ marginLeft: 5 }}>backup</Icon>
      </Button> */}
    </>
  )
}

const PdfPropsEdit = () => {
  const [collapse, setColapse] = useState(false)
  const { setFieldValue, setValues, values } = useFormikContext()
  const { pdfProps } = values

  return (
    <>
      <Grid container alignItems='center'>
        <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
          <Trans>Pdf props</Trans>
        </Typography>
        <IconButton
          onClick={() => {
            setColapse(!collapse)
          }}
        >
          <Icon>{collapse ? 'expand_less' : 'expand_more'}</Icon>
        </IconButton>
      </Grid>
      <Collapse in={collapse}>
        <div style={{ padding: 10 }}>
          <div>
            <FormikTextField
              label={<Trans>Page margin </Trans>}
              name='pdfProps.pagePadding'
              // fullWidth
              variant='outlined'
              InputProps={{
                inputComponent: NumberFormatDefault,
                endAdornment: <InputAdornment position='end'>cm</InputAdornment>
              }}
            />
          </div>
          {[
            {
              label: <Trans>Header</Trans>,
              id: 'header'
            },
            {
              label: <Trans>Footer</Trans>,
              id: 'footer'
            }
          ].map(item => {
            const formikValues = pdfProps[item.id]
            return (
              <>
                <Typography
                  style={{ fontSize: 16, fontWeight: 400, padding: 10 }}
                >
                  {item.label}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(formikValues.isPageNumber)}
                      value={item.key}
                      onChange={e => {
                        const toSet = { ...values }
                        toSet.pdfProps[item.id].textEN = ''
                        toSet.pdfProps[item.id].textFR = ''
                        toSet.pdfProps[item.id].isPageNumber = e.target.checked

                        setValues(toSet)
                      }}
                    />
                  }
                  style={{ marginBottom: 5 }}
                  label={<Trans>Is page number?</Trans>}
                />
                {!formikValues.isPageNumber && (
                  <>
                    <FormikTextField
                      multiline
                      rows={3}
                      label={<Trans>Text - english</Trans>}
                      name={`pdfProps.${item.id}.textEN`}
                      fullWidth
                      variant='outlined'
                    />
                    <FormikTextField
                      style={{ marginTop: 10 }}
                      multiline
                      rows={3}
                      label={<Trans>Text - french</Trans>}
                      name={`pdfProps.${item.id}.textFR`}
                      fullWidth
                      variant='outlined'
                    />
                  </>
                )}
                <FormikTextField
                  style={{ marginTop: 10 }}
                  label={<Trans>Font size</Trans>}
                  name={`pdfProps.${item.id}.fontSize`}
                  inputProps={{
                    maxLength: 2
                  }}
                  InputProps={{
                    inputComponent: NumberFormatDefault
                  }}
                  fullWidth
                  variant='outlined'
                />
                <FormikCheckboxGroupField
                  row
                  multiple
                  name={`pdfProps.${item.id}.textProps`}
                  options={[
                    { value: 'html', label: <Trans>Is html?</Trans> },
                    { value: 'bold', label: <Trans>Bold</Trans> },
                    { value: 'italics', label: <Trans>Italics</Trans> },
                    { value: 'underline', label: <Trans>Underline</Trans> }
                  ].filter(
                    obj => !(obj.value === 'html' && formikValues.isPageNumber)
                  )}
                />
                <div style={{ paddingBottom: 10 }}>
                  <FormikRadioGroupField
                    label={<Trans>Placement</Trans>}
                    row
                    name={`pdfProps.${item.id}.placement`}
                    options={[
                      { value: 'left', label: <Trans>Left</Trans> },
                      { value: 'center', label: <Trans>Center</Trans> },
                      { value: 'right', label: <Trans>Right</Trans> }
                    ]}
                  />
                </div>
              </>
            )
          })}
        </div>
      </Collapse>
    </>
  )
}

const EditRolesRestrictionButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  return (
    <Field name='restrictAccessForRoles'>
      {({ meta, field, form }) => {
        const { value } = field
        const { setFieldValue } = form
        return (
          <>
            <Button
              color='primary'
              variant='contained'
              onClick={e => {
                setDialogOpen(true)
              }}
            >
              <Trans>Edit restrictions for account roles</Trans>
            </Button>
            <Dialog open={dialogOpen} fullWidth maxWidth='sm'>
              <DialogTitle>
                <Grid container justifyContent='space-between'>
                  <Trans>Edit restrictions for account roles</Trans>
                  <IconButton
                    onClick={e => {
                      setDialogOpen(false)
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>
                {Object.values(accountRoles)
                  .filter(role => !role.cantCreate)
                  .map((obj, index) => (
                    <div key={index} style={{ padding: 5 }}>
                      <div style={{ marginBottom: 10, fontSize: 15 }}>
                        {myI18n._(obj.label)}
                      </div>
                      <TextField
                        value={value[obj.apiName] || 'none'}
                        onChange={e => {
                          setFieldValue(
                            `restrictAccessForRoles.${obj.apiName}`,
                            e.target.value
                          )
                        }}
                        fullWidth
                        label={<Trans>How to restric access?</Trans>}
                        select
                        variant='outlined'
                      >
                        <MenuItem value='none'>
                          <Trans>Allow access</Trans>
                        </MenuItem>
                        <MenuItem value='disable'>
                          <Trans>Disable form</Trans>
                        </MenuItem>
                        <MenuItem value='preventAccessMessage'>
                          <Trans>Prevent access - display message</Trans>
                        </MenuItem>
                        <MenuItem value='preventAccessBlock'>
                          <Trans>Prevent access - block entering page</Trans>
                        </MenuItem>
                      </TextField>
                    </div>
                  ))}
              </DialogContent>
            </Dialog>
          </>
        )
      }}
    </Field>
  )
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      defaultValue=''
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

const DeleteSectionButton = ({ deleteSection, index }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const dispatch = useDispatch()

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: 600 }}>
          <DialogTitle>
            <Trans>Are you sure you want to delete that section?</Trans>
          </DialogTitle>
          <DialogActions>
            <Button
              color='primary'
              variant='outlined'
              onClick={e => {
                e.stopPropagation()
                setDialogOpen(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={e => {
                e.stopPropagation()
                setDialogOpen(false)
                // deleteSection(index)
                dispatch({
                  deleteIndex: index,
                  type: 'REMOVE_SECTION'
                })
              }}
            >
              <Trans>Confirm</Trans>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <IconButton
        onClick={e => {
          e.stopPropagation()
          setDialogOpen(true)
        }}
      >
        <Icon fontSize='small'>delete</Icon>
      </IconButton>
    </>
  )
}

const EditSectionTitleButton = ({ sectionIndex, tree }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [sectionNameEN, setSectionNameEN] = React.useState('')
  const [sectionNameFR, setSectionNameFR] = React.useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (dialogOpen) {
      setSectionNameEN(tree.sections[sectionIndex].titleEN || '')
      setSectionNameFR(tree.sections[sectionIndex].titleFR || '')
    }
  }, [dialogOpen])

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: 600 }}>
          <DialogTitle>
            <Trans>Edit section title</Trans>
          </DialogTitle>
          <DialogContent>
            <TextField
              style={{ marginBottom: 15 }}
              label={<Trans>Section name - English</Trans>}
              variant='outlined'
              value={sectionNameEN}
              fullWidth
              onChange={e => {
                setSectionNameEN(e.target.value)
              }}
            />
            <TextField
              label={<Trans>Section name - French</Trans>}
              variant='outlined'
              value={sectionNameFR}
              fullWidth
              onChange={e => {
                setSectionNameFR(e.target.value)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='outlined'
              onClick={e => {
                e.stopPropagation()
                setDialogOpen(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={e => {
                e.stopPropagation()
                // const newValues = { ...values }
                // newValues.sections[sectionIndex].titleEN = sectionNameEN
                // newValues.sections[sectionIndex].titleFR = sectionNameFR
                // setValues(newValues)
                dispatch({
                  type: 'FIELD',
                  depth: [sectionIndex],
                  fieldArray: [
                    { fieldName: 'titleEN', fieldValue: sectionNameEN },
                    { fieldName: 'titleFR', fieldValue: sectionNameFR }
                  ]
                })
                setDialogOpen(false)
              }}
              disabled={
                sectionNameEN.length === 0 || sectionNameFR.length === 0
              }
            >
              <Trans>Confirm</Trans>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <IconButton
        onClick={e => {
          e.stopPropagation()
          setDialogOpen(true)
        }}
      >
        <Icon fontSize='small'>edit</Icon>
      </IconButton>
    </>
  )
}

const AddSectionButton = ({ addNewSection }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [sectionNameEN, setSectionNameEN] = React.useState('')
  const [sectionNameFR, setSectionNameFR] = React.useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    setSectionNameEN('')
    setSectionNameFR('')
  }, [dialogOpen])

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: 600 }}>
          <DialogTitle>
            <Trans>Add new section</Trans>
          </DialogTitle>
          <DialogContent>
            <TextField
              style={{ marginBottom: 15 }}
              label={<Trans>Section title - English</Trans>}
              variant='outlined'
              value={sectionNameEN}
              fullWidth
              onChange={e => {
                setSectionNameEN(e.target.value)
              }}
            />
            <TextField
              label={<Trans>Section title - French</Trans>}
              variant='outlined'
              value={sectionNameFR}
              fullWidth
              onChange={e => {
                setSectionNameFR(e.target.value)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='outlined'
              onClick={e => {
                e.stopPropagation()
                setDialogOpen(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={e => {
                e.stopPropagation()
                // addNewSection({ nameEN: sectionNameEN, nameFR: sectionNameFR })
                dispatch({
                  nameEN: sectionNameEN,
                  nameFR: sectionNameFR,
                  type: 'ADD_SECTION'
                })
                setDialogOpen(false)
              }}
              disabled={
                sectionNameEN.length === 0 || sectionNameFR.length === 0
              }
            >
              <Trans>Confirm</Trans>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        onClick={() => {
          setDialogOpen(true)
        }}
      >
        <Trans>Add new section</Trans>
      </Button>
    </>
  )
}

export default FormWizard
