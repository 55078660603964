import { NO_USER } from '../SFAuthService'
import SFAuthService from '..//SFAuthService'
import sfOauthConfig from '../sfAuthConfig'

/**
 * Created by Michał Stawski on 02.10.2020.
 */

export const uploadFile = (
  values,
  fileData,
  networkId,
  returnUploadFileResult = false
) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const uploadId = values.opportunityId || values.uploadId
  const base64data = new Buffer(fileData).toString('base64')
  console.log('upload file', values, networkId)
  return conn
    .sobject('ContentVersion')
    .create({
      Title: values.name,
      Description: values.description,
      PathOnClient: values.name,
      FirstPublishLocationId: uploadId,
      NetworkId: networkId,
      TagCsv: values.tags,
      VersionData: base64data
    })
    .then(result => {
      if (returnUploadFileResult) {
        return conn.sobject('ContentVersion').retrieve(result.id)
      }
      return getDocumentsByEntity(uploadId)
    })
}

export const uploadFileToMultipleObjects = ({
  name,
  description,
  tags,
  fileData,
  networkId,
  mainId,
  ids,
  enqueueSnackbar
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const base64data = new Buffer(fileData).toString('base64')
  console.log('upload file', name, tags, networkId, ids)
  return conn
    .sobject('ContentVersion')
    .create({
      Title: name,
      Description: description,
      PathOnClient: name,
      FirstPublishLocationId: mainId,
      NetworkId: networkId,
      TagCsv: tags,
      VersionData: base64data
    })
    .then(contentVersion => {
      return conn
        .sobject('ContentVersion')
        .retrieve(contentVersion.id)
        .then(document => {
          return conn
            .sobject('ContentDocumentLink')
            .create(
              ids.map(id => ({
                ContentDocumentId: document.ContentDocumentId,
                LinkedEntityId: id
              }))
            )
            .then(result => {
              return getDocumentsByEntity(mainId)
            })
        })
    })
}

export const getDocumentsByEntitiesByFlow = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Get_Files_For_Entity', {
      inputs: ids.map(id => ({
        parentId: id
      }))
    })
    .then(flowResult => {
      const toReturn = {}
      const documentsMap = {}
      flowResult.forEach((resObj, index) => {
        const {
          contentDocumentLinks,
          contentVersions,
          contentDocuments
        } = resObj.outputValues
        const matchObj = {}
        if (contentDocuments) {
          contentDocuments.forEach(document => {
            documentsMap[document.Id] = document
          })
        }
        contentDocumentLinks.forEach(link => {
          matchObj[link.ContentDocumentId] = {
            ...link,
            ContentDocument: documentsMap[link.ContentDocumentId]
          }
        })
        if (contentVersions) {
          contentVersions.forEach(version => {
            matchObj[version.ContentDocumentId].ContentVersion = version
          })
        }
        toReturn[ids[index]] = Object.values(matchObj).map(obj =>
          parseDocument(obj)
        )
      })
      return toReturn
    })
}

export const getDocumentsByEntities = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const returnObj = {}
  if (ids.length === 0) {
    return Promise.resolve([])
  }
  return conn
    .sobject('ContentDocumentLink')
    .find({
      LinkedEntityId: { $in: ids }
    })
    .select('ContentDocumentId, Id, LinkedEntityId, ContentDocument.*')
    .autoFetch(true)
    .then(result => {
      const find = []
      result.forEach(res => {
        find.push(res.ContentDocumentId)
        returnObj[res.ContentDocumentId] = { ...res }
      })
      if (find.length === 0) {
        return []
      }
      return conn
        .sobject('ContentVersion')
        .find({
          ContentDocumentId: find
        })
        .select('Id, ContentDocumentId, TagCsv, Title, VersionNumber')
        .then(versions => {
          const toReturn = []
          versions.forEach((version, index) => {
            returnObj[version.ContentDocumentId].ContentVersion = version
          })
          for (const key in returnObj) {
            toReturn.push(returnObj[key])
          }
          return toReturn
        })
    })
}

export const getDocumentsByEntity = id => {
  if (!id) {
    return Promise.resolve([])
  }
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }

  // return conn.sobject('ContentVersion')
  const returnObj = {}
  return conn
    .sobject('ContentDocumentLink')
    .select('ContentDocumentId, Id, LinkedEntityId, ContentDocument.*')
    .where({
      LinkedEntityId: id
    })
    .autoFetch(true)
    .then(result => {
      const find = []
      result.forEach(res => {
        find.push(res.ContentDocumentId)
        returnObj[res.ContentDocumentId] = { ...res }
      })
      if (find.length === 0) {
        return []
      }
      return conn
        .sobject('ContentVersion')
        .find({
          ContentDocumentId: find
        })
        .then(versions => {
          const toReturn = []
          versions.forEach((version, index) => {
            returnObj[version.ContentDocumentId].ContentVersion = version
          })
          for (const key in returnObj) {
            toReturn.push(returnObj[key])
          }
          return toReturn
        })
    })
}

export const linkFile = ({ file, target }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Link_File', {
    inputs: [
      {
        file,
        target
      }
    ]
  })
}

export const contentDocumentDownloadUrl = id =>
  `${sfOauthConfig.oauth2.loginUrl}/sfc/servlet.shepherd/document/download/${id}?operationContext=S1`

export const parseDocument = doc => {
  return {
    name: doc.ContentDocument.Title,
    parent: doc.LinkedEntityId,
    description: doc.ContentDocument.Description,
    id: doc.ContentDocument.Id,
    tags: doc.ContentVersion.TagCsv,
    lastModifiedDate: doc.ContentVersion.LastModifiedDate,
    url: contentDocumentDownloadUrl(doc.ContentDocument.Id)
    // progress: true
  }
}

export const deleteDocumentByFlow = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Delete_File', {
    inputs: [
      {
        toDelete: {
          Id: id
        }
      }
    ]
  })
}

export const deleteDocument = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Delete_Document', {
    inputs: [
      {
        toDelete: {
          Id: id
        }
      }
    ]
  })
}

const SF_API_VERSION = 'v49.0'

export const uploadFiles = files => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    `/services/data/${SF_API_VERSION}/composite/sobjects`,
    {
      allOrNone: false,
      records: [
        files.map((item, index) => {
          return {
            attributes: {
              type: 'Document',
              binaryPartName: `binaryPart_${index}`,
              binaryPartNameAlias: 'Body'
            },
            // 'Description': values.name ,
            FolderId: '005xx000001Svs4AAC',
            Name: 'Brochure',
            Type: 'pdf'
          }
        })
      ]
    }
  )
}
