import { Trans } from '@lingui/macro'
import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'

export const FormBlankSpace = ({ typeProps }) => {
  const { width = 1, height = 1 } = typeProps
  return (
    <div
      style={{
        width: Number(width),
        height: Number(height)
      }}
    />
  )
}
export const FormEditorBlankSpace = ({
  editMode,
  depth,
  typeProps = {},
  ...props
}) => {
  const { width, height } = typeProps
  const dispatch = useDispatch()

  if (!editMode) {
    return <FormBlankSpace typeProps={typeProps} {...props} />
  }

  return (
    <div>
      <Grid container direction='row'>
        <Grid item xs={6} style={{ padding: 5 }}>
          <TextField
            variant='outlined'
            label={<Trans>Height</Trans>}
            defaultValue={0}
            value={height}
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.height = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} style={{ padding: 5 }}>
          <TextField
            variant='outlined'
            label={<Trans>Width</Trans>}
            defaultValue={0}
            value={width}
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.width = e.target.value
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  )
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, id, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      defaultValue=''
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}
