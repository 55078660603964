import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  Grid,
  Icon,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { EligibilityDialog } from './EligibilityDialog'
import { I18n } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import {
  deleteOpportunity,
  FCHI2_RECORD_TYPE,
  getOpportunities,
  getOpportunity,
  getRecordTypes,
  opportunitiesStagesInOrder,
  opportunityEditable,
  oppToStepper
} from '../../services/sfAuth/sfData/sfOpportunity'
import moment from 'moment'
import { dateFormat, defaultDocTitle } from '../../appSettings'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Loading from '../../../egret/components/EgretLoadable/Loading'
import { withSnackbar } from 'notistack'
import { OpportunityView } from '../opportunity/OpportunityView'
import { granteeReportConfiguration } from './GranteeReport'
import ReactToPrint from 'react-to-print'
import ContactProgramManager from './ContactProgramManager'
import MUIDataTable from 'mui-datatables'
import { muiTextLabels } from '../utilities/muiDataTablesTranslations'
import { muiDateCompare } from 'utils'
import { OrganisationToPrint } from './OrganisationDetails'
import { PAGE_BREAK } from '../opportunity/opportunityViewComponents'
import { OrganisationToPrintPageBreakBefore } from './OrganisationDetails'
import { HEADER } from '../opportunity/opportunityViewComponents'
import { validateYupSchema } from 'formik'
import { OrganisationDetailsValidationSchema } from './OrganisationDetails'
import { reject } from 'lodash'

// function Transition (props) {
//   return <Slide direction="up" {...props} />
// }

export const printStyles = theme => {
  return {
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
      // width: 800
    },
    CellCentered: {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button: {
      margin: theme.spacing(1)
    },
    gridObjectives: {
      flexGrow: 1
    },
    dataList: {
      padding: 15,
      marginBottom: 15,
      marginLeft: 20,
      marginRight: 20,
      flexGrow: [[1], '!important']
    },
    dataContainer: {
      padding: 15,
      width: '100%'
    },
    paperContainerStretch: {
      width: '100%',
      padding: 15
    },
    gridContainerStretch: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 15
    },
    gridContainerStretchNoBreak: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 15
    },
    contactGridItem: {
      minHeight: [['90px'], '!important']
    },
    '@media print': {
      fill: {
        width: [['100%'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191'
      },
      titleCard: {
        padding: '5px',
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        justifyContent: [['center'], '!important'],
        marginLeft: [[25], '!important'],
        marginRight: [[15], '!important'],
        height: [['auto'], '!important'],
        width: [['750px'], '!important']
      },
      titleCardStretch: {
        padding: '5px',
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        justifyContent: [['center'], '!important'],
        //marginLeft: [[25], '!important'],
        //marginRight: [[15], '!important'],
        height: [['auto'], '!important'],
        width: [['100%'], '!important']
      },
      titleGrid: {
        margin: [[0], '!important'],
        height: [['auto'], '!important'],
        width: [['750px'], '!important']
        // alignItems: [['center'], '!important'],
        // justify: [['flex-start'], '!important'],
        // flexDirection: [['row'], '!important'],
        // display: [['flex'], '!important']
      },
      nameGrid: {
        width: [['300px'], '!important']
      },
      gridObjectives: {
        paddingLeft: 20,
        paddingRight: 20,
        flexGrow: 1
      },
      programManagerCard: {
        padding: [[10], '!important'],
        // marginBottom: [[40], '!important'],
        // paddinTop: [[20], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        display: [['inline-block'], '!important'],
        height: [['150px'], '!important'],
        width: [['300px'], '!important']
      },
      imageLogo: {
        display: [['inline'], '!important']
      },
      gridContainer: {
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        justifyContent: [['space-evenly'], '!important'],
        alignItems: [['center'], '!important']
      },
      gridContainerStretch: {
        paddingLeft: 0,
        paddingRight: 0,
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        marginBottom: 15,
        justifyContent: [['center'], '!important']
      },
      gridContainerStretchNoBreak: {
        paddingLeft: 0,
        paddingRight: 0,
        display: [['flex'], '!important'],
        width: [['100%'], '!important'],
        marginBottom: 15,
        justifyContent: [['center'], '!important'],
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important']
      },
      gridChild: {
        display: [['block'], '!important'],
        alignSelf: [['center'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto'
      },
      otherGrantsGrid: {
        borderRadius: 3,
        margin: 0,
        padding: 0,
        display: [['block'], '!important'],
        alignSelf: [['center'], '!important'],
        border: '2px solid',
        borderColor: '#919191',
        height: [['300px'], '!imortant'],
        width: [['368px'], '!important']
      },
      contactGridItem: {
        minHeight: [['95px'], '!important'],
        width: [['575px'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191'
      },
      gridChildHalfWidth: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        //flexGrow: 1,
        //width: '100%'
        width: [['575px'], '!important']
      },
      gridChildTable1: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['600px'], '!important']
      },
      gridChildTable2: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['150px'], '!important']
      },
      gridChildTable3: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['350px'], '!important']
      },
      gridChildTable4: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        margin: 'auto',
        minHeight: [['65px'], '!important'],
        width: [['950px'], '!important']
      },
      gridChildTable5: {
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        minHeight: [['65px'], '!important'],
        width: [['180px'], '!important']
      },
      paperContainer: {
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 15,
        border: '2px solid',
        borderColor: '#919191'
      },
      description: {
        padding: 15,
        margin: 0,
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        width: [['1150px'], '!important']
      },
      paperContainerStretch: {
        padding: 15,
        margin: 0,
        // margin: 0,
        //marginLeft: 20,
        //marginRight: 20,
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        width: [['1150px'], '!important']
      },
      dataList: {
        padding: 15,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 3,
        border: '2px solid',
        borderColor: '#919191',
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        //flexGrow: 1,
        width: [['1150px'], '!important']
      },
      dataContainer: {
        padding: 15,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        borderRadius: 3,
        flexGrow: 1,
        breakInside: [['auto'], '!important'],
        pageBreakInside: [['auto'], '!important'],
        border: [['2px solid'], '!important'],
        borderColor: [['#919191'], '!important']
        //width: [['1150px'], '!important']
      },
      mainTitle: {
        fontSize: [[24], '!important'],
        marginLeft: [[15], '!important'],
        padding: [[10], '!important']
      },
      subtitle: {
        fontSize: 12,
        color: '#919191'
      },
      hideInPrint: {
        display: [['none'], '!important'],
        visibility: [['hidden', '!important']]
      }
    }
  }
}

const styles = theme => printStyles(theme)

class ApplicationList extends React.Component {
  constructor (props) {
    super(props)
    this.printRef = React.createRef()
  }

  state = {
    open: false,
    modalId: null,
    eligibilityOpen: false,
    recordTypes: null,
    list: [],
    loaded: false,
    opportunities: []
  }

  refresh () {
    if (this.props.user && this.props.user.userId && !this.state.loading) {
      console.log(this.props.organization, this.props.user)
      if (!this.props.organization.id) {
        this.setState({ list: [], loaded: true, loading: false })
        return
      }
      this.props.enqueueSnackbar(<Trans>Refreshing</Trans>, {
        variant: 'info'
      })
      Promise.all([
        getRecordTypes('Opportunity'),
        getOpportunities(
          {
            permissions: {
              TEAM_MEMBERS: true
            },
            searchParams: {
              AccountId: this.props.organization.id
            }
          },
          true
        )
      ])
        .then(([recordTypes, list]) => {
          console.log('getRecordTypes and opportunities', recordTypes, list)
          list = list.filter(function (opp) {
            return opp.UserRecordAccess.HasEditAccess
          })
          list = list.map(item => oppToStepper(item))
          console.log(list)
          this.props.enqueueSnackbar(<Trans>Loaded</Trans>, {
            variant: 'info'
          })
          this.setState({ recordTypes, list, loaded: true, loading: false })
        })
        .catch(reject => console.error(reject))

      this.setState({ loading: true })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.state.loaded) {
      this.refresh()
    }
    if (
      this.props.user &&
      this.props.user.userId &&
      prevProps.user.userId !== this.props.user.userId
    ) {
      console.log('updated user', this.props.user)
      this.refresh()
    }
    if (this.props.organization) {
      if (prevProps.organization !== this.props.organization) {
        this.setState({ loaded: false })
        this.refresh()
      }
      //console.log(prevProps.organization === this.props.organization)
    }
  }

  componentDidMount () {
    console.log('mount user', this.props.user)
    console.log('mount org', this.props.organization)
    if (this.props.organization) {
      validateYupSchema(
        { ...this.props.organization },
        OrganisationDetailsValidationSchema
      ).then(
        result => {
          this.setState({
            isOrganizationValid: true
          })
        },
        reject => {
          this.setState({
            isOrganizationValid: false
          })
        }
      )
    }
    this.refresh()
  }

  onRemoveItem = id => {
    console.log(id)
    this.props.enqueueSnackbar(<Trans>Deleting</Trans>, {
      variant: 'info'
    })
    deleteOpportunity(id)
      .then(() => {
        this.props.enqueueSnackbar(<Trans>Deleted</Trans>, {
          variant: 'info'
        })
        this.refresh()
      })
      .catch(error => {
        this.props.enqueueSnackbar(<Trans>Error while Deleting</Trans>, {
          variant: 'error'
        })
      })

    this.setState({ open: false })
  }

  createTable = ({ i18n, history, rolePermissions }) => {
    let columns = [
      t`Name`,
      t`Fund`,
      t`Modification`,
      t`Stage`,
      t`Created date`,
      t`Program Manager`,
      t`Actions`,
      t`Delete`
    ]

    if (!this.state.list.map) {
      return <Loading />
    }

    const data = this.state.list.map(item => this.opportunityToTable(item))

    return this.state.list && this.state.loaded ? (
      <>
        <MUIDataTable
          title={
            <Typography variant='h5'>
              <b>
                <Trans>Applications list</Trans>
              </b>
            </Typography>
          }
          data={data}
          options={{
            textLabels: muiTextLabels(i18n),
            filter: false,
            selectableRows: 'none',
            print: false,
            download: false,
            viewColumns: false
          }}
          columns={[
            {
              name: 'name',
              label: i18n._(t`Name`),
              options: {
                customHeadLabelRender: props => {
                  return (
                    <div>
                      <Trans>Name</Trans>
                    </div>
                  )
                },
                customBodyRender: value => {
                  return <div>{value}</div>
                }
              }
            },
            {
              name: 'recordType',
              label: i18n._(t`Fund`),
              options: {
                customHeadLabelRender: props => {
                  return <Trans>Fund</Trans>
                },
                customBodyRender: value => {
                  return (
                    <div>
                      <Trans id={value} />
                    </div>
                  )
                }
              }
            },
            {
              name: 'lastModifiedDate',
              sortCompare: muiDateCompare('lastModifiedDate'),
              label: i18n._(t`Modification`),
              options: {
                customHeadLabelRender: props => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <Trans>Modification</Trans>
                    </div>
                  )
                },
                customBodyRender: value => {
                  return (
                    <div className={this.props.classes.CellCentered}>
                      {moment.utc(value).format(dateFormat)}
                    </div>
                  )
                }
              }
            },
            {
              name: 'stageName',
              label: i18n._(t`Stage`),
              options: {
                sortCompare: order => ({ data: stage1 }, { data: stage2 }) => {
                  const ret =
                    opportunitiesStagesInOrder.indexOf(stage1) -
                    opportunitiesStagesInOrder.indexOf(stage2)
                  return order === 'asc' ? ret : ret * -1
                },
                customHeadLabelRender: props => {
                  return <Trans>Stage</Trans>
                },
                customBodyRender: value => {
                  return (
                    <div>
                      <Trans id={value} />
                    </div>
                  )
                }
              }
            },
            {
              name: 'createdDate',
              sortCompare: muiDateCompare('createdDate'),
              label: i18n._(t`Created date`),
              options: {
                customHeadLabelRender: props => {
                  return <Trans>Created date</Trans>
                },
                customBodyRender: value => {
                  return <div>{moment.utc(value).format(dateFormat)}</div>
                }
              }
            },
            {
              name: 'pm',
              label: i18n._(t`Program Manager`),
              options: {
                sort: false,
                customHeadLabelRender: props => {
                  return (
                    <div>
                      <Trans>Program Manager</Trans>
                    </div>
                  )
                },
                customBodyRender: value => {
                  return (
                    <div>
                      <div>{value.pmName}</div>
                      <div>{value.pmMail}</div>
                      <div>{value.pmPhone}</div>
                    </div>
                  )
                }
              }
            },
            {
              name: 'actions',
              label: i18n._(t`Actions`),
              options: {
                sort: false,
                customHeadLabelRender: props => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <Trans>Actions</Trans>
                    </div>
                  )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                  const item = data[tableMeta.rowIndex]
                  const appToPrint = this.state.list[tableMeta.rowIndex]
                  const per = {
                    isTeamMember: appToPrint.teamMembers.some(
                      member => member.UserId === this.props.user.userId
                    ),
                    ...rolePermissions
                  }
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <ContactProgramManager
                        recordType={item.recordType}
                        active={item.pm.isPm}
                        opportunityId={item.id}
                      />
                      {!opportunityEditable(item) ? (
                        <Tooltip title={i18n._(t`View`)}>
                          <IconButton
                            disabled={per.associate && !per.isTeamMember}
                            type='button'
                            onClick={() => {
                              history.push(`/grants/Application/${item.id}`, {
                                id: item.id
                              })
                            }}
                          >
                            <Icon color={'inherit'}>description</Icon>
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title={i18n._(t`Edit`)}>
                          <IconButton
                            disabled={
                              !per.manager &&
                              !per.contributor &&
                              !per.isTeamMember
                            }
                            type='button'
                            onClick={() => {
                              history.push(`/grants/Application/${item.id}`, {
                                id: item.id
                              })
                            }}
                          >
                            <Icon color={'inherit'}>edit</Icon>
                          </IconButton>
                        </Tooltip>
                      )}
                      {item.displayPrint && (
                        <IconButton
                          variant='filled'
                          onClick={() => {
                            this.setState({
                              applicationToPrint: appToPrint
                            })
                            getOpportunity(item.id).then(result => {
                              this.setState({
                                applicationToPrint: {
                                  ...oppToStepper(result),
                                  readyToPrint: true
                                }
                              })
                            })
                          }}
                        >
                          <Icon
                            className='material-icons MuiIcon-root'
                            variant={'filled'}
                          >
                            print
                          </Icon>
                        </IconButton>
                      )}
                      {(opportunityEditable(item) ||
                        item.stageName === 'InProgress') && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <IconButton
                            disabled={!per.manager}
                            variant='filled'
                            onClick={this.handleClickOpen(item.id)}
                          >
                            <Icon
                              style={{ color: per.manager && 'red' }}
                              variant={'filled'}
                            >
                              delete
                            </Icon>
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )
                }
              }
            }
          ]}
        />
      </>
    ) : (
      <Loading />
    )
  }

  opportunityToTable = item => {
    let splitContact = ['', '']
    if (item.info.pmContact) {
      splitContact = item.info.pmContact.split(';')
    }
    const mail = splitContact[0]
    const phone = splitContact[1]
    if (item.info.fundTypeName === FCHI2_RECORD_TYPE) {
      return {
        name: item.info.name,
        recordType: item.info.fundTypeName,
        stageName: item.info.stageName,
        createdDate: item.info.createdDate,
        pm: {
          isPm: Boolean(item.info.pmName && mail),
          assignedManager: item.info.assignedManager,
          pmName: item.info.pmName,
          pmMail: mail,
          pmPhone: phone
        },
        lastModifiedDate: item.info.lastModifiedDate,
        id: item.info.id,
        displayPrint: false
      }
    } else {
      return {
        name: item.projectDetails.projectName,
        recordType: item.info.recordType,
        stageName: item.info.stageName,
        createdDate: item.info.createdDate,
        pm: {
          isPm: Boolean(item.info.pmName && mail),
          assignedManager: item.info.assignedManager,
          pmName: item.info.pmName,
          pmMail: mail,
          pmPhone: phone
        },
        lastModifiedDate: item.info.lastModifiedDate,
        id: item.info.id,
        displayPrint: true
      }
    }
  }

  handleClickOpen = id => () => {
    this.setState({ open: true, modalId: id })
  }

  handleClose = () => {
    this.setState({ open: false, modelId: null })
  }

  render () {
    let { classes, history, user, organization } = this.props
    const { applicationToPrint, loaded, isOrganizationValid } = this.state
    const config = granteeReportConfiguration({
      classes,
      applicationList: true
    })
    config.fields.splice(
      5,
      0,
      ...OrganisationToPrint({
        classes
      }),
      {
        type: HEADER,
        style: { fontSize: 24 },
        text: item => (
          <span className={classes.mainTitle}>
            <Trans>Application Details</Trans>
          </span>
        )
      }
    )
    let userRole = 'No role'
    if (user.organizationMember) {
      userRole = user.organizationMember.TeamMemberRole
    }
    const rolePermissions = {
      manager: userRole === 'Manager',
      contributor: userRole === 'Contributor',
      partner: userRole === 'Partner',
      associate: userRole === 'Associate'
    }

    if (!loaded) {
      return <Loading />
    }

    return (
      <I18n>
        {({ i18n }) => (
          <>
            {applicationToPrint && (
              <Dialog
                open={Boolean(this.state.applicationToPrint)}
                maxWidth='false'
                scroll='paper'
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
              >
                <DialogTitle>
                  <Grid container direction='row' justify='space-between'>
                    <ReactToPrint
                      onAfterPrint={() => (document.title = defaultDocTitle)}
                      onBeforePrint={() =>
                        (document.title =
                          applicationToPrint.projectDetails.projectName)
                      }
                      trigger={() => (
                        <IconButton
                          disabled={!applicationToPrint.readyToPrint}
                          aria-label={<Trans>Print</Trans>}
                          className=''
                        >
                          <Icon>print</Icon>
                        </IconButton>
                      )}
                      content={() => this.printRef.current}
                    />
                    <IconButton
                      onClick={() => {
                        this.setState({ applicationToPrint: null })
                      }}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </Grid>
                </DialogTitle>

                <DialogContent>
                  <div style={{ width: '900px', height: '800px' }}>
                    {!applicationToPrint.readyToPrint && <Loading />}
                    {applicationToPrint.readyToPrint && (
                      <div ref={this.printRef}>
                        <OpportunityView
                          opportunity={this.state.applicationToPrint}
                          committeeType='External Review Accept'
                          configuration={config}
                        />
                      </div>
                    )}
                  </div>
                </DialogContent>
              </Dialog>
            )}
            <Paper className={classes.root}>
              <div>
                <div style={{ paddingLeft: 15, paddingTop: 20 }}>
                  <Button
                    variant='contained'
                    color='primary'
                    type='button'
                    disabled={
                      !this.state.recordTypes ||
                      !organization.id ||
                      Boolean(
                        !rolePermissions.manager && !rolePermissions.contributor
                      ) ||
                      !isOrganizationValid
                    }
                    onClick={() => this.setState({ eligibilityOpen: true })}
                  >
                    <Icon color={'inherit'}>add</Icon>
                    <span data-tut='reactour__applicationList__new'>
                      <Trans>New Application</Trans>
                    </span>
                  </Button>
                </div>
                {Boolean(!isOrganizationValid && organization.id) && (
                  <div
                    style={{
                      color: 'red',
                      marginLeft: 10,
                      marginTop: 10
                    }}
                  >
                    <Trans>
                      You cannot create new application until you fill out all
                      field marked as required in the Organisation Details
                    </Trans>
                  </div>
                )}
                {!organization.id && (
                  <Typography
                    style={{
                      color: 'red',
                      marginLeft: 10,
                      marginTop: 10
                    }}
                  >
                    <Trans>
                      Before you will be able to create a new application you
                      have to associate an organisation with your account
                    </Trans>
                  </Typography>
                )}
                <div className='w-100 overflow-auto' style={{ padding: 20 }}>
                  {this.createTable({ i18n, history, rolePermissions })}
                </div>
              </div>
              <Dialog
                open={this.state.open}
                // TransitionComponent={Transition}
                onClose={this.handleClose}
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
              >
                <DialogTitle id='alert-dialog-slide-title'>
                  {'\n' +
                    i18n._(
                      t`Are you sure you want to delete this application?`
                    )}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-slide-description'>
                    This process cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color='default'>
                    {i18n._(t`Cancel`)}
                  </Button>
                  <Button
                    onClick={() => this.onRemoveItem(this.state.modalId)}
                    color='default'
                  >
                    {i18n._(t`Yes, delete it forever~`)}
                  </Button>
                </DialogActions>
              </Dialog>
              <EligibilityDialog
                open={this.state.eligibilityOpen}
                recordTypes={this.state.recordTypes}
                user={this.props.user}
                handleClose={() => this.setState({ eligibilityOpen: false })}
              />
            </Paper>
          </>
        )}
      </I18n>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
})

export default withSnackbar(
  withRouter(withStyles(styles)(connect(mapStateToProps)(ApplicationList)))
)
