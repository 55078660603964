/**
 * Created by Michał Stawski on 01.02.2021.
 */
import React from 'react'
import { Trans } from '@lingui/macro'

export const requiredTrans = <Trans>This field is required</Trans>
export const emailTrans = <Trans>Must be valid email</Trans>
export const dateRangeRequiredTrans = (
  <Trans>*You need to provide both end and a start point dates</Trans>
)
export const questionRequiredTrans = <Trans>This question is required</Trans>
export const questionRequiredTransWithStar = (
  <Trans>*This question is required</Trans>
)
export const requiredInt = <Trans>Amount entered must be an integer</Trans>
export const requiredPositive = <Trans>Amount entered cannot be negative</Trans>
export const requiredSite = (
  <Trans>
    You have to enter a valid site address, including https:// or http://
  </Trans>
)
export const maxLength = ({ max }) => <Trans>Must be at most {max} chars</Trans>
