import { Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { countHelperText } from 'app/views/page-layouts/FormElement'
import { Field, useField } from 'formik'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import { validFieldTypesForTextField } from '../FormWizard'
import SFAuthService from 'app/services/sfAuth/SFAuthService'
import { FormikCKEditor } from 'app/views/page-layouts/FormikCKEditor'
import {
  FormConnectToObject,
  MuEditedByLabel,
  pdfDefaultFontSize
} from './Common'
import { FormErrorLabel, FormLabel } from './FormErrorLabel'
import moment from 'moment'
import { CurrencyFormated } from 'app/views/common/Formats'
import { dateFormat } from 'app/appSettings'
import MUTextField from './multiuser-input/MUTextField'
import { getMainConnected } from '../Form'
import { Alert, AlertTitle } from '@material-ui/lab'
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser'
import Html from 'react-pdf-html'
import { Text, View } from '@react-pdf/renderer'

export const getCurrentWords = (input = '', limit) => {
  const text = input.split(' ')
  let wordCount = 0
  for (let i = 0; i < text.length; i++) {
    if (text[i] !== ' ' && text[i] !== '') {
      wordCount++
    }
  }
  if (limit) {
    return wordCount + '/' + limit
  }
  return wordCount
}

export const formTextFieldValueToText = (value, question, object) => {
  const { isAdvancedTextEditor } = question.typeProps
  if (isAdvancedTextEditor) {
    const h2p = require('html2plaintext')
    const parsed = h2p(value)
    return { en: parsed, fr: parsed }
  }
  return value ? { en: value, fr: value } : { en: '', fr: '' }
}

// export const formTextFieldExtractSaveKey = ({ saveObj, item, value }) => {
//   if (!item.typeProps.requiresRequest && item.typeProps.connectedField) {
//     const keyName = item.typeProps.connectedField.name
//     saveObj[keyName] = value
//     return saveObj
//   }
// }

export const FormTextInputPdf = ({
  value,
  elementType,
  typeProps,
  langFR,
  title,
  ...props
}) => {
  const {
    printLabelPlacement,
    currency,
    isPhone,
    isAdvancedTextEditor
  } = typeProps
  const top = !printLabelPlacement || printLabelPlacement === 'top'
  let valueToShow = value
  if (elementType === 'textInputNumeric' && valueToShow) {
    if (currency && !(isNaN(value) || Boolean(!value && value !== 0))) {
      valueToShow = String(
        !langFR
          ? new Intl.NumberFormat('en-CA', {
              style: 'currency',
              currency: 'CAD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(value)
          : new Intl.NumberFormat('fr-CA', {
              style: 'currency',
              currency: 'CAD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })
              .format(value)
              .split('CA')[0]
      )
    }
  } else if (elementType === 'datePicker' && valueToShow) {
    valueToShow = moment(valueToShow).format(dateFormat)
  } else if (isAdvancedTextEditor) {
    valueToShow = <Html>{valueToShow}</Html>
  }

  const titleLabel = <Text style={{ fontWeight: 'bold' }}>{title}</Text>

  return (
    <View style={{ fontFamily: 'Roboto', fontSize: pdfDefaultFontSize }}>
      {top && titleLabel}
      <Text style={{ whiteSpace: 'pre-line' }}>{valueToShow}</Text>
      {!top && titleLabel}
    </View>
  )
}

export function FormTextInputPrint ({
  value,
  elementType,
  typeProps,
  langFR,
  title,
  ...props
}) {
  const {
    printLabelPlacement,
    currency,
    isPhone,
    isAdvancedTextEditor
  } = typeProps
  const top = !printLabelPlacement || printLabelPlacement === 'top'
  let valueToShow = value
  if (elementType === 'textInputNumeric' && valueToShow) {
    if (isPhone) {
      valueToShow = '+1 ' + valueToShow
    } else if (currency) {
      valueToShow = <CurrencyFormated value={valueToShow} />
    }
  } else if (elementType === 'datePicker' && valueToShow) {
    valueToShow = moment(valueToShow).format(dateFormat)
  } else if (isAdvancedTextEditor) {
    valueToShow = ReactHtmlParser(value)
  }

  return (
    <div>
      {top && <Typography className='form-print-subtitle'>{title}</Typography>}
      <Typography style={{ whiteSpace: 'pre-line' }}>{valueToShow}</Typography>
      {!top && <Typography className='form-print-subtitle'>{title}</Typography>}
    </div>
  )
}

export function FormTextField ({
  id,
  useMultiuser,
  formId,
  connectedObject,
  disabled,
  preview,
  typeProps,
  langFR,
  connectedFieldDetails = {},
  title,
  connectedMap,
  ...props
}) {
  const {
    required,
    maxChar,
    expandable,
    rows = 1,
    readOnly,
    isAdvancedTextEditor,
    wordCount,
    isPostalCode,
    requiresRequest,
    showFieldLabel
  } = typeProps
  const { length } = connectedFieldDetails
  let limit = maxChar || length
  if (limit > length) {
    limit = length
  }
  const helperArray = []
  const [dialogOpen, openDialog] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [field, meta] = useField(id)
  const value = field.value

  if (readOnly) {
    return <Typography>{value}</Typography>
  }

  const baseProps = {}
  if (isPostalCode) {
    baseProps.inputComponent = PostalCodeFormat
  }

  if (isAdvancedTextEditor) {
    let stringValue = value || ''
    stringValue = value
      ? value
          .replaceAll('&nbsp;', ' ')
          .replace(/<[^>]+>/g, '')
          .replaceAll('\n', '')
      : ''
    if (wordCount) {
      helperArray.push(
        <span key='wordsCount'>
          {getCurrentWords(stringValue)} <Trans>words</Trans>
          {'. '}
        </span>
      )
    }
    if (Number(limit)) {
      helperArray.push(
        <span key='limit'>
          {countHelperText(stringValue, Number(limit))}
          {'. '}
        </span>
      )
    }
    if (required) {
      helperArray.push(
        <span key='required'>
          <Trans>Required</Trans>
          {'. '}
        </span>
      )
    }

    return (
      <Field name={id}>
        {({ field, form, meta }) => {
          const { setFieldTouched } = form
          return (
            <>
              <FormikCKEditor
                name={id}
                disabled={disabled}
                style={{ marginTop: 5 }}
                label={title}
                characterLimit={limit && Number(limit)}
                config={{
                  toolbar: [
                    ['Bold', 'Italic'],
                    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'],
                    ['Link', 'Unlink'],
                    ['About']
                  ]
                }}
                formId={formId}
                useMultiuser={useMultiuser}
                onBlur={e => {
                  if (!meta.touched) {
                    setFieldTouched(id)
                  }
                }}
              />
              <FormLabel label={helperArray} />
              <FormErrorLabel id={id} error={meta.error} />
            </>
          )
        }}
      </Field>
    )
  }

  return (
    <>
      <Grid container wrap='nowrap'>
        <MUTextField
          useMultiuser={useMultiuser}
          id={id}
          formId={formId}
          disabled={disabled}
          style={{ marginTop: 5 }}
          label={showFieldLabel && title}
          multiline={expandable || rows > 1}
          rows={expandable ? 0 : rows}
          inputProps={{
            maxLength: limit && Number(limit)
          }}
          required={required}
          limit={limit}
          wordCount={wordCount}
        />
        {requiresRequest && (
          <Grid
            item
            style={{
              paddingLeft: 10,
              paddingTop: 15,
              minWidth: 160
            }}
          >
            <Button
              disabled={requesting || !value || meta.error}
              color='primary'
              variant='contained'
              onClick={e => {
                setRequesting(true)
                const conn = SFAuthService.getConnection()
                if (
                  conn &&
                  connectedObject &&
                  Array.isArray(typeProps.connectedTo)
                ) {
                  const sending = enqueueSnackbar(
                    <Trans>Sending request</Trans>,
                    {
                      variant: 'info'
                    }
                  )
                  const promises = typeProps.connectedTo.map(connected => {
                    const { connectedObject, connectedField } = connected
                    let sfObject = connectedMap[connectedObject].sfObject
                    let fieldName = connectedField?.name
                    if (connectedField && fieldName.indexOf('.') !== '-1') {
                      const subObjectName = fieldName.split('.')[0]
                      const subFieldName = fieldName.split('.')[1]
                      if (subObjectName && subFieldName) {
                        fieldName = subFieldName
                        sfObject = sfObject[subObjectName]
                      }
                    }
                    const toUpdate = {
                      Id: sfObject.Id
                    }
                    toUpdate[fieldName] = value
                    return conn
                      .sobject(sfObject.attributes.type)
                      .update(toUpdate)
                  })
                  Promise.all(promises)
                    .then(r => {
                      openDialog(true)
                      setRequesting(false)
                      closeSnackbar(sending)
                    })
                    .catch(err => {
                      closeSnackbar(sending)
                      setRequesting(false)
                      console.error(err)
                    })
                } else {
                  setRequesting(false)
                  enqueueSnackbar(
                    <Trans>There is no field connected to this element!</Trans>,
                    {
                      variant: 'error'
                    }
                  )
                }
              }}
            >
              <Trans>Request change</Trans>
            </Button>
          </Grid>
        )}
      </Grid>
      {requiresRequest && (
        <Dialog open={Boolean(dialogOpen)}>
          <DialogTitle>
            <Trans>Check your email for confirmation</Trans>
          </DialogTitle>
          <DialogContent>
            {title && (
              <Typography>
                <Trans>
                  To change {title.toLowerCase()} you have to click confirmation
                  link which was send to you via email
                </Trans>
              </Typography>
            )}
            <Grid container justify='center' style={{ padding: 15 }}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  openDialog(false)
                }}
              >
                <Trans>Ok</Trans>
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export function FormEditorTextField ({
  typeProps = {},
  french,
  depth,
  editMode,
  title,
  showPdfProps,
  showPrintProps,
  ...props
}) {
  const dispatch = useDispatch()
  const {
    required,
    forceRequired,
    expandable,
    rows,
    isAdvancedTextEditor,
    isEmail,
    isUrl,
    regexValidation,
    regexValidationMessageEN,
    regexValidationMessageFR,
    lengthLimit,
    maxChar,
    isConnected,
    wordCount,
    showFieldLabel,
    requiresRequest,
    printPageBreakBefore,
    printLabelPlacement
  } = typeProps

  if (!editMode) {
    return <FormTextField typeProps={typeProps} title={title} {...props} />
  }

  const { connectedField } = getMainConnected({ typeProps, ...props })
  const forceReadOnly =
    (connectedField && connectedField.readOnly) || showPrintProps
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container direction='column' style={{ padding: 10 }}>
      {!showPdfProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField) || forceReadOnly}
              disabled={!connectedField || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.wordCount
                delete toSet.required
                delete toSet.rows
                delete toSet.lengthLimit
                delete toSet.maxChar
                delete toSet.showFieldLabel
                delete toSet.expandable
                delete toSet.requiresRequest
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {forceReadOnly && !showPdfProps ? (
        <Alert severity='info' variant='outlined'>
          <Trans>
            You can't change this element to an input. Either connected field or
            whole form is marked as read only
          </Trans>
        </Alert>
      ) : (
        !readOnly &&
        !showPdfProps && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(required || forceRequired) || showPrintProps}
                  disabled={readOnly || forceRequired || showPrintProps}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isEmail)}
                  disabled={readOnly || isAdvancedTextEditor}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isEmail = e.target.checked
                    if (e.target.checked) {
                      delete toSet.isUrl
                      // delete toSet.isPostalCode
                    }
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is email?</Trans>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isUrl)}
                  disabled={readOnly || isAdvancedTextEditor}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isUrl = e.target.checked
                    if (e.target.checked) {
                      delete toSet.isEmail
                      // delete toSet.isPostalCode
                    }
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is url?</Trans>}
            />

            <TextField
              value={regexValidation || ''}
              disabled={readOnly}
              style={{ marginTop: 5, marginBottom: 5 }}
              fullWidth
              label={<Trans>Regex validation</Trans>}
              variant='outlined'
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.regexValidation = e.target.value
                if (!e.target.value) {
                  delete toSet.regexValidationMessageEN
                  delete toSet.regexValidationMessageFR
                }
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />

            {regexValidation && (
              <TextField
                value={regexValidationMessageEN || ''}
                disabled={readOnly}
                style={{ marginTop: 5, marginBottom: 5 }}
                fullWidth
                label={<Trans>Regex validation message EN</Trans>}
                variant='outlined'
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.regexValidationMessageEN = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            )}

            {regexValidation && (
              <TextField
                value={regexValidationMessageFR || ''}
                disabled={readOnly}
                style={{ marginTop: 5, marginBottom: 5 }}
                fullWidth
                label={<Trans>Regex validation message FR</Trans>}
                variant='outlined'
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.regexValidationMessageFR = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(isAdvancedTextEditor)}
                  disabled={readOnly}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.isAdvancedTextEditor = e.target.checked
                    delete toSet.showFieldLabel
                    delete toSet.expandable
                    delete toSet.isEmail
                    delete toSet.isUrl
                    toSet.rows = 1
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Is an andvanced editor?</Trans>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(showFieldLabel)}
                  disabled={readOnly || isAdvancedTextEditor}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.showFieldLabel = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show label in field?</Trans>}
            />

            <Grid container alignItems='center'>
              <Grid item xs style={{ padding: 5 }}>
                <TextField
                  value={rows || '1'}
                  disabled={readOnly || isAdvancedTextEditor}
                  style={{ marginTop: 5, marginBottom: 5 }}
                  fullWidth
                  label={<Trans>Rows</Trans>}
                  InputProps={{ inputComponent: NumberFormatCustom }}
                  variant='outlined'
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.rows = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </Grid>

              <Grid item style={{ paddingLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={Boolean(isAdvancedTextEditor || readOnly)}
                      checked={Boolean(expandable)}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet.expandable = e.target.checked
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={<Trans>Allow expanding rows?</Trans>}
                />
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly}
                  checked={Boolean(wordCount)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.wordCount = e.target.checked
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Show word count?</Trans>}
            />

            <FormControlLabel
              control={
                <Checkbox
                  disabled={readOnly}
                  checked={Boolean(lengthLimit)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.lengthLimit = e.target.checked
                    delete toSet.maxChar
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Custom characters limit?</Trans>}
            />
            {lengthLimit && (
              <div style={{ width: 400 }}>
                <TextField
                  style={{ marginTop: 5, marginBottom: 5 }}
                  label={<Trans>Length limit</Trans>}
                  value={maxChar}
                  InputProps={{ inputComponent: NumberFormatCustom }}
                  variant='outlined'
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.maxChar = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </div>
            )}
          </>
        )
      )}

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForTextField}
        allowReadOnlyFields
        clearOnDisconnect={['isUrl', 'isEmail', 'requiresRequest']}
        clearOnReadOnlyField={[
          'wordCount',
          'required',
          'rows',
          'lengthLimit',
          'maxChar',
          'showFieldLabel',
          'expandable',
          'requiresRequest'
        ]}
      />
      {Boolean(
        isConnected && connectedField && !forceReadOnly && !showPdfProps
      ) && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(requiresRequest)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.requiresRequest = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            />
          }
          label={<Trans>Requires request to change value</Trans>}
        />
      )}
    </Grid>
  )
}

function PostalCodeFormat (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      format='###-###-####'
      mask='_'
      isNumericString
      // type='tel'
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

function NumberFormatCustom (props) {
  const { inputRef, onChange, id, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      defaultValue=''
      thousandSeparator=','
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}
