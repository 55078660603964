import SFAuthService, { NO_USER } from '../SFAuthService'
import { isSandbox } from '../sfAuthConfig'
import { mapSFToForm } from '../sfDataService'
import { SF_API_VERSION } from './sfOpportunity'
import { surveyToRTF } from '../../../utils/survey/surveyToRTF'
import { satScoreCard } from '../../../views/surveys/surveyScoreCard'
import { satScoreCardCoop } from '../../../views/surveys/surveyScoreCardCoop'
import { satScoreCardOSBL } from '../../../views/surveys/surveyScoreCardOSBL'
import { getTitleTranslation } from 'utils'
import { CONFIGURATION_SALESFORCE_ID } from '../../../config/configurationMetadata'
import moment from 'moment'

const joinTemplateAndItemFields = [
  'Id',
  'Item_Options__c',
  'Order__c',
  'Parent__c',
  'Survey_Item__c',
  'Survey_Template__c',
  'Tag__c'
]

export const textFields = [
  'Id',
  'Extended__c',
  'Language__c',
  'Survey_Translation__c',
  'Text__c',
  'Tooltip__c'
]

const itemFields = ['Id', 'Text__c', 'Type__c']

const translationFields = ['Id']

const detailFields = [
  'Id',
  'Answer_Text__c',
  'API_value__c',
  'Item__c',
  'Details__c',
  'Order__c'
]

const templateFields = ['Id', 'Name', 'Title_Translation__c']

const joinTemplateAndTemplateFields = [
  'Id',
  'Child_Templete__c',
  'Order__c',
  'Parent_Template__c'
]

export const NO_TEMPLATES = 'NO_TEMPLATES'

export const surveyItemOptionsFields = [
  'conditionalObject',
  'allowRowsToExpand',
  'conditionalObjectSubfield',
  'conditionalObjectName',
  'conditionalObjectField',
  'conditionForObjectField',
  'isLinked',
  'linkedObject',
  'linkedField',
  'createCase',
  'caseType',
  'tooltipAtBottom',
  'hasTooltip',
  'isRequired',
  'nonApplicable',
  'skipInExport',
  'skipInPrint',
  'isGroup',
  'rows',
  'min',
  'max',
  'maxCharacters',
  'validationRules',
  'fileDescription',
  'showDescription'
]

export const findSurveyTemplatesWithChildren = (props = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return findTemplates(props).then(templates => {
    const toRet = {}
    console.log('found templates', templates)
    templates.forEach(result => {
      const template = result.template
      if (template.Survey_Template_Joins_Survey_Template1__r === null) {
        toRet[template.Id] = {
          id: template.Id,
          translations: result.titleTranslation,
          name:
            getTitleTranslation(props.language, result.titleTranslation) ||
            template.Name,
          children: []
        }
      }
    })
    templates.forEach(result => {
      const template = result.template
      if (template.Survey_Template_Joins_Survey_Template1__r !== null) {
        const join =
          template.Survey_Template_Joins_Survey_Template1__r.records[0]
        const order = join.Order__c
        toRet[join.Parent_Template__c].children.push({
          id: template.Id,
          order,
          join: join,
          translations: result.titleTranslation,
          name:
            getTitleTranslation(props.language, result.titleTranslation) ||
            template.Name
        })
      }
    })
    return toRet
  })
}

export const findTemplates = (props = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const { searchParams = {}, permissions = {} } = props
  let query = conn
    .sobject('Survey_Templates__c')
    .find({ ...searchParams })
    .select(templateFields.join(', '))
    .include('Survey_Joins_Template_and_Question__r')
    .select(joinTemplateAndItemFields.join(', '))
    .end()
    .include('Survey_Template_Joins_Survey_Template1__r')
    .select(joinTemplateAndTemplateFields.join(', '))
    .end()
    .include('Survey_Template_Joins_Survey_Template__r')
    .select(joinTemplateAndTemplateFields.join(', '))
    .end()
  if (permissions.SCORE_CARDS) {
    query = query.include('Score_Cards__r').end()
  }
  return query.run({ autoFetch: true, maxFetch: 1000 }).then(result => {
    const toFind = []
    const matchObj = {}
    result.forEach(template => {
      toFind.push(template.Title_Translation__c)
      if (template.Score_Cards__r) {
        template.Score_Cards__r.records.forEach(record => {
          if (!toFind.includes(record.Title_Translation__c)) {
            toFind.push(record.Title_Translation__c)
          }
        })
      }
      matchObj[template.Title_Translation__c] = {
        template: template,
        titleTranslation: null
      }
    })
    if (toFind.length === 0) {
      return Promise.reject(NO_TEMPLATES)
    }
    return conn
      .sobject('SurveyTranslation__c')
      .find({ Id: toFind })
      .select(translationFields.join(', '))
      .include('Survey_Texts__r')
      .select(textFields.join(', '))
      .end()
      .then(translations => {
        const toReturn = []
        const matchScoreCardsTranslations = {}
        translations.forEach(trans => {
          if (matchObj[trans.Id]) {
            matchObj[trans.Id].titleTranslation = trans
          } else {
            matchScoreCardsTranslations[trans.Id] = trans
          }
        })
        for (const key in matchObj) {
          if (Object.hasOwnProperty.call(matchObj, key)) {
            const element = matchObj[key]
            const template = element.template
            if (template.Score_Cards__r) {
              template.Score_Cards__r.records.forEach(record => {
                record.Title_Translation__c =
                  matchScoreCardsTranslations[record.Title_Translation__c]
              })
            }
            toReturn.push(element)
          }
        }
        return toReturn
      })
  })
}

export const fetchOtherTemplates = (excludeIds = []) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Survey_Templates__c')
    .find({})
    .select('Name, Id, Title_Translation__c')
    .include('Survey_Template_Joins_Survey_Template1__r')
    .select(joinTemplateAndTemplateFields.join(', '))
    .end()
    .run({ autoFetch: true, maxFetch: 1000 })
    .then(result => {
      const toFind = []
      const matchObj = {}
      result.forEach(template => {
        if (!excludeIds.includes(template.Id)) {
          toFind.push(template.Title_Translation__c)
          matchObj[template.Title_Translation__c] = {
            template: template,
            en: null,
            fr: null
          }
        }
      })
      return conn
        .sobject('SurveyTranslation__c')
        .find({ Id: toFind })
        .select(translationFields.join(', '))
        .include('Survey_Texts__r')
        .select(textFields.join(', '))
        .end()
        .then(result => {
          const matchWithTranslation = []
          result.forEach(trans => {
            trans.Survey_Texts__r.records.forEach(subtrans => {
              if (subtrans.Language__c === 'en-ca') {
                matchObj[trans.Id].en = subtrans.Text__c
              } else if (subtrans.Language__c === 'fr-ca') {
                matchObj[trans.Id].fr = subtrans.Text__c
              }
            })
          })
          for (const key in matchObj) {
            if (Object.hasOwnProperty.call(matchObj, key)) {
              const element = matchObj[key]
              matchWithTranslation.push(element)
            }
          }
          const toReturn = {}
          matchWithTranslation.forEach(template => {
            if (!template.template.Survey_Template_Joins_Survey_Template1__r) {
              const id = template.template.Id
              if (!toReturn[id]) {
                toReturn[id] = {
                  ...template,
                  childTemplates: []
                }
              } else {
                toReturn[id] = {
                  ...toReturn[id],
                  ...template
                }
              }
            }
            if (template.template.Survey_Template_Joins_Survey_Template1__r) {
              const join =
                template.template.Survey_Template_Joins_Survey_Template1__r
                  .records[0]
              const parentId = join.Parent_Template__c
              const order = join.Order__c
              if (toReturn[parentId]) {
                toReturn[parentId].childTemplates.push({
                  ...template,
                  order: order
                })
                toReturn[parentId].childTemplates.sort(
                  (a, b) => a.order - b.order
                )
              } else {
                toReturn[parentId] = {
                  childTemplates: [{ ...template, order: order }]
                }
              }
            }
          })
          return toReturn
        })
    })
}

export const findCompletedSurveys = (templateId, searchProps = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  console.log('completed surveys search props', searchProps, templateId)
  return conn
    .sobject('Survey__c')
    .find({
      Template__c:
        typeof templateId === 'object' ? { $in: templateId } : templateId,
      SurveyState__c: 'Submitted',
      ...searchProps
    })
    .then(
      result => {
        result = result.map(user => mapSFToForm(mapFields, user))
        result.sort(
          (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
        )
        return result
      },
      reject => {
        console.log(reject)
      }
    )
}

export const findTemplateById = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Survey_Templates__c')
    .find({
      Id: id
    })
    .select(templateFields.join(', '))
    .then(res => {
      return res[0]
    })
}

export const createSurvey = (nameEN, nameFR) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('SurveyTranslation__c')
    .create({})
    .then(translation => {
      const transTextFR = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: nameFR,
        Language__c: 'fr-ca'
      })
      const transTextEN = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: nameEN,
        Language__c: 'en-ca'
      })
      return conn.sobject('Survey_Templates__c').create(
        {
          Name: nameEN,
          Title_Translation__c: translation.id
        },
        function (err, ret) {
          if (err || !ret.success) {
            return console.error(err, ret)
          }
        }
      )
    })
}

export const createSubsurvey = (parentId, nameEN, nameFR, childCount = 0) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('SurveyTranslation__c')
    .create({})
    .then(translation => {
      const transTextFR = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: nameFR,
        Language__c: 'fr-ca'
      })
      const transTextEN = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Text__c: nameEN,
        Language__c: 'en-ca'
      })
      return Promise.all([
        transTextEN,
        transTextFR,
        conn.sobject('Survey_Templates__c').create(
          {
            Name: nameEN,
            Title_Translation__c: translation.id
          },
          function (err, ret) {
            if (err || !ret.success) {
              return console.error(err, ret)
            }
            // console.log('Created record id : ' + ret.id)
            return conn
              .sobject('Survey_Template_Join_Survey_Template__c')
              .create({
                Parent_Template__c: parentId,
                Order__c: childCount,
                Child_Templete__c: ret.id
              })
          }
        )
      ])
    })
}

const questionDetailsKeys = [
  'rows',
  'max',
  'min',
  'createdId',
  'tooltipAtBottom',
  'showDescription',
  'fileDescription',
  'hasTooltip',
  'mainTemplate',
  'conditionalObject',
  'conditionalObjectSubfield',
  'conditionalObjectName',
  'conditionalObjectField',
  'conditionForObjectField',
  'isLinked',
  'linkedObject',
  'linkedField',
  'isRequired',
  'isGroup',
  'conditions',
  'validationRules',
  'externalConditions',
  'conditionsToCheck',
  'index',
  'parentQuestionId',
  'parentQuestionIndex',
  'optionsWithRequiredDetails'
]

export const duplicateSurveyTemplate = data => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }

  return duplicateTemplate(data.id).then(duplicateId => {
    if (data.children.length === 0) {
      return Promise.resolve()
    } else {
      return Promise.all(
        data.children.map((child, index) =>
          duplicateTemplate(child.id, duplicateId, index)
        )
      )
    }
  })
}

const duplicateTemplate = (id, parentId, index = 0) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return getSurveyTemplateDetails(id).then(details => {
    const { titleDetails, questionsDetails, info } = details
    const crypto = require('crypto')
    const oldQuestionIdToNewId = {}
    questionsDetails.forEach(question => {
      const { itemOptions } = question
      oldQuestionIdToNewId[itemOptions.createdId] = crypto
        .randomBytes(20)
        .toString('hex')
    })
    return conn
      .sobject('SurveyTranslation__c')
      .create({})
      .then(translation => {
        const transTextFR = conn.sobject('Survey_Text__c').create({
          Survey_Translation__c: translation.id,
          Tooltip__c: titleDetails.tooltipFR,
          Text__c: titleDetails.fr + ' duplicate',
          Extended__c: titleDetails.extendedFR,
          Language__c: 'fr-ca'
        })
        const transTextEN = conn.sobject('Survey_Text__c').create({
          Survey_Translation__c: translation.id,
          Tooltip__c: titleDetails.tooltipEN,
          Text__c: titleDetails.en + ' duplicate',
          Extended__c: titleDetails.extendedEN,
          Language__c: 'en-ca'
        })
        return conn
          .sobject('Survey_Templates__c')
          .create({
            Name: info.surveyName,
            Title_Translation__c: translation.id,
            Template_Options__c: JSON.stringify(details.templateOptions || {})
          })
          .then(templateCreatedResult => {
            const questionPromises = []

            if (parentId) {
              questionPromises.push(
                conn.sobject('Survey_Template_Join_Survey_Template__c').create({
                  Parent_Template__c: parentId,
                  Order__c: index,
                  Child_Templete__c: templateCreatedResult.id
                })
              )
            }

            questionsDetails.forEach((question, qIndex) => {
              const {
                item,
                itemDetails,
                itemOptions,
                itemTranslation,
                order
              } = question
              const questionPromise = conn
                .sobject('SurveyTranslation__c')
                .create({})
                .then(questionTrans => {
                  const questionFR = conn.sobject('Survey_Text__c').create({
                    Survey_Translation__c: questionTrans.id,
                    Text__c: itemTranslation.fr,
                    Extended__c: itemTranslation.extendedFR,
                    Tooltip__c: itemTranslation.tooltipFR,
                    Language__c: 'fr-ca'
                  })
                  const questionEN = conn.sobject('Survey_Text__c').create({
                    Survey_Translation__c: questionTrans.id,
                    Text__c: itemTranslation.en,
                    Extended__c: itemTranslation.extendedEN,
                    Tooltip__c: itemTranslation.tooltipEN,
                    Language__c: 'en-ca'
                  })
                  const surveyItem = conn
                    .sobject('Survey_Items__c')
                    .create({
                      Type__c: item.Type__c,
                      Text__c: questionTrans.id
                    })
                    .then(item => {
                      const promises = []
                      itemDetails.forEach((option, index) => {
                        const details = JSON.stringify({
                          clearOnSelect: option.clearOnSelect
                        })
                        promises.push(
                          conn
                            .sobject('SurveyTranslation__c')
                            .create({})
                            .then(optionTrans => {
                              const promiseDetail = conn
                                .sobject('SurveyItemDetail__c')
                                .create({
                                  Answer_Text__c: optionTrans.id,
                                  API_value__c: option.apiValue,
                                  Item__c: item.id,
                                  Order__c: index,
                                  Details__c: details
                                })
                              const optionEN = conn
                                .sobject('Survey_Text__c')
                                .create({
                                  Survey_Translation__c: optionTrans.id,
                                  Text__c: option.en,
                                  Language__c: 'en-ca'
                                })
                              const optionFR = conn
                                .sobject('Survey_Text__c')
                                .create({
                                  Survey_Translation__c: optionTrans.id,
                                  Text__c: option.fr,
                                  Language__c: 'fr-ca'
                                })
                              return Promise.all([
                                optionFR,
                                optionEN,
                                promiseDetail
                              ])
                            })
                        )
                      })
                      const options = {}
                      questionDetailsKeys.forEach(key => {
                        options[key] = itemOptions[key]
                      })
                      const createdId = oldQuestionIdToNewId[options.createdId]
                      if (options.parentQuestionId) {
                        options.parentQuestionId =
                          oldQuestionIdToNewId[options.parentQuestionId]
                      }
                      const promiseJoin = conn
                        .sobject('Survey_Join_Template_and_Item__c')
                        .create({
                          Survey_Item__c: item.id,
                          Survey_Template__c: templateCreatedResult.id,
                          Order__c: order,
                          Item_Options__c: JSON.stringify({
                            ...options,
                            parentTemplateName: {
                              en: titleDetails.en + ' duplicate',
                              fr: titleDetails.fr + ' duplicate'
                            },
                            subquestions: [
                              ...itemDetails.map(item => item.itemId)
                            ],
                            createdId,
                            conditions: options.conditions || [],
                            validationRules: options.validationRules || [],
                            externalConditions:
                              options.externalConditions || [],
                            conditionsToCheck: options.conditionsToCheck || []
                          })
                        })
                      promises.push(promiseJoin)
                      return Promise.all(promises)
                    })
                  return Promise.all([surveyItem, questionEN, questionFR])
                })
              questionPromises.push(questionPromise)
            })

            return Promise.all(questionPromises).then(
              r => templateCreatedResult.id
            )
          })
      })
  })
}

export const deleteSurveyTemplate = (id, childIds = null) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  let toDestroy = [id]
  if (childIds) {
    toDestroy = [id, ...childIds]
  }
  return getDeleteIds(toDestroy).then(idsArray => {
    console.log('to delete', idsArray)
    let requests = []
    if (idsArray.length < 200) {
      requests = [
        {
          method: 'DELETE',
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            'ids=' +
            idsArray.join(',') +
            '&allOrNone=true',
          referenceId: 'refDeleteObjects'
        }
      ]
    } else if (idsArray.length < 1000) {
      const subArrays = new Array(Math.ceil(idsArray.length / 200))
        .fill()
        .map(_ => idsArray.splice(0, 200))
      subArrays.forEach((sub, index) => {
        requests.push({
          method: 'DELETE',
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            'ids=' +
            sub.join(',') +
            '&allOrNone=true',
          referenceId: 'refDeleteObjects' + index
        })
      })
    } else {
      return Promise.all(
        new Array(Math.ceil(idsArray.length / 1000))
          .fill()
          .map(_ => idsArray.splice(0, 1000))
          .map(thousandArray => {
            const subArrays = new Array(Math.ceil(thousandArray.length / 200))
              .fill()
              .map(_ => thousandArray.splice(0, 200))
            subArrays.forEach((sub, index) => {
              requests.push({
                method: 'DELETE',
                url:
                  `/services/data/${SF_API_VERSION}/composite/sobjects?` +
                  'ids=' +
                  sub.join(',') +
                  '&allOrNone=true',
                referenceId: 'refDeleteObjects' + index
              })
            })
            return conn.requestPost(
              `/services/data/${SF_API_VERSION}/composite/`,
              {
                allOrNone: true,
                compositeRequest: requests
              }
            )
          })
      )
    }
    return conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
      allOrNone: true,
      compositeRequest: requests
    })
  })
}

export const changeSurveyLock = ({
  surveyId,
  userId,
  lockState,
  lockedTooLong
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Survey_Lock_Unlock_SAT', {
      inputs: [
        {
          surveyId,
          lockState,
          userId,
          lockedTooLong
        }
      ]
    })
    .then(flow => flow[0].outputValues)
}

export const fetchGranteeReportSurveys = ({ opportunities, templatesIDs }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Survey_Get_Surveys', {
      inputs: opportunities.map(id => ({ templatesIDs, opportunityId: id }))
    })
    .then(flowResult => {
      const toRet = []
      flowResult.forEach(res => {
        if (res && res.outputValues.result) {
          toRet.push(...res.outputValues.result)
        }
      })
      return toRet
    })
}

export const fetchSATs = ({ accountId, templateIDs }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Survey_Get_SAT', {
      inputs: [
        {
          accountId,
          templateIDs
        }
      ]
    })
    .then(flowResult => flowResult[0].outputValues.result)
}

// export const cleanDatabase = () => {
//   const conn = SFAuthService.getConnection()
//   if (!conn) {
//     return Promise.reject(NO_USER)
//   }
//   const promises = []
//   const checkTextsWithoutParent = conn
//     .sobject('Survey_Text__c')
//     .find({
//       Survey_Translation__c: null
//     })
//     .destroy()
//   promises.push(checkTextsWithoutParent)
//   // const checkTranslationsWithoutTexts = conn
//   //   .sobject('SurveyTranslation__c')
//   //   .find({
//   //     Survey_Texts__r: null
//   //   })
//   //   .destroy()
//   // promises.push(checkTranslationsWithoutTexts)
//   // const chechSurveysWithoutTemplate = conn
//   //   .sobject('Survey__c')
//   //   .find({
//   //     Template__c: null
//   //   })
//   //   .destroy()
//   // promises.push(chechSurveysWithoutTemplate)
//   const checkTemplatesWithoutParent = conn
//     .sobject('Survey_Template_Join_Survey_Template__c')
//     .find({
//       Parent_Template__c: null
//     })
//     .destroy()
//   promises.push(checkTemplatesWithoutParent)
//   const checkTemplateJoins = conn
//     .sobject('Survey_Template_Join_Survey_Template__c')
//     .find({
//       Child_Templete__c: null
//     })
//     .destroy()
//   promises.push(checkTemplateJoins)
//   const checkDetailsWithoutParent = conn
//     .sobject('SurveyItemDetail__c')
//     .find({ Answer_Text__c: null })
//     .destroy()
//   promises.push(checkDetailsWithoutParent)
//   const checkJoinsTemplateToItem = conn
//     .sobject('Survey_Join_Template_and_Item__c')
//     .find({
//       Survey_Item__c: null
//     })
//     .destroy()
//   promises.push(checkJoinsTemplateToItem)
//   return Promise.all(promises)
// }

export const getSurveys = (props = {}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const { searchParams = {}, searchByFlow = false } = props
  console.log('sfSurvey searchParams', searchParams)

  let promise
  if (searchByFlow) {
    promise = conn
      .requestPost('/actions/custom/flow/App_Get_Grantee_Report_Survey', {
        inputs: [
          {
            id: searchParams.Id,
            reportId: searchParams.Template__c,
            templateId: searchParams.Report__c
          }
        ]
      })
      .then(flowResult => flowResult[0].outputValues.result)
  } else {
    promise = conn
      .sobject('Survey__c')
      .find({ ...searchParams })
      .select('*, Template__r.Category__c, Owner.Id')
  }

  return promise.then(surveys => {
    const templatesIds = []
    surveys.forEach(survey => {
      if (!templatesIds.includes(survey.Template__c)) {
        templatesIds.push(survey.Template__c)
      }
    })
    return conn
      .sobject('Survey_Templates__c')
      .find({
        Id: { $in: templatesIds }
      })
      .select(templateFields.join(', '))
      .then(templates => {
        const translationsIds = []
        const transObj = {}
        templates.forEach(template => {
          if (!translationsIds.includes(template.Title_Translation__c)) {
            transObj[template.Title_Translation__c] = template.Id
            translationsIds.push(template.Title_Translation__c)
          }
        })
        return conn
          .sobject('SurveyTranslation__c')
          .find({
            Id: { $in: translationsIds }
          })
          .select(translationFields.join(', '))
          .include('Survey_Texts__r')
          .select(textFields.join(', '))
          .end()
          .then(translations => {
            const matchWithSurvey = {}
            translations.forEach(trans => {
              const templateId = transObj[trans.Id]
              if (templateId) {
                matchWithSurvey[templateId] = trans
              }
            })
            return surveys.map(survey => {
              return {
                titleTranslation: matchWithSurvey[survey.Template__c],
                survey: survey
              }
            })
          })
      })
  })
}

export const getSiblings = templateId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Survey_Template_Join_Survey_Template__c')
    .find({
      Child_Templete__c: templateId
    })
    .select(joinTemplateAndTemplateFields.join(', '))
    .then(result => {
      const parentId = result[0].Parent_Template__c
      return conn
        .sobject('Survey_Template_Join_Survey_Template__c')
        .find({
          Parent_Template__c: parentId
        })
        .select(joinTemplateAndTemplateFields.join(', '))
        .then(joins => {
          const returnTemplates = []
          joins.forEach(join => {
            if (join.Child_Templete__c !== templateId) {
              returnTemplates.push({ id: join.Child_Templete__c })
            }
          })
          return { templates: returnTemplates, parentId: parentId }
        })
    })
}

export const getSubtemplates = templateId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }

  return conn
    .sobject('Survey_Template_Join_Survey_Template__c')
    .find({
      Parent_Template__c: templateId
    })
    .select(joinTemplateAndTemplateFields.join(', '))
    .then(result => {
      const toReturn = []
      result.forEach(join => {
        toReturn.push({
          id: join.Child_Templete__c,
          order: join.Order__c
        })
      })
      return { templates: toReturn, parentId: null }
    })
}

export const getSurveyTemplateDetails = templateId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  // const templatePromise = conn
  //   .sobject('Survey_Templates__c')
  //   .retrieve(templateId)
  // const joinPromise = conn
  //   .sobject('Survey_Template_Join_Survey_Template__c')
  //   .find({
  //     Child_Templete__c: templateId
  //   })
  return (
    conn
      .sobject('Survey_Templates__c')
      .find({ Id: templateId })
      .include('Survey_Template_Joins_Survey_Template1__r')
      .select(joinTemplateAndTemplateFields.join(', '))
      .end()
      // .include('Survey_Template_Joins_Survey_Template__r')
      // .select(joinTemplateAndTemplateFields.join(', '))
      // .end()
      .then(result => {
        const template = result[0]
        return conn
          .sobject('Survey_Join_Template_and_Item__c')
          .find({
            Survey_Template__c: templateId
          })
          .select(joinTemplateAndItemFields.join(', '))
          .then(joins => {
            const detailsData = []
            const translationData = []
            translationData.push(template.Title_Translation__c)
            const itemsData = []
            joins.forEach((join, index) => {
              if (!itemsData.includes(join.Survey_Item__c)) {
                itemsData.push(join.Survey_Item__c)
              }
            })
            const returnObj = {}
            if (itemsData.length < 1) {
              // STOP EXECUTING PROMISES IF TEMPLATE HAS NO ITEMS
              return conn
                .sobject('SurveyTranslation__c')
                .find({
                  Id: template.Title_Translation__c
                })
                .select('Id')
                .include('Survey_Texts__r')
                .select(textFields.join(', '))
                .end()
                .run({ autoFetch: true, maxFetch: 1000 })
                .then(titleTranslation => {
                  returnObj.questionsDetails = []
                  returnObj.templateOptions = template.Template_Options__c
                    ? JSON.parse(template.Template_Options__c)
                    : {}
                  returnObj.info = {
                    surveyName: template.Name,
                    templateId: template.Id,
                    order:
                      template.Survey_Template_Joins_Survey_Template1__r &&
                      template.Survey_Template_Joins_Survey_Template1__r
                        .records[0].Order__c,
                    mainTemplate:
                      template.Survey_Template_Joins_Survey_Template1__r &&
                      template.Survey_Template_Joins_Survey_Template1__r
                        .records[0].Parent_Template__c,
                    templateCreatedDate: template.CreatedDate
                  }
                  returnObj.titleDetails = getDetailsParsed([
                    titleTranslation
                  ])[0]
                  return returnObj
                })
            }
            return conn
              .sobject('Survey_Items__c')
              .find({
                Id: itemsData
              })
              .select(itemFields.join(', '))
              .include('Survey_Joins_Template_and_Question__r')
              .select(joinTemplateAndItemFields.join(', '))
              .end()
              .run({ autoFetch: true, maxFetch: 1000 })
              .then(items => {
                items.forEach(item => {
                  detailsData.push(item.Id)
                  translationData.push(item.Text__c)
                })
                return conn
                  .sobject('SurveyItemDetail__c')
                  .find({
                    Item__c: detailsData
                  })
                  .select(detailFields.join(', '))
                  .then(details => {
                    details.forEach(detail => {
                      translationData.push(detail.Answer_Text__c)
                    })
                    return conn
                      .sobject('SurveyTranslation__c')
                      .find({
                        Id: translationData
                      })
                      .select(translationFields.join(', '))
                      .include('SurveyFieldTranslation__r')
                      .select(detailFields.join(', '))
                      .end()
                      .include('Survey_Texts__r')
                      .select(textFields.join(', '))
                      .end()
                      .include('Survey_Items__r')
                      .select(itemFields.join(', '))
                      .end()
                      .include('Survey_Templates2__r')
                      .select(templateFields.join(', '))
                      .end()
                      .run({ autoFetch: true, maxFetch: 1000 })
                      .then(translations => {
                        translations.forEach(translation => {
                          translation.Survey_Texts__r.records.forEach(
                            text => {}
                          )
                        })
                        const parsedObj = matchSurveyObjects(
                          translations,
                          items,
                          details,
                          template.Id
                        )
                        // returnObj.deleteData = deleteData
                        returnObj.questionsDetails = parsedObj.list
                        returnObj.templateOptions = template.Template_Options__c
                          ? JSON.parse(template.Template_Options__c)
                          : {}
                        returnObj.info = {
                          surveyName: template.Name,
                          templateId: template.Id,
                          templateCreatedDate: template.CreatedDate,
                          order:
                            template.Survey_Template_Joins_Survey_Template1__r &&
                            template.Survey_Template_Joins_Survey_Template1__r
                              .records[0].Order__c,
                          mainTemplate:
                            template.Survey_Template_Joins_Survey_Template1__r &&
                            template.Survey_Template_Joins_Survey_Template1__r
                              .records[0].Parent_Template__c
                        }
                        returnObj.titleDetails = parsedObj.titleTranslation
                        return returnObj
                      })
                  })
              })
          })
      })
  )
}

export const getDeleteIds = templateIds => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const deleteData = []
  const searchArray = []
  templateIds.forEach(id => {
    searchArray.push({ Survey_Template__c: id })
  })
  return conn
    .sobject('Survey_Templates__c')
    .find({ Id: templateIds })
    .select('Id, Name, Title_Translation__c')
    .include('Survey_Template_Joins_Survey_Template1__r')
    .select(joinTemplateAndTemplateFields.join(', '))
    .end()
    .include('Survey_Template_Joins_Survey_Template__r')
    .select(joinTemplateAndTemplateFields.join(', '))
    .end()
    .then(templates => {
      return conn
        .sobject('Survey_Join_Template_and_Item__c')
        .find({
          $or: searchArray
        })
        .select(joinTemplateAndItemFields.join(', '))
        .then(joins => {
          const detailsData = []
          const translationData = []
          const itemsData = []
          templates.forEach(template => {
            translationData.push(template.Title_Translation__c)
            deleteData.push(template.Id)
            if (template.Survey_Template_Joins_Survey_Template__r) {
              template.Survey_Template_Joins_Survey_Template__r.records.forEach(
                join => {
                  if (!deleteData.includes(join.Id)) {
                    deleteData.push(join.Id)
                  }
                }
              )
            }
            if (template.Survey_Template_Joins_Survey_Template1__r) {
              template.Survey_Template_Joins_Survey_Template1__r.records.forEach(
                join => {
                  if (!deleteData.includes(join.Id)) {
                    deleteData.push(join.Id)
                  }
                }
              )
            }
          })
          joins.forEach((join, index) => {
            if (!itemsData.includes(join.Survey_Item__c)) {
              itemsData.push(join.Survey_Item__c)
            }
            deleteData.push(join.Id)
          })
          if (itemsData.length < 1) {
            // STOP EXECUTING PROMISES IF TEMPLATES HAVE NO ITEMS
            return conn
              .sobject('SurveyTranslation__c')
              .find({
                Id: translationData
              })
              .select('Id')
              .include('Survey_Texts__r')
              .select(textFields.join(', '))
              .end()
              .run({ autoFetch: true, maxFetch: 1000 })
              .then(translations => {
                translations.forEach(translation => {
                  translation.Survey_Texts__r.records.forEach(text => {
                    deleteData.push(text.Id)
                  })
                  deleteData.push(translation.Id)
                })
                return deleteData
              })
          }
          return conn
            .sobject('Survey_Items__c')
            .find({
              Id: itemsData
            })
            .select(itemFields.join(', '))
            .include('Survey_Joins_Template_and_Question__r')
            .select(joinTemplateAndItemFields.join(', '))
            .end()
            .run({ autoFetch: true, maxFetch: 1000 })
            .then(items => {
              items.forEach(item => {
                if (
                  item.Survey_Joins_Template_and_Question__r.records.length ===
                  1
                ) {
                  deleteData.push(item.Id)
                  detailsData.push(item.Id)
                  translationData.push(item.Text__c)
                }
              })
              // IF DELETING AND ONLY QUESTIONS USED IN OTHER TEMPLATES
              if (detailsData.length === 0) {
                return deleteData
              }
              return conn
                .sobject('SurveyItemDetail__c')
                .find({
                  Item__c: detailsData
                })
                .select(detailFields.join(', '))
                .then(details => {
                  details.forEach(detail => {
                    translationData.push(detail.Answer_Text__c)
                    deleteData.push(detail.Id)
                  })
                  return conn
                    .sobject('SurveyTranslation__c')
                    .find({
                      Id: translationData
                    })
                    .select(translationFields.join(', '))
                    .include('SurveyFieldTranslation__r')
                    .select(detailFields.join(', '))
                    .end()
                    .include('Survey_Texts__r')
                    .select(textFields.join(', '))
                    .end()
                    .include('Survey_Items__r')
                    .select(itemFields.join(', '))
                    .end()
                    .include('Survey_Templates2__r')
                    .select(templateFields.join(', '))
                    .end()
                    .run({ autoFetch: true, maxFetch: 1000 })
                    .then(translations => {
                      translations.forEach(translation => {
                        translation.Survey_Texts__r.records.forEach(text => {
                          deleteData.push(text.Id)
                        })
                        deleteData.push(translation.Id)
                      })
                      return deleteData
                    })
                })
            })
        })
    })
}

const matchSurveyObjects = (translations, items, details, templateId) => {
  const toReturn = []
  const matchObj = {}
  const matchItemsWithTranslation = {}
  const matchDetailsWithTranslation = {}
  const transArray = getDetailsParsed(translations)
  let titleTranslation = null
  transArray.forEach(obj => {
    if (obj.isItemTranslation) {
      matchItemsWithTranslation[obj.itemId] = obj
      return
    } else if (obj.isTitleTranslation) {
      titleTranslation = obj
      return
    }
    matchDetailsWithTranslation[obj.itemId]
      ? matchDetailsWithTranslation[obj.itemId].push(obj)
      : (matchDetailsWithTranslation[obj.itemId] = [obj])
  })

  items.forEach(item => {
    let matchJoin = null
    if (!item.Survey_Joins_Template_and_Question__r) {
      return
    }
    item.Survey_Joins_Template_and_Question__r.records.forEach(join => {
      if (join.Survey_Template__c === templateId) {
        matchJoin = join
      }
    })
    if (!matchJoin) {
      return
    }
    matchObj[item.Id] = {
      item: item,
      itemTranslation: matchItemsWithTranslation[item.Id],
      itemDetails: [],
      itemOptions: JSON.parse(matchJoin.Item_Options__c),
      order: matchJoin.Order__c,
      joinId: matchJoin.Id
    }
  })

  details.forEach(detail => {
    matchObj[detail.Item__c].itemDetails = [
      ...matchDetailsWithTranslation[detail.Item__c]
    ]
  })

  for (const key in matchObj) {
    if (Object.hasOwnProperty.call(matchObj, key)) {
      const element = matchObj[key]
      toReturn.push(element)
    }
  }

  return { list: toReturn, titleTranslation: titleTranslation }
}

const getDetailsParsed = details => {
  const toReturn = []
  details.forEach(detailArray => {
    let option = {}
    const optionSF = detailArray[0] ? detailArray[0] : detailArray
    if (!optionSF.Survey_Texts__r) {
      return
    }
    optionSF.Survey_Texts__r.records.forEach(text => {
      if (text.Language__c === 'fr-ca') {
        option.fr = text.Text__c
        option.tooltipFR = text.Tooltip__c
        option.extendedFR = text.Extended__c
        option.frTransId = text.Id
      } else if (text.Language__c === 'en-ca') {
        option.en = text.Text__c
        option.tooltipEN = text.Tooltip__c
        option.extendedEN = text.Extended__c
        option.enTransId = text.Id
      }
    })
    option.parentId = optionSF.Id
    if (optionSF.SurveyFieldTranslation__r) {
      const detailsObj = optionSF.SurveyFieldTranslation__r.records[0]
      const optionDetails = detailsObj.Details__c
        ? JSON.parse(detailsObj.Details__c)
        : {}
      option = { ...option, ...optionDetails }
      option.itemId = detailsObj.Item__c
      option.detailId = detailsObj.Id
      option.apiValue = detailsObj.API_value__c
      option.order = detailsObj.Order__c
    } else if (optionSF.Survey_Items__r) {
      option.itemId = optionSF.Survey_Items__r.records[0].Id
      option.detailId = null
      option.isItemTranslation = true
    } else if (optionSF.Survey_Templates2__r) {
      option.titleId = optionSF.Survey_Templates2__r.records[0].Id
      option.isTitleTranslation = true
    }
    toReturn.push(option)
  })
  toReturn.sort((a, b) => a.order - b.order)
  return toReturn
}

export const saveTitles = (values, titleData) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const titlePromises = []
  if (titleData) {
    titlePromises.push(
      conn.sobject('Survey_Text__c').update({
        Id: titleData.frTransId,
        Tooltip__c: values.surveyTitleFR_tooltip,
        Text__c: values.surveyTitleFR,
        Extended__c: values.surveyTitleFR_ext
      })
    )
    titlePromises.push(
      conn.sobject('Survey_Text__c').update({
        Id: titleData.enTransId,
        Tooltip__c: values.surveyTitleEN_tooltip,
        Text__c: values.surveyTitleEN,
        Extended__c: values.surveyTitleEN_ext
      })
    )
  } else {
    titlePromises.push(
      conn
        .sobject('SurveyTranslation__c')
        .create({})
        .then(translation => {
          const transTextFR = conn.sobject('Survey_Text__c').create({
            Survey_Translation__c: translation.id,
            Text__c: values.surveyTitleFR,
            Tooltip__c: values.surveyTitleFR_tooltip,
            Extended__c: values.surveyTitleFR_ext,
            Language__c: 'fr-ca'
          })
          const transTextEN = conn.sobject('Survey_Text__c').create({
            Survey_Translation__c: translation.id,
            Text__c: values.surveyTitleEN,
            Tooltip__c: values.surveyTitleEN_tooltip,
            Extended__c: values.surveyTitleEN_ext,
            Language__c: 'en-ca'
          })
          const updateSurvey = conn.sobject('Survey_Templates__c').update({
            Id: values.templateId,
            Title_Translation__c: translation.id,
            Template_Options__c: JSON.stringify({
              autosave: values.autosave,
              skipSection: values.skipSection
            })
          })
          return Promise.all([transTextFR, transTextEN, updateSurvey])
        })
    )
    return Promise.all(titlePromises)
  }
}

export const saveConditionsOnExternal = (joinId, conditionToAdd) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }

  return conn
    .sobject('Survey_Join_Template_and_Item__c')
    .retrieve(joinId)
    .then(join => {
      // console.log(join)
      const retrievedOptions = JSON.parse(join.Item_Options__c)
      retrievedOptions.conditionsToCheck.push(conditionToAdd)
      return conn.sobject('Survey_Join_Template_and_Item__c').update({
        Id: joinId,
        Item_Options__c: JSON.stringify(retrievedOptions)
      })
    })
}

// export const chooseExternalQuestion = (itemId, joinId, deleteId) => {
//   const conn = SFAuthService.getConnection()
//   if (!conn) {
//     return Promise.reject(NO_USER)
//   }
//   const requests = []
//   if (deleteId) {
//     requests.push({
//       method: 'DELETE',
//       url:
//         `/services/data/${SF_API_VERSION}/composite/sobjects?` +
//         'ids=' +
//         deleteId,
//       referenceId: 'refDeleteQuestion'
//     })
//   }
//   if (itemId && joinId) {
//     requests.push({
//       method: 'PATCH',
//       url: `/services/data/${SF_API_VERSION}/composite/sobjects/`,
//       referenceId: 'refUpdated',
//       body: {
//         allOrNone: true,
//         records: [
//           {
//             attributes: { type: 'Survey_Join_Template_and_Item__c' },
//             Id: joinId,
//             Survey_Item__c: itemId
//           }
//         ]
//       }
//     })
//   }
//   return conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
//     allOrNone: true,
//     compositeRequest: requests
//   })
// }

export const saveRearrangedTemplates = updateArray => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Survey_Template_Join_Survey_Template__c')
    .update(updateArray)
}

export const saveItems = (values, templateId) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const questionPromises = []
  let deletePromise = Promise.resolve()
  let requests = []
  if (values.toDelete) {
    const idsArray = [
      ...values.toDelete.map(item => {
        item.Id = item.id || item.Id
        return item.Id
      })
    ]
    if (idsArray < 200) {
      requests = [
        {
          method: 'DELETE',
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            'ids=' +
            idsArray.join(',') +
            '&allOrNone=true',
          referenceId: 'refDeleteObjects'
        }
      ]
    } else {
      const subArrays = new Array(Math.ceil(idsArray.length / 200))
        .fill()
        .map(_ => idsArray.splice(0, 200))
      subArrays.forEach((sub, index) => {
        requests.push({
          method: 'DELETE',
          url:
            `/services/data/${SF_API_VERSION}/composite/sobjects?` +
            'ids=' +
            sub.join(',') +
            '&allOrNone=true',
          referenceId: 'refDeleteObjects' + index
        })
      })
    }

    deletePromise = conn.requestPost(
      `/services/data/${SF_API_VERSION}/composite/`,
      {
        allOrNone: true,
        compositeRequest: requests
      }
    )
  }

  let queriesUpdated = [...values.toUpdate]
  return Promise.resolve(deletePromise).then(result => {
    values.created.forEach((question, qIndex) => {
      const groupQuestionsPromises = []
      if (question.isGroup && question.groupQuestions) {
        question.groupQuestions.forEach((subQuestion, subIndex) => {
          if (subQuestion.sfId) {
            queriesUpdated.push({
              Id: subQuestion.itemTranslation.frTransId,
              Text__c: subQuestion.fr,
              Language__c: 'fr-ca',
              type: 'Survey_Text__c'
            })
            queriesUpdated.push({
              Id: subQuestion.itemTranslation.enTransId,
              Text__c: subQuestion.en,
              Language__c: 'en-ca',
              type: 'Survey_Text__c'
            })
            queriesUpdated.push({
              Id: subQuestion.sfId,
              Type__c: question.type,
              type: 'Survey_Items__c'
            })
            if (subQuestion.joins.length === 0) {
              questionPromises.push(
                conn.sobject('Survey_Join_Template_and_Item__c').create({
                  Survey_Item__c: subQuestion.sfId,
                  Survey_Template__c: templateId,
                  Order__c: qIndex,
                  Item_Options__c: JSON.stringify({
                    parentQuestionIndex: qIndex,
                    parentQuestionId: question.id,
                    createdId: subQuestion.id,
                    index: subIndex
                  })
                })
              )
            } else {
              subQuestion.joins.forEach(join => {
                const options = JSON.parse(join.Item_Options__c)
                queriesUpdated.push({
                  Id: join.Id,
                  Order__c: qIndex,
                  Item_Options__c: JSON.stringify({
                    parentQuestionIndex: qIndex,
                    parentQuestionId: options.parentQuestionId || question.id,
                    createdId: options.createdId,
                    index: subIndex
                  }),
                  type: 'Survey_Join_Template_and_Item__c'
                })
              })
            }
          } else {
            groupQuestionsPromises.push(
              conn
                .sobject('SurveyTranslation__c')
                .create({})
                .then(questionTrans => {
                  const questionFR = conn.sobject('Survey_Text__c').create({
                    Survey_Translation__c: questionTrans.id,
                    Text__c: subQuestion.fr,
                    Language__c: 'fr-ca'
                  })
                  const questionEN = conn.sobject('Survey_Text__c').create({
                    Survey_Translation__c: questionTrans.id,
                    Text__c: subQuestion.en,
                    Language__c: 'en-ca'
                  })
                  const surveyItem = conn
                    .sobject('Survey_Items__c')
                    .create({
                      Type__c: question.type,
                      Text__c: questionTrans.id
                    })
                    .then(item => {
                      if (question.joins.length > 0) {
                        const joinPromises = []
                        question.joins.forEach(join => {
                          joinPromises.push(
                            conn
                              .sobject('Survey_Join_Template_and_Item__c')
                              .create({
                                Survey_Item__c: item.id,
                                Survey_Template__c: join.Survey_Template__c,
                                Order__c: qIndex,
                                Item_Options__c: JSON.stringify({
                                  parentQuestionIndex: qIndex,
                                  parentQuestionId: question.id,
                                  createdId: subQuestion.id,
                                  index: subIndex
                                })
                              })
                          )
                        })
                        return Promise.all(joinPromises)
                      } else {
                        return conn
                          .sobject('Survey_Join_Template_and_Item__c')
                          .create({
                            Survey_Item__c: item.id,
                            Survey_Template__c: templateId,
                            Order__c: qIndex,
                            Item_Options__c: JSON.stringify({
                              parentQuestionIndex: qIndex,
                              parentQuestionId: question.id,
                              createdId: subQuestion.id,
                              index: subIndex
                            })
                          })
                      }
                    })
                  return Promise.all([questionEN, questionFR, surveyItem])
                })
            )
          }
        })
      } else if (!question.isGroup && question.groupQuestions) {
        question.groupQuestions.forEach((subQuestion, subIndex) => {
          if (subQuestion.sfId) {
            const deleteQueries = []
            if (subQuestion.itemTranslation.frTransId) {
              deleteQueries.push(
                conn
                  .sobject('Survey_Text__c')
                  .delete(subQuestion.itemTranslation.frTransId)
              )
            }
            if (subQuestion.itemTranslation.enTransId) {
              deleteQueries.push(
                conn
                  .sobject('Survey_Text__c')
                  .delete(subQuestion.itemTranslation.enTransId)
              )
            }
            if (subQuestion.itemTranslation.parentId) {
              deleteQueries.push(
                conn
                  .sobject('SurveyTranslation__c')
                  .delete(subQuestion.itemTranslation.parentId)
              )
            }
            if (subQuestion.joinId) {
              deleteQueries.push(
                conn
                  .sobject('Survey_Join_Template_and_Item__c')
                  .delete(subQuestion.joinId)
              )
            }
            if (subQuestion.sfId) {
              deleteQueries.push(
                conn.sobject('Survey_Items__c').delete(subQuestion.sfId)
              )
            }
            groupQuestionsPromises.push(Promise.all(deleteQueries))
          }
        })
      }

      if (question.sfId) {
        queriesUpdated.push({
          Id: question.itemTranslation.frTransId,
          Text__c: question.questionFR,
          Tooltip__c: question.tooltipFR,
          Language__c: 'fr-ca',
          type: 'Survey_Text__c'
        })
        queriesUpdated.push({
          Id: question.itemTranslation.enTransId,
          Tooltip__c: question.tooltipEN,
          Text__c: question.questionEN,
          Language__c: 'en-ca',
          type: 'Survey_Text__c'
        })
        queriesUpdated.push({
          Id: question.sfId,
          Type__c: question.type,
          type: 'Survey_Items__c'
        })
        question.options.forEach((option, index) => {
          const details = JSON.stringify({
            clearOnSelect: option.clearOnSelect
          })
          if (option.parentId) {
            queriesUpdated.push({
              Id: option.enTransId,
              Text__c: option.en,
              Language__c: 'en-ca',
              type: 'Survey_Text__c'
            })
            queriesUpdated.push({
              Id: option.frTransId,
              Text__c: option.fr,
              Language__c: 'fr-ca',
              type: 'Survey_Text__c'
            })
            queriesUpdated.push({
              Id: option.detailId,
              API_value__c: option.apiValue,
              Order__c: index,
              Details__c: details,
              type: 'SurveyItemDetail__c'
            })
          } else {
            // CREATE OPTION
            questionPromises.push(
              conn
                .sobject('SurveyTranslation__c')
                .create({})
                .then(optionTrans => {
                  const promiseDetail = conn
                    .sobject('SurveyItemDetail__c')
                    .create({
                      Answer_Text__c: optionTrans.id,
                      API_value__c: option.apiValue,
                      Item__c: question.sfId,
                      Details__c: details,
                      Order__c: index
                    })
                  const optionEN = conn.sobject('Survey_Text__c').create({
                    Survey_Translation__c: optionTrans.id,
                    Text__c: option.en,
                    Language__c: 'en-ca'
                  })
                  const optionFR = conn.sobject('Survey_Text__c').create({
                    Survey_Translation__c: optionTrans.id,
                    Text__c: option.fr,
                    Language__c: 'fr-ca'
                  })
                  return Promise.all([optionFR, optionEN, promiseDetail])
                })
            )
          }
        })
        const optionsWithRequiredDetails = []
        question.options.forEach(option => {
          if (option.requireDetails) {
            optionsWithRequiredDetails.push({
              index: option.order,
              type: option.requireDetails
            })
          }
        })
        question.joins.forEach(join => {
          const options = JSON.parse(join.Item_Options__c)
          const updateObj = {
            optionsWithRequiredDetails: optionsWithRequiredDetails,
            createdId: options.createdId, /// options.createdId, // question.id, //
            mainTemplate: options.mainTemplate,
            parentTemplateName: options.parentTemplateName || {
              en: null,
              fr: null
            },
            conditions: options.conditions || [],
            externalConditions: options.externalConditions || [],
            conditionsToCheck: options.conditionsToCheck || []
          }
          surveyItemOptionsFields.forEach(field => {
            updateObj[field] = question[field]
          })
          if (join.Id === question.joinId) {
            updateObj.parentTemplateName = {
              en: values.surveyTitleEN,
              fr: values.surveyTitleFR
            }
            updateObj.mainTemplate = values.mainTemplate
            updateObj.conditions = question.conditions
            updateObj.externalConditions = question.externalConditions
            updateObj.conditionsToCheck = question.conditionsToCheck
          }
          queriesUpdated.push({
            Id: join.Id,
            Order__c: join.Id === question.joinId ? qIndex : join.Order__c,
            Item_Options__c: JSON.stringify(updateObj),
            type: 'Survey_Join_Template_and_Item__c'
          })
        })
        if (question.joins.length === 0) {
          questionPromises.push(
            conn.sobject('Survey_Join_Template_and_Item__c').create({
              Survey_Item__c: question.sfId,
              Survey_Template__c: templateId,
              Order__c: qIndex,
              Item_Options__c: JSON.stringify({
                optionsWithRequiredDetails: optionsWithRequiredDetails,
                tooltipAtBottom: question.tooltipAtBottom,
                fileDescription: question.fileDescription,
                showDescription: question.showDescription,
                hasTooltip: question.hasTooltip,
                mainTemplate: values.mainTemplate,
                conditionalObject: question.conditionalObject,
                conditionalObjectSubfield: question.conditionalObjectSubfield,
                conditionalObjectName: question.conditionalObjectName,
                conditionalObjectField: question.conditionalObjectField,
                conditionForObjectField: question.conditionForObjectField,
                isLinked: question.isLinked,
                linkedObject: question.linkedObject,
                linkedField: question.linkedField,
                isRequired: question.isRequired,
                validationRules: question.validationRules,
                rows: question.rows,
                max: question.max,
                min: question.min,
                parentTemplateName: {
                  en: values.surveyTitleEN,
                  fr: values.surveyTitleFR
                },
                // subquestions: [
                //   ...question.groupQuestions.map(item => item.sfId)
                // ],
                createdId: question.id,
                conditions: question.conditions,
                isGroup: question.isGroup,
                externalConditions: question.externalConditions,
                conditionsToCheck: question.conditionsToCheck
              })
            })
          )
        }
      } else {
        const questionPromise = conn
          .sobject('SurveyTranslation__c')
          .create({})
          .then(questionTrans => {
            const questionFR = conn.sobject('Survey_Text__c').create({
              Survey_Translation__c: questionTrans.id,
              Text__c: question.questionFR,
              Tooltip__c: question.tooltipFR,
              Language__c: 'fr-ca'
            })
            const questionEN = conn.sobject('Survey_Text__c').create({
              Survey_Translation__c: questionTrans.id,
              Text__c: question.questionEN,
              Tooltip__c: question.tooltipEN,
              Language__c: 'en-ca'
            })
            const surveyItem = conn
              .sobject('Survey_Items__c')
              .create({
                Type__c: question.type,
                Text__c: questionTrans.id
              })
              .then(item => {
                const promises = []
                question.options.forEach((option, index) => {
                  const details = JSON.stringify({
                    clearOnSelect: option.clearOnSelect
                  })
                  promises.push(
                    conn
                      .sobject('SurveyTranslation__c')
                      .create({})
                      .then(optionTrans => {
                        const promiseDetail = conn
                          .sobject('SurveyItemDetail__c')
                          .create({
                            Answer_Text__c: optionTrans.id,
                            API_value__c: option.apiValue,
                            Item__c: item.id,
                            Order__c: index,
                            Details__c: details
                          })
                        const optionEN = conn.sobject('Survey_Text__c').create({
                          Survey_Translation__c: optionTrans.id,
                          Text__c: option.en,
                          Language__c: 'en-ca'
                        })
                        const optionFR = conn.sobject('Survey_Text__c').create({
                          Survey_Translation__c: optionTrans.id,
                          Text__c: option.fr,
                          Language__c: 'fr-ca'
                        })
                        return Promise.all([optionFR, optionEN, promiseDetail])
                      })
                  )
                })
                const optionsWithRequiredDetails = []
                question.options.forEach(option => {
                  if (option.requireDetails) {
                    optionsWithRequiredDetails.push({
                      index: option.order,
                      type: option.requireDetails
                    })
                  }
                })
                const promiseJoin = conn
                  .sobject('Survey_Join_Template_and_Item__c')
                  .create({
                    Survey_Item__c: item.id,
                    Survey_Template__c: templateId,
                    Order__c: qIndex,
                    Item_Options__c: JSON.stringify({
                      optionsWithRequiredDetails: optionsWithRequiredDetails,
                      tooltipAtBottom: question.tooltipAtBottom,
                      showDescription: question.showDescription,
                      fileDescription: question.fileDescription,
                      hasTooltip: question.hasTooltip,
                      mainTemplate: values.mainTemplate,
                      conditionalObject: question.conditionalObject,
                      conditionalObjectSubfield:
                        question.conditionalObjectSubfield,
                      conditionalObjectName: question.conditionalObjectName,
                      conditionalObjectField: question.conditionalObjectField,
                      conditionForObjectField: question.conditionForObjectField,
                      isLinked: question.isLinked,
                      linkedObject: question.linkedObject,
                      linkedField: question.linkedField,
                      isRequired: question.isRequired,
                      parentTemplateName: {
                        en: values.surveyTitleEN,
                        fr: values.surveyTitleFR
                      },
                      subquestions: [
                        ...question.groupQuestions.map(item => item.sfId)
                      ],
                      rows: question.rows,
                      max: question.max,
                      min: question.min,
                      createdId: question.id,
                      conditions: question.conditions,
                      isGroup: question.isGroup,
                      validationRules: question.validationRules,
                      externalConditions: question.externalConditions,
                      conditionsToCheck: question.conditionsToCheck
                    })
                  })
                promises.push(promiseJoin)
                return Promise.all(promises)
              })
            return Promise.all([questionEN, questionFR, surveyItem])
          })

        questionPromises.push(questionPromise)
      }
      questionPromises.push(groupQuestionsPromises)
    })

    if (values.titleData) {
      queriesUpdated.push({
        Id: values.titleData.frTransId,
        Tooltip__c: values.surveyTitleFR_tooltip,
        Text__c: values.surveyTitleFR,
        Extended__c: values.surveyTitleFR_ext,
        type: 'Survey_Text__c'
      })
      queriesUpdated.push({
        Id: values.titleData.enTransId,
        Tooltip__c: values.surveyTitleEN_tooltip,
        Text__c: values.surveyTitleEN,
        Extended__c: values.surveyTitleEN_ext,
        type: 'Survey_Text__c'
      })
      queriesUpdated.push({
        Id: values.templateId,
        Name: values.surveyName,
        Template_Options__c: JSON.stringify({
          autosave: values.autosave,
          skipSection: values.skipSection
        }),
        type: 'Survey_Templates__c'
      })
    } else {
      questionPromises.push(
        conn
          .sobject('SurveyTranslation__c')
          .create({})
          .then(translation => {
            const transTextFR = conn.sobject('Survey_Text__c').create({
              Survey_Translation__c: translation.id,
              Text__c: values.surveyTitleFR,
              Tooltip__c: values.surveyTitleFR_tooltip,
              Extended__c: values.surveyTitleFR_ext,
              Language__c: 'fr-ca'
            })
            const transTextEN = conn.sobject('Survey_Text__c').create({
              Survey_Translation__c: translation.id,
              Text__c: values.surveyTitleEN,
              Tooltip__c: values.surveyTitleEN_tooltip,
              Extended__c: values.surveyTitleEN_ext,
              Language__c: 'en-ca'
            })
            const updateSurvey = conn.sobject('Survey_Templates__c').update({
              Id: values.templateId,
              Name: values.surveyName,
              Title_Translation__c: translation.id,
              Template_Options__c: JSON.stringify({
                autosave: values.autosave,
                skipSection: values.skipSection
              })
            })
            return Promise.all([transTextFR, transTextEN, updateSurvey])
          })
      )
    }

    console.log('QUERIES', [...queriesUpdated])

    queriesUpdated = queriesUpdated.sort((a, b) => {
      var nameA = a.type.toUpperCase()
      var nameB = b.type.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    const queriesSplit = new Array(Math.ceil(queriesUpdated.length / 200))
      .fill()
      .map(_ => queriesUpdated.splice(0, 200))
    const updatePromise =
      queriesSplit.length > 0
        ? conn.requestPost(`/services/data/${SF_API_VERSION}/composite/`, {
            allOrNone: true,
            compositeRequest: [
              ...queriesSplit.map((sub, index) => {
                return {
                  method: 'PATCH',
                  url: `/services/data/${SF_API_VERSION}/composite/sobjects/`,
                  referenceId: 'refUpdatedObjects' + index,
                  body: {
                    allOrNone: true,
                    records: [
                      ...sub.map(item => {
                        const type = item.type
                        delete item.type
                        return {
                          attributes: { type: type },
                          ...item
                        }
                      })
                    ]
                  }
                }
              })
            ]
          })
        : Promise.resolve()

    return Promise.all([
      ...questionPromises,
      updatePromise
      // saveTitles(values, values.titleData)
    ])
  })
}

export const describe = obj => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject(obj).describe(function (err, meta) {
    if (err) {
      return console.error(err)
    }
    console.log(meta)
    console.log('Label : ' + meta.label)
    console.log('Num of Fields : ' + meta.fields.length)
    let toLog = []
    meta.fields.forEach(item => {
      toLog.push(item.label)
    })
    toLog = toLog.sort((a, b) => a.localeCompare(b))
    console.log(toLog)
  })
}

const mapFields = {
  direction: 'in',
  Id: 'id',
  Answer__c: 'answer',
  Name: 'name',
  SurveyState__c: 'surveyState',
  CreatedDate: 'createdData',
  LastModifiedDate: {
    key: 'modifiedDate',
    in: survey =>
      survey.Last_Modified_By_User_Date__c || survey.LastModifiedDate
  },
  Answer_RTF__c: 'answerRTF',
  Template_Snapshot__c: 'templateSnapshot',
  Template__c: 'templateId',
  User__c: 'user',
  Account__c: 'account',
  OwnerId: 'owner',
  Locked__c: 'locked',
  Opportunity__c: 'opportunity',
  Account_Name__c: 'accountName'
  // Name: 'organisationsName',
  // Other_used_name__c: 'otherName',
}

export const getSurvey = id => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    // console.log('getSurvey', id)
    return conn
      .sobject('Survey__c')
      .find({ Id: id })
      .then(r => r[0])
  } else {
    return Promise.reject(NO_USER)
  }
}
export const getSurveyParsed = id => {
  // console.log('getSurveyParsed', id)
  return getSurvey(id).then(survey =>
    survey ? mapSFToForm(mapFields, survey) : null
  )
}

export const getSurveyByFlow = surveyId => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn
      .requestPost('/actions/custom/flow/App_Get_Survey', {
        inputs: [
          {
            surveyId
          }
        ]
      })
      .then(result => {
        console.log('survey flow', result)
        if (result[0].isSuccess && result[0].outputValues.survey) {
          return mapSFToForm(mapFields, result[0].outputValues.survey)
        } else {
          console.error('survey flow error getting survey', result)
          return null
        }
      })
      .catch(err => {
        console.error('survey flow error in flow', err)
        return null
      })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const updateSurvey = ({ Id, ...updateObj }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Survey__c').update({
    Id: Id,
    ...updateObj
  })
}

export const saveSurveyData = ({
  id,
  name,
  accountId,
  opportunityId,
  values,
  submitButtonType,
  template,
  userId,
  disableIds = []
}) => {
  const conn = SFAuthService.getConnection()
  const saveData = {
    Id: id,
    Name: name,
    User__c: userId,
    Answer__c: JSON.stringify(values, null, 2),
    Answer_RTF__c: surveyToRTF(values, template, disableIds),
    Account__c: accountId,
    Opportunity__c: opportunityId,
    SurveyState__c: submitButtonType,
    Last_Modified_By_User_Date__c: moment.utc()
  }
  if (conn) {
    if (id) {
      return conn.sobject('Survey__c').update(saveData)
    } else {
      return conn.sobject('Survey__c').create(saveData)
    }
  } else {
    return Promise.reject(NO_USER)
  }
}

export const surveyConfigurationVariables = {
  SAT_SURVEY: {
    key: 'SAT_SURVEY',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  SAT_OSBL_SURVEY: {
    key: 'SAT_OSBL_SURVEY',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  SAT_COOPS_SURVEY: {
    key: 'SAT_COOPS_SURVEY',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  E_SAT_SURVEY: {
    key: 'E_SAT_SURVEY',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },

  ABOUT_ME_SURVEY: {
    key: 'ABOUT_ME_SURVEY',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },

  EXTERNAL_REVIEW_STF_SI: {
    key: 'EXTERNAL_REVIEW_STF_SI',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  EXTERNAL_REVIEW_STF_LP: {
    key: 'EXTERNAL_REVIEW_STF_LP',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  EXTERNAL_REVIEW_CBTI: {
    key: 'EXTERNAL_REVIEW_CBTI',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  EXTERNAL_REVIEW_FCHI: {
    key: 'EXTERNAL_REVIEW_FCHI',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },

  GRANTEE_REPORT_MIDTERM: {
    key: 'GRANTEE_REPORT_MIDTERM',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },

  GRANTEE_REPORT_FINAL: {
    key: 'GRANTEE_REPORT_FINAL',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  OPPORTUNITY_VIEW_REPORT: {
    key: 'OPPORTUNITY_VIEW_REPORT',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  OPPORTUNITY_VIEW_EXTERNAL_REVIEW: {
    key: 'OPPORTUNITY_VIEW_EXTERNAL_REVIEW',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  },
  OPPORTUNITY_VIEW_REVIEW_APPLICATION: {
    key: 'OPPORTUNITY_VIEW_REVIEW_APPLICATION',
    type: CONFIGURATION_SALESFORCE_ID,
    sObject: 'Survey_Templates__c'
  }
}

export const SATsIdsList = [
  'a2Z5X0000006IHHUA2',
  'a2Z5X0000006IHHUA2',
  'a2Z5X0000007BHhUAM',
  'a2Z5X0000007BHhUAM',
  'a2Z5X0000007CJ0UAM',
  'a2Z5X0000007CJ0UAM'
]

export const surveyMapping = {
  'S.A.T.': {
    sandbox: 'a2Z5X0000006IHHUA2',
    prod: 'a2Z5X0000006IHHUA2',
    hasScore: true,
    configuration: surveyConfigurationVariables.SAT_SURVEY,
    surveyScoreCard: Object.fromEntries(
      satScoreCard.map(item => [item.QuestionID, item])
    )
  },
  'S.A.T. - OSBL': {
    sandbox: 'a2Z5X0000007BHhUAM',
    prod: 'a2Z5X0000007BHhUAM',
    configuration: surveyConfigurationVariables.SAT_OSBL_SURVEY,
    hasScore: true,
    surveyScoreCard: Object.fromEntries(
      satScoreCardOSBL.map(item => [item.QuestionID, item])
    )
  },
  'S.A.T. - Co-ops': {
    sandbox: 'a2Z5X0000007CJ0UAM',
    prod: 'a2Z5X0000007CJ0UAM',
    configuration: surveyConfigurationVariables.SAT_COOPS_SURVEY,
    hasScore: true,
    surveyScoreCard: Object.fromEntries(
      satScoreCardCoop.map(item => [item.QuestionID, item])
    )
  },
  'Environmental S.A.T.': {
    sandbox: 'a2ZAm000000038yMAA',
    prod: 'a2ZAm000000038yMAA',
    configuration: surveyConfigurationVariables.E_SAT_SURVEY,
    hasScore: true
    // surveyScoreCard: Object.fromEntries(
    //   satScoreCardOSBL.map(item => [item.QuestionID, item])
    // )
  },
  AboutMe: {
    sandbox: 'a2Z5X00000070FYUAY',
    prod: 'a2Z5X00000070FYUAY',
    configuration: surveyConfigurationVariables.ABOUT_ME_SURVEY
  },
  'Sector Transformation Fund – Sectoral Impact': {
    sandbox: 'a2Z5X00000070FsUAI',
    prod: 'a2Z5X00000070FsUAI',
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_STF_SI
  },
  'Sector Transformation Fund – Local Project': {
    sandbox: 'a2Z5X00000070FsUAI',
    prod: 'a2Z5X00000070FsUAI',
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_STF_LP
  },
  'Community-Based Tenant Initiative Fund (CBTIF)': {
    sandbox: 'a2Z5X00000070FiUAI',
    prod: 'a2Z5X00000070FiUAI',
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_CBTI
  },
  'FCHI-2': {
    sandbox: 'a2Z5X00000070FiUAI',
    prod: 'a2Z5X00000070FiUAI',
    configuration: surveyConfigurationVariables.EXTERNAL_REVIEW_FCHI
  },
  'Final report': {
    configuration: surveyConfigurationVariables.GRANTEE_REPORT_FINAL
  },
  Interim: {
    configuration: surveyConfigurationVariables.GRANTEE_REPORT_MIDTERM
  }
}

export const templateIdToMapping = (templateId, configuration) => {
  const ret = templateIdToMappingConf(templateId, configuration)
  if (ret) {
    return ret.surveyScoreCard
  }
  return null
}

export const templateIdToMappingConf = (templateId, configuration) => {
  console.log('templateIdToMapping templateConfig ', templateId, configuration)
  for (const templateConfigName in configuration) {
    if (configuration[templateConfigName] === templateId) {
      // console.log(
      //   'templateIdToMapping templateConfigName ',
      //   templateConfigName,
      //   surveyMapping
      // )
      for (const templateName in surveyMapping) {
        // console.log('templateIdToMapping templateName ', templateName)
        if (
          surveyMapping[templateName].configuration.key === templateConfigName
        ) {
          console.log(
            'templateIdToMapping templateName score card found',
            templateName
          )
          return surveyMapping[templateName]
        }
      }
    }
  }

  return null
}

export const isConfiguredTemplate = (templateId, configuration, searchKey) => {
  const surveyMapping = templateIdToMappingConf(templateId, configuration)
  if (!surveyMapping) {
    return false
  }
  if (Array.isArray(searchKey)) {
    for (const key of searchKey) {
      if (surveyMapping.configuration.key === key) {
        return true
      }
    }
  } else {
    if (surveyMapping.configuration.key === searchKey) {
      return true
    }
  }
  return false
}

// no template ID when trying to create survey
export const NO_TEMPLATE = 'NO_TEMPLATE'

export const addSurvey = (
  {
    contactId = null,
    userId = null,
    opportunityId = null,
    accountId = null,
    name,
    locked,
    reportId = null,
    surveyTemplate = 'S.A.T.',
    templateId = null
  },
  configuration
) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }

  let surveyTemplateId
  if (!templateId && surveyMapping[surveyTemplate]) {
    if (surveyMapping[surveyTemplate].configuration && configuration) {
      surveyTemplateId =
        configuration[surveyMapping[surveyTemplate].configuration.key]
    } else {
      if (isSandbox()) {
        surveyTemplateId = surveyMapping[surveyTemplate].sandbox
      } else {
        surveyTemplateId = surveyMapping[surveyTemplate].prod
      }
    }
  } else {
    surveyTemplateId = templateId
  }
  // let surveyTemplateIdPromise
  // if (!surveyTemplateId) {
  //   console.log(
  //     'add Survey',
  //     surveyTemplate,
  //     configuration,
  //     surveyMapping[surveyTemplate] && surveyMapping[surveyTemplate].configuration,
  //     surveyTemplateId
  //   )
  //   surveyTemplateIdPromise = conn.sobject('Survey_Templates__c')
  //     .find({
  //       Id: id
  //     })
  //     .select('Id')
  //     .then(result => {
  //       if (result.length === 1) {
  //         return result[0].Id
  //       } else {
  //         console.error('To many with same name', result )
  //         return Promise.reject(NO_TEMPLATE)
  //       }
  //     })
  //
  // } else {
  //   surveyTemplateIdPromise = Promise.resolve(surveyTemplateId)
  // }
  if (!surveyTemplateId) {
    return Promise.reject(NO_TEMPLATE)
  }

  console.log('adding survey', {
    accountId,
    contactId,
    opportunityId,
    reportId,
    userId,
    surveyTemplateId,
    name
  })
  return conn.requestPost('/actions/custom/flow/App_Survey_Create_New', {
    inputs: [
      {
        AccountID: accountId,
        ContactID: contactId,
        UserID: userId,
        OpportunityID: opportunityId,
        SurveyTemplateID: surveyTemplateId,
        ReportId: reportId,
        SurveyName: name,
        Locked: Boolean(locked)
      }
    ]
  })
}

export const convertToExternalReviewerFlow = userId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost(
    '/actions/custom/flow/App_Convert_to_External_Reviewer',
    {
      inputs: [
        {
          userId
        }
      ]
    }
  )
}
