/**
 * Created by Michał Stawski on 24.04.2020.
 */
import React from 'react'
import { ErrorMessage, Field, useField } from 'formik'
import { RadioGroup, TextField } from 'formik-material-ui'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { indexToOption, questionTypes } from './QuestionWidget'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import {
  Checkbox,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
  TextField as MTextField
} from '@material-ui/core'
import FormikTextField from 'formik-material-fields/lib/FormikTextField/FormikTextField'
import { t, Trans } from '@lingui/macro'
import NumberFormat from 'react-number-format'
import ScrollBar from 'react-perfect-scrollbar'
import { DatePicker } from '@material-ui/pickers'
import PreviouslyAnsweredField from './PreviouslyAnsweredField'
import Dropzone from 'react-dropzone'
import {
  deleteDocument,
  getDocumentsByEntity,
  parseDocument,
  uploadFileToMultipleObjects
} from 'app/services/sfAuth/sfData/sfFiles'
import { useSnackbar } from 'notistack'
import { I18n } from '@lingui/react'
import {
  SURVEY_SLIDER_DEFAULT_MAX,
  SURVEY_SLIDER_DEFAULT_MIN
} from './SurveyCreationSliderFields'
import { nonApplicableSuffix, questionToInitial } from './SurveyTab'
import CustomDatePicker from '../common/CustomDatePicker'
import { myI18n } from 'translation/I18nConnectedProvider'
import { countHelperText } from '../page-layouts/FormElement'
import { bilingualNumberFormat } from '../common/Formats'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'

const SurveyTextField = ({
  id,
  numeric,
  max,
  min,
  maxCharacters,
  rows,
  hideError,
  allowRowsToExpand,
  ...props
}) => {
  const [field, meta] = useField(id)
  const value = field.value || ''
  const user = useSelector(state => state.user)
  const langFR = user.language !== 'en_US'

  const NumberFormatWithMinAndMax = useCallback(
    props => {
      const { inputRef, onChange, id, value = '', ...other } = props
      let formattedValue = ''
      // noinspection JSIncompatibleTypesComparison
      if (value || value === 0) {
        const num = String(value).replaceAll(',', '')
        formattedValue = parseFloat(Number(num).toFixed(2))
      }
      return (
        <NumberFormat
          {...other}
          isAllowed={({ floatValue }) => {
            if (!floatValue && floatValue !== 0) {
              return true
            }
            if (max && !min) {
              return floatValue <= Number(max)
            } else if (min && !max) {
              return floatValue >= Number(min)
            } else if (max && min) {
              return floatValue <= Number(max) && floatValue >= Number(min)
            }
            return true
          }}
          value={typeof props.value === 'object' ? '' : formattedValue}
          defaultValue=''
          thousandSeparator=','
          isNumericString
          getInputRef={inputRef}
          allowNegative={false}
          onValueChange={values => {
            onChange({
              target: {
                name: props.name,
                value: values.value
              }
            })
          }}
        />
      )
    },
    [max, min]
  )

  let helperText
  let inputError = false
  if (Number(maxCharacters)) {
    helperText = countHelperText(value, Number(maxCharacters))
    if (+Number(maxCharacters) < value.length) {
      inputError = true
      meta.error =
        meta.error +
        ' ' +
        myI18n._(t`Your current answer is ${value.length} characters!`)
    }
  } else if (numeric) {
    helperText = [
      <span key={1}>
        <Trans>Only numerical values are permitted in this field</Trans>
      </span>
    ]
    if (min) {
      helperText.push(
        '. ',
        <Trans>Minimum value is: {bilingualNumberFormat(min, langFR)}</Trans>
      )
    }
    if (max) {
      helperText.push(
        '. ',
        <Trans>Maximum value is: {bilingualNumberFormat(max, langFR)}</Trans>
      )
    }
    if (max && value) {
      if (+max < +value) {
        inputError = true
      }
    }
    if (min && value) {
      if (+min > +value) {
        inputError = true
      }
    }
  }
  const InputProps = {}
  if (numeric) {
    InputProps.inputComponent = NumberFormatWithMinAndMax
  }

  return (
    <MTextField
      style={{ padding: 2 }}
      helperText={meta.error && meta.touched ? meta.error : helperText}
      InputProps={InputProps}
      inputProps={{ maxLength: maxCharacters && Number(maxCharacters) }}
      variant='outlined'
      fullWidth
      {...props}
      minRows={rows || 4}
      maxRows={allowRowsToExpand ? null : rows || 4}
      {...meta}
      error={(meta.error && meta.touched) || inputError}
      {...field}
    />
  )
}

function QuestionInputText ({
  fieldId,
  hideError,
  question,
  disabled,
  disableFields,
  completedSurveys,
  displayEN,
  setFieldValue
}) {
  const numeric = question.type === questionTypes.INPUT_NUMBER.id
  const textArea = question.type === questionTypes.INPUT_TEXTAREA.id
  const {
    maxCharacters,
    max,
    min,
    rows,
    tooltip,
    tooltipAtBottom,
    isGroup,
    groupQuestions = [],
    allowRowsToExpand
  } = question

  return (
    <div>
      {tooltip && !tooltipAtBottom && returnTooltip(tooltip)}
      {!disabled && completedSurveys && (
        <PreviouslyAnsweredField
          setFieldValue={setFieldValue}
          completedSurveys={completedSurveys}
          item={question}
          displayEN={displayEN}
        />
      )}
      {isGroup ? (
        groupQuestions.map((item, qIndex) => {
          if (item.id && disableFields.includes(item.id)) {
            return null
          }
          return (
            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ padding: 10 }}
              key={qIndex}
            >
              <Grid item xs={3}>
                <Typography style={{ textAlign: 'center' }}>
                  {!displayEN ? item.fr : item.en}
                </Typography>
              </Grid>
              <Grid item container xs={9} justify='center'>
                <SurveyTextField
                  max={max}
                  min={min}
                  id={`${fieldId}.${qIndex}`}
                  allowRowsToExpand={allowRowsToExpand}
                  rows={rows}
                  multiline={textArea}
                  disabled={disabled}
                  numeric={numeric}
                  hideError={hideError}
                  maxCharacters={maxCharacters}
                />
              </Grid>
            </Grid>
          )
        })
      ) : (
        <SurveyTextField
          max={max}
          min={min}
          id={fieldId}
          rows={rows}
          allowRowsToExpand={allowRowsToExpand}
          multiline={textArea}
          disabled={disabled}
          numeric={numeric}
          hideError={hideError}
          maxCharacters={maxCharacters}
        />
      )}
      {tooltip && tooltipAtBottom && returnTooltip(tooltip)}
    </div>
  )
}

function QuestionUploadFiles ({
  fieldId,
  question,
  disabled,
  questionValue,
  setFieldValue,
  displayEN,
  completedSurveys,
  opportunity,
  entityFiles = [],
  surveyId,
  network = {}
}) {
  const [fileList, setFileList] = React.useState(
    entityFiles.filter(item => item.tags && item.tags.includes(fieldId))
  )
  const { enqueueSnackbar } = useSnackbar()

  const isDisabled =
    disabled || !network.Id || !opportunity || !opportunity.reportId

  const handleOnDrop = files => {
    console.log('handleOnDrop', files, network)
    const listCount = fileList.length
    const uploadId = listCount + 1
    const file = files[0]
    setFileList(
      [...fileList].concat({
        uploadId,
        name: file.name,
        progress: true
      })
    )
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const binaryStr = reader.result
      console.log('binary', binaryStr)
      enqueueSnackbar(<Trans>Uploading File</Trans>, {
        variant: 'info'
      })
      let additionalTags = ''
      let mainObjId
      if (opportunity.reportId) {
        additionalTags = ';;granteeReport'
        mainObjId = opportunity.reportId
      }
      if (!mainObjId) {
        return
      }
      uploadFileToMultipleObjects({
        name: file.name,
        description: question.fileDescription,
        tags: question.id + additionalTags,
        fileData: binaryStr,
        networkId: network.Id,
        mainId: mainObjId,
        ids: [opportunity.id]
      })
        .then(
          res => {
            console.log('files', res)
            enqueueSnackbar(<Trans>Uploaded File</Trans>, {
              variant: 'success'
            })
            const otherFiles = []
            res.forEach(doc => {
              otherFiles.push(doc)
            })
            const list = otherFiles.map(item => parseDocument(item))
            console.log('uploaded File', list)
            setFileList(list)
          },
          reject => {
            getDocumentsByEntity(opportunity.reportId).then(res => {
              enqueueSnackbar(
                <Trans>
                  Error ocurred while uploading file to some objects
                </Trans>,
                {
                  variant: 'error'
                }
              )
              console.log('files', res)
              const otherFiles = []
              res.forEach(doc => {
                otherFiles.push(doc)
              })
              const list = otherFiles.map(item => parseDocument(item))
              console.log('uploaded File', list)
              setFileList(list)
            })
          }
        )
        .catch(error => {
          console.warn('cant upload file', error)
          enqueueSnackbar(<Trans>Error Uploaded File</Trans>, {
            variant: 'error'
          })
          setFileList(fileList.filter(item => item.id !== uploadId))
        })
    }
    reader.readAsArrayBuffer(file)
  }

  const onRemoveItem = id => {
    enqueueSnackbar(<Trans>Deleting File</Trans>, {
      variant: 'info'
    })
    deleteDocument(id).then(result => {
      enqueueSnackbar(<Trans>Deleted File</Trans>, {
        variant: 'success'
      })
      setFileList(fileList.filter(item => item.id !== id))
    })
  }

  const createTable = ({ disabled }) => {
    const columns = [t`Name`, t`Remove`]
    return (
      <TableContainer component={Paper}>
        <Table style={{ whiteSpace: 'pre' }}>
          <colgroup>
            <col width='85%' />
            <col width='15%' />
          </colgroup>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              {columns.map((item, i) => (
                <TableCell key={i}>
                  <p
                    style={{
                      textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingRight: i === 1 && 20
                    }}
                  >
                    {myI18n._(item)}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fileList.map((item, index) => {
              const iconDisabled = disabled || item.progress
              return [
                <TableRow key={index}>
                  <TableCell>
                    <div style={{ paddingLeft: 35 }}>
                      <Link color='primary' href={item.url}>
                        {item.name}
                      </Link>
                      {question.showDescription && (
                        <div>{item.description}</div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 20
                      }}
                    >
                      <Tooltip title={myI18n._(t`Remove file`)}>
                        <IconButton
                          variant='filled'
                          disabled={iconDisabled}
                          onClick={() => {
                            onRemoveItem(item.id)
                          }}
                        >
                          <Icon
                            className={
                              iconDisabled
                                ? ''
                                : 'material-icons MuiIcon-root MuiIcon-colorError'
                            }
                            variant='filled'
                          >
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>,
                item.progress && (
                  <TableRow key={fileList.length + 1}>
                    <TableCell colSpan={3}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )
              ]
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div>
      {question.tooltip &&
        !question.tooltipAtBottom &&
        returnTooltip(question.tooltip)}
      <div>{createTable({ disabled })}</div>
      <Dropzone
        disabled={isDisabled}
        multiple={false}
        maxFiles={1}
        onDrop={files => {
          handleOnDrop(files)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={isDisabled ? 'dropbox_disabled' : 'dropbox'}
          >
            <section>
              <div>
                <input {...getInputProps()} />
                <div
                  style={{
                    textAlign: 'center'
                    // color: !item.income.id && 'red'
                  }}
                >
                  <span>
                    {isDisabled && (
                      <>
                        <Trans>Upload disabled</Trans>
                        <br />
                      </>
                    )}
                    <Trans>
                      Drag 'n' drop file here, or click to select file
                    </Trans>
                  </span>
                </div>
              </div>
              <div />
            </section>
          </div>
        )}
      </Dropzone>
      {question.tooltip &&
        question.tooltipAtBottom &&
        returnTooltip(question.tooltip)}
    </div>
  )
}

function QuestionNumericSlider ({
  fieldId,
  hideError,
  question,
  disabled,
  questionValue,
  setFieldValue,
  disableFields,
  displayEN,
  completedSurveys
}) {
  const minValue = Number(question.min) || SURVEY_SLIDER_DEFAULT_MIN
  const maxValue = Number(question.max) || SURVEY_SLIDER_DEFAULT_MAX
  let isValid =
    !question.isGroup && questionValue <= maxValue && questionValue >= minValue
  const [tempValue, setTempValue] = React.useState(null)
  const [subMoved, setSubMoved] = React.useState(null)
  return (
    <div>
      {question.tooltip && !question.tooltipAtBottom && (
        <div style={{ marginBottom: 5 }}>{returnTooltip(question.tooltip)}</div>
      )}
      {!disabled && completedSurveys && (
        <PreviouslyAnsweredField
          setFieldValue={setFieldValue}
          completedSurveys={completedSurveys}
          item={question}
          displayEN={displayEN}
        />
      )}
      {question.isGroup ? (
        question.groupQuestions ? (
          question.groupQuestions.map((item, qIndex) => {
            if (item.id && disableFields.includes(item.id)) {
              return null
            }
            const qValue = questionValue ? questionValue[qIndex] : minValue
            isValid = qValue <= maxValue && qValue >= minValue
            return (
              <Grid key={qIndex} container direction='row' alignItems='center'>
                <Grid style={{ flex: 1, margin: 10 }}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      wordBreak: 'break-word'
                    }}
                  >
                    {!displayEN ? item.fr : item.en}
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{ flex: 2, marginTop: 20 }}
                  direction='column'
                  justify='flex-start'
                >
                  <Grid
                    container
                    direction='row'
                    wrap='nowrap'
                    style={{ paddingLeft: 15, paddingRight: 15 }}
                  >
                    <div
                      style={{
                        flexGrow: 1,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 50,
                        paddingRight: 50
                      }}
                    >
                      <Slider
                        className={!qValue && 'slider-muted'}
                        style={{
                          color: !qValue
                            ? '#A5C9D3'
                            : disabled
                              ? 'rgba(0, 0, 0, 0.26)'
                              : '#10708A'
                        }}
                        onChangeCommitted={(event, newValue) => {
                          setFieldValue(`${fieldId}.${qIndex}`, newValue)
                          setTempValue(null)
                          setSubMoved(null)
                        }}
                        onChange={(event, newValue) => {
                          setSubMoved(qIndex)
                          setTempValue(newValue)
                        }}
                        value={
                          subMoved === qIndex
                            ? tempValue
                            : isValid
                              ? qValue
                              : minValue
                        }
                        step={1}
                        disabled={disabled}
                        min={minValue}
                        max={maxValue}
                        marks={[
                          { value: minValue, label: minValue },
                          { value: maxValue, label: maxValue }
                        ]}
                        valueLabelDisplay='auto'
                      />
                    </div>
                    <Grid
                      item
                      container
                      style={{ width: 100 }}
                      justify='center'
                      alignItems='center'
                    >
                      <Typography style={{ fontSize: 18 }}>{qValue}</Typography>
                    </Grid>
                  </Grid>
                  {!hideError && (
                    <ErrorMessage name={`${fieldId}.${qIndex}`}>
                      {msg => (
                        <p
                          style={{
                            color: '#f5543b',
                            fontSize: '0.75rem',
                            marginTop: 3,
                            marginLeft: 14,
                            textAlign: 'left'
                          }}
                        >
                          {msg}
                        </p>
                      )}
                    </ErrorMessage>
                  )}
                  <div style={{ marginBottom: 20 }} />
                </Grid>
              </Grid>
            )
          })
        ) : null
      ) : (
        <div>
          <Grid
            container
            direction='row'
            wrap='nowrap'
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            <div
              style={{
                flexGrow: 1,
                paddingTop: 40,
                paddingBottom: 20,
                paddingLeft: 50,
                paddingRight: 50
              }}
            >
              <Slider
                className={!questionValue && 'slider-muted'}
                style={{
                  color: !questionValue
                    ? '#A5C9D3'
                    : disabled
                      ? 'rgba(0, 0, 0, 0.26)'
                      : '#10708A'
                }}
                onChangeCommitted={(event, newValue) => {
                  setFieldValue(`${fieldId}`, newValue)
                  setTempValue(null)
                }}
                onChange={(event, newValue) => {
                  setTempValue(newValue)
                }}
                value={tempValue || (isValid ? questionValue : minValue)}
                step={1}
                disabled={disabled}
                min={minValue}
                max={maxValue}
                marks={[
                  { value: minValue, label: minValue },
                  { value: maxValue, label: maxValue }
                ]}
                valueLabelDisplay='auto'
              />
            </div>
            <Grid
              item
              container
              style={{ width: 100 }}
              justify='center'
              justifyContent='center'
              alignItems='center'
            >
              <Typography style={{ fontSize: 18 }}>{questionValue}</Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {question.tooltip &&
        question.tooltipAtBottom &&
        returnTooltip(question.tooltip)}
    </div>
  )
}

const SurveyDatePicker = ({ id, hideError, nullValue, ...props }) => {
  const [field, meta, helpers] = useField(id)
  const { setValue } = helpers

  return (
    <>
      <Grid container wrap='nowrap'>
        <Grid item xs>
          <CustomDatePicker
            {...field}
            {...meta}
            clearable
            fullWidth
            format='YYYY-MM-DD'
            inputVariant='outlined'
            autoOk
            value={field.value || null}
            onChange={e => {
              setValue(e ? e.toDate() : null)
            }}
            {...props}
          />
        </Grid>
        <Grid item>
          <IconButton
            onClick={e => {
              setValue(nullValue || null)
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </Grid>
      </Grid>

      {!hideError && (
        <ErrorMessage name={id}>
          {msg => (
            <p
              style={{
                color: '#f5543b',
                fontSize: '0.75rem',
                marginTop: 3,
                marginLeft: 14,
                textAlign: 'left'
              }}
            >
              {msg}
            </p>
          )}
        </ErrorMessage>
      )}
      <div style={{ marginBottom: 20 }} />
    </>
  )
}

function QuestionDate ({
  fieldId,
  question,
  hideError,
  disabled,
  questionValue,
  setFieldValue,
  disableFields,
  displayEN,
  completedSurveys
}) {
  const { tooltip, tooltipAtBottom, isGroup, groupQuestions = [] } = question
  return (
    <div>
      {tooltip && !tooltipAtBottom && (
        <div style={{ marginBottom: 5 }}>{returnTooltip(tooltip)}</div>
      )}
      {!disabled && completedSurveys && (
        <PreviouslyAnsweredField
          setFieldValue={setFieldValue}
          completedSurveys={completedSurveys}
          item={question}
          displayEN={displayEN}
        />
      )}
      {isGroup ? (
        groupQuestions.map((item, qIndex) => {
          if (item.id && disableFields.includes(item.id)) {
            return null
          }
          let qValue = questionValue
          if (qValue && !qValue[qIndex]) {
            qValue = null
          }
          return (
            <Grid key={qIndex} container direction='row' alignItems='center'>
              <Grid style={{ flex: 3, margin: 10 }}>
                <Typography
                  style={{
                    textAlign: 'center',
                    wordBreak: 'break-word'
                  }}
                >
                  {!displayEN ? item.fr : item.en}
                </Typography>
              </Grid>
              <Grid
                container
                style={{ flex: 2, marginTop: 20 }}
                direction='column'
                justify='flex-start'
              >
                <SurveyDatePicker
                  disabled={disabled}
                  id={`${fieldId}.${qIndex}`}
                />
              </Grid>
            </Grid>
          )
        })
      ) : (
        <SurveyDatePicker disabled={disabled} id={fieldId} />
      )}
      {tooltip && tooltipAtBottom && returnTooltip(tooltip)}
    </div>
  )
}

function QuestionDateRange ({
  fieldId,
  question,
  hideError,
  disabled,
  disableFields,
  questionValue,
  setFieldValue,
  displayEN,
  completedSurveys
}) {
  const { isGroup, tooltip, tooltipAtBottom, groupQuestions = [] } = question
  return (
    <div>
      {tooltip && !tooltipAtBottom && (
        <div style={{ marginBottom: 5 }}>{returnTooltip(tooltip)}</div>
      )}
      {!disabled && completedSurveys && (
        <PreviouslyAnsweredField
          setFieldValue={setFieldValue}
          completedSurveys={completedSurveys}
          item={question}
          displayEN={displayEN}
        />
      )}
      {isGroup ? (
        groupQuestions.map((item, qIndex) => {
          if (item.id && disableFields.includes(item.id)) {
            return null
          }
          const qValue = { ...questionValue }
          if (qValue && !qValue[qIndex]) {
            qValue[qIndex] = {
              end: null,
              start: null
            }
          }
          return (
            <Grid
              key={qIndex}
              container
              direction='row'
              alignItems='center'
              wrap='nowrap'
            >
              <Grid container item style={{ flex: 2, margin: 10 }}>
                <Typography
                  style={{
                    textAlign: 'center',
                    wordBreak: 'break-word',
                    width: '100%'
                  }}
                >
                  {!displayEN ? item.fr : item.en}
                </Typography>
              </Grid>

              <Grid
                container
                item
                style={{ flex: 3, marginTop: 20 }}
                justify='center'
                alignItems='center'
              >
                <Grid item xs={6} style={{ padding: 5 }}>
                  <SurveyDatePicker
                    label={<Trans>Start</Trans>}
                    id={`${fieldId}.${qIndex}`}
                    disabled={disabled}
                    value={qValue ? qValue[qIndex].start : null}
                    onChange={e => {
                      const toSet = { ...qValue[qIndex] }
                      toSet.start = e ? e.toDate() : null
                      if (!toSet.end) {
                        toSet.end = null
                      }
                      setFieldValue(`${fieldId}.${qIndex}`, toSet)
                    }}
                    nullValue={{
                      ...qValue[qIndex],
                      start: null
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: 5 }}>
                  <SurveyDatePicker
                    label={<Trans>End</Trans>}
                    id={`${fieldId}.${qIndex}`}
                    disabled={disabled}
                    value={qValue ? qValue[qIndex].end : null}
                    onChange={e => {
                      const toSet = { ...qValue[qIndex] }
                      toSet.end = e ? e.toDate() : null
                      if (!toSet.start) {
                        toSet.start = null
                      }
                      setFieldValue(`${fieldId}.${qIndex}`, toSet)
                    }}
                    nullValue={{
                      ...qValue[qIndex],
                      end: null
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        })
      ) : (
        <Grid container direction='row' style={{ padding: 5 }}>
          <Grid item xs={6} style={{ padding: 5 }}>
            <SurveyDatePicker
              label={<Trans>Start</Trans>}
              id={fieldId}
              disabled={disabled}
              value={
                questionValue && questionValue.start
                  ? questionValue.start
                  : null
              }
              onChange={e => {
                setFieldValue(`${fieldId}`, {
                  ...questionValue,
                  start: e ? e.toDate() : null
                })
              }}
              nullValue={{
                ...questionValue,
                start: null
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ padding: 5 }}>
            <SurveyDatePicker
              label={<Trans>End</Trans>}
              id={fieldId}
              disabled={disabled}
              value={
                questionValue && questionValue.end ? questionValue.end : null
              }
              onChange={e => {
                setFieldValue(`${fieldId}`, {
                  ...questionValue,
                  end: e ? e.toDate() : null
                })
              }}
              nullValue={{
                ...questionValue,
                end: null
              }}
            />
          </Grid>
        </Grid>
      )}
      {tooltip && tooltipAtBottom && returnTooltip(tooltip)}
    </div>
  )
}

function QuestionSelectOne ({
  fieldId,
  nonApplicable,
  nonApplicableDisabled,
  question,
  hideError,
  isSubmitting,
  disabled,
  questionValue,
  disableFields,
  completedSurveys,
  displayEN
}) {
  return (
    <Field>
      {({ form: { values, setFieldValue, setValues } }) => {
        return (
          <Grid
            container
            justify='center'
            alignItems='center'
            direction='column'
          >
            {question.tooltip &&
              !question.tooltipAtBottom &&
              returnTooltip(question.tooltip)}
            {!disabled && completedSurveys && (
              <PreviouslyAnsweredField
                setFieldValue={setFieldValue}
                completedSurveys={completedSurveys}
                item={question}
                displayEN={displayEN}
              />
            )}
            <ScrollBar className='position-relative flex pb-16 w-100'>
              {question.isGroup ? (
                question.groupQuestions && (
                  <TableContainer style={{ overflowX: 'visible' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: '200px' }} />
                          {question.options.map((item, index) => {
                            const cellWidth =
                              String(100 / question.options.length) + '%'
                            return (
                              <TableCell
                                key={index}
                                align='center'
                                style={{ width: cellWidth }}
                              >
                                <p
                                  style={{
                                    textAlign: 'center',
                                    wordBreak: 'break-word',
                                    minWidth: '150px'
                                  }}
                                >
                                  {item.text}
                                </p>
                              </TableCell>
                            )
                          })}
                          {question.nonApplicable && (
                            <TableCell
                              align='center'
                              style={{ width: '150px' }}
                            >
                              <p
                                style={{
                                  textAlign: 'center',
                                  wordBreak: 'break-word',
                                  minWidth: '150px'
                                }}
                              >
                                <Trans>Is non applicable</Trans>
                              </p>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {question.groupQuestions &&
                          question.groupQuestions.map((item, qIndex) => {
                            const nonApplicableSelected =
                              nonApplicable && nonApplicable[qIndex]
                            const toRet = (
                              <TableRow key={qIndex}>
                                <TableCell>
                                  <Typography
                                    style={{
                                      wordWrap: true,
                                      width: '180px',
                                      wordBreak: 'break-word',
                                      padding: 10
                                    }}
                                  >
                                    {!displayEN ? item.fr : item.en}
                                  </Typography>
                                  {Boolean(
                                    !hideError && !nonApplicableSelected
                                  ) && (
                                    <ErrorMessage name={`${fieldId}.${qIndex}`}>
                                      {msg => (
                                        <p
                                          style={{
                                            color: '#f5543b',
                                            fontSize: '0.75rem',
                                            marginTop: 3,
                                            marginLeft: 14,
                                            textAlign: 'left'
                                          }}
                                        >
                                          {msg}
                                        </p>
                                      )}
                                    </ErrorMessage>
                                  )}
                                </TableCell>
                                {question.options.map((item, index) => {
                                  let isChecked = false
                                  if (questionValue) {
                                    isChecked =
                                      questionValue[qIndex] ===
                                      indexToOption(index)
                                  }
                                  return (
                                    <TableCell key={index} align='center'>
                                      <Radio
                                        style={{ marginLeft: 10 }}
                                        key={index}
                                        checked={isChecked}
                                        value={indexToOption(index)}
                                        onChange={e => {
                                          setFieldValue(
                                            `${fieldId}.${qIndex}`,
                                            e.target.value
                                          )
                                          if (
                                            values[fieldId + '_other'] &&
                                            values[fieldId + '_other'][qIndex]
                                          ) {
                                            setFieldValue(
                                              `${fieldId}_other.${qIndex}`,
                                              null
                                            )
                                          }
                                        }}
                                        disabled={
                                          isSubmitting ||
                                          disabled ||
                                        nonApplicableSelected
                                        }
                                      />
                                      {isChecked && item.requireDetails && (
                                        <FormikTextField
                                          name={`${fieldId}_other.${qIndex}`}
                                          style={{
                                            marginLeft: 15,
                                            marginRight: 15
                                          }}
                                          variant='outlined'
                                          placeholder={myI18n._(
                                            t`Please, specify`
                                          )}
                                          fullWidth
                                          disabled={disabled}
                                          InputProps={
                                            item.requireDetails === 'number'
                                              ? {
                                                inputComponent: NumberFormatCustom
                                              }
                                              : {}
                                          }
                                        />
                                      )}
                                    </TableCell>
                                  )
                                })}
                                {question.nonApplicable && (
                                  <TableCell align='center'>
                                    <Checkbox
                                      style={{ marginLeft: 10, marginTop: 3 }}
                                      checked={nonApplicableSelected}
                                      onChange={e => {
                                        const toSet = { ...values }
                                        toSet[fieldId] = [...toSet[fieldId]]
                                        toSet[fieldId][qIndex] = ''
                                        if (
                                          !toSet[fieldId + nonApplicableSuffix]
                                        ) {
                                          toSet[
                                            fieldId + nonApplicableSuffix
                                          ] = []
                                        }
                                        toSet[fieldId + nonApplicableSuffix][
                                          qIndex
                                        ] = e.target.checked
                                        setValues(toSet)
                                      }}
                                      disabled={nonApplicableDisabled}
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            )
                            if (item.id && disableFields.includes(item.id)) {
                              return null
                            } else {
                              return toRet
                            }
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
              ) : (
                <Field
                  component={RadioGroup}
                  name={fieldId}
                  disabled={disabled}
                  style={{ width: '100%', marginTop: 5 }}
                >
                  {question.options &&
                    question.options.map((item, index) => {
                      const selected = questionValue === indexToOption(index)
                      return (
                        <Grid key={index} container direction='column'>
                          <FormControlLabel
                            style={{ marginLeft: 10, marginTop: 3 }}
                            key={index}
                            value={indexToOption(index)}
                            control={<Radio disabled={isSubmitting} />}
                            label={
                              <Typography style={{ textAlign: 'left' }}>
                                {item.text}
                              </Typography>
                            }
                            onChange={e => {
                              if (nonApplicable) {
                                setFieldValue(
                                  `${question.id}` + nonApplicableSuffix,
                                  false
                                )
                              }
                              if (values[fieldId + '_other']) {
                                setFieldValue(`${fieldId}_other`, null)
                              }
                            }}
                            disabled={isSubmitting || disabled}
                          />
                          {selected && item.requireDetails && (
                            <FormikTextField
                              name={`${fieldId}_other`}
                              style={{ marginLeft: 15, marginRight: 15 }}
                              variant='outlined'
                              placeholder={myI18n._(t`Please, specify`)}
                              fullWidth
                              disabled={disabled}
                              InputProps={
                                item.requireDetails === 'number'
                                  ? { inputComponent: NumberFormatCustom }
                                  : {}
                              }
                            />
                          )}
                        </Grid>
                      )
                    })}
                  {question.nonApplicable && (
                    <FormControlLabel
                      style={{ marginLeft: 10, marginTop: 3 }}
                      checked={nonApplicable}
                      control={<Checkbox />}
                      label={
                        <Typography style={{ textAlign: 'left' }}>
                          <Trans>Is non applicable</Trans>
                        </Typography>
                      }
                      onChange={e => {
                        const toSet = { ...values }
                        toSet[question.id + nonApplicableSuffix] =
                          e.target.checked
                        toSet[question.id] = questionToInitial(question)[1]
                        setValues(toSet)
                      }}
                      disabled={nonApplicableDisabled}
                    />
                  )}
                </Field>
              )}
            </ScrollBar>
            {question.tooltip &&
              question.tooltipAtBottom &&
              returnTooltip(question.tooltip)}
          </Grid>
        )
      }}
    </Field>
  )
}

const QuestionPointsGenerator = (options = []) =>
  withStyles(styles)(
    ({
      fieldId,
      nonApplicable,
      nonApplicableDisabled,
      question,
      isSubmitting,
      hideError,
      classes,
      disabled,
      questionValue,
      disableFields,
      completedSurveys,
      displayEN,
      setFieldValue
    }) => {
      return (
        <Field>
          {({ form: { values, setFieldValue, setValues } }) => {
            return (
              <Grid
                container
                justify='center'
                alignItems='center'
                direction='column'
              >
                {question.tooltip &&
                  !question.tooltipAtBottom &&
                  returnTooltip(question.tooltip)}
                {!disabled && completedSurveys && (
                  <PreviouslyAnsweredField
                    setFieldValue={setFieldValue}
                    completedSurveys={completedSurveys}
                    item={question}
                    displayEN={displayEN}
                  />
                )}
                <ScrollBar className='position-relative flex pb-16 w-100'>
                  {question.isGroup ? (
                    question.groupQuestions && (
                      <TableContainer style={{ overflowX: 'visible' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '200px' }} />
                              {options.map((item, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    align='center'
                                    style={{ width: '150px' }}
                                  >
                                    <p
                                      style={{
                                        textAlign: 'center',
                                        wordBreak: 'break-word',
                                        minWidth: '150px'
                                      }}
                                    >
                                      <Trans id={item} />
                                    </p>
                                  </TableCell>
                                )
                              })}
                              {question.nonApplicable && (
                                <TableCell
                                  align='center'
                                  style={{ width: '150px' }}
                                >
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      wordBreak: 'break-word',
                                      minWidth: '150px'
                                    }}
                                  >
                                    <Trans>Is non applicable</Trans>
                                  </p>
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {question.groupQuestions &&
                              question.groupQuestions.map((item, qIndex) => {
                                const nonApplicableSelected =
                                  nonApplicable && nonApplicable[qIndex]
                                const toRet = (
                                  <TableRow key={qIndex}>
                                    <TableCell>
                                      <Typography
                                        style={{
                                          wordWrap: true,
                                          width: '180px',
                                          wordBreak: 'break-word',
                                          padding: 10
                                        }}
                                      >
                                        {!displayEN ? item.fr : item.en}
                                      </Typography>
                                      {Boolean(
                                        !hideError && !nonApplicableSelected
                                      ) && (
                                        <ErrorMessage
                                          name={`${fieldId}.${qIndex}`}
                                        >
                                          {msg => (
                                            <p
                                              style={{
                                                color: '#f5543b',
                                                fontSize: '0.75rem',
                                                marginTop: 3,
                                                marginLeft: 14,
                                                textAlign: 'left'
                                              }}
                                            >
                                              {msg}
                                            </p>
                                          )}
                                        </ErrorMessage>
                                      )}
                                    </TableCell>
                                    {options.map((item, index) => {
                                      let isChecked = false
                                      if (questionValue) {
                                        isChecked =
                                          questionValue[qIndex] ===
                                          indexToOption(index)
                                      }
                                      return (
                                        <TableCell key={index} align='center'>
                                          <Radio
                                            style={{ marginLeft: 10 }}
                                            key={index}
                                            checked={isChecked}
                                            value={indexToOption(index)}
                                            onChange={e => {
                                              setFieldValue(
                                                `${fieldId}.${qIndex}`,
                                                e.target.value
                                              )
                                            }}
                                            disabled={
                                              isSubmitting ||
                                              disabled ||
                                            nonApplicableSelected
                                            }
                                          />
                                          {isChecked && item.requireDetails && (
                                            <div
                                              style={{
                                                marginLeft: 15,
                                                marginRight: 15
                                              }}
                                            >
                                              <FormikTextField
                                                name={
                                                  'other.' +
                                                  `${fieldId}.${qIndex}`
                                                }
                                                variant='outlined'
                                                placeholder={myI18n._(
                                                  t`Please, specify`
                                                )}
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={
                                                  item.requireDetails ===
                                                  'number'
                                                    ? {
                                                      inputComponent: NumberFormatCustom
                                                    }
                                                    : {}
                                                }
                                              />
                                            </div>
                                          )}
                                        </TableCell>
                                      )
                                    })}
                                    {question.nonApplicable && (
                                      <TableCell align='center'>
                                        <Checkbox
                                          style={{
                                            marginLeft: 10,
                                            marginTop: 3
                                          }}
                                          checked={nonApplicableSelected}
                                          onChange={e => {
                                            const toSet = { ...values }
                                            toSet[fieldId] = [...toSet[fieldId]]
                                            toSet[fieldId][qIndex] = ''
                                            if (
                                              !toSet[
                                                fieldId + nonApplicableSuffix
                                              ]
                                            ) {
                                              toSet[
                                                fieldId + nonApplicableSuffix
                                              ] = []
                                            }
                                            toSet[
                                              fieldId + nonApplicableSuffix
                                            ][qIndex] = e.target.checked
                                            setValues(toSet)
                                          }}
                                          disabled={nonApplicableDisabled}
                                        />
                                      </TableCell>
                                    )}
                                  </TableRow>
                                )
                                if (
                                  item.id &&
                                  disableFields.includes(item.id)
                                ) {
                                  return null
                                } else {
                                  return toRet
                                }
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                  ) : (
                    <Field
                      component={RadioGroup}
                      name={fieldId}
                      disabled={disabled}
                      style={{ width: '100%', marginTop: 5 }}
                    >
                      {options.map((item, index) => {
                        const selected = questionValue === indexToOption(index)
                        return (
                          <Grid key={index} container direction='column'>
                            <FormControlLabel
                              style={{ marginLeft: 10, marginTop: 3 }}
                              key={index}
                              value={indexToOption(index)}
                              control={<Radio disabled={isSubmitting} />}
                              label={
                                <Typography style={{ textAlign: 'left' }}>
                                  <Trans id={item} />
                                </Typography>
                              }
                              onChange={e => {
                                if (nonApplicable) {
                                  setFieldValue(
                                    `${question.id}` + nonApplicableSuffix,
                                    false
                                  )
                                }
                              }}
                              disabled={isSubmitting || disabled}
                            />
                            {selected && item.requireDetails && (
                              <div style={{ marginLeft: 15, marginRight: 15 }}>
                                <FormikTextField
                                  name={`${fieldId}` + '_other'}
                                  variant='outlined'
                                  placeholder={myI18n._(t`Please, specify`)}
                                  fullWidth
                                  disabled={disabled}
                                  InputProps={
                                    item.requireDetails === 'number'
                                      ? { inputComponent: NumberFormatCustom }
                                      : {}
                                  }
                                />
                              </div>
                            )}
                          </Grid>
                        )
                      })}
                      {question.nonApplicable && (
                        <FormControlLabel
                          style={{ marginLeft: 10, marginTop: 3 }}
                          checked={nonApplicable}
                          control={<Checkbox />}
                          label={
                            <Typography style={{ textAlign: 'left' }}>
                              <Trans>Is non applicable</Trans>
                            </Typography>
                          }
                          onChange={e => {
                            const toSet = { ...values }
                            toSet[question.id + nonApplicableSuffix] =
                              e.target.checked
                            toSet[question.id] = questionToInitial(question)[1]
                            setValues(toSet)
                          }}
                          disabled={nonApplicableDisabled}
                        />
                      )}
                    </Field>
                  )}
                </ScrollBar>
                {question.tooltip &&
                  question.tooltipAtBottom &&
                  returnTooltip(question.tooltip)}
              </Grid>
            )
          }}
        </Field>
      )
    }
  )

const QuestionMultipleValue = ({
  fieldId,
  question,
  disabled,
  completedSurveys,
  setFieldValue,
  displayEN,
  questionValue = []
}) => {
  const clearOptionSelected = question.options.some((item, index) => {
    if (questionValue[index] && item.clearOnSelect) {
      return true
    } else {
      return false
    }
  })

  return (
    <Field>
      {({ form: { values } }) => (
        <FormControl component='fieldset' style={{ width: '100%' }}>
          <FormLabel component='legend'>
            {!question.tooltipAtBottom && question.tooltip ? (
              returnTooltip(question.tooltip)
            ) : (
              <Trans> Select all that apply </Trans>
            )}
          </FormLabel>
          {!disabled && completedSurveys && (
            <PreviouslyAnsweredField
              setFieldValue={setFieldValue}
              completedSurveys={completedSurveys}
              item={question}
              displayEN={displayEN}
            />
          )}
          <FormGroup style={{ width: '100%' }}>
            {question.options.map((opt, index) => {
              let selected = false
              selected = questionValue[index]
              return (
                <Grid
                  key={index}
                  container
                  direction='column'
                  style={{ width: '100%', paddingBottom: 20 }}
                >
                  <FormControlLabel
                    name={`${fieldId}[${index}]`}
                    disabled={
                      disabled || Boolean(clearOptionSelected && !selected)
                    }
                    checked={Boolean(selected)}
                    onChange={e => {
                      if (opt.clearOnSelect) {
                        const toSet = Array(question.options.length).fill(false)
                        toSet[index] = e.target.checked
                        setFieldValue(`${fieldId}`, toSet)
                      } else {
                        setFieldValue(`${fieldId}[${index}]`, e.target.checked)
                        if (
                          values[fieldId + '_other'] &&
                          values[fieldId + '_other'][index]
                        ) {
                          setFieldValue(`${fieldId}_other.${index}`, null)
                        }
                      }
                    }}
                    control={<Checkbox indeterminate={false} />}
                    label={
                      <Typography style={{ textAlign: 'left' }}>
                        {opt.text}
                      </Typography>
                    }
                  />
                  {selected && opt.requireDetails && (
                    <FormikTextField
                      style={{ marginLeft: 15, marginRight: 15 }}
                      name={`${fieldId}_other.${index}`}
                      variant='outlined'
                      placeholder={myI18n._(t`Please, specify`)}
                      fullWidth
                      disabled={disabled}
                      InputProps={
                        opt.requireDetails === 'number'
                          ? { inputComponent: NumberFormatCustom }
                          : {}
                      }
                    />
                  )}
                </Grid>
              )
            })}
          </FormGroup>
          <FormLabel component='legend'>
            {question.tooltipAtBottom && question.tooltip
              ? returnTooltip(question.tooltip)
              : null}
          </FormLabel>
        </FormControl>
      )}
    </Field>
  )
}

const styles = theme => ({
  optionLabel: {
    fontSize: '0.8rem'
  },
  dropbox: {
    margin: '20px 0',
    border: '2px dashed #0085B8',
    padding: 35,
    backgroundColor: 'rgba(0, 133, 184, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0, 133, 184, 0.15)',
      border: '3px dashed #0085B8',
      textWeight: 'bold'
    }
  },
  optionHeaderElement: {},
  optionsContainer: {
    justifyContent: 'space-around'
  }
})

function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof props.value === 'object' ? '' : props.value}
      defaultValue=''
      thousandSeparator=' '
      isNumericString
      getInputRef={inputRef}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
    />
  )
}

export const returnTooltip = tooltipText => {
  const paragraphs = tooltipText.split('\n')
  return (
    <FormLabel component='legend'>
      {paragraphs.map((item, index) => (
        <>
          <Typography style={{ textAlign: 'center' }} key={index}>
            {item}
          </Typography>
          <br />
        </>
      ))}
    </FormLabel>
  )
}

export {
  QuestionInputText,
  QuestionSelectOne,
  QuestionDate,
  QuestionDateRange,
  QuestionPointsGenerator,
  QuestionMultipleValue,
  QuestionNumericSlider,
  QuestionUploadFiles
}
