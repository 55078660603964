import { t, Trans } from '@lingui/macro'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core'
import Save from '@material-ui/icons/Save'
import {
  fetchFormPage,
  fetchFormPages
} from 'app/services/sfAuth/sfData/sfForms'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Formik, useFormikContext, validateYupSchema } from 'formik'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormTitle } from '../grants/FormTitle'
import {
  formElementsWithoutInput,
  FormElementTitle,
  formElementTypes,
  FormHelptext,
  formUseStyles
} from './GroupElement'
import * as Yup from 'yup'
import { getNetwork, saveUser } from 'app/services/sfAuth/sfData/sfUser'
import { useSnackbar } from 'notistack'
import SFAuthService from 'app/services/sfAuth/SFAuthService'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import {
  createOpportunity,
  opportunitieStages,
  submitOpportunity
} from 'app/services/sfAuth/sfData/sfOpportunity'
import ReactHtmlParser from 'react-html-parser'
import { formObjectsToConnect } from './FormWizard'
import {
  checkAltLabel,
  getDisabledIds,
  isConditionMet
} from './FormHelpersConditions'
import { submitTechnicalAdvisory } from 'app/services/sfAuth/sfData/sfTechnicalAdvisories'
import { myI18n } from 'translation/I18nConnectedProvider'
import { useHistory } from 'react-router-dom'
import SaveWillOverrideWarningDialog from '../common/SaveWillOverrideWarningDialog'
import SavingFailedWarningDialog from '../common/SavingFailedWarningDialog'
import {
  constructFormAddressString,
  parseDisplayedText,
  pdfDefaultFontSize
} from './components/Common'
import moment from 'moment'
import { getContactsMap } from 'app/services/sfAuth/sfData/sfContact'
import { getAccountsMap } from 'app/services/sfAuth/sfData/sfAccount'
import ReactToPrint from 'react-to-print'
import {
  Page,
  PDFViewer,
  Text,
  View,
  Document,
  PDFDownloadLink,
  Font
} from '@react-pdf/renderer'
import robotoBold from 'app/assets/fonts/Roboto-Bold.ttf'
import robotoRegular from 'app/assets/fonts/Roboto-Regular.ttf'
import robotoItalic from 'app/assets/fonts/Roboto-Italic.ttf'
import robotoBoldItalic from 'app/assets/fonts/Roboto-BoldItalic.ttf'
import { dateFormat, datetimeFormat, defaultDocTitle } from 'app/appSettings'
import RedirectWarning from '../page-layouts/RedirectWarning'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import { unified } from 'unified'
import _, { isError, isNaN } from 'lodash'
import Html from 'react-pdf-html'
import {
  constructValidationSchema,
  errorsToRender
} from './FormHelpersValidation'
import { connectedObjectQuery } from './FormsHelpersQueries'
import { parseExtensionPhoneToSF } from '../common/Formats'
import { Alert, AlertTitle } from '@material-ui/lab'
import * as crypto from 'crypto'
import { useBeforeunload } from 'react-beforeunload'
import {
  commitFormCache,
  endEditingField,
  getCurrentFormState,
  grpcEndEditingForm,
  grpcFetchAllUsersInfo,
  grpcListenForChatMessageSent,
  grpcListenForEndEditingFormEvent,
  grpcListenForFieldChangeEvent,
  grpcListenForFieldCommitEvent,
  grpcListenForFieldLockEvent,
  grpcListenForMouseCursorEvent,
  grpcListenForSFSaveRequest,
  grpcListenForSFSaveResult,
  grpcListenForStarEditingFormEvent,
  grpcReportSFSaveResult,
  grpcRequestSFSave,
  grpcStartEditingForm,
  moveMouseCursor,
  pingServer,
  grpcGetFormBackups,
  tryInitiatingForm,
  grpcGetFormCache,
  commitChangeToMultipleFields,
  grpcUpdateUserInfo,
  grpcListenForUserInfoUpdated,
  grpGetLockedFieldsForForm,
  unlockFieldWithoutChanges,
  grpcFetchChatMessages
} from './multiuser/grpcMultiuserEdit'
import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position'
import {
  resetEditingUsers,
  setEditingUsers
} from 'app/redux/actions/MultiuserActions'
import CursorIcon from './multiuser/CursorIcon'
import { authRoles, checkAuth, hasRole } from 'app/auth/authRoles'
import Chat from './multiuser/Chat'
import {
  LockOperation,
  RequestStatus
} from './multiuser/proto/generated/Multiuser_pb'
import MUAvatar from './components/multiuser-input/MUAvatar'
import PopUpDialogue from '../grants/PopUpDialogue'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import Scrollbars from 'react-custom-scrollbars-2'
import { grpcListenForFieldCommentChangedEvent } from './multiuser/grpcFieldComment'

export const formItemPadding = 12
const printViewSpacing = 15
const DEFAULT_FORM_SAVE_REJECT = 'DEFAULT_FORM_SAVE_REJECT'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: robotoBoldItalic, fontWeight: 700, fontStyle: 'italic' },
    { src: robotoRegular }, // font-style: normal, font-weight: normal
    { src: robotoBold, fontWeight: 700 },
    { src: robotoItalic, fontStyle: 'italic' }
  ]
})

const getInitialTouched = data => {
  const toRet = {}
  if (!data.sections) {
    return toRet
  }
  data.sections.forEach(section => {
    section.elements.forEach(element => {
      initialTouchedForElement(element, toRet)
    })
  })
  return toRet
}

const initialTouchedForElement = (item, initObj) => {
  if (item.elements) {
    item.elements.forEach(element => {
      initialTouchedForElement(element, initObj)
    })
  } else {
    const type = item.elementType
    if (
      [
        'icce_budget',
        'uploadFiles',
        'connectContact',
        'connectAccount',
        'associatedContactsList'
      ].includes(type)
    ) {
      initObj[item.id] = true
    }
  }
}

export const getInitialValues = ({ data, files, ...props }) => {
  const toRet = { other: {}, muInfo: { chat: [] } }
  if (!data.sections) {
    return toRet
  }
  data.sections.forEach(section => {
    section.elements.forEach(element => {
      initialValuesForElement({
        item: element,
        initObj: toRet,
        files,
        ...props
      })
    })
  })
  return toRet
}

const initialValuesForElement = ({
  item,
  initObj,
  connectedMap = {},
  contactsMap = {},
  accountsMap = {}
}) => {
  if (item.elements) {
    item.elements.forEach(element => {
      initialValuesForElement({
        item: element,
        initObj,
        connectedMap,
        contactsMap,
        accountsMap
      })
    })
  } else {
    const type = item.elementType
    if (type && !formElementsWithoutInput.includes(type)) {
      const { typeProps = {} } = item
      const { options, picklistType } = typeProps
      let sfObject = {}
      let additionalSFInfo
      const { connectedObject, connectedField } = getMainConnected(item)
      if (connectedObject && connectedMap[connectedObject]) {
        sfObject = connectedMap[connectedObject].sfObject
        const files = connectedMap[connectedObject].files
        additionalSFInfo = connectedMap[connectedObject].additionalInfo
        if (type === 'uploadFiles') {
          initObj[item.id] = [
            ...files.filter(file => file.tags && file.tags.includes(item.id))
          ]
          return
        }
        if (options) {
          options.forEach((option, index) => {
            const { requireDetails, connectedField } = option
            if (requireDetails && connectedField) {
              if (picklistType === 'multiselect') {
                if (!initObj.other[item.id]) {
                  initObj.other[item.id] = []
                }
                initObj.other[item.id][index] = sfObject[connectedField.name]
              } else {
                initObj.other[item.id] = sfObject[connectedField.name]
              }
            }
          })
        }
        const sfValue = getSFObjectFieldValue(sfObject, connectedField)
        if (connectedField && (sfValue || sfValue === 0)) {
          if (type === 'picklist') {
            if (picklistType === 'multiselect') {
              initObj[item.id] = sfValue
                .split(';')
                .map(string => String(string).replace(/\s/g, ' ').trim())
            } else {
              initObj[item.id] = sfValue.replace(/\s/g, ' ')
            }
            return
          } else if (
            typeof formElementTypes[type].defaultValue === 'function'
          ) {
            initObj[item.id] = formElementTypes[type].defaultValue(
              sfObject,
              { ...additionalSFInfo, contactsMap, accountsMap, initObj },
              item
            )
            return
          } else {
            initObj[item.id] = sfValue
            return
          }
        }
      }
      if (type === 'picklist') {
        initObj[item.id] = picklistType === 'multiselect' ? [] : ''
        return
      }
      if (!formElementTypes[type]) {
        return
      }
      if (typeof formElementTypes[type].defaultValue === 'function') {
        initObj[item.id] = formElementTypes[type].defaultValue(
          sfObject,
          { ...additionalSFInfo, contactsMap, accountsMap, initObj },
          item
        )
      } else {
        initObj[item.id] = formElementTypes[type].defaultValue
      }
    }
  }
}

export const insertValueToSFObject = ({
  saveMap,
  value,
  fieldProps,
  sfObject,
  subObjectsMap,
  connectedObjectId,
  customConnectedField = false
}) => {
  const { name, subObject } = fieldProps
  if (subObject && name.indexOf('.') !== '-1') {
    const subObjectName = name.split('.')[0]
    const subFieldName = name.split('.')[1]
    if (subObjectName && subFieldName) {
      if (customConnectedField) {
        sfObject = sfObject[subObjectName]
      }
      subObjectsMap[sfObject.Id] = { type: subObject }
      if (!saveMap[sfObject.Id]) {
        saveMap[sfObject.Id] = {
          Id: sfObject.Id
        }
      } else {
        saveMap[sfObject.Id][subFieldName] = value
      }
    }
  } else {
    saveMap[connectedObjectId][name] = value
  }
}

export const getSFObjectFieldValue = (sfObject, props) => {
  if (!props) {
    return null
  }
  let sfValue = sfObject[props.name]
  if (props.subObject && sfValue) {
    const mainField = props.name.split('.')[0]
    const subField = props.name.split('.')[1]
    sfValue = sfObject[mainField][subField]
  }
  return sfValue
}

export const getMainConnected = item => {
  const connected = item.typeProps.connectedTo || []
  let toRet = {}
  connected.some((obj, index) => {
    const noField = formElementTypes[item.elementType]?.noFieldConnect
    if (obj.connectedObject && (obj.connectedField || noField)) {
      toRet = obj
      return true
    }
    if (index === 0) {
      // console.error('Main connected object was empty!', item)
    } else {
      console.warn('Empty connection configured detected for:', item)
    }
    return false
  })
  return toRet
}

export const mapFormElements = (data, langFR) => {
  const returnObj = {}
  if (!data || !data.sections) {
    return returnObj
  }
  data.sections.forEach((section, sectionIndex) =>
    section.elements.forEach(item =>
      mapItem({ item, returnObj, section, langFR, sectionIndex })
    )
  )
  return returnObj
}

const mapItem = ({ item, returnObj, section, langFR, sectionIndex }) => {
  if (item.elements) {
    item.elements.forEach(element =>
      mapItem({ item: element, returnObj, section, langFR, sectionIndex })
    )
  } else {
    returnObj[item.id] = {
      ...item,
      title: langFR ? item.titleFR : item.titleEN,
      sectionIndex,
      sectionName: langFR ? section.titleFR : section.titleEN
    }
  }
}

export const correctableErrors = {
  ZIP_CODE: {
    text: <Trans>Provided zip code has incorrect format!</Trans>,
    logic: error => error.includes('X1X 1X1')
  },
  DUPLICATE_NAME: {
    text: <Trans>Organization with this name already exists!</Trans>,
    logic: error =>
      error.includes('Organization with this name already exists!')
  },
  REQUIRED_NAME: {
    text: <Trans>Empty name is invalid!</Trans>,
    logic: error => error.includes('[Name]')
  }
}

export const handleFormSave = ({
  values,
  reduxBag,
  extraInfo,
  utilityBag = {},
  elementsMap,
  connectedMap,
  baseToSave = {}
}) => {
  const conn = SFAuthService.getConnection()
  const { enqueueSnackbar, reloadLastModifiedDates } = utilityBag
  let promises = []
  const subObjectsMap = {}
  if (!connectedMap || Object.keys(connectedMap).length === 0) {
    return Promise.resolve()
  }
  console.log('form save values', values)
  const saveMap = { ...baseToSave }
  Object.keys(connectedMap).forEach(key => {
    if (connectedMap[key].sfObject) {
      saveMap[key] = {
        ...saveMap[key],
        Id: connectedMap[key].sfObject.Id
      }
    }
  })
  Object.keys(values).forEach((key, index) => {
    const item = elementsMap[key]
    if (item) {
      const elementProps = formElementTypes[item.elementType]
      const typeProps = item.typeProps
      const {
        isConnected,
        connectedTo = [],
        options,
        requiresRequest,
        isPhone
      } = typeProps
      let sfObject, additionalSFInfo
      connectedTo.forEach((obj, index) => {
        let value = values[key]
        let { connectedObject, connectedField } = obj
        if (isConnected && connectedObject && connectedMap[connectedObject]) {
          sfObject = connectedMap[connectedObject].sfObject
          additionalSFInfo = connectedMap[connectedObject].additionalInfo
          let fieldName = connectedField?.name
          if (connectedField && fieldName.indexOf('.') !== '-1') {
            const subObjectName = fieldName.split('.')[0]
            const subFieldName = fieldName.split('.')[1]
            if (subObjectName && subFieldName) {
              fieldName = subFieldName
              sfObject = sfObject[subObjectName]
              connectedObject = sfObject.Id
              subObjectsMap[sfObject.Id] = { type: connectedField.subObject }
              if (!saveMap[sfObject.Id]) {
                saveMap[sfObject.Id] = {
                  Id: sfObject.Id
                }
              }
            }
          }
          if (options) {
            options.forEach((option, index) => {
              let isSelected = false
              const defaultOpt = 'option' + index
              const optionValue = isConnected
                ? option.apiValue || defaultOpt
                : defaultOpt
              if (Array.isArray(value)) {
                isSelected = value.includes(optionValue)
              } else {
                isSelected = value === optionValue
              }
              if (isSelected && option.requireDetails) {
                if (option.connectedField) {
                  let fieldValue = values.other && values.other[key]
                  if (Array.isArray(fieldValue)) {
                    fieldValue = fieldValue[index]
                  }
                  saveMap[connectedObject][option.connectedField.name] =
                    fieldValue
                }
              }
            })
          }
          if (typeProps.picklistType === 'multiselect' && value) {
            value = value.join(';')
          }
          // If empty string is sent to SF instead of null  the field will be set to 0 instead of null
          if (
            item.elementType === 'textInputNumeric' ||
            item.elementType === 'numericSlider'
          ) {
            if (!value && value !== 0) {
              value = null
            } else if (isPhone) {
              value = parseExtensionPhoneToSF(value, values.other[key])
            }
          }
          let saveHandled = false
          if (elementProps.savePromise) {
            saveHandled = true
            promises.push(
              elementProps.savePromise({
                value,
                item,
                connectedObject: sfObject,
                additionalSFInfo
              })
            )
          }
          if (elementProps.extractSaveKey) {
            saveHandled = true
            elementProps.extractSaveKey({
              saveMap,
              subObjectsMap,
              additionalSFInfo,
              value,
              values,
              item,
              connectedProps: obj,
              connectedObjectId: connectedObject,
              sfObject
            })
          }
          if (!saveHandled && !requiresRequest && fieldName) {
            saveMap[connectedObject][fieldName] = value
          }
        }
      })
    }
  })

  Object.keys(saveMap).forEach(key => {
    const objData = connectedMap[key]?.sfObject
    const type = objData ? objData.attributes.type : subObjectsMap[key].type
    const sfObjectData = formObjectsToConnect[type]
    if (saveMap[key].Id && Object.keys(saveMap[key]).length > 1) {
      if (sfObjectData && sfObjectData.saveFunction) {
        promises.push(
          sfObjectData.saveFunction(saveMap[key], reduxBag, utilityBag)
        )
      } else {
        promises.push(conn.sobject(type).update(saveMap[key]))
      }
    } else if (!saveMap[key].Id) {
      if (type === 'Opportunity') {
        promises.push(createOpportunity(conn, saveMap[key], extraInfo))
      } else {
        if (Object.keys(saveMap[key]).length > 0) {
          promises.push(conn.sobject(type).create(saveMap[key]))
        }
      }
    }
  })

  const hasError = result => {
    if (Array.isArray(result)) {
      return result.some(sub => hasError(sub))
    } else {
      if (!result) {
        console.error(
          'No promise resolution configured or an unexpected error ocurred!'
        )
        return true
      }
      if (result.compositeResponse) {
        return result.compositeResponse.some(comp => {
          if (!comp.body) {
            return false
          } else {
            return comp.body.some(sub => !sub.success)
          }
        })
      }
      if ('isSuccess' in result) {
        return !result.isSuccess
      }
      if ('success' in result) {
        return !result.success
      }
      if ('hasErrors' in result) {
        return result.hasErrors
      }
      return false
    }
  }

  promises = promises.filter(f => f)

  const handleReject = reject => {
    let correctableError

    if (Array.isArray(reject)) {
      if (reject.some(result => !hasError(result))) {
        reloadLastModifiedDates()
      }
    }

    const checkErrorGravity = error => {
      if (Array.isArray(error)) {
        error.forEach(error => checkErrorGravity(error))
      } else if (error.compositeResponse) {
        error.compositeResponse.forEach(result => {
          if (result.body) {
            result.body.forEach(result => {
              checkErrorGravity(result)
            })
          }
        })
      } else if (error.errors) {
        error.errors.forEach(error => {
          checkErrorGravity(error)
        })
      } else {
        let parsedError = error
        if (isError(error)) {
          parsedError = reject.toString()
        } else if (error.errorCode && error.message) {
          parsedError = error.message
        }
        if (typeof parsedError === 'string') {
          Object.keys(correctableErrors).forEach(key => {
            const errorData = correctableErrors[key]
            if (errorData.logic(parsedError)) {
              correctableError = key
              if (errorData.text) {
                enqueueSnackbar(errorData.text, {
                  variant: 'error'
                })
              }
            }
          })
        }
      }
    }
    checkErrorGravity(reject)
    return Promise.reject(correctableError || DEFAULT_FORM_SAVE_REJECT)
  }

  return Promise.allSettled(promises).then(
    result => {
      let errorOcurred
      console.log('form saving result', result)
      result.forEach(promise => {
        if (promise.status === 'rejected') {
          errorOcurred = true
        } else if (!errorOcurred) {
          errorOcurred =
            Array.isArray(promise.value) &&
            promise.value.some(res => hasError(res))
        }
      })
      if (errorOcurred) {
        const rejectArray = result.map(promise =>
          promise.status === 'rejected' ? promise.reason : promise.value
        )
        return handleReject(rejectArray)
      } else {
        return result.map(promise =>
          promise.status === 'rejected' ? promise.reason : promise.value
        )
      }
    },
    reject => {
      console.error('form error saving', reject)
      return handleReject(reject)
    }
  )
}

export const checkFormValidity = ({ id, formId }) => {
  return fetchFormPage(formId)
    .then(result => {
      console.log('form loaded', result)
      if (result.objectsConnected) {
        return connectedObjectQuery(result, {
          id
        }).then(
          connectedMap => {
            const initialValues = getInitialValues({
              data: result,
              connectedMap
            })
            const elementsMap = mapFormElements(result)

            const getValidFormSchema = (errors = {}) => {
              const validationSchemaRaw = constructValidationSchema({
                data: result,
                returnRaw: true,
                english: true
              })

              const disabledIds = getDisabledIds({
                sections: result.sections,
                elementsMap,
                values: initialValues,
                connectedMap,
                errors
              })
              disabledIds.forEach(id => {
                delete validationSchemaRaw[id]
              })
              return Yup.object().shape(validationSchemaRaw)
            }

            return validateYupSchema(initialValues, getValidFormSchema()).then(
              result => {
                return validateYupSchema(
                  initialValues,
                  getValidFormSchema({})
                ).then(
                  result => true,
                  reject => false
                )
              },
              reject => {
                const errors = {}
                reject.inner.forEach(error => {
                  errors[error.path] = error
                })
                return validateYupSchema(
                  initialValues,
                  getValidFormSchema(errors)
                ).then(
                  result => true,
                  reject => false
                )
              }
            )
          },
          reject => {
            console.error('no object found', reject)
            return false
          }
        )
      } else {
        return true
      }
    })
    .catch(error => {
      console.error('error loading form', error)
      return false
    })
}

const getObjectsFieldsMap = ({ data, connectedMap, describeMap }) => {
  const toReturn = {}
  data.objectsConnected.forEach(object => {
    const connData = connectedMap[object.identId]
    const toSet = {}
    if (connData) {
      const objName = connData.sfObject.attributes.type
      Object.keys(connData.fieldsMap).forEach(key => {
        toSet[key] = {
          value: connData.sfObject[key],
          type: connData.fieldsMap[key].type
        }
      })
      Object.keys(connData.sfObject).forEach(key => {
        const value = connData.sfObject[key]
        if (!toSet[key]) {
          toSet[key] = {
            value,
            type: 'object'
          }
        }
      })
      if (formObjectsToConnect[objName].additionalObjects) {
        formObjectsToConnect[objName].additionalObjects.forEach(obj => {
          const { sfObject, field } = obj
          const subObject = connData.sfObject[field]
          if (subObject) {
            const fields = describeMap[subObject.attributes.type].fields
            const subObjectFieldsMap = {}
            if (fields) {
              fields.forEach(obj => {
                subObjectFieldsMap[obj.name] = {
                  value: subObject[obj.name],
                  type: obj.type
                }
              })
            }
            toSet[field] = {
              value: subObjectFieldsMap,
              type: 'object'
            }
          }
        })
      }
      toReturn[object.name] = toSet
    }
  })
  return toReturn
}

const multiuserColors = [
  '#fca737',
  '#42c9c0',
  '#810bbc',
  '#a01313',
  '#232efc',
  '#f43f9a',
  '#1b9121',
  '#e03134',
  '#8e6d5e',
  '#5e595a',
  '#FFADBC'
]

const FormWrapped = props => {
  const editingUsers = useSelector(state => state.multiuser.editingUsers)
  const user = useSelector(state => state.user)
  const multiuserEdit = editingUsers && Object.values(editingUsers).length > 0
  const mouseDetectRef = useRef()
  const [currentStep, setStep] = useState(0)

  return (
    <>
      {multiuserEdit && (
        <div style={{ height: 1 }}>
          {Object.values(editingUsers)
            .filter(
              userObj =>
                userObj.id !== user.userId && userObj.step === currentStep
            )
            .map(userObj => {
              const { coordinates, color, name, step } = userObj
              return (
                coordinates && (
                  <CursorIcon
                    coordinates={coordinates}
                    dimensions={
                      mouseDetectRef?.current?.state?.elementDimensions
                    }
                    color={color}
                    name={name}
                  />
                )
              )
            })}
        </div>
      )}
      <Form
        {...props}
        mouseDetectRef={mouseDetectRef}
        currentStep={currentStep}
        setStep={setStep}
      />
    </>
  )
}

const Form = React.memo(
  ({
    match = {},
    saveCallback,
    fetchString,
    formId,
    fixedDisplay,
    scrollbarContentRef,
    disableTitle = false,
    pdfTitle,
    returnPdf,
    displayView,
    disablePDF,
    inDialog,
    onDialogClose,
    mouseDetectRef,
    currentStep,
    setStep,
    ...props
  }) => {
    const { params = {} } = match
    const { preview } = params
    const id = fetchString || params.id
    formId = formId || params.formId
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(true)
    const [initialValues, setInitialValues] = useState()
    const [saving, setSaving] = useState(false)
    const [data, setData] = useState()
    const [network, setNetwork] = useState()
    const [validationSchema, setValidationSchema] = useState()
    const [overrideWarningData, setOverrideWarningData] = useState()
    const [saveFailedData, setSaveFailedData] = useState()
    const [connectedMap, setConnectedMap] = useState({})
    const [describeMap, setDescribeMap] = useState({})
    const [injectablesMap, setInjectablesMap] = useState({})
    const [configuration, setConfiguration] = useState()
    const [readOnly, setReadOnly] = useState(false)
    const [pdfDisplay, setPdfDisplay] = useState(false)
    const [insufficientAccess, setInsufficientAccess] = useState(false)
    const [wrongAccountRole, setWrongAccountRole] = useState(null)
    const [useMultiuser, setUseMultiuser] = useState(false)
    const [haveConnectionToServer, setHaveConnectionToServer] = useState(false)
    const [multiuserSnackbar, setMultiuserSnackbar] = useState(null)
    const [muSessionId, setMuSessionId] = useState(null)
    // const editingUsers = useSelector(state => state.multiuser.editingUsers)
    const printRef = useRef()
    const user = useSelector(state => state.user)
    const appConfigurations = useSelector(state => state.configuration || {})
    const organization = useSelector(state => state.organization || {})
    const avaliableOrganizations = useSelector(
      state => state.avaliableOrganizations
    )
    const langFR = user.language !== 'en_US'
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const history = useHistory()
    const dispatch = useDispatch()
    const formik = useRef()
    const isPreview = preview === 'preview'
    const noConnectedObjects =
      connectedMap && Object.keys(connectedMap).length === 0
    const classes = formUseStyles()
    // useBeforeunload(event => {
    //   muEndEditingForm()
    //   dispatch(resetEditingUsers())
    // })

    useEffect(() => {
      return () => {
        if (muSessionId) {
          muEndEditingForm()
          dispatch(resetEditingUsers())
        }
      }
    }, [muSessionId])

    const isTrueDirty = () => {
      if (!formik.current || !formik.current.values || !initialValues) {
        return false
      }
      const values = _.cloneDeep(formik.current.values)
      const initValues = _.cloneDeep(initialValues)
      delete values.muInfo
      delete values.muUsers
      delete initValues.muInfo
      delete initValues.muUsers
      return !_.isEqual(values, initValues)
    }

    const muEndEditingForm = () => {
      const { muUsers } = formik.current.values
      let shouldBrodcast = true
      if (
        muUsers[user.userId] &&
        muUsers[user.userId].sessionId !== muSessionId
      ) {
        shouldBrodcast = false
      }
      if (useMultiuser && shouldBrodcast) {
        const formikRef = formik.current
        const { values, setFieldValue } = formikRef
        if (
          values.muUsers &&
          Object.keys(values.muUsers).length === 1 &&
          isTrueDirty()
        ) {
          commitFormCache({
            values,
            userId: user.userId,
            formId: id
          })
        }
        grpcEndEditingForm({
          userId: user.userId,
          formId: id,
          onSuccess: result => {
            console.log('exited form', result)
          }
        })
      }
    }

    useEffect(() => {
      if (useMultiuser && haveConnectionToServer) {
        const streamFieldLocked = grpcListenForFieldLockEvent({
          id,
          onEventRecieved: ({ userId, changes = [], status, operation }) => {
            console.log('lock change event', status, operation, changes)
            const formikRef = formik.current
            if (operation === LockOperation.LOCK) {
              const toSet = { ...formikRef.values.muInfo }
              changes.forEach(obj => {
                const { lockId, fieldId } = obj
                console.log('user locked field', user, fieldId, lockId)
                if (!toSet[fieldId]) {
                  toSet.lockId = lockId
                  toSet[fieldId] = {
                    locked: true,
                    user: userId
                  }
                }
              })
              formikRef.setFieldValue(`muInfo`, toSet, false)
            } else if (operation === LockOperation.COMMIT) {
              const toSet = { ...formikRef.values }
              changes.forEach(obj => {
                const { lockId, fieldId, fieldValue } = obj
                let newValue = fieldValue
                console.log('user unlocked field', fieldId, newValue)
                if (newValue) {
                  console.log(fieldValue)
                  newValue = JSON.parse(newValue)
                  console.log(fieldValue)
                  const testNum = parseFloat(
                    String(newValue).replace(/,|\$/g, '')
                  )
                  console.log(testNum)
                  if (!isNaN(testNum)) {
                    newValue = testNum
                  }
                } else {
                  newValue = null
                }
                delete toSet.muInfo[fieldId]
                _.set(toSet, fieldId, newValue)
              })
              formikRef.setValues(toSet, userId !== user.userId)
            } else if (operation === LockOperation.UPDATE) {
              if (userId !== user.userId) {
                const toSet = { ...formikRef.values }
                changes.forEach(obj => {
                  const { lockId, fieldId, fieldValue } = obj
                  const newValue = fieldValue
                  if (newValue) {
                    newValue = JSON.parse(newValue)
                    const testNum = parseFloat(
                      String(newValue).replace(/,|\$/g, '')
                    )
                    if (!isNaN(testNum)) {
                      newValue = testNum
                    }
                  } else {
                    newValue = null
                  }
                  _.set(toSet, fieldId, newValue)
                })
                formikRef.setValues(toSet)
              }
            } else if (operation === LockOperation.CANCEL) {
              const toSet = { ...formikRef.values }
              changes.forEach(obj => {
                const { lockId, fieldId } = obj
                delete toSet.muInfo[fieldId]
              })
              formikRef.setValues(toSet, false)
            }
          }
        })
        const streamMouseMoved = grpcListenForMouseCursorEvent({
          id,
          onEventRecieved: ({ coordinates, userId }) => {
            const formikRef = formik.current
            const toSet = { ...formikRef.values }
            const newMuUsers = { ...toSet.muUsers }
            if (newMuUsers[userId]) {
              newMuUsers[userId].coordinates = coordinates
              dispatch(setEditingUsers(newMuUsers))
            }
          }
        })
        const streamStartEditing = grpcListenForStarEditingFormEvent({
          id,
          onEventRecieved: info => {
            console.log('user started editing', info)
            const formikRef = formik.current
            const { values } = formikRef
            const toSet = { ...values.muUsers }
            if (info.id === user.userId && toSet[info.id]) {
              toSet[info.id] = info
              formikRef.setFieldValue('muUsers', toSet, false)
              history.push('/grants/GrantsHome')
              //TODO: unlock field on exit
              //values.muInfo.lockId
              enqueueSnackbar(
                <Trans>
                  You logged to this form in different tab or browser
                </Trans>,
                {
                  variant: 'info'
                }
              )
            } else {
              toSet[info.id] = info
              formikRef.setFieldValue('muUsers', toSet, false)
              dispatch(setEditingUsers(toSet))
            }
          }
        })
        const streamChatMessage = grpcListenForChatMessageSent({
          id,
          onEventRecieved: ({ user, message }) => {
            const { values, setFieldValue } = formik.current
            const toSet = { ...values.muInfo }
            console.log('got message', user, message)
            if (values.muUsers[user]) {
              const newChat = toSet.chat ? [...toSet.chat] : []
              newChat.push({
                userId: user,
                text: message,
                color: values.muUsers[user].color,
                name: values.muUsers[user].name,
                recieved: moment.utc()
              })
              toSet.chat = newChat
              setFieldValue(`muInfo`, toSet, false)
            }
          }
        })
        const streamEndEditing = grpcListenForEndEditingFormEvent({
          id,
          onEventRecieved: id => {
            console.log('user logout of form', id)
            const formikRef = formik.current
            const toSet = { ...formikRef.values }
            const newMuUsers = { ...toSet.muUsers }
            if (newMuUsers[id]) {
              delete newMuUsers[id]
              toSet.muUsers = newMuUsers
            }
            // unlock fields for editor
            Object.keys(toSet.muInfo).forEach(key => {
              const value = toSet.muInfo[key]
              if (typeof value === 'object' && value && value.user === id) {
                delete toSet.muInfo[key]
                unlockFieldWithoutChanges({
                  formId: id,
                  userId: user.userId
                })
              }
            })
            formikRef.setValues(toSet, false)
            dispatch(setEditingUsers(toSet.muUsers))
          }
        })
        const streamSFSaveRequested = grpcListenForSFSaveRequest({
          id,
          onEventRecieved: ({ canSave, requestType, userRequesting }) => {
            const formikRef = formik.current
            const { values, setFieldValue } = formikRef
            const userInfo = values.muUsers[userRequesting]
            console.log(
              'save request recieved',
              canSave,
              requestType,
              userRequesting
            )
            if (userInfo && userRequesting !== user.userId) {
              if (requestType === 'Autosave') {
                enqueueSnackbar(<Trans>Autosaving</Trans>, { variant: 'info' })
              } else {
                enqueueSnackbar(
                  <Trans>{userInfo.name} requested form save</Trans>,
                  { variant: 'info' }
                )
              }
            }
            if (!canSave) {
              setSaving(false)
              enqueueSnackbar(
                <Trans>
                  All fields must be unlocked for saving to commence!
                </Trans>,
                { variant: 'error' }
              )
            } else {
              setSaving(true)
              if (userRequesting === user.userId) {
                handleSave({ values: formikRef.values }).then(
                  result => {
                    grpcReportSFSaveResult({
                      type: requestType,
                      formId: id,
                      userId: user.userId,
                      result: RequestStatus.ALLOWED
                    })
                  },
                  reject => {
                    grpcReportSFSaveResult({
                      type: requestType,
                      formId: id,
                      userId: user.userId,
                      result: RequestStatus.BLOCKED
                    })
                  }
                )
              } else {
                const muSnackbar = enqueueSnackbar(null, {
                  persist: true,
                  content: key =>
                    ProgressSnackbar(<Trans>{userInfo.name} is saving</Trans>)
                })
                setFieldValue(`muInfo.muSnackbar`, muSnackbar, false)
              }
            }
          }
        })
        const streamSFSaveResult = grpcListenForSFSaveResult({
          id,
          onEventRecieved: ({ success, requestType, userSaving }) => {
            console.log(
              'save request recieved',
              success,
              requestType,
              userSaving
            )
            if (userSaving !== user.userId) {
              const formikRef = formik.current
              const { values, setFieldValue } = formikRef

              if (success) {
                if (requestType === 'Save' || requestType === 'Autosave') {
                  fetchData({ multiuserReload: true }).then(result => {
                    if (values.muInfo.muSnackbar) {
                      closeSnackbar(values.muInfo.muSnackbar)
                      setFieldValue(`muInfo.muSnackbar`, null, false)
                    }
                    enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
                      variant: 'success'
                    })
                    setSaving(false)
                  })
                } else {
                  if (values.muInfo.muSnackbar) {
                    closeSnackbar(values.muInfo.muSnackbar)
                    setFieldValue(`muInfo.muSnackbar`, null, false)
                  }
                  enqueueSnackbar(<Trans>Successfully submitted!</Trans>, {
                    variant: 'success'
                  })
                }
              } else {
                if (values.muInfo.muSnackbar) {
                  closeSnackbar(values.muInfo.muSnackbar)
                  setFieldValue(`muInfo.muSnackbar`, null, false)
                }
                setSaving(false)
                if (requestType === 'Save') {
                  enqueueSnackbar(
                    <Trans>
                      Error ocurred while saving! Some fields were not saved!
                    </Trans>,
                    {
                      variant: 'error'
                    }
                  )
                } else {
                  enqueueSnackbar(<Trans>Error Submitting</Trans>, {
                    variant: 'error'
                  })
                }
              }
            }
          }
        })

        const streamUserInfoUpdated = grpcListenForUserInfoUpdated({
          id,
          onEventRecieved: ({ userId, info }) => {
            const formikRef = formik.current
            const { values, setFieldValue } = formikRef
            const toSet = { ...values.muUsers }
            toSet[userId] = info
            setFieldValue(`muUsers`, toSet, false)
          }
        })

        const streamFieldCommentChanged = grpcListenForFieldCommentChangedEvent(
          {
            id,
            onEventRecieved: ({ content, fieldId, operation }) => {
              console.log('field comment changed', content, fieldId, operation)
            }
          }
        )

        const resetCursorDetect = () => {
          if (mouseDetectRef && mouseDetectRef.current) {
            mouseDetectRef.current.reset()
          }
        }
        window.addEventListener('wheel', resetCursorDetect)
        const mouseMoveInterval = setInterval(() => {
          const ref = mouseDetectRef && mouseDetectRef.current
          const formikRef = formik.current
          const muUsers = formikRef.values.muUsers
          if (ref) {
            const { elementDimensions, isPositionOutside, position } = ref.state
            if (!isPositionOutside) {
              const { x, y } = position
              if (muUsers && muUsers[user.userId]) {
                const oldX = muUsers[user.userId].coordinates?.x
                if (Math.abs(oldX - x) < 10) {
                  return
                }
              }
              const xPercent = Number(
                Number(x / elementDimensions.width).toFixed(2)
              )
              const yPercent = Number(
                Number(y / elementDimensions.height).toFixed(2)
              )
              moveMouseCursor({
                userId: user.userId,
                formId: id,
                x,
                y,
                xPercent,
                yPercent
              })
            }
          }
        }, 500)

        return () => {
          closeSnackbar(multiuserSnackbar)
          streamFieldLocked.close()
          streamChatMessage.close()
          streamMouseMoved.close()
          streamEndEditing.close()
          streamStartEditing.close()
          streamSFSaveRequested.close()
          streamSFSaveResult.close()
          streamUserInfoUpdated.close()
          streamFieldCommentChanged.close()
          clearInterval(mouseMoveInterval)
          window.removeEventListener('wheel', resetCursorDetect)
        }
      }
    }, [useMultiuser, haveConnectionToServer])

    const checkFormLocksForCommit = handle => {
      const formikRef = formik.current
      if (!formikRef && handle) {
        clearInterval(handle)
      } else if (formikRef) {
        const { values, setFieldValue } = formikRef
        let isFieldLocked
        Object.values(values.muInfo).forEach(obj => {
          if (obj && obj.locked) {
            isFieldLocked = true
          }
        })
        if (!isFieldLocked) {
          if (handle) {
            clearInterval(handle)
          }
          setFieldValue(`muInfo.waitingForCommit`, false, false)
          commitFormCache({
            values,
            userId: user.userId,
            formId: id
          })
          // commit revision
        } else if (!handle) {
          setFieldValue(`muInfo.waitingForCommit`, true, false)
          const handle = setInterval(() => {
            checkFormLocksForCommit(handle)
          }, 1000)
        }
      }
    }

    useEffect(() => {
      if (formik.current) {
        const { values, setFieldValue } = formik.current
        if (haveConnectionToServer && values.muInfo.disconnected) {
          setFieldValue(`muInfo.disconnected`, false, false)
          console.log('connection restored')
          getCurrentFormState({
            formId: id,
            userId: user.userId,
            onSuccess: formState => {
              grpcFetchAllUsersInfo({
                id,
                userId: user.userId,
                onFail: e => {
                  enqueueSnackbar(
                    <Trans>
                      Could not connect to server for multiuser editing
                    </Trans>,
                    {
                      variant: 'error'
                    }
                  )
                },
                onSuccess: ({ users }) => {
                  grpGetLockedFieldsForForm({
                    formId: id,
                    userId: user.userId,
                    onSuccess: locksList => {
                      console.log('all users', users)
                      const { values, setValues } = formik.current
                      const colorsUsed = Object.values(users).map(
                        info => info.color
                      )
                      const colorsAvaliable = [...multiuserColors].filter(
                        color => !colorsUsed.includes(color)
                      )
                      const muInfo = { chat: [] }
                      if (values.muInfo.chat) {
                        muInfo.chat = [...values.muInfo.chat]
                      }
                      const myLock = values.muInfo.lockId
                      locksList.forEach(lock => {
                        muInfo[lock.fieldId] = {
                          locked: true,
                          user: lock.lockedBy
                        }
                        if (myLock && myLock === lock.lockId) {
                          muInfo.lockId = lock.lockId
                        }
                      })
                      dispatch(setEditingUsers(users))
                      const userColor = users[user.userId]
                        ? users[user.userId].color
                        : colorsAvaliable[0] || multiuserColors[0]
                      grpcFetchChatMessages({
                        userId: user.userId,
                        formId: id,
                        onSuccess: messeges => {
                          muInfo.chat = [
                            ...muInfo.chat,
                            ...messeges.map(obj => {
                              let color, name
                              if (obj.userId === user.userId) {
                                color = userColor
                                name = user.displayName
                              } else {
                                color = users[obj.userId].color
                                name = users[obj.userId].name
                              }
                              return {
                                ...obj,
                                color,
                                name
                              }
                            })
                          ]
                          setValues({
                            ...values,
                            ...formState,
                            muInfo,
                            muUsers: users
                          })
                        }
                      })

                      if (!users[user.userId]) {
                        grpcStartEditingForm({
                          userId: user.userId,
                          formId: id,
                          onFail: e => {
                            enqueueSnackbar(
                              <Trans>
                                Could not connect to server for multiuser
                                editing
                              </Trans>,
                              {
                                variant: 'error'
                              }
                            )
                          },
                          userInfo: JSON.stringify({
                            id: user.userId,
                            step: 0,
                            sessionId: crypto.randomBytes(16).toString('hex'),
                            startEditingTime: moment.utc(),
                            name: user.displayName,
                            color: userColor
                          })
                        })
                      }
                    }
                  })
                }
              })
            }
          })
        }
      }
    }, [haveConnectionToServer])

    useEffect(() => {
      if (useMultiuser) {
        const handle = setInterval(() => {
          pingServer({
            userId: user.userId,
            formId: id,
            onReject: () => {
              const { setFieldValue, values } = formik.current
              setHaveConnectionToServer(false)
              if (!values.muInfo.disconnected) {
                setFieldValue(`muInfo.disconnected`, true, false)
              }
            },
            onSuccess: () => {
              setHaveConnectionToServer(true)
            }
          })
        }, 5000)
        return () => {
          clearInterval(handle)
        }
      }
    }, [useMultiuser])

    useEffect(() => {
      if (useMultiuser) {
        const minutes = 3
        const handle = setInterval(() => {
          const formikRef = formik.current
          const { values } = formikRef
          if (!values.muInfo.waitingForCommit && !values.muInfo.disconnected) {
            checkFormLocksForCommit()
          }
        }, 60000 * minutes)
        return () => {
          clearInterval(handle)
        }
      }
    }, [useMultiuser])

    const elementsMap = mapFormElements(data, langFR)

    const scrollToTop = () => {
      console.log('scrollbarContentRef ref', scrollbarContentRef)
      if (scrollbarContentRef && scrollbarContentRef.current) {
        setTimeout(() => {
          scrollbarContentRef.current.scrollToTop()
          scrollbarContentRef.current.handleWindowResize()
          //scrollbarContentRef.current.scrollTop = 0
          //scrollbarContentRef.current.updateScroll()
          console.log('scrollbarContentRef update Scrolls', scrollbarContentRef)
        }, 500)
      }
    }

    const handleObjectMissing = () => {
      setInsufficientAccess(true)
      enqueueSnackbar(<Trans>Not all objects could be loaded!</Trans>, {
        variant: 'error'
      })
    }

    const fetchData = ({ multiuserReload = false }) => {
      return Promise.all([fetchFormPage(formId), getNetwork()])
        .then(([result, network]) => {
          console.log('form loaded', result, network)
          const { injectableComponents } = result
          setNetwork(network)
          setData(result)
          setValidationSchema(
            constructValidationSchema({
              data: result,
              english: user.language === 'en_US'
            })
          )

          if (result.objectsConnected && result.objectsConnected.length > 0) {
            if (id) {
              let noId
              const objArray = id.split(';')
              objArray.forEach((string, index) => {
                if (string.includes('NO_OBJECT_ID')) {
                  noId = true
                }
                const ident = string.split('=')[0]
                let objInfo
                result.objectsConnected.some(obj => {
                  if (obj.identId === ident) {
                    objInfo = obj
                  }
                  return obj.identId === ident
                })
                if (objInfo && objInfo.type === 'User') {
                  objArray[index] = ident + '=' + user.userId
                }
              })
              if (noId) {
                enqueueSnackbar(
                  <Trans>
                    Ids were not provided for all objects used in this form.
                    Contact your administrator
                  </Trans>,
                  { variant: 'error' }
                )
                return Promise.reject()
              }
              if (objArray.join(';') !== id) {
                history.push(
                  '/elasticform/' + formId + '/' + objArray.join(';')
                )
                return Promise.reject()
              }
            }
            return connectedObjectQuery(result, {
              setupDescribeMap: setDescribeMap,
              handleObjectMissing,
              langFR,
              id,
              enqueueSnackbar
            }).then(
              connectedMap => {
                let preventAccess
                let shouldDisable = false
                Object.values(connectedMap).forEach(data => {
                  const obj = data.sfObject
                  const additionalInfo = data.additionalInfo
                  const objName = obj.attributes.type
                  Object.values(appConfigurations)
                    .filter(config => config && typeof config === 'object')
                    .forEach(config => {
                      const sameForm = config.form && config.form === formId
                      const sameRecordType =
                        obj.RecordType &&
                        obj.RecordType.Id === config.recordType
                      if (sameForm && sameRecordType) {
                        setConfiguration(config)
                      }
                    })

                  if (
                    obj.UserRecordAccess &&
                    !obj.UserRecordAccess.HasEditAccess
                  ) {
                    shouldDisable = true
                  }

                  if (result.readOnly || returnPdf || displayView) {
                    shouldDisable = true
                  }
                  if (!isPreview) {
                    if (objName === 'Opportunity') {
                      if (
                        obj.StageName !== opportunitieStages.IN_PROGRESS &&
                        obj.StageName !== opportunitieStages.MORE_INFO_REQUIERED
                      ) {
                        shouldDisable = true
                      }
                      if (
                        obj.ProcessInstances &&
                        obj.ProcessInstances.records
                      ) {
                        if (
                          obj.ProcessInstances.records.some(
                            process => process.Status === 'Pending'
                          )
                        ) {
                          enqueueSnackbar(
                            <Trans>
                              Application is Locked: Contact the Administrator
                            </Trans>,
                            { variant: 'error' }
                          )
                          shouldDisable = true
                        }
                      }
                    } else if (objName === 'TechnicalAdvisoryAssignment__c') {
                      if (obj.Status__c === 'Submitted') {
                        shouldDisable = true
                      }
                    } else if (objName === 'TechnicalAdvisoryAssignment__c') {
                      if (obj.Status__c === 'Submitted') {
                        shouldDisable = true
                      }
                    } else if (
                      objName === 'Account' &&
                      result.restrictAccessForRoles
                    ) {
                      let userRole
                      additionalInfo.accountMembers.some(member => {
                        if (member.UserId === user.userId) {
                          userRole = member.TeamMemberRole
                          return true
                        }
                        return false
                      })
                      if (
                        result.restrictAccessForRoles[userRole] ||
                        !userRole
                      ) {
                        const restricType =
                          result.restrictAccessForRoles[userRole]

                        if (restricType === 'preventAccessBlock') {
                          enqueueSnackbar(
                            <Trans>
                              Your member role has insufficient access to view
                              this page!
                            </Trans>,
                            {
                              variant: 'error'
                            }
                          )
                          history.push('/grants/GrantsHome')
                          preventAccess = true
                        } else if (restricType === 'disable') {
                          shouldDisable = true
                          setWrongAccountRole('warning')
                        } else if (restricType === 'preventAccessMessage') {
                          setWrongAccountRole('prevent')
                        }
                      }
                    }
                  }
                })

                setDisabled(shouldDisable)
                const fakeInitialValues = getInitialValues({
                  data: result,
                  connectedMap
                })
                const map = mapFormElements(result, langFR)
                const contacts = []
                const accounts = []
                Object.keys(map).forEach(key => {
                  const question = map[key]
                  const value = fakeInitialValues[key]
                  if (question.elementType === 'connectContact') {
                    if (!contacts.includes(value) && value) {
                      contacts.push(value)
                    }
                  } else if (question.elementType === 'connectAccount') {
                    if (!accounts.includes(value) && value) {
                      accounts.push(value)
                    }
                  }
                })
                const mapPromise =
                  contacts.length > 0 || accounts.length > 0
                    ? Promise.all([
                        getContactsMap(contacts),
                        getAccountsMap(accounts)
                      ])
                    : Promise.resolve().then(r => [{}, {}])

                return mapPromise.then(([contactsMap, accountsMap]) => {
                  setPdfDisplay(result.showPdfDownload)
                  setReadOnly(result.readOnly || displayView)
                  setConnectedMap(connectedMap)
                  const initialValues = getInitialValues({
                    data: result,
                    connectedMap,
                    contactsMap,
                    accountsMap
                  })

                  const multiuser =
                    Boolean(result.enableMultiuser && id) &&
                    checkAuth(authRoles.tester, user.role) &&
                    !(result.readOnly || displayView) &&
                    !shouldDisable
                  setUseMultiuser(multiuser)
                  if (multiuser && !multiuserReload) {
                    setInitialValues(initialValues)
                    setLoading(preventAccess)
                    tryInitiatingForm({
                      formId: id,
                      userId: user.userId,
                      initialValues,
                      onFail: e => {
                        enqueueSnackbar(
                          <Trans>
                            Could not connect to server for multiuser editing
                          </Trans>,
                          {
                            variant: 'error'
                          }
                        )
                      },
                      onSuccess: (inited, formState) => {
                        setHaveConnectionToServer(true)
                        console.log('form inited', inited, formState)
                        grpcFetchAllUsersInfo({
                          id,
                          userId: user.userId,
                          onFail: e => {
                            enqueueSnackbar(
                              <Trans>
                                Could not connect to server for multiuser
                                editing
                              </Trans>,
                              {
                                variant: 'error'
                              }
                            )
                          },
                          onSuccess: ({
                            users,
                            isAnotherUser,
                            sessionStartTime
                          }) => {
                            console.log(
                              'all users',
                              users,
                              isAnotherUser,
                              sessionStartTime
                            )
                            grpGetLockedFieldsForForm({
                              formId: id,
                              userId: user.userId,
                              onSuccess: locksList => {
                                const colorsUsed = Object.values(users).map(
                                  info => info.color
                                )
                                const colorsAvaliable = [
                                  ...multiuserColors
                                ].filter(color => !colorsUsed.includes(color))

                                const toSet =
                                  isAnotherUser && formState
                                    ? formState
                                    : initialValues
                                let muInfo = toSet.muInfo || {}
                                locksList.forEach(lock => {
                                  const isUserInForm =
                                    users[lock.lockedBy] &&
                                    lock.lockedBy !== user.userId
                                  if (isUserInForm) {
                                    muInfo[lock.fieldId] = {
                                      locked: true,
                                      user: lock.lockedBy
                                    }
                                  } else {
                                    unlockFieldWithoutChanges({
                                      lockId: lock.lockId,
                                      fieldId: lock.fieldId,
                                      formId: id,
                                      userId: user.userId
                                    })
                                  }
                                })
                                let color = colorsAvaliable[0]
                                if (users[user.userId]) {
                                  color = users[user.userId].color
                                }
                                delete users[user.userId]
                                dispatch(setEditingUsers(users))
                                //if (!result[user.userId]) {
                                const sessionId = crypto
                                  .randomBytes(16)
                                  .toString('hex')
                                setMuSessionId(sessionId)
                                if (isAnotherUser) {
                                  grpcFetchChatMessages({
                                    userId: user.userId,
                                    formId: id,
                                    sessionStartTime,
                                    onSuccess: messeges => {
                                      muInfo.chat = messeges.map(obj => {
                                        let userColor, name
                                        if (obj.userId === user.userId) {
                                          userColor = color
                                          name = user.displayName
                                        } else {
                                          userColor = users[obj.userId]?.color
                                          name = users[obj.userId]?.name
                                        }
                                        return {
                                          ...obj,
                                          color: userColor,
                                          name
                                        }
                                      })
                                      let usersToSet = { ...users }
                                      if (formik.current) {
                                        const currentUsers =
                                          formik.current.values.muUsers
                                        usersToSet = {
                                          ...usersToSet,
                                          ...currentUsers
                                        }
                                      }
                                      setInitialValues({
                                        ...toSet,
                                        muInfo,
                                        muUsers: usersToSet
                                      })
                                      setLoading(preventAccess)
                                    }
                                  })
                                } else {
                                  setInitialValues({
                                    ...toSet,
                                    muInfo,
                                    muUsers: users
                                  })
                                  setLoading(preventAccess)
                                }
                                grpcStartEditingForm({
                                  userId: user.userId,
                                  formId: id,
                                  onFail: e => {
                                    enqueueSnackbar(
                                      <Trans>
                                        Could not connect to server for
                                        multiuser editing
                                      </Trans>,
                                      {
                                        variant: 'error'
                                      }
                                    )
                                  },
                                  userInfo: JSON.stringify({
                                    id: user.userId,
                                    step: 0,
                                    sessionId,
                                    startEditingTime: moment.utc(),
                                    name: user.displayName,
                                    color
                                  })
                                })
                                //}
                              }
                            })
                          }
                        })
                      }
                    })
                  } else if (multiuser && multiuserReload) {
                    const { values } = formik.current
                    setInitialValues({
                      ...initialValues,
                      muUsers: values.muUsers,
                      muInfo: values.muInfo
                    })
                    setLoading(preventAccess)
                  } else {
                    setInitialValues(initialValues)
                    setLoading(preventAccess)
                  }
                  if (injectableComponents) {
                    const toSet = {}
                    injectableComponents.forEach(component => {
                      toSet[component.injectableId] = component
                    })
                    setInjectablesMap(toSet)
                  }
                })
              },
              reject => {
                console.error('no object found', reject)
                enqueueSnackbar(<Trans>No object found!</Trans>, {
                  variant: 'error'
                })
              }
            )
          } else {
            setInitialValues(
              getInitialValues({
                data: result,
                connectedMap: {}
              })
            )
            setLoading(false)
          }
        })
        .catch(error => {
          console.error('error loading form', error)
        })
    }

    const trySaving = useCallback(
      ({ values, type = 'Save' }) => {
        if (
          overrideWarningData ||
          !SFAuthService.user ||
          readOnly ||
          saving ||
          saveFailedData
        ) {
          return
        }
        setSaving(true)

        if (useMultiuser) {
          return grpcRequestSFSave({
            formId: id,
            userId: user.userId,
            type
          })
        } else {
          const savingSnackbar = enqueueSnackbar(null, {
            persist: true,
            content: key => ProgressSnackbar(<Trans>Saving</Trans>)
          })

          const handleError = err => {
            console.error('error saving form', err)
            closeSnackbar(savingSnackbar)
            setSaving(false)
            enqueueSnackbar(
              <Trans>
                Error ocurred while saving! Some fields were not saved!
              </Trans>,
              {
                variant: 'error'
              }
            )
          }

          if (!data.displayesSavedInMeantimeWarning || useMultiuser) {
            return handleSave({ values, snackbar: savingSnackbar }).catch(
              err => {
                handleError(err)
              }
            )
          }

          return connectedObjectQuery(data, {
            id,
            langFR,
            enqueueSnackbar,
            handleObjectMissing
          })
            .then(currentConnectedMap => {
              if (Object.keys(currentConnectedMap).length === 0) {
                enqueueSnackbar(<Trans>You lost connection!</Trans>, {
                  variant: 'error'
                })
                return Promise.reject()
              }
              const wasSavedInMeantime = Object.keys(currentConnectedMap).some(
                key => {
                  const objectSaved = currentConnectedMap[key].sfObject
                  const objectNow = connectedMap[key].sfObject
                  const savedDate = moment.utc(objectSaved.LastModifiedDate)
                  const currentDate = moment.utc(objectNow.LastModifiedDate)
                  return savedDate.isAfter(currentDate)
                }
              )
              if (wasSavedInMeantime) {
                const dataToPass = {
                  type: 'form'
                }
                const savedValues = getInitialValues({
                  data,
                  connectedMap: currentConnectedMap
                })

                const current = {}
                const saved = {}
                const map = mapFormElements(data, langFR)
                const contacts = []
                const accounts = []
                Object.keys(map).forEach(key => {
                  const question = map[key]
                  if (question.elementType === 'connectContact') {
                    if (
                      savedValues[key] &&
                      savedValues[key] !== values[key]?.id
                    ) {
                      contacts.push(savedValues[key])
                    }
                  } else if (question.elementType === 'connectAccount') {
                    if (
                      savedValues[key] &&
                      savedValues[key] !== values[key]?.id
                    ) {
                      accounts.push(savedValues[key])
                    }
                  }
                })
                const mapPromise =
                  contacts.length > 0 || accounts.length > 0
                    ? Promise.all([
                        getContactsMap(contacts),
                        getAccountsMap(accounts)
                      ])
                    : Promise.resolve().then(r => [{}, {}])
                return mapPromise.then(([contactsMap, accountsMap]) => {
                  closeSnackbar(savingSnackbar)
                  Object.keys(values).forEach(key => {
                    const question = map[key]
                    if (question) {
                      dataToPass[key] = question
                      const toText =
                        formElementTypes[question.elementType].valueToText
                      const parseValue =
                        formElementTypes[question.elementType]
                          .parseValueToCompare
                      const { isConnected } = question.typeProps
                      let connectedFieldDetails
                      const { connectedField, connectedObject } =
                        getMainConnected(question)
                      if (isConnected && connectedField) {
                        connectedFieldDetails = extractFieldDetails({
                          connectedField,
                          connectedMap,
                          describeMap,
                          connectedObject
                        })
                      }
                      if (toText) {
                        current[key] = {
                          value: parseValue
                            ? parseValue(values[key])
                            : values[key],
                          ...toText(values[key], question, {
                            contactsMap,
                            accountsMap,
                            connectedFieldDetails
                          })
                        }
                        saved[key] = {
                          value: parseValue
                            ? parseValue(savedValues[key])
                            : savedValues[key],
                          ...toText(savedValues[key], question, {
                            contactsMap,
                            accountsMap,
                            connectedFieldDetails
                          })
                        }
                      } else {
                        console.warn(
                          'No value to text function configured for: ',
                          question.elementType
                        )
                      }
                    }
                  })
                  setOverrideWarningData({
                    current,
                    saved,
                    formData: dataToPass
                  })
                  return Promise.resolve()
                })
              } else {
                handleSave({ values, snackbar: savingSnackbar })
              }
            })
            .catch(err => {
              handleError(err)
            })
        }
      },
      [connectedMap, saving, saveFailedData, overrideWarningData, useMultiuser]
    )

    useEffect(() => {
      if ((formId && id) || !id) {
        setLoading(true)
        fetchData({})
      }
    }, [id, formId])

    useEffect(() => {
      if (id && Object.keys(connectedMap).length > 0 && !preview) {
        const objArray = id.split(';')
        objArray.forEach((string, index) => {
          const ident = string.split('=')[0]
          const obj = connectedMap[ident]
          const connectedObject = obj.sfObject
          if (
            connectedObject &&
            connectedObject.attributes.type === 'Account'
          ) {
            objArray[index] = ident + '=' + organization.id
          }
        })
        if (objArray.join(';') !== id) {
          history.push('/elasticform/' + formId + '/' + objArray.join(';'))
        }
      }
    }, [organization.id])

    useEffect(() => {
      // This fixes issues with bold font not being used in first render of pdf
      Font.load({ fontFamily: 'Roboto' })
      Font.load({ fontFamily: 'Roboto', fontStyle: 'italic' })
      Font.load({ fontFamily: 'Roboto', fontWeight: 700 })
      Font.load({ fontFamily: 'Roboto', fontWeight: 700, fontStyle: 'italic' })
    }, [])

    useEffect(() => {
      const minutes = data && Number(data.autosave)
      if (loading || !minutes) {
        return
      }
      if (minutes !== 0) {
        const handle = setInterval(() => {
          const formikRef = formik.current
          if (formikRef && isTrueDirty()) {
            if (useMultiuser) {
              //only first user should commence autosave
              grpcFetchAllUsersInfo({
                id,
                userId: user.userId,
                onSuccess: ({ users }) => {
                  let myUserInfo
                  Object.values(users).some(obj => {
                    if (obj.id === user.userId) {
                      myUserInfo = obj
                      return true
                    }
                    return false
                  })
                  if (myUserInfo && myUserInfo.logOrder === 0) {
                    trySaving({ values: formikRef.values, type: 'Autosave' })
                  }
                }
              })
            } else {
              trySaving({ values: formikRef.values })
            }
          }
        }, 60000 * minutes)
        return () => {
          clearInterval(handle)
        }
      }
    }, [
      currentStep,
      loading,
      trySaving,
      initialValues,
      useMultiuser,
      id,
      user.userId
    ])

    useEffect(() => {
      if (data) {
        setValidationSchema(
          constructValidationSchema({
            data,
            english: user.language === 'en_US'
          })
        )
        connectedObjectQuery(data, {
          id,
          returnOnlyDescribe: true,
          langFR: user.language !== 'en_US',
          enqueueSnackbar,
          handleObjectMissing
        }).then(describeResult => {
          const newMap = { ...connectedMap }
          Object.values(newMap).forEach(obj => {
            const { objectType } = obj
            const sfData = describeResult[objectType]
            obj.fieldsMap = sfData.fieldsMap
          })
          setConnectedMap(newMap)
          setDescribeMap(describeResult)
        })
      }
    }, [user.language])

    useEffect(() => {
      if (formik.current) {
        formik.current.validateForm()
      }
    }, [validationSchema])

    const addMethodToValidationSchema = (method, id) => {
      const yupObj = constructValidationSchema({
        data,
        english: user.language === 'en_US',
        returnRaw: true
      })
      if (!yupObj[id]) {
        yupObj[id] = method
      } else {
        yupObj[id].concat(method)
      }
      setValidationSchema(Yup.object().shape(yupObj))
    }

    const muHandleChangeStep = index => {
      const formikRef = formik.current
      const toSet = formikRef.values
      const newMuUsers = { ...toSet.muUsers }
      if (newMuUsers[user.userId]) {
        grpcUpdateUserInfo({
          formId: id,
          userId: user.userId,
          userInfo: JSON.stringify({
            ...newMuUsers[user.userId],
            step: index
          })
        })
      }
    }

    const handleNext = () => {
      const toSet = currentStep + 1
      scrollToTop()
      setStep(toSet)
      if (useMultiuser) {
        muHandleChangeStep(toSet)
      }
    }

    const handleBack = () => {
      const toSet = currentStep - 1
      scrollToTop()
      setStep(toSet)
      if (useMultiuser) {
        muHandleChangeStep(toSet)
      }
    }

    const handleSave = ({ values, snackbar }) => {
      const savingSnackbar =
        snackbar ||
        enqueueSnackbar(null, {
          persist: true,
          content: key => ProgressSnackbar(<Trans>Saving</Trans>)
        })

      const isValid = Object.keys(formik.current.errors).length === 0
      return handleFormSave({
        values,
        elementsMap,
        connectedMap,
        reduxBag: {
          dispatch,
          user,
          organization,
          avaliableOrganizations,
          appConfigurations
        },
        utilityBag: { closeSnackbar, enqueueSnackbar, reloadLastModifiedDates }
      }).then(
        result => {
          fetchData({ multiuserReload: useMultiuser }).then(r => {
            console.log('form saved', result)
            closeSnackbar(savingSnackbar)
            setSaving(false)
            enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
              variant: 'success'
            })

            if (saveCallback) {
              saveCallback({
                isValid
              })
            }
            if (
              formId === appConfigurations.FORM_USER_PROFILE &&
              !organization.id &&
              hasRole(user.role, authRoles.grantee) &&
              !user.userObject.redirectedToJoinOrganization
            ) {
              history.push('/grants/JoinOrganization')
              saveUser({
                Id: user.userId,
                Redirected_To_Join_Organization__c: true
              })
            }
          })
        },
        reject => {
          console.error(reject)
          closeSnackbar(savingSnackbar)
          setSaving(false)
          if (
            data.displaySaveFailedDialog &&
            reject === DEFAULT_FORM_SAVE_REJECT
          ) {
            const current = {}
            const map = mapFormElements(data, langFR)
            const dataToPass = {
              type: 'form'
            }
            Object.keys(values).forEach(key => {
              const question = map[key]
              if (question) {
                dataToPass[key] = question
                const toText =
                  formElementTypes[question.elementType].valueToText
                const parseValue =
                  formElementTypes[question.elementType].parseValueToCompare
                const { isConnected } = question.typeProps
                let connectedFieldDetails
                const { connectedField, connectedObject } =
                  getMainConnected(question)
                if (isConnected && connectedField) {
                  connectedFieldDetails = extractFieldDetails({
                    connectedField,
                    connectedMap,
                    describeMap,
                    connectedObject
                  })
                }
                if (toText) {
                  current[key] = {
                    value: parseValue
                      ? parseValue(values[key], { saveFailed: true })
                      : values[key],
                    ...toText(values[key], question, {
                      connectedFieldDetails,
                      saveFailed: true
                    })
                  }
                } else {
                  console.warn(
                    'No value to text function configured for: ',
                    question.elementType
                  )
                }
              }
            })
            setSaveFailedData({
              current,
              formData: dataToPass
            })
          } else {
            enqueueSnackbar(
              <Trans>
                Error ocurred while saving! Some fields were not saved!
              </Trans>,
              {
                variant: 'error'
              }
            )
          }
          return reject
        }
      )
    }

    const reloadLastModifiedDates = () => {
      return connectedObjectQuery(data, {
        enqueueSnackbar,
        langFR,
        id,
        handleObjectMissing
      }).then(queryResult => {
        const toSet = { ...connectedMap }
        Object.keys(toSet).forEach(key => {
          const setObj = toSet[key].sfObject
          const nowObj = queryResult[key]?.sfObject
          if (nowObj && setObj && setObj.LastModifiedDate) {
            setObj.LastModifiedDate = nowObj.LastModifiedDate
          }
        })
        setConnectedMap(toSet)
      })
    }

    const handleSubmit = ({ values }) => {
      setSaving(true)
      const submitText = <Trans>Submitting</Trans>

      const snackKey = enqueueSnackbar(null, {
        variant: 'info',
        persist: true,
        content: key => ProgressSnackbar(submitText)
      })

      const baseHandleError = (error, snackbarText) => {
        console.log('error submitting', error)
        setSaving(false)
        closeSnackbar(snackKey)
        enqueueSnackbar(snackbarText || <Trans>Error Submitting</Trans>, {
          variant: 'error'
        })
      }

      const baseHandleSuccess = ({ result, successText }) => {
        console.log('submitted and got ', result)
        return fetchData({ multiuserReload: useMultiuser }).then(() => {
          closeSnackbar(snackKey)
          setSaving(false)
          enqueueSnackbar(successText, {
            variant: 'success'
          })
        })
      }

      return Promise.all([
        handleFormSave({
          values,
          elementsMap,
          connectedMap,
          reduxBag: {
            dispatch,
            user,
            organization,
            avaliableOrganizations
          },
          utilityBag: {
            closeSnackbar,
            enqueueSnackbar,
            reloadLastModifiedDates
          }
        }),
        fetchFormPages()
      ]).then(([result, formPages]) => {
        if (noConnectedObjects) {
          enqueueSnackbar(<Trans>There is no object to submit!</Trans>, {
            variant: 'error'
          })
        } else {
          const promises = []
          Object.values(connectedMap).forEach(data => {
            const connectedObject = data.sfObject
            const type = connectedObject.attributes.type
            if (type === 'Opportunity') {
              const organizationDetailsFormId =
                appConfigurations.FORM_ORGANIZATION_DETAILS
              let organizationDetailsForm
              organizationDetailsFormId &&
                formPages.some(form => {
                  if (organizationDetailsFormId === form.id) {
                    organizationDetailsForm = form.config
                  }
                  return organizationDetailsFormId === form.id
                })
              let checkOrganizationValidity = Promise.resolve(true)
              if (organizationDetailsForm) {
                checkOrganizationValidity = checkFormValidity({
                  formId: organizationDetailsFormId,
                  id: constructFormAddressString({
                    user,
                    organization,
                    configuration,
                    objectsConnected: organizationDetailsForm.objectsConnected
                  })
                })
              }
              promises.push(
                checkOrganizationValidity.then(isValid => {
                  if (!isValid) {
                    baseHandleError(
                      'organization details form is not valid',
                      <Trans>
                        You cannot submit the application until you fill out all
                        field marked as required in the Organisation Details
                      </Trans>
                    )
                    return Promise.reject(
                      new Error('organization details form is not valid')
                    )
                  } else {
                    return submitOpportunity(connectedObject.Id)
                      .then(res => {
                        return baseHandleSuccess({
                          result,
                          successText: <Trans>Submitted Application</Trans>
                        }).then(result => {
                          history.push('/grants/ApplicationsList')
                        })
                      })
                      .catch(error => {
                        baseHandleError(error)
                      })
                  }
                })
              )
            }
            if (type === 'TechnicalAdvisoryAssignment__c') {
              promises.push(
                submitTechnicalAdvisory(connectedObject.Id).then(
                  result => {
                    return baseHandleSuccess({
                      result,
                      successText: (
                        <Trans>Submitted Technical Advisory Assigment</Trans>
                      )
                    })
                  },
                  reject => {
                    baseHandleError(reject)
                  }
                )
              )
            }
          })
          return Promise.all(promises)
        }
      })
    }

    const returnInDialog = ({ component, loading, disableSave }) => {
      if (loading) {
        return (
          <Dialog open maxWidth='md' fullWidth>
            <DialogTitle>
              <Grid
                container
                wrap='nowrap'
                alignItems='flex-end'
                justifyContent='flex-end'
              >
                <Grid item>
                  <IconButton
                    onClick={() => {
                      onDialogClose()
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ width: '500px', height: '300px' }}>
              <Loading />
            </DialogContent>
          </Dialog>
        )
      }
      const formTitle = parseDisplayedText({
        text: langFR ? data.titleFR : data.titleEN,
        french: langFR,
        objectsFieldsMap,
        describeMap,
        injectablesMap,
        returnString: true
      })
      return (
        <Dialog
          open
          maxWidth='md'
          fullWidth
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogTitle>
            <Grid
              container
              wrap='nowrap'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs>
                <Typography
                  variant='h6'
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {formTitle}
                </Typography>
              </Grid>

              {Boolean(readOnly && printRef) && (
                <Grid item>
                  <ReactToPrint
                    onAfterPrint={() => (document.title = defaultDocTitle)}
                    onBeforePrint={() => (document.title = formTitle)}
                    trigger={() => (
                      <IconButton
                        disabled={disableSave}
                        aria-label={<Trans>Print</Trans>}
                        className=''
                      >
                        <Icon>print</Icon>
                      </IconButton>
                    )}
                    content={() => printRef.current}
                  />
                </Grid>
              )}
              <Grid item>
                <IconButton
                  onClick={() => {
                    onDialogClose()
                  }}
                >
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>{component}</DialogContent>
        </Dialog>
      )
    }

    const checkIfFormValidationShouldRebuild = ({ sections, values }) => {
      let shouldRebuild = false
      const requiredFromConditions = []
      const nonRequiredFromConditions = []
      const validationInfoFromConditions = {}
      const errors = formik && formik.current ? formik.current.errors : {}
      const checkRequiredConditions = item => {
        if (item.elements) {
          item.elements.forEach(element => {
            checkRequiredConditions(element)
          })
        }
        if (item.conditions) {
          item.conditions
            .filter(condition =>
              ['required', 'notRequired', 'minFiles'].includes(condition.state)
            )
            .forEach(condition => {
              const { conditionMet, state } = isConditionMet({
                condition,
                elementsMap,
                values,
                langFR,
                connectedMap,
                errors
              })
              const yupField = validationSchema.fields[item.id]
              if (conditionMet) {
                if (state === 'notRequired') {
                  nonRequiredFromConditions.push(item.id)
                } else {
                  requiredFromConditions.push(item.id)
                }
                if (yupField && state === 'notRequired') {
                  shouldRebuild = true
                } else if (!yupField && state !== 'notRequired') {
                  shouldRebuild = true
                }
              } else {
                if (
                  yupField &&
                  state !== 'notRequired' &&
                  !item.typeProps.required
                ) {
                  shouldRebuild = true
                } else if (!yupField && state === 'notRequired') {
                  shouldRebuild = true
                } else if (item.typeProps.required && !yupField) {
                  shouldRebuild = true
                }
              }

              if (state === 'minFiles') {
                if (conditionMet) {
                  validationInfoFromConditions[item.id] = {
                    minFiles: +condition.minFiles
                  }
                }
                if (yupField) {
                  const current = +yupField._meta.current
                  const parameter = +condition.minFiles
                  if (
                    current &&
                    parameter &&
                    current !== parameter &&
                    conditionMet
                  ) {
                    shouldRebuild = true
                  }
                  if (
                    current &&
                    parameter &&
                    current === parameter &&
                    !conditionMet
                  ) {
                    shouldRebuild = true
                  }
                }
              }
            })
        }
      }
      sections.forEach(section => checkRequiredConditions(section))
      if (shouldRebuild) {
        console.log(
          'validation change from conditions detected. Form will rebuild validation schema'
        )
        setValidationSchema(
          constructValidationSchema({
            data,
            requiredFromConditions,
            nonRequiredFromConditions,
            validationInfoFromConditions,
            english: user.language === 'en_US'
          })
        )
      }
    }

    if (loading || !initialValues) {
      if (inDialog) {
        return returnInDialog({
          loading: true,
          disableSave: true
        })
      }
      return fixedDisplay || returnPdf ? (
        <Grid container>
          <Loading isNotFixed />
        </Grid>
      ) : (
        <Loading />
      )
    }
    const { sections } = data
    if (!sections) {
      return null
    }
    if (insufficientAccess) {
      return (
        <div style={{ padding: 15 }}>
          <Alert severity='error'>
            <AlertTitle>
              <Typography variant='h6'>
                <Trans>
                  You don't have access to all objects used in this form!
                </Trans>
              </Typography>
            </AlertTitle>
            <div style={{ marginTop: 5 }}>
              <Trans>You cannot view or edit this page</Trans>
            </div>
          </Alert>
        </div>
      )
    }

    if (wrongAccountRole === 'prevent') {
      return (
        <div style={{ padding: 15 }}>
          <Alert severity='error'>
            <AlertTitle>
              <Typography variant='h6'>
                <Trans>You cannot view or edit this page</Trans>
              </Typography>
            </AlertTitle>
            <div style={{ marginTop: 5 }}>
              {/* <Trans>You cannot view or edit this page</Trans>
              {'. '} */}
              <Trans>You can request higher organization access</Trans>{' '}
              <Link href='/grants/Organizations'>
                <Trans>here</Trans>
              </Link>
            </div>
          </Alert>
        </div>
      )
    }

    const objectsFieldsMap = noConnectedObjects
      ? {}
      : getObjectsFieldsMap({ data, connectedMap, describeMap })

    const noStepper = sections.length < 2 || pdfDisplay
    let style = {
      padding: 20,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      //minHeight: '100%',
      userSelect: 'text'
    }
    try {
      if (data.style) {
        const parsed = JSON.parse(data.style)
        if (typeof parsed === 'object') {
          style = Object.assign(style, JSON.parse(data.style))
        }
      }
    } catch (e) {}

    const toReturn = (
      <Formik
        innerRef={formik}
        validateOnBlur
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        initialTouched={getInitialTouched(data)}
      >
        {({ values, validateForm, setFieldValue, errors }) => {
          console.log('values', values, saving)
          const dirty = isTrueDirty()
          const formTitle = parseDisplayedText({
            text: langFR ? data.titleFR : data.titleEN,
            french: langFR,
            injectablesMap,
            objectsFieldsMap,
            describeMap,
            renderProps: {
              connectedMap
            }
          })
          const formTitlePdf = parseDisplayedText({
            text: langFR ? data.titleFR : data.titleEN,
            french: langFR,
            objectsFieldsMap,
            describeMap,
            injectablesMap,
            returnString: true
          })

          const sectionTitle = parseDisplayedText({
            text: langFR
              ? sections[currentStep].titleFR
              : sections[currentStep].titleEN,
            french: langFR,
            objectsFieldsMap,
            describeMap,
            injectablesMap,
            renderProps: {
              connectedMap
            }
          })

          const disabledIds = getDisabledIds({
            sections,
            elementsMap,
            values,
            langFR,
            connectedMap,
            errors
          })
          checkIfFormValidationShouldRebuild({ sections, values })

          const pdfDocument = (
            <Document style={{ fontFamily: 'Roboto' }} title={formTitlePdf}>
              {sections.map((section, sIndex) => {
                let header, footer
                let pageMargin = 2.54
                if (data.pdfProps) {
                  const keys = ['footer', 'header']
                  const { pagePadding } = data.pdfProps
                  if (pagePadding) {
                    pageMargin = +pagePadding
                  }
                  keys.forEach(key => {
                    if (data.pdfProps[key]) {
                      const {
                        textFR,
                        textEN,
                        placement,
                        fontSize,
                        isPageNumber,
                        textProps = []
                      } = data.pdfProps[key]
                      const label = isPageNumber
                        ? '5'
                        : parseDisplayedText({
                            text: langFR ? textFR : textEN,
                            french: langFR,
                            describeMap,
                            objectsFieldsMap,
                            injectablesMap,
                            returnString: true
                          })
                      if (label || isPageNumber) {
                        const style = {
                          fontFamily: 'Roboto',
                          left: pageMargin + 'cm',
                          width: '100%',
                          position: 'absolute'
                        }
                        if (key === 'header') {
                          style.top = '1.27cm'
                        } else {
                          style.bottom = '1.27cm'
                        }

                        const textStyle = {
                          fontFamily: 'Roboto',
                          fontWeight: textProps.includes('bold') ? 700 : 400,
                          fontStyle: textProps.includes('italics')
                            ? 'italic'
                            : 'normal',
                          textDecoration:
                            textProps.includes('underline') && 'underline',
                          fontSize: +fontSize || pdfDefaultFontSize,
                          textAlign: placement || 'center'
                        }
                        const component = isPageNumber ? (
                          <View
                            fixed
                            style={style}
                            render={({ pageNumber, totalPages }) => (
                              <Text style={textStyle}>
                                {pageNumber + '/' + totalPages}
                              </Text>
                            )}
                          />
                        ) : (
                          <View fixed style={style}>
                            {textProps.includes('html') ? (
                              <Html
                                style={{
                                  fontSize: +fontSize || pdfDefaultFontSize
                                }}
                              >
                                {label}
                              </Html>
                            ) : (
                              <Text style={textStyle}>{label}</Text>
                            )}
                          </View>
                        )
                        if (key === 'footer') {
                          footer = component
                        } else {
                          header = component
                        }
                      }
                    }
                  })
                }
                const basePageStyle = {
                  padding: pageMargin + 'cm',
                  fontFamily: 'Roboto'
                }
                if (footer && pageMargin < 1) {
                  basePageStyle.paddingBottom = '1cm'
                }
                if (header && pageMargin < 1) {
                  basePageStyle.paddingTop = '1cm'
                }
                return (
                  <Page style={basePageStyle} key={sIndex}>
                    {section.elements.map((item, index) => {
                      if (!section.elements || section.elements.length === 0) {
                        return null
                      }
                      return renderItem({
                        item: {
                          ...item,
                          value: values[item.id],
                          title: langFR ? item.titleFR : item.titleEN
                        },
                        lastInSection: section.elements.length === index + 1,
                        disabledIds,
                        pdfView: true,
                        baseErrors: {},
                        values,
                        objectsFieldsMap,
                        connectedMap,
                        elementsMap,
                        injectablesMap,
                        describeMap,
                        formikRef: formik,
                        langFR,
                        index
                      })
                    })}
                    {header}
                    {footer}
                  </Page>
                )
              })}
            </Document>
          )

          if (returnPdf) {
            return returnPdf(pdfDocument, formTitlePdf, fetchData)
          }

          return (
            <ReactCursorPosition
              isEnabled={useMultiuser}
              activationInteractionMouse={INTERACTIONS.HOVER}
              ref={mouseDetectRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%'
              }}
            >
              <Paper style={style}>
                {useMultiuser && <Chat formId={id} />}
                {useMultiuser && values.muInfo?.disconnected && (
                  <Dialog open fullWidth maxWidth='sm'>
                    <DialogContent>
                      <Typography variant='h6' style={{ marginBottom: 10 }}>
                        <Trans>Connection lost. Reconnecting...</Trans>
                      </Typography>
                      <div style={{ paddingBottom: 20 }}>
                        <Loading isNotFixed />
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
                {wrongAccountRole === 'warning' && (
                  <Alert severity='warning' style={{ marginBottom: 10 }}>
                    <AlertTitle>
                      <Typography variant='h6'>
                        <Trans>You cannot edit this page</Trans>
                      </Typography>
                    </AlertTitle>
                    <div style={{ marginTop: 5 }}>
                      {/* <Trans>You cannot edit this page</Trans>
                      {'. '} */}
                      <Trans>You can request higher organization access</Trans>{' '}
                      <Link href='/grants/Organizations'>
                        <Trans>here</Trans>
                      </Link>
                    </div>
                  </Alert>
                )}
                {!noStepper && !inDialog && (
                  <Typography
                    variant='h6'
                    style={{
                      textAlign: 'center',
                      marginBottom: 20,
                      margintTop: 20
                    }}
                  >
                    {formTitle}
                  </Typography>
                )}
                {data.displaySaveFailedDialog && (
                  <SavingFailedWarningDialog
                    open={Boolean(saveFailedData)}
                    data={saveFailedData}
                    handleClose={() => {
                      setSaveFailedData(null)
                    }}
                    fileName={
                      myI18n._(t`Extracted data`) +
                      ' - ' +
                      moment.utc().format(dateFormat)
                    }
                  />
                )}
                {data.displayUnsavedWarning && (
                  <RedirectWarning
                    open={dirty}
                    handleSave={() => {
                      handleSave({ values })
                    }}
                  />
                )}
                <SaveWillOverrideWarningDialog
                  handleSave={() => {
                    handleSave({ values })
                  }}
                  handleClose={() => {
                    setOverrideWarningData(null)
                    setSaving(false)
                  }}
                  open={Boolean(overrideWarningData)}
                  data={overrideWarningData}
                />
                {!noStepper && (
                  <Stepper
                    nonLinear
                    activeStep={currentStep}
                    orientation='horizontal'
                    alternativeLabel
                  >
                    {sections.map((section, index) => {
                      return (
                        <Step
                          itemType='step'
                          id='step'
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={e => {
                            scrollToTop()
                            setStep(index)
                            if (useMultiuser) {
                              muHandleChangeStep(index)
                            }
                          }}
                        >
                          <StepLabel id='label'>
                            {parseDisplayedText({
                              text: langFR ? section.titleFR : section.titleEN,
                              french: langFR,
                              injectablesMap,
                              objectsFieldsMap,
                              describeMap,
                              renderProps: {
                                connectedMap
                              }
                            })}
                            {useMultiuser && values.muUsers && (
                              <Grid container justifyContent='center'>
                                {Object.entries(values.muUsers)
                                  .filter(
                                    ([key, value]) =>
                                      value.step === index &&
                                      key !== user.userId
                                  )
                                  .map(([key, value]) => {
                                    return (
                                      <Grid item key={key}>
                                        <MUAvatar {...value} />
                                      </Grid>
                                    )
                                  })}
                              </Grid>
                            )}
                          </StepLabel>
                        </Step>
                      )
                    })}
                  </Stepper>
                )}
                {!disableTitle && (
                  <FormTitle title={noStepper ? formTitle : sectionTitle}>
                    <StepperButtons
                      formTitle={formTitlePdf}
                      printRef={readOnly && printRef}
                      pdfFileName={formTitlePdf}
                      hideSaveButton={readOnly || pdfDisplay}
                      pdf={Boolean(!disablePDF && pdfDisplay) && pdfDocument}
                      noStepper={noStepper}
                      saving={saving}
                      disableSave={
                        disabled || isPreview || noConnectedObjects || !dirty
                      }
                      elementsMap={elementsMap}
                      formId={id}
                      handleSave={() => trySaving({ values })}
                      handleNext={handleNext}
                      handleBack={handleBack}
                      steps={sections}
                      activeStep={currentStep}
                      useMultiuser={useMultiuser}
                    />
                  </FormTitle>
                )}
                {Boolean(readOnly && printRef) && (
                  <div
                    ref={printRef}
                    style={{ width: '100%' }}
                    className='show-in-print'
                  >
                    {sections.map((section, index) => {
                      const sectionTitle = parseDisplayedText({
                        text: langFR ? section.titleFR : section.titleEN,
                        french: langFR,
                        objectsFieldsMap,
                        describeMap,
                        injectablesMap,
                        renderProps: {
                          connectedMap
                        }
                      })

                      return (
                        <>
                          <div
                            style={{
                              pageBreakBefore: 'always'
                            }}
                          >
                            <FormTitle title={sectionTitle} />
                          </div>
                          {section.elements.map((item, index) => {
                            return renderItem({
                              item: {
                                ...item,
                                value: values[item.id],
                                title: langFR ? item.titleFR : item.titleEN
                              },
                              lastInSection:
                                section.elements.length === index + 1,
                              objectsFieldsMap,
                              values,
                              setFieldValue,
                              printView: true,
                              renderPrint: true,
                              disabled: Boolean(disabled || saving || readOnly),
                              saveDisabled: Boolean(disabled || isPreview),
                              configuration,
                              addMethodToValidationSchema,
                              connectedMap,
                              injectablesMap,
                              elementsMap,
                              describeMap,
                              saving,
                              handleSubmit,
                              saveButtonClicked: trySaving,
                              disabledIds,
                              classes,
                              reloadLastModifiedDates,
                              sectionIndex: currentStep,
                              preview: isPreview,
                              formikRef: formik,
                              baseErrors: errors,
                              errors: errorsToRender({
                                errors,
                                objectsFieldsMap,
                                describeMap,
                                disabledIds,
                                elementsMap,
                                french: langFR
                              }),
                              formId: id,
                              useMultiuser,
                              network,
                              langFR,
                              index
                            })
                          })}
                        </>
                      )
                    })}
                  </div>
                )}

                {pdfDisplay ? (
                  <PDFViewer showToolbar={false} style={{ flexGrow: 1 }}>
                    {pdfDocument}
                  </PDFViewer>
                ) : (
                  sections[currentStep].elements.map((item, index) => {
                    return (
                      <div key={index}>
                        {renderItem({
                          item: {
                            ...item,
                            value: values[item.id],
                            title: langFR ? item.titleFR : item.titleEN
                          },
                          lastInSection:
                            sections[currentStep].elements.length === index + 1,
                          objectsFieldsMap,
                          values,
                          setFieldValue,
                          renderPrint: readOnly,
                          disabled: Boolean(disabled || saving || readOnly),
                          saveDisabled: Boolean(disabled || isPreview),
                          configuration,
                          addMethodToValidationSchema,
                          connectedMap,
                          elementsMap,
                          injectablesMap,
                          describeMap,
                          saving,
                          handleSubmit,
                          saveButtonClicked: trySaving,
                          disabledIds,
                          classes,
                          reloadLastModifiedDates,
                          sectionIndex: currentStep,
                          preview: isPreview,
                          baseErrors: errors,
                          errors: errorsToRender({
                            errors,
                            objectsFieldsMap,
                            describeMap,
                            disabledIds,
                            elementsMap,
                            french: langFR
                          }),
                          formikRef: formik,
                          formId: id,
                          useMultiuser,
                          network,
                          langFR,
                          index
                        })}
                      </div>
                    )
                  })
                )}

                {Boolean(!noStepper) && (
                  <StepperButtons
                    formTitle={formTitlePdf}
                    printRef={readOnly && printRef}
                    pdfFileName={formTitle}
                    hideSaveButton={readOnly || pdfDisplay}
                    pdf={Boolean(!disablePDF && pdfDisplay) && pdfDocument}
                    noStepper={noStepper}
                    saving={saving}
                    disableSave={
                      disabled || isPreview || noConnectedObjects || !dirty
                    }
                    formId={id}
                    elementsMap={elementsMap}
                    handleSave={() => trySaving({ values })}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    steps={sections}
                    activeStep={currentStep}
                    useMultiuser={useMultiuser}
                  />
                )}
              </Paper>
            </ReactCursorPosition>
          )
        }}
      </Formik>
    )

    return inDialog
      ? returnInDialog({
          component: toReturn
        })
      : toReturn
  }
)

const extractFieldDetails = ({
  connectedField,
  connectedMap,
  describeMap,
  connectedObject
}) => {
  const { subObject, name } = connectedField
  if (!subObject) {
    if (!connectedMap[connectedObject]) {
      return {}
    }
    return connectedMap[connectedObject].fieldsMap[name]
  } else {
    const subFieldName = name.split('.')[1]
    const subObjectName = name.split('.')[0]
    if (!subFieldName || !subObjectName) {
      console.error('Could not find field details for: ', name)
      return null
    }
    const fields = describeMap[subObjectName].fields
    let details
    fields.some(field => {
      if (field.name === subFieldName) {
        details = field
      }
      return field.name === subFieldName
    })
    return details
  }
}

const renderItem = ({
  item,
  langFR,
  index,
  columns = 1,
  renderPrint,
  skipCard,
  pdfView,
  ...props
}) => {
  const {
    elements,
    padding = {},
    headerFontSize,
    style,
    titleFR,
    titleEN,
    headerStyle,
    bold,
    italics
  } = item
  const { connectedMap } = props
  const paddingStyles = {}
  const paddingKeys = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom'
  ]
  paddingKeys.forEach(key => {
    let toSet = 0
    if (padding[key]) {
      toSet = Number(padding[key])
    }
    paddingStyles[key] = toSet
  })

  if (elements) {
    if (props.disabledIds.includes(item.id)) {
      return null
    }
    const title = langFR ? titleFR : titleEN
    let styleToPass = {
      width: '100%',
      ...paddingStyles
    }
    let headerStyleToPass = {}
    try {
      if (style) {
        const parsed = JSON.parse(style)
        if (typeof parsed === 'object') {
          styleToPass = Object.assign(styleToPass, JSON.parse(style))
        }
      }
    } catch (e) {}
    try {
      const parsed = JSON.parse(headerStyle)
      if (typeof parsed === 'object') {
        headerStyleToPass = Object.assign(headerStyleToPass, parsed)
      }
    } catch (e) {}
    if (headerFontSize) {
      headerStyleToPass.fontSize = +headerFontSize
    }
    if (bold) {
      headerStyleToPass.fontWeight = 'bold'
    }
    if (italics) {
      headerStyleToPass.fontStyle = 'italic'
    }

    if (pdfView) {
      styleToPass.width = props.printWidth || '100%'
      delete props.printWidth
      return (
        <View key={index} style={styleToPass} wrap={false}>
          {title ? (
            <Text
              style={{
                fontWeight: 'bold',
                paddingTop: 12,
                paddingBottom: 6,
                fontSize: pdfDefaultFontSize,
                ...headerStyleToPass
              }}
            >
              {parseDisplayedText({
                text: title,
                french: langFR,
                pdf: true,
                describeMap: props.describeMap,
                injectablesMap: props.injectablesMap,
                objectsFieldsMap: props.objectsFieldsMap,
                renderProps: {
                  connectedMap
                }
              })}
            </Text>
          ) : (
            <Text />
          )}

          <View
            style={{
              fontSize: pdfDefaultFontSize,
              width: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {item.elements.map((element, eIndex) => {
              return renderItem({
                item: {
                  ...element,
                  value: !formElementsWithoutInput.includes(element.elementType)
                    ? props.values[element.id]
                    : null
                },
                langFR,
                eIndex,
                printWidth: String(100 / +item.columns) + '%',
                pdfView: true,
                ...props
              })
            })}
          </View>
        </View>
      )
    }

    const toRender = (
      <Grid key={index} style={styleToPass} item xs={12 / columns}>
        {title && (
          <div
            style={{
              // fontWeight: 'bold',
              marginBottom: formItemPadding,
              marginLeft: formItemPadding,
              fontSize: 28,
              ...headerStyleToPass
            }}
          >
            {parseDisplayedText({
              text: title,
              french: langFR,
              objectsFieldsMap: props.objectsFieldsMap,
              injectablesMap: props.injectablesMap,
              describeMap: props.describeMap,
              renderProps: {
                connectedMap
              }
            })}
          </div>
        )}
        <Grid container direction='row' className='break-in-print'>
          {item.elements.map((element, eIndex) => {
            const labelsWidth = element.labelsWidth || item.labelsWidth
            const itemsSpacing = element.itemsSpacing || item.itemsSpacing
            return renderItem({
              item: {
                ...element,
                value: !formElementsWithoutInput.includes(element.elementType)
                  ? props.values[element.id]
                  : null,
                itemsSpacing: itemsSpacing,
                labelsWidth
              },
              langFR,
              eIndex,
              columns: item.columns,
              skipCard: true,
              renderPrint,
              pdfView,
              ...props
            })
          })}
        </Grid>
      </Grid>
    )

    if (renderPrint && !skipCard) {
      const containerStyle = {
        padding: printViewSpacing
      }
      if (props.lastInSection && props.printView) {
        containerStyle.paddingBottom = 0
      }
      return (
        <div style={containerStyle}>
          <Paper
            elevation={3}
            style={{
              width: '100%',
              display: 'flex'
            }}
          >
            {toRender}
          </Paper>
        </div>
      )
    } else {
      return toRender
    }
  } else {
    return (
      <RenderElement
        item={item}
        langFR={langFR}
        index={index}
        pdfView={pdfView}
        skipCard={skipCard}
        renderPrint={renderPrint}
        xs={12 / columns}
        {...props}
      />
    )
  }
}

const RenderElement = ({
  xs = 12,
  item,
  langFR,
  connectedMap,
  describeMap,
  injectablesMap,
  formId,
  index,
  network,
  pdfView,
  disabledIds,
  classes,
  skipCard,
  printView,
  renderPrint,
  lastInSection,
  ...props
}) => {
  const type = item.elementType
  const elementData = formElementTypes[type]
  const {
    labelsWidth,
    padding = {},
    itemsSpacing,
    titleFR,
    titleEN,
    conditions,
    labelAsMarkdown,
    typeProps,
    elementType,
    helpTextEN,
    helpTextFR
  } = item

  const { printAvoidBreak, options } = typeProps
  const paddingStyles = {}
  const paddingKeys = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom'
  ]
  paddingKeys.forEach(key => {
    let toSet = formItemPadding
    if (pdfView) {
      if (key === 'paddingTop' || key === 'paddingBottom') {
        toSet = 6
      } else {
        toSet = 0
      }
    }
    if (padding[key]) {
      toSet = Number(padding[key])
    }
    if (itemsSpacing || itemsSpacing === 0) {
      toSet = Number(itemsSpacing)
    }
    paddingStyles[key] = toSet
  })

  if (type && item.id && elementData) {
    const renderComponent = pdfView
      ? elementData.formComponentPdf
      : elementData.formComponent
    if (!renderComponent || disabledIds.includes(item.id)) {
      return null
    }
    const tooltip = langFR ? item.tooltipFR : item.tooltipEN
    let helpText = langFR ? helpTextFR : helpTextEN
    let sfObject, connectedFieldDetails
    const { isConnected, showFieldLabel } = typeProps
    const { connectedField, connectedObject } = getMainConnected(item)
    if (connectedObject && connectedMap[connectedObject]) {
      sfObject = connectedMap[connectedObject].sfObject
    }
    if (isConnected && connectedField) {
      connectedFieldDetails = extractFieldDetails({
        connectedField,
        connectedMap,
        describeMap,
        connectedObject
      })
    }
    if (options) {
      options.forEach(option => {
        if (option.connectedField) {
          option.connectedFieldDetails = extractFieldDetails({
            connectedField: option.connectedField,
            connectedMap,
            describeMap,
            connectedObject
          })
        }
      })
    }
    let label = langFR ? titleFR : titleEN
    if (conditions && conditions.length > 0) {
      const altLabel = checkAltLabel({
        item,
        connectedMap,
        values: props.values,
        errors: props.baseErrors,
        langFR,
        elementsMap: props.elementsMap
      })
      if (typeof altLabel === 'string') {
        label = altLabel
      }
    }
    label = parseDisplayedText({
      text: label,
      french: langFR,
      objectsFieldsMap: props.objectsFieldsMap,
      describeMap,
      injectablesMap,
      connectedMap,
      pdf: pdfView,
      returnString: !pdfView,
      renderProps: {
        connectedMap
      }
    })
    if (labelAsMarkdown) {
      label = unified()
        .use(remarkParse)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeStringify, { allowDangerousHtml: true })
        .processSync(label)
        .toString()
    }
    if (helpText) {
      helpText = unified()
        .use(remarkParse)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeStringify, { allowDangerousHtml: true })
        .processSync(helpText)
        .toString()
    }
    const alt = item.altLabelPlacement
    if (renderPrint) {
      if (elementData.printComponent) {
        let printRender = elementData.printComponent({
          ...item,
          describeMap,
          title: label,
          connectedMap,
          editMode: false,
          injectablesMap,
          connectedObject: sfObject,
          connectedFieldDetails,
          formId,
          langFR,
          tooltip,
          ...props
        })
        if (
          ['budget', 'objectives', 'milestones', 'table'].includes(elementType)
        ) {
          if (!skipCard) {
            printRender = (
              <div style={{ padding: printViewSpacing }}> {printRender}</div>
            )
          }
        } else {
          if (!skipCard) {
            const containerStyle = {
              padding: printViewSpacing
            }
            if (lastInSection && printView) {
              containerStyle.paddingBottom = 0
            }
            printRender = (
              <div style={containerStyle}>
                <Paper
                  elevation={3}
                  style={{
                    ...paddingStyles,
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  {printRender}
                </Paper>
              </div>
            )
          } else {
            printRender = (
              <div style={{ ...paddingStyles, width: '100%', display: 'flex' }}>
                {printRender}
              </div>
            )
          }
        }
        const printBreakBehaviourClass = printAvoidBreak
          ? 'avoid-print-break'
          : 'allow-print-break'
        return (
          <Grid
            key={index}
            item
            container
            xs={xs}
            style={{
              width: '100%',
              display: 'flex'
            }}
            direction={alt ? 'row' : 'column'}
            wrap='nowrap'
            className={
              typeProps.printPageBreakBefore
                ? 'page-break-before'
                : printBreakBehaviourClass
            }
          >
            {printRender}
          </Grid>
        )
      } else {
        return null
      }
    }
    const baseElement = renderComponent({
      ...item,
      describeMap,
      injectablesMap,
      connectedMap,
      editMode: false,
      connectedObject: sfObject,
      connectedFieldDetails,
      network,
      formId,
      langFR,
      tooltip,
      title: label,
      helpText,
      ...props
    })
    if (pdfView) {
      return (
        <View style={{ width: props.printWidth || '100%', ...paddingStyles }}>
          {baseElement}
        </View>
      )
    }

    return (
      <Grid
        key={index}
        item
        container
        xs={xs}
        style={{
          width: '100%',
          display: 'flex',
          ...paddingStyles
        }}
        direction={alt ? 'row' : 'column'}
        className='break-in-print'
        wrap='nowrap'
      >
        <FormElementTitle
          tooltip={tooltip}
          labelsWidth={labelsWidth}
          id={item.id}
          altLabelPlacement={alt}
          helpText={!elementData.customHelptext && helpText}
          labelAsMarkdown={labelAsMarkdown}
          title={label}
          type={type}
          showFieldLabel={showFieldLabel}
          useMultiuser={props.useMultiuser}
          formId={formId}
          disabled={props.disabled}
          connectedObject={sfObject}
          item={item}
        />
        <Grid item xs>
          {baseElement}
        </Grid>
      </Grid>
    )
  }
}

const StepperButtons = ({
  activeStep,
  noStepper,
  handleBack,
  handleSave,
  handleNext,
  printRef,
  pdf,
  pdfFileName,
  saving,
  disableSave,
  formId,
  hideSaveButton,
  steps,
  formTitle,
  elementsMap,
  useMultiuser
}) => (
  <Grid
    container
    style={{ marginLeft: 'auto' }}
    wrap='nowrap'
    justifyContent='flex-end'
  >
    {!noStepper && (
      <Button
        variant='contained'
        color='secondary'
        disabled={activeStep === 0}
        onClick={handleBack}
      >
        <Trans>Previous</Trans>
      </Button>
    )}
    {printRef && !pdf && (
      <ReactToPrint
        trigger={() => (
          <Button
            className='ml-4'
            variant='outlined'
            color='primary'
            onClick={handleSave}
          >
            <Icon>print</Icon>
          </Button>
        )}
        onAfterPrint={() => (document.title = defaultDocTitle)}
        onBeforePrint={() => (document.title = formTitle)}
        content={() => printRef.current}
      />
    )}
    {pdf && (
      <PDFDownloadLink fileName={pdfFileName} document={pdf}>
        {({ blob, url, loading, error }) => {
          return (
            <Button
              disabled={loading}
              className='ml-4'
              variant='outlined'
              color='primary'
            >
              <Icon>download</Icon>
            </Button>
          )
        }}
      </PDFDownloadLink>
    )}
    {useMultiuser && (
      <BackupsPanel elementsMap={elementsMap} saving={saving} formId={formId} />
    )}
    {!hideSaveButton && (
      <Button
        className='ml-4'
        variant='outlined'
        color='primary'
        disabled={saving || disableSave}
        onClick={handleSave}
      >
        <Trans>Save</Trans>
        <Save style={{ marginLeft: 5 }} />
      </Button>
    )}
    {!noStepper && (
      <Button
        className='ml-4'
        variant='contained'
        color='primary'
        disabled={activeStep === steps.length - 1}
        onClick={handleNext}
      >
        <Trans>Next</Trans>
      </Button>
    )}
  </Grid>
)

const BackupsPanel = ({ saving, formId, elementsMap = {} }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [backups, setBackups] = useState(null)
  const [versions, setVersions] = useState(null)
  const [restoring, setRestoring] = useState(false)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { values, setValues } = useFormikContext()
  const { enqueueSnackbar } = useSnackbar()
  const langFR = user.language !== 'en_US'

  useEffect(() => {
    grpcGetFormBackups({
      formId,
      userId: user.userId,
      onSuccess: backups => {
        setBackups(backups)
      }
    })
  }, [dialogOpen])

  return (
    <>
      <Dialog open={Boolean(versions)} fullWidth maxWidth='md'>
        <DialogTitle>
          <Grid container direction='row'>
            <Grid
              item
              xs
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: 17,
                paddingBottom: 15
              }}
            >
              <Trans>Current version</Trans>

              <Grid></Grid>
            </Grid>
            <Grid
              item
              xs
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: 17,
                paddingBottom: 15
              }}
            >
              <Trans>Backup</Trans>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {versions && (
            <VersionsDifferences {...versions} elementsMap={elementsMap} />
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent='space-evenly'
            style={{ paddingTop: 15, paddingBottom: 15 }}
          >
            <Button
              color='primary'
              variant='contained'
              disabled={restoring}
              onClick={e => {
                setRestoring(true)
                grpGetLockedFieldsForForm({
                  formId,
                  userId: user.userId,
                  onSuccess: locks => {
                    if (locks.length === 0) {
                      const newValues = { ...values, ...versions.cache }
                      setRestoring(false)
                      setDialogOpen(false)
                      setVersions(null)
                      enqueueSnackbar(<Trans>Backup restored!</Trans>, {
                        variant: 'success'
                      })
                      setValues(newValues)
                      commitChangeToMultipleFields({
                        formId,
                        userId: user.userId,
                        array: Object.entries(newValues).filter(
                          ([key, values]) =>
                            !['muUsers', 'muInfo'].includes(key)
                        )
                      })
                    } else {
                      setRestoring(false)
                      enqueueSnackbar(
                        <Trans>
                          You cannot restore the backup, some fields are
                          currently edited by other users!
                        </Trans>,
                        {
                          variant: 'error'
                        }
                      )
                    }
                  }
                })
              }}
            >
              <Trans>Restore backup</Trans>
            </Button>
            <Button
              color='primary'
              variant='contained'
              disabled={restoring}
              onClick={e => {
                setVersions(null)
                setRestoring(false)
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Trans>Form backups</Trans>
            <IconButton
              disabled={restoring || !backups || saving}
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
          <div style={{ fontSize: 13, fontWeight: 400 }}>
            <Trans>
              Be careful! Restoring the backup will overrite your current
              changes
            </Trans>
          </div>
        </DialogTitle>
        <DialogContent>
          {Array.isArray(backups) ? (
            backups.length === 0 ? (
              <div>
                <Trans>There are no backups yet for this form!</Trans>
              </div>
            ) : (
              backups.map((backup, index) => (
                <Grid
                  container
                  key={index}
                  alignItems='center'
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs>
                    <div style={{ fontWeight: 400, fontSize: 11 }}>
                      <Trans>Date</Trans>
                    </div>
                    <div>{moment.utc(backup.date).format(datetimeFormat)}</div>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={restoring || saving}
                      onClick={e => {
                        setRestoring(true)
                        grpcGetFormCache({
                          formId,
                          userId: user.userId,
                          id: backup.id,
                          onSuccess: cache => {
                            setRestoring(false)
                            setVersions({
                              cache,
                              current: values
                            })
                          }
                        })
                      }}
                    >
                      <Icon>settings_backup_restore</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))
            )
          ) : (
            <Loading />
          )}
        </DialogContent>
      </Dialog>

      <Button
        style={{ marginLeft: 4 }}
        disabled={!backups}
        variant='contained'
        color='primary'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Trans>Form backups</Trans>
        <Icon style={{ marginLeft: 5 }}>backup</Icon>
      </Button>
    </>
  )
}

const VersionsDifferences = ({ cache, current, elementsMap }) => {
  const user = useSelector(state => state.user)

  const renderArray = Object.entries(current)
    .filter(([key, value]) => {
      const elementData = elementsMap[key]
      return (
        elementData &&
        elementData.elementType &&
        !['muInfo', 'muUsers'].includes(key)
      )
    })
    .map(([key, value]) => {
      const elementData = elementsMap[key]
      let currentValue = value
      let savedValue = cache[key]
      const typeData = formElementTypes[elementData.elementType]
      const { titleFR, titleEN } = elementData
      if (typeData.parseValueToCompare) {
        savedValue = typeData.parseValueToCompare(savedValue)
        currentValue = typeData.parseValueToCompare(currentValue)
      }
      if (_.isEqual(currentValue, savedValue)) {
        return null
      }
      return (
        <>
          <Grid container>
            <Grid item xs style={{ padding: 10 }}>
              {typeData.printComponent({
                ...elementData,
                value,
                langFR: user.language !== 'en_US'
              })}
            </Grid>
            <Grid item xs style={{ padding: 10 }}>
              {typeData.printComponent({
                ...elementData,
                value: savedValue,
                langFR: user.language !== 'en_US'
              })}
            </Grid>
          </Grid>
          <Divider />
        </>
      )
    })
    .filter(o => o)

  console.log('QQQ', renderArray)

  if (renderArray.length === 0) {
    return (
      <Alert severity='info'>
        <AlertTitle>
          <Trans>
            There are no changes between current version and this saved backup
          </Trans>
        </AlertTitle>
      </Alert>
    )
  }

  return (
    <Grid container direction='column'>
      {renderArray}
    </Grid>
  )
}

export const FormPage = ({
  label,
  langFR,
  errors,
  handleSubmit,
  values,
  elements,
  sections,
  formId
}) => {
  const elementsMap = mapFormElements({ sections: sections }, langFR)
  const disabledIds = getDisabledIds({ sections, elementsMap, values })

  return (
    <Paper style={{ padding: 20 }}>
      <Typography variant='h3' style={{ textAlign: 'center' }}>
        {label}
      </Typography>
      <div>
        {elements.map((item, index) => {
          if (disabledIds.includes(item.id)) {
            return null
          }
          return renderItem({
            item: {
              ...item,
              value: values[item.id],
              title: langFR ? item.titleFR : item.titleEN
            },
            lastInSection: elements.length === index + 1,
            disabledIds,
            connectedMap: {},
            saving: false,
            handleSubmit,
            sectionIndex: 0,
            errors: errorsToRender({
              errors,
              objectsFieldsMap: {},
              disabledIds,
              elementsMap,
              french: langFR
            }),
            formId,
            network: {},
            langFR,
            index
          })
        })}
      </div>
    </Paper>
  )
}

export default FormWrapped
