/**
 * Created by Michał Stawski on 05.10.2020.
 */
import React from 'react'
import { Button } from '@material-ui/core'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>
        <h1>Something went wrong.</h1>
        <p>
          {this.state.error}
        </p>
        <Button onSubmit={() => this.setState({ hasError: false })}
                color={'secondary'} variant={'contained'} className='m-20'>
          Dismiss
        </Button>

      </div>
    }

    return this.props.children
  }
}

export { ErrorBoundary }
