import { t, Trans } from '@lingui/macro'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { Text, View } from '@react-pdf/renderer'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getMainConnected } from '../Form'
import {
  validFieldTypesForPicklist,
  validFieldTypesForTextField
} from '../FormWizard'
import { commitChangeToMultipleFields } from '../multiuser/grpcMultiuserEdit'
import {
  ConfigureSelectActions,
  FormConnectToObject,
  handleSelectActions,
  NumberFormatDefault,
  pdfDefaultFontSize
} from './Common'
import { FormLabel, FormErrorLabel } from './FormErrorLabel'
import MUTextField from './multiuser-input/MUTextField'

export const FormPicklistPdf = ({
  title,
  id,
  typeProps,
  value,
  values = {},
  disabled,
  langFR,
  connectedFieldDetails,
  ...props
}) => {
  const { picklistType, options = [], dynamic } = typeProps
  const valueToTrans = {}
  const valueToIndex = {}
  const valueToPicklistTrans = {}
  options.forEach((opt, index) => {
    if (opt.apiValue) {
      valueToTrans[opt.apiValue] = langFR ? opt.titleFR : opt.titleEN
    }
    valueToIndex[opt.apiValue] = index
  })
  if (connectedFieldDetails) {
    connectedFieldDetails.picklistValues.forEach(option => {
      valueToPicklistTrans[option.value] = option.label
    })
  }

  if (picklistType === 'multiselect') {
    const data = value || []
    return (
      <View style={{ fontFamily: 'Roboto', fontSize: pdfDefaultFontSize }}>
        <Text style={{ fontWeight: 'bold' }}>{title}</Text>
        {Boolean(data.length === 0) && (
          <Text style={{ fontWeight: 'bold' }}>
            <Trans>This list is empty!</Trans>
          </Text>
        )}
        {data.map((item, index) => {
          const orgIndex = valueToIndex[item]
          return (
            <View
              key={index}
              style={{
                display: 'flex',
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 8
              }}
            >
              <Text>
                {dynamic
                  ? valueToPicklistTrans[item]
                  : valueToTrans[item] || <Trans id={item} />}
                {values.other[id] && values.other[id][orgIndex] && (
                  <Text>{' (' + values.other[id][orgIndex] + ')'}</Text>
                )}
              </Text>
            </View>
          )
        })}
      </View>
    )
  } else {
    return (
      <View style={{ fontFamily: 'Roboto', fontSize: pdfDefaultFontSize }}>
        <Text style={{ fontWeight: 'bold' }}>{title}</Text>
        <Text style={{ whiteSpace: 'pre-line' }}>
          {dynamic
            ? valueToPicklistTrans[value]
            : valueToTrans[value] || <Trans id={value} />}
          {values.other[id] && <Text>{' (' + values.other[id] + ')'}</Text>}
        </Text>
      </View>
    )
  }
}

export const FormPicklistPrint = ({
  title,
  id,
  typeProps,
  value,
  disabled,
  langFR,
  connectedFieldDetails,
  ...props
}) => {
  const { values } = useFormikContext()
  const { printIcon = 'none', picklistType, options = [], dynamic } = typeProps
  const valueToTrans = {}
  const valueToIndex = {}
  const valueToPicklistTrans = {}
  options.forEach((opt, index) => {
    if (opt.apiValue) {
      valueToTrans[opt.apiValue] = langFR ? opt.titleFR : opt.titleEN
    }
    valueToIndex[opt.apiValue] = index
  })
  if (connectedFieldDetails) {
    connectedFieldDetails.picklistValues.forEach(option => {
      valueToPicklistTrans[option.value] = option.label
    })
  }

  if (picklistType === 'multiselect') {
    const data = value || []
    return (
      <div>
        <Typography className='form-print-subtitle'>{title}</Typography>
        <List>
          {Boolean(data.length === 0) && (
            <ListItem style={{ display: 'flex', fontWeight: 'bold' }}>
              <Trans>This list is empty!</Trans>
            </ListItem>
          )}
          {data.map((item, index) => {
            const orgIndex = valueToIndex[item]
            return (
              <ListItem
                key={index}
                style={{
                  display: 'flex',
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 8
                }}
              >
                {printIcon !== 'none' && (
                  <ListItemIcon style={{ minWidth: 35 }}>
                    <Icon>{printIcon}</Icon>
                  </ListItemIcon>
                )}
                <Typography>
                  {dynamic
                    ? valueToPicklistTrans[item]
                    : valueToTrans[item] || <Trans id={item} />}
                  {values.other[id] && values.other[id][orgIndex] && (
                    <span>{' (' + values.other[id][orgIndex] + ')'}</span>
                  )}
                </Typography>
              </ListItem>
            )
          })}
        </List>
      </div>
    )
  } else {
    return (
      <div>
        <Typography className='form-print-subtitle'>{title}</Typography>
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {dynamic
            ? valueToPicklistTrans[value]
            : valueToTrans[value] || <Trans id={value} />}
          {values.other[id] && <span>{' (' + values.other[id] + ')'}</span>}
        </Typography>
      </div>
    )
  }
}

export const formPicklistValueToText = (value = [], question, object = {}) => {
  const { connectedFieldDetails } = object
  const { dynamic } = question.typeProps
  const options =
    dynamic && connectedFieldDetails
      ? connectedFieldDetails.picklistValues
          .filter(item => item.active)
          .map((item, index) => {
            return {
              titleEN: myI18n._(t`${item.label}`),
              titleFR: myI18n._(t`${item.label}`),
              apiValue: item.value
            }
          })
      : question.typeProps.options

  const selectedEn = []
  const selectedFr = []
  if (Array.isArray(value)) {
    value.forEach(v => {
      options.some((option, index) => {
        const found = v === 'option' + index || v === option.apiValue
        if (found) {
          selectedEn.push(<div key={index}>{option.titleEN}</div>)
          selectedFr.push(<div key={index}>{option.titleFR}</div>)
        }
        return found
      })
    })
    return { en: <div>{selectedEn}</div>, fr: <div>{selectedFr}</div> }
  } else {
    let singleOptionEN = ''
    let singleOptionFR = ''
    options.some((option, index) => {
      let found
      if (option.apiValue) {
        found = value === option.apiValue
      } else {
        found = value === 'option' + index
      }
      if (found) {
        singleOptionEN = option.titleEN
        singleOptionFR = option.titleFR
      }
      return found
    })
    return { en: <div>{singleOptionEN}</div>, fr: <div>{singleOptionFR}</div> }
  }
}

export const FormPicklist = ({
  id,
  useMultiuser,
  formId,
  typeProps,
  value,
  disabled,
  langFR,
  connectedFieldDetails,
  ...props
}) => {
  const {
    options = [],
    picklistType,
    required,
    readOnly,
    isConnected,
    componentWidth,
    dynamic,
    maxOptions
  } = typeProps
  const otherId = 'other.' + id
  const hasTooltip = Boolean(props.tooltipEN || props.tooltipFR)
  const user = useSelector(state => state.user)
  const { values, setValues, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(id)
  let selectableOptions =
    dynamic && connectedFieldDetails && connectedFieldDetails.picklistValues
      ? connectedFieldDetails.picklistValues
          .filter(item => item.active)
          .map((item, index) => {
            return {
              titleEN: myI18n._(t`${item.label}`),
              titleFR: myI18n._(t`${item.label}`),
              apiValue: item.value
            }
          })
      : options.map(item => ({
          ...item,
          titleEN: String(item.titleEN).replace(/\u00a0/g, ' '),
          titleFR: String(item.titleFR).replace(/\u00a0/g, ' ')
        }))

  const splitValue = value?.split ? value.split(';') : []
  if (readOnly) {
    return (
      <List>
        {splitValue.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>
    )
  }
  const renderTwoColumns = selectableOptions.length > 10
  const optionsMap = {}
  selectableOptions.forEach((option, index) => {
    const defaultOpt = 'option' + index
    const optionValue = isConnected ? option.apiValue || defaultOpt : defaultOpt
    selectableOptions[index].orgIndex = index
    optionsMap[optionValue] = option
  })
  const isError = Boolean(meta.touched && meta.error)

  if (renderTwoColumns) {
    const firstHalf = selectableOptions.slice(
      0,
      Math.ceil(selectableOptions.length / 2)
    )
    const secondHalf = selectableOptions.slice(
      Math.ceil(selectableOptions.length / 2)
    )
    const toSet = []
    firstHalf.forEach((item, index) => {
      toSet.push(item)
      if (secondHalf[index]) {
        toSet.push(secondHalf[index])
      }
    })
    selectableOptions = toSet
  }

  if (picklistType === 'multiselect') {
    const listValue = field.value || []
    const clearOptionSelected =
      listValue.length === 1 &&
      selectableOptions.some(item => {
        const index = item.orgIndex
        const defaultOpt = 'option' + index
        const optionValue = isConnected
          ? item.apiValue || defaultOpt
          : defaultOpt
        if (optionValue === listValue[0] && item.clearOnSelect) {
          return true
        } else {
          return false
        }
      })
    return (
      <div>
        <FormControl component='fieldset'>
          <FormGroup
            style={
              renderTwoColumns
                ? {
                    flexDirection: 'row',
                    marginLeft: 25,
                    justifyContent: 'start'
                  }
                : {}
            }
          >
            {selectableOptions.map(item => {
              const index = item.orgIndex
              const option = langFR ? item.titleFR : item.titleEN
              const tooltip = langFR ? item.tooltipFR : item.tooltipEN
              const defaultOpt = 'option' + index
              const optionValue = isConnected
                ? item.apiValue || defaultOpt
                : defaultOpt
              let disableOption =
                disabled ||
                Boolean(clearOptionSelected && listValue[0] !== optionValue)
              const checked = listValue.includes(optionValue)
              if (!disableOption && maxOptions) {
                disableOption =
                  listValue.length >= Number(maxOptions) && !checked
              }
              return (
                <Grid
                  container
                  direction='column'
                  key={index}
                  style={{ width: renderTwoColumns && '35%' }}
                >
                  <FormControlLabel
                    style={{
                      // width: renderTwoColumns && '45%',
                      marginBottom: 10
                    }}
                    disabled={disableOption}
                    key={index}
                    value={optionValue}
                    onChange={e => {
                      const value = e.currentTarget.value
                      if (!meta.touched) {
                        setFieldTouched(id)
                      }
                      const newValues = { ...values }
                      const oldValue = [...listValue]
                      const newList = [...listValue]
                      const otherId = 'other.' + id + '.' + index
                      if (newList.includes(value)) {
                        newList.splice(newList.indexOf(value), 1)
                        newValues[id] = newList
                        if (item.requireDetails) {
                          newValues[otherId] = ''
                        }
                      } else {
                        if (item.clearOnSelect) {
                          newValues[id] = [value]
                        } else {
                          newList.push(value)
                          newValues[id] = newList
                        }
                      }
                      let lastOptionActions = []
                      if (listValue) {
                        selectableOptions.some(option => {
                          const index = item.orgIndex
                          const defaultOpt = 'option' + index
                          const optionValue = isConnected
                            ? item.apiValue || defaultOpt
                            : defaultOpt
                          const wasSelected =
                            oldValue.includes(optionValue) &&
                            !newValues[id].includes(optionValue)
                          if (wasSelected) {
                            lastOptionActions = option.selectActions
                          }
                          return wasSelected
                        })
                      }
                      const modified = handleSelectActions({
                        toSet: newValues,
                        checked: newValues[id].includes(value),
                        lastOptionActions,
                        selectActions: item.selectActions
                      })
                      setValues(newValues)
                      if (useMultiuser) {
                        commitChangeToMultipleFields({
                          array: [...modified, id, otherId].map(id => [
                            id,
                            newValues[id]
                          ]),
                          userId: user.userId,
                          formId
                        })
                      }
                    }}
                    checked={checked}
                    control={
                      <Checkbox
                        style={{
                          paddingLeft: 9,
                          paddingRight: 9,
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      />
                    }
                    label={
                      <div>
                        {option}
                        {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                      </div>
                    }
                  />
                  {Boolean(
                    listValue.includes(optionValue) && item.requireDetails
                  ) && (
                    <div
                      style={{
                        marginTop: 5,
                        marginBottom: 5
                        // width: renderTwoColumns && '100%'
                      }}
                    >
                      <RequireDetailsField
                        item={item}
                        useMultiuser={useMultiuser}
                        formId={formId}
                        id={'other.' + id + '.' + index}
                        disabled={disabled}
                      />
                    </div>
                  )}
                </Grid>
              )
            })}
          </FormGroup>
        </FormControl>
        <FormErrorLabel error={isError} id={id} required={required} />
      </div>
    )
  } else if (picklistType === 'expandable') {
    const requireDetails = optionsMap[value]?.requireDetails

    return (
      <div>
        <MUTextField
          useMultiuser={useMultiuser}
          select
          formId={formId}
          id={id}
          style={{
            marginTop: !hasTooltip && 5,
            width: componentWidth
          }}
          fullWidth={!componentWidth}
          variant='outlined'
          disabled={selectableOptions.length === 0 || disabled}
          required={required}
          onChange={e => {
            const toSet = { ...values }
            const oldValue = toSet[id]
            let lastOptionActions = []
            if (oldValue) {
              selectableOptions.some(option => {
                const index = option.orgIndex
                const defaultOpt = 'option' + index
                const optionValue = isConnected
                  ? option.apiValue || defaultOpt
                  : defaultOpt
                if (oldValue === optionValue) {
                  lastOptionActions = option.selectActions
                }
                return oldValue === optionValue
              })
            }
            toSet[id] = e.target.value
            // if (!optionsMap[e.target.value]?.requireDetails) {
            toSet[otherId] = ''
            // }
            const modified = handleSelectActions({
              toSet,
              checked: true,
              lastOptionActions,
              selectActions: optionsMap[toSet[id]]?.selectActions
            })
            setValues(toSet)
            if (useMultiuser) {
              commitChangeToMultipleFields({
                array: [...modified, id, otherId].map(id => [id, toSet[id]]),
                userId: user.userId,
                formId
              })
            }
          }}
        >
          {selectableOptions.map(item => {
            const index = item.orgIndex
            const option = langFR ? item.titleFR : item.titleEN
            const defaultOpt = 'option' + index
            const optionValue = isConnected
              ? item.apiValue || defaultOpt
              : defaultOpt
            return (
              <MenuItem key={index} value={optionValue}>
                {option}
              </MenuItem>
            )
          })}
        </MUTextField>
        {requireDetails && (
          <div style={{ marginTop: 5 }}>
            <RequireDetailsField
              item={optionsMap[value]}
              useMultiuser={useMultiuser}
              formId={formId}
              id={otherId}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <FormControl component='fieldset'>
        <RadioGroup
          style={
            renderTwoColumns
              ? {
                  flexDirection: 'row',
                  marginLeft: 25,
                  justifyContent: 'space-between'
                }
              : {}
          }
          {...field}
          value={field.value || ''}
        >
          {selectableOptions.map(item => {
            const index = item.orgIndex
            const option = langFR ? item.titleFR : item.titleEN
            const tooltip = langFR ? item.tooltipFR : item.tooltipEN
            const defaultOpt = 'option' + index
            const optionValue = isConnected
              ? item.apiValue || defaultOpt
              : defaultOpt
            return (
              <>
                <FormControlLabel
                  style={{ width: renderTwoColumns && '45%', marginBottom: 10 }}
                  key={index}
                  disabled={disabled}
                  value={optionValue}
                  control={
                    <Radio
                      style={{
                        paddingLeft: 9,
                        paddingRight: 9,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                      onChange={e => {
                        const toSet = { ...values }
                        const oldValue = toSet[id]
                        let lastOptionActions = []
                        if (oldValue) {
                          selectableOptions.some(option => {
                            const index = item.orgIndex
                            const defaultOpt = 'option' + index
                            const optionValue = isConnected
                              ? item.apiValue || defaultOpt
                              : defaultOpt
                            if (oldValue === optionValue) {
                              lastOptionActions = option.selectActions
                            }
                            return oldValue === optionValue
                          })
                        }
                        toSet[id] = e.target.value
                        // if (!item.requireDetails) {
                        toSet[otherId] = ''
                        // }
                        const modified = handleSelectActions({
                          toSet,
                          checked: true,
                          selectActions: item.selectActions,
                          lastOptionActions
                        })
                        setValues(toSet)
                        if (useMultiuser) {
                          commitChangeToMultipleFields({
                            array: [...modified, id, otherId].map(id => [
                              id,
                              toSet[id]
                            ]),
                            userId: user.userId,
                            formId
                          })
                        }
                      }}
                    />
                  }
                  label={
                    <div>
                      {option}
                      {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
                    </div>
                  }
                />
                {Boolean(
                  field.value === optionValue && item.requireDetails
                ) && (
                  <div
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      width: renderTwoColumns && '100%'
                    }}
                  >
                    <RequireDetailsField
                      item={item}
                      useMultiuser={useMultiuser}
                      formId={formId}
                      id={'other.' + id}
                      disabled={disabled}
                    />
                  </div>
                )}
              </>
            )
          })}
        </RadioGroup>
      </FormControl>
      <FormErrorLabel error={isError} id={id} required={required} />
    </div>
  )
}

const RequireDetailsField = ({ item, ...props }) => {
  let specifyLimit
  if (item && item.connectedFieldDetails) {
    specifyLimit = item.connectedFieldDetails.length
  }
  return (
    <>
      <Trans>Please, specify: </Trans>
      <MUTextField
        {...props}
        inputProps={{
          maxLength: specifyLimit && Number(specifyLimit)
        }}
        limit={specifyLimit}
      />
    </>
  )
}

export const FormEditorPicklist = ({
  typeProps = {},
  elementType,
  id,
  french,
  editMode,
  depth,
  showPrintProps,
  showPdfProps,
  ...props
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const {
    options = [],
    required,
    requiredAll,
    forceRequired,
    picklistType,
    isConnected,
    dynamic,
    componentWidth,
    maxOptions
  } = typeProps

  if (!editMode) {
    return (
      <FormPicklist
        preview
        typeProps={typeProps}
        id={id}
        {...props}
        langFR={french}
      />
    )
  }
  const { connectedField, connectedObject } = getMainConnected({
    typeProps,
    ...props
  })
  const { objects, objectsConnected } = values
  let fieldsToConnect = []
  const avaliableObjectsMap = {}
  objectsConnected.forEach(obj => {
    avaliableObjectsMap[obj.identId] = obj
  })
  connectedObject &&
    objects.some(obj => {
      if (!avaliableObjectsMap[connectedObject]) {
        return false
      }
      const bool = obj.name === avaliableObjectsMap[connectedObject].type
      if (bool) {
        fieldsToConnect = obj.fields
      }
      return bool
    })

  const fieldsMap = {}
  fieldsToConnect.forEach(field => {
    fieldsMap[field.name] = field
  })
  fieldsToConnect = fieldsToConnect.filter(item =>
    validFieldTypesForTextField.includes(item.type)
  )
  const forceReadOnly =
    (connectedField && connectedField.readOnly) || showPrintProps
  const readOnly = typeProps.readOnly || forceReadOnly

  return (
    <Grid container style={{ padding: 10 }} direction='column'>
      {!showPdfProps && (
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(readOnly && connectedField) || forceReadOnly}
              disabled={!connectedField || forceReadOnly}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.readOnly = e.target.checked
                delete toSet.required
                delete toSet.requiredAll
                delete toSet.componentWidth
                delete toSet.maxOptions
                // delete toSet.picklistType
                toSet.options = []
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Read only</Trans>}
        />
      )}

      {readOnly && !showPdfProps ? (
        <Alert severity='info' variant='outlined'>
          <Trans>
            You can't change this element to an input. Either connected field or
            whole form is marked as read only
          </Trans>
        </Alert>
      ) : (
        !showPdfProps && (
          <>
            <FormControlLabel
              disabled={readOnly || forceRequired}
              style={{ marginBottom: 5 }}
              control={
                <Checkbox
                  checked={Boolean(required || forceRequired)}
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet.required = e.target.checked
                    if (e.target.checked) {
                      delete toSet.requiredAll
                    }
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              }
              label={<Trans>Required</Trans>}
            />

            {picklistType === 'multiselect' && (
              <FormControlLabel
                disabled={readOnly || forceRequired}
                style={{ marginBottom: 5 }}
                control={
                  <Checkbox
                    checked={Boolean(requiredAll)}
                    onChange={e => {
                      const toSet = { ...typeProps }
                      toSet.requiredAll = e.target.checked
                      if (e.target.checked) {
                        delete toSet.required
                      }
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: { ...toSet }
                      })
                    }}
                  />
                }
                label={<Trans>Required (All)</Trans>}
              />
            )}

            {picklistType === 'expandable' && (
              <TextField
                variant='outlined'
                label={<Trans>Component width</Trans>}
                fullWidth
                value={componentWidth}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.componentWidth = e.currentTarget.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            )}
          </>
        )
      )}

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        validTypes={validFieldTypesForPicklist}
        allowReadOnlyFields
        clearOnReadOnlyField={[
          'required',
          'requiredAll',
          'componentWidth',
          'maxOptions'
        ]}
        handleDisconnect={toSet => {
          delete toSet.dynamic
          if (toSet.options) {
            toSet.options.forEach(option => {
              delete option.apiValue
            })
          }
        }}
      />

      {!showPdfProps && (
        <FormControl>
          <FormLabel component='legend'>
            <Trans>List type</Trans>
          </FormLabel>
          <RadioGroup
            defaultValue='singleselect'
            value={picklistType || 'singleselect'}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.picklistType = e.currentTarget.value
              // delete toSet.connectedField
              delete toSet.requiredAll
              delete toSet.componentWidth
              if (e.currentTarget.value !== 'multiselect') {
                if (toSet.options) {
                  toSet.options.forEach(option => {
                    delete option.clearOnSelect
                  })
                }
                delete toSet.maxOptions
              }
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          >
            <FormControlLabel
              disabled={
                Boolean(
                  connectedField && connectedField.type !== 'multipicklist'
                ) || forceReadOnly
              }
              value='multiselect'
              control={<Radio />}
              label={<Trans>Multiselect</Trans>}
            />
            {
              <FormControlLabel
                disabled={
                  Boolean(
                    connectedField && connectedField.type === 'multipicklist'
                  ) || forceReadOnly
                }
                value='singleselect'
                control={<Radio />}
                label={<Trans>Single select (Radio group)</Trans>}
              />
            }
            {
              <FormControlLabel
                disabled={
                  Boolean(
                    connectedField && connectedField.type === 'multipicklist'
                  ) || forceReadOnly
                }
                value='expandable'
                control={<Radio />}
                label={<Trans>Single select (Dropdown)</Trans>}
              />
            }
          </RadioGroup>
        </FormControl>
      )}

      <FormControlLabel
        disabled={!connectedField}
        style={{ marginBottom: 5 }}
        control={
          <Checkbox
            checked={Boolean(dynamic)}
            onChange={e => {
              const toSet = { ...typeProps }
              toSet.dynamic = e.target.checked
              if (e.target.checked) {
                toSet.options = []
              }
              dispatch({
                type: 'FIELD',
                depth: depth.split('.'),
                fieldName: 'typeProps',
                fieldValue: toSet
              })
            }}
          />
        }
        label={<Trans>Dynamic picklist</Trans>}
      />
      {picklistType === 'multiselect' && !readOnly && !showPdfProps && (
        <TextField
          variant='outlined'
          label={<Trans>Max options to select</Trans>}
          fullWidth
          value={maxOptions || ''}
          InputProps={{ inputComponent: NumberFormatDefault }}
          onChange={e => {
            const toSet = { ...typeProps }
            toSet.maxOptions = e.target.value
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        />
      )}

      {(readOnly || showPdfProps) && (
        <Alert severity='info' variant='outlined'>
          <Trans>
            For read only view all options selected by user will be displayed
            regardless of configuration. If element is marked as Dynamic
            Picklist it will use labels from Selesforce. If it is not it will
            use labels configured below
          </Trans>
        </Alert>
      )}

      {!dynamic && (
        <div style={{ marginTop: 5 }}>
          <Grid container direction='row' alignItems='center'>
            <Typography>
              <Trans>Picklist options</Trans>
            </Typography>
            <IconButton
              onClick={() => {
                options.push({ titleEN: '', titleFR: '', apiValue: '' })
                typeProps.options = options
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Icon>add</Icon>
            </IconButton>
            <Button
              disabled={!connectedField}
              style={{ marginLeft: 10 }}
              color='primary'
              variant='contained'
              onClick={() => {
                const fieldInfo = fieldsMap[connectedField.name]
                const toSet = fieldInfo?.picklistValues
                  .map(sfObj => {
                    if (sfObj.active) {
                      const french = sfObj.labelLang !== 'en_US'
                      const toRet = {
                        titleEN: french ? '' : sfObj.label,
                        titleFR: french ? sfObj.label : '',
                        apiValue: sfObj.value
                      }

                      return toRet
                    }
                  })
                  .filter(item => item)
                typeProps.options = toSet || []
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...typeProps }
                })
              }}
            >
              <Trans>Use values from object definition</Trans>
            </Button>
          </Grid>
          <Grid container direction='column'>
            {options.map((item, index) => {
              const selectActions = item.selectActions || []
              return (
                <div key={index}>
                  <Grid
                    item
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    wrap='nowrap'
                  >
                    {index + 1 + '. '}
                    <Grid item style={{ flex: 1, padding: 10 }}>
                      <Grid container direction='row'>
                        <Grid item xs={!isConnected ? 6 : 4}>
                          <TextField
                            variant='outlined'
                            label={<Trans>Option label - english</Trans>}
                            fullWidth
                            value={item.titleEN}
                            onChange={e => {
                              const toSet = { ...typeProps }
                              toSet.options[index].titleEN =
                                e.currentTarget.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'typeProps',
                                fieldValue: toSet
                              })
                              // setOptionEN(e.currentTarget.value)
                            }}
                          />
                        </Grid>
                        <Grid item xs={!isConnected ? 6 : 4}>
                          <TextField
                            variant='outlined'
                            label={<Trans>Option label - french</Trans>}
                            fullWidth
                            value={item.titleFR}
                            onChange={e => {
                              const toSet = { ...typeProps }
                              toSet.options[index].titleFR =
                                e.currentTarget.value
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'typeProps',
                                fieldValue: toSet
                              })
                              // setOptionFR(e.currentTarget.value)
                            }}
                          />
                        </Grid>
                        {isConnected && (
                          <Grid item xs={4}>
                            <TextField
                              variant='outlined'
                              label={<Trans>API value</Trans>}
                              fullWidth
                              value={item.apiValue}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].apiValue =
                                  e.currentTarget.value
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: toSet
                                })
                                // setOptionFR(e.currentTarget.value)
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <div style={{ width: 50 }}>
                      <IconButton
                        onClick={() => {
                          const toSet = { ...typeProps }
                          toSet.options.splice(index, 1)
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </div>
                  </Grid>
                  {!(forceReadOnly || readOnly) && !showPdfProps && (
                    <>
                      <Grid container direction='row'>
                        <FormControlLabel
                          style={{ marginLeft: 15 }}
                          control={
                            <Checkbox
                              checked={Boolean(item.requireDetails)}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].requireDetails =
                                  e.target.checked
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: { ...toSet }
                                })
                              }}
                            />
                          }
                          label={<Trans>Require details</Trans>}
                        />
                        {picklistType === 'multiselect' && (
                          <FormControlLabel
                            style={{ marginLeft: 15 }}
                            control={
                              <Checkbox
                                checked={Boolean(item.clearOnSelect)}
                                onChange={e => {
                                  const toSet = { ...typeProps }
                                  toSet.options[index].clearOnSelect =
                                    e.target.checked
                                  dispatch({
                                    type: 'FIELD',
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: { ...toSet }
                                  })
                                }}
                              />
                            }
                            label={
                              <Trans>
                                Clear and disable other options on select
                              </Trans>
                            }
                          />
                        )}
                        <FormControlLabel
                          style={{ marginLeft: 15 }}
                          control={
                            <Checkbox
                              checked={Boolean(item.hasTooltip)}
                              onChange={e => {
                                const toSet = { ...typeProps }
                                toSet.options[index].hasTooltip =
                                  e.target.checked
                                delete toSet.options[index].tooltipEN
                                delete toSet.options[index].tooltipFR
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: { ...toSet }
                                })
                              }}
                            />
                          }
                          label={<Trans>Has tooltip?</Trans>}
                        />
                        {Boolean(item.hasTooltip) && (
                          <Grid
                            container
                            direction='row'
                            style={{ padding: 20, marginRight: 35 }}
                          >
                            <Grid xs={6}>
                              <TextField
                                variant='outlined'
                                label={<Trans>Option tooltip - english</Trans>}
                                fullWidth
                                value={item.tooltipEN}
                                onChange={e => {
                                  const toSet = { ...typeProps }
                                  toSet.options[index].tooltipEN =
                                    e.currentTarget.value
                                  dispatch({
                                    type: 'FIELD',
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: toSet
                                  })
                                }}
                              />
                            </Grid>
                            <Grid xs={6}>
                              <TextField
                                variant='outlined'
                                label={<Trans>Option tooltip - french</Trans>}
                                fullWidth
                                value={item.tooltipFR}
                                onChange={e => {
                                  const toSet = { ...typeProps }
                                  toSet.options[index].tooltipFR =
                                    e.currentTarget.value
                                  dispatch({
                                    type: 'FIELD',
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: toSet
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {!showPdfProps && (
                        <ConfigureSelectActions
                          depth={depth}
                          typeProps={typeProps}
                          id={id}
                          index={index}
                          french={french}
                          selectActions={selectActions}
                        />
                      )}
                    </>
                  )}
                  {connectedObject && !showPdfProps && (
                    <div
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingBottom: 10
                      }}
                    >
                      <Autocomplete
                        freeSolo={false}
                        value={
                          options[index].connectedField
                            ? options[index].connectedField.label
                            : null
                        }
                        onChange={(e, value) => {
                          const toSet = { ...typeProps }
                          let obj
                          fieldsToConnect.some(item => {
                            const bool = item.label === value
                            if (bool) {
                              obj = item
                            }
                            return bool
                          })
                          toSet.options[index].connectedField = obj
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                        style={{ marginTop: 15 }}
                        fullWidth
                        options={fieldsToConnect.map(item => item.label)}
                        renderInput={params => (
                          <TextField
                            variant='outlined'
                            {...params}
                            label={<Trans>Connected field</Trans>}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </Grid>
        </div>
      )}
    </Grid>
  )
}
