import { grpc } from '@improbable-eng/grpc-web'
import { datetimeFormat } from 'app/appSettings'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import { uncompressFormData } from 'app/services/sfAuth/sfData/sfForms'
import moment from 'moment'
import {
  FormContent,
  FormID,
  FormPointer
} from './proto/generated/Multiuser_grpc_web_pb'
import { Multiuser } from './proto/generated/Multiuser_pb_service'

export const uploadFormBackup = ({ id, content, onFail, onSuccess }) => {
  const formContent = new FormContent()
  formContent.setId(id)
  formContent.setContent(content)
  grpc.unary(Multiuser.UploadForm, {
    request: formContent,
    host: sfOauthConfig.getHost(),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(response.message)
        }
      }
    }
  })
}

export const getFormBackups = ({ id, onFail, onSuccess }) => {
  const formId = new FormID()
  formId.setId(id)
  grpc.unary(Multiuser.GetListOfRevisions, {
    request: formId,
    host: sfOauthConfig.getHost(),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          const resArray = response.message.array
          const toRet = []
          const length = resArray[0].length
          for (let index = 0; index < length; index++) {
            toRet.push({
              revision: resArray[0][index],
              date: moment.unix(resArray[1][index]).format(datetimeFormat)
            })
          }
          onSuccess(toRet)
        }
      }
    }
  })
}

export const getFormBackup = ({ id, revision, onFail, onSuccess }) => {
  const formPointer = new FormPointer()
  formPointer.setId(id)
  formPointer.setRevision(revision)
  grpc.unary(Multiuser.GetForm, {
    request: formPointer,
    host: sfOauthConfig.getHost(),
    onEnd: response => {
      if (response.status !== grpc.Code.OK) {
        if (onFail) {
          onFail()
        }
      } else {
        if (onSuccess) {
          onSuccess(uncompressFormData(response.message.array[3]))
        }
      }
    }
  })
}
