import { Trans } from '@lingui/macro'
import {
  CardActionArea,
  Collapse,
  Grid,
  Icon,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import { questionTypes } from './QuestionWidget'

function SurveyCreationFieldMenu ({
  anchorEl,
  handleClose,
  index,
  setExternalDialogOpened,
  setFieldValue,
  setValues,
  values
}) {
  const [expandedTypes, setExpandedTypes] = useState(false)
  const [renderDummyNumber, forceRender] = useState(0)

  const handleExpandTypesClick = () => {
    setExpandedTypes(!expandedTypes)
  }

  if (!anchorEl) {
    return null
  }

  const question = values.created[index]

  return (
    <Menu
      id='simple-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onBackdropClick={handleClose}
    >
      <MenuItem
        onClick={() => {
          const toSet = [...values.created]
          if (index > 0) {
            const toMove = toSet[index]
            const toReplace = toSet[index - 1]
            toSet[index - 1] = toMove
            toSet[index] = toReplace
            handleClose()
            setFieldValue('created', toSet)
          }
        }}
      >
        <Icon style={{ marginRight: 5, color: '#757575' }}>arrow_upward</Icon>
        <Trans>Move up</Trans>
      </MenuItem>
      <MenuItem
        onClick={() => {
          const toSet = [...values.created]
          if (index < toSet.length - 1) {
            const toMove = toSet[index]
            const toReplace = toSet[index + 1]
            toSet[index + 1] = toMove
            toSet[index] = toReplace
            setFieldValue('created', toSet)
            handleClose()
          }
        }}
      >
        <Icon style={{ marginRight: 5, color: '#757575' }}>arrow_downward</Icon>
        <Trans>Move down</Trans>
      </MenuItem>
      <MenuItem
        onClick={() => {
          const deletedOptions = question.options
          const deletedQuestion = question
          const newDelete = [...values.toDelete]
          if (question.sfId && question.joins.length === 1) {
            deletedOptions.forEach(deletedOption => {
              if (!deletedOption.parentId) {
                return
              }
              newDelete.push(
                {
                  type: 'SurveyTranslation__c',
                  id: deletedOption.parentId
                },
                {
                  type: 'SurveyItemDetail__c',
                  id: deletedOption.detailId
                },
                {
                  type: 'Survey_Text__c',
                  id: deletedOption.frTransId
                },
                {
                  type: 'Survey_Text__c',
                  id: deletedOption.enTransId
                }
              )
            })
            if (question.groupQuestions) {
              question.groupQuestions.forEach(sub => {
                if (sub.sfId) {
                  newDelete.push(
                    {
                      type: 'Survey_Join_Template_and_Item__c',
                      id: sub.joinId
                    },
                    {
                      type: 'SurveyTranslation__c',
                      id: sub.itemTranslation.parentId
                    },
                    {
                      type: 'Survey_Text__c',
                      id: sub.itemTranslation.enTransId
                    },
                    {
                      type: 'Survey_Text__c',
                      id: sub.itemTranslation.frTransId
                    },
                    { type: 'Survey_Items__c', id: sub.sfId }
                  )
                }
              })
            }
            newDelete.push(
              {
                type: 'Survey_Join_Template_and_Item__c',
                id: deletedQuestion.joinId
              },
              {
                type: 'SurveyTranslation__c',
                id: deletedQuestion.itemTranslation.parentId
              },
              {
                type: 'Survey_Text__c',
                id: deletedQuestion.itemTranslation.enTransId
              },
              {
                type: 'Survey_Text__c',
                id: deletedQuestion.itemTranslation.frTransId
              },
              { type: 'Survey_Items__c', id: deletedQuestion.sfId }
            )
          } else if (question.sfId && question.joins.length > 1) {
            const newValues = { ...values }
            if (question.groupQuestions) {
              question.groupQuestions.forEach(sub => {
                newDelete.push({
                  type: 'Survey_Join_Template_and_Item__c',
                  id: sub.joinId
                })
              })
            }
            newDelete.push({
              type: 'Survey_Join_Template_and_Item__c',
              id: deletedQuestion.joinId
            })
            setValues(newValues)
          }
          const toSet = [...values.created]
          toSet.splice(index, 1)
          setFieldValue('toDelete', newDelete)
          setFieldValue('created', toSet)
          handleClose()
        }}
      >
        <Icon style={{ marginRight: 5, color: '#757575' }}>delete</Icon>
        <Trans>Delete</Trans>
      </MenuItem>
      <MenuItem onClick={handleExpandTypesClick}>
        <Icon style={{ marginRight: 5, color: '#757575' }}>style</Icon>
        <div style={{ flex: 1 }}>
          <Trans>Change question type</Trans>
        </div>
        <Icon>{expandedTypes ? 'expand_less' : 'expand_more'}</Icon>
      </MenuItem>

      <Collapse
        in={expandedTypes}
        onEntered={() => {
          forceRender(renderDummyNumber + 1)
        }}
        onExited={() => {
          forceRender(renderDummyNumber + 1)
        }}
      >
        {[
          'HEADER',
          'SELECT_ONE',
          'SELECT_MULTIPLE',
          'FIVE_POINT_FREQUENCY',
          'FIVE_POINT_TIME',
          'FIVE_POINT_EXTENT',
          'FOUR_POINT_SATISFACTION',
          'INPUT_TEXT',
          'INPUT_TEXTAREA',
          'INPUT_NUMBER',
          'SLIDER',
          'INPUT_DATE',
          'INPUT_DATE_RANGE',
          'SELECT_YESNO',
          'UPLOAD_FILES'
        ].map((id, mapIndex) =>
          returnTypeOption({
            index: index,
            setFieldValue,
            toDelete: values.toDelete,
            question: question,
            handleClose: handleClose,
            idString: id,
            icon: questionTypes[id].icon,
            text: questionTypes[id].text
          })
        )}

        <div style={{ width: '100%' }}>
          <CardActionArea
            onClick={() => {
              setExternalDialogOpened(index, false)
              handleClose()
            }}
          >
            <Grid container direction='row' alignItems='center'>
              <Icon
                style={{
                  margin: 10,
                  marginLeft: 25,
                  fontSize: '28px',
                  color: '#757575'
                }}
              >
                next_plan
              </Icon>
              <Typography style={{ textAlign: 'center', marginRight: 15 }}>
                <Trans>Question from another template</Trans>
              </Typography>
            </Grid>
          </CardActionArea>
          <CardActionArea
            onClick={() => {
              setExternalDialogOpened(index, true)
              handleClose()
            }}
          >
            <Grid container direction='row' alignItems='center'>
              <Icon
                style={{
                  margin: 10,
                  marginLeft: 25,
                  fontSize: '28px',
                  color: '#757575'
                }}
              >
                next_plan
              </Icon>
              <Typography style={{ textAlign: 'center', marginRight: 15 }}>
                <Trans>Copy question from another template</Trans>
              </Typography>
            </Grid>
          </CardActionArea>
        </div>
      </Collapse>
    </Menu>
  )
}

function returnTypeOption ({
  index,
  toDelete,
  question,
  handleClose,
  idString,
  setFieldValue,
  icon,
  text
}) {
  return (
    <div style={{ width: '100%' }}>
      <CardActionArea
        onClick={() => {
          const toSet = { ...question }
          toSet.type = questionTypes[idString].id
          delete toSet.allowRowsToExpand
          toSet.rows = null
          toSet.max = null
          toSet.min = null
          toSet.maxCharacters = null
          toSet.validationRules = []
          if (
            toSet.type === questionTypes.UPLOAD_FILES.id ||
            toSet.type === questionTypes.HEADER.id
          ) {
            toSet.conditionalObject = false
            toSet.isGroup = false
            toSet.isLinked = false
            toSet.isRequired = false
            toSet.conditionForObjectField = ''
            toSet.conditionalObjectField = ''
            toSet.conditionalObjectName = ''
            toSet.conditionalObjectSubfield = ''
          }

          const isOption =
            questionTypes[idString].id === questionTypes.SELECT_MULTIPLE.id ||
            questionTypes[idString].id === questionTypes.SELECT_ONE.id
          if (!isOption) {
            toSet.options = []
            const newDelete = [...toDelete]
            question.options.forEach(option => {
              if (!option.parentId) {
                return
              }
              newDelete.push(
                {
                  type: 'SurveyTranslation__c',
                  id: option.parentId
                },
                {
                  type: 'SurveyItemDetail__c',
                  id: option.detailId
                },
                {
                  type: 'Survey_Text__c',
                  id: option.frTransId
                },
                {
                  type: 'Survey_Text__c',
                  id: option.enTransId
                }
              )
            })
            setFieldValue('toDelete', newDelete)
          } else if (
            questionTypes[idString].id === questionTypes.SELECT_ONE.id
          ) {
            toSet.options.forEach(opt => {
              delete opt.clearOnSelect
            })
          }
          setFieldValue(`created.${index}`, toSet)
          handleClose()
        }}
      >
        <Grid container direction='row' alignItems='center'>
          <Icon
            style={{
              margin: 10,
              marginLeft: 25,
              fontSize: '28px',
              color: question.type === idString ? 'red' : '#757575'
            }}
          >
            {icon}
          </Icon>
          <Typography style={{ textAlign: 'center', marginRight: 15 }}>
            {text}
          </Typography>
        </Grid>
      </CardActionArea>
    </div>
  )
}

export default SurveyCreationFieldMenu
