import {
  checkIfFormVersionExists,
  checkNextAvaliableFormVersion,
  compressFormData,
  createFormPage,
  deleteFormPage,
  fetchFormPages,
  FORM_PREVIEW_CONFIG,
  FORM_VERSION_ERROR,
  removeOriginFromFormPage,
  savePreviewConfig,
  updateFormPage
} from 'app/services/sfAuth/sfData/sfForms'
import Loading from 'egret/components/EgretLoadable/Loading'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { t, Trans } from '@lingui/macro'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import MUIDataTable, { ExpandButton } from 'mui-datatables'
import { muiTextLabels } from '../utilities/muiDataTablesTranslations'
import { countHelperText } from '../page-layouts/FormElement'
import { connect, useSelector, useDispatch } from 'react-redux'
import { constructFormAddressString } from '../forms/components/Common'
import { myI18n } from 'translation/I18nConnectedProvider'
import moment from 'moment'
import { dateFormat } from 'app/appSettings'
import { muiDateCompare } from 'utils'
import { formObjectsToConnect } from '../forms/FormWizard'
import * as crypto from 'crypto'
import { setConfigurationData } from 'app/redux/actions/ConfigurationActions'
import _, { template } from 'lodash'
import {
  findSurveyTemplatesWithChildren,
  findTemplates,
  getSubtemplates
} from 'app/services/sfAuth/sfData/sfSurvey'
import { sfDataToRenderData } from '../surveys/SurveyTab'
import { questionTypes } from '../surveys/QuestionWidget'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'

export const FORMS_PREVIEW_ID_OPPORTUNITY = 'FORMS_PREVIEW_ID_OPPORTUNITY'
export const FORMS_PREVIEW_ID_RESOURCE = 'FORMS_PREVIEW_ID_RESOURCE'

const Forms = ({ settings }) => {
  const [formsList, setFormsList] = React.useState(null)
  const [removingFromOrigin, setRemovingFromOrigin] = React.useState([])
  const [expandedRows, setExpandedRows] = React.useState([])
  const [surveyTemplates, setSurveyTemplates] = React.useState(null)
  const history = useHistory()
  const lang = useSelector(state => state.user.language)
  const organization = useSelector(state => state.organization)
  const user = useSelector(state => state.user)
  const configuration = useSelector(state => state.configuration)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    fetchData()
    findSurveyTemplatesWithChildren({
      language: user.language
    }).then(result => {
      setSurveyTemplates(Object.values(result))
    })
  }, [])

  const fetchData = (keepOpen = false) => {
    console.log('try to got form pages', organization.id, user.userId)
    return fetchFormPages()
      .then(result => {
        console.log('got form pages', result)
        const formsList = []
        if (!keepOpen) {
          setExpandedRows([])
        }
        const versionsMap = {}
        result.forEach(obj => {
          let connected = []
          if (obj.config.objectsConnected) {
            connected = [...obj.config.objectsConnected]
          }
          const version = obj.config.version ? Number(obj.config.version) : null
          const data = {
            id: obj.id,
            origin: obj.origin,
            comments: obj.comments,
            name: lang === 'en_US' ? obj.en : obj.fr,
            lastModifiedBy: obj.lastModifiedBy,
            lastModifiedDate: obj.lastModifiedDate,
            version,
            actions: {
              id: obj.id,
              version,
              connectedObjects: connected,
              form: obj
            }
          }
          if (obj.origin) {
            if (versionsMap[obj.origin]) {
              versionsMap[obj.origin].push(data)
            } else {
              versionsMap[obj.origin] = [data]
            }
          } else {
            formsList.push(data)
          }
        })
        Object.values(versionsMap).forEach(array => {
          array = array.sort((a, b) => a.version - b.version)
          if (array.length === 1) {
            formsList.push(array[0])
          } else {
            formsList.push({
              children: array,
              origin: array[0].origin,
              name: array[0].name
            })
          }
        })
        setFormsList(formsList)
        enqueueSnackbar(<Trans>Loaded data</Trans>, { variant: 'info' })
      })
      .catch(err => {
        console.log('error got form pages', err)
        enqueueSnackbar(<Trans>Error getting data</Trans>, {
          variant: 'error'
        })
      })
  }

  if (!formsList) {
    return <Loading />
  }

  const actionsBodyRender = (value, tableMeta, updateValue, formsList) => {
    if (!value) {
      return <div style={{ minHeight: 50 }} />
    }
    const defaultAddressString = constructFormAddressString({
      previewConfig: configuration[FORM_PREVIEW_CONFIG],
      user,
      organization,
      configuration,
      contact: user.userObject.contactId,
      objectsConnected: value.connectedObjects
    })
    return (
      <Grid container justify='flex-end' wrap='nowrap'>
        <Tooltip title={<Trans>Preview</Trans>}>
          <IconButton
            style={{ marginRight: 5 }}
            onClick={() => {
              history.push(
                '/elasticform/' +
                  `${value.id}` +
                  '/' +
                  defaultAddressString +
                  '/preview'
              )
            }}
          >
            <Icon>remove_red_eye</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title={<Trans>Edit form</Trans>}>
          <IconButton
            style={{ marginRight: 5 }}
            onClick={() => {
              history.push('/internal/FormEditor' + `/${value.id}`)
            }}
          >
            <Icon>edit</Icon>
          </IconButton>
        </Tooltip>
        {value.form.origin ? (
          <Tooltip title={<Trans>Remove connection to parent</Trans>}>
            <IconButton
              disabled={removingFromOrigin.includes(value.id)}
              style={{ marginRight: 5 }}
              onClick={() => {
                setRemovingFromOrigin([...removingFromOrigin, value.id])
                removeOriginFromFormPage({
                  id: value.id,
                  origin: value.form.origin
                }).then(result => {
                  fetchData().then(result => {
                    let toSet = [...removingFromOrigin]
                    toSet = toSet.filter(id => id !== value.id)
                    setRemovingFromOrigin(toSet)
                  })
                })
              }}
            >
              <Icon>low_priority</Icon>
            </IconButton>
          </Tooltip>
        ) : (
          <ReparentFormButton
            reloadData={fetchData}
            form={value.form}
            formsList={formsList}
          />
        )}
        <DuplicateFormButton reloadData={fetchData} form={value.form} />
        <DeleteFormPageButton
          id={value.id}
          reloadData={fetchData}
          origin={value.form.origin}
        />
      </Grid>
    )
  }

  return (
    <div style={{ padding: 15 }}>
      <Grid container justifyContent='flex-end' style={{ marginBottom: 15 }}>
        <Grid item>
          <ConfigureFormPreviewButton
            config={configuration[FORM_PREVIEW_CONFIG]}
          />
        </Grid>
      </Grid>
      <MUIDataTable
        title={
          <Grid container>
            <Typography variant='h5'>
              <b>
                <Trans>Form Pages</Trans>
              </b>
            </Typography>
            <AddFormPageButton reloadData={fetchData} />
            <CreateFormFromSurveyButton
              reloadData={fetchData}
              surveyTemplates={surveyTemplates}
            />
          </Grid>
        }
        data={formsList}
        options={{
          textLabels: muiTextLabels(myI18n),
          rowsExpanded: expandedRows,
          expandableRows: true,
          filter: false,
          selectableRows: 'none',
          print: false,
          download: false,
          viewColumns: false,
          renderExpandableRow: (rowData, rowMeta) => {
            if (!formsList[rowMeta.dataIndex]) {
              return null
            }
            return (
              <TableRow>
                <TableCell colSpan={rowData.length + 1} style={{ padding: 0 }}>
                  <MUIDataTable
                    options={{
                      filter: false,
                      search: false,
                      selectableRows: 'none',
                      expandableRows: true,
                      print: false,
                      download: false,
                      viewColumns: false,
                      fixedHeader: false,
                      rowsPerPage: 100,
                      customFooter: props => null,
                      setRowProps: (row, dataIndex, rowIndex) => {
                        return {
                          style: { backgroundColor: '#DDDDDD' }
                        }
                      }
                    }}
                    components={{
                      ExpandButton: props => <div style={{ width: '24px' }} />
                    }}
                    data={formsList[rowMeta.dataIndex]?.children || []}
                    columns={[
                      {
                        name: 'name',
                        label: myI18n._(t`Form title`),
                        options: {
                          // customHeadLabelRender: props => {
                          //   return <div style={{ marginLeft: 20 }} />
                          // },
                          customHeadRender: props => (
                            <TableCell
                              style={{
                                marginLeft: 20,
                                height: 1,
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            />
                          ),
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <div style={{ flex: 1, marginLeft: 20 }}>
                                {value}
                              </div>
                            )
                          }
                        }
                      },
                      {
                        name: 'version',
                        label: myI18n._(t`Version`),
                        options: {
                          customHeadRender: props => (
                            <TableCell
                              style={{
                                width: 100,
                                height: 1,
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            />
                          ),
                          customBodyRender: value => (
                            <div
                              style={{ textAlign: 'center', paddingRight: 20 }}
                            >
                              {value}
                            </div>
                          )
                        }
                      },
                      {
                        name: 'id',
                        label: myI18n._(t`Id`),
                        options: {
                          customHeadRender: props => (
                            <TableCell
                              style={{
                                width: 160,
                                height: 1,
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            />
                          )
                        }
                      },
                      {
                        name: 'lastModifiedDate',
                        label: myI18n._(t`Last modified date`),
                        sortCompare: muiDateCompare(),
                        options: {
                          customBodyRender: value => (
                            <div
                              style={{ textAlign: 'center', paddingRight: 20 }}
                            >
                              {moment.utc(value).format(dateFormat)}
                            </div>
                          ),
                          customHeadRender: props => (
                            <TableCell
                              style={{
                                width: 180,
                                height: 1,
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            />
                          )
                        }
                      },
                      {
                        name: 'lastModifiedBy',
                        label: myI18n._(t`Last modified by`),
                        options: {
                          customHeadRender: props => (
                            <TableCell
                              style={{
                                width: 180,
                                height: 1,
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            />
                          ),
                          customBodyRender: value => (
                            <div
                              style={{ textAlign: 'center', paddingRight: 20 }}
                            >
                              {value}
                            </div>
                          )
                        }
                      },
                      {
                        name: 'comments',
                        label: myI18n._(t`Comments`),
                        options: {
                          customHeadRender: props => (
                            <TableCell
                              style={{
                                width: 250,
                                height: 1,
                                paddingTop: 0,
                                paddingBottom: 0
                              }}
                            />
                          )
                        }
                      },
                      {
                        name: 'actions',
                        label: myI18n._(t`Actions`),
                        options: {
                          sort: false,
                          customHeadRender: props => {
                            return (
                              <TableCell
                                style={{
                                  width: 260,
                                  paddingTop: 0,
                                  paddingBottom: 0
                                }}
                              />
                            )
                          },
                          customBodyRender: (value, tableMeta, updateValue) =>
                            actionsBodyRender(
                              value,
                              tableMeta,
                              updateValue,
                              formsList
                            )
                        }
                      }
                    ]}
                  />
                </TableCell>
              </TableRow>
            )
          }
        }}
        components={{
          ExpandButton: props => {
            if (
              props.dataIndex === undefined ||
              props.dataIndex >= formsList.length
            ) {
              return <div style={{ width: '24px' }} />
            }
            if (formsList[props.dataIndex]?.children?.length > 0) {
              return (
                <ExpandButton
                  {...props}
                  style={{ margin: 20 }}
                  onExpand={() => {
                    const toSet = [...expandedRows]
                    if (!expandedRows.includes(props.dataIndex)) {
                      toSet.push(props.dataIndex)
                    } else {
                      toSet.splice(toSet.indexOf(props.dataIndex), 1)
                    }
                    setExpandedRows(toSet)
                    props.onExpand()
                  }}
                />
              )
            }
            return <div style={{ width: '24px' }} />
          }
        }}
        columns={[
          {
            name: 'name',
            label: myI18n._(t`Form title`),
            options: {
              // customHeadRender: (props, sort, sortData) => {
              //   return (
              //     <TableCell style={{ width: 300 }}>
              //       <Button
              //         style={{ textTransform: 'none', paddingLeft: 20 }}
              //         onClick={() => {
              //           sort(0)
              //         }}
              //       >
              //         <Trans>Form title</Trans>
              //         {sortData?.name === 'name' && (
              //           <Icon
              //             style={{
              //               fontSize: '0.875rem',
              //               fontWeight: 500,
              //               color: '#757575',
              //               margin: 4
              //             }}
              //           >
              //             {sortData.direction === 'desc'
              //               ? 'arrow_downward'
              //               : 'arrow_upward'}
              //           </Icon>
              //         )}
              //       </Button>
              //     </TableCell>
              //   )
              // },
              customHeadLabelRender: () => (
                <div style={{ marginLeft: 20 }}>
                  <Trans>Form title</Trans>
                </div>
              ),
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div style={{ flex: 1, marginLeft: 20 }}>{value}</div>
              }
            }
          },
          {
            name: 'version',
            label: myI18n._(t`Version`),
            options: {
              customHeadRender: (props, sort, sortData) => (
                <TableCell style={{ width: 100 }}>
                  <Button
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                      sort(1)
                    }}
                  >
                    <Trans>Version</Trans>
                    {sortData?.name === 'version' && (
                      <Icon
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: 500,
                          color: '#757575',
                          margin: 4
                        }}
                      >
                        {sortData.direction === 'desc'
                          ? 'arrow_downward'
                          : 'arrow_upward'}
                      </Icon>
                    )}
                  </Button>
                </TableCell>
              ),
              customBodyRender: value => (
                <div style={{ textAlign: 'center', paddingRight: 20 }}>
                  {value}
                </div>
              )
            }
          },
          {
            name: 'id',
            label: myI18n._(t`Id`),
            options: {
              customHeadRender: props => (
                <TableCell style={{ width: 160, textAlign: 'center' }}>
                  <Trans>Id</Trans>
                </TableCell>
              )
              // customHeadLabelRender: props => <Trans>Id</Trans>
            }
          },
          {
            name: 'lastModifiedDate',
            label: myI18n._(t`Last modified date`),
            sortCompare: muiDateCompare(),
            options: {
              customHeadRender: (props, sort, sortData) => (
                <TableCell style={{ width: 180 }}>
                  <Button
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                      sort(3)
                    }}
                  >
                    <Trans>Last modified date</Trans>
                    {sortData?.name === 'lastModifiedDate' && (
                      <Icon
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: 500,
                          color: '#757575',
                          margin: 4
                        }}
                      >
                        {sortData.direction === 'desc'
                          ? 'arrow_downward'
                          : 'arrow_upward'}
                      </Icon>
                    )}
                  </Button>
                </TableCell>
              ),
              customBodyRender: value => (
                <div style={{ textAlign: 'center', paddingRight: 20 }}>
                  {moment.utc(value).format(dateFormat)}
                </div>
              )
            }
          },
          {
            name: 'lastModifiedBy',
            label: myI18n._(t`Last modified by`),
            options: {
              customHeadRender: (props, sort, sortData) => (
                <TableCell style={{ width: 180 }}>
                  <Button
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                      sort(4)
                    }}
                  >
                    <Trans>Last modified by</Trans>
                    {sortData?.name === 'lastModifiedBy' && (
                      <Icon
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: 500,
                          color: '#757575',
                          margin: 4
                        }}
                      >
                        {sortData.direction === 'desc'
                          ? 'arrow_downward'
                          : 'arrow_upward'}
                      </Icon>
                    )}
                  </Button>
                </TableCell>
              ),
              customBodyRender: value => (
                <div style={{ textAlign: 'center', paddingRight: 20 }}>
                  {value}
                </div>
              )
            }
          },
          {
            name: 'comments',
            label: myI18n._(t`Comments`),
            options: {
              customHeadRender: props => (
                <TableCell style={{ width: 250, textAlign: 'center' }}>
                  <Trans>Comments</Trans>
                </TableCell>
              )
            }
          },
          {
            name: 'actions',
            label: myI18n._(t`Actions`),
            options: {
              sort: false,
              customHeadRender: props => {
                return <TableCell style={{ width: 260 }} />
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                actionsBodyRender(value, tableMeta, updateValue, formsList)
            }
          }
        ]}
      />
    </div>
  )
}

const CreateFormFromSurveyButton = ({ surveyTemplates, reloadData }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [converting, setConverting] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const configuration = useSelector(state => state.configuration)
  const user = useSelector(state => state.user)

  useEffect(() => {
    setSelectedTemplate(null)
    setConverting(false)
  }, [dialogOpen])

  return (
    <>
      <Button
        disabled={!surveyTemplates}
        style={{ marginLeft: 10 }}
        variant='contained'
        color='primary'
        onClick={e => {
          setDialogOpen(true)
        }}
      >
        <Trans>Create form from survey template</Trans>
      </Button>
      <Dialog open={dialogOpen} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
          >
            <Trans>Create form from survey template</Trans>
            <IconButton
              disabled={converting}
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            select
            variant='outlined'
            value={selectedTemplate || ''}
            onChange={e => {
              setSelectedTemplate(e.target.value)
            }}
          >
            {surveyTemplates &&
              surveyTemplates.map((obj, index) => {
                return (
                  <MenuItem key={index} value={obj.id}>
                    {obj.name}
                  </MenuItem>
                )
              })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selectedTemplate || converting}
            style={{ marginLeft: 10 }}
            variant='contained'
            color='primary'
            onClick={e => {
              setConverting(true)
              const snackbar = enqueueSnackbar(null, {
                persist: true,
                content: key =>
                  ProgressSnackbar(
                    <Trans>Converting survey template to form</Trans>
                  )
              })
              getSubtemplates(selectedTemplate).then(
                result => {
                  sfDataToRenderData(result, selectedTemplate, {
                    configuration,
                    user
                  }).then(
                    templates => {
                      let mainTemplate
                      templates.forEach(template => {
                        if (template.templateId === selectedTemplate) {
                          mainTemplate = template
                        }
                      })
                      const convertTable = {
                        [questionTypes.INPUT_TEXT.id]: 'textInput',
                        [questionTypes.INPUT_TEXTAREA.id]: 'textInput',
                        [questionTypes.INPUT_NUMBER.id]: 'textInputNumeric',
                        [questionTypes.SLIDER.id]: 'numericSlider',
                        [questionTypes.HEADER.id]: 'header',
                        [questionTypes.UPLOAD_FILES.id]: 'uploadFiles',
                        [questionTypes.SELECT_ONE.id]: 'picklist',
                        [questionTypes.SELECT_ONE_WITH_OTHER.id]: 'picklist',
                        [questionTypes.SELECT_MULTIPLE.id]: 'picklist',
                        [questionTypes.INPUT_DATE.id]: 'datePicker',
                        [questionTypes.INPUT_DATE_RANGE.id]: 'datePicker'
                      }
                      const convertTypePropsKeys = {
                        min: 'min',
                        max: 'max',
                        allowRowsToExpand: 'expandable',
                        rows: 'rows',
                        maxCharacters: 'maxChar',
                        isRequired: 'required'
                      }
                      const data = {
                        sections: [],
                        autosave: mainTemplate.autosave,
                        version: 1
                      }
                      templates
                        .filter(template => {
                          return (
                            template.templateId !== mainTemplate.templateId ||
                            template.questions.length > 0
                          )
                        })
                        .forEach(template => {
                          const convertQuestion = question => {
                            const newQuestion = {
                              typeProps: {},
                              padding: {},
                              id: crypto.randomBytes(16).toString('hex'),
                              titleEN: question.textEn,
                              titleFR: question.textFr,
                              tooltipEN: question.tooltipEn,
                              tooltipFR: question.tooltipFr,
                              elementType: convertTable[question.type]
                            }
                            if (question.options) {
                              newQuestion.typeProps.options = question.options.map(
                                option => ({
                                  titleEN: option.textEn,
                                  titleFR: option.textFr,
                                  apiValue: option.apiValue,
                                  clearOnSelect: option.clearOnSelect,
                                  requireDetails: option.requireDetails
                                })
                              )
                            }
                            Object.keys(convertTypePropsKeys).forEach(key => {
                              if (question[key]) {
                                newQuestion.typeProps[
                                  convertTypePropsKeys[key]
                                ] = question[key]
                              }
                            })
                            return newQuestion
                          }

                          const section = {
                            elements: [],
                            titleEN: template.headerEN,
                            titleFR: template.headerFR
                          }
                          template.questions.forEach(question => {
                            if (convertTable[question.type]) {
                              if (question.isGroup) {
                                const elements = question.groupQuestions.map(
                                  groupObj => {
                                    const toRet = convertQuestion(question)
                                    toRet.titleEN = groupObj.en
                                    toRet.titleFR = groupObj.fr
                                    return toRet
                                  }
                                )
                                section.elements.push({
                                  titleEN: question.textEn,
                                  titleFR: question.textFr,
                                  columns: 1,
                                  padding: {},
                                  id: crypto.randomBytes(16).toString('hex'),
                                  elements
                                })
                              } else {
                                section.elements.push(convertQuestion(question))
                              }
                            }
                          })
                          data.sections.push(section)
                        })
                      createFormPage({
                        titleEN: mainTemplate.headerEN,
                        titleFR: mainTemplate.headerFR,
                        data
                      }).then(
                        result => {
                          reloadData().then(result => {
                            setConverting(false)
                            closeSnackbar(snackbar)
                            enqueueSnackbar(<Trans>Form created</Trans>, {
                              variant: 'success'
                            })
                          })
                        },
                        reject => {
                          enqueueSnackbar(
                            <Trans>
                              Could not convert survey template to form.
                              Possible data corruption
                            </Trans>,
                            {
                              variant: 'error'
                            }
                          )
                          closeSnackbar(snackbar)
                          setConverting(false)
                        }
                      )
                    },
                    reject => {
                      enqueueSnackbar(
                        <Trans>
                          Could not convert survey template to form. Possible
                          data corruption
                        </Trans>,
                        {
                          variant: 'error'
                        }
                      )
                      closeSnackbar(snackbar)
                      setConverting(false)
                    }
                  )
                },
                reject => {
                  enqueueSnackbar(
                    <Trans>
                      Could not convert survey template to form. Possible data
                      corruption
                    </Trans>,
                    {
                      variant: 'error'
                    }
                  )
                  closeSnackbar(snackbar)
                  setConverting(false)
                }
              )
            }}
          >
            <Trans>Convert</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const ConfigureFormPreviewButton = ({ config }) => {
  const [previewConfigOpen, setPreviewConfigOpen] = useState(false)
  const [newConfig, setNewConfig] = useState({})
  const [saving, setSaving] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const configurations = useSelector(state => state.configuration)
  const dispatch = useDispatch()

  useEffect(() => {
    if (config) {
      setNewConfig(config)
    }
  }, [previewConfigOpen])

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        onClick={e => {
          setPreviewConfigOpen(true)
        }}
      >
        <Trans>Configure objects used in preview</Trans>
      </Button>
      <Dialog open={previewConfigOpen} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
          >
            <Trans>Configure objects used in preview</Trans>
            <IconButton
              disabled={saving}
              onClick={e => {
                setPreviewConfigOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {Object.entries(formObjectsToConnect).map(([key, item]) => {
            const array = newConfig[key] || []
            return (
              <Grid
                container
                key={key}
                style={{ paddingLeft: 10 }}
                alignItems='flex-start'
              >
                <Grid
                  item
                  style={{
                    width: 200,
                    marginRight: 15,
                    fontWeight: 'bold',
                    marginTop: 15
                  }}
                >
                  {key}
                </Grid>
                <Grid container item xs direction='column'>
                  <Grid item>
                    <Trans>Ids (in order):</Trans>
                    <IconButton
                      onClick={e => {
                        const toSet = { ...newConfig }
                        const newArray = [...array]
                        newArray.push('')
                        toSet[key] = newArray
                        setNewConfig(toSet)
                      }}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  </Grid>
                  {array.map((id, index) => {
                    return (
                      <Grid item container key={index} alignItems='center'>
                        <Grid item>{index + 1 + '. '}</Grid>
                        <Grid item xs style={{ padding: 5 }}>
                          <TextField
                            key={index}
                            fullWidth
                            variant='outlined'
                            label={<Trans>Id</Trans>}
                            value={id || ''}
                            onChange={e => {
                              const toSet = { ...newConfig }
                              _.set(toSet, `${key}.${index}`, e.target.value)
                              // toSet[key] = e.target.value
                              setNewConfig(toSet)
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            size='small'
                            onClick={e => {
                              const toSet = { ...newConfig }
                              const newArray = [...array]
                              newArray.splice(index, 1)
                              toSet[key] = newArray
                              setNewConfig(toSet)
                            }}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={saving}
            variant='contained'
            color='primary'
            onClick={e => {
              setSaving(true)
              const snackbar = enqueueSnackbar(<Trans>Saving</Trans>, {
                variant: 'info',
                persist: true
              })
              savePreviewConfig(newConfig).then(
                result => {
                  const toSet = { ...configurations }
                  toSet[FORM_PREVIEW_CONFIG] = newConfig
                  dispatch(setConfigurationData(toSet))
                  closeSnackbar(snackbar)
                  setSaving(false)
                  enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
                    variant: 'success'
                  })
                },
                reject => {
                  closeSnackbar(snackbar)
                  setSaving(false)
                  enqueueSnackbar(<Trans>Error ocurred while saving!</Trans>, {
                    variant: 'error'
                  })
                }
              )
            }}
          >
            <Icon style={{ marginRight: 5 }}>save</Icon>
            <Trans>Save</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const ReparentFormButton = ({ formsList = [], form, reloadData }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [futureParent, setFutureParent] = useState(null)
  const [reparenting, setReparenting] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return (
    <>
      <Dialog open={openDialog} maxWidth='sm' fullWidth>
        <DialogTitle>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Trans>Move to another form as child</Trans>
            <IconButton
              onClick={e => {
                setOpenDialog(false)
              }}
              disabled={reparenting}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 15 }}>
            <TextField
              select
              variant='outlined'
              fullWidth
              value={futureParent || ''}
              disabled={reparenting}
              onChange={e => {
                setFutureParent(e.target.value)
              }}
            >
              {formsList
                .filter(obj => obj.id !== form.id)
                .map(form => (
                  <MenuItem value={form.id || form.origin} key={form.id}>
                    {form.name}
                  </MenuItem>
                ))}
            </TextField>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', padding: 5 }}
          >
            <Button
              variant='contained'
              color='primary'
              disabled={!futureParent || reparenting}
              onClick={e => {
                const formsMap = {}
                formsList.forEach(form => {
                  formsMap[form.id || form.origin] = form
                })
                const newParentForm = formsMap[futureParent]
                const toUpdate = {
                  ...form.config
                }
                if (newParentForm.version) {
                  toUpdate.version = Number(newParentForm.version) + 1
                }
                const savingKey = enqueueSnackbar(
                  <Trans>Moving form to a new parent</Trans>,
                  { variant: 'info', persist: true }
                )
                setReparenting(true)
                if (newParentForm.origin) {
                  checkNextAvaliableFormVersion({
                    origin: checkNextAvaliableFormVersion
                  }).then(avaliableVersion => {
                    toUpdate.version = avaliableVersion
                    updateFormPage({
                      Id: form.id,
                      Origin__c: newParentForm.origin,
                      Data__c: compressFormData(toUpdate)
                    }).then(
                      result => {
                        reloadData().then(result => {
                          setReparenting(false)
                          setOpenDialog(false)
                          closeSnackbar(savingKey)
                          enqueueSnackbar(
                            <Trans>Form moved successfuly</Trans>,
                            {
                              variant: 'success'
                            }
                          )
                        })
                      },
                      reject => {
                        setReparenting(false)
                        closeSnackbar(savingKey)
                        enqueueSnackbar(
                          <Trans>Error ocurred while moving form</Trans>,
                          { variant: 'error' }
                        )
                      }
                    )
                  })
                } else {
                  Promise.all([
                    updateFormPage({
                      Id: futureParent,
                      Origin__c: futureParent
                    }),
                    updateFormPage({
                      Id: form.id,
                      Origin__c: futureParent,
                      Data__c: compressFormData(toUpdate)
                    })
                  ]).then(
                    result => {
                      reloadData().then(result => {
                        setReparenting(false)
                        setOpenDialog(false)
                        closeSnackbar(savingKey)
                        enqueueSnackbar(<Trans>Form moved successfuly</Trans>, {
                          variant: 'success'
                        })
                      })
                    },
                    reject => {
                      Promise.all([
                        updateFormPage({
                          Id: futureParent,
                          Origin__c: null
                        }),
                        updateFormPage({
                          Id: form.id,
                          Origin__c: null,
                          Data__c: compressFormData({ ...form.config })
                        })
                      ])
                      setReparenting(false)
                      closeSnackbar(savingKey)
                      enqueueSnackbar(
                        <Trans>Error ocurred while moving form</Trans>,
                        { variant: 'error' }
                      )
                    }
                  )
                }
              }}
            >
              <Trans>Move to a new parent</Trans>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Tooltip title={<Trans>Move to another form as child</Trans>}>
        <IconButton
          style={{ marginRight: 5 }}
          onClick={() => {
            setOpenDialog(true)
          }}
        >
          <Icon>shortcut</Icon>
        </IconButton>
      </Tooltip>
    </>
  )
}

const DuplicateFormButton = ({ reloadData, form }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [duplicating, setDuplicating] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const duplicateForm = (asChild = false) => {
    setDuplicating(true)
    setOpenDialog(false)
    const toCopy = {
      ...form.config
    }
    const duplicatingSnackbar = enqueueSnackbar(
      <Trans>Duplicating form</Trans>,
      { variant: 'info', persist: true }
    )
    let promise

    if (asChild) {
      if (form.origin) {
        promise = checkNextAvaliableFormVersion({
          origin: form.origin,
          version: toCopy.version
        }).then(
          result => {
            toCopy.version = result
            return createFormPage({
              titleEN: form.en + ' copy',
              titleFR: form.fr + ' copy',
              origin: form.origin,
              data: toCopy
            })
          },
          reject => {
            return reject
          }
        )
      } else {
        if (toCopy.version) {
          toCopy.version = Number(toCopy.version) + 1
        } else {
          toCopy.version = 1
        }
        promise = Promise.all([
          createFormPage({
            titleEN: form.en + ' copy',
            titleFR: form.fr + ' copy',
            origin: form.id,
            data: toCopy
          }),
          updateFormPage({
            Id: form.id,
            Origin__c: form.id
          })
        ])
      }
    } else {
      toCopy.version = 1
      promise = createFormPage({
        titleEN: form.en + ' copy',
        titleFR: form.fr + ' copy',
        data: toCopy
      })
    }
    promise.then(result => {
      if (result === FORM_VERSION_ERROR) {
        enqueueSnackbar(<Trans>This version of form already exists!</Trans>, {
          variant: 'error'
        })
        setDuplicating(false)
        closeSnackbar(duplicatingSnackbar)
      } else {
        reloadData(asChild).then(
          result => {
            setDuplicating(false)
            enqueueSnackbar(<Trans>Form duplicated!</Trans>, {
              variant: 'success'
            })
            closeSnackbar(duplicatingSnackbar)
          },
          reject => {
            setDuplicating(false)
            enqueueSnackbar(
              <Trans>Error ocurred while duplicating form!</Trans>,
              {
                variant: 'error'
              }
            )
            closeSnackbar(duplicatingSnackbar)
          }
        )
      }
    })
  }

  return (
    <>
      <Dialog open={openDialog && !duplicating} maxWidth='sm' fullWidth>
        <DialogTitle>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Trans>Duplicate form</Trans>
            <IconButton
              onClick={e => {
                setOpenDialog(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 15 }}>
            <Trans>
              Duplicating form as form version will bound the duplicated form to
              its origin and it will appear as its child on forms list. Creating
              separete form will leave no connection between newly created form
              and original form
            </Trans>
          </div>

          <Grid container justifyContent='space-evenly'>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={e => {
                  duplicateForm(false)
                }}
              >
                <Trans>Duplicate as separate form</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={e => {
                  duplicateForm(true)
                }}
              >
                <Trans>Duplicate as form version</Trans>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Tooltip title={<Trans>Duplicate form</Trans>}>
        <IconButton
          style={{ marginRight: 5 }}
          disabled={duplicating}
          onClick={() => {
            setOpenDialog(true)
          }}
        >
          <Icon>file_copy</Icon>
        </IconButton>
      </Tooltip>
    </>
  )
}

const DeleteFormPageButton = ({ reloadData, id, origin }) => {
  const [deleting, setDeleting] = React.useState(false)
  const [dialogOpen, openDialog] = React.useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  return (
    <>
      <Dialog open={dialogOpen}>
        <DialogTitle>
          <Typography variant='h6'>
            <Trans>Are you sure you want to delete this form template?</Trans>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction='row'
            justify='space-evenly'
            alignItems='center'
          >
            <Button
              disabled={deleting}
              variant='contained'
              color='primary'
              onClick={() => {
                enqueueSnackbar(<Trans>Deleting form page</Trans>, {
                  variant: 'info'
                })
                setDeleting(true)
                deleteFormPage(id, origin).then(
                  () => {
                    reloadData().then(() => {
                      enqueueSnackbar(<Trans>Form page deleted!</Trans>, {
                        variant: 'success'
                      })
                      setDeleting(false)
                      openDialog(false)
                    })
                  },
                  reject => {
                    enqueueSnackbar(
                      <Trans>Error occured while deleting form page!</Trans>,
                      {
                        variant: 'error'
                      }
                    )
                    setDeleting(false)
                    openDialog(false)
                  }
                )
              }}
            >
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='space-evenly'
              >
                <Trans>Yes</Trans>
                <Icon style={{ marginLeft: 5 }}>done</Icon>
              </Grid>
            </Button>
            <Button
              disabled={deleting}
              variant='contained'
              color='primary'
              onClick={e => {
                openDialog(false)
              }}
            >
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='space-evenly'
              >
                <Trans>No</Trans>
                <Icon style={{ marginLeft: 5 }}>close</Icon>
              </Grid>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Tooltip title={<Trans>Delete</Trans>}>
        <IconButton
          disabled={deleting}
          onClick={() => {
            openDialog(true)
          }}
        >
          <Icon>delete</Icon>
        </IconButton>
      </Tooltip>
    </>
  )
}

const AddFormPageButton = ({ reloadData }) => {
  const [creating, setCreating] = React.useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [dialogOpen, openDialog] = React.useState(false)
  const [nameEN, setNameEN] = React.useState('')
  const [nameFR, setNameFR] = React.useState('')

  useEffect(() => {
    setNameEN('')
    setNameFR('')
  }, [dialogOpen])

  return (
    <>
      <Dialog open={dialogOpen} fullWidth maxWidth='md'>
        <DialogTitle>
          <Trans>Create form page</Trans>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: 10 }}>
            <TextField
              value={nameEN}
              inputProps={{ maxLength: 80 }}
              helperText={countHelperText(nameEN, 80, true)}
              fullWidth
              autoFocus
              variant='outlined'
              label={<Trans>Form title - english</Trans>}
              onChange={e => {
                setNameEN(e.target.value)
              }}
            />
          </div>
          <div style={{ padding: 10 }}>
            <TextField
              value={nameFR}
              inputProps={{ maxLength: 80 }}
              helperText={countHelperText(nameFR, 80, true)}
              fullWidth
              autoFocus
              variant='outlined'
              label={<Trans>Form title - french</Trans>}
              onChange={e => {
                setNameFR(e.target.value)
              }}
            />
          </div>
        </DialogContent>
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='center'
          style={{ marginBottom: 10 }}
        >
          <Button
            disabled={creating || !nameEN || !nameFR}
            variant='contained'
            color='primary'
            onClick={() => {
              setCreating(true)
              createFormPage({
                titleEN: nameEN,
                titleFR: nameFR
              }).then(
                result => {
                  reloadData().then(
                    res => {
                      console.log(res)
                      enqueueSnackbar(<Trans>Form page created!</Trans>, {
                        variant: 'success'
                      })
                      setCreating(false)
                      openDialog(false)
                    },
                    reject => {
                      console.log(reject)
                    }
                  )
                },
                reject => {
                  enqueueSnackbar(
                    <Trans>Error occured while creating form page!</Trans>,
                    {
                      variant: 'error'
                    }
                  )
                  setCreating(false)
                  openDialog(false)
                }
              )
            }}
          >
            <Grid
              container
              direction='row'
              alignItems='center'
              justify='space-evenly'
            >
              <Trans>Create</Trans>
              <Icon style={{ marginLeft: 5 }}>done</Icon>
            </Grid>
          </Button>
          <Button
            disabled={creating}
            variant='contained'
            color='primary'
            onClick={e => {
              openDialog(false)
            }}
          >
            <Grid
              container
              direction='row'
              alignItems='center'
              justify='space-evenly'
            >
              <Trans>Cancel</Trans>
              <Icon style={{ marginLeft: 5 }}>close</Icon>
            </Grid>
          </Button>
        </Grid>
      </Dialog>
      <Button
        disabled={creating}
        color='primary'
        variant='contained'
        style={{ marginLeft: 10 }}
        onClick={() => {
          openDialog(true)
        }}
      >
        <Trans>Add new form page</Trans>
        <Icon style={{ marginLeft: 5 }}>add</Icon>
      </Button>
    </>
  )
}

const mapStateToProps = state => ({
  settings: state.layout.settings
})

export default connect(mapStateToProps)(Forms)
