import { Grid } from '@material-ui/core'
import CustomDatePicker from 'app/views/common/CustomDatePicker'
import { useField, useFormikContext } from 'formik'
import React from 'react'

import { useSelector } from 'react-redux'
import {
  endEditingField,
  startEditingField,
  unlockFieldWithoutChanges
} from '../../multiuser/grpcMultiuserEdit'
import { MuEditedByLabel } from '../Common'

const MUDatePicker = ({
  id,
  formId,
  useMultiuser,
  preventEndEditingEvent = false,
  onChange,
  ...props
}) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext()
  const user = useSelector(state => state.user)
  const [field, meta] = useField(id)
  const { muInfo, muUsers = {} } = values
  const muState = muInfo[id]
  let userColor, userName, isEdited
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      isEdited = true
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }
  let marginBottom = 0
  const style = { ...props.style }
  if (style && style.marginBottom) {
    marginBottom = style.marginBottom || style.margin || 0
    if (isEdited) {
      delete style.marginBottom
    }
  }
  const error = props.error || meta.error
  const isError = error && meta.touched

  return (
    <Grid container direction='column'>
      <CustomDatePicker
        {...field}
        {...props}
        disabled={
          props.disabled ||
          Boolean(muState && muState.locked && muState.user !== user.userId)
        }
        error={isError}
        helperText={isError ? error : props.helperText}
        color={userColor}
        handleOpen={e => {
          startEditingField({
            userId: user.userId,
            formId,
            fieldId: id
          })
        }}
        onChange={e => {
          if (onChange) {
            onChange(e)
          } else {
            setFieldValue(id, e)
          }
          if (useMultiuser) {
            endEditingField({
              userId: user.userId,
              formId,
              fieldId: id,
              fieldValue: e,
              lockId: values.muInfo.lockId
            })
          }
        }}
        handleClose={e => {
          if (!meta.touched) {
            setFieldTouched(id)
          }
          if (useMultiuser) {
            unlockFieldWithoutChanges({
              userId: user.userId,
              formId,
              fieldId: id,
              lockId: values.muInfo.lockId
            })
          }
        }}
      />
      <div style={{ marginBottom: marginBottom }}>
        <MuEditedByLabel color={userColor} userName={userName} />
      </div>
    </Grid>
  )
}

export default MUDatePicker
