import { Trans } from '@lingui/macro'
import { Button, Grid, Icon, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GroupElementWithoutDnD } from './GroupElement'

const InjectablesElementsPanel = () => {
  const dispatch = useDispatch()
  const tree = useSelector(state => state.formEditorTree)
  const { injectableComponents } = tree

  return (
    <div>
      <Grid container alignItems='center'>
        <h3 style={{ margin: 0 }}>
          <Trans>Reusable components</Trans>
        </h3>
        <Button
          style={{ marginLeft: 10 }}
          variant='contained'
          color='primary'
          onClick={() => {
            dispatch({
              type: 'ADD_ELEMENT_INJECTABLE'
            })
          }}
        >
          <Grid container justify='center' alignItems='center'>
            <Trans>Add element</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
      </Grid>
      {injectableComponents.map((component, index) => {
        const {
          titleEN,
          titleFR,
          editMode,
          isDragLayer,
          parentIsDummy,
          movingInStack,
          selectedParent,
          elementType,
          fieldType,
          width,
          columns,
          french,
          selected,
          emptySpace,
          typeProps,
          ...props
        } = component
        return (
          <GroupElementWithoutDnD
            injectable
            key={index}
            depth={[index].join('.')}
            elementType={elementType}
            movingInStack={movingInStack}
            width={width}
            selectedParent={selectedParent}
            french={french}
            titleEN={titleEN}
            titleFR={titleFR}
            title={french ? titleFR : titleEN}
            tooltipEN={props.tooltipEN}
            tooltipFR={props.tooltipFR}
            id={props.id}
            networkId={props.networkId}
            formId={props.formId}
            selected={selected}
            typeProps={typeProps}
            injectableId={props.injectableId}
            altLabelPlacement={props.altLabelPlacement}
            labelAsMarkdown={props.labelAsMarkdown}
            showBasicElements={props.showBasicElements}
            showCustomElements={props.showCustomElements}
            sectionLength={injectableComponents.length}
            editMode={editMode}
            showPrintProps={props.showPrintProps}
            showPdfProps={props.showPdfProps}
            padding={props.padding}
          />
        )
      })}
    </div>
  )
}

export default InjectablesElementsPanel
