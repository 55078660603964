import layout1Settings from './Layout1/Layout1SettingsChtc'
import layout1SettingsIcce from './Layout1/Layout1SettingsIcce'
import layout2Settings from './Layout2/Layout2Settings'
import { themeColors } from './EgretTheme/themeColors'
import { createMuiTheme } from '@material-ui/core'
import { forEach, merge } from 'lodash'
import themeOptions from './EgretTheme/themeOptions'
import sfOauthConfig from '../services/sfAuth/sfAuthConfig'
import { muiTableOverrides } from '../views/surveys/SurveyTemplates'

function createEgretThemes () {
  let themes = {}

  forEach(themeColors, (value, key) => {
    themes[key] = createMuiTheme(
      merge({}, themeOptions, value, muiTableOverrides)
    )
  })
  return themes
}

const themes = createEgretThemes()

export const settingsToSidebarTheme = settings => {
  return settings.sidebarThemes[settings.activeTheme] || {}
}

export const settingsToTheme = settings => {
  console.log('settings to theme', settings)
  return settings.themes[settings.activeTheme] || {}
}

export const EgretLayoutSettings = {
  activeLayout: 'layout1', // layout1, layout2
  activeTheme: sfOauthConfig.isIcce ? 'icceWhite' : 'chtcWhite', // View all valid theme colors inside EgretTheme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  sidebarThemes: {
    chtcWhite: layout1Settings,
    icceWhite: layout1SettingsIcce
  },
  layout1Settings, // open Layout1/Layout1Settings.js
  layout2Settings, // open Layout1/Layout2Settings.js
  layout1SettingsIcce, // open Layout1/Layout2Settings.js

  secondarySidebar: {
    show: false,
    theme: 'slateDark1' // View all valid theme colors inside EgretTheme/themeColors.js
  },
  // Footer options
  footer: {
    show: true,
    fixed: false,
    theme: 'slateDark1' // View all valid theme colors inside EgretTheme/themeColors.js
  }
}
