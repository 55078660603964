import SFAuthService, { NO_USER } from '../SFAuthService'

export const updateTexts = updateArray => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Survey_Text__c').update(updateArray)
}

export const createTranslation = ({ en, fr }) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('SurveyTranslation__c')
    .create({})
    .then(translation => {
      const engPromise = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Language__c: 'en-ca',
        Text__c: en
      })
      const frPromise = conn.sobject('Survey_Text__c').create({
        Survey_Translation__c: translation.id,
        Language__c: 'fr-ca',
        Text__c: fr
      })
      return Promise.all([engPromise, frPromise]).then(result => {
        return translation.id
      })
    })
}

export const getPortalTranslations = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Portal_Translation__c')
    .find({})
    .select(
      'Id, Translation_Id__c, Translated_EN__c, Translated_FR__c, Translator_Comment__c, LastModifiedDate'
    )
    .run({ autoFetch: true, maxFetch: 10000 })
}

export const createPortalTranslation = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Portal_Translation__c').create({
    Translation_Id__c: values.id,
    Translated_EN__c: values.en,
    Translated_FR__c: values.fr,
    Translator_Comment__c: values.comment
  })
}

export const createPortalTranslations = list => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const toCreate = list.map(obj => {
    return {
      Translation_Id__c: obj.id,
      Translated_EN__c: obj.en,
      Translated_FR__c: obj.fr,
      Translator_Comment__c: obj.comment
    }
  })
  if (toCreate.length < 200) {
    return conn.sobject('Portal_Translation__c').create(toCreate)
  } else {
    const subArrays = new Array(Math.ceil(toCreate.length / 200))
      .fill()
      .map(_ => toCreate.splice(0, 200))
    return Promise.all(
      subArrays.map(sub => conn.sobject('Portal_Translation__c').create(sub))
    )
  }
}

export const updatePortalTranslation = values => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Portal_Translation__c').update({
    Id: values.SFId,
    Translation_Id__c: values.id,
    Translated_EN__c: values.en,
    Translated_FR__c: values.fr,
    Translator_Comment__c: values.comment
  })
}

export const deletePortalTranslation = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('Portal_Translation__c').destroy(id)
}

export const getTranslations = ids => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('SurveyTranslation__c')
    .find({
      Id: { $in: ids }
    })
    .select('Id')
    .include('Survey_Texts__r')
    .select(
      'Id, Extended__c, Language__c, Survey_Translation__c, Text__c, Tooltip__c'
    )
    .end()
    .run({ autoFetch: true, maxFetch: 1000 })
  // .then(res => res[0])
}

export const getTranslation = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('SurveyTranslation__c')
    .find({
      Id: id
    })
    .select('Id')
    .include('Survey_Texts__r')
    .select(
      'Id, Extended__c, Language__c, Survey_Translation__c, Text__c, Tooltip__c'
    )
    .end()
    .then(res => res[0])
}
