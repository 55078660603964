import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  Icon,
  IconButton,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  FormGroup,
  FormLabel,
  MenuItem
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import { FieldArray, useField, useFormikContext } from 'formik'
import Grid from '@material-ui/core/Grid'
import {
  CheckboxGroupField,
  FormikSelectField,
  FormikTextField
} from 'formik-material-fields'
import { t, Trans } from '@lingui/macro'
import Typography from '@material-ui/core/Typography'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import {
  CBTI_OBJECTIVE_RECORD_TYPE,
  CBTI_RECORD_TYPE,
  CHGF_CB_OBJECTIVE_RECORD_TYPE,
  CHGF_CB_RECORD_TYPE,
  CHGF_PP_OBJECTIVE_RECORD_TYPE,
  CHGF_PP_RECORD_TYPE,
  CHGF_RI_OBJECTIVE_RECORD_TYPE,
  CHGF_RI_RECORD_TYPE,
  STF_LP_OBJECTIVE_RECORD_TYPE,
  STF_LP_RECORD_TYPE,
  STF_SIP_OBJECTIVE_RECORD_TYPE,
  STF_SIP_RECORD_TYPE
} from 'app/services/sfAuth/sfData/sfOpportunity'
import { ENTITY_IS_LOCKED } from 'app/services/sfAuth/sfData/jsConnectionUtils'
import { deleteObjective } from 'app/services/sfAuth/sfData/sfObjectives'
import { TooltipLabelIcon } from 'app/views/page-layouts/TooltipLabelIcon'
import {
  commonPdfStyles,
  FormColorAutocomplete,
  FormConnectToObject,
  NumberFormatDefault
} from '../Common'
import { useDispatch, useSelector } from 'react-redux'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { myI18n } from 'translation/I18nConnectedProvider'
import { endEditingField } from '../../multiuser/grpcMultiuserEdit'
import MUTextField from '../multiuser-input/MUTextField'
import { FormHelptext, FormHelptextWithParse } from '../../GroupElement'

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  dataPicker: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    width: '100%',
    marginBottom: 16
  },
  tcSpan4: {
    paddingLeft: 50,
    paddingTop: 25,
    paddingBottom: 25,
    paddingRight: 35
  },
  tr: {
    borderBottom: 'solid 1px #c1c1c1',
    borderTop: 'solid 0px #ffffff'
  },
  tHeaderText: {
    textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  divCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraph: {
    marginBottom: '1rem'
  }
})

export const formObjectivesValueToText = v => ({
  en: (
    <div>
      <Trans>Data is too complex to show objectives!</Trans>
      <div style={{ color: '#e0a912' }}>
        <Trans>
          Overriding will ony save changes to already added objectives. It will
          not delete any newly added milestones. Manual cleanup may be required.
        </Trans>
      </div>
    </div>
  ),
  fr: <Trans>Data is too complex to show differences!</Trans>
})

export const objectiveChoices = [
  {
    name: t`Increase/promote access to information`,
    value: 'Increase/promote access to information',
    fund: [CBTI_RECORD_TYPE]
  },
  {
    name: t`Build capacity around housing decisions and responsibilities`,
    value: 'Build capacity around housing decisions and responsibilities',
    fund: [CBTI_RECORD_TYPE]
  },
  {
    name: t`Increase participation in housing-related decisions and projects`,
    value: 'Increase participation in housing-related decisions and projects',
    fund: [CBTI_RECORD_TYPE]
  },
  {
    name: t`Leverage existing funding`,
    value: 'Leverage existing funding',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Lead the sector to reduce its environmental footprint`,
    value: 'Lead the sector to reduce its environmental footprint',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Develop tools and make them more accessible`,
    value: 'Develop tools and make them more accessible',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Support sectoral consolidation and other partnerships`,
    value: 'Support sectoral consolidation and other partnerships',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Reinforce organizational capacity`,
    value: 'Reinforce organizational capacity',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Enhance sector resilience`,
    value: 'Enhance sector resilience',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Fill sectoral gaps in housing services`,
    value: 'Fill sectoral gaps in housing services',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Encourage housing initiative for Indigenous communities`,
    value: 'Encourage housing initiative for Indigenous communities',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  },
  {
    name: t`Engage community`,
    value: 'Engage community',
    fund: [STF_LP_RECORD_TYPE, STF_SIP_RECORD_TYPE]
  }
]

const keyToLabel = {
  needs: <Trans>Needs</Trans>,
  objective: <Trans>Objective</Trans>,
  outcomes: <Trans>Outcomes</Trans>
}

export const FormObjectivesError = error => {
  if (Array.isArray(error)) {
    return {
      toMap: error
        .filter(err => err)
        .map((errorItem, index) => {
          return (
            <span key={index}>
              <u>
                <Trans>Objective</Trans> {index + 1}
              </u>
              <ul key={index}>
                {Object.entries(errorItem).map(([key, value]) => {
                  return (
                    <li
                      key={key}
                      className='m-0 text-muted'
                      style={{ maxWidth: '771px' }}
                    >
                      <b>
                        {keyToLabel[key]}
                        {': '}
                      </b>
                      {value}
                    </li>
                  )
                })}
              </ul>
            </span>
          )
        })
    }
  } else {
    return error
  }
}

export const FormObjectivesExtractKey = ({
  saveMap,
  sfObject,
  value,
  connectedObjectId,
  additionalSFInfo
}) => {
  const { recordTypes } = additionalSFInfo
  let objectiveRecordType
  const fundType = sfObject.RecordType.Name
  switch (fundType) {
    case CBTI_RECORD_TYPE:
      objectiveRecordType = CBTI_OBJECTIVE_RECORD_TYPE
      break
    case STF_LP_RECORD_TYPE:
      objectiveRecordType = STF_LP_OBJECTIVE_RECORD_TYPE
      break
    case STF_SIP_RECORD_TYPE:
      objectiveRecordType = STF_SIP_OBJECTIVE_RECORD_TYPE
      break
    case CHGF_CB_RECORD_TYPE:
      objectiveRecordType = CHGF_CB_OBJECTIVE_RECORD_TYPE
      break
    case CHGF_PP_RECORD_TYPE:
      objectiveRecordType = CHGF_PP_OBJECTIVE_RECORD_TYPE
      break
    case CHGF_RI_RECORD_TYPE:
      objectiveRecordType = CHGF_RI_OBJECTIVE_RECORD_TYPE
      break
  }
  if (!objectiveRecordType) {
    console.error('No objective record type was linked for: ', fundType)
  }
  const inner = value.map(item => ({
    Outcomes__c: item.outcomes.join(';'),
    Objective__c: item.objective,
    Identified_needs__c: item.needs,
    Id: item.id,
    RecordTypeId: recordTypes[objectiveRecordType].recordTypeId
  }))
  saveMap[connectedObjectId].Objectives__r = inner
}

export const FormObjectivesDefaultValue = obj => {
  if (!obj || !obj.Objectives__r) {
    return []
  }
  return obj.Objectives__r.records.map(objective => ({
    outcomes: objective.Outcomes__c ? objective.Outcomes__c.split(';') : [],
    objective: objective.Objective__c,
    needs: objective.Identified_needs__c,
    id: objective.Id
  }))
}

const requiredTrans = <Trans>This field is required</Trans>
const sizeTrans = <Trans>Must Have at least one Outcome</Trans>
const objectiveSizeTrans = <Trans>Must Have at least one Objective</Trans>
export const FormObjectivesValidation = ({ typeProps, ...props }) => {
  const { outcomesRequired, needsRequired } = typeProps

  const objectiveShape = {
    objective: Yup.string()
      .ensure()
      .required(requiredTrans)
      .label(<Trans>Objective</Trans>),
    needs: Yup.string().ensure(),
    outcomes: Yup.array()
      .ensure()
      .min(1, sizeTrans)
      .label(<Trans>Outcomes</Trans>)
  }

  if (outcomesRequired) {
    objectiveShape.outcomes = objectiveShape.outcomes.of(
      Yup.string().required(requiredTrans)
    )
  }
  if (needsRequired) {
    objectiveShape.needs = objectiveShape.needs
      .required(requiredTrans)
      .label(<Trans>Needs</Trans>)
  }

  return Yup.array()
    .ensure()
    .min(1, objectiveSizeTrans)
    .label(<Trans>Objectives</Trans>)
    .of(Yup.object().shape(objectiveShape))
}

const useStyles = makeStyles(styles)
const pdfStyles = StyleSheet.create({
  headerCell: {
    textAlign: 'center',
    // fontWeight: 'bold',
    // backgroundColor: '#deeaf6',
    padding: 10,
    fontSize: 10,
    flex: 1,
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  },
  cell: {
    padding: 10,
    fontSize: 10,
    flex: 1,
    fontFamily: 'Roboto',
    border: '1px solid black',
    borderCollapse: 'collapse'
  }
})

export const FormObjectivesPdf = ({ title, typeProps, value }) => {
  const { pdfHeaderTextProps = [], pdfBackgroundColor, pdfFontSize } = typeProps
  const headerStyle = { ...pdfStyles.headerCell }
  if (pdfHeaderTextProps.includes('bold')) {
    headerStyle.fontWeigth = 'bold'
  }
  if (pdfHeaderTextProps.includes('italics')) {
    headerStyle.fontStyle = 'italic'
  }
  if (pdfHeaderTextProps.includes('underline')) {
    headerStyle.textDecoration = 'underline'
  }
  if (pdfBackgroundColor) {
    headerStyle.backgroundColor = pdfBackgroundColor.id
  }
  if (pdfFontSize) {
    headerStyle.fontSize = +pdfFontSize
  }
  return (
    <View>
      <View>
        <Text style={commonPdfStyles.title}>{title}</Text>
      </View>
      <View style={commonPdfStyles.row}>
        <View style={headerStyle}>
          <Text>{myI18n._(t`Objective`)}</Text>
        </View>
        {/* <View style={headerStyle}>
          <Text>{myI18n._(t`Needs`)}</Text>
        </View> */}
        <View style={headerStyle}>
          <Text>{myI18n._(t`Outcomes`)}</Text>
        </View>
      </View>
      {value.map((data, index) => {
        const { objective, needs, outcomes = [] } = data
        return (
          <View style={commonPdfStyles.row} key={index}>
            <View style={pdfStyles.cell}>
              <Text>{objective}</Text>
            </View>
            {/* <View style={pdfStyles.cell}>
              <Text>{needs}</Text>
            </View> */}
            <View style={pdfStyles.cell}>
              {outcomes.map((item, index) => {
                return (
                  <View key={index}>
                    <Text>
                      - <Trans id={item} />
                    </Text>
                  </View>
                )
              })}
            </View>
          </View>
        )
      })}
    </View>
  )
}

export const FormObjectivesPrint = ({ title, value }) => {
  return (
    <div style={{ width: '100%' }}>
      <div className='form-print-title'>{title}</div>
      {value.map((data, index) => {
        const { objective, needs, outcomes = [] } = data
        return (
          <Paper
            elevation={6}
            key={index}
            style={{ width: '100%', padding: 10, marginTop: 15 }}
          >
            <div style={{ padding: 8 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Objective</Trans>
              </Typography>
              <Typography>{objective}</Typography>
            </div>
            <Divider />
            <div style={{ padding: 8 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Needs</Trans>
              </Typography>
              <Typography>{needs}</Typography>
            </div>
            <Divider />
            <div style={{ padding: 8 }}>
              <Typography className='form-print-subtitle'>
                <Trans>Outcomes</Trans>
              </Typography>
              <List>
                {Boolean(outcomes.length === 0) && (
                  <ListItem style={{ display: 'flex', fontWeight: 'bold' }}>
                    <Trans>This list is empty!</Trans>
                  </ListItem>
                )}
                {outcomes.map((item, index) => {
                  return (
                    <ListItem key={index} style={{ display: 'flex' }}>
                      <ListItemIcon>
                        <Icon>fiber_manual_record</Icon>
                      </ListItemIcon>

                      <Typography>
                        <Trans id={item} />
                      </Typography>
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </Paper>
        )
      })}
    </div>
  )
}

export const FormObjectives = ({
  id,
  useMultiuser,
  formId,
  title,
  helpText,
  connectedObject,
  editMode,
  disabled,
  typeProps,
  describeMap,
  langFR
}) => {
  const {
    outcomesMaxChar,
    outcomesMaxWords,
    outcomesRows,
    outcomesExpandable,
    outcomesHelpTextFR,
    outcomesHelpTextEN,
    needsMaxChar,
    needsMaxWords,
    needsRows,
    needsHelpTextFR,
    needsHelpTextEN,
    needsExpandable,
    options
  } = typeProps
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const { values, setFieldValue } = useFormikContext()
  const { enqueueSnackbar } = useSnackbar()
  const columns = [t`Objectives`]
  const fundType = connectedObject
    ? connectedObject.RecordType.Id
    : STF_LP_RECORD_TYPE
  const [indexToDelete, setIndexToDelete] = React.useState(null)

  const getOptions = () => {
    const valueToLabel = {}
    const sfData = describeMap?.Objective__c
    sfData &&
      sfData.fields.some(field => {
        if (field.name === 'Objective__c') {
          field.picklistValues.forEach(option => {
            valueToLabel[option.value] = option.label
          })
        }
        return field.name === 'Objective__c'
      })
    const toReturn = []
    if (options) {
      options.forEach(option => {
        if (option.recordTypes.includes(fundType)) {
          toReturn.push({
            label: valueToLabel[option.value] || myI18n._(option.value),
            value: option.value
          })
        }
      })
    }
    // else {
    //   for (const obj of objectiveChoices) {
    //     if (obj.fund.includes(fundType)) {
    //       toReturn.push({
    //         label: <Trans id={obj.name} />,
    //         value: obj.value
    //       })
    //     }
    //   }
    // }
    return toReturn
  }

  const objectiveOptions = getOptions()
  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  const needsHelpText = langFR ? needsHelpTextFR : needsHelpTextEN
  const outcomesHelpText = langFR ? outcomesHelpTextFR : outcomesHelpTextEN

  return (
    <Paper className={classes.root}>
      <h3>{title}</h3>
      <FormHelptext helpText={helpText} />
      {/* <Typography className={classes.paragraph}>
        <Trans>
          Select the fund’s objectives that relate to your project. We recommend
          you pick only from 1 to 4 objectives.
        </Trans>
      </Typography>
      <Typography className={classes.paragraph}>
        <Trans>
          For each of them, please provide us with a description of the
          identified need linking your project to this objective.
        </Trans>
      </Typography>
      <Typography className={classes.paragraph}>
        <Trans>
          Then, provide the outcomes that you aim at achieving. You can put more
          than one outcome. Each outcome that you provide should be reachable
          within the timespan of the project. Be specific, have a measurable
          outcome, make it achievable and realistic. Those outcomes will be
          included in the funding agreement if your project is awarded and you
          will need to report on those.
        </Trans>
      </Typography> */}
      <FieldArray
        name={id}
        render={({ push, form, name, remove }) => {
          const objectives = form.values[name] || []
          const alreadySelected = {}
          objectives.forEach((obj, oIndex) => {
            if (obj.objective) {
              if (alreadySelected[obj.objective]) {
                alreadySelected[obj.objective].push(oIndex)
              } else {
                alreadySelected[obj.objective] = [oIndex]
              }
            }
          })

          return (
            <div>
              <div style={{ paddingLeft: 15, paddingTop: 10 }}>
                <Button
                  variant='contained'
                  color='primary'
                  type='button'
                  disabled={disabled}
                  onClick={() => {
                    push({
                      objective: '',
                      needs: '',
                      outcomes: []
                    })
                    if (useMultiuser) {
                      endEditingField({
                        userId: user.userId,
                        formId,
                        fieldId: id,
                        fieldValue: [
                          ...objectives,
                          {
                            objective: '',
                            needs: '',
                            outcomes: []
                          }
                        ]
                      })
                    }
                  }}
                >
                  <Icon color='inherit'>add</Icon>
                  <span>
                    <Trans>New Objective</Trans>
                  </span>
                </Button>
              </div>
              <div className='w-100 overflow-auto' style={{ padding: 20 }}>
                <Table style={{ whiteSpace: 'pre' }}>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                      {columns.map((item, i) => (
                        <TableCell key={i} style={{ paddingLeft: 35 }}>
                          <p
                            style={{
                              textShadow: '2px 2px 0px rgba(63,107,169, 0.15)',
                              fontWeight: 'bold'
                            }}
                          >
                            <Trans id={item} />
                          </p>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objectives.map((item, index) => [
                      <TableRow key={index}>
                        <TableCell style={{ paddingLeft: 20 }}>
                          <Grid
                            wrap='nowrap'
                            container
                            style={{
                              paddingLeft: 15,
                              paddingTop: 15,
                              paddingBottom: 15
                            }}
                          >
                            <MUTextField
                              select
                              disabled={disabled}
                              label={myI18n._(t`Objective`)}
                              formId={formId}
                              useMultiuser={useMultiuser}
                              id={id + `[${index}].objective`}
                            >
                              {objectiveOptions
                                .filter(option => {
                                  return (
                                    !alreadySelected[option.value] ||
                                    alreadySelected[option.value].includes(
                                      index
                                    )
                                  )
                                })
                                .map(item => {
                                  return (
                                    <MenuItem
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  )
                                })}
                            </MUTextField>
                            <Grid
                              item
                              style={{ marginLeft: 15, marginRight: 15 }}
                            >
                              <IconButton
                                disabled={disabled}
                                variant='filled'
                                onClick={() => {
                                  setIndexToDelete(index)
                                }}
                              >
                                <Icon
                                  color={disabled ? 'inherit' : 'error'}
                                  className='material-icons MuiIcon-root'
                                  variant='filled'
                                >
                                  delete
                                </Icon>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>,
                      <TableRow key={index + objectives.length}>
                        <TableCell
                          style={{
                            paddingLeft: 75,
                            paddingTop: 25,
                            paddingBottom: 25,
                            paddingRight: 35
                          }}
                        >
                          <div style={{ marginBottom: 5, fontWeight: 'bold' }}>
                            <Trans>Needs</Trans>
                          </div>

                          <FormHelptextWithParse helpText={needsHelpText} />
                          <MUTextField
                            useMultiuser={useMultiuser}
                            formId={formId}
                            id={id + `[${index}].needs`}
                            label=''
                            multiline
                            minRows={needsRows || 5}
                            maxRows={needsExpandable ? null : needsRows || 5}
                            disabled={disabled}
                            margin='normal'
                            limit={needsMaxChar && Number(needsMaxChar)}
                            maxWords={needsMaxWords && Number(needsMaxWords)}
                          />
                        </TableCell>
                      </TableRow>,
                      <TableRow key={index + objectives.length * 2}>
                        <TableCell style={{ paddingLeft: 75 }}>
                          <FieldArray
                            name={id + `[${index}].outcomes`}
                            render={({ push, remove }) => (
                              <div>
                                <div key={-1}>
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{ marginBottom: 5 }}
                                  >
                                    <Grid
                                      item
                                      style={{
                                        paddingTop: 25,
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      {myI18n._(t`Outcomes`)}
                                    </Grid>
                                    <Grid
                                      item
                                      style={{
                                        paddingTop: 18,
                                        paddingLeft: 10
                                      }}
                                    >
                                      <Button
                                        variant='contained'
                                        color='primary'
                                        type='button'
                                        size='small'
                                        disabled={disabled}
                                        onClick={() => {
                                          push('')
                                          if (useMultiuser) {
                                            endEditingField({
                                              userId: user.userId,
                                              formId,
                                              fieldId:
                                                id + `[${index}].outcomes`,
                                              fieldValue: [...item.outcomes, '']
                                            })
                                          }
                                        }}
                                      >
                                        <Icon color='inherit'>add</Icon>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <FormHelptextWithParse
                                    helpText={outcomesHelpText}
                                  />
                                </div>
                                {item.outcomes.map((outcome, outcomeIndex) => [
                                  <Grid
                                    container
                                    spacing={2}
                                    key={outcomeIndex}
                                  >
                                    <Grid item xs={7} lg={11}>
                                      <MUTextField
                                        useMultiuser={useMultiuser}
                                        formId={formId}
                                        id={
                                          id +
                                          `[${index}].outcomes[${outcomeIndex}]`
                                        }
                                        label=''
                                        multiline
                                        minRows={outcomesRows || 2}
                                        maxRows={
                                          outcomesExpandable
                                            ? null
                                            : outcomesRows || 2
                                        }
                                        disabled={disabled}
                                        margin='normal'
                                        limit={
                                          outcomesMaxChar &&
                                          Number(outcomesMaxChar)
                                        }
                                        maxWords={
                                          outcomesMaxWords &&
                                          Number(outcomesMaxWords)
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      lg={1}
                                      style={{ paddingTop: 40 }}
                                    >
                                      <IconButton
                                        variant='contained'
                                        color='secondary'
                                        type='button'
                                        size='small'
                                        disabled={disabled}
                                        onClick={() => {
                                          remove(outcomeIndex)
                                          if (useMultiuser) {
                                            const newValue = [...item.outcomes]
                                            newValue.splice(outcomeIndex, 1)
                                            endEditingField({
                                              userId: user.userId,
                                              formId,
                                              fieldId:
                                                id + `[${index}].outcomes`,
                                              fieldValue: newValue
                                            })
                                          }
                                        }}
                                      >
                                        <Icon
                                          color={disabled ? 'inherit' : 'error'}
                                        >
                                          delete
                                        </Icon>
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ])}
                              </div>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ])}
                  </TableBody>
                </Table>
              </div>
              <Dialog
                open={Boolean(indexToDelete) || indexToDelete === 0}
                onClose={() => {
                  setIndexToDelete(null)
                }}
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
              >
                <DialogTitle id='alert-dialog-slide-title'>
                  <Trans>Are you sure you want to delete this objective?</Trans>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-slide-description'>
                    <Trans>This process cannot be undone.</Trans>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setIndexToDelete(null)
                    }}
                    color='default'
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    onClick={() => {
                      const toDelete = objectives[indexToDelete]
                      const modalId = toDelete.id
                      if (modalId) {
                        enqueueSnackbar(<Trans>Deleting</Trans>, {
                          variant: 'info'
                        })
                        deleteObjective(modalId)
                          .then(() => {
                            enqueueSnackbar(<Trans>Deleted</Trans>, {
                              variant: 'success'
                            })
                            remove(indexToDelete)
                            if (useMultiuser) {
                              const newValue = [...objectives]
                              newValue.splice(indexToDelete, 1)
                              endEditingField({
                                userId: user.userId,
                                formId,
                                fieldId: id,
                                fieldValue: newValue
                              })
                            }
                          })
                          .catch(error => {
                            if (error && error.errorCode === ENTITY_IS_LOCKED) {
                              enqueueSnackbar(
                                <Trans>
                                  Application is Locked: Contact the
                                  Administrator
                                </Trans>,
                                { variant: 'error' }
                              )
                            } else {
                              enqueueSnackbar(<Trans>Can't Delete</Trans>, {
                                variant: 'error'
                              })
                            }
                          })
                      } else {
                        remove(indexToDelete)
                      }
                      setIndexToDelete(null)
                    }}
                    variant='contained'
                    color='error'
                  >
                    <Trans>Yes, delete it forever</Trans>
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )
        }}
      />
    </Paper>
  )
}

export const FormEditorObjectives = ({
  editMode,
  showPrintProps,
  showPdfProps,
  typeProps,
  depth,
  ...props
}) => {
  const {
    pdfHeaderTextProps = [],
    pdfBackgroundColor,
    pdfFontSize,
    options = []
  } = typeProps
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  if (!editMode) {
    return <FormObjectives editMode typeProps={typeProps} {...props} />
  }

  let avaliableRecordTypes = []
  let avaliableObjectives = []
  values.objects.forEach(object => {
    if (object.name === 'Opportunity' && object.recordTypes) {
      avaliableRecordTypes = Object.values(object.recordTypes)
        .filter(recordType => recordType.active)
        .map(recordType => ({
          value: recordType.recordTypeId,
          label: recordType.name
        }))
    }
    if (object.name === 'Objective__c') {
      object.fields.some(field => {
        const bool = field.name === 'Objective__c'
        if (bool) {
          avaliableObjectives = field.picklistValues.filter(
            value => value.active
          )
        }
        return bool
      })
    }
  })

  return (
    <div>
      {[
        { label: <Trans>Needs field</Trans>, key: 'needs' },
        { label: <Trans>Outcomes field</Trans>, key: 'outcomes' }
      ].map((obj, index) => {
        const { label, key } = obj
        return (
          <div key={index}>
            <h6 style={{ marignTop: 5, marginBottom: 5 }}>{label}</h6>
            <Grid container wrap='nowrap' alignItems='center'>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(typeProps[key + 'Required'])}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet[key + 'Required'] = e.target.checked
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: toSet
                        })
                      }}
                    />
                  }
                  label={<Trans>Is required?</Trans>}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
                  label={<Trans>Characters limit</Trans>}
                  value={typeProps[key + 'MaxChar']}
                  InputProps={{ inputComponent: NumberFormatDefault }}
                  variant='outlined'
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet[key + 'MaxChar'] = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ marginTop: 5, marginBottom: 5, marginRight: 10 }}
                  label={<Trans>Words limit</Trans>}
                  value={typeProps[key + 'MaxWords']}
                  InputProps={{ inputComponent: NumberFormatDefault }}
                  variant='outlined'
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet[key + 'MaxWords'] = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ marginTop: 5, marginBottom: 5 }}
                  label={<Trans>Rows</Trans>}
                  value={typeProps[key + 'Rows'] || ''}
                  InputProps={{ inputComponent: NumberFormatDefault }}
                  variant='outlined'
                  onChange={e => {
                    const toSet = { ...typeProps }
                    toSet[key + 'Rows'] = e.target.value
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                />
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(typeProps[key + 'Expandable'])}
                      onChange={e => {
                        const toSet = { ...typeProps }
                        toSet[key + 'Expandable'] = e.target.checked
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={<Trans>Allow expanding rows?</Trans>}
                />
              </Grid>
            </Grid>
            <div>
              <TextField
                variant='outlined'
                style={{ marginBottom: 10, marginTop: 10 }}
                label={<Trans>Help text - English</Trans>}
                value={typeProps[key + 'HelpTextEN'] || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet[key + 'HelpTextEN'] = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                fullWidth
              />
              <TextField
                variant='outlined'
                style={{ marginBottom: 10 }}
                label={<Trans>Help text - French</Trans>}
                value={typeProps[key + 'HelpTextFR'] || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet[key + 'HelpTextFR'] = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
                fullWidth
              />
            </div>
          </div>
        )
      })}

      <div>
        <Grid container alignItems='center' style={{ marginTop: 10 }}>
          <Grid item>
            <h6 style={{ marignTop: 5, marginBottom: 5 }}>
              <Trans>Objective options</Trans>
            </h6>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              style={{ marginLeft: 10 }}
              onClick={e => {
                const toSet = { ...typeProps }
                const newOptions = [...options]
                newOptions.push({
                  value: '',
                  recordTypes: []
                })
                toSet.options = newOptions
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
            >
              <Trans>Add option</Trans>
              <Icon style={{ marginLeft: 5 }}>add</Icon>
            </Button>
          </Grid>
        </Grid>
        {options.map((item, index) => {
          const { recordTypes = [], value } = item
          return (
            <div key={index} style={{ padding: 5 }}>
              <Grid container alignItems='center'>
                <Grid item xs>
                  <b>{Number(index + 1) + '. '}</b>
                </Grid>
                <IconButton
                  onClick={e => {
                    const toSet = { ...typeProps }
                    toSet.options.splice(index, 1)
                    dispatch({
                      type: 'FIELD',
                      depth: depth.split('.'),
                      fieldName: 'typeProps',
                      fieldValue: { ...toSet }
                    })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
              <TextField
                label={<Trans>Option</Trans>}
                fullWidth
                variant='outlined'
                disabled={avaliableObjectives.length === 0}
                value={item.value}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.options[index].value = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                select
              >
                {avaliableObjectives.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ padding: 5 }}>
                <FormLabel>
                  <Trans>Avaliable in record types</Trans>:
                </FormLabel>
                <FormGroup row>
                  {avaliableRecordTypes.map(item => {
                    const checked = recordTypes.some(
                      recordType => recordType === item.value
                    )
                    return (
                      <FormControlLabel
                        onChange={e => {
                          const toSet = { ...typeProps }
                          const newArray = toSet.options[index].recordTypes
                          if (!checked) {
                            newArray.push(item.value)
                          } else {
                            let index = -1
                            newArray.some((recordType, sIndex) => {
                              const bool = recordType === item.value
                              if (bool) {
                                index = sIndex
                              }
                              return bool
                            })
                            newArray.splice(index, 1)
                          }
                          toSet.options[index].recordTypes = newArray
                          dispatch({
                            type: 'FIELD',
                            depth: depth.split('.'),
                            fieldName: 'typeProps',
                            fieldValue: toSet
                          })
                        }}
                        style={{ width: '24%' }}
                        key={item.value}
                        value={item.value}
                        // checked={recordTypes.includes(item.value)}
                        control={<Checkbox checked={checked} />}
                        label={item.label}
                      />
                    )
                  })}
                </FormGroup>
              </div>
            </div>
          )
        })}
      </div>
      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />
      {showPdfProps && (
        <div style={{ marginTop: 10 }}>
          <Typography variant='h6'>
            <Trans>Pdf props</Trans>
          </Typography>
          <div style={{ padding: 5, marginTop: 10 }}>
            <Typography style={{ marginBottom: 10, fontSize: 16 }}>
              <Trans>Header</Trans>
            </Typography>
            <TextField
              label={<Trans>Font size</Trans>}
              fullWidth
              variant='outlined'
              value={pdfFontSize || ''}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.pdfFontSize = e.target.value
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: toSet
                })
              }}
              inputProps={{
                maxLength: 2
              }}
              InputProps={{
                inputComponent: NumberFormatDefault
              }}
            />
            <FormGroup row>
              {[
                { key: 'bold', label: <Trans>Bold</Trans> },
                { key: 'italics', label: <Trans>Italics</Trans> },
                { key: 'underline', label: <Trans>Underline</Trans> }
              ].map(item => (
                <FormControlLabel
                  key={item.key}
                  control={
                    <Checkbox
                      checked={pdfHeaderTextProps.includes(item.key)}
                      value={item.key}
                      onChange={e => {
                        const v = e.target.value
                        const toSet = { ...typeProps }
                        const newTextProps = [...pdfHeaderTextProps]
                        if (newTextProps.includes(v)) {
                          newTextProps.splice(newTextProps.indexOf(v), 1)
                        } else {
                          newTextProps.push(v)
                        }
                        toSet.pdfHeaderTextProps = newTextProps
                        dispatch({
                          type: 'FIELD',
                          depth: depth.split('.'),
                          fieldName: 'typeProps',
                          fieldValue: { ...toSet }
                        })
                      }}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormColorAutocomplete
              depth={depth}
              label={<Trans>Cell background color</Trans>}
              name='pdfBackgroundColor'
              typeProps={typeProps}
              value={pdfBackgroundColor}
            />
          </div>
        </div>
      )}
    </div>
  )
}
