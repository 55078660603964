import { EgretLoadable } from 'egret'
import CreateSurvey from './CreateSurvey'
import { authRoles } from '../../auth/authRoles'

const SurveyTab = EgretLoadable({
  loader: () => import('./SurveyTab')
})

const SurveyScore = EgretLoadable({
  loader: () => import('./SurveyScore')
})

const SurveyTemplateList = EgretLoadable({
  loader: () => import('./SurveyTemplates')
})

const AvaliableSurveys = EgretLoadable({
  loader: () => import('./AvaliableSurveys')
})
const ESATSurveys = EgretLoadable({
  loader: () => import('./ESATSurveys')
})

const SurveyList = EgretLoadable({
  loader: () => import('./SurveyList')
})
const SurveyScoreCards = EgretLoadable({
  loader: () => import('./SurveyScoreCards')
})

const ScoreCardCreator = EgretLoadable({
  loader: () => import('./ScoreCardCreator')
})
const FormPage = EgretLoadable({ loader: () => import('../forms/FormWizard') })

const surveyRoutes = [
  {
    path: '/surveys/sampleSurvey',
    component: SurveyTab,
    auth: authRoles.admin
  },
  {
    path: '/surveys/ScoreCards',
    component: SurveyScoreCards
  },
  {
    path: '/surveys/ScoreCardCreator/:templateId/:scoreCardId',
    component: ScoreCardCreator
  },
  {
    path: '/surveys/SurveyTemplates',
    component: SurveyTemplateList,
    auth: authRoles.editor
  },
  {
    path: '/surveys/SelfAssessmentSurveys',
    component: AvaliableSurveys,
    auth: authRoles.grantee
  },
  {
    path: '/surveys/ESAT',
    component: ESATSurveys,
    auth: authRoles.grantee
  },
  {
    path: '/surveys/SurveyTab/:id/:surveyId/:mode',
    component: SurveyTab,
    auth: authRoles.grantee
  },
  {
    path: '/surveys/SurveyScore/:id/:templateId?/:scoreCardId?',
    component: SurveyScore,
    auth: authRoles.grantee
  },
  {
    path: '/surveys/CreateSurvey/:id/:templateType',
    component: CreateSurvey,
    auth: authRoles.editor
  },
  {
    path: '/surveys/CreateSurvey',
    component: CreateSurvey,
    auth: authRoles.editor,
    exact: true
  },
  {
    path: '/surveys/SurveyList',
    component: SurveyList,
    auth: authRoles.grantee,
    exact: true
  },
  {
    path: '/surveys/FormPage/:id?',
    component: FormPage
  }
]

export default surveyRoutes
