import { t, Trans } from '@lingui/macro'
import {
  Button,
  Grid,
  Icon,
  IconButton,
  TableCell,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import {
  addOpportunityContactAffiliation,
  deleteOpportunityContactAffiliations,
  updateOpportunityContactAffiliation
} from 'app/services/sfAuth/sfData/sfOpportunityAffiliation'
import FindContactButton, {
  FillMissingInformationForContact
} from 'app/views/grants/FindContactButton'
import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import { Field, Formik, useField, useFormikContext } from 'formik'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { myI18n } from 'translation/I18nConnectedProvider'
import { getMainConnected } from '../../Form'
import {
  FormConnectToObject,
  getMuState,
  MuEditedByLabel,
  NumberFormatDefault
} from '../Common'
import * as Yup from 'yup'
import { FormErrorLabel } from '../FormErrorLabel'
import * as crypto from 'crypto'
import {
  endEditingField,
  startEditingField
} from '../../multiuser/grpcMultiuserEdit'
import { Alert } from '@material-ui/lab'
import FormikTextField from 'formik-material-fields/lib/FormikTextField'
import {
  parseExtensionPhoneToSF,
  PhoneCountryCodeFormat,
  PhoneFormatWithExtension
} from 'app/views/common/Formats'
import {
  mapContact,
  saveContactParsed
} from 'app/services/sfAuth/sfData/sfContact'
import { emailTrans, requiredTrans } from '../../formTranslations'
import { createCase, createCaseByFlow } from 'app/services/sfAuth/sfData/sfCase'
import moment from 'moment/moment'
import { dateFormat } from 'app/appSettings'

Yup.addMethod(Yup.array, 'checkAssociatedContacts', function (item) {
  return this.test('checkAssociatedContacts', null, function (array) {
    const { path, createError } = this
    const { roles } = item.typeProps
    if (!array) {
      return true
    }
    const countMap = {}
    let missingInfoError = false
    array.forEach(obj => {
      if (!obj.deleted) {
        const { jobTitle, phoneNumber, workPhone, mobilePhone } = obj
        if (!countMap[obj.role]) {
          countMap[obj.role] = 1
        } else {
          countMap[obj.role] = countMap[obj.role] + 1
        }
        const missingInfo =
          !jobTitle || (!phoneNumber && !workPhone && !mobilePhone)
        if (missingInfo) {
          missingInfoError = true
        }
      }
    })
    const notEnoughRolesError =
      roles &&
      roles.some(role => {
        const min = +role.min
        if (!min) {
          return false
        }
        return countMap[role.apiValue] < min || !countMap[role.apiValue]
      })

    if (notEnoughRolesError) {
      return createError({
        path,
        message: (
          <Trans>
            All roles must have assigned the minimum number of contacts
          </Trans>
        )
      })
    } else if (missingInfoError) {
      return createError({
        path,
        message: (
          <Trans>
            Some of affiliated contacts have required information missing
          </Trans>
        )
      })
    }
    return true
  })
})

export const formAssociatedContactsListValidation = item =>
  Yup.array().checkAssociatedContacts(item)

export const formAssociatedContactsSavePromise = ({
  value,
  item,
  connectedObject,
  additionalSFInfo
}) => {
  const toDelete = []
  const toCreate = []
  const toUpdate = []
  value.forEach(obj => {
    if (!obj.id.includes('fake')) {
      if (obj.deleted) {
        // toDelete.push({ Id: obj.id })
        toUpdate.push({
          Id: obj.id,
          Status__c: 'Former',
          End_Date__c: moment.utc().format(dateFormat)
        })
      } else {
        toUpdate.push({
          Id: obj.id,
          Status__c: 'Current'
        })
      }
    } else {
      if (!obj.deleted) {
        toCreate.push({
          Contact__c: obj.contactId,
          Relationship__c: obj.relationship,
          Role__c: obj.role,
          Opportunity__c: connectedObject.Id,
          Status__c: 'Current'
        })
      }
    }
  })
  if (toUpdate.length > 0 || toCreate.length > 0) {
    // if (toDelete.length === 0) {
    //   return addOpportunityContactAffiliation(toCreate)
    // } else if (toCreate.length === 0) {
    //   return deleteOpportunityContactAffiliations(toDelete)
    // }
    return Promise.all([
      addOpportunityContactAffiliation(toCreate),
      updateOpportunityContactAffiliation(toUpdate)
    ])
    // return deleteOpportunityContactAffiliations(toDelete).then(deleteResult => {
    //   return addOpportunityContactAffiliation(toCreate).then(createResult => {
    //     return [createResult, deleteResult]
    //   })
    // })
  } else {
    return Promise.resolve({
      success: true
    })
  }
}

export const formAssociatedContactsListDefaultValue = (
  obj,
  additionalInfo,
  item
) => {
  const { roles = [] } = item.typeProps
  if (!obj || !additionalInfo.opportunityAffiliatedContacts) {
    return []
  } else {
    const toMap = additionalInfo.opportunityAffiliatedContacts || []
    const avaliableRoles = roles.map(role => role.apiValue)
    return toMap
      .filter(
        obj =>
          avaliableRoles.includes(obj.Role__c) && obj.Status__c !== 'Former'
      )
      .map(item => {
        const contactInfo = mapContact(item.Contact__c)
        return {
          ...contactInfo,
          contactId: contactInfo.id,
          id: item.Id,
          role: item.Role__c,
          relationship: item.Relationship__c,
          actions: {
            id: item.Id,
            contactInfo
          }
        }
      })
  }
}

export const formAssociatedContactsListValueToText = (v, question) => {
  const { roles } = question.typeProps
  const toRet = fr => {
    const roleToTrans = {}
    roles.forEach(role => {
      if (role.apiValue) {
        roleToTrans[role.apiValue] = fr ? role.labelFR : role.labelEN
      }
    })
    return (
      <div>
        <Grid container style={{ width: '100%' }}>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Contact name</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Contact e-mai</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Contact role</Trans>
            </Typography>
          </Grid>
        </Grid>
        {v
          .filter(c => !c.deleted)
          .map((contact, index) => {
            return (
              <>
                <Divider />
                <Grid container key={index} style={{ width: '100%' }}>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography>{contact.name}</Typography>
                  </Grid>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography>{contact.email}</Typography>
                  </Grid>
                  <Grid item xs style={{ padding: 4 }}>
                    <Typography>
                      {roleToTrans[contact.role] || contact.role}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )
          })}
      </div>
    )
  }

  return {
    fr: toRet(true),
    en: toRet()
  }
}

export const FormAssociatedContactsListPrint = ({
  typeProps = {},
  title,
  value = [],
  connectedObject,
  langFR,
  ...props
}) => {
  const { roles } = typeProps
  const roleToTrans = {}
  roles.forEach(role => {
    if (role.apiValue) {
      roleToTrans[role.apiValue] = langFR ? role.labelFR : role.labelEN
    }
  })

  return (
    <div style={{ width: '100%' }}>
      <div className='form-print-title-medium'>{title}</div>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Contact name</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Contact e-mai</Trans>
          </Typography>
        </Grid>
        <Grid item xs style={{ padding: 4 }}>
          <Typography className='form-print-subtitle'>
            <Trans>Contact role</Trans>
          </Typography>
        </Grid>
      </Grid>
      {value.map((contact, index) => {
        return (
          <>
            <Divider />
            <Grid container key={index} style={{ width: '100%' }}>
              <Grid item xs style={{ padding: 4 }}>
                <Typography>{contact.name}</Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography>{contact.email}</Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography>{roleToTrans[contact.role]}</Typography>
              </Grid>
            </Grid>
          </>
        )
      })}
    </div>
  )
}

export const FormAssociatedContactsList = ({
  typeProps = {},
  title,
  value = [],
  disabled,
  saveDisabled,
  connectedObject,
  id,
  formId,
  useMultiuser,
  editMode,
  reloadLastModifiedDates,
  ...props
}) => {
  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )
  const { roles = [], showRelationshipColumn } = typeProps
  const { enqueueSnackbar } = useSnackbar()
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(id)
  const { touched } = meta
  const { isEdited, userColor, userName } = getMuState({ values, id })
  const user = useSelector(state => state.user)

  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }

  const rolesLeft = []
  const valueToLabel = {}
  roles.forEach(role => {
    let usedCount = 0
    const label = user.language === 'en_US' ? role.labelEN : role.labelFR
    valueToLabel[role.apiValue] = label
    value.forEach(obj => {
      if (obj.role === role.apiValue && !obj.deleted) {
        usedCount++
      }
    })
    rolesLeft.push({
      label,
      askForRelationship: role.askForRelationship,
      value: role.apiValue,
      defaultOrganization: role.defaultOrganization,
      error: role.min && usedCount < +role.min,
      min: role.min || 0,
      current: usedCount,
      limit: role.limit || +role.min + 1
    })
  })

  const onContactFound = contact => {
    const toSet = [...value]
    if (
      toSet.some(
        obj =>
          obj.role === contact.Role__c &&
          obj.contactId === contact.Id &&
          !obj.deleted
      )
    ) {
      enqueueSnackbar(
        <Trans>This contact is already associated with this role!</Trans>,
        { variant: 'error' }
      )
    } else {
      const fakeId = 'fake' + crypto.randomBytes(8).toString('hex')
      const contactInfo = mapContact(contact)
      toSet.push({
        ...contactInfo,
        id: fakeId,
        contactId: contactInfo.id,
        actions: {
          id: fakeId,
          contactInfo
        }
      })
      setFieldValue(id, toSet)
      if (useMultiuser) {
        endEditingField({
          userId: user.userId,
          formId,
          fieldId: id,
          fieldValue: toSet,
          lockId: values.muInfo.lockId
        })
      }
    }
  }

  const onContactUpdate = newValues => {
    const toSet = [...value]
    toSet.forEach((aff, index) => {
      const bool = aff.contactId === newValues.id
      if (bool) {
        toSet[index] = {
          ...aff,
          ...newValues,
          id: aff.id,
          role: aff.role,
          relationship: aff.relationship,
          contactId: newValues.id,
          actions: {
            id: aff.id,
            contactInfo: { ...aff.actions.contactInfo, ...newValues }
          }
        }
      }
      return bool
    })
    setFieldValue(id, toSet)
    if (useMultiuser) {
      endEditingField({
        userId: user.userId,
        formId,
        fieldId: id,
        fieldValue: toSet,
        lockId: values.muInfo.lockId
      })
    }
  }

  return (
    <>
      <MUIDataTable
        data={value.filter(v => !v.deleted)}
        title={
          <Grid container wrap='nowrap'>
            <FindContactButton
              preview={saveDisabled}
              onDialogClose={e => {
                if (useMultiuser) {
                  endEditingField({
                    userId: user.userId,
                    formId,
                    fieldId: id,
                    fieldValue: field.value,
                    lockId: values.muInfo.lockId
                  })
                }
              }}
              onDialogOpen={e => {
                if (useMultiuser) {
                  startEditingField({
                    userId: user.userId,
                    formId,
                    fieldId: id
                  })
                }
              }}
              allowCreatingNewContact
              disabled={
                disabled || editMode || rolesLeft.length === 0 || saveDisabled
              }
              onSelect={onContactFound}
              roles={rolesLeft}
            />
          </Grid>
        }
        columns={[
          {
            name: 'name',
            label: myI18n._(t`Contact name`),
            options: {
              customHeadLabelRender: props => {
                return (
                  <div style={{ marginLeft: 20 }}>
                    <Trans>Contact name</Trans>
                  </div>
                )
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div style={{ flex: 1, marginLeft: 20 }}>{value}</div>
              }
            }
          },
          {
            name: 'email',
            label: myI18n._(t`Contact e-mail`),
            options: {
              customHeadLabelRender: props => <Trans>E-mail</Trans>
            }
          },
          {
            name: 'role',
            label: myI18n._(t`Contact role`),
            options: {
              customHeadLabelRender: props => <Trans>Contact role</Trans>,
              customBodyRender: value => valueToLabel[value]
            }
          },
          {
            name: 'relationship',
            label: myI18n._(t`Relationship`),
            options: {
              display: showRelationshipColumn ? 'true' : 'excluded',
              customHeadLabelRender: props => <Trans>Relationship</Trans>
            }
          },
          {
            name: 'actions',
            options: {
              sort: false,
              customHeadRender: props => {
                return <TableCell />
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                const { contactInfo } = value
                return (
                  <Grid container wrap='nowrap' justifyContent='flex-end'>
                    {/* {missingInfo && (
                      <FillMissingInformationForContact
                        contactId={contactInfo.id}
                        phone={phoneNumber}
                        mobilePhone={mobilePhone}
                        workPhone={workPhone}
                        jobTitle={jobTitle}
                        onContactUpdate={onContactUpdate}
                      />
                    )} */}

                    <EditContactPanel
                      disabled={disabled || saveDisabled}
                      onContactUpdate={onContactUpdate}
                      contactInfo={contactInfo}
                      opportunityId={connectedObject.Id}
                      reloadLastModifiedDates={reloadLastModifiedDates}
                      programManager={
                        connectedObject.Assigned_Program_Manager__c
                      }
                    />

                    <IconButton
                      disabled={disabled || saveDisabled}
                      onClick={() => {
                        const toSet = [...field.value]
                        toSet.some((obj, index) => {
                          const bool = obj.id === value.id
                          if (bool) {
                            const item = { ...obj }
                            item.deleted = true
                            toSet[index] = item
                          }
                          return bool
                        })
                        setFieldValue(id, toSet)
                        if (useMultiuser) {
                          endEditingField({
                            userId: user.userId,
                            formId,
                            fieldId: id,
                            fieldValue: toSet,
                            lockId: values.muInfo.lockId
                          })
                        }
                        if (!touched) {
                          setFieldTouched(id)
                        }
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                )
              }
            }
          }
        ]}
        options={{
          textLabels: muiTextLabels(myI18n),
          search: false,
          filter: false,
          selectableRows: 'none',
          print: false,
          download: false,
          viewColumns: false
        }}
      />
      <FormErrorLabel error={meta.error} id={id} />
      {isEdited && (
        <div style={{ marginTop: 5 }}>
          <MuEditedByLabel color={userColor} userName={userName} />
        </div>
      )}
    </>
  )
}

const phoneValidation = Yup.string()
  .nullable()
  .test(
    'isValidPhone',
    () => <Trans>Phone Number must have at least 10 digits</Trans>,
    value => {
      if (value) {
        const filtered = value.replace(/[^0-9]/g, '')
        return filtered.length >= 10
      } else {
        return true
      }
    }
  )

export const EditContactPanel = ({
  reloadLastModifiedDates,
  disabled,
  contactInfo,
  onContactUpdate,
  opportunityId,
  renderIconOnLeft,
  programManager
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [updating, setUpdating] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(state => state.user)
  const organization = useSelector(state => state.organization)
  const { phoneNumber, mobilePhone, jobTitle, workPhone, owner } = contactInfo
  const missingInfo = !jobTitle || (!phoneNumber && !workPhone && !mobilePhone)
  const phoneMissing = !phoneNumber && !workPhone && !mobilePhone
  const isContactOwner = owner === user.userId

  const initialValues = { ...contactInfo }
  const countryCodesKeys = [
    'phoneNumberCountryCode',
    'mobilePhoneCountryCode',
    'workPhoneCountryCode'
  ]
  countryCodesKeys.forEach(key => {
    if (!initialValues[key]) {
      delete initialValues[key]
    }
  })

  return (
    <Formik
      initialValues={{
        phoneNumberCountryCode: '1',
        mobilePhoneCountryCode: '1',
        workPhoneCountryCode: '1',
        ...initialValues
      }}
      enableReinitialize
      validateOnBlur
      validateOnMount
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .nullable()
          .required(requiredTrans),
        lastName: Yup.string()
          .nullable()
          .required(requiredTrans),
        jobTitle: Yup.string()
          .nullable()
          .required(requiredTrans),
        providedOrganizationName: Yup.string()
          .nullable()
          .required(requiredTrans),
        email: Yup.string()
          .nullable()
          .ensure()
          .required(requiredTrans)
          .email(() => emailTrans),
        phoneNumber: phoneValidation,
        mobilePhone: phoneValidation,
        workPhone: phoneValidation
      })}
    >
      {({ values, dirty, isValid }) => {
        const { phoneNumber, mobilePhone, workPhone } = values
        const noPhoneProvided = !phoneNumber && !mobilePhone && !workPhone

        const alert = (
          <Alert severity='warning'>
            <Trans>
              Some of required information in this contact are missing
            </Trans>
          </Alert>
        )
        const iconButton = (
          <Tooltip title={<Trans>Update affiliated contact</Trans>}>
            <IconButton
              disabled={disabled}
              onClick={e => {
                setDialogOpen(true)
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
        )

        return (
          <>
            <Dialog open={dialogOpen} fullWidth maxWidth='md'>
              <DialogTitle>
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Trans>Contact information</Trans>
                  <IconButton
                    disabled={updating}
                    style={{ marginLeft: 10 }}
                    onClick={e => {
                      setDialogOpen(false)
                    }}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </DialogTitle>
              {!isContactOwner && (
                <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                  <Alert severity='info'>
                    <Trans>
                      You are not the owner of this contact, your change request
                      will be reviewed
                    </Trans>
                  </Alert>
                </div>
              )}

              <DialogContent>
                <Grid
                  container
                  wrap='nowrap'
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  <Grid xs item style={{ paddingRight: 5 }}>
                    <FormikTextField
                      disabled={updating}
                      variant='outlined'
                      label={<Trans>First Name</Trans>}
                      name='firstName'
                      fullWidth
                      helperText={<Trans>Required</Trans>}
                    />
                  </Grid>
                  <Grid xs item style={{ paddingLeft: 5 }}>
                    <FormikTextField
                      disabled={updating}
                      variant='outlined'
                      label={<Trans>Last Name</Trans>}
                      name='lastName'
                      fullWidth
                      helperText={<Trans>Required</Trans>}
                    />
                  </Grid>
                </Grid>
                <FormikTextField
                  disabled={updating}
                  variant='outlined'
                  label={<Trans>E-mail</Trans>}
                  name='email'
                  style={{ marginBottom: 10 }}
                  fullWidth
                  helperText={<Trans>Required</Trans>}
                />
                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item>
                    <FormikTextField
                      disabled={updating}
                      variant='outlined'
                      name='phoneNumberCountryCode'
                      InputProps={{
                        inputComponent: PhoneCountryCodeFormat
                      }}
                      inputProps={{
                        maxLength: 5
                      }}
                      style={{ width: 65 }}
                    />
                  </Grid>

                  <Grid item xs>
                    <FormikTextField
                      disabled={updating}
                      variant='outlined'
                      label={<Trans>Phone number</Trans>}
                      helperText={
                        phoneMissing && (
                          <Trans>At least one phone number is required</Trans>
                        )
                      }
                      name='phoneNumber'
                      InputProps={{
                        inputComponent: PhoneFormatWithExtension
                      }}
                      style={{ marginBottom: !phoneMissing && 10 }}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {phoneMissing && (
                  <>
                    <Grid container style={{ marginBottom: 10 }}>
                      <Grid item>
                        <FormikTextField
                          disabled={updating}
                          variant='outlined'
                          name='mobilePhoneCountryCode'
                          InputProps={{
                            inputComponent: PhoneCountryCodeFormat
                          }}
                          inputProps={{
                            maxLength: 5
                          }}
                          style={{ width: 65 }}
                        />
                      </Grid>

                      <Grid item xs>
                        <FormikTextField
                          disabled={updating}
                          variant='outlined'
                          label={<Trans>Mobile Phone</Trans>}
                          helperText={
                            <Trans>At least one phone number is required</Trans>
                          }
                          name='mobilePhone'
                          InputProps={{
                            inputComponent: PhoneFormatWithExtension
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: 10 }}>
                      <Grid item>
                        <FormikTextField
                          disabled={updating}
                          variant='outlined'
                          name='workPhoneCountryCode'
                          InputProps={{
                            inputComponent: PhoneCountryCodeFormat
                          }}
                          inputProps={{
                            maxLength: 5
                          }}
                          style={{ width: 65 }}
                        />
                      </Grid>

                      <Grid item xs>
                        <FormikTextField
                          disabled={updating}
                          variant='outlined'
                          label={<Trans>Work Phone</Trans>}
                          helperText={
                            <Trans>At least one phone number is required</Trans>
                          }
                          name='workPhone'
                          InputProps={{
                            inputComponent: PhoneFormatWithExtension
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                <FormikTextField
                  disabled={updating}
                  variant='outlined'
                  label={<Trans>Organization</Trans>}
                  name='providedOrganizationName'
                  style={{ marginBottom: 10 }}
                  fullWidth
                  helperText={<Trans>Required</Trans>}
                />
                <FormikTextField
                  disabled={updating}
                  variant='outlined'
                  label={<Trans>Job title</Trans>}
                  name='jobTitle'
                  style={{ marginBottom: 10 }}
                  fullWidth
                  helperText={<Trans>Required</Trans>}
                />
              </DialogContent>
              <DialogActions>
                {isContactOwner ? (
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValid || updating || noPhoneProvided}
                    onClick={e => {
                      setUpdating(true)
                      saveContactParsed(values).then(
                        result => {
                          reloadLastModifiedDates().then(result => {
                            enqueueSnackbar(<Trans>Contact updated!</Trans>, {
                              variant: 'info'
                            })
                            setUpdating(false)
                            const toForward = { ...values }
                            toForward.name =
                              values.firstName + ' ' + values.lastName
                            onContactUpdate(toForward)
                            setDialogOpen(false)
                          })
                        },
                        reject => {
                          enqueueSnackbar(
                            <Trans>
                              Error ocurred while updating contact!
                            </Trans>,
                            {
                              variant: 'error'
                            }
                          )
                          setUpdating(false)
                        }
                      )
                    }}
                  >
                    <Icon style={{ marginRight: 5 }}>save</Icon>
                    <Trans>Update</Trans>
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!isValid || updating || noPhoneProvided || !dirty}
                    onClick={e => {
                      setUpdating(true)
                      let body = ''
                      const textData = [
                        { key: 'firstName', text: myI18n._(t`First name`) },
                        { key: 'lastName', text: myI18n._(t`Last name`) },
                        { key: 'email', text: myI18n._(t`Email`) },
                        {
                          key: 'providedOrganizationName',
                          text: myI18n._(t`Organization`)
                        },
                        { key: 'jobTitle', text: myI18n._(t`Job title`) }
                      ]
                      textData.forEach(obj => {
                        const { key, text } = obj
                        if (values[key] !== initialValues[key]) {
                          body += text + ': ' + values[key] + '\n'
                        }
                      })
                      if (
                        values.phoneNumber !== initialValues.phoneNumber ||
                        values.phoneNumberCountryCode !==
                          initialValues.phoneNumberCountryCode
                      ) {
                        body +=
                          myI18n._(t`Phone number`) +
                          ': ' +
                          parseExtensionPhoneToSF(
                            values.phoneNumber,
                            values.phoneNumberCountryCode
                          )
                      }
                      const toCreate = {
                        title: 'Update contact information',
                        contact: values.id,
                        language: user.language,
                        opportunityId,
                        userName: user.displayName,
                        organization: organization.id,
                        description: body
                      }
                      if (programManager) {
                        toCreate.OwnerId = programManager
                        toCreate.Skip_Assignment__c = true
                      }
                      createCaseByFlow(toCreate).then(
                        result => {
                          reloadLastModifiedDates().then(result => {
                            enqueueSnackbar(
                              <Trans>Information submitted!</Trans>,
                              {
                                variant: 'info'
                              }
                            )
                            setUpdating(false)
                            setDialogOpen(false)
                          })
                        },
                        reject => {
                          enqueueSnackbar(
                            <Trans>Error Submitting Case</Trans>,
                            {
                              variant: 'error'
                            }
                          )
                          setUpdating(false)
                        }
                      )
                    }}
                  >
                    <Icon style={{ marginRight: 5 }}>mail</Icon>
                    <Trans>Submit changes</Trans>
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            {renderIconOnLeft ? (
              <>
                <Grid item>{iconButton}</Grid>
                {missingInfo && <Grid item>{alert}</Grid>}
              </>
            ) : (
              <Grid container wrap='nowrap' justifyContent='flex-end'>
                {missingInfo && alert}
                {iconButton}
              </Grid>
            )}
          </>
        )
      }}
    </Formik>
  )
}

export const FormEditorAssociatedContactsList = ({
  editMode,
  depth,
  typeProps = {},
  ...props
}) => {
  const dispatch = useDispatch()
  const { roles = [], showRelationshipColumn } = typeProps
  if (!editMode) {
    return (
      <FormAssociatedContactsList editMode typeProps={typeProps} {...props} />
    )
  }
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(showRelationshipColumn)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.showRelationshipColumn = e.target.checked
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Show relationship column</Trans>}
        />
      </div>

      <FormConnectToObject
        typeProps={typeProps}
        depth={depth}
        noField
        disableMultiple
      />

      <div style={{ marginTop: 10 }}>
        <Button
          variant='contained'
          color='primary'
          style={{ marginLeft: 5, marginBottom: 10 }}
          onClick={e => {
            const toSet = { ...typeProps }
            toSet.roles = [...roles]
            toSet.roles.push({
              labelEN: '',
              labelFR: '',
              limit: 1
            })
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: { ...toSet }
            })
          }}
        >
          <Grid container alignItems='center'>
            <Trans>Add contact role</Trans>
            <Icon style={{ marginLeft: 5 }}>add</Icon>
          </Grid>
        </Button>
        {roles.map((item, index) => (
          <Grid
            key={index}
            container
            alignItems='center'
            justifyContent='space-between'
            wrap='nowrap'
          >
            <Grid item xs style={{ padding: 5 }}>
              <TextField
                variant='outlined'
                label={<Trans>Role label - english</Trans>}
                fullWidth
                value={item.labelEN || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.roles[index].labelEN = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
            <Grid item xs style={{ padding: 5 }}>
              <TextField
                variant='outlined'
                label={<Trans>Role label - french</Trans>}
                fullWidth
                value={item.labelFR || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.roles[index].labelFR = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
            <Grid item xs style={{ padding: 5 }}>
              <TextField
                variant='outlined'
                label={<Trans>API value</Trans>}
                fullWidth
                value={item.apiValue || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.roles[index].apiValue = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
            <Grid item xs style={{ padding: 5 }}>
              <TextField
                variant='outlined'
                label={<Trans>Default organization</Trans>}
                fullWidth
                select
                value={item.defaultOrganization || ''}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.roles[index].defaultOrganization = e.target.value
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              >
                <MenuItem value='current' key='current'>
                  <Trans>Current Organization</Trans>
                </MenuItem>
                <MenuItem value='household' key='household'>
                  <Trans>Household</Trans>
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item style={{ padding: 5, width: 100 }}>
              <TextField
                variant='outlined'
                label={<Trans>Minimum</Trans>}
                fullWidth
                value={item.min || ''}
                InputProps={{ inputComponent: NumberFormatDefault }}
                onChange={e => {
                  const toSet = { ...typeProps }
                  const item = toSet.roles[index]
                  item.min = e.target.value
                  if (item.min && item.limit && +item.limit < +item.min) {
                    item.limit = Number(+item.min + 1)
                  }
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
            <Grid item style={{ padding: 5, width: 100 }}>
              <TextField
                variant='outlined'
                label={<Trans>Limit</Trans>}
                fullWidth
                value={item.limit || ''}
                InputProps={{ inputComponent: NumberFormatDefault }}
                onChange={e => {
                  const toSet = { ...typeProps }
                  const item = toSet.roles[index]
                  item.limit = e.target.value
                  if (item.min && item.limit && +item.min > +item.limit) {
                    item.min = Number(+item.limit - 1)
                  }
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                style={{ margin: 0 }}
                onChange={e => {
                  const toSet = { ...typeProps }
                  toSet.roles[index].askForRelationship = e.target.checked
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
                checked={Boolean(item.askForRelationship)}
                control={<Checkbox />}
                label={<Trans>Ask for relationship?</Trans>}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={e => {
                  const toSet = { ...typeProps }
                  toSet.roles.splice(index, 1)
                  dispatch({
                    type: 'FIELD',
                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: { ...toSet }
                  })
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  )
}
