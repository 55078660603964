import React, { useRef } from 'react'
import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import {
  Grid,
  Card,
  IconButton,
  Icon,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  FormControlLabel,
  Switch,
  FormLabel,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core'
import { useDrag, useDrop } from 'react-dnd'
import { draggableItemTypes } from './FormWizard'
import { t, Trans } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormEditorPicklist,
  FormPicklist,
  FormPicklistPdf,
  FormPicklistPrint,
  formPicklistValueToText
} from './components/FormPicklist'
import {
  FormEditorTextField,
  FormTextField,
  formTextFieldValueToText,
  FormTextInputPdf,
  FormTextInputPrint
} from './components/FormTextField'
import { FormText, FormEditorText, FormTextPdf } from './components/FormText'
import {
  FormUploadFiles,
  FormEditorUploadFiles,
  FormUploadFilesPrint,
  formUploadFilesConditionsStates
} from './components/FormUploadFiles'
import { useField, useFormikContext } from 'formik'
import { TooltipLabelIcon } from '../page-layouts/TooltipLabelIcon'
import {
  FormImage,
  FormEditorImage,
  formImageConditionsStates,
  FormImagePrint
} from './components/FormImage'
import { FormHeader, FormEditorHeader } from './components/FormHeader'
import {
  FormHtmlInjector,
  FormEditorHtmlInjector,
  FormHtmlPdf
} from './components/FormHtmlInjector'
import {
  FormTextFieldNumeric,
  FormEditorTextFieldNumeric,
  formTextFieldNumericValueToText,
  formTextFieldNumericConditionsStates,
  formTextFieldNumericDefaultValue
} from './components/FormTextFieldNumeric'
import {
  FormSubmitButton,
  FormEditorSubmitButton
} from './components/custom/FormSubmitButton'
import {
  FormSubmitOpportunity,
  FormEditorSubmitOpportunity
} from './components/custom/FormSubmitOpportunity'
import {
  FormEditorMilestones,
  FormMilestones,
  FormMilestonesDefaultValue,
  FormMilestonesValidation,
  FormMilestonesError,
  FormMilestonesExtractKey,
  formMilestonesValueToText,
  FormMilestonesPrint,
  FormMilestonesPdf
} from './components/custom/FormMilestones'
import {
  FormObjectives,
  FormEditorObjectives,
  FormObjectivesDefaultValue,
  FormObjectivesValidation,
  FormObjectivesError,
  FormObjectivesExtractKey,
  formObjectivesValueToText,
  FormObjectivesPrint,
  FormObjectivesPdf
} from './components/custom/FormObjectives'
import {
  FormBudget,
  FormEditorBudget,
  FormBudgetDefaultValue,
  FormBudgetExtractKey,
  formBudgetValueToText,
  formBudgetParseValueToCompare,
  FormBudgetPrint,
  formBudgetValidation,
  FormBudgetError
} from './components/custom/FormBudget'
import ConditionalElementEditor from './ConditionalElementEditor'
import {
  FormICCEBudget,
  FormEditorICCEBudget,
  formICCEBudgetDefaultValue,
  formICCEBudgetExtractKey,
  formICCEBudgetError,
  formICCEBudgetValueToText,
  formICCEBudgetValidation
} from './components/custom/FormICCEBudget'
import {
  formICCEWorkplanDefaultValue,
  FormICCEWorkplan,
  formICCEWorkplanValidation,
  FormEditorICCEWorkplan,
  formICCEWorkplanExtractKey,
  formICCEWorkplanError,
  formICCEWorkplanValueToText
} from './components/custom/FormICCEWorkplan'
import {
  FormContactPicker,
  FormEditorContactPicker,
  formContactPickerValueToText,
  formContactParseValueToCompare,
  formContactPickerSavePromise,
  formContactPickerDefaultValue,
  FormContactPickerPrint,
  formContactPickerExtractSaveKey
} from './components/custom/FormContactPicker'
import {
  FormAccountPicker,
  FormAccountPickerPrint,
  FormEditorAccountPicker,
  formAccountPickerExtractSaveKey,
  formAccountPickerValueToText,
  formAccountPickerParseValueToCompare,
  formAccountPickerDefaultValue
} from './components/custom/FormAccountPicker'
import {
  FormBoolean,
  FormEditorBoolean,
  formBooleanValueToText,
  formBooleanParseValueToCompare,
  FormBooleanPrint
} from './components/FormBoolean'
import {
  FormBlankSpace,
  FormEditorBlankSpace
} from './components/FormBlankSpace'
import {
  FormSaveButton,
  FormEditorSaveButton
} from './components/FormSaveButton'
import {
  FormOtherGrants,
  FormOtherGrantsPrint,
  FormEditorOtherGrants,
  formOtherGrantsDefaultValue,
  formOtherGrantsExtractSaveKey,
  formOtherGrantsValueToText
} from './components/custom/FormOtherGrants'
import {
  FormGoogleMapsPicker,
  FormEditorGoogleMapsPicker,
  formGoogleMapsPickerDefaultValue,
  formGoogleMapsPickerExtractSaveKey,
  formGoogleMapsPickerValueToText,
  formGoogleMapsPickerValidation,
  formGoogleMapsPickerError
} from './components/custom/FormGoogleMapsPicker'
import {
  FormEditorHomePageButton,
  FormHomePageButton
} from './components/FormHomePageButton'
import {
  FormAccountJoinList,
  FormEditorAccountJoinList,
  formAccountJoinListDefaultValue
} from './components/custom/FormAccountJoinList'
import {
  FormDatePicker,
  FormEditorDatePicker,
  formDatePickerDefaultValue,
  formDatePickerValueToText
} from './components/FormDatePicker'
import {
  FormAssociatedContactsList,
  formAssociatedContactsListDefaultValue,
  FormAssociatedContactsListPrint,
  formAssociatedContactsListValidation,
  formAssociatedContactsListValueToText,
  formAssociatedContactsSavePromise,
  FormEditorAssociatedContactsList
} from './components/custom/FormAssociatedContactsList'
import ReactHtmlParser from 'react-html-parser'
import { makeStyles } from '@material-ui/styles'
import { FormElementPrintProps, NumberFormatDefault } from './components/Common'
import {
  FormEditorPayments,
  FormPayments,
  formPaymentsDefaultValue,
  FormPaymentsPdf
} from './components/custom/FormPayments'
import {
  FormEditorGranteeReports,
  FormGranteeReports,
  formGranteeReportsDefaultValue,
  FormGranteeReportsPdf
} from './components/custom/FormGranteeReports'
import {
  FormEditorTextWithReferences,
  FormTextWithReferences
} from './components/FormTextWithReferences'
import {
  FormEditorSignature,
  FormSignature,
  FormSignaturePdf
} from './components/custom/FormSignature'
import { myI18n } from 'translation/I18nConnectedProvider'
import {
  FormEditorTable,
  FormTable,
  FormTablePdf
} from './components/custom/FormTable'
import { formItemPadding } from './Form'
import sfOauthConfig from 'app/services/sfAuth/sfAuthConfig'
import {
  FormNumericSlider,
  FormEditorNumericSlider,
  FormNumericSliderPrint
} from './components/FormNumericSlider'
import { useState } from 'react'
import Loading from 'egret/components/EgretLoadable/Loading'
import moment from 'moment/moment'
import { datetimeFormat } from 'app/appSettings'
import {
  endEditingField,
  grpcGetFieldHistory
} from './multiuser/grpcMultiuserEdit'
import { useEffect } from 'react'
import {
  grpcGetFieldComment,
  grpcLockFieldComment,
  grpcUnlockFieldComment
} from './multiuser/grpcFieldComment'
import MUTextField from './components/multiuser-input/MUTextField'

export const formUseStyles = makeStyles(theme => ({
  root: {
    '& p': {
      margin: 0,
      padding: 0
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  }
}))

export const FormElementTitle = ({
  tooltip,
  formId,
  labelsWidth,
  id,
  altLabelPlacement,
  helpText,
  labelAsMarkdown,
  title,
  type,
  useMultiuser,
  disabled,
  showFieldLabel = false,
  item,
  connectedObject
}) => {
  const [field, meta] = useField(id)
  const classes = formUseStyles()
  const isError = Boolean(meta.error && meta.touched)

  const showTitle = Boolean(
    !formElementsWithoutDefaultTitle.includes(type) &&
      !formElementsWithCustomTitleRender.includes(type) &&
      !showFieldLabel
  )

  let width = 0
  if (altLabelPlacement) {
    width = 'initial'
  } else {
    width = '100&'
  }
  if (labelsWidth) {
    width = Number(labelsWidth)
  }

  return (
    <>
      {showTitle && (
        <Grid
          item
          container
          alignItems='center'
          wrap='nowrap'
          style={{
            paddingLeft: 10,
            paddingBottom: useMultiuser ? 0 : 8,
            paddingRight: altLabelPlacement && 10,
            paddingTop: altLabelPlacement && 10,
            width,
            textAlign: altLabelPlacement && 'right'
          }}
        >
          <Typography
            style={{
              flex: altLabelPlacement && 1,
              marginRight: 5
            }}
          >
            {labelAsMarkdown ? (
              <Grid container wrap='nowrap'>
                <span className={classes.root}>{ReactHtmlParser(title)}</span>
                {Boolean(isError && title) && (
                  <span style={{ color: 'red' }}>*</span>
                )}
                {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
              </Grid>
            ) : (
              <>
                <b>{title}</b>
                {Boolean(isError && title) && (
                  <span style={{ color: 'red' }}>*</span>
                )}
                {tooltip && <TooltipLabelIcon tooltip={tooltip} />}
              </>
            )}
            {/* {labelAsMarkdown ? (
              <span className={classes.root}>{ReactHtmlParser(title)}</span>
            ) : (
              <b>{title}</b>
            )}
            {Boolean(isError && title) && (
              <span style={{ color: 'red' }}>*</span>
            )}
            {tooltip && <TooltipLabelIcon tooltip={tooltip} />} */}
          </Typography>
          <PreviouslySavedValuesPanel
            disabled={disabled}
            formId={formId}
            fieldId={id}
            type={type}
            connectedObject={connectedObject}
            item={item}
            useMultiuser={useMultiuser}
          />
          {/* {useMultiuser && (
            <MultiuserFieldComment formId={formId} fieldId={id} />
          )} */}
        </Grid>
      )}
      {helpText && !formElementsWithCustomTitleRender.includes(type) && (
        <FormHelptext helpText={helpText} />
      )}
    </>
  )
}

export const PreviouslySavedValuesPanel = ({
  disabled,
  formId,
  fieldId,
  type,
  connectedObject = {},
  item = {},
  useMultiuser
}) => {
  const { setFieldValue, values } = useFormikContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [backups, setBackups] = useState()
  const user = useSelector(state => state.user)
  const renderValue = formElementTypes[type].valueToText
  const { muInfo } = values
  const muState = muInfo[fieldId]
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      disabled = true
    }
  }

  useEffect(() => {
    if (!dialogOpen) {
      setBackups(null)
    }
  }, [dialogOpen])

  if (!useMultiuser) {
    return null
  }

  return (
    <>
      <Dialog open={dialogOpen} fullWidth maxWidth='md'>
        <DialogTitle>
          <Grid container justifyContent='space-between'>
            <Trans>Field history</Trans>
            <IconButton
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ minHeight: 600 }}>
          {backups ? (
            backups.length === 0 ? (
              <div>
                <Trans>This field has no history yet</Trans>
              </div>
            ) : (
              backups.map((backup, index) => {
                let showValue = backup.value
                if (renderValue) {
                  const { en, fr } = renderValue(
                    backup.value,
                    item,
                    connectedObject
                  )
                  if (user.language === 'en_US') {
                    showValue = en
                  } else {
                    showValue = fr
                  }
                }
                return (
                  <Grid
                    container
                    key={index}
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    style={{ marginTop: 10 }}
                  >
                    <Grid item style={{ padding: 5 }}>
                      <div style={{ fontWeight: 400, fontSize: 11 }}>
                        <Trans>Date</Trans>
                      </div>
                      <div>
                        {moment.utc(backup.date).format(datetimeFormat)}
                      </div>
                    </Grid>

                    <Grid item xs style={{ padding: 5, paddingLeft: 15 }}>
                      <div style={{ fontWeight: 400, fontSize: 11 }}>
                        <Trans>Value</Trans>
                      </div>
                      <div>{showValue}</div>
                    </Grid>
                    <Grid item style={{ padding: 5 }}>
                      <IconButton
                        disabled={disabled}
                        onClick={e => {
                          setDialogOpen(false)
                          setFieldValue(fieldId, backup.value)
                          if (useMultiuser) {
                            endEditingField({
                              userId: user.userId,
                              formId,
                              fieldId,
                              fieldValue: backup.value
                            })
                          }
                        }}
                      >
                        <Icon>settings_backup_restore</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })
            )
          ) : (
            <Loading />
          )}
        </DialogContent>
      </Dialog>
      <Tooltip title={<Trans>Browse saved field values</Trans>}>
        <span>
          <IconButton
            style={{ marginLeft: 5 }}
            size='small'
            disabled={disabled}
            onClick={e => {
              setDialogOpen(true)
              grpcGetFieldHistory({
                userId: user.userId,
                formId,
                fieldId,
                onSuccess: e => {
                  setBackups(e.sort((a, b) => a.date.diff(b.date, 'minutes')))
                }
              })
            }}
          >
            <Icon>settings_backup_restore</Icon>
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

const MultiuserFieldComment = ({ fieldId, formId }) => {
  const userId = useSelector(state => state.user.userId)
  const [comment, setComment] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const fetchComment = () => {
    grpcGetFieldComment({
      fieldId,
      userId,
      formId,
      onSuccess: e => {
        console.log('get field comment', e)
      }
    })
  }

  return (
    <>
      <span
        onMouseEnter={e => {
          if (!comment) {
            fetchComment()
          }
        }}
      >
        <Tooltip title={comment}>
          <IconButton
            onClick={e => {
              setDialogOpen(true)
              fetchComment()
            }}
          >
            <Icon>sticky_note_2</Icon>
          </IconButton>
        </Tooltip>
      </span>
      <Dialog open={dialogOpen} fullWidth maxWidth='md'>
        <DialogTitle>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Trans>Field history</Trans>
            <IconButton
              onClick={e => {
                setDialogOpen(false)
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={inputValue}
            multiline
            onChange={e => {
              setInputValue(e.target.value)
            }}
            onFocus={e => {
              grpcLockFieldComment({
                fieldId,
                userId,
                formId,
                comment: inputValue
              })
            }}
            onBlur={e => {
              grpcUnlockFieldComment({
                fieldId,
                userId,
                formId,
                comment: inputValue
              })
            }}
            variant='outlined'
            fullWidth
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export const FormHelptext = ({ helpText }) => {
  const classes = formUseStyles()
  if (!helpText) {
    return <div />
  }

  return (
    <div
      style={{ padding: 10, paddingTop: 0, fontWeight: 400, fontSize: 13 }}
      className={classes.root}
    >
      {ReactHtmlParser(helpText)}
    </div>
  )
}

export const FormHelptextWithParse = ({ helpText }) => {
  const classes = formUseStyles()
  if (helpText) {
    helpText = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(helpText)
      .toString()
  } else {
    return <div />
  }
  return (
    <div
      style={{ padding: 10, paddingTop: 0, fontWeight: 400, fontSize: 13 }}
      className={classes.root}
    >
      {ReactHtmlParser(helpText)}
    </div>
  )
}

export const formElementTypes = {
  text: {
    text: myI18n._(t`Text`),
    basic: true,
    injectable: true,
    printComponent: props => <FormText {...props} />,
    formComponent: props => <FormText {...props} />,
    formComponentPdf: props => <FormTextPdf {...props} />,
    component: props => <FormEditorText {...props} />
  },
  textWithReferences: {
    text: myI18n._(t`Text with references`),
    basic: true,
    injectable: true,
    printComponent: props => <FormTextWithReferences {...props} />,
    formComponent: props => <FormTextWithReferences {...props} />,
    formComponentPdf: props => <FormTextWithReferences {...props} pdf />,
    component: props => <FormEditorTextWithReferences {...props} />
  },
  header: {
    text: myI18n._(t`Header`),
    basic: true,
    printComponent: props => <FormHeader {...props} />,
    formComponent: props => <FormHeader {...props} />,
    component: props => <FormEditorHeader {...props} />
  },
  image: {
    text: myI18n._(t`Image`),
    basic: true,
    additionalConditions: formImageConditionsStates,
    printComponent: props => <FormImage {...props} />,
    formComponentPdf: props => <FormImagePrint {...props} />,
    formComponent: props => <FormImage {...props} />,
    component: props => <FormEditorImage {...props} />
  },
  textInput: {
    text: myI18n._(t`Text Input (Text)`),
    basic: true,
    formComponentPdf: props => <FormTextInputPdf {...props} />,
    printComponent: props => <FormTextInputPrint {...props} />,
    formComponent: props => <FormTextField {...props} />,
    component: props => <FormEditorTextField {...props} />,
    // extractSaveKey: props => formTextFieldExtractSaveKey(props),
    valueToText: (value, question, object) =>
      formTextFieldValueToText(value, question, object),
    defaultValue: ''
  },
  textInputNumeric: {
    text: myI18n._(t`Text Input (Numeric)`),
    basic: true,
    formComponentPdf: props => <FormTextInputPdf {...props} />,
    printComponent: props => <FormTextInputPrint {...props} />,
    formComponent: props => <FormTextFieldNumeric {...props} />,
    component: props => <FormEditorTextFieldNumeric {...props} />,
    valueToText: value => formTextFieldNumericValueToText(value),
    additionalConditions: formTextFieldNumericConditionsStates,
    defaultValue: (obj, addInfo, item) =>
      formTextFieldNumericDefaultValue(obj, addInfo, item)
  },
  numericSlider: {
    text: myI18n._(t`Numeric slider`),
    basic: true,
    printComponent: props => <FormNumericSliderPrint {...props} />,
    formComponent: props => <FormNumericSlider {...props} />,
    component: props => <FormEditorNumericSlider {...props} />
  },
  datePicker: {
    text: myI18n._(t`Date picker`),
    basic: true,
    printComponent: props => <FormTextInputPrint {...props} />,
    formComponent: props => <FormDatePicker {...props} />,
    component: props => <FormEditorDatePicker {...props} />,
    valueToText: value => formDatePickerValueToText(value),
    defaultValue: (obj, info, item) =>
      formDatePickerDefaultValue(obj, info, item)
  },
  bool: {
    text: myI18n._(t`Boolean Input`),
    basic: true,
    printComponent: props => <FormBooleanPrint {...props} />,
    formComponent: props => <FormBoolean {...props} />,
    component: props => <FormEditorBoolean {...props} />,
    defaultValue: false,
    valueToText: value => formBooleanValueToText(value),
    parseValueToCompare: value => formBooleanParseValueToCompare(value)
  },
  picklist: {
    text: myI18n._(t`Picklist`),
    basic: true,
    formComponentPdf: props => <FormPicklistPdf {...props} />,
    formComponent: props => <FormPicklist {...props} />,
    component: props => <FormEditorPicklist {...props} />,
    printComponent: props => <FormPicklistPrint {...props} />,
    valueToText: (value, question, object) =>
      formPicklistValueToText(value, question, object)
  },
  uploadFiles: {
    text: myI18n._(t`Upload Files`),
    noFieldConnect: true,
    basic: true,
    additionalConditions: formUploadFilesConditionsStates,
    printComponent: props => <FormUploadFilesPrint {...props} />,
    formComponent: props => <FormUploadFiles {...props} />,
    component: props => <FormEditorUploadFiles {...props} />,
    defaultValue: []
  },
  blank: {
    text: myI18n._(t`Blank space`),
    basic: true,
    formComponent: props => <FormBlankSpace {...props} />,
    component: props => <FormEditorBlankSpace {...props} />
  },
  html: {
    text: myI18n._(t`Html`),
    basic: true,
    printComponent: props => <FormHtmlInjector {...props} />,
    formComponent: props => <FormHtmlInjector {...props} />,
    formComponentPdf: props => <FormHtmlPdf {...props} />,
    component: props => <FormEditorHtmlInjector {...props} />
  },
  saveButton: {
    text: myI18n._(t`Save button`),
    basic: true,
    formComponent: props => <FormSaveButton {...props} />,
    component: props => <FormEditorSaveButton {...props} />
  },
  submitButton: {
    text: myI18n._(t`Submit button`),
    basic: true,
    formComponent: props => <FormSubmitButton {...props} />,
    component: props => <FormEditorSubmitButton {...props} />
  },
  submitOpportunity: {
    text: myI18n._(t`Submit application field`),
    formComponent: props => <FormSubmitOpportunity {...props} />,
    component: props => <FormEditorSubmitOpportunity {...props} />,
    defaultValue: Array(5).fill(false)
  },
  table: {
    text: myI18n._(t`Table`),
    noFieldConnect: true,
    printComponent: props => <FormTable {...props} />,
    formComponentPdf: props => <FormTablePdf {...props} />,
    formComponent: props => <FormTable {...props} />,
    component: props => <FormEditorTable {...props} />
  },
  milestones: {
    text: myI18n._(t`Milestones`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    relatedCollections: ['milestones'],
    // include: 'FGM_Base__Benchmarks__r',
    formComponentPdf: props => <FormMilestonesPdf {...props} />,
    printComponent: props => <FormMilestonesPrint {...props} />,
    formComponent: props => <FormMilestones {...props} />,
    component: props => <FormEditorMilestones {...props} />,
    validation: item => FormMilestonesValidation(item),
    extractError: error => FormMilestonesError(error),
    defaultValue: (obj, addInfo, item) =>
      FormMilestonesDefaultValue(obj, addInfo, item),
    extractSaveKey: props => FormMilestonesExtractKey(props),
    valueToText: (value, question) => formMilestonesValueToText(value, question)
  },
  objectives: {
    text: myI18n._(t`Objectives`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    customHelptext: true,
    relatedCollections: ['objectives'],
    // include: 'Objectives__r',
    formComponentPdf: props => <FormObjectivesPdf {...props} />,
    printComponent: props => <FormObjectivesPrint {...props} />,
    formComponent: props => <FormObjectives {...props} />,
    component: props => <FormEditorObjectives {...props} />,
    defaultValue: obj => FormObjectivesDefaultValue(obj),
    extractError: error => FormObjectivesError(error),
    validation: item => FormObjectivesValidation(item),
    extractSaveKey: props => FormObjectivesExtractKey(props),
    valueToText: value => formObjectivesValueToText(value)
  },
  budget: {
    text: myI18n._(t`Budget`),
    noFieldConnect: true,
    disabled: sfOauthConfig.isIcce,
    printComponent: props => <FormBudgetPrint {...props} />,
    include: 'FGM_Portal__Grantee_Budget_Line_Items__r',
    includeSelect:
      'Id, LastModifiedDate, FGM_Portal__Amount__c, FGM_Portal__Category__r.Name, FGM_Portal__Grantee_Budget__r.Name, ' +
      'FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name, ' +
      'FGM_Portal__Grantee_Budget_Line_Item__c.FGM_Portal__Note__c ',
    formComponent: props => <FormBudget {...props} />,
    validation: props => formBudgetValidation(props),
    extractError: error => FormBudgetError(error),
    component: props => <FormEditorBudget {...props} />,
    defaultValue: obj => FormBudgetDefaultValue(obj),
    extractSaveKey: props => FormBudgetExtractKey(props),
    valueToText: value => formBudgetValueToText(value),
    parseValueToCompare: value => formBudgetParseValueToCompare(value)
  },
  icce_budget: {
    text: myI18n._(t`ICCE Budget`),
    disabled: !sfOauthConfig.isIcce,
    noFieldConnect: true,
    include: 'Budget_Lines__r',
    validation: item => formICCEBudgetValidation(item),
    extractError: error => formICCEBudgetError(error),
    defaultValue: (obj, addInfo, item) =>
      formICCEBudgetDefaultValue(obj, addInfo, item),
    formComponent: props => <FormICCEBudget {...props} />,
    component: props => <FormEditorICCEBudget {...props} />,
    extractSaveKey: props => formICCEBudgetExtractKey(props),
    valueToText: (v, q, obj) => formICCEBudgetValueToText(v, q, obj)
  },
  icce_workplan: {
    text: myI18n._(t`ICCE Workplan`),
    disabled: !sfOauthConfig.isIcce,
    noFieldConnect: true,
    include: 'Work_Plan_Lines__r',
    extractError: error => formICCEWorkplanError(error),
    validation: item => formICCEWorkplanValidation(item),
    defaultValue: obj => formICCEWorkplanDefaultValue(obj),
    formComponent: props => <FormICCEWorkplan {...props} />,
    component: props => <FormEditorICCEWorkplan {...props} />,
    extractSaveKey: props => formICCEWorkplanExtractKey(props),
    valueToText: (v, q, obj) => formICCEWorkplanValueToText(v, q, obj)
  },
  googleMapsPicker: {
    text: myI18n._(t`Google Maps Picker`),
    noFieldConnect: true,
    formComponent: props => <FormGoogleMapsPicker {...props} />,
    component: props => <FormEditorGoogleMapsPicker {...props} />,
    defaultValue: (obj, info, item) =>
      formGoogleMapsPickerDefaultValue(obj, info, item),
    validation: () => formGoogleMapsPickerValidation(),
    extractError: error => formGoogleMapsPickerError(error),
    extractSaveKey: props => formGoogleMapsPickerExtractSaveKey(props),
    valueToText: (value, question) =>
      formGoogleMapsPickerValueToText(value, question)
  },
  granteeReports: {
    text: myI18n._(t`Grantee Reports`),
    deprecated: true,
    noFieldConnect: true,
    relatedCollections: ['reports'],
    defaultValue: (obj, info, item) =>
      formGranteeReportsDefaultValue(obj, info, item),
    formComponentPdf: props => <FormGranteeReportsPdf {...props} />,
    formComponent: props => <FormGranteeReports {...props} />,
    component: props => <FormEditorGranteeReports {...props} />
  },
  payments: {
    text: myI18n._(t`Payments`),
    deprecated: true,
    noFieldConnect: true,
    relatedCollections: ['payments'],
    defaultValue: (obj, info, item) =>
      formPaymentsDefaultValue(obj, info, item),
    formComponentPdf: props => <FormPaymentsPdf {...props} />,
    formComponent: props => <FormPayments {...props} />,
    component: props => <FormEditorPayments {...props} />
  },
  otherGrants: {
    text: myI18n._(t`Other grants`),
    printComponent: props => <FormOtherGrantsPrint {...props} />,
    formComponent: props => <FormOtherGrants {...props} />,
    component: props => <FormEditorOtherGrants {...props} />,
    extractSaveKey: props => formOtherGrantsExtractSaveKey(props),
    defaultValue: (obj, info, item) =>
      formOtherGrantsDefaultValue(obj, info, item),
    valueToText: value => formOtherGrantsValueToText(value)
  },
  signature: {
    text: myI18n._(t`Signature`),
    relatedCollections: ['opportunityAffiliatedContacts'],
    noFieldConnect: true,
    formComponentPdf: props => <FormSignaturePdf {...props} />,
    component: props => <FormEditorSignature {...props} />,
    formComponent: props => <FormSignature {...props} />
  },
  associatedContactsList: {
    text: myI18n._(t`Contacts associated wih object`),
    noFieldConnect: true,
    extraFields: ['Assigned_Program_Manager__c'],
    relatedCollections: ['opportunityAffiliatedContacts'],
    printComponent: props => <FormAssociatedContactsListPrint {...props} />,
    component: props => <FormEditorAssociatedContactsList {...props} />,
    formComponent: props => <FormAssociatedContactsList {...props} />,
    savePromise: props => formAssociatedContactsSavePromise(props),
    validation: item => formAssociatedContactsListValidation(item),
    valueToText: (value, question) =>
      formAssociatedContactsListValueToText(value, question),
    defaultValue: (obj, info, item) =>
      formAssociatedContactsListDefaultValue(obj, info, item)
  },
  accountJoinList: {
    text: myI18n._(t`Connect opportunity with organizations`),
    noFieldConnect: true,
    formComponent: props => <FormAccountJoinList {...props} />,
    component: props => <FormEditorAccountJoinList {...props} />,
    defaultValue: (obj, info, item) =>
      formAccountJoinListDefaultValue(obj, info, item)
  },
  connectContact: {
    text: myI18n._(t`Contact picker`),
    relatedCollections: ['accountAffiliations'],
    printComponent: props => <FormContactPickerPrint {...props} />,
    formComponent: props => <FormContactPicker {...props} />,
    savePromise: props => formContactPickerSavePromise(props),
    defaultValue: (obj, info, item) =>
      formContactPickerDefaultValue(obj, info, item),

    // savePromise: () => Promise.resolve(),
    extractSaveKey: props => formContactPickerExtractSaveKey(props),
    component: props => <FormEditorContactPicker {...props} />,
    valueToText: (v, q, obj) => formContactPickerValueToText(v, q, obj),
    parseValueToCompare: (value, props = {}) =>
      formContactParseValueToCompare(value, props)
  },
  connectAccount: {
    text: myI18n._(t`Organization picker`),
    formComponent: props => <FormAccountPicker {...props} />,
    printComponent: props => <FormAccountPickerPrint {...props} />,
    extractSaveKey: props => formAccountPickerExtractSaveKey(props),
    component: props => <FormEditorAccountPicker {...props} />,
    defaultValue: (obj, info, item) =>
      formAccountPickerDefaultValue(obj, info, item),
    valueToText: (value, question, object) =>
      formAccountPickerValueToText(value, question, object),
    parseValueToCompare: (value, props = {}) =>
      formAccountPickerParseValueToCompare(value, props)
  },
  homePageButton: {
    text: myI18n._(t`Redirect Button`),
    formComponent: props => <FormHomePageButton {...props} />,
    component: props => <FormEditorHomePageButton {...props} />
  }
}

export const formElementsWithoutInput = [
  'text',
  'table',
  'textWithReferences',
  'image',
  'header',
  'html',
  'blank',
  'saveButton',
  'submitButton',
  'homePageButton'
]
export const formElementsWithoutTooltip = [
  'text',
  'textWithReferences',
  'table',
  'accountJoinList',
  'image',
  'header',
  'html',
  'blank',
  'saveButton',
  'submitButton',
  'submitOpportunity',
  'milestones',
  'objectives',
  'budget',
  'icce_budget',
  'icce_workplan'
]
export const formElementsWithoutDefaultTitle = [
  'image',
  'textWithReferences',
  'html',
  'blank',
  'otherGrants',
  'saveButton',
  'submitButton',
  'submitOpportunity',
  'homePageButton'
]

export const formElementsWithCustomTitleRender = [
  'budget',
  'table',
  'bool',
  'objectives',
  'accountJoinList',
  'icce_budget',
  'icce_workplan',
  'milestones',
  'text',
  'otherGrants',
  'header',
  'homePageButton'
]

const usesMarkdownInRender = ['bool']

const rendersTitle = type =>
  Boolean(
    !formElementsWithoutDefaultTitle.includes(type) ||
      formElementsWithCustomTitleRender.includes(type)
  )

export const GroupElement = ({
  titleEN,
  titleFR,
  editMode,
  isDragLayer,
  parentIsDummy,
  movingInStack,
  selectedParent,
  index,
  elementType,
  fieldType,
  width,
  columns,
  french,
  depth = [],
  selected,
  emptySpace,
  typeProps,
  conditions = [],
  ...props
}) => {
  const dummy = props.dummy || parentIsDummy
  const dispatch = useDispatch()
  const [collected, drag, preview] = useDrag(
    () => ({
      type: draggableItemTypes.ITEM_ELEMENT,
      item: () => {
        dispatch({
          type: 'START_DRAG',
          depth
        })
        const dragRectWidth = body.current?.getBoundingClientRect().width
        return {
          index,
          elementType,
          depth,
          titleEN,
          titleFR,
          typeProps,
          editMode: editMode,
          selected: selected,
          type: draggableItemTypes.ITEM_ELEMENT,
          width: dragRectWidth,
          conditions,
          ...props
        }
      },
      canDrag: () => !selectedParent,
      end (item, monitor) {
        dispatch({
          type: 'END_DRAG',
          depth: [...item.depth]
        })
      },
      collect: monitor => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [
      titleEN,
      titleFR,
      editMode,
      isDragLayer,
      parentIsDummy,
      movingInStack,
      selectedParent,
      index,
      elementType,
      fieldType,
      width,
      columns,
      french,
      depth,
      selected,
      emptySpace,
      props
    ]
  )

  const body = useRef()

  const [{ isHoveredOverStrict }, drop] = useDrop({
    accept: [draggableItemTypes.GROUP_CARD, draggableItemTypes.ITEM_ELEMENT],
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isHoveredOverStrict: monitor.isOver({ shallow: true })
      }
    },
    drop (item, monitor) {
      if (!body.current) {
        return
      }
      if (!isHoveredOverStrict) {
        return
      }
      if (item.dropped) {
        return
      }
      const dragDepth = [...item.depth]
      const hoverDepth = [...depth]
      if (dragDepth.join('.') === hoverDepth.join('.')) {
        dispatch({
          type: 'FIELD',
          depth,
          fieldName: 'dummy',
          fieldValue: false
        })
        return
      }
      const dragParentPath = [...dragDepth]
      dragParentPath.splice(-1, 1)
      const hoverParentPath = [...hoverDepth]
      hoverParentPath.splice(-1, 1)
      const isInSameGroup =
        hoverParentPath.join('.') === dragParentPath.join('.')
      if (!isInSameGroup) {
        dispatch({
          type: 'MOVE_ITEM_BETWEEN',
          dragDepth,
          hoverDepth,
          endDragDepth: [...hoverDepth]
        })
      } else {
        const dragIndex = dragDepth[dragDepth.length - 1]
        const hoverIndex = hoverDepth[hoverDepth.length - 1]
        const groupDepth = [...depth]
        groupDepth.splice(-1, 1)
        dispatch({
          dragIndex,
          hoverIndex,
          depth: groupDepth,
          endDragDepth: [...hoverDepth],
          type: 'MOVE_ITEM'
        })
      }
      item.depth = [...hoverDepth]
      item.dropped = true
    },
    hover (item, monitor) {
      return
      if (!body.current) {
        return
      }
      if (!isHoveredOverStrict) {
        return
      }
      if (movingInStack) {
        return
      }
      const dragDepth = [...item.depth]
      const hoverDepth = [...depth]
      if (dragDepth.join('.') === hoverDepth.join('.')) {
        return
      }
      const dragParentPath = [...dragDepth]
      dragParentPath.splice(-1, 1)
      const hoverParentPath = [...hoverDepth]
      hoverParentPath.splice(-1, 1)
      const isInSameGroup =
        hoverParentPath.join('.') === dragParentPath.join('.')

      const dragIndex = dragDepth[dragDepth.length - 1]
      const hoverIndex = hoverDepth[hoverDepth.length - 1]
      if (hoverIndex === dragIndex && dragDepth.length < 3) {
        return
      }
      // if (
      //   Boolean(hoverIndex === dragIndex && isInSameGroup) ||
      //   Boolean(hoverIndex === dragIndex && dragDepth.length < 3)
      // ) {
      //   return
      // }
      // const hoverBoundingRect = body.current?.getBoundingClientRect()
      // const hoverMiddleX =
      //   (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // const clientOffset = monitor.getClientOffset()
      // const hoverClientX = clientOffset.x - hoverBoundingRect.left
      // if (
      //   dragIndex < hoverIndex &&
      //   hoverClientX < hoverMiddleX &&
      //   isInSameGroup
      // ) {
      //   return
      // }
      // if (
      //   dragIndex > hoverIndex &&
      //   hoverClientX > hoverMiddleX &&
      //   isInSameGroup
      // ) {
      //   return
      // }
      const hoverBoundingRect = body.current?.getBoundingClientRect()
      const isMainGroup = depth.length < 3
      const hoverMiddle = isMainGroup
        ? (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        : (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientValue = isMainGroup
        ? clientOffset.y - hoverBoundingRect.top
        : clientOffset.x - hoverBoundingRect.left
      if (dragIndex < hoverIndex && hoverClientValue < hoverMiddle) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientValue > hoverMiddle) {
        return
      }

      const groupDepth = [...depth]
      groupDepth.splice(-1, 1)
      console.log('moved at card element', [...hoverDepth])
      console.log('drag source', [...dragDepth])
      if (!isInSameGroup) {
        const isMain = Boolean(hoverDepth.length === 2)
        if (isMain) {
          // To avoid disconnecting placeholder with dragged item place dragged card
          // after the hovered one if it is being draged from below
          if (dragDepth[1] > hoverDepth[1]) {
            const insertIndex = hoverDepth[hoverDepth.length - 1]
            hoverDepth.splice(-1, 1, insertIndex + 1)
          }
        }
        // Idk why it prevents crashes but it does
        // setTimeout(() => {
        //   moveItemBetweenGroups(dragDepth, hoverDepth)
        // })
        dispatch({
          type: 'MOVE_ITEM_BETWEEN',
          dragDepth,
          hoverDepth
        })
      } else {
        dispatch({
          dragIndex,
          hoverIndex,
          depth: groupDepth,
          type: 'MOVE_ITEM'
        })
        // setTimeout(() => {
        //   moveItem(dragIndex, hoverIndex, groupDepth)
        // })
      }
      item.depth = [...hoverDepth]
    }
  })

  if (emptySpace) {
    return (
      <Grid item xs={12 / +columns} style={{ opacity: 0 }}>
        <div />
      </Grid>
    )
  }

  const conditionsToPass = JSON.stringify(conditions)
  return (
    <>
      <div ref={isDragLayer ? null : preview} />
      <Grid
        item
        xs={columns ? 12 / +columns : 12}
        style={{
          width: Boolean(depth.length === 2) && '100%'
        }}
      >
        <div ref={isDragLayer ? null : drop} style={{ flex: 1 }}>
          <div ref={isDragLayer ? null : drag}>
            <ElementMemoized
              isHoverOver={isHoveredOverStrict}
              depth={depth.join('.')}
              body={body}
              elementType={elementType}
              conditionsToPass={conditionsToPass}
              dummy={dummy}
              movingInStack={movingInStack}
              width={width}
              selectedParent={selectedParent}
              french={french}
              titleEN={titleEN}
              titleFR={titleFR}
              tooltipEN={props.tooltipEN}
              helpTextEN={props.helpTextEN}
              helpTextFR={props.helpTextFR}
              tooltipFR={props.tooltipFR}
              id={props.id}
              networkId={props.networkId}
              formId={props.formId}
              selected={selected}
              typeProps={typeProps}
              altLabelPlacement={props.altLabelPlacement}
              labelAsMarkdown={props.labelAsMarkdown}
              showBasicElements={props.showBasicElements}
              showCustomElements={props.showCustomElements}
              sectionLength={props.sectionLength}
              editMode={editMode}
              showPrintProps={props.showPrintProps}
              showPdfProps={props.showPdfProps}
              padding={props.padding}
            />
          </div>
        </div>
      </Grid>
    </>
  )
}

const ElementMemoized = React.memo(props => {
  const dispatch = useDispatch()
  const {
    body,
    injectable,
    elementType,
    dummy,
    movingInStack,
    width,
    selectedParent,
    showBasicElements = formElementTypes[elementType].basic,
    showCustomElements = !formElementTypes[elementType].basic,
    injectableId,
    titleEN,
    titleFR,
    tooltipEN,
    tooltipFR,
    helpTextEN,
    helpTextFR,
    selected,
    id,
    isHoverOver,
    labelAsMarkdown,
    altLabelPlacement,
    editMode,
    sectionLength,
    typeProps,
    padding = {},
    french,
    showPrintProps,
    showPdfProps
  } = props
  const { setFieldValue, values } = useFormikContext()
  const depth = props.depth.split('.')
  const index = Number(depth[depth.length - 1])
  const tooltip = french ? tooltipFR : tooltipEN
  let helpText = french ? helpTextFR : helpTextEN
  if (helpText) {
    helpText = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(helpText)
      .toString()
  }
  const { showFieldLabel } = typeProps
  let title = french ? titleFR : titleEN
  if (labelAsMarkdown) {
    title = unified()
      .use(remarkParse)
      .use(remarkRehype, { allowDangerousHtml: true })
      .use(rehypeStringify, { allowDangerousHtml: true })
      .processSync(title)
      .toString()
  }
  const elementData = formElementTypes[elementType]
  const hasLabel =
    !formElementsWithCustomTitleRender.includes(elementType) &&
    !formElementsWithoutDefaultTitle.includes(elementType) &&
    !showFieldLabel

  const paddingStyles = {}
  const paddingKeys = [
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom'
  ]
  paddingKeys.forEach(key => {
    let toSet = formItemPadding
    if (showPdfProps) {
      if (key === 'paddingTop' || key === 'paddingBottom') {
        toSet = 6
      } else {
        toSet = 0
      }
    }
    if (padding[key]) {
      toSet = Number(padding[key])
    }
    paddingStyles[key] = toSet
  })

  return (
    <Card
      ref={body}
      elevation={5}
      style={{
        padding: 5,
        backgroundColor:
          Boolean(isHoverOver && !dummy && !movingInStack) &&
          'rgba(255, 255, 180, 0.7)',
        margin: 10,
        width: width,
        flexGrow: 1,
        opacity: Boolean(movingInStack || dummy) && 0.2,
        border: '1px solid rgba(0, 0, 0, 1)'
      }}
    >
      <Grid container direction='row' justify='space-between' wrap='nowrap'>
        <Grid item container xs style={{ paddingTop: 10, paddingLeft: 10 }}>
          {injectable ? (
            <TextField
              label={<Trans>Name</Trans>}
              fullWidth
              variant='outlined'
              value={injectableId}
              onChange={e => {
                dispatch({
                  type: 'FIELD',
                  injectable,
                  depth,
                  fieldName: 'injectableId',
                  fieldValue: e.currentTarget.value
                })
              }}
            />
          ) : (
            <Checkbox
              disabled={selectedParent}
              checked={Boolean(selected || selectedParent)}
              onChange={e => {
                dispatch({
                  type: 'SELECT_ITEM',
                  depth,
                  select: e.target.checked
                })
              }}
            />
          )}
        </Grid>

        <Grid xs container direction='row' justify='flex-end' item>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(editMode)}
                onChange={e => {
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'editMode',
                    fieldValue: e.target.checked
                  })
                }}
              />
            }
            label={editMode ? <Trans>Edit</Trans> : <Trans>View</Trans>}
          />
          <IconButton
            onClick={() => {
              dispatch({
                object: {
                  titleEN,
                  titleFR,
                  editMode: props.editMode,
                  showBasicElements: props.showBasicElements,
                  elementType: props.elementType,
                  typeProps: props.typeProps,
                  tooltipEN: props.tooltipEN,
                  tooltipFR: props.tooltipFR,
                  altLabelPlacement: props.altLabelPlacement,
                  labelAsMarkdown,
                  padding: padding,
                  conditions:
                    props.conditionsToPass && JSON.parse(props.conditionsToPass)
                },
                type: 'COPY'
              })
            }}
          >
            <Icon>content_copy</Icon>
          </IconButton>
          {(depth.length === 2 || injectable) && (
            <>
              <IconButton
                size='small'
                onClick={e => {
                  dispatch({
                    type: 'MOVE_GROUP_DOWN',
                    injectable,
                    depth: depth
                  })
                }}
                disabled={index === sectionLength - 1}
              >
                <Icon>arrow_downward</Icon>
              </IconButton>
              <IconButton
                size='small'
                onClick={e => {
                  dispatch({
                    type: 'MOVE_GROUP_UP',
                    injectable,
                    depth: depth
                  })
                }}
                disabled={index === 0}
              >
                <Icon>arrow_upward</Icon>
              </IconButton>
            </>
          )}
          <IconButton
            onClick={() => {
              dispatch({
                depth: depth,
                injectable,
                type: 'REMOVE_ITEM'
              })
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </Grid>
      </Grid>
      {!editMode && (
        <Grid
          container
          direction={altLabelPlacement ? 'row' : 'column'}
          style={{ ...paddingStyles }}
        >
          <Grid item>
            <FormElementTitle
              title={hasLabel && title}
              showPdfProps={showPdfProps}
              helpText={!elementData.customHelptext && helpText}
              tooltip={tooltip}
              id={id}
              altLabelPlacement={altLabelPlacement}
              labelAsMarkdown={labelAsMarkdown}
              type={elementType}
            />
            {/* {helpText && (
              <div
                style={{ padding: 10, fontWeight: 300, fontSize: 13 }}
                className={classes.root}
              >
                {ReactHtmlParser(helpText)}
              </div>
            )} */}
          </Grid>
          <div style={{ flex: 1 }}>
            {elementData &&
              elementData.component({
                ...props,
                langFR: french,
                title,
                helpText,
                tooltip
              })}
          </div>
        </Grid>
      )}
      {editMode && (
        <Grid container direction='column' style={{ padding: 15 }}>
          {rendersTitle(elementType) && (
            <>
              <TextField
                style={{ marginBottom: 10 }}
                label={
                  elementType === 'text' ? (
                    <Trans>Text - English</Trans>
                  ) : (
                    <Trans>Title - English</Trans>
                  )
                }
                value={titleEN || ''}
                variant='outlined'
                multiline={elementType === 'text'}
                rows={4}
                onChange={e => {
                  let value = e.currentTarget.value
                  if (
                    e.nativeEvent.inputType &&
                    e.nativeEvent.inputType.startsWith('insertFromPaste')
                  ) {
                    value = e.currentTarget.value.replace(/\r?\n|\r/g, ' ')
                  }
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'titleEN',
                    fieldValue: value
                  })
                }}
                fullWidth
              />
              <TextField
                style={{ marginBottom: 10 }}
                label={
                  elementType === 'text' ? (
                    <Trans>Text - French</Trans>
                  ) : (
                    <Trans>Title - French</Trans>
                  )
                }
                value={titleFR || ''}
                variant='outlined'
                multiline={elementType === 'text'}
                rows={4}
                onChange={e => {
                  let value = e.currentTarget.value
                  if (
                    e.nativeEvent.inputType &&
                    e.nativeEvent.inputType.startsWith('insertFromPaste')
                  ) {
                    value = e.currentTarget.value.replace(/\r?\n|\r/g, ' ')
                  }
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'titleFR',
                    fieldValue: value
                  })
                }}
                fullWidth
              />
            </>
          )}

          <Grid
            style={{ marginTop: 5 }}
            container
            direction='row'
            wrap='nowrap'
          >
            <Grid item xs style={{ marginRight: 10 }}>
              <FormLabel style={{ padding: 3, marginTop: 10 }}>
                <Trans>Element type</Trans>
              </FormLabel>
              <Select
                disabled={!showCustomElements && !showBasicElements}
                style={{ marginBottom: 10 }}
                fullWidth
                variant='outlined'
                onChange={e => {
                  const fieldArray = []
                  const v = e.target.value
                  fieldArray.push({
                    fieldName: 'elementType',
                    fieldValue: v
                  })
                  const conditions = JSON.parse(props.conditionsToPass)
                  if (conditions) {
                    fieldArray.push({
                      fieldName: 'conditions',
                      fieldValue: conditions.filter(condition =>
                        [
                          'hide',
                          'show',
                          'altLabel',
                          'required',
                          'notRequired'
                        ].includes(condition.state)
                      )
                    })
                  }
                  const newDef = formElementTypes[v].defaultValue
                  if (typeof newDef === 'function') {
                    setFieldValue(id, newDef({}, {}, { ...props }))
                  } else {
                    setFieldValue(id, newDef)
                  }
                  fieldArray.push({
                    fieldName: 'typeProps',
                    fieldValue: {}
                  })
                  if (formElementsWithoutTooltip.includes(v)) {
                    fieldArray.push({
                      fieldName: 'tooltipEN',
                      fieldValue: null
                    })
                    fieldArray.push({
                      fieldName: 'tooltipFR',
                      fieldValue: null
                    })
                  }
                  if (!rendersTitle(v)) {
                    fieldArray.push({
                      fieldName: 'titleEN',
                      fieldValue: null
                    })
                    fieldArray.push({
                      fieldName: 'titleFR',
                      fieldValue: null
                    })
                  }
                  if (
                    !formElementsWithCustomTitleRender.includes(v) &&
                    !formElementsWithoutDefaultTitle.includes(v)
                  ) {
                    fieldArray.push({
                      fieldName: 'altLabelPlacement',
                      fieldValue: null
                    })
                  } else {
                    if (!usesMarkdownInRender.includes(v)) {
                      fieldArray.push({
                        fieldName: 'labelAsMarkdown',
                        fieldValue: null
                      })
                    }
                  }
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldArray
                  })
                }}
                value={elementType || ''}
              >
                {Object.keys(formElementTypes)
                  .filter(key => {
                    const obj = formElementTypes[key]
                    if (!showBasicElements && obj.basic) {
                      return false
                    } else if (!showCustomElements && !obj.basic) {
                      return false
                    } else if (showPdfProps && !obj.formComponentPdf) {
                      return false
                    } else if (showPrintProps && !obj.printComponent) {
                      return false
                    } else if (obj.deprecated) {
                      return false
                    } else if (!obj.injectable && injectable) {
                      return false
                    } else if (obj.disabled) {
                      return false
                    } else {
                      return true
                    }
                  })
                  .sort((a, b) => {
                    const obj1 = formElementTypes[a]
                    const obj2 = formElementTypes[b]
                    return obj1.text.localeCompare(obj2.text)
                  })
                  .map((key, index) => {
                    const item = formElementTypes[key]
                    return (
                      <MenuItem value={key} key={index}>
                        {item.text}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Grid>

            <Grid item xs='auto'>
              <Grid
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 10
                }}
                container
                direction='column'
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(showBasicElements)}
                      onChange={e => {
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth,
                          fieldName: 'showBasicElements',
                          fieldValue: e.currentTarget.checked
                        })
                      }}
                    />
                  }
                  label={<Trans>Show basic elements</Trans>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(showCustomElements)}
                      onChange={e => {
                        dispatch({
                          type: 'FIELD',
                          injectable,
                          depth,
                          fieldName: 'showCustomElements',
                          fieldValue: e.currentTarget.checked
                        })
                      }}
                    />
                  }
                  label={<Trans>Show advanced elements</Trans>}
                />
              </Grid>
            </Grid>
          </Grid>

          {Boolean(hasLabel || usesMarkdownInRender.includes(elementType)) && (
            <FormControlLabel
              style={{ marginLeft: 5, marginTop: -5 }}
              control={
                <Checkbox
                  checked={Boolean(labelAsMarkdown)}
                  onChange={e => {
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth,
                      fieldName: 'labelAsMarkdown',
                      fieldValue: e.currentTarget.checked
                    })
                  }}
                />
              }
              label={<Trans>Interpret label as markdown</Trans>}
            />
          )}
          {hasLabel && (
            <FormControlLabel
              style={{ marginLeft: 5, marginTop: -5 }}
              control={
                <Checkbox
                  checked={Boolean(altLabelPlacement)}
                  onChange={e => {
                    dispatch({
                      type: 'FIELD',
                      injectable,
                      depth,
                      fieldName: 'altLabelPlacement',
                      fieldValue: e.currentTarget.checked
                    })
                  }}
                />
              }
              label={<Trans>Place label on the left</Trans>}
            />
          )}
          {Boolean(
            !formElementsWithoutTooltip.includes(elementType) && !showPdfProps
          ) && (
            <div>
              <TextField
                variant='outlined'
                style={{ marginBottom: 10, marginTop: 10 }}
                label={<Trans>Tooltip - English</Trans>}
                value={tooltipEN || ''}
                onChange={e => {
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'tooltipEN',
                    fieldValue: e.currentTarget.value
                  })
                }}
                fullWidth
              />
              <TextField
                variant='outlined'
                style={{ marginBottom: 10 }}
                label={<Trans>Tooltip - French</Trans>}
                value={tooltipFR || ''}
                onChange={e => {
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'tooltipFR',
                    fieldValue: e.currentTarget.value
                  })
                }}
                fullWidth
              />
            </div>
          )}
          {Boolean(!showPdfProps) && (
            <div>
              <TextField
                variant='outlined'
                style={{ marginBottom: 10, marginTop: 10 }}
                label={<Trans>Help text - English</Trans>}
                value={helpTextEN || ''}
                multiline
                onChange={e => {
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'helpTextEN',
                    fieldValue: e.currentTarget.value
                  })
                }}
                fullWidth
              />
              <TextField
                variant='outlined'
                style={{ marginBottom: 10 }}
                label={<Trans>Help text - French</Trans>}
                value={helpTextFR || ''}
                multiline
                onChange={e => {
                  dispatch({
                    type: 'FIELD',
                    injectable,
                    depth,
                    fieldName: 'helpTextFR',
                    fieldValue: e.currentTarget.value
                  })
                }}
                fullWidth
              />
            </div>
          )}
          <Grid container>
            {[
              {
                key: 'paddingLeft',
                label: <Trans>Padding left</Trans>,
                pdf: 0
              },
              {
                key: 'paddingRight',
                label: <Trans>Padding right</Trans>,
                pdf: 0
              },
              { key: 'paddingTop', label: <Trans>Padding top</Trans>, pdf: 6 },
              {
                key: 'paddingBottom',
                label: <Trans>Padding bottom</Trans>,
                pdf: 6
              }
            ].map((obj, index) => {
              const defaultValue = showPdfProps ? obj.pdf : formItemPadding
              return (
                <Grid item xs key={index} style={{ padding: 5 }}>
                  <TextField
                    style={{ marginBottom: 10 }}
                    label={obj.label}
                    value={padding[obj.key] || defaultValue}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatDefault
                    }}
                    variant='outlined'
                    onChange={e => {
                      const toSet = { ...padding }
                      toSet[obj.key] = e.target.value
                      dispatch({
                        type: 'FIELD',
                        injectable,
                        depth,
                        fieldName: 'padding',
                        fieldValue: toSet
                      })
                    }}
                  />
                </Grid>
              )
            })}
          </Grid>

          {Boolean(elementData && elementData.component) && (
            <>
              {elementData.component({
                ...props,
                value: values[id],
                title,
                helpText,
                tooltip
              })}
              {showPrintProps && (
                <FormElementPrintProps elementType={elementType} {...props} />
              )}
            </>
          )}

          {!injectable && (
            <ConditionalElementEditor
              conditions={JSON.parse(props.conditionsToPass)}
              {...props}
            />
          )}
        </Grid>
      )}
    </Card>
  )
})

export const GroupElementWithoutDnD = ElementMemoized
