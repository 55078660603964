import { t, Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Collapse,
  Typography
} from '@material-ui/core'
import {
  fetchConfigurations,
  fetchFormPages,
  saveApplicationConfig,
  deleteApplicationConfig
} from 'app/services/sfAuth/sfData/sfForms'
import Loading from 'egret/components/EgretLoadable/Loading'
import { Field, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isValidJSON } from 'utils'
import ProgressSnackbar from '../page-layouts/CustomSnackbars'
import moment from 'moment'
import { getRecordTypes } from 'app/services/sfAuth/sfData/sfOpportunity'
import { myI18n } from 'translation/I18nConnectedProvider'
import { DateTimePicker } from '@material-ui/pickers'
import { dateFormat, datetimeFormat } from 'app/appSettings'
import { checkPromiseAllSuccess } from '../../services/sfAuth/sfData/jsConnectionUtils'
import {
  FormikCheckboxField,
  FormikCheckboxGroupField
} from 'formik-material-fields'
import { makeStyles } from '@material-ui/styles'
import { setConfigurationData } from 'app/redux/actions/ConfigurationActions'
import { findSurveyTemplatesWithChildren } from 'app/services/sfAuth/sfData/sfSurvey'
import { Alert, Autocomplete } from '@material-ui/lab'
import { CONFIGURATION_APPLICATION_TYPES } from '../../config/configurationMetadata'

const useStyles = makeStyles(theme => ({
  FormGroup_grid: {
    flexDirection: 'row',
    marginLeft: 25,
    justifyContent: 'space-between'
  },
  FormControlLabel_grid: {
    width: '45%'
  },
  HelperText_root: {
    marginLeft: 10,
    marginBottom: 8,
    marginTop: 18,
    fontSize: 14,
    lineHeight: 1
  },
  MuiCheckbox_root: {
    padding: 3
  }
}))

export const configurationTypes = {
  Contract: {
    name: 'CONTRACT'
  },
  'Application Type': {
    name: 'AVAILABLE_APPLICATIONS'
  }
}

export const applicationConfigurationVariables = {
  AVAILABLE_APPLICATIONS: {
    key: 'AVAILABLE_APPLICATIONS',
    type: CONFIGURATION_APPLICATION_TYPES,
    sObject: 'Survey_Templates__c'
  }
}

const ConfigureApplicationTypes = () => {
  const lang = useSelector(state => state.user.language)
  const [formsData, setFormsData] = React.useState([])
  const [initialValues, setInitialValues] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [saving, setSaving] = React.useState(false)
  const [recordTypes, setRecordTypes] = React.useState([])
  const [surveyTemplates, setSurveyTemplates] = React.useState([])
  const classes = useStyles()
  const configurations = useSelector(state => state.configuration)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    if (!initialValues) {
      setLoading(true)
    }
    return Promise.all([
      fetchFormPages(),
      fetchConfigurations(),
      getRecordTypes('Opportunity'),
      findSurveyTemplatesWithChildren({
        language: lang
      })
    ]).then(([forms, configurations, recordTypes, surveyTemplates]) => {
      console.log('got survey tempaltes', surveyTemplates)
      console.log('got forms', forms)
      console.log('got configurations', configurations)
      console.log('got record types', recordTypes)
      setSurveyTemplates(
        Object.values(surveyTemplates).map(obj => ({
          id: obj.id,
          name: obj.name
        }))
      )
      setRecordTypes(
        Object.keys(recordTypes).map(key => {
          const item = recordTypes[key]
          return {
            id: item.recordTypeId,
            name: item.name
          }
        })
      )
      const formsCount = {}
      forms.forEach(form => {
        if (form.origin) {
          if (formsCount[form.origin]) {
            formsCount[form.origin].push(form)
          } else {
            formsCount[form.origin] = [form]
          }
        }
      })
      setFormsData(
        forms
          .map(obj => {
            let name = lang === 'en_US' ? obj.en : obj.fr
            if (obj.config.version && formsCount[obj.origin]) {
              name += ' [v.' + obj.config.version + ']'
            }
            if (obj.comments) {
              name += ' (' + obj.comments + ')'
            }
            return {
              name,
              id: obj.id
            }
          })
          .filter(name => name)
          .sort((a, b) => String(a.name).localeCompare(b.name))
      )
      setInitialValues({
        configList: configurations
          .filter(item => {
            let data = {}
            if (isValidJSON(item.Value__c)) {
              data = JSON.parse(item.Value__c) || {}
            }
            return (
              configurationTypes[data.type] ||
              item.Name === 'AVAILABLE_APPLICATIONS'
            )
          })
          .map((item, index) => {
            let data = {}
            if (isValidJSON(item.Value__c)) {
              data = JSON.parse(item.Value__c) || {}
            }
            if (data.startTime) {
              const localValue = moment(data.startTime).utc(true)
              data.startTime = localValue
            }
            if (data.endTime) {
              const localValue = moment(data.endTime).utc(true)
              data.endTime = localValue
            }
            if (!data.nameEN) {
              data.nameEN = item.Name
            }
            delete data.expanded
            if (!data.disableInProvinces) {
              data.disableInProvinces = []
            }
            let order = data.order
            if (!order && order !== 0) {
              order = index
            }
            return {
              id: item.Id,
              ...data,
              order
            }
          })
          .sort((a, b) => a.order - b.order)
      })
      setLoading(false)
    })
  }

  const handleSave = values => {
    const savingSnackbar = enqueueSnackbar(null, {
      persist: true,
      content: key => ProgressSnackbar(<Trans>Saving</Trans>)
    })
    setSaving(true)
    return saveApplicationConfig(values).then(
      result => {
        console.log('saved configuration', result)
        if (!checkPromiseAllSuccess(result)) {
          return fetchData().then(() => {
            setSaving(false)
            closeSnackbar(savingSnackbar)
            enqueueSnackbar(<Trans>Successfully saved!</Trans>, {
              variant: 'success'
            })
            const toSet = { ...configurations }
            toSet.AVAILABLE_APPLICATIONS = values.configList
            dispatch(setConfigurationData(toSet))
          })
        } else {
          enqueueSnackbar(<Trans>Error occurred while saving!</Trans>, {
            variant: 'error'
          })
          setSaving(false)
          closeSnackbar(savingSnackbar)
          return Promise.reject()
        }
      },
      reject => {
        console.error('rejected configuration', reject)
        setSaving(false)
        closeSnackbar(savingSnackbar)
        enqueueSnackbar(<Trans>Error occurred while saving!</Trans>, {
          variant: 'error'
        })
      }
    )
  }

  if (loading || !initialValues) {
    return <Loading />
  }

  const { formatToTimeZone } = require('date-fns-timezone')

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {({ values, setValues, setFieldValue }) => {
        const { configList } = values
        console.log('configurations', values)
        return (
          <div style={{ padding: 20 }}>
            <Grid container direction='row' justify='space-between'>
              <Button
                variant='contained'
                color='primary'
                onClick={e => {
                  setFieldValue('configList', [
                    ...configList,
                    {
                      disableInProvinces: []
                    }
                  ])
                }}
              >
                <Trans>Add new configuration</Trans>
                <Icon>add</Icon>
              </Button>

              <Button
                disabled={saving}
                variant='contained'
                color='primary'
                onClick={() => {
                  handleSave(values)
                }}
              >
                <Trans>Save</Trans>
                <Icon style={{ marginLeft: 5 }}>save</Icon>
              </Button>
            </Grid>
            <Alert severity='info' style={{ marginTop: 10 }}>
              <Trans>
                Applications in create application dialog will appear in order
                in which they are sorted on this list
              </Trans>
            </Alert>
            <List>
              {configList.map((item, index) => {
                const { expanded, type } = item
                const appType = type === 'Application Type'
                return (
                  <ListItem key={index}>
                    <Card
                      style={{
                        flexGrow: 1,
                        padding: 20,
                        border: '1px solid #C4C4C4'
                      }}
                    >
                      <Grid justify='space-between' container wrap='nowrap'>
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              `configList.${index}.expanded`,
                              !expanded
                            )
                          }}
                        >
                          <Icon>
                            {expanded ? 'expand_less' : 'expand_more'}
                          </Icon>
                        </IconButton>

                        <Grid item>
                          <IconButton
                            disabled={index === 0}
                            onClick={() => {
                              const toSet = [...configList]
                              const toMove = toSet[index]
                              const toReplace = toSet[index - 1]
                              toSet[index - 1] = toMove
                              toSet[index] = toReplace
                              setFieldValue('configList', toSet)
                            }}
                          >
                            <Icon>arrow_upward</Icon>
                          </IconButton>
                          <IconButton
                            disabled={index + 1 === configList.length}
                            onClick={() => {
                              const toSet = [...configList]
                              const toMove = toSet[index]
                              const toReplace = toSet[index + 1]
                              toSet[index + 1] = toMove
                              toSet[index] = toReplace
                              setFieldValue('configList', toSet)
                            }}
                          >
                            <Icon>arrow_downward</Icon>
                          </IconButton>
                          <DeleteConfigurationButton
                            handleDelete={e => {
                              const toDelete = configList[index]
                              const toSet = [...configList]
                              if (!toDelete.id) {
                                toSet.splice(index, 1)
                                setFieldValue('configList', toSet)
                                enqueueSnackbar(
                                  <Trans>Configuration deleted!</Trans>,
                                  {
                                    variant: 'success'
                                  }
                                )
                                return Promise.resolve()
                              } else {
                                return deleteApplicationConfig(
                                  toDelete.id
                                ).then(
                                  result => {
                                    return fetchData().then(() => {
                                      enqueueSnackbar(
                                        <Trans>Configuration deleted!</Trans>,
                                        {
                                          variant: 'success'
                                        }
                                      )
                                      toSet.splice(index, 1)
                                      setFieldValue('configList', toSet)
                                    })
                                  },
                                  reject => {
                                    enqueueSnackbar(
                                      <Trans>
                                        Error occurred while deleting!
                                      </Trans>,
                                      {
                                        variant: 'error'
                                      }
                                    )
                                  }
                                )
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6} style={{ padding: 5 }}>
                          <Field name={`configList.${index}.nameEN`}>
                            {({ field }) => {
                              return (
                                <TextField
                                  {...field}
                                  value={field.value || ''}
                                  label={<Trans>Name - english</Trans>}
                                  variant='outlined'
                                  fullWidth
                                />
                              )
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={6} style={{ padding: 5 }}>
                          <Field name={`configList.${index}.nameFR`}>
                            {({ field }) => {
                              return (
                                <TextField
                                  {...field}
                                  value={field.value || ''}
                                  label={<Trans>Name - french</Trans>}
                                  variant='outlined'
                                  fullWidth
                                />
                              )
                            }}
                          </Field>
                        </Grid>
                      </Grid>

                      {/* <Field name={`configList.${index}.type`}>
                        {({ field }) => {
                          return (
                            <div style={{ padding: 5 }}>
                              <TextField
                                {...field}
                                value={field.value || ''}
                                onChange={e => {
                                  const value = e.target.value
                                  const newValues = { ...values }
                                  newValues.configList[index].type = value
                                  if (value === 'Contract') {
                                    delete newValues.configList[index].quiz
                                    delete newValues.configList[index]
                                      .moreInfoUrlEN
                                    delete newValues.configList[index]
                                      .moreInfoUrlFR
                                    delete newValues.configList[index].logo
                                    delete newValues.configList[index]
                                      .recordType
                                  } else {
                                  }
                                  setValues(newValues)
                                }}
                                label={<Trans>Configuration type</Trans>}
                                select
                                fullWidth
                                variant='outlined'
                              >
                                {Object.keys(configurationTypes).map(
                                  (item, index) => (
                                    <MenuItem key={index} value={item}>
                                      {myI18n._(t`${item}`)}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </div>
                          )
                        }}
                      </Field> */}

                      <Collapse in={expanded}>
                        <Grid container direction='column'>
                          <Field name={`configList.${index}.form`}>
                            {({ field, form: { setFieldValue } }) => {
                              return (
                                <Grid container wrap='nowrap'>
                                  <TextField
                                    {...field}
                                    value={field.value || ''}
                                    label={<Trans>Form</Trans>}
                                    style={{ margin: 5 }}
                                    select
                                    fullWidth
                                    variant='outlined'
                                  >
                                    {formsData.map((item, index) => (
                                      <MenuItem key={index} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <IconButton
                                    onClick={e => {
                                      setFieldValue(
                                        `configList.${index}.form`,
                                        null
                                      )
                                    }}
                                  >
                                    <Icon>close</Icon>
                                  </IconButton>
                                </Grid>
                              )
                            }}
                          </Field>

                          {appType && (
                            <div style={{ width: '100%' }}>
                              <FormSelectField
                                label={<Trans>Quiz</Trans>}
                                name={`configList.${index}.quiz`}
                                formsData={formsData}
                              />

                              <FormSelectField
                                label={<Trans>Print form</Trans>}
                                name={`configList.${index}.printForm`}
                                formsData={formsData}
                              />

                              <FormSelectField
                                label={
                                  <Trans>Grantee report form (preview)</Trans>
                                }
                                name={`configList.${index}.granteeReportForm`}
                                formsData={formsData}
                              />

                              <FormSelectField
                                label={
                                  <Trans>Grantee report survey template</Trans>
                                }
                                name={`configList.${index}.granteeReportSurveyTemplate`}
                                formsData={surveyTemplates}
                              />

                              <Typography
                                style={{ padding: 10, fontWeight: 'bold' }}
                              >
                                <Trans>Internal</Trans>
                              </Typography>
                              <FormSelectField
                                label={<Trans>Print preview</Trans>}
                                name={`configList.${index}.internalPrintPreview`}
                                formsData={formsData}
                              />

                              <Typography
                                style={{ padding: 10, fontWeight: 'bold' }}
                              >
                                <Trans>Program Manager</Trans>
                              </Typography>

                              <FormSelectField
                                label={<Trans>Contract</Trans>}
                                name={`configList.${index}.contract`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={<Trans>Program manager - preview</Trans>}
                                name={`configList.${index}.previewPM`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>Selection Committee preview</Trans>
                                }
                                name={`configList.${index}.SC`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>Allocation Committee preview</Trans>
                                }
                                name={`configList.${index}.AC`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>Board executive summary preview</Trans>
                                }
                                name={`configList.${index}.Board`}
                                formsData={formsData}
                              />
                              <FormSelectField
                                label={
                                  <Trans>CMHC executive summary preview</Trans>
                                }
                                name={`configList.${index}.CMHC`}
                                formsData={formsData}
                              />

                              <Field name={`configList.${index}.recordType`}>
                                {({ field }) => {
                                  return (
                                    <div style={{ padding: 5 }}>
                                      <TextField
                                        {...field}
                                        value={field.value || ''}
                                        label={<Trans>Record type</Trans>}
                                        style={{ marginBottom: 10 }}
                                        select
                                        fullWidth
                                        variant='outlined'
                                      >
                                        {recordTypes.map((item, index) => (
                                          <MenuItem key={index} value={item.id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </div>
                                  )
                                }}
                              </Field>
                            </div>
                          )}

                          <div>
                            <FormikCheckboxGroupField
                              label={<Trans>Disable in provinces</Trans>}
                              style={{ padding: 10, width: '100%' }}
                              name={`configList.${index}.disableInProvinces`}
                              multiple
                              FormGroupProps={{
                                classes: {
                                  root: classes.FormGroup_grid
                                }
                              }}
                              FormControlLabelProps={{
                                classes: {
                                  root: classes.FormControlLabel_grid
                                }
                              }}
                              CheckboxProps={{
                                classes: {
                                  root: classes.MuiCheckbox_root
                                }
                              }}
                              FormHelperTextProps={{
                                classes: {
                                  root: classes.HelperText_root
                                }
                              }}
                              options={[
                                {
                                  label: <Trans>Alberta</Trans>,
                                  value: 'Alberta'
                                },
                                {
                                  label: <Trans>British Columbia</Trans>,
                                  value: 'British Columbia'
                                },
                                {
                                  label: <Trans>Manitoba</Trans>,
                                  value: 'Manitoba'
                                },
                                {
                                  label: <Trans>New Brunswick</Trans>,
                                  value: 'New Brunswick'
                                },
                                {
                                  label: (
                                    <Trans>Newfoundland and Labrador</Trans>
                                  ),
                                  value: 'Newfoundland and Labrador'
                                },
                                {
                                  label: <Trans>Northwest Territories</Trans>,
                                  value: 'Northwest Territories'
                                },
                                {
                                  label: <Trans>Nova Scotia</Trans>,
                                  value: 'Nova Scotia'
                                },
                                {
                                  label: <Trans>Nunavut</Trans>,
                                  value: 'Nunavut'
                                },
                                {
                                  label: <Trans>Ontario</Trans>,
                                  value: 'Ontario'
                                },
                                {
                                  label: <Trans>Prince Edward Island</Trans>,
                                  value: 'Prince Edward Island'
                                },
                                {
                                  label: <Trans>Québec</Trans>,
                                  value: 'Québec'
                                },
                                {
                                  label: <Trans>Saskatchewan</Trans>,
                                  value: 'Saskatchewan'
                                },
                                {
                                  label: <Trans>Yukon</Trans>,
                                  value: 'Yukon'
                                }
                              ]}
                            />
                          </div>

                          <div style={{ padding: 5 }}>
                            <b>
                              <Trans>Your time zone:</Trans>
                            </b>{' '}
                            {Intl.DateTimeFormat().resolvedOptions().timeZone}
                            {' ('}
                            {new Date().getTimezoneOffset()}
                            {')'}
                          </div>

                          <Grid container>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.startTime`}>
                                {({ field, form }) => {
                                  return (
                                    <>
                                      <Grid
                                        container
                                        direction='row'
                                        wrap='nowrap'
                                      >
                                        <DateTimePicker
                                          {...field}
                                          format={datetimeFormat}
                                          value={field.value || null}
                                          onChange={e => {
                                            form.setFieldValue(
                                              `configList.${index}.startTime`,
                                              e
                                            )
                                          }}
                                          fullWidth
                                          inputVariant='outlined'
                                          label={<Trans>Validity start</Trans>}
                                        />
                                        <div style={{ padding: 5 }}>
                                          <IconButton
                                            onClick={e => {
                                              form.setFieldValue(
                                                `configList.${index}.startTime`,
                                                null
                                              )
                                            }}
                                          >
                                            <Icon>close</Icon>
                                          </IconButton>
                                        </div>
                                      </Grid>
                                      {field.value && (
                                        <div style={{ padding: 5 }}>
                                          <b>
                                            <Trans>In Pacific time:</Trans>
                                          </b>{' '}
                                          {formatToTimeZone(
                                            moment.utc(field.value).local(true),
                                            datetimeFormat,
                                            {
                                              timeZone: 'Canada/Pacific'
                                            }
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )
                                }}
                              </Field>
                            </Grid>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.endTime`}>
                                {({ field, form }) => {
                                  return (
                                    <>
                                      <Grid
                                        container
                                        direction='row'
                                        wrap='nowrap'
                                      >
                                        <DateTimePicker
                                          {...field}
                                          format={dateFormat + ' h:mm a'}
                                          value={field.value || null}
                                          onChange={e => {
                                            form.setFieldValue(
                                              `configList.${index}.endTime`,
                                              e
                                            )
                                          }}
                                          fullWidth
                                          inputVariant='outlined'
                                          label={<Trans>Validity end</Trans>}
                                        />
                                        <div style={{ padding: 5 }}>
                                          <IconButton
                                            onClick={e => {
                                              form.setFieldValue(
                                                `configList.${index}.endTime`,
                                                null
                                              )
                                            }}
                                          >
                                            <Icon>close</Icon>
                                          </IconButton>
                                        </div>
                                      </Grid>
                                      {field.value && (
                                        <div style={{ padding: 5 }}>
                                          <b>
                                            <Trans>In Pacific time:</Trans>
                                          </b>{' '}
                                          {formatToTimeZone(
                                            moment.utc(field.value).local(true),
                                            datetimeFormat,
                                            {
                                              timeZone: 'Canada/Pacific'
                                            }
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )
                                }}
                              </Field>
                            </Grid>
                          </Grid>

                          <div>
                            <FormikCheckboxField
                              name={`configList.${index}.inTesting`}
                              style={{ marginLeft: 10 }}
                              FormControlLabelProps={{ labelPlacement: 'end' }}
                              controlLabel={<Trans>In testing?</Trans>}
                            />
                            <FormikCheckboxField
                              name={`configList.${index}.obsolete`}
                              style={{ marginLeft: 10 }}
                              FormControlLabelProps={{ labelPlacement: 'end' }}
                              controlLabel={<Trans>Is obsolete?</Trans>}
                            />
                          </div>

                          <Grid container>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.subtitleEN`}>
                                {({ field }) => {
                                  return (
                                    <TextField
                                      {...field}
                                      value={field.value || ''}
                                      label={<Trans>Subtitle - english</Trans>}
                                      variant='outlined'
                                      fullWidth
                                    />
                                  )
                                }}
                              </Field>
                            </Grid>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.subtitleFR`}>
                                {({ field }) => {
                                  return (
                                    <TextField
                                      {...field}
                                      value={field.value || ''}
                                      label={<Trans>Subtitle - french</Trans>}
                                      variant='outlined'
                                      fullWidth
                                    />
                                  )
                                }}
                              </Field>
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.descriptionEN`}>
                                {({ field }) => {
                                  return (
                                    <TextField
                                      {...field}
                                      multiline
                                      rows={6}
                                      value={field.value || ''}
                                      label={
                                        <Trans>Description - english</Trans>
                                      }
                                      variant='outlined'
                                      fullWidth
                                    />
                                  )
                                }}
                              </Field>
                            </Grid>
                            <Grid xs={6} item style={{ padding: 5 }}>
                              <Field name={`configList.${index}.descriptionFR`}>
                                {({ field }) => {
                                  return (
                                    <TextField
                                      {...field}
                                      multiline
                                      rows={6}
                                      value={field.value || ''}
                                      label={
                                        <Trans>Description - french</Trans>
                                      }
                                      variant='outlined'
                                      fullWidth
                                    />
                                  )
                                }}
                              </Field>
                            </Grid>
                          </Grid>

                          {appType && (
                            <div style={{ width: '100%' }}>
                              <Grid container>
                                <Grid xs={6} item style={{ padding: 5 }}>
                                  <Field
                                    name={`configList.${index}.moreInfoUrlEN`}
                                  >
                                    {({ field }) => {
                                      return (
                                        <TextField
                                          {...field}
                                          value={field.value || ''}
                                          label={
                                            <Trans>
                                              More info url - english
                                            </Trans>
                                          }
                                          variant='outlined'
                                          fullWidth
                                        />
                                      )
                                    }}
                                  </Field>
                                </Grid>
                                <Grid xs={6} item style={{ padding: 5 }}>
                                  <Field
                                    name={`configList.${index}.moreInfoUrlFR`}
                                  >
                                    {({ field }) => {
                                      return (
                                        <TextField
                                          {...field}
                                          value={field.value || ''}
                                          label={
                                            <Trans>
                                              More info url - french
                                            </Trans>
                                          }
                                          variant='outlined'
                                          fullWidth
                                        />
                                      )
                                    }}
                                  </Field>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                direction='column'
                                style={{ padding: 5, flexGrow: 1 }}
                                justify='center'
                                alignContent='center'
                                alignItems='center'
                              >
                                <Field name={`configList.${index}.logo`}>
                                  {({ field }) => {
                                    return (
                                      <TextField
                                        {...field}
                                        value={field.value || ''}
                                        label={<Trans>Logo url</Trans>}
                                        variant='outlined'
                                        fullWidth
                                      />
                                    )
                                  }}
                                </Field>
                                <Typography
                                  style={{ fontWeight: 'bold', margin: 5 }}
                                >
                                  <Trans>Preview:</Trans>
                                </Typography>
                                <img
                                  src={item.logo}
                                  style={{
                                    borderRadius: 10,
                                    maxHeight: 250,
                                    maxWidth: 250
                                  }}
                                />
                              </Grid>
                            </div>
                          )}
                        </Grid>
                      </Collapse>
                    </Card>
                  </ListItem>
                )
              })}
            </List>
          </div>
        )
      }}
    </Formik>
  )
}

export const FormSelectField = ({ name, formsData, label }) => {
  const idToLabel = {}
  formsData.forEach(obj => {
    idToLabel[obj.id] = obj.name
  })

  return (
    <Field name={name}>
      {({ field, form }) => {
        const { setFieldValue } = form
        return (
          <div style={{ padding: 5 }}>
            <Autocomplete
              {...field}
              freeSolo={false}
              value={field.value || ''}
              fullWidth
              getOptionLabel={opt => idToLabel[opt] || ''}
              options={formsData.map((item, index) => item.id)}
              onChange={(e, value) => {
                setFieldValue(name, value)
              }}
              renderInput={params => (
                <TextField variant='outlined' {...params} label={label} />
              )}
            />
          </div>
        )
      }}
    </Field>
  )
}

const DeleteConfigurationButton = ({ handleDelete }) => {
  const [dialogOpen, openDialog] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setDeleting(false)
  }, [dialogOpen])

  return (
    <>
      <Dialog open={dialogOpen}>
        <div style={{ width: 600 }}>
          <DialogTitle>
            <Trans>Are you sure you want to delete that section?</Trans>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction='row'
              justify='space-evenly'
              alignItems='center'
            >
              <Button
                disabled={deleting}
                variant='contained'
                color='primary'
                onClick={e => {
                  e.stopPropagation()
                  setDeleting(true)
                  enqueueSnackbar(<Trans>Deleting</Trans>, {
                    variant: 'info'
                  })
                  handleDelete().then(r => {
                    openDialog(false)
                  })
                }}
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justify='space-evenly'
                >
                  <Trans>Yes</Trans>
                  <Icon style={{ marginLeft: 5 }}>done</Icon>
                </Grid>
              </Button>
              <Button
                disabled={deleting}
                variant='outlined'
                color='primary'
                onClick={e => {
                  openDialog(false)
                }}
              >
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justify='space-evenly'
                >
                  <Trans>No</Trans>
                  <Icon style={{ marginLeft: 5 }}>close</Icon>
                </Grid>
              </Button>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
      <IconButton
        onClick={() => {
          openDialog(true)
        }}
      >
        <Icon>delete</Icon>
      </IconButton>
    </>
  )
}

export default ConfigureApplicationTypes
