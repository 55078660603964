// package: Mo_multiuser
// file: Multiuser.proto

var Multiuser_pb = require("./Multiuser_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Multiuser = (function () {
  function Multiuser() {}
  Multiuser.serviceName = "Mo_multiuser.Multiuser";
  return Multiuser;
}());

Multiuser.UserStartedEditingForm = {
  methodName: "UserStartedEditingForm",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UserInfo
};

Multiuser.UserExitedEditingForm = {
  methodName: "UserExitedEditingForm",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UserInfo
};

Multiuser.UserUpdatedInfoAboutSelf = {
  methodName: "UserUpdatedInfoAboutSelf",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.FormAndUserInfoReceive
};

Multiuser.UsersCountInFormChanged = {
  methodName: "UsersCountInFormChanged",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UsersCount
};

Multiuser.UserChangedLockFieldStatus = {
  methodName: "UserChangedLockFieldStatus",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.FieldLockOperation
};

Multiuser.UserCommitedFieldAsynchronously = {
  methodName: "UserCommitedFieldAsynchronously",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.LockID
};

Multiuser.UserSubmitedFormCache = {
  methodName: "UserSubmitedFormCache",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.FormCacheReceive
};

Multiuser.UserRequestedSFSave = {
  methodName: "UserRequestedSFSave",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.RequestSFSaveReceive
};

Multiuser.UserCompletedSaveToSF = {
  methodName: "UserCompletedSaveToSF",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.SaveToSFCompleted
};

Multiuser.ServerRequestSubmitCache = {
  methodName: "ServerRequestSubmitCache",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UserID
};

Multiuser.ServerRequestSubmitToSF = {
  methodName: "ServerRequestSubmitToSF",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UserID
};

Multiuser.UserEndedToSubmitToSF = {
  methodName: "UserEndedToSubmitToSF",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UserOperation
};

Multiuser.UserSentCursorEvent = {
  methodName: "UserSentCursorEvent",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.CursorEventReceive
};

Multiuser.UserChangeFieldComment = {
  methodName: "UserChangeFieldComment",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.FieldCommentOperation
};

Multiuser.UserSentChatMessage = {
  methodName: "UserSentChatMessage",
  service: Multiuser,
  requestStream: false,
  responseStream: true,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.ChatMessage
};

Multiuser.GetUserInfo = {
  methodName: "GetUserInfo",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndUser,
  responseType: Multiuser_pb.UserSessionInfo
};

Multiuser.GetUsersCount = {
  methodName: "GetUsersCount",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UsersCount
};

Multiuser.GetAllConnectedUsers = {
  methodName: "GetAllConnectedUsers",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UsersSessionsList
};

Multiuser.GetAllConnectedUsersWithInfo = {
  methodName: "GetAllConnectedUsersWithInfo",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.UserSessionsInfoList
};

Multiuser.IsFormInited = {
  methodName: "IsFormInited",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.IsTrue
};

Multiuser.InitForm = {
  methodName: "InitForm",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.Form,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.StartEditingForm = {
  methodName: "StartEditingForm",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndUserInfo,
  responseType: Multiuser_pb.FormState
};

Multiuser.ExitEditingForm = {
  methodName: "ExitEditingForm",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.UpdateUserInfo = {
  methodName: "UpdateUserInfo",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndUserInfo,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.Ping = {
  methodName: "Ping",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.Time
};

Multiuser.IsFieldLocked = {
  methodName: "IsFieldLocked",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndField,
  responseType: Multiuser_pb.IsTrue
};

Multiuser.IsLockCommited = {
  methodName: "IsLockCommited",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.LockPointer,
  responseType: Multiuser_pb.LockStateType
};

Multiuser.GetUserLockingField = {
  methodName: "GetUserLockingField",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndField,
  responseType: Multiuser_pb.UserID
};

Multiuser.GetIDsOfLocksNotSubmitedToCache = {
  methodName: "GetIDsOfLocksNotSubmitedToCache",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.LocksListLite
};

Multiuser.GetLocksNotSubmitedToCache = {
  methodName: "GetLocksNotSubmitedToCache",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.LocksList
};

Multiuser.GetIDsOfLocksForField = {
  methodName: "GetIDsOfLocksForField",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndField,
  responseType: Multiuser_pb.LocksListLite
};

Multiuser.GetLocksForField = {
  methodName: "GetLocksForField",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndField,
  responseType: Multiuser_pb.LocksList
};

Multiuser.GetIDsOfLockedFields = {
  methodName: "GetIDsOfLockedFields",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.LocksListLite
};

Multiuser.GetLockedFields = {
  methodName: "GetLockedFields",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.LocksList
};

Multiuser.LockField = {
  methodName: "LockField",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndField,
  responseType: Multiuser_pb.LockID
};

Multiuser.UpdateLockedFieldValue = {
  methodName: "UpdateLockedFieldValue",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndFieldAndLockIDAndContent,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.CommitLockedField = {
  methodName: "CommitLockedField",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndFieldAndLockIDAndContent,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.CancelLockedField = {
  methodName: "CancelLockedField",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndFieldAndLockID,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.CommitFieldImmediately = {
  methodName: "CommitFieldImmediately",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndFieldAndFieldValue,
  responseType: Multiuser_pb.LockID
};

Multiuser.CreateAsynchronousLock = {
  methodName: "CreateAsynchronousLock",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.AsyncLock,
  responseType: Multiuser_pb.LockID
};

Multiuser.MassiveCommitFieldsImmediately = {
  methodName: "MassiveCommitFieldsImmediately",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormAndFieldsAndValues,
  responseType: Multiuser_pb.FieldsAndLocks
};

Multiuser.GetListOfCachedFormVersions = {
  methodName: "GetListOfCachedFormVersions",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.FormCacheListLite
};

Multiuser.GetFormCacheByID = {
  methodName: "GetFormCacheByID",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormCachePointer,
  responseType: Multiuser_pb.FormCache
};

Multiuser.GetNewestFormCache = {
  methodName: "GetNewestFormCache",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormIDString,
  responseType: Multiuser_pb.FormCache
};

Multiuser.SubmitFormCache = {
  methodName: "SubmitFormCache",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormToSubmit,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.ReportSubmitToSF = {
  methodName: "ReportSubmitToSF",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FormOperation,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.ReportSaveToSFCompleted = {
  methodName: "ReportSaveToSFCompleted",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.SaveToSFCompletedReport,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.RequestSFSave = {
  methodName: "RequestSFSave",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.RequestSFSaveMessage,
  responseType: Multiuser_pb.RequestSFSaveResponde
};

Multiuser.SendCursorEvent = {
  methodName: "SendCursorEvent",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.CursorEvent,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.GetFieldComment = {
  methodName: "GetFieldComment",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentID,
  responseType: Multiuser_pb.FieldComment
};

Multiuser.WhoIsLockingFieldComment = {
  methodName: "WhoIsLockingFieldComment",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentID,
  responseType: Multiuser_pb.UserID
};

Multiuser.LockFieldComment = {
  methodName: "LockFieldComment",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentToSend,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.UpdateFieldComment = {
  methodName: "UpdateFieldComment",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentToSend,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.CommitFieldComment = {
  methodName: "CommitFieldComment",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.FieldCommentToSend,
  responseType: google_protobuf_empty_pb.Empty
};

Multiuser.GetChatMessages = {
  methodName: "GetChatMessages",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.ChatMessageCollectionRequest,
  responseType: Multiuser_pb.ChatMessageCollection
};

Multiuser.GetChatMessagesCount = {
  methodName: "GetChatMessagesCount",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.ChatMessageCollectionRequest,
  responseType: Multiuser_pb.MessageCount
};

Multiuser.SendChatMessage = {
  methodName: "SendChatMessage",
  service: Multiuser,
  requestStream: false,
  responseStream: false,
  requestType: Multiuser_pb.ChatMessageSend,
  responseType: google_protobuf_empty_pb.Empty
};

exports.Multiuser = Multiuser;

function MultiuserClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MultiuserClient.prototype.userStartedEditingForm = function userStartedEditingForm(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserStartedEditingForm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userExitedEditingForm = function userExitedEditingForm(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserExitedEditingForm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userUpdatedInfoAboutSelf = function userUpdatedInfoAboutSelf(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserUpdatedInfoAboutSelf, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.usersCountInFormChanged = function usersCountInFormChanged(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UsersCountInFormChanged, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userChangedLockFieldStatus = function userChangedLockFieldStatus(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserChangedLockFieldStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userCommitedFieldAsynchronously = function userCommitedFieldAsynchronously(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserCommitedFieldAsynchronously, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userSubmitedFormCache = function userSubmitedFormCache(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserSubmitedFormCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userRequestedSFSave = function userRequestedSFSave(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserRequestedSFSave, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userCompletedSaveToSF = function userCompletedSaveToSF(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserCompletedSaveToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.serverRequestSubmitCache = function serverRequestSubmitCache(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.ServerRequestSubmitCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.serverRequestSubmitToSF = function serverRequestSubmitToSF(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.ServerRequestSubmitToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userEndedToSubmitToSF = function userEndedToSubmitToSF(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserEndedToSubmitToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userSentCursorEvent = function userSentCursorEvent(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserSentCursorEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userChangeFieldComment = function userChangeFieldComment(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserChangeFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.userSentChatMessage = function userSentChatMessage(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Multiuser.UserSentChatMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getUserInfo = function getUserInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetUserInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getUsersCount = function getUsersCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetUsersCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getAllConnectedUsers = function getAllConnectedUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetAllConnectedUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getAllConnectedUsersWithInfo = function getAllConnectedUsersWithInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetAllConnectedUsersWithInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.isFormInited = function isFormInited(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.IsFormInited, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.initForm = function initForm(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.InitForm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.startEditingForm = function startEditingForm(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.StartEditingForm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.exitEditingForm = function exitEditingForm(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.ExitEditingForm, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.updateUserInfo = function updateUserInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.UpdateUserInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.ping = function ping(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.Ping, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.isFieldLocked = function isFieldLocked(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.IsFieldLocked, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.isLockCommited = function isLockCommited(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.IsLockCommited, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getUserLockingField = function getUserLockingField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetUserLockingField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getIDsOfLocksNotSubmitedToCache = function getIDsOfLocksNotSubmitedToCache(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetIDsOfLocksNotSubmitedToCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getLocksNotSubmitedToCache = function getLocksNotSubmitedToCache(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetLocksNotSubmitedToCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getIDsOfLocksForField = function getIDsOfLocksForField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetIDsOfLocksForField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getLocksForField = function getLocksForField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetLocksForField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getIDsOfLockedFields = function getIDsOfLockedFields(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetIDsOfLockedFields, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getLockedFields = function getLockedFields(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetLockedFields, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.lockField = function lockField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.LockField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.updateLockedFieldValue = function updateLockedFieldValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.UpdateLockedFieldValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.commitLockedField = function commitLockedField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.CommitLockedField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.cancelLockedField = function cancelLockedField(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.CancelLockedField, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.commitFieldImmediately = function commitFieldImmediately(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.CommitFieldImmediately, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.createAsynchronousLock = function createAsynchronousLock(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.CreateAsynchronousLock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.massiveCommitFieldsImmediately = function massiveCommitFieldsImmediately(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.MassiveCommitFieldsImmediately, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getListOfCachedFormVersions = function getListOfCachedFormVersions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetListOfCachedFormVersions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getFormCacheByID = function getFormCacheByID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetFormCacheByID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getNewestFormCache = function getNewestFormCache(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetNewestFormCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.submitFormCache = function submitFormCache(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.SubmitFormCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.reportSubmitToSF = function reportSubmitToSF(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.ReportSubmitToSF, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.reportSaveToSFCompleted = function reportSaveToSFCompleted(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.ReportSaveToSFCompleted, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.requestSFSave = function requestSFSave(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.RequestSFSave, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.sendCursorEvent = function sendCursorEvent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.SendCursorEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getFieldComment = function getFieldComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.whoIsLockingFieldComment = function whoIsLockingFieldComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.WhoIsLockingFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.lockFieldComment = function lockFieldComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.LockFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.updateFieldComment = function updateFieldComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.UpdateFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.commitFieldComment = function commitFieldComment(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.CommitFieldComment, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getChatMessages = function getChatMessages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetChatMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.getChatMessagesCount = function getChatMessagesCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.GetChatMessagesCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MultiuserClient.prototype.sendChatMessage = function sendChatMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Multiuser.SendChatMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MultiuserClient = MultiuserClient;

