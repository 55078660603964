import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import {
  setAvaliableOrganizations,
  setOrganization
} from '../redux/actions/OrganizationActions'
import { setUserData } from '../redux/actions/UserActions'
import { setCaseTypes } from '../redux/actions/CaseDataActions'
import localStorageService from '../services/localStorageService'
import sfAuthService from '../services/sfAuth/SFAuthService'
import { userLoggedIn, userLoggedOut } from '../redux/actions/LoginActions'
import { withRouter } from 'react-router'
import { setConfigurationData } from '../redux/actions/ConfigurationActions'
import { Typography } from '@material-ui/core'
import { setTutorialsFromSteps } from 'app/redux/actions/TutorialsActions'
import sfOauthConfig from '../services/sfAuth/sfAuthConfig'

class Auth extends Component {
  state = {}

  constructor (props) {
    super(props)

    let user = localStorageService.getItem('auth_user')
    this.props.setUserData(user)

    // this.checkJwtAuth();
    // this.checkFirebaseAuth();
    if (!props.maintenance) {
      this.checkSfAuth(user)
    }
  }

  // checkJwtAuth = () => {
  //   jwtAuthService.loginWithToken().then(user => {
  //     this.props.setUserData(user)
  //   })
  // }

  checkSfAuth = user => {
    let { location, history, login } = this.props
    let sentState = {
      redirect_uri: location.pathname
    }
    console.log('match', location, login, user, user && user.access_token)
    if (login.success) {
      console.log('user logged in')
    } else if (user && user.access_token && user.userId) {
      console.log('read user saved', user)
      sfAuthService
        .checkLogin(user)
        .then(
          ({ user, state, configuration, tutorialSteps }) => {
            this.props.setUserData(user)
            this.props.setConfigurationData(configuration)
            sfAuthService.setUser(user)
            this.props.userLoggedIn()
          },
          e => {
            console.error('user check failed', e, user)
            sfAuthService.setUser(null)
            this.props.userLoggedOut()
            sfAuthService.doLogin(sentState)
          }
        )
        .catch(e => {
          console.error('cant login from stored user', e, login)
          sfAuthService.setUser(null)
          this.props.userLoggedOut()
          sfAuthService.doLogin(sentState)
        })
    } else {
      sfAuthService
        .parseToken()
        .then(
          ({
            user,
            state,
            organization,
            configuration,
            tutorialSteps,
            caseTypes,
            avaliableOrganizations
          }) => {
            this.props.setOrganization(organization)
            this.props.setUserData(user)
            this.props.setConfigurationData(configuration)
            this.props.setTutorialsFromSteps(tutorialSteps)
            this.props.setAvaliableOrganizations(avaliableOrganizations)
            this.props.setCaseTypes(caseTypes)
            this.props.userLoggedIn()
            try {
              console.log('redirect', state, history.location.pathname)
              if (
                !String(history.location.pathname).includes(
                  'ApplicantInformation'
                ) &&
                user.isUserProfileValid ||
                // always redirect on internal, skip validity check
                sfOauthConfig.isInternal
              ) {
                history.push({
                  pathname: state.redirect_uri
                })
              }
            } catch (e) {
              console.error("can't redirect going to root", e, user, state)
              // window.location = '/'
            }
          }
        )
        .catch(e => {
          console.error('cant login', e, login)
          sfAuthService.doLogin(sentState)
        })
    }
  }

  render () {
    const { children, maintenance } = this.props
    if (maintenance) {
      return (
        <>
          <div
            style={{
              position: 'fixed',
              left: 0,
              textAlign: 'center',
              right: 0,
              top: 'calc(50% - 100px)',
              margin: 'auto',
              height: '200px',
              width: '400px'
            }}
          >
            <Typography variant='h6'>
              Temporarily Under Maintenance
              <br />
              We'll be back shortly
            </Typography>
            <br />
            <br />
            <Typography variant='h6'>
              Temporairement en maintenance
              <br />
              Nous serons bientôt de retour
            </Typography>
          </div>
        </>
      )
    }
    return <Fragment>{children}</Fragment>
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  setConfigurationData: PropTypes.func.isRequired,
  login: state.login,
  user: state.user
})

export default connect(mapStateToProps, {
  setUserData,
  setCaseTypes,
  setOrganization,
  setAvaliableOrganizations,
  setConfigurationData,
  setTutorialsFromSteps,
  userLoggedIn,
  userLoggedOut
})(withRouter(Auth))
