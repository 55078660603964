import { Trans } from '@lingui/macro'
import { ErrorMessage } from 'formik'
import React from 'react'

export const FormLabel = ({ label, error }) => {
  return (
    <p
      style={{
        color: error ? '#f5543b' : 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        marginTop: 3,
        marginLeft: 14,
        textAlign: 'left'
      }}
    >
      {label}
    </p>
  )
}

export function FormErrorText ({ msg }) {
  return (
    <p
      style={{
        color: '#f5543b',
        fontSize: '0.75rem',
        marginTop: 3,
        marginLeft: 14,
        textAlign: 'left'
      }}
    >
      {msg}
    </p>
  )
}

export function FormErrorLabel ({ error, required, id, label }) {
  const nonErrorLabel = Boolean(label || required)
  return (
    <>
      {Boolean(!error && nonErrorLabel) && (
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.75rem',
            marginTop: 3,
            marginLeft: 14,
            textAlign: 'left'
          }}
        >
          {label ? (
            required ? (
              <span>
                {label}. <Trans>Required</Trans>
              </span>
            ) : (
              <span>{label}</span>
            )
          ) : (
            <Trans>Required</Trans>
          )}
        </p>
      )}
      {Boolean(error) && (
        <ErrorMessage name={id}>
          {msg => (
            <p
              style={{
                color: '#f5543b',
                fontSize: '0.75rem',
                marginTop: 3,
                marginLeft: 14,
                textAlign: 'left'
              }}
            >
              {msg}
            </p>
          )}
        </ErrorMessage>
      )}
    </>
  )
}
