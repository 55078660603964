import { mapSFToForm, reverseMap } from '../sfDataService'
import SFAuthService from '..//SFAuthService'
import { NO_USER, USER_UPDATE_OLD_USERS_ERROR } from '../SFAuthService'

export const fetchTechnicalAdvisoriesForUser = userId => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('TechnicalAdvisoryAssignment__c')
    .find({
      User__c: userId
    })
    .then(assigments => {
      const opportunitiesId = []
      assigments.forEach(item => {
        if (!opportunitiesId.includes(item.Opportunity__c)) {
          opportunitiesId.push(item.Opportunity__c)
        }
      })
      return conn
        .sobject('Opportunity')
        .find({
          Id: { $in: opportunitiesId }
        })
        .select('Id, Name')
        .then(opportunities => {
          const opportunitiesMap = {}
          opportunities.forEach(opp => {
            opportunitiesMap[opp.Id] = {
              ...opp
            }
          })
          return assigments.map(assigment => ({
            ...assigment,
            Opportunity__c: opportunitiesMap[assigment.Opportunity__c]
          }))
        })
    })
}

export const fetchOpportunitiesWithTechnicalAdvisoryAssigments = () => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('Opportunity')
    .find({
      Community_Delete__c: false
    })
    .select('Id, Name, Account.Name, StageName')
    .include('Technical_Advisory_Assignments__r')
    .end()
}

export const submitTechnicalAdvisory = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('TechnicalAdvisoryAssignment__c').update({
    Id: id,
    Status__c: 'Submitted'
  })
}

// export const createTechnicalAdvisory = oppId => {
//   const conn = SFAuthService.getConnection()
//   if (!conn) {
//     return Promise.reject(NO_USER)
//   }
//   return conn.sobject('TechnicalAdvisoryAssignment__c').create({
//     Opportunity__c: '0065e00000DOFkWAAX',
//     Status__c: 'Assigned'
//   })
// }
