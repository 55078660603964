import moment from 'moment'

export function debounce (func, wait, immediate) {
  var timeout
  return function () {
    var context = this
    var args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

export function isValidJSON (str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function isMobile () {
  if (window) {
    return window.matchMedia('(max-width: 767px)').matches
  }
  return false
}

export function isMdScreen () {
  if (window) {
    return window.matchMedia('(max-width: 1199px)').matches
  }
  return false
}

function currentYPosition () {
  if (!window) {
    return
  }
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop
  }
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop
  return 0
}

function elmYPosition (elm) {
  var y = elm.offsetTop
  var node = elm
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent
    y += node.offsetTop
  }
  return y
}

export function scrollTo (scrollableElement, elmID) {
  var elm = document.getElementById(elmID)
  if (!elmID || !elm) {
    return
  }
  var startY = currentYPosition()
  var stopY = elmYPosition(elm)
  var distance = stopY > startY ? stopY - startY : startY - stopY
  if (distance < 100) {
    scrollTo(0, stopY)
    return
  }
  var speed = Math.round(distance / 50)
  if (speed >= 20) speed = 20
  var step = Math.round(distance / 25)
  var leapY = stopY > startY ? startY + step : startY - step
  var timer = 0
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY)
          }
        })(leapY),
        timer * speed
      )
      leapY += step
      if (leapY > stopY) leapY = stopY
      timer++
    }
    return
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY)
        }
      })(leapY),
      timer * speed
    )
    leapY -= step
    if (leapY < stopY) leapY = stopY
    timer++
  }
  return false
}

export function getTitleTranslation (userLang, titleTranslation) {
  let ret = ''
  if (
    !titleTranslation ||
    !titleTranslation.Survey_Texts__r ||
    !titleTranslation.Survey_Texts__r.records
  ) {
    // console.warn('there was no translation for provided title!')
    return ret
  }
  titleTranslation.Survey_Texts__r.records.some(trans => {
    if (userLang === 'en_US' && trans.Language__c === 'en-ca') {
      ret = trans.Text__c
      return true
    } else if (userLang === 'fr' && trans.Language__c === 'fr-ca') {
      ret = trans.Text__c
      return true
    }
    return false
  })
  return ret
}

export function removePartOfString (string, startChar, endChar) {
  if (string.indexOf(startChar) === -1 || string.indexOf(endChar) === -1) {
    console.warn(
      'Could not remove part of this string with given separators: ',
      string
    )
    return string
  }
  const part1 = string.substring(0, string.indexOf(startChar))
  const part2 = string.substring(string.indexOf(endChar))
  return part1 + part2
}

export function getTimeDifference (date) {
  const difference =
    moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(
      moment(date, 'DD/MM/YYYY HH:mm:ss')
    ) / 1000

  if (difference < 60) return `${Math.floor(difference)} seconds`
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`
  else if (difference < 86400 * 30) {
    return `${Math.floor(difference / 86400)} days`
  } else if (difference < 86400 * 30 * 12) {
    return `${Math.floor(difference / 86400 / 30)} months`
  } else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`
}

export function generateRandomId () {
  const tempId = Math.random().toString()
  const uid = tempId.substr(2, tempId.length - 1)
  return uid
}

export function getQueryParam (prop) {
  var params = {}
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf('?') + 1)
  )
  var definitions = search.split('&')
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2)
    params[parts[0]] = parts[1]
  })
  return prop && prop in params ? params[prop] : params
}

export function classList (classes) {
  return Object.entries(classes)
    .filter(entry => entry[1])
    .map(entry => entry[0])
    .join(' ')
}

export const muiDateCompare = fieldName => order => (
  { data: val1 },
  { data: val2 }
) => {
  let ret = 1
  const val1Date = fieldName ? moment.utc(val1[fieldName]) : moment.utc(val1)
  const val2Date = fieldName ? moment.utc(val2[fieldName]) : moment.utc(val2)
  ret = val1Date.diff(val2Date, 'minutes')

  if (order === 'asc') {
    return ret
  } else {
    return ret * -1
  }
}

export const chunkArray = (arr, chunkSize) => {
  if (chunkSize <= 0) throw 'Invalid chunk size'
  const R = []
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize))
  }
  return R
}
