import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppContext from 'app/appContext'
import { checkAuth } from './authRoles'

class AuthGuard extends Component {
  constructor (props, context) {
    super(props)
    const { routes } = context

    this.state = {
      authenticated: true,
      routes
    }
  }

  componentDidMount () {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props)
    }
  }

  componentDidUpdate () {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState.authenticated !== this.state.authenticated
  }

  static getDerivedStateFromProps (props, state) {
    const { location, user, organization } = props
    const { pathname } = location
    const matched = state.routes.find(r => r.path === pathname)
    const authenticated =
      matched && matched.auth && matched.auth.length
        ? checkAuth(matched.auth, user.role, matched, { user, organization })
        : true

    return {
      authenticated
    }
  }

  redirectRoute (props) {
    const { location, history } = props
    const { pathname } = location

    history.push({
      pathname: '/session/404',
      state: { redirectUrl: pathname }
    })
  }

  render () {
    const { children, user } = this.props
    const { authenticated } = this.state
    const userFilled = user && user.userFilled
    console.log('userFilled', user, user.userFilled, authenticated)

    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { logged: authenticated })
      }
      return child
    })

    // return <Fragment>{children}</Fragment>;
    return authenticated ? <>{childrenWithProps}</> : null
  }
}

AuthGuard.contextType = AppContext

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organization
})

export default withRouter(connect(mapStateToProps)(AuthGuard))
