import { Grid, makeStyles, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  startEditingField,
  endEditingField,
  updateLockedFieldValue,
  unlockFieldWithoutChanges
} from 'app/views/forms/multiuser/grpcMultiuserEdit'
import { Trans } from '@lingui/macro'
import { countHelperText } from 'app/views/page-layouts/FormElement'
import { Field, useFormikContext } from 'formik'
import { MuEditedByLabel } from '../Common'
import { getCurrentWords } from '../FormTextField'
import _ from 'lodash'

const MUTextField = ({
  id,
  showErrorWithoutTouch = false,
  formId,
  useMultiuser,
  preventEndEditingEvent = false,
  maxWords,
  wordCount,
  required,
  limit,
  maxInput,
  minInput,
  type = 'text',
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext()
  let fieldValue = _.get(values, id)
  if (!fieldValue && fieldValue !== 0) {
    fieldValue = ''
  }
  const user = useSelector(state => state.user)
  const { muInfo, muUsers = {} } = values
  const [inputValue, setInputValue] = useState(fieldValue)
  const [defaultZeroRemoved, setDefaultZeroRemoved] = useState(false)
  const [timer, setTimer] = useState(null)
  const [startEditingValue, setStartEditingValue] = useState(null)

  useEffect(() => {
    setInputValue(fieldValue)
  }, [fieldValue])

  const helperArray = []
  const stringValue = inputValue || ''
  if (wordCount || maxWords) {
    helperArray.push(
      <span key='wordsCount'>
        {getCurrentWords(stringValue, maxWords)} <Trans>words</Trans>
        {'. '}
      </span>
    )
  }
  if (Number(limit)) {
    helperArray.push(
      <span key='limit'>
        {countHelperText(stringValue, Number(limit))}
        {'. '}
      </span>
    )
  }
  if (required) {
    helperArray.push(
      <span key='required'>
        <Trans>Required</Trans>
        {'. '}
      </span>
    )
  }

  const inputChanged = e => {
    let value = e.target.value
    if (value === inputValue) {
      return
    }
    let invalid = false
    if (maxWords) {
      const words = getCurrentWords(e.target.value)
      if (words > maxWords) {
        invalid = true
      } else if (words === maxWords) {
        value = value.trim()
      }
    }
    if (!invalid) {
      setInputValue(value)
    } else {
      setInputValue(inputValue)
    }
    clearTimeout(timer)
    const lockId = values.muInfo.lockId
    const newTimer = setTimeout(() => {
      setFieldValue(id, value)
      if (useMultiuser) {
        updateLockedFieldValue({
          lockId,
          fieldId: id,
          fieldValue: value,
          formId,
          userId: user.userId
        })
      }
    }, 700)
    setTimer(newTimer)
  }

  const muState = muInfo[id]
  let userColor, userName, isEdited

  if (muState) {
    const { user, locked } = muState
    if (locked) {
      isEdited = true
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }

  let marginBottom = 0
  const style = { ...props.style }
  if (style && style.marginBottom) {
    marginBottom = style.marginBottom || style.margin || 0
    if (isEdited) {
      delete style.marginBottom
    }
  }

  const coloredStyles = makeStyles(theme => ({
    root: {
      '& label.Mui-focused': {
        color: userColor || 'rgba(0, 0, 0, 0.38)'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: userColor || 'rgba(0, 0, 0, 0.38)'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: userColor || 'rgba(0, 0, 0, 0.38)'
        },
        '&.Mui-disabled fieldset': {
          borderColor: userColor || 'rgba(0, 0, 0, 0.38)'
        },
        '&.Mui-focused fieldset': {
          borderColor: userColor || 'rgba(0, 0, 0, 0.38)'
        }
      }
    }
  }))
  const classes = coloredStyles()

  return (
    <Grid container direction='column'>
      <Field name={id}>
        {({ field, meta, form }) => {
          const displayError =
            (meta.touched || showErrorWithoutTouch) && meta.error
          let inputError = false
          if (maxInput && inputValue) {
            if (+maxInput < +inputValue) {
              inputError = true
            }
          }
          if (minInput && inputValue) {
            if (+minInput > +inputValue) {
              inputError = true
            }
          }
          if (limit) {
            if (+limit < stringValue.length) {
              inputError = true
            }
          }
          let valueToShow = inputValue
          if (!valueToShow && valueToShow !== 0 && valueToShow !== '0') {
            valueToShow = props.defaultValue || ''
          }

          return (
            <TextField
              name={id}
              value={valueToShow}
              onChange={inputChanged}
              error={
                (meta.error && (meta.touched || showErrorWithoutTouch)) ||
                inputError
              }
              className={classes.root}
              variant='outlined'
              fullWidth
              {...props}
              helperText={
                displayError ? meta.error : props.helperText || helperArray
              }
              style={{
                ...style,
                marginBottom: isEdited ? 0 : marginBottom
              }}
              disabled={
                Boolean(
                  muState && muState.locked && muState.user !== user.userId
                ) || props.disabled
              }
              onFocus={e => {
                setStartEditingValue(inputValue)
                if (useMultiuser) {
                  startEditingField({
                    userId: user.userId,
                    formId,
                    fieldId: id
                  })
                }
                if (
                  type === 'number' &&
                  (valueToShow === 0 || valueToShow === '0')
                ) {
                  setDefaultZeroRemoved(true)
                  setInputValue('')
                }
              }}
              onBlur={e => {
                if (!preventEndEditingEvent && useMultiuser) {
                  if (startEditingValue === e.target.value) {
                    unlockFieldWithoutChanges({
                      lockId: values.muInfo.lockId,
                      fieldId: id,
                      formId,
                      userId: user.userId
                    })
                  } else {
                    endEditingField({
                      userId: user.userId,
                      formId,
                      fieldId: id,
                      fieldValue: e.target.value,
                      lockId: values.muInfo.lockId
                    })
                  }
                }
                if (!meta.touched) {
                  form.setFieldTouched(id)
                }
                field.onBlur(e)
                setStartEditingValue(null)
                if (type === 'number' && !inputValue && setDefaultZeroRemoved) {
                  setDefaultZeroRemoved(false)
                  setInputValue('0')
                } else if (setDefaultZeroRemoved) {
                  setDefaultZeroRemoved(false)
                }
              }}
            />
          )
        }}
      </Field>
      <div style={{ marginBottom: marginBottom }}>
        <MuEditedByLabel color={userColor} userName={userName} />
      </div>
    </Grid>
  )
}

export default MUTextField
