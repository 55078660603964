import { mapSFToForm, reverseMap } from '../sfDataService'
import SFAuthService from '..//SFAuthService'
import { NO_USER } from '../SFAuthService'
import { saveUserParsed } from './sfUser'
import { saveContactParsed } from './sfContact'
import moment from 'moment'
import { t } from '@lingui/macro'
import { getJoinRequests } from './sfJoinRequest'
import { myI18n } from '../../../../translation/I18nConnectedProvider'
import sfOauthConfig from '../sfAuthConfig'
import { fetchConfiguration, fetchFormPage } from './sfForms'
import { checkFormValidity } from 'app/views/forms/Form'
import { constructFormAddressString } from 'app/views/forms/components/Common'
/**
 * Created by Michał Stawski on 22.08.2020.
 */
/*
let example = {
  'attributes': { 'type': 'Account', 'url': '/services/data/v42.0/sobjects/Account/0014m000002uPSNAA2' },
  'Id': '0014m000002uPSNAA2',
  'IsDeleted': false,
  'MasterRecordId': null,
  'Name': 'new Organization for 0054m000000KIPHAA4',
  'Type': null,
  'RecordTypeId': '0123j000001LByjAAG',
  'ParentId': null,
  'BillingStreet': null,
  'BillingCity': null,
  'BillingState': null,
  'BillingPostalCode': null,
  'BillingCountry': null,
  'BillingLatitude': null,
  'BillingLongitude': null,
  'BillingGeocodeAccuracy': null,
  'BillingAddress': null,
  'ShippingStreet': null,
  'ShippingCity': null,
  'ShippingState': null,
  'ShippingPostalCode': null,
  'ShippingCountry': null,
  'ShippingLatitude': null,
  'ShippingLongitude': null,
  'ShippingGeocodeAccuracy': null,
  'ShippingAddress': null,
  'Phone': null,
  'Fax': null,
  'AccountNumber': null,
  'Website': null,
  'PhotoUrl': null,
  'Sic': null,
  'Industry': null,
  'AnnualRevenue': null,
  'NumberOfEmployees': null,
  'Ownership': null,
  'TickerSymbol': null,
  'Description': null,
  'Rating': null,
  'Site': null,
  'OwnerId': '0054m000000KIPHAA4',
  'CreatedDate': '2020-08-27T13:28:44.000+0000',
  'CreatedById': '0054m000000KIPHAA4',
  'LastModifiedDate': '2020-08-27T13:28:44.000+0000',
  'LastModifiedById': '0054m000000KIPHAA4',
  'SystemModstamp': '2020-08-27T13:28:44.000+0000',
  'LastActivityDate': null,
  'LastViewedDate': '2020-08-27T13:28:44.000+0000',
  'LastReferencedDate': '2020-08-27T13:28:44.000+0000',
  'IsPartner': false,
  'Jigsaw': null,
  'JigsawCompanyId': null,
  'AccountSource': null,
  'SicDesc': null,
  'npe01__One2OneContact__c': null,
  'npe01__SYSTEMIsIndividual__c': false,
  'npe01__SYSTEM_AccountType__c': null,
  'FGM_Base__EIN__c': null,
  'FGM_Base__IRS_Letter__c': false,
  'FGM_Portal__Active__c': null,
  'FGM_Portal__CustomerPriority__c': null,
  'npo02__AverageAmount__c': 0,
  'npo02__Best_Gift_Year_Total__c': null,
  'npo02__Best_Gift_Year__c': null,
  'npo02__FirstCloseDate__c': null,
  'npo02__Formal_Greeting__c': null,
  'npo02__HouseholdPhone__c': null,
  'npo02__Informal_Greeting__c': null,
  'npo02__LargestAmount__c': 0,
  'npo02__LastCloseDate__c': null,
  'npo02__LastMembershipAmount__c': 0,
  'npo02__LastMembershipDate__c': null,
  'npo02__LastMembershipLevel__c': null,
  'npo02__LastMembershipOrigin__c': null,
  'npo02__LastOppAmount__c': 0,
  'npo02__MembershipEndDate__c': null,
  'npo02__MembershipJoinDate__c': null,
  'npo02__NumberOfClosedOpps__c': 0,
  'npo02__NumberOfMembershipOpps__c': 0,
  'npo02__OppAmount2YearsAgo__c': 0,
  'npo02__OppAmountLastNDays__c': 0,
  'npo02__OppAmountLastYear__c': 0,
  'npo02__OppAmountThisYear__c': 0,
  'npo02__OppsClosed2YearsAgo__c': 0,
  'npo02__OppsClosedLastNDays__c': 0,
  'npo02__OppsClosedLastYear__c': 0,
  'npo02__OppsClosedThisYear__c': 0,
  'npo02__SYSTEM_CUSTOM_NAMING__c': null,
  'npo02__SmallestAmount__c': 0,
  'npo02__TotalMembershipOppAmount__c': 0,
  'npo02__TotalOppAmount__c': 0,
  'npsp__Number_of_Household_Members__c': null,
  'npsp__Membership_Span__c': null,
  'npsp__Membership_Status__c': null,
  'npsp__Funding_Focus__c': null,
  'npsp__Grantmaker__c': false,
  'npsp__Matching_Gift_Administrator_Name__c': null,
  'npsp__Matching_Gift_Amount_Max__c': null,
  'npsp__Matching_Gift_Amount_Min__c': null,
  'npsp__Matching_Gift_Annual_Employee_Max__c': null,
  'npsp__Matching_Gift_Comments__c': null,
  'npsp__Matching_Gift_Company__c': false,
  'npsp__Matching_Gift_Email__c': null,
  'npsp__Matching_Gift_Info_Updated__c': null,
  'npsp__Matching_Gift_Percent__c': null,
  'npsp__Matching_Gift_Phone__c': null,
  'npsp__Matching_Gift_Request_Deadline__c': null,
  'FGM_Portal__NumberofLocations__c': null,
  'FGM_Portal__SLAExpirationDate__c': null,
  'FGM_Portal__SLASerialNumber__c': null,
  'FGM_Portal__SLA__c': null,
  'FGM_Portal__TestLookupUser__c': null,
  'FGM_Portal__TestRichText__c': null,
  'FGM_Portal__Testmultiselect__c': null,
  'FGM_Portal__UpsellOpportunity__c': null,
  'FGM_Portal__Number_of_Requests__c': 0,
  'Organisation_History__c': null,
  'Other_used_name__c': null,
  'Acronym__c': null,
  'Email__c': null,
  'Demography__c': null,
  'Service_belt__c': null,
  'Sector__c': null,
  'Service_Locations__c': null,
  'Address__c': null,
  'Mission_Statement__c': null,
  'Geographic_area_of_Operation__c': null,
  'Total_yearly_budget__c': null,
  'Services_provided__c': null,
  'Account_Type__c': null,
  'Main_funding_sources__c': null,
  'Number_of_buildings__c': null,
  'Housing_units_managed_by_Organisation__c': null,
  'First_Signer__c': null,
  'Facebook__c': null,
  'LinkedIn__c': null,
  'Other__c': null,
  'Is_a_member_of_an_Association_Network__c': null,
  'Association_Network_Name__c': null,
  'Other_Demographic__c': null,
  'Number_of_tenants_living_in_your_buildin__c': null,
  'Second_Signer__c': null,
  'Owner_Portal_User__c': null,
  'Is_Non_Profit_Housing__c': false
}
*/

export const NO_FOCUS_DEMOGRAPHIC = 'No focus demographic'

const mapFields = {
  direction: 'in',
  Id: 'id',
  LastModifiedDate: 'lastModifiedDate',
  Name: 'organisationsName',
  // Other_used_name__c: 'otherName',
  Facebook__c: 'facebook',
  Website: 'website',
  // BillingCity: 'City',
  BillingState: 'province',
  BillingStreet: 'street',
  BillingPostalCode: 'postalCode',
  // Organisation_History__c: 'organisationHistory',
  // Geographic_area_of_Operation__c: 'geographicArea',
  // Services_provided__c: 'services',
  // Is_a_member_of_an_Association_Network__c: {
  //   key: 'memberOfAsocNetw',
  //   out: res => res.memberOfAsocNetw,
  //   in: opp =>
  //     opp.Is_a_member_of_an_Association_Network__c
  //       ? opp.Is_a_member_of_an_Association_Network__c.split(';')[0]
  //       : null
  // },
  // Association_Network_Name__c: {
  //   key: 'AsocNetwName',
  //   out: res => {
  //     if (res.memberOfAsocNetw === 'No') {
  //       return 'None'
  //     }
  //     return res.AsocNetwName || ''
  //   },
  //   in: opp => {
  //     if (opp.Association_Network_Name__c === 'None') {
  //       return null
  //     }
  //     return opp.Association_Network_Name__c
  //   }
  // },
  // Units_special_needs__c: 'unitsSpecialNeeds',
  Focus_demographic__c: {
    key: 'focusDemographic',
    in: opp => {
      if (opp.Focus_demographic__c === 'No focus demographic') {
        return []
      }
      return opp.Focus_demographic__c ? opp.Focus_demographic__c.split(';') : []
    },
    out: res => {
      if (res.hasFocusDemographic === 'No') {
        return 'No focus demographic'
      }
      return res.focusDemographic.join(';')
    }
  },
  // Target_demographics__c: {
  //   key: 'targetDemographics',
  //   in: opp =>
  //     opp.Target_demographics__c
  //       ? opp.Target_demographics__c.split(';')[0]
  //       : null,
  //   out: res => res.targetDemographics
  // }, // yes no
  AccountTeamMembers: {
    key: 'members',
    in: acc => (acc.AccountTeamMembers ? acc.AccountTeamMembers.records : [])
  },
  Account_Type__c: {
    key: 'organisationType',
    in: opp => (opp.Account_Type__c ? opp.Account_Type__c.split(';') : []),
    out: res => {
      return res.organisationType.join(';')
    }
  },
  // Has_Focus_Demographic__c: {
  //   key: 'hasFocusDemographic',
  //   out: res => res.hasFocusDemographic,
  //   in: opp =>
  //     opp.Has_Focus_Demographic__c
  //       ? opp.Has_Focus_Demographic__c.split(';')[0]
  //       : null
  // },
  OwnerId: 'ownerId',
  // Number_of_buildings__c: 'buildings',
  // Acronym__c: 'acronym',
  Phone: 'phone',
  Email__c: 'email',
  LinkedIn__c: 'linkedIn',
  NumberOfEmployees: 'employees',
  Total_yearly_budget__c: 'yearlyBudget',
  npo02__NumberOfMembershipOpps__c: 'numberOfMembers',
  Main_funding_sources__c: 'mainFundingSource',
  Number_of_tenants_living_in_your_buildin__c: 'numberOfTenants',
  Housing_units_managed_by_Organisation__c: 'units',
  Mission_Statement__c: 'missionStatement',
  Number_of_member_organizations__c: 'numberOfOrganizations',
  Indigenous_Organization__c: {
    key: 'indigenousAffiliation',
    in: opp =>
      opp.Indigenous_Organization__c
        ? opp.Indigenous_Organization__c.split(';')
        : [],
    out: res => res.indigenousAffiliation.join(';')
  },
  Demography__c: {
    key: 'typeOfPopulation',
    in: opp => (opp.Demography__c ? opp.Demography__c.split(';') : []),
    out: res => res.typeOfPopulation.join(';')
  }
}

const mapFieldsICCE = {
  direction: 'in',
  Id: 'id',
  Name: 'organisationsName',
  Instagram__c: 'instagram',
  Twitter__c: 'twitter',
  Facebook__c: 'facebook',
  Website: 'website',
  BillingStreet: 'street',
  BillingPostalCode: 'postalCode',
  Focus_demographic__c: {
    key: 'focusDemographic',
    in: opp => {
      if (opp.Focus_demographic__c === 'No focus demographic') {
        return []
      }
      return opp.Focus_demographic__c ? opp.Focus_demographic__c.split(';') : []
    },
    out: res => {
      if (res.hasFocusDemographic === 'No') {
        return 'No focus demographic'
      }
      return res.focusDemographic.join(';')
    }
  },
  AccountTeamMembers: {
    key: 'members',
    in: acc => (acc.AccountTeamMembers ? acc.AccountTeamMembers.records : [])
  },
  Account_Type__c: {
    key: 'organisationType',
    in: opp => (opp.Account_Type__c ? opp.Account_Type__c.split(';') : []),
    out: res => {
      return res.organisationType.join(';')
    }
  },
  OwnerId: 'ownerId',
  Phone: 'phone',
  Email__c: 'email',
  LinkedIn__c: 'linkedIn',
  NumberOfEmployees: 'employees',
  Total_yearly_budget__c: 'yearlyBudget',
  npo02__NumberOfMembershipOpps__c: 'numberOfMembers',
  Main_funding_sources__c: 'mainFundingSource',
  Number_of_tenants_living_in_your_buildin__c: 'numberOfTenants',
  Housing_units_managed_by_Organisation__c: 'units',
  Mission_Statement__c: 'missionStatement',
  Number_of_member_organizations__c: 'numberOfOrganizations',
  Indigenous_Organization__c: {
    key: 'indigenousAffiliation',
    in: opp =>
      opp.Indigenous_Organization__c
        ? opp.Indigenous_Organization__c.split(';')
        : [],
    out: res => res.indigenousAffiliation.join(';')
  },
  Demography__c: {
    key: 'typeOfPopulation',
    in: opp => (opp.Demography__c ? opp.Demography__c.split(';') : []),
    out: res => res.typeOfPopulation.join(';')
  }
}

const reverseMapFields = reverseMap(
  sfOauthConfig.isIcce ? mapFieldsICCE : mapFields
)
// isPartnerSuperUser
//

// associatedOrganisations,
//
const ORGANIZATION_VALIDITY_ERROR = 'ORGANIZATION_VALIDITY_ERROR'
export const checkOrganizationDetailsValidity = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return fetchConfiguration({
    Name: 'FORM_ORGANIZATION_DETAILS'
  }).then(config => {
    if (config.Value__c) {
      return fetchFormPage(config.Value__c).then(form => {
        if (!form) {
          return ORGANIZATION_VALIDITY_ERROR
        }
        return checkFormValidity({
          formId: form.id,
          id: constructFormAddressString({
            organizationId: id,
            objectsConnected: form.objectsConnected
          })
        })
      })
    } else {
      return ORGANIZATION_VALIDITY_ERROR
    }
  })
}

export const getAccountByName = name => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .requestPost('/actions/custom/flow/App_Find_Account_by_Name', {
      inputs: [
        {
          accountName: name
          // accountId: value.Id
        }
      ]
    })
    .then(result => {
      const ret = result[0].outputValues.resultIDs
      if (ret) {
        return ret
      } else {
        return []
      }
    })
}

export const getAccountTeamMembers = id => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('AccountTeamMember')
    .find({
      AccountId: id
    })
    .select(
      'UserId, Id, AccountAccessLevel, TeamMemberRole, User.Email, User.Name'
    )
}

export const getAccount = (id, props = {}) => {
  const conn = SFAuthService.getConnection()
  const { permissions = {}, fields } = props
  let retPromise = conn.sobject('Account').find({
    Id: id
  })
  if (permissions.TEAM_MEMBERS) {
    retPromise = retPromise
      .include('AccountTeamMembers')
      .select(
        'UserId, Id, AccountAccessLevel, TeamMemberRole, User.Email, User.Name'
      )
      .end()
  }
  if (conn) {
    return retPromise
  } else {
    return Promise.reject(NO_USER)
  }
}

export const getAccountsMap = (ids = []) => {
  const conn = SFAuthService.getConnection()
  if (ids.length === 0) {
    return Promise.resolve([])
  }
  return conn
    .sobject('Account')
    .find({
      Id: { $in: ids }
    })
    .then(accounts => {
      const toRet = {}
      accounts.forEach(acc => {
        toRet[acc.Id] = acc
      })
      return toRet
    })
}

export const getOrganizations = (props = {}) => {
  const conn = SFAuthService.getConnection()
  const { permissions = {}, fields = ['Id', 'Name'], searchFields = {} } = props
  if (conn) {
    let retPromise = conn
      .sobject('Account')
      .find(searchFields)
      .select(fields.join(', '))
    if (permissions.TEAM_MEMBERS_FULL) {
      retPromise = retPromise
        .include('AccountTeamMembers')
        .select(
          'UserId, Id, Title__c, AccountAccessLevel, TeamMemberRole, User.Email, User.Name, User.FGM_Portal__UserEmail__c'
        )
        .end()
    }
    retPromise = retPromise.autoFetch(true)
    return retPromise
  } else {
    return Promise.reject(NO_USER)
  }
}

export const createAccount = userId => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.sobject('Account').create({
      Name: 'New organisation2'
    })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const checkOrganization = (
  sfUser,
  { username, displayName, orgId, organizationName, email, jobTitle }
) => {
  console.log(
    'checkOrganization',
    username,
    displayName,
    orgId,
    organizationName
  )
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  if (!orgId) {
    const promise = saveAccount({
      Name: organizationName || 'new Organization for ' + displayName,
      Email__c: email
    }).then(account => {
      orgId = account.id
      console.log('save user org', sfUser, account)
      return Promise.all([
        saveUserParsed(sfUser),
        conn.requestPost('/actions/custom/flow/App_Team_Create_member', {
          inputs: [
            {
              title: jobTitle,
              orgId,
              userId: sfUser.id,
              contactId: sfUser.contactId,
              toCreate: 'Manager'
            }
          ]
        })
      ]).then((saveUser, flowResult) => orgId)
    })
    if (sfUser.contactId) {
      return promise.then(associatedOrganizations => {
        console.log('checkOrganization', sfUser)
        return saveContactParsed({
          id: sfUser.contactId,
          associatedOrganizations
        }).then(() => associatedOrganizations)
      })
    } else {
      return promise
    }
  } else {
    return Promise.resolve().then(() => orgId)
  }
}

export const getUsersOrganizationsJoinRequests = user => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return getOrganizations({
    permissions: {
      TEAM_MEMBERS_FULL: true
    },
    fields: ['Id']
  }).then(orgs => {
    const list = orgs
      .filter(function (acc) {
        let currentUserTeamMemberRole
        if (acc.AccountTeamMembers) {
          const thisUserAtm = acc.AccountTeamMembers.records.filter(
            atm => atm.UserId === user.userId
          )
          if (thisUserAtm && thisUserAtm.length === 1 && thisUserAtm[0]) {
            currentUserTeamMemberRole = thisUserAtm[0].TeamMemberRole
          }
          return currentUserTeamMemberRole === 'Manager'
        } else {
          return false
        }
      })
      .map(org => org.Id)
    if (list.length === 0) {
      return []
    }
    return getJoinRequests(list, true)
  })
}

export const searchForAccount = searchObj => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  console.log(searchObj)
  if (
    searchObj.name === '' ||
    searchObj.email === '' ||
    searchObj.website === '' ||
    searchObj.phone === ''
  ) {
    return Promise.resolve().then(result => {
      return 'Cannot search by empty record!'
    })
  }
  return conn
    .requestPost('/actions/custom/flow/App_Find_Account', {
      inputs: [
        {
          name: searchObj.name !== '' ? searchObj.name : null,
          email: searchObj.email !== '' ? searchObj.email : null,
          website: searchObj.website !== '' ? searchObj.website : null,
          phone: searchObj.phone !== '' ? searchObj.phone : null
        }
      ]
    })
    .then(result => {
      let records = []
      if (result[0].outputValues.resultsCount > 0) {
        records = result[0].outputValues.orgList
      }
      if (records.length === 0) {
        return myI18n._(t`No organisation found!`)
      } else if (records.length > 0 && records.length <= 3) {
        return records
      } else {
        return myI18n._(
          t`'Too many records found! Try to be more specific in your search`
        )
      }
    })
}

export const checkIfOrganizationsAreStale = (userId, orgNowSaved = null) => {
  return getOrganizations({
    permissions: {
      TEAM_MEMBERS_FULL: true
    },
    fields: ['Id', 'LastModifiedDate']
  }).then(result => {
    const list = result.filter(function (acc) {
      if (acc.AccountTeamMembers) {
        let isMember = false
        acc.AccountTeamMembers.records.forEach(member => {
          if (member.UserId === userId) {
            isMember = true
          }
        })
        return isMember
      } else {
        return false
      }
    })
    const staleIds = list
      .map(org => {
        const diff = moment
          .utc()
          .diff(moment.utc(org.LastModifiedDate), 'months')
        if (orgNowSaved && orgNowSaved === org.Id) {
          return false
        }
        return diff > 11 ? org.Id : false
      })
      .filter(p => p)
    return staleIds
  })
}

export const changeAccountTeamMemberRole = ({
  roleName,
  userId,
  accountId
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost('/actions/custom/flow/App_Team_Edit_member', {
      inputs: [
        {
          roleName,
          accountId,
          userId
          // magic: 'mn234brmji87dsfkn3'
        }
      ]
    })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const removeMemberFromOrganization = ({ accountId, userId }) => {
  const conn = SFAuthService.getConnection()
  console.log(accountId, userId)
  if (conn) {
    return conn.requestPost('/actions/custom/flow/App_Team_Remove_member', {
      inputs: [
        {
          accountId: accountId,
          userId: userId
          // callerId: callerId,
        }
      ]
    })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const addNewMember = ({
  userId,
  accountId,
  role,
  userMail,
  jobTitle,
  callerId
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Team_Add_member', {
    inputs: [
      {
        // useNew: true,
        jobTitle: jobTitle,
        accountId: accountId,
        userId: userId,
        roleName: role || 'Partner',
        userEmail: userMail
      }
    ]
  })
  // output in error field:
  // ERROR_CALLER_NOT_MANAGER
  // ERROR_FOUND_MORE
  // ERROR_FOUND_NONE
}

export const memberAskToJoin = ({
  userId,
  accountId,
  role,
  userMail,
  jobTitle,
  callerId
}) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.requestPost('/actions/custom/flow/App_Team_Ask_to_join', {
    inputs: [
      {
        // useNew: true,
        jobTitle: jobTitle,
        accountId: accountId,
        userId: userId
      }
    ]
  })
  // output in error field:
  // ERROR_CALLER_NOT_MANAGER
  // ERROR_FOUND_MORE
  // ERROR_FOUND_NONE
}
export const removeUserFromAccount = ({ userId, contactId }) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return Promise.resolve()
    // return Promise.all([
    //   conn.sobject('User').update({
    //     Id: userId,
    //     Associated_Organizations__c: null
    //   }),
    //   saveContactParsed({
    //     id: contactId,
    //     associatedOrganizations: null
    //   })
    // ])
  } else {
    return Promise.reject(NO_USER)
  }
}

export const JOIN_ORGANIZATION_CONTEXTS = {
  CONTEXT_SELF: 'CONTEXT_SELF',
  CONTEXT_ACCEPT: 'CONTEXT_ACCEPT',
  CONTEXT_JOIN: 'CONTEXT_JOIN '
}

export const associateWithOrganisation = ({
  userId,
  jobTitle,
  orgId,
  email,
  context = JOIN_ORGANIZATION_CONTEXTS.CONTEXT_JOIN
}) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost('/actions/custom/flow/App_Set_Account_Ownership', {
      inputs: [
        {
          accountId: orgId,
          userId,
          email,
          jobTitle,
          context
        }
      ]
    })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const askToJoinOrganization = ({ userId, jobTitle, orgId }) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost('/actions/custom/flow/App_Team_Ask_to_join', {
      inputs: [
        {
          accountId: orgId,
          userId,
          jobTitle
        }
      ]
    })
    // RESULT_REQUEST_SENT
    // ERROR_ALREADY_ON_TEAM
    // ERROR_ALREADY_REQUESTED
  } else {
    return Promise.reject(NO_USER)
  }
}

export const addAccountContactAffiliation = toCreate => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Create_Account_Affiliation',
      {
        inputs: [{ ...toCreate }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

export const fetchAccountAffiliations = ({ accountId }) => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn
      .requestPost(
        '/actions/custom/flow/App_Account_Affiliations_and_Contacts',
        {
          inputs: [{ accountId }]
        }
      )
      .then(flowResult => {
        const { contacts, accountAffiliations } = flowResult[0].outputValues
        if (!accountAffiliations) {
          return []
        }
        const contactsMap = {}
        contacts &&
          contacts.forEach(contact => {
            contactsMap[contact.Id] = contact
          })
        accountAffiliations.forEach(aff => {
          aff.npe5__Contact__c = contactsMap[aff.npe5__Contact__c]
        })
        return accountAffiliations || []
      })
  } else {
    return Promise.reject(NO_USER)
  }
}

export const setAcountAffiliationToFormer = affiliationId => {
  const conn = SFAuthService.getConnection()
  if (conn) {
    return conn.requestPost(
      '/actions/custom/flow/App_Set_Account_Affiliation_To_Former',
      {
        inputs: [{ affiliationId }]
      }
    )
  } else {
    return Promise.reject(NO_USER)
  }
}

export const ERROR_DUPLICATE_NAME = 'DUPLICATE_NAME_DETECTED'

export const saveAccount = value => {
  console.log('save Account', value)
  const conn = SFAuthService.getConnection()
  console.log('save Account', value.Name, value)
  return conn
    .requestPost('/actions/custom/flow/App_Find_Account_by_Name', {
      inputs: [
        {
          accountName: value.Name,
          accountId: value.Id
        }
      ]
    })
    .then(result => {
      console.log('save Account result', result)
      const dupliates = result[0].outputValues.result
      if (dupliates > 0) {
        return Promise.reject(new Error(ERROR_DUPLICATE_NAME))
      } else {
        if (value.Id) {
          return conn.sobject('Account').update(value)
        } else {
          return conn.sobject('Account').create(value)
        }
      }
    })
}

export const whatRole = (userId, members) => {
  for (const member of members) {
    if (member.UserId === userId) {
      return member.TeamMemberRole
    }
  }
  return null
}

export const mapAccountToRedux = (obj, onlyActiveKeys) =>
  mapSFToForm(
    sfOauthConfig.isIcce ? mapFieldsICCE : mapFields,
    obj,
    onlyActiveKeys
  )

export const saveAccountParsed = value => {
  return saveAccount(mapSFToForm(reverseMapFields, value))
}

export const getAccountParsed = (id, props = {}) => {
  return getAccount(id, props).then(acc => mapSFToForm(mapFields, acc[0]))
}
