import { Trans } from '@lingui/macro'
import { Button, FormControlLabel, Checkbox } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { FormErrorsList } from './custom/FormSubmitButton'

export const FormSaveButton = ({
  saving,
  connectedMap,
  objectsFieldsMap,
  describeMap,
  saveButtonClicked,
  values,
  saveDisabled,
  errors,
  disabled,
  sectionIndex,
  typeProps
}) => {
  const { showAllErrors, showSectionErrors } = typeProps
  let errorsToPass = errors || []
  if (showSectionErrors) {
    errorsToPass = errorsToPass.filter(obj => sectionIndex === obj.sectionIndex)
  }

  return (
    <Field>
      {({ form }) => (
        <>
          {!disabled && (showAllErrors || showSectionErrors) && (
            <FormErrorsList
              helperText={
                showSectionErrors ? (
                  <Trans>
                    You have not completed all required fields on this page
                  </Trans>
                ) : (
                  <Trans>You have not completed all required fields</Trans>
                )
              }
              errors={errorsToPass}
              objectsFieldsMap={objectsFieldsMap}
              describeMap={describeMap}
              connectedMap={connectedMap}
            />
          )}
          <Button
            onClick={e => {
              saveButtonClicked({ values })
            }}
            disabled={saving || saveDisabled || disabled || !form.dirty}
            color='primary'
            variant='contained'
            fullWidth
          >
            <Trans>Save</Trans>
          </Button>
        </>
      )}
    </Field>
  )
}

export const FormEditorSaveButton = ({
  editMode,
  depth,
  typeProps = {},
  ...props
}) => {
  const { showAllErrors, showSectionErrors } = typeProps
  const dispatch = useDispatch()
  if (!editMode) {
    return <FormSaveButton {...props} typeProps={typeProps} />
  }

  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(showAllErrors)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.showAllErrors = e.target.checked
                delete toSet.showSectionErrors
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Show all errors?</Trans>}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(showSectionErrors)}
              onChange={e => {
                const toSet = { ...typeProps }
                toSet.showSectionErrors = e.target.checked
                delete toSet.showAllErrors
                dispatch({
                  type: 'FIELD',
                  depth: depth.split('.'),
                  fieldName: 'typeProps',
                  fieldValue: { ...toSet }
                })
              }}
            />
          }
          label={<Trans>Show errors from current section?</Trans>}
        />
      </div>
    </div>
  )
}
